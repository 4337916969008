import React, { useMemo } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { getIn, useFormikContext } from "formik";
import { useVariableData } from "@ploy-lib/calculation";
import { usePages } from "../pagesContext";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import PriorityIcon from "@material-ui/icons/PriorityHigh";
import { makeStyles } from "@material-ui/core/styles";
import { usePageState } from "../PageContext";
import { ApplicationStatus } from "@ploy-lib/types";
import { touchAllVisibleFields } from "./TouchVisibleFieldsOnPreviousPages";
import { useAppLoad } from "../appLoadContext";
import { defineMessages, useIntl } from "react-intl";
import {
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible,
	useFormHasErrors
} from "../hooks";
import { PaperProps, Paper } from "@material-ui/core";
import { DployTheme } from "@ploy-ui/tenants";

const useStyles = makeStyles((theme: DployTheme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},
	icon: {},
	iconInformation: {
		color: theme.form?.statusInformation.color ?? theme.palette.error.main
	},
	text: {
		fontWeight: 700
	},
	textInformation: {
		color: theme.form?.statusInformation.color
	},
	success: {
		"& $icon": {
			color: "rgba(109,162,18,1)"
		}
	},
	error: {
		"& $icon": {
			color: theme.palette.error.main
		}
	}
}));

const generalMessages = defineMessages({
	visibleErrors: {
		id: `form.form-status.visible-errors`,
		description: `Form status message when there are visible validation errors`,
		defaultMessage: "The application has shortcomings"
	},
	hiddenErrorsFirstPage: {
		id: `form.form-status.hidden-errors-first-page`,
		description: `Form status message when there are hidden validation errors`,
		defaultMessage: "Please fill in the application"
	},
	hiddenErrors: {
		id: `form.form-status.hidden-errors`,
		description: `Form status message when there are hidden validation errors`,
		defaultMessage: "Please complete the application"
	},
	noErrors: {
		id: `form.form-status.validation-ok`,
		description: `Form status message when there are no validation errors`,
		defaultMessage: "The application is complete and ready for submission!"
	},
	submitted: {
		id: `form.form-status.submitted`,
		description: `Form status message when the form have been submitted`,
		defaultMessage: "The application has been submitted"
	},
	cancelled: {
		id: `form.form-status.cancelled`,
		description: `Form status message when the application have been cancelled`,
		defaultMessage: "The application has been canceled"
	}
});

const submitErrorMessages = defineMessages({
	submitErrorMessage: {
		id: `form.form-status.submit-error`,
		description: `Form error message when submit fails`,
		defaultMessage: `Noe gikk galt ved innsending av søknad`
	}
});

export function FormStatus(props: {
	className?: string;
	errorText?: string;
	successText?: string;
	allPages?: boolean;
	elevation?: PaperProps["elevation"];
	hideStatusMessage?: boolean;
}) {
	const { className, allPages, elevation, hideStatusMessage } = props;

	const formik = useFormikContext();

	const { applicationStatus } = useAppLoad();

	const classes = useStyles();

	const formHasErrors = useFormHasErrors();
	const pages = usePages();
	const { isLastStep, step } = usePageState();
	const isFirstStep = step === 0;
	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();

	const { hasVisibleErrors, hasAnyErrors } = useMemo(() => {
		const relevantTouched = touchAllVisibleFields(
			formik.touched,
			pages.slice(0, step + 1),
			fieldIsVisible,
			sectionIsVisible
		);

		const hasVisibleErrors = formHasErrors(formik, undefined, false);
		const hasAnyErrors = formHasErrors(
			{
				...formik,
				touched: relevantTouched
			},
			undefined,
			true
		);

		return { hasVisibleErrors, hasAnyErrors };
	}, [fieldIsVisible, formHasErrors, formik, pages, sectionIsVisible, step]);

	const StatusIcon =
		hasVisibleErrors && applicationStatus !== ApplicationStatus.Cancelled
			? CloseIcon
			: (!hasAnyErrors && applicationStatus !== ApplicationStatus.Cancelled) ||
			  applicationStatus === ApplicationStatus.Submitted
			? CheckIcon
			: PriorityIcon;

	const status =
		applicationStatus === ApplicationStatus.Cancelled
			? "cancelled"
			: applicationStatus === ApplicationStatus.Submitted
			? "submitted"
			: hasVisibleErrors
			? "visibleErrors"
			: !hasAnyErrors
			? "noErrors"
			: isFirstStep
			? "hiddenErrorsFirstPage"
			: "hiddenErrors";

	const intl = useIntl();

	const statusMessage = intl.formatMessage(generalMessages[status]);

	const submitErrorMessage = intl.formatMessage(
		submitErrorMessages.submitErrorMessage
	);

	const { value: errorMessageValue } = useVariableData<string>(
		"Calculator",
		"ErrorMessage"
	);

	const { value: informationMessageValue } = useVariableData<string>(
		"Calculator",
		"InformationMessage"
	);

	const submitErrorsValue = useMemo(() => {
		return (getIn(formik.values, "__calculation") ?? {})?.submitResult
			?.Messages;
	}, [formik]);

	return (
		<>
			{errorMessageValue && (
				<Paper
					square
					elevation={elevation}
					className={clsx(className, classes.root, {
						[classes.error]: true
					})}
				>
					<CloseIcon className={classes.icon} />
					<Typography className={classes.text}>{errorMessageValue}</Typography>
				</Paper>
			)}
			{informationMessageValue && (
				<Paper
					square
					elevation={elevation}
					className={clsx(className, classes.root)}
				>
					<PriorityIcon
						className={clsx(classes.icon, classes.iconInformation)}
					/>
					<Typography className={clsx(classes.text, classes.textInformation)}>
						{informationMessageValue}
					</Typography>
				</Paper>
			)}
			{submitErrorsValue?.length > 0 && (
				<Paper
					square
					elevation={elevation}
					className={clsx(className, classes.root, {
						[classes.error]: true
					})}
				>
					<CloseIcon className={classes.icon} />
					<Typography className={classes.text}>{submitErrorMessage}</Typography>
				</Paper>
			)}
			{!hideStatusMessage &&
			!errorMessageValue &&
			(submitErrorsValue === undefined || submitErrorsValue?.length === 0) &&
			(allPages || isLastStep) &&
			statusMessage !== " " ? (
				<Paper
					square
					elevation={elevation}
					className={clsx(className, classes.root, {
						[classes.success]:
							(!hasAnyErrors &&
								applicationStatus !== ApplicationStatus.Cancelled) ||
							applicationStatus === ApplicationStatus.Submitted,
						[classes.error]:
							hasVisibleErrors &&
							applicationStatus !== ApplicationStatus.Cancelled
					})}
				>
					{StatusIcon && <StatusIcon className={classes.icon} />}
					<Typography className={classes.text}>{statusMessage}</Typography>
				</Paper>
			) : null}
		</>
	);
}

FormStatus.displayName = "DployFormStatus";

export default FormStatus;
