import { useDebugValue } from "react";
import { useValidationExpression, useSignerSetup } from ".";
import { useAppLoad } from "../appLoadContext";
import {
	ApplicationStatusesResource,
	FormValidationResource,
	useInvalidateOnUnmount
} from "@ploy-lib/rest-resources";
import { useStatefulResource } from "@rest-hooks/legacy";
import { useResource } from "@rest-hooks/core";
import { useFormTemplate } from "../FormTemplateContext";

const fieldDisabledSignerSetupProp: {
	[key: string]: (s: ReturnType<typeof useSignerSetup>) => boolean | undefined;
} = {
	SendToEsign: s =>
		s.incompleteSignerSetup ||
		s.allDocumentsSigned ||
		s.missingRequiredRegnrForEsign
			? true
			: undefined,
	SendToScreenSign: s =>
		s.incompleteSignerSetup ||
		s.allDocumentsSigned ||
		s.missingRequiredRegnrForEsign
			? true
			: undefined,
	ReleaseProofOfDelivery: s => (s.incompleteSignerSetup ? true : undefined)
};

const fieldDisabledStatusProp: {
	[key: string]: (s: ApplicationStatusesResource) => boolean | undefined;
} = {
	RegNumber: s =>
		s.canEditRegNumber == null ? undefined : !s.canEditRegNumber,
	ChassisNumber: s =>
		s.canEditChassisNumber == null ? undefined : !s.canEditChassisNumber,
	InvoiceNoRef: s =>
		s.canEditInvoiceNoRef == null ? undefined : !s.canEditInvoiceNoRef,
	DueDay: s => (s.canEditDueDay == null ? undefined : !s.canEditDueDay),
	DeliveryDate: s =>
		s.canEditDeliveryDate == null ? undefined : !s.canEditDeliveryDate,
	FormalityDeliveryDate: s =>
		s.canEditDeliveryDate == null ? undefined : !s.canEditDeliveryDate
};

export function useDisabledOverride(
	namespace: string | undefined,
	fieldName: string | null
) {
	const setup = useSignerSetup({
		skip: fieldName == null || !(fieldName in fieldDisabledSignerSetupProp)
	});

	const { applicationNumber, vulcanContext, id } = useAppLoad();

	const appStatusParams =
		fieldName != null &&
		applicationNumber &&
		vulcanContext === "PostProcess" &&
		fieldName in fieldDisabledStatusProp
			? {
					id: applicationNumber,
					__session: id
			  }
			: null;
	const {
		data: appStatus,
		loading: appStatusLoading,
		error: appStatusError
	} = useStatefulResource(
		ApplicationStatusesResource.detail(),
		appStatusParams
	);
	useInvalidateOnUnmount(ApplicationStatusesResource.detail(), appStatusParams);

	const { formTemplateId } = useFormTemplate();

	const formValidations =
		useResource(FormValidationResource.formValidations(), formTemplateId) ?? {};

	const { fieldIsDisabled } = useValidationExpression(formValidations);

	const fieldValidatorResult = fieldIsDisabled(`${namespace}.${fieldName}`);

	let disabled: boolean | undefined = undefined;

	if (setup && fieldName && fieldName in fieldDisabledSignerSetupProp) {
		disabled ||= fieldDisabledSignerSetupProp[fieldName](setup);
	}

	if (appStatus && fieldName && fieldName in fieldDisabledStatusProp) {
		disabled ||= fieldDisabledStatusProp[fieldName](appStatus);
	}

	if (disabled === undefined && fieldValidatorResult) {
		disabled = true;
	}

	useDebugValue(disabled);

	const loading = setup.loading || appStatusLoading;

	return {
		disabled: loading ? true : disabled,
		loading,
		error: setup.error ?? appStatusError
	};
}
