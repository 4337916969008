import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ModalPopupProps } from "./ModalPopup";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		pageMaxWidth: (props: ModalPopupProps) => ({
			width: props.maxWidth
		}),
		modalInternalGrid: {
			backgroundColor: "#fff",
			width: "50%",
			margin: "0 auto",
			marginTop: "100px",
			border: "2px solid black"
		},
		modal: {
			overflowY: "scroll"
		}
	}),
	{ name: "DployFormFullWidth" }
);
