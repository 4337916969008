import { memo, useEffect, useLayoutEffect } from "react";
import {
	AllApplications,
	Dashboard,
	Login,
	PendingOrders,
	PendingProofOfDelivery,
	Profile,
	Reports,
	VendorSigner
} from "./components/pages";
import LayoutSwitcher from "./components/LayoutSwitcher";
import { useFetcher, useResetter, useResource } from "@rest-hooks/core";

import { useTenant } from "@ploy-ui/tenants";
import {
	Routes,
	Route,
	useHref,
	useNavigate,
	Navigate,
	Outlet
} from "react-router-dom";
import { Authenticated } from "./components/Authenticated";
import { LoginResource } from "@ploy-lib/rest-resources";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet";
import { ParamsNavigate, v1routes } from "./components/Portal/routeMigration";
import { VulcanDataResource } from "@ploy-lib/rest-resources";

import { ExternalViewLoader } from "@ploy-ui/external-views";
import { GeneralInformationPage } from "@ploy-ui/general-info";
import {
	ProductView,
	ApplicationView,
	UserLimitationsBanner,
	RouteNavigationProvider,
	ProductViewProps
} from "@ploy-ui/form-views";
import { WholesaleLimitPage } from "@ploy-ui/wholesale-limit";
import { WholesaleOverviewPage } from "@ploy-ui/wholesale-overview";
import SalesPersonAssignment from "@ploy-ui/salesperson-assignment";
import { Box } from "@material-ui/core";
import { SimpleLayout } from "./components/SimpleLayout";
import { NewPasswordForm } from "./components/pages/Login/forms";
import { NetworkError } from "@ploy-lib/types";

function NotFound(): JSX.Element | null {
	throw new NetworkError(
		new Response(null, { status: 404, statusText: "Not Found" })
	);
}

function getProductRoutes(basepath: string, props: ProductViewProps) {
	return (
		<Route path={basepath}>
			<Route path=":externalCode/*" element={<ProductView {...props} />} />
			<Route index element={<ProductView {...props} />} />
		</Route>
	);
}

function FormViewLayout({ children = <Outlet /> }) {
	return (
		<>
			<UserLimitationsBanner />
			<Box
				p={{ sm: 2, md: 4 }}
				display="flex"
				justifyContent="center"
				width="100%"
			>
				{children}
			</Box>
		</>
	);
}

const App = memo(() => {
	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: useTenant().locale,
					translate: window.env?.REACT_APP_ENABLE_TRANSLATION as
						| "yes"
						| "no"
						| undefined
				}}
			/>
			<SnackbarProvider maxSnack={3}>
				<Routes>
					<Route path="logout" element={<Logout authenticate="/login" />} />

					<Route path="login" element={<SimpleLayout />}>
						<Route path="forgotten-password" element={<NewPasswordForm />} />
						<Route index element={<Login />} />
						<Route path="*" element={<NotFound />} />
					</Route>

					<Route
						element={
							<Authenticated authenticate="/login">
								<RouteNavigationProvider
									home="/"
									application="/applications/:applicationNumber"
									applicationWithStep="/applications/:applicationNumber/*"
									applicationFromCalculator="/form/:category/:externalCode"
								>
									<LayoutSwitcher />
								</RouteNavigationProvider>
							</Authenticated>
						}
					>
						<Route path="tokenLogin" element={<Navigate to=".." replace />} />

						{v1routes}

						{/* Route config */}
						<Route path="dashboard" element={<Dashboard />} />
						<Route path="pending-orders/*" element={<PendingOrders />} />
						<Route path="external/:view" element={<ExternalViewLoader />} />
						<Route path="reports/*" element={<Reports />} />

						<Route path="applications">
							<Route
								path=":applicationNumber/*"
								element={
									<FormViewLayout>
										<ApplicationView />
									</FormViewLayout>
								}
							/>
							<Route index element={<AllApplications />} />
						</Route>

						<Route path="form" element={<FormViewLayout />}>
							<Route path="application">
								<Route
									path=":applicationNumber"
									element={
										<ParamsNavigate
											replace
											to="../../../applications/:applicationNumber"
										/>
									}
								/>
							</Route>

							{/* All products in general except wholesale when you have external product code */}
							{getProductRoutes("product", {
								enableSelect: true,
								wholesale: false
							})}

							{/* Calculator products */}
							{getProductRoutes("calculator", {
								formContext: "Calculator",
								enableSelect: true
							})}

							{/* Wholesale products */}
							{getProductRoutes("wholesale", {
								wholesale: true,
								enableSelect: true
							})}

							{/* Category products, (not wholesale) */}
							{getProductRoutes(":category", {
								wholesale: false,
								enableSelect: true
							})}

							<Route index element={<Navigate to="finance" replace />} />
						</Route>

						<Route path="profile/*" element={<Profile />} />
						<Route
							path="proof-of-delivery/*"
							element={<PendingProofOfDelivery />}
						/>
						<Route path="vendor-signer/*" element={<VendorSigner />} />
						<Route path="wholesale-limit/*" element={<WholesaleLimitPage />} />
						<Route path="general-info" element={<GeneralInformationPage />} />
						<Route
							path="salesperson-assignment"
							element={<SalesPersonAssignment />}
						/>
						<Route
							path="wholesale-overview/*"
							element={<WholesaleOverviewPage />}
						/>

						<Route
							path="change-vendor"
							element={<ChangeVendor redirectTo="/dashboard" />}
						/>
						{/* Redirect to default */}
						<Route index element={<RedirectToInitial fallback="dashboard" />} />

						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</SnackbarProvider>
		</>
	);
});

function RedirectToInitial({ fallback }: { fallback: string }) {
	const { initialView = fallback } = useResource(
		VulcanDataResource.detail(),
		{}
	);

	const navigate = useNavigate();

	useEffect(() => {
		navigate(initialView, { replace: true });
	});

	return null;
}

const Logout = ({ authenticate = "" }: { authenticate?: string }) => {
	const logout = useFetcher(LoginResource.logout());

	const href = useHref(authenticate);

	useLayoutEffect(() => {
		// reload whole page to clear cache after logout
		async function logoutAndRedirect() {
			const { isAuthenticated } = await logout({});
			if (!isAuthenticated) window.location.href = href;
		}

		logoutAndRedirect();
	}, [href, logout]);

	return null;
};
const ChangeVendor = ({ redirectTo = "" }: { redirectTo?: string }) => {
	const resetVendor = useFetcher(LoginResource.selectVendor());
	const navigate = useNavigate();
	const resetCache = useResetter();

	useEffect(() => {
		resetVendor(undefined, { customerId: -1 });
		resetCache();
		navigate(redirectTo);
	}, [resetVendor, navigate, resetCache, redirectTo]);

	return null;
};

export default App;
