import { useHeight } from "./useHeight";
import { useDebugValue } from "react";

export interface MaxItemsOptions {
	wait?: number;
	itemHeight: number;
	divider?: boolean;
	dividerHeight?: number;
	staticHeight?: number;
}

export function useMaxItems<
	T extends HTMLElement = HTMLUListElement | HTMLOListElement
>(options: MaxItemsOptions) {
	const {
		wait,
		itemHeight,
		divider,
		dividerHeight = divider ? 1 : 0,
		staticHeight = 0
	} = options;

	const [listRef, listHeight] = useHeight<T>(wait);

	const maxItems =
		listHeight &&
		Math.floor(
			(listHeight + dividerHeight - staticHeight) / (itemHeight + dividerHeight)
		);

	useDebugValue(maxItems);

	return [listRef, maxItems] as const;
}
