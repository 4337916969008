import { useIntl } from "react-intl";
import { PrimitiveType, Options, FormatXMLElementFn } from "intl-messageformat";
import { useCallback } from "react";

import { formatMessage } from "@formatjs/intl";

export interface MessageFormatter {
	(
		message: string,
		values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
		options?: Options
	): string;
	<T>(
		message: string,
		values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T>>,
		options?: Options
	): Array<T | string> | string | T;
}

const id = "runtime_message_id";

export function useFormatRuntimeMessage(): MessageFormatter {
	const intl = useIntl();

	return useCallback(
		(
			message: string,
			values?: Record<
				string,
				PrimitiveType | FormatXMLElementFn<string, string>
			>,
			options?: Options
		) => {
			return formatMessage(
				{
					locale: intl.locale,
					timeZone: intl.timeZone,
					formats: intl.formats,
					defaultLocale: intl.defaultLocale,
					defaultFormats: intl.defaultFormats,
					onError: intl.onError,
					messages: {
						[id]: message
					}
				},
				intl.formatters,
				{ id },
				values,
				options
			);
		},
		[intl]
	);
}
