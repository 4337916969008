import { useMemo, useCallback } from "react";
import { InjectedFormikProps } from "formik";
import { usePages } from "../pagesContext";
import { getFieldName } from "@ploy-lib/core";
import { TemplatePage, ErrorDisplay } from "@ploy-lib/types";
import { isNotNull } from "@ploy-lib/core";
import { getFieldError } from "@ploy-ui/form-fields";
import { useTemplateFieldIsVisible } from ".";

export function useFormHasErrors() {
	const pages = usePages();
	const fieldIsVisible = useTemplateFieldIsVisible();

	const errorFields = useMemo(
		() =>
			pages
				.flatMap(page =>
					Object.values(page.panels).map(panel => ({
						panel,
						page
					}))
				)
				.flatMap(({ panel, page }) =>
					(panel.sections || []).map(section => ({ section, page, panel }))
				)
				.flatMap(({ section, page, panel }) =>
					section.fields.map(field => ({ field, page, panel, section }))
				)
				.map(({ field, page, section, panel }) => {
					const literal = panel.literal ?? section.literal ?? field.literal;

					const errorDisplay = literal
						? field.errorDisplayLiteral ?? ErrorDisplay.Never
						: field.errorDisplay ?? ErrorDisplay.Touched;

					switch (errorDisplay) {
						case ErrorDisplay.Never:
							return null;
						default:
							return {
								literal,
								name: getFieldName(field),
								field,
								page,
								errorDisplay
							};
					}
				})
				.filter(isNotNull),
		[pages]
	);

	const formHasErrors = useCallback(
		(
			{
				touched,
				errors
			}: Pick<InjectedFormikProps<{}, any>, "touched" | "errors">,
			includedPages?: TemplatePage[],
			includeLiteral = true
		) => {
			return errorFields.some(
				({ name, errorDisplay, field, page, literal }) => {
					if (literal && !includeLiteral) return false;
					if (includedPages && !includedPages.includes(page)) return false;

					if (!fieldIsVisible(field)) return false;

					return getFieldError(errors, touched, name, errorDisplay).error;
				}
			);
		},
		[errorFields, fieldIsVisible]
	);

	return formHasErrors;
}
