import { CalculatorState } from "../calculator";
import { isNotNull } from "../calculator/utils";
import { getIn } from "@ploy-lib/core";
import { Method, isMethod, isBodyStaticValue } from "./utils";
import { Patch, NamespaceService } from "../types";
import { Service, CalculationResponse } from "@ploy-lib/types";
import {
	fieldOptionFilterVariableName,
	fieldOptionListVariableName,
	servicePendingName
} from "../utils";
import { calculationToFieldList } from "./calculationToFieldList";
import { calculationToParams } from "./calculationToParams";
import { createPromiseHandler } from "./createPromiseHandler";
import { ServiceBodyValue } from "..";

export interface HandlerResponse {
	ok: boolean;
	data?: any;
	error?: Error;
}

export interface HandlerOptions {
	throttle?: boolean;
	accept?: string;
	method?: Method;
}

export interface ServiceCaller<TNamespaces extends string, TData> {
	(
		service: NamespaceService<TNamespaces>,
		options: HandlerOptions | undefined,
		calculation: Partial<CalculatorState<TNamespaces, TData>> | undefined,
		urlParams: { [key: string]: string } | undefined,
		payload: any
	): Promise<HandlerResponse>;
}

export function createServiceCaller<TNamespaces extends string, TData>(
	handler: (
		service: NamespaceService<TNamespaces>,
		body: any,
		params: any,
		options: HandlerOptions
	) => Promise<HandlerResponse>,
	onServiceSuccess: (
		namespace: TNamespaces,
		service: Service,
		patches: Patch<TNamespaces, TData>[]
	) => void,
	additionalServiceBody:
		| Partial<Record<TNamespaces, ServiceBodyValue[]>>
		| undefined,
	onUpdateDataModel?: (calc: CalculationResponse) => void
): ServiceCaller<TNamespaces, TData> {
	function callService(
		service: NamespaceService<TNamespaces>,
		options: HandlerOptions | undefined,
		calculation: Partial<CalculatorState<TNamespaces, TData>> | undefined,
		urlParams: { [key: string]: string } | undefined,
		payload: HandlerOptions
	) {
		const namespaces =
			service.includeEntireForm && calculation?.formValues
				? (Object.keys(calculation.formValues) as TNamespaces[])
				: [service.namespace];

		// LockedElements field must be on body root (see DynamicCalculatorViewInputModel.vb)
		// this should probably be refactored
		const currentNamespaceStaticAdditionalFields = additionalServiceBody?.[
			service.namespace
		]?.reduce((acc, x) => {
			if (isBodyStaticValue(x) && x.name === "LockedElements") {
				acc[x.name] = x.value;
			}
			return acc;
		}, {});

		const additionalSubmitValues =
			(service.includeEntireForm &&
				(calculation?.formValues?.["AdditionalSubmitValues"] ??
					calculation?.initialFormValues?.["AdditionalSubmitValues"])) ||
			{};

		const params = calculationToParams(
			calculation,
			service.parameterList,
			service.namespace
		);
		let body: any = null;

		const method = isMethod(service.method)
			? service.method
			: params
			? "GET"
			: "POST";
		if (method === "GET" && params) {
			urlParams = params;
		} else if (method !== "DELETE") {
			body = params || {
				...currentNamespaceStaticAdditionalFields,
				additionalSubmitValues,
				...payload,
				fieldlist: calculationToFieldList(
					calculation,
					additionalServiceBody,
					...namespaces
				)
			};
		}

		return handler(service, body, urlParams, {
			accept: service.accept || "application/json",
			...options,
			method
		});
	}

	function isIframe(): boolean {
		try {
			return window.self !== window.top;
		} catch (e: any) {
			// window.top throws exception if limited access.
			// Assume we're in an iframe if that happens.
			return true;
		}
	}

	const handleCallService = createPromiseHandler(callService);

	return async function serviceCaller(
		service,
		options,
		calculation,
		urlParams,
		payload
	) {
		const throttledCallService = handleCallService({
			throttle: service.callDelay,
			key: `${service.namespace}.${service.name}`
		});
//
		try {
			const response = await throttledCallService(
				service,
				options,
				calculation,
				urlParams,
				payload
			);

			if (!response.ok) return response;

			//if (response.data && response.data.DoPostBack) return location.reload();

			if (
				response.data &&
				response.data._updateDataModel &&
				onUpdateDataModel
			) {
				const calc = response.data.model as CalculationResponse;
				onUpdateDataModel(calc);
			}

			if (response.data && response.data.RedirectExternalURL && !isIframe()) {
				document.location.href = response.data.RedirectExternalURL;
			}

			const patches = createServiceSuccessPatches(service, response);

			onServiceSuccess(service.namespace, service, patches);

			return {
				patches,
				...response,
				// TODO TS2783: safe to remove?
				ok: response.ok ?? true
			};
		} catch (error: any) {
			return {
				ok: false,
				error
			};
		}
	};
}

function createServiceSuccessPatches<TNamespaces extends string>(
	service: NamespaceService<TNamespaces>,
	response: HandlerResponse
): Patch<TNamespaces, any>[] {
	let toUpdate = service.fieldList
		? service.fieldList.map(update => ({ update, overwrite: true }))
		: [];

	toUpdate = service.fieldListWriteIfEmpty
		? toUpdate.concat(
				service.fieldListWriteIfEmpty.map(update => ({
					update,
					overwrite: false
				}))
		  )
		: toUpdate;

	if (service.fillOptionValues) {
		const optionConfigs = service.fillOptionValues.split("|");
		for (const optionConfig of optionConfigs) {
			const [source, targetStr] = optionConfig.split(";");
			const [target, ...path] = (targetStr || source).split(".").reverse();
			const optionTarget = [fieldOptionListVariableName(target), ...path]
				.reverse()
				.join(".");

			toUpdate = toUpdate.concat({
				update: `${targetStr ? source : ""};${optionTarget}`,
				overwrite: true
			});
		}
	}

	let patches = toUpdate
		.map(({ update, overwrite }) => {
			const [source, targetStr, defaultString] = update.trim().split(";");
			// Should mabye rewrite this by removing actual function calls in default value
			// See DR for example of why this is needed: "Make model engine year as dropdowns externally"
			const defaultValue =
				defaultString != null
					? // eslint-disable-next-line no-new-func
					  new Function("result", "return " + defaultString + ";")(
							response.data
					  )
					: null;

			const value = getIn(
				response.data,
				source,
				getIn(response.data, `0.${source}`, defaultValue)
			);

			let targetParts = (targetStr || source).split(".");

			const isFilter = targetParts.includes("OPTIONFILTER");

			targetParts = isFilter
				? targetParts.filter(x => x !== "OPTIONFILTER")
				: targetParts;

			let [target, ns = service.namespace] = targetParts.reverse();

			target = isFilter ? fieldOptionFilterVariableName(target) : target;

			return value != null
				? {
						target,
						namespace: ns as TNamespaces,
						value,
						overwrite,
						changeTrigger: target,
						isResolve: true
				  }
				: null;
		})
		.filter(isNotNull);

	// FIXME: Whatever DR that returns this version of data should be rewritten, look for EvaluateDrServiceCall
	if (response.data && response.data.UpdateFieldsHelper) {
		const updateFields: { ControlId: string; Options: string[] }[] =
			response.data.UpdateFieldsHelper;

		patches = patches.concat(
			updateFields.map(f => {
				const options = f.Options.map(option => {
					const [key, value] = option.split(";");
					return { key, value };
				});
				return {
					target: `options_${f.ControlId}`,
					namespace: service.namespace,
					value: options,
					overwrite: true,
					changeTrigger: "",
					isResolve: true
				};
			})
		);
	}

	patches = patches.concat(
		{
			target: `service_${service.name}_result`,
			namespace: service.namespace,
			value: response.data,
			overwrite: true,
			changeTrigger: `service_${service.name}_result`,
			isResolve: true
		},
		{
			target: servicePendingName(service.namespace, service.name),
			namespace: service.namespace,
			value: 0,
			overwrite: true,
			changeTrigger: servicePendingName(service.namespace, service.name),
			isResolve: true
		}
	);

	return patches;
}
