import React, { useEffect, useState } from "react";
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button,
	useMediaQuery,
	useTheme,
	Box,
	Grid
} from "@material-ui/core";
import { PendingButton, DataTable, DployColumn } from "@ploy-ui/core";
import {
	DployTextField,
	BaseFieldProps,
	DployFormControl,
	DployAutocomplete
} from "@ploy-ui/form-fields";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { FieldProps } from "formik";
import { useService } from "@ploy-lib/calculation";
import { BaseCamelCasedResource } from "@ploy-lib/rest-resources/es/plain/BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useFetcher, useResource } from "@rest-hooks/core";
import {
	AbstractInstanceType,
	SchemaList,
	SimpleResource
} from "@rest-hooks/rest";
import { useStatefulResource } from "@rest-hooks/legacy";
import { ID, LoginResource } from "@ploy-lib/rest-resources";
import { useAppLoad } from "../../appLoadContext";

export const Fulfillment = props => {
	return (
		<RefinancingAndOrFulfillment {...props} financingCode={"FULFILLMENT"} />
	);
};

export const FulfillmentReadOnly = props => {
	return (
		<RefinancingAndOrFulfillment
			{...props}
			readOnly
			financingCode={"FULFILLMENT"}
		/>
	);
};

export const Refinancing = props => {
	return (
		<RefinancingAndOrFulfillment {...props} financingCode={"REFINANCING"} />
	);
};

export const RefinancingReadOnly = props => {
	return (
		<RefinancingAndOrFulfillment
			{...props}
			readOnly
			financingCode={"REFINANCING"}
		/>
	);
};

interface DateOption {
	key: string;
	text: string;
	amount: string;
}

interface RefinancingContract {
	readonly contractId: ID;
	readonly text: string;
	readonly customerName: string;

	// Calculated
	readonly termLength: string;
	readonly dateOptions: DateOption[];

	// Selected DateOption
	readonly dateOption?: string;
	readonly refinancedAmount?: number;
	readonly matureDate?: string;
}

class RefinancingContractResource
	extends BaseCamelCasedResource
	implements RefinancingContract
{
	readonly appInstanceId?: string;
	readonly financingCode?: string;

	readonly contractId: ID = 0;
	readonly text: string = "";
	readonly customerName: string = "";

	// Calculated
	readonly termLength: string = "";
	readonly dateOptions: DateOption[] = [];

	// Selected DateOption
	readonly dateOption?: string;
	readonly refinancedAmount?: number;
	readonly matureDate?: string;

	pk() {
		return `${this.contractId} - ${this.financingCode} - ${this.appInstanceId}`;
	}

	static list<T extends typeof SimpleResource>(this: T) {
		const endpoint = super.list();
		return endpoint.extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>,
			fetch: async ({ financingCode, appInstanceId, ...params }) => {
				const data = await endpoint.fetch({ financingCode, ...params });

				return (
					data?.rows?.map(c => ({ ...c, financingCode, appInstanceId })) ?? []
				);
			}
		});
	}

	static calculate<T extends typeof RefinancingContractResource>(this: T) {
		const endpoint = this.create();
		return endpoint.extend({
			url: (params: any) => endpoint.url({ ...params, action: "Calculate" }),
			fetch: async (
				{ financingCode, appInstanceId },
				body: Readonly<RefinancingContract>
			) => {
				const result: any = await endpoint.fetch(
					{ financingCode, action: "Calculate" },
					{ calculationRow: body }
				);

				return { ...result?.calculationRow, financingCode, appInstanceId };
			}
		});
	}

	static save<T extends typeof RefinancingContractResource>(this: T) {
		const endpoint = this.create();

		return endpoint.extend({
			schema: [this] as SchemaList<Readonly<RefinancingContract>>,
			url: ({ financingCode, appInstanceId }: any) =>
				this.listUrl({ financingCode, appInstanceId, action: "Save" }),
			fetch: async (
				{ financingCode, appInstanceId },
				body: Readonly<RefinancingContract>[]
			) => {
				await endpoint.fetch({ financingCode, action: "Save" }, { rows: body });

				return { ...body, financingCode, appInstanceId };
			}
		});
	}

	static urlRoot = legacyApiResourceUrl("Refinancing/{action}");
}

const commonButtonColors = [
	"inherit",
	"primary",
	"secondary",
	"default"
] as const;
type CommonButtonColors = typeof commonButtonColors[number];

function isButtonColor(color?: string): color is CommonButtonColors {
	return commonButtonColors.includes(color as CommonButtonColors);
}

const commonButtonVariants = ["text", "outlined", "contained"] as const;
type CommonButtonVariants = typeof commonButtonVariants[number];

function isButtonVariant(variant?: string): variant is CommonButtonVariants {
	return commonButtonVariants.includes(variant as CommonButtonVariants);
}

export interface RefinancingProps extends BaseFieldProps, FieldProps {
	readOnly: boolean;
	options: { buttonText: string };
	financingCode: string;
	className?: string;
	color?: string;
	variant?: string;
	fullWidth?: boolean;
	placeholder?: string;
	margin?: string;
}

const RefinancingAndOrFulfillment = (props: RefinancingProps) => {
	const {
		//readOnly = false,
		field: { name },
		options: { buttonText },
		financingCode,
		form: { setFieldValue },
		className,
		color,
		variant,
		fullWidth,
		disabled,
		margin
	} = props;

	const { id: appInstanceId } = useAppLoad();

	const buttonColor = isButtonColor(color) ? color : undefined;
	const buttonVariant = isButtonVariant(variant) ? variant : undefined;

	const firstDateService = useService("Main", "SetFirstDueDate");

	const [open, setOpen] = useState(false);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loadedContracts =
		useResource(
			RefinancingContractResource.list(),
			appInstanceId
				? {
						action: "Load",
						appInstanceId,
						financingCode
				  }
				: null
		) ?? [];

	const saveContracts = useFetcher(RefinancingContractResource.save());

	const [selection, setSelection] = useState<Record<ID, RefinancingContract>>(
		{}
	);
	const [saveError, setSaveError] = useState<string>();

	useEffect(() => {
		setSelection(
			Object.fromEntries(
				loadedContracts.map(c => [c.contractId, c as RefinancingContract]) ?? []
			)
		);
	}, [loadedContracts]);

	const onClose = () => {
		setSelection(
			Object.fromEntries(
				loadedContracts.map(c => [c.contractId, c as RefinancingContract]) ?? []
			)
		);
		setOpen(false);
	};

	const onOpen = () => {
		setOpen(true);
	};

	const onSave = async () => {
		if (!appInstanceId) return;
		try {
			setSaveError(undefined);
			await saveContracts(
				{ financingCode, appInstanceId },
				Object.values(selection),
				[
					[
						RefinancingContractResource.list(),
						{
							action: "Load",
							financingCode,
							appInstanceId
						},
						ids => ids
					]
				]
			);

			await firstDateService?.(undefined, null);
			//form.setFieldValue(name, Number.isNaN(value) ? 1 : value + 1);
			setFieldValue(
				name,
				0 //selectedRows.reduce((sum, item) => sum + item.refinancedAmount, 0)
			);

			setOpen(false);
		} catch (e: any) {
			let message = e.message;
			if (e.response.headers.get("content-type").includes("json")) {
				const data = await e.response.json();
				if (data.ErrorMessage) message = data.ErrorMessage;
			}

			setSaveError(message);
		}
	};

	return (
		<DployFormControl
			margin={margin as any}
			fullWidth={fullWidth}
			className={className}
		>
			<PendingButton
				fullWidth={fullWidth}
				color={buttonColor}
				variant={buttonVariant}
				disabled={disabled}
				size="large"
				onClick={onOpen}
			>
				{buttonText}
			</PendingButton>

			<Dialog
				fullScreen={fullScreen}
				maxWidth="lg"
				open={open}
				onClose={onClose}
				aria-labelledby={`${financingCode}-dialog-title`}
			>
				<DialogTitle id={`${financingCode}-dialog-title`}>
					{buttonText}
				</DialogTitle>

				<DialogContent>
					{props.placeholder && (
						<DialogContentText color="textPrimary">
							{props.placeholder}
						</DialogContentText>
					)}

					{open && (
						<ContractsSelection
							financingCode={financingCode}
							appInstanceId={appInstanceId}
							onSelectionChange={data =>
								setSelection(
									Object.fromEntries(
										data.map(d => [d.contractId, selection[d.contractId] ?? d])
									)
								)
							}
							onDateSelect={(id, data) =>
								setSelection({ ...selection, [id]: data })
							}
							selection={selection}
						/>
					)}
				</DialogContent>

				<DialogActions>
					{saveError && (
						<Box flexGrow={1} ml={2}>
							<Typography color="error">{saveError}</Typography>
						</Box>
					)}

					<Button onClick={onClose} color="primary" variant="contained">
						<FormattedMessage
							id="core.refinancing.close"
							defaultMessage="Close"
						/>
					</Button>
					<PendingButton
						onClick={onSave}
						color="primary"
						variant="contained"
						success={false}
					>
						<FormattedMessage
							id="core.refinancing.save"
							defaultMessage="Save"
						/>
					</PendingButton>
				</DialogActions>
			</Dialog>
		</DployFormControl>
	);
};

interface ContractsSelectionProps {
	financingCode: string;
	appInstanceId?: string;
	onSelectionChange: (data: RefinancingContract[]) => void;
	onDateSelect: (id: ID, data: RefinancingContract) => void;
	selection: Record<ID, RefinancingContract>;
}

function ContractsSelection(props: ContractsSelectionProps) {
	const {
		financingCode,
		appInstanceId,
		onSelectionChange,
		onDateSelect,
		selection
	} = props;

	const { vendor } = useResource(LoginResource.status(), {});

	const [searchValue, setSearchValue] = useState<string>();
	const [topContracts, setTopContracts] = useState<RefinancingContract[]>(
		Object.values(selection)
	);

	const onSearch = (value: string) => {
		setTopContracts(Object.values(selection));
		setSearchValue(value);
	};

	const { data: searchedContracts, loading } = useStatefulResource(
		RefinancingContractResource.list(),
		searchValue != null && appInstanceId
			? {
					action: "Search",
					searchString: searchValue,
					vendorId: vendor?.id,
					appInstanceId,
					financingCode
			  }
			: null
	);

	const calculate = useFetcher(RefinancingContractResource.calculate());
	const contracts = topContracts.concat(
		searchedContracts?.filter(
			s => !topContracts?.some(t => t.contractId === s.contractId)
		) ?? []
	);

	const columns: DployColumn<RefinancingContract>[] = [
		{
			field: "contractId",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.contractId.header"
					defaultMessage="Contract number"
				/>
			)
		},
		{
			field: "text",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.tekst.header"
					defaultMessage="Text."
				/>
			)
		},
		{
			field: "customerName",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.customerName.header"
					defaultMessage="Customer"
				/>
			)
		},
		{
			field: "matureDate",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.matureDate.header"
					defaultMessage="Maturity Date."
				/>
			),
			render: rowData =>
				selection[rowData.contractId] != null ? (
					<Typography>{selection[rowData.contractId].matureDate}</Typography>
				) : null
		},
		{
			field: "termLength",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.termLength.header"
					defaultMessage="Interval"
				/>
			),
			render: rowData =>
				selection[rowData.contractId] != null ? (
					<Typography>{rowData.termLength}</Typography>
				) : null
		},
		{
			field: "dateOptions",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.dateOptions.header"
					defaultMessage="Calculate from"
				/>
			),
			render: rowData => {
				const selected = selection[rowData.contractId] != null;
				return (
					<DployAutocomplete
						style={{ width: 190 }}
						disabled={(rowData.dateOptions?.length ?? 0) <= 0}
						items={selected ? rowData.dateOptions : []}
						getItemLabel={item => item.text}
						value={
							selected
								? rowData.dateOptions?.find(
										item =>
											item.key === selection[rowData.contractId].dateOption
								  )
								: null
						}
						searchable={false}
						onChange={(e, item) =>
							onDateSelect(rowData.contractId, {
								...rowData,
								dateOption: item?.key,
								matureDate: item?.key,
								refinancedAmount: item
									? Number(item.amount.replace(",", "."))
									: undefined
							})
						}
					/>
				);
			}
		},
		{
			field: "refinancedAmount",
			title: (
				<FormattedMessage
					id="dploy.fulfillmentSection.refinancedAmount.header"
					defaultMessage="Outstanding debt."
				/>
			),
			render: rowData =>
				selection[rowData.contractId]?.refinancedAmount ? (
					<FormattedNumber
						value={selection[rowData.contractId].refinancedAmount ?? 0}
						format="currency_full"
					/>
				) : null
		}
	];

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<SearchField onSearch={onSearch} searching={loading} />
			</Grid>
			<Grid item>
				<DataTable
					columns={columns}
					data={contracts.map(
						c =>
							({
								...c,
								tableData: { checked: selection[c.contractId] != null }
							} as RefinancingContract)
					)}
					listCellsBreakpoint="sm"
					options={{
						search: false,
						grouping: false,
						showEmptyDataSourceMessage: false,
						emptyRowsWhenPaging: false,
						paging: true,
						header: true,
						toolbar: false,
						selection: true,
						showSelectAllCheckbox: false
					}}
					onSelectionChange={async (data, row) => {
						onSelectionChange(data);
						if (
							appInstanceId &&
							row &&
							data.includes(row) &&
							(row.dateOptions?.length ?? 0) <= 0
						)
							await calculate({ financingCode, appInstanceId }, row);
					}}
				/>
			</Grid>
		</Grid>
	);
}

function SearchField(props: {
	onSearch: (value: string) => void;
	searching?: boolean;
}) {
	const { onSearch, searching } = props;

	const [searchValue, setSearchValue] = useState("");

	return (
		<div>
			<DployTextField
				variant="outlined"
				value={searchValue}
				onChange={e => {
					setSearchValue(e.target.value);
					return e;
				}}
				onKeyDown={e => {
					if (e.key === "Enter") onSearch(searchValue);
				}}
			/>
			<PendingButton
				pending={searching}
				size="large"
				variant="outlined"
				onClick={() => onSearch(searchValue)}
				success={false}
			>
				<FormattedMessage
					id="core.refinancing.search"
					defaultMessage="Search"
				/>
			</PendingButton>
		</div>
	);
}
