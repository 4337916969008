import { Typography, Card, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface WholesaleOverviewSummaryProps {
	agreementLimit?: number;
	totalCurrent?: number;
	totalPayout?: number;
	totalRedeem?: number;
	remainingLimit?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
	cardWrapper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	summaryItemContainer: {
		padding: theme.spacing(4)
	},
	boldFontWeight: {
		fontWeight: "bold"
	},
	thinFontWeight: {
		color: "grey"
	}
}));

const WholesaleOverviewSummary = (props: WholesaleOverviewSummaryProps) => {
	const {
		agreementLimit,
		totalCurrent,
		totalPayout,
		totalRedeem,
		remainingLimit
	} = props;

	const intl = useIntl();
	const classes = useStyles();

	const formatNumber = (num: number | undefined) => {
		return (
			intl.formatNumber(num || 0, {
				maximumFractionDigits: 0
			}) + ",-"
		);
	};

	return (
		<Card elevation={2} className={classes.cardWrapper}>
			<Grid container justifyContent="center">
				<Grid item className={classes.summaryItemContainer}>
					<TextItem variant="h6" bold>
						<FormattedMessage
							id="vendor.wholesale.available-limit"
							description="Label for available limit"
							defaultMessage="Available limit"
						/>
					</TextItem>
					<TextItem variant="h4" bold>
						{formatNumber(remainingLimit)}
					</TextItem>
					<TextItem variant="subtitle1">
						<FormattedMessage
							id="vendor.wholesale.available-limit-total"
							description="Label for available total"
							defaultMessage="out of {agreementLimit}"
							values={{ agreementLimit: formatNumber(agreementLimit) }}
						/>
					</TextItem>
				</Grid>
				<Grid item className={classes.summaryItemContainer}>
					<TextItem variant="h6" bold>
						<FormattedMessage
							id="vendor.wholesale.used-limit"
							description="Label for used limit"
							defaultMessage="Used limit"
						/>
					</TextItem>
					<TextItem variant="h4" bold>
						{formatNumber(totalCurrent)}
					</TextItem>
					<TextItem variant="subtitle1">
						<FormattedMessage
							id="vendor.wholesale.used-limit-max-possible"
							description="Label for used limit maximum possible"
							defaultMessage="out of {agreementLimit}"
							values={{ agreementLimit: formatNumber(agreementLimit) }}
						/>
					</TextItem>
				</Grid>
				<Grid item className={classes.summaryItemContainer}>
					<TextItem variant="h6" bold>
						<FormattedMessage
							id="vendor.wholesale.to-be-payed"
							description="Label for amount to be payed"
							defaultMessage="To be payed"
						/>
					</TextItem>
					<TextItem variant="h4" bold>
						{formatNumber(totalPayout)}
					</TextItem>
				</Grid>
				<Grid item className={classes.summaryItemContainer}>
					<TextItem variant="h6" bold>
						<FormattedMessage
							id="vendor.wholesale.to-be-fulfilled"
							description="Label for amount to be fulfilled"
							defaultMessage="To be fulfilled"
						/>
					</TextItem>
					<TextItem variant="h4" bold>
						{formatNumber(totalRedeem)}
					</TextItem>
				</Grid>
			</Grid>
		</Card>
	);
};

const TextItem = ({
	children,
	variant = "body1",
	bold = false,
	gutter = false
}: {
	children: React.ReactNode;
	variant: "body1" | "h4" | "h6" | "subtitle1";
	bold?: boolean;
	gutter?: boolean;
}) => {
	const classes = useStyles();
	return (
		<Typography
			gutterBottom={gutter}
			variant={variant}
			className={bold ? classes.boldFontWeight : classes.thinFontWeight}
		>
			{children}
		</Typography>
	);
};

export { WholesaleOverviewSummary };
