import React, { memo, useCallback, ChangeEvent } from "react";
import {
	FormHelperText,
	Checkbox,
	makeStyles,
	Tooltip,
	CheckboxProps,
	FormLabel,
	Button
} from "@material-ui/core";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormControlLabel, {
	FormControlLabelProps
} from "@material-ui/core/FormControlLabel";
import { FieldProps as FormikFieldProps } from "formik";
import { BaseFieldProps } from "./types";
import { Overwrite } from "@material-ui/types";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FunctionsIcon from "@material-ui/icons/Functions";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { identityRecordOfFieldEditorOptions, getFieldError } from "./utils";
import { ErrorDisplay } from "@ploy-lib/types";

const checkboxVariants = [
	"standard",
	"chevron",
	"star",
	"functions",
	"calculator",
	"button"
] as const;
const colors = ["primary", "secondary", "default"] as const;

type ColorType = typeof colors[number];

export interface CheckboxFieldProps
	extends FormikFieldProps,
		Overwrite<
			Omit<FormControlProps, "variant">,
			Omit<CheckboxProps, "label" | "form" | "onKeyPress">
		>,
		BaseFieldProps {
	variant?: typeof checkboxVariants[number];
	tooltipTitle?: React.ReactNode;
	color?: ColorType;
	literal?: boolean;
}

const useStyles = makeStyles(
	theme => ({
		root: {
			width: "fit-content"
		},
		formControlLabel: {}
	}),
	{ name: "CheckboxField" }
);

export const useFieldToCheckbox = <
	T extends FormikFieldProps & {
		disabled?: boolean;
		helperText?: React.ReactNode;
		errorDisplay?: ErrorDisplay;
		onChange?: (
			event: React.ChangeEvent<HTMLInputElement>,
			checked: boolean
		) => void;
	}
>(
	props: T
) => {
	const { field, form, disabled, errorDisplay, onChange, ...rest } = props;
	const { value } = field;
	const { errors, touched, isSubmitting, setFieldValue } = form;

	// "1" / "0" -> true / false
	const checked =
		value === "1" || value === 1 || value === "checked" || value === true;

	const onChangeFunc = useCallback(
		(e, checked) => {
			e.target.value = checked;
			if (onChange) onChange(e, checked);
			else setFieldValue(field.name, checked);
		},
		[field.name, onChange, setFieldValue]
	);

	return {
		disabled: disabled != null ? disabled : isSubmitting,
		...rest,
		...field,
		...getFieldError(errors, touched, field.name, errorDisplay),
		checked,
		value: checked ? "checked" : "",
		onChange: onChangeFunc
	};
};

const colorRemapper = (c?: ColorType) => (c === "default" ? "inherit" : c);

export function CheckboxWithLabelField(props: CheckboxFieldProps) {
	const {
		items,
		getItemValue,
		getItemLabel,
		valueKey,
		labelKey,
		getItemSuggestions,
		onSelectItem,
		options,
		manual,
		horizontal,
		multiple,
		pending,
		emptyEqualsZero,
		formatString,
		onKeyPress,
		italicText,
		boldText,
		label,
		modalText,
		textAlign,
		clearable,
		literal,
		...rest
	} = props;

	const checkboxProps = useFieldToCheckbox(rest);

	const hasLabel =
		typeof label === "string" ? label.trim() !== "" : Boolean(label);

	return (
		<DployCheckboxWithLabelField
			tooltipTitle={hasLabel ? undefined : options?.buttonText}
			{...checkboxProps}
			label={hasLabel ? label : undefined}
		/>
	);
}

export interface DployCheckboxFieldProps
	extends Overwrite<Omit<FormControlProps, "variant">, CheckboxProps> {
	variant?: typeof checkboxVariants[number];
	color?: ColorType;
	helperText?: React.ReactNode;
	tooltipTitle?: React.ReactNode;
	label?: FormControlLabelProps["label"];
}

function DployCheckboxWithLabelFieldInternal(props: DployCheckboxFieldProps) {
	const {
		label,
		helperText,
		error,
		fullWidth,
		classes,
		disabled,
		variant,
		color: _,
		className,
		tooltipTitle,
		...checkboxProps
	} = props;

	const internalClasses = useStyles();

	let color = props.color;
	let icon: React.ReactNode | undefined = undefined;
	let checkedIcon: React.ReactNode | undefined = undefined;

	switch (variant) {
		case "chevron":
			icon = (
				<KeyboardArrowDownIcon color={colorRemapper(color) || "primary"} />
			);
			checkedIcon = (
				<KeyboardArrowUpIcon color={colorRemapper(color) || "primary"} />
			);
			color = undefined;
			break;
		case "star":
			icon = <StarBorderIcon />;
			checkedIcon = <StarIcon />;
			break;
		case "functions":
			icon = <FunctionsIcon />;
			checkedIcon = <FunctionsIcon />;
			break;
		case "calculator":
			icon = (
				<i className="fa fa-calculator" style={{ fontSize: "1.5rem" }}></i>
			);
			break;
		case "button":
			break;
	}

	const selected = Boolean(checkboxProps.value);

	const controlElement =
		variant === "button" || variant === "calculator" ? (
			<Button
				size="large"
				color={color}
				data-option-label={label}
				data-option-value="checked"
				variant={
					variant === "calculator"
						? "text"
						: selected
						? "outlined"
						: "contained"
				}
				onClick={() => {
					const checked = !selected;
					checkboxProps.onChange?.(
						{
							target: {
								value: checked ? "checked" : "",
								checked,
								name: checkboxProps.name,
								id: checkboxProps.id
							}
						} as ChangeEvent<HTMLInputElement>,
						checked
					);
				}}
			>
				{icon ?? label ?? tooltipTitle}
			</Button>
		) : label ? (
			<FormControlLabel
				control={
					<Checkbox color={color} icon={icon} checkedIcon={checkedIcon} />
				}
				label={label}
				disabled={disabled}
				className={internalClasses.formControlLabel}
				{...(checkboxProps as Omit<FormControlLabelProps, "control" | "label">)}
			/>
		) : (
			<Checkbox
				{...checkboxProps}
				color={color}
				icon={icon}
				checkedIcon={checkedIcon}
				disabled={disabled}
			/>
		);

	return (
		<FormControl
			variant="standard"
			className={className}
			component={"fieldset" as "div"}
			error={error}
			disabled={disabled}
			fullWidth={fullWidth}
			classes={{ ...classes, root: internalClasses.root }}
		>
			{/* nbsp used to align the checkmark with previous linked field */}
			{label && <FormLabel>&nbsp;</FormLabel>}
			{tooltipTitle ? (
				<Tooltip title={tooltipTitle}>{controlElement}</Tooltip>
			) : (
				controlElement
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}

DployCheckboxWithLabelFieldInternal.displayName = "DployCheckboxWithLabelField";

export const DployCheckboxWithLabelField = memo(
	DployCheckboxWithLabelFieldInternal
);

export const EditorCheckboxFields = identityRecordOfFieldEditorOptions({
	CheckboxWithLabelField: {
		editableOptions: {
			variant: checkboxVariants,
			color: colors
		}
	}
});
