import React, { useState } from "react";
import { SectionProps } from "../Section";
import {
	Grid,
	TextField,
	Typography,
	MenuItem,
	FormControlLabel,
	Switch
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { injectIntl, WrappedComponentProps } from "react-intl";

const useStyles = makeStyles(theme => ({
	card: {
		backgroundColor: "#fff",
		borderRadius: 8,
		paddingTop: 8,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 8,
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	header: {
		paddingLeft: 12,
		paddingRight: 12,
		paddingBottom: 6,
		marginBottom: 24,
		alignItems: "center",
		display: "flex"
	}
}));

addRegisteredSectionLayout({
	name: "SectionsWithSteppers",
	displayName: "SectionsWithSteppers",
	settings: {}
});

enum LoanTypeEnum {
	car = "Car loan",
	student = "Student loan"
}

interface Loan {
	cash: string;
	bank: string;
	type: LoanTypeEnum;
	account: string;
}

const SectionsWithSteppers = injectIntl(
	(props: SectionProps & WrappedComponentProps) => {
		const { className } = props;
		const classes = useStyles();

		const loans: Loan[] = [
			{
				cash: "500000",
				bank: "Lånekassen",
				type: LoanTypeEnum.student,
				account: "2222 5555"
			},
			{
				cash: "300000",
				bank: "Santander Consumer Bank",
				type: LoanTypeEnum.car,
				account: "4695 0920"
			}
		];

		return (
			<Grid
				container
				spacing={3}
				className={clsx(className, classes.card)}
				direction="column"
			>
				{loans.map((l, i) => (
					<LoanItem key={l.account} loan={l} idx={i} />
				))}
			</Grid>
		);
	}
);

interface LoanItemProps {
	loan: Loan;
	idx: number;
}

const LoanItem = (props: LoanItemProps) => {
	const { loan, idx } = props;

	const [cashVal, setCashVal] = useState(loan.cash);
	const [typeVal, setTypeVal] = useState(loan.type.toString());
	const [refiVal, setRefiVal] = useState(true);

	return (
		<Grid item container direction="row">
			<Grid
				item
				container
				style={{
					borderRadius: "50%",
					backgroundColor: "#ABDCBC",
					width: 48,
					height: 48,
					alignItems: "center",
					justifyContent: "center",
					marginTop: 20,
					marginRight: 36
				}}
			>
				<Typography>{idx + 1}</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="column">
					<Grid item>
						<Typography variant="caption">
							{loan.bank} - Account **
							{loan.account.substring(
								loan.account.length - 4,
								loan.account.length
							)}
						</Typography>
					</Grid>
					<Grid item>
						<TextField
							placeholder="Remaining loan amount"
							variant="outlined"
							value={cashVal}
							onChange={e => setCashVal(e.target.value)}
						/>
					</Grid>
					<Grid item>
						<Typography variant="caption">Loan type</Typography>
					</Grid>
					<Grid item xs={4}>
						<TextField
							select
							variant="outlined"
							value={typeVal}
							onChange={e => setTypeVal(e.target.value)}
							style={{ width: 223 }}
						>
							{Object.values(LoanTypeEnum).map(x => (
								<MenuItem value={x} key={x}>
									{x}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<Switch
									checked={!!refiVal}
									onChange={e => setRefiVal(e.target.checked)}
								/>
							}
							label="This loan should be fulfilled upon refinancing"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

SectionsWithSteppers.displayName = "SectionsWithSteppers";

export { SectionsWithSteppers };
