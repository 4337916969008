import { createContext, useContext } from "react";

export interface FormTemplateHook {
	formTemplateId?: number;
	// formValidators?
	// mobileForm?
}

export interface FormTemplateContext extends FormTemplateHook {
	formTemplateId?: number;
}

const defaultContext: FormTemplateContext = {
	formTemplateId: undefined
};

export const templateFormContext =
	createContext<FormTemplateContext>(defaultContext);
templateFormContext.displayName = "TemplateForm";

export const useFormTemplate = () => useContext(templateFormContext);

export const FormTemplateProvider = templateFormContext.Provider;
export const FormTemplateConsumer = templateFormContext.Consumer;
