import { Field } from "./useFieldData";

export function fieldDef(
	namespaceOrField?: Field["namespace"] | Field,
	fieldName?: Field["name"]
): Field | undefined {
	const field = namespaceOrField as Field;
	if (field?.name) return field;

	const namespace =
		typeof namespaceOrField === "string" ? namespaceOrField : undefined;

	if (fieldName) return { namespace, name: fieldName };

	return undefined;
}
