import { useState, Suspense } from "react";
import marked from "@ploy-lib/purify-marked";
import { defineMessages, FormattedMessage } from "react-intl";
import ContentBox from "../../ContentBox";
import ContentListItem from "../../ContentListItem";
import DetailsBox from "../../DetailsBox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import WarningIcon from "@material-ui/icons/Warning";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MailIcon from "@material-ui/icons/Mail";
import ChatIcon from "@material-ui/icons/Chat";

import { DividerItems } from "@ploy-ui/core";
import { DocumentStatus, ApplicationStatus, ID } from "@ploy-lib/types";
import { DocumentType } from "@ploy-lib/rest-resources";
import { DashboardContentProps } from "../../types";
import { TaskFilter, TaskListItem, useTasks } from "../Todos";
import { Divider, List, Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHeight } from "../../hooks/useHeight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useNewsListResources } from "./useNewsListResources";

const messages = defineMessages({
	awaitingRegAppPrimaryMessage: {
		id: "dploy.dashboard.newscontent.AwaitingOwnerRegistration.primary",
		defaultMessage: "Avventer eierregistrering"
	},
	awaitingRegAppSecondaryMessage: {
		id: "dploy.dashboard.newscontent.AwaitingOwnerRegistration.secondary",
		defaultMessage:
			"Det er {amount, number} kjøretøy som ikke har blitt omregistrert."
	},
	fullySignedIncompletePrimaryMessage: {
		id: "dploy.dashboard.newscontent.FullySignedIncomplete.primary",
		defaultMessage: "Ferdig signert med mangler"
	},
	fullySignedIncompleteSecondaryMessage: {
		id: "dploy.dashboard.newscontent.FullySignedIncomplete.secondary",
		defaultMessage:
			"{amount, plural,\none {# avtale}\nother {# avtaler}\n  } er ferdig signert, men mangler informasjon."
	},
	missingRegNumberPrimaryMessage: {
		id: "dploy.dashboard.newscontent.MissingRegistrationNumber.primary",
		defaultMessage: "{amount, number} kjøretøy avventer registreringsnummer"
	},
	missingRegNumberSecondaryMessage: {
		id: "dploy.dashboard.newscontent.MissingRegistrationNumber.secondary",
		defaultMessage: "Pantedokument er ikke elektronisk signert av kunde."
	}
});

const newsTasks: TaskFilter[] = [
	{
		id: 1,
		name: "missing-reg-number-apps",
		filter: {
			allTasksSignedExceptType: [DocumentType.Security],
			docStatus: [DocumentStatus.PartiallySigned],
			appStatus: [
				ApplicationStatus.ManuallyApproved,
				ApplicationStatus.ApprovedWithConditions
			],
			salepersonId: "current"
		},
		primaryMessage: messages.missingRegNumberPrimaryMessage.id,
		secondaryMessage: messages.missingRegNumberSecondaryMessage.id,
		icon: <ScheduleIcon />
	},
	{
		id: 2,
		name: "awaiting-reg-app",
		filter: {
			docStatus: [DocumentStatus.AwaitingOwnerRegistration],
			appStatus: [
				ApplicationStatus.ManuallyApproved,
				ApplicationStatus.ApprovedWithConditions
			],
			salepersonId: "current"
		},
		primaryMessage: messages.awaitingRegAppPrimaryMessage.id,
		secondaryMessage: messages.awaitingRegAppSecondaryMessage.id,
		icon: <ScheduleIcon />
	},
	{
		id: 3,
		name: "incomplete-apps",
		filter: {
			docStatus: [DocumentStatus.FullySignedIncomplete],
			appStatus: [
				ApplicationStatus.ManuallyApproved,
				ApplicationStatus.ApprovedWithConditions
			],
			salepersonId: "current"
		},
		primaryMessage: messages.fullySignedIncompletePrimaryMessage.id,
		secondaryMessage: messages.missingRegNumberSecondaryMessage.id,
		icon: <ScheduleIcon />
	}
];

export interface NewsProps extends DashboardContentProps {
	showInfoMessage?: boolean;
	showTasks?: boolean;
	emptyMessage?: string;
}

function News(props: NewsProps) {
	const {
		id,
		showInfoMessage,
		showTasks,
		boxProps,
		applicationsSearchPath,
		setApplicationsSearchParams,
		emptyMessage,
		onChangeHeight,
		onResetHeight,
		placeholder
	} = props;

	const classes = useStyles();

	const [detailsId, handleClick] = useState<ID>();
	const handleBack = () => handleClick(undefined);

	const header = (
		<FormattedMessage
			id="dealer.dashboard.newslist.header"
			defaultMessage="Messages from {name}"
			description="Header for newslist on dashboard. Params: name"
			values={{
				name: (
					<FormattedMessage
						id="tenant.name"
						defaultMessage="creditor"
						description="The name of the current tenant (financial institution)"
					/>
				)
			}}
		/>
	);

	const [{ info, newsList }, { error }] = useNewsListResources({
		showInfoMessage,
		placeholder
	});

	const [listRef] = useHeight<HTMLUListElement>(500);
	const overflowHeight =
		(listRef.current?.scrollHeight ?? 0) - (listRef.current?.offsetHeight ?? 0);

	const detailsItem =
		detailsId && newsList?.find(x => x.newsItemId === detailsId);

	const tasks = useTasks(showTasks ? newsTasks : [], placeholder);

	let detailsContent: JSX.Element | undefined = undefined;

	if (detailsId === "info" && info != null) {
		detailsContent = (
			<article className={classes.article}>
				<Typography variant="subtitle1" className={classes.detailsHeader}>
					{info.header}
				</Typography>
				<Typography>{info.message}</Typography>
			</article>
		);
	} else if (detailsItem) {
		detailsContent = (
			<article className={classes.article}>
				<Typography variant="subtitle1" className={classes.detailsHeader}>
					{detailsItem.newsHeader}
				</Typography>
				<Typography gutterBottom>{detailsItem.newsIngress}</Typography>
				{detailsItem.newsContent && (
					<Typography
						variant="body2"
						component="div"
						dangerouslySetInnerHTML={{
							__html: marked(detailsItem.newsContent)
						}}
					/>
				)}
			</article>
		);
	}

	if (detailsContent) {
		return (
			<DetailsBox
				avatar={<ChatIcon />}
				{...boxProps}
				header={boxProps?.header || header}
				error={error}
				onClick={handleBack}
			>
				{detailsContent}
			</DetailsBox>
		);
	}

	const animation = !placeholder && undefined;

	return (
		<ContentBox
			avatar={<ChatIcon />}
			{...boxProps}
			header={boxProps?.header || header}
			scrollGradient
			error={error}
			actions={
				<>
					{onResetHeight ? (
						<Button
							color="primary"
							onClick={id ? () => onResetHeight(id) : undefined}
							endIcon={<KeyboardArrowUpIcon />}
						>
							<FormattedMessage
								id="dploy.dashboard.newslist.show-fewer"
								description="Show fewer messages"
								defaultMessage="Se færre meldinger"
							/>
						</Button>
					) : overflowHeight > 0 ? (
						<Button
							color="primary"
							onClick={
								id
									? () =>
											onChangeHeight?.(id, {
												type: "relative",
												value: overflowHeight
											})
									: undefined
							}
							endIcon={<KeyboardArrowDownIcon />}
							disabled={!onChangeHeight}
						>
							<FormattedMessage
								id="dploy.dashboard.newslist.show-more"
								description="Show more messages"
								defaultMessage="Se flere meldinger"
							/>
						</Button>
					) : null}
				</>
			}
		>
			<List ref={listRef} dense className={classes.list}>
				{(info?.message || info?.header) && (
					<>
						<ContentListItem
							key="info"
							icon={<WarningIcon />}
							primary={info?.header}
							secondary={info?.message}
							button
							onClick={() => handleClick("info")}
						/>
						<Divider />
					</>
				)}

				<Suspense
					fallback={
						<>
							<ContentListItem
								iconClass={tasks[0]?.iconClass}
								icon={tasks[0]?.icon}
								primary={
									<Skeleton animation={animation} variant="text" width="60%" />
								}
								secondary={
									<Skeleton animation={animation} variant="text" width="90%" />
								}
								hideChevron
							/>
							<Divider />
						</>
					}
				>
					{tasks.map(task => (
						<TaskListItem
							{...task}
							key={task.name}
							applicationsSearchPath={applicationsSearchPath}
							setApplicationsSearchParams={setApplicationsSearchParams}
						/>
					))}
				</Suspense>

				{newsList && newsList.length === 0 && (
					<ContentListItem
						secondary={
							emptyMessage ?? (
								<FormattedMessage
									id="dploy.dashboard.newslist.empty"
									defaultMessage="Ingen meldinger"
								/>
							)
						}
						hideChevron
					/>
				)}
				<DividerItems>
					{newsList?.map(({ newsHeader, newsIngress, newsItemId }) => (
						<ContentListItem
							key={newsItemId}
							icon={<MailIcon />}
							primary={newsHeader?.substring(0, 140)}
							secondary={newsIngress?.substring(0, 140)}
							button
							onClick={() => handleClick(newsItemId)}
						/>
					)) ?? (
						<ContentListItem
							icon={<MailIcon />}
							primary={
								<Skeleton animation={animation} variant="text" width="60%" />
							}
							secondary={
								<Skeleton animation={animation} variant="text" width="90%" />
							}
							hideChevron
						/>
					)}
				</DividerItems>
			</List>
		</ContentBox>
	);
}

News.displayName = "DployDashboardNews";

const useStyles = makeStyles(
	{
		detailsHeader: {
			fontWeight: "bold"
		},
		list: {
			height: "100%"
		},
		article: {
			overflow: "auto"
		}
	},
	{ name: "DashboardNewsList" }
);

export default News;
