import React, { memo } from "react";
import {
	Typography,
	makeStyles,
	FormHelperText,
	Grid
} from "@material-ui/core";
import { InputFieldProps } from "../../types";
import clsx from "clsx";
import { FormattedNumber } from "react-intl";
import {
	identityRecordOfFieldEditorOptions,
	HasItems,
	FieldEditorOptions,
	getFieldError,
	defaultGetItemLabel,
	defaultGetItemValue
} from "@ploy-ui/form-fields";
import merge from "lodash/merge";

const useStyles = makeStyles(
	theme => ({
		wrapper: {
			width: "100%"
		},
		root: {
			display: "flex",
			alignItems: "flex-start",
			width: "100%",
			margin: 0
		},
		label: {
			flexGrow: 0,
			marginRight: theme.spacing(1),
			whiteSpace: "pre-line"
		},
		value: {
			flexGrow: 1,
			textAlign: ({ textAlign = "right" }: InputFieldProps) => textAlign,
			whiteSpace: "pre-line",
			marginLeft: ({ textAlign = "right" }: InputFieldProps) =>
				textAlign === "left" ? 5 : 0
		},
		suffix: {
			marginLeft: theme.spacing(1),
			flexGrow: 0,
			width: 20,
			whiteSpace: "nowrap"
		},
		marginNormal: {
			marginTop: 0,
			marginBottom: theme.spacing(1)
		},
		marginDense: {
			marginTop: 8,
			marginBottom: 4
		},
		center: { textAlign: "center" },
		variantContained: {
			padding: theme.spacing(1),
			background: theme.palette.grey[300],
			"& > $value": {
				fontSize: 12,
				textAlign: "inherit" as "inherit"
			}
		},
		variantInformation: {
			borderLeft: "6px solid " + theme.palette.primary.main,
			paddingLeft: theme.spacing(1),
			fontSize: 16
		},
		variantEmphasized: {
			// borderBottom: "1px solid #efefef",
			"& > $value": {
				fontWeight: 700
			}
		},
		variantPlain: {
			marginBottom: 0,
			alignItems: "center"
		},
		variantNormal: {
			"& > $value": {
				fontSize: 12,
				textAlign: "inherit" as "inherit"
			}
		},
		variantBig: {},
		bigVariantValue: {
			fontSize: "2.5rem"
		}
	}),
	{ name: "DployFormLiteral" }
);

const literalVariants = [
	"big",
	"plain",
	"emphasized",
	"contained",
	"information",
	"normal",
	"none"
] as const;

export type LiteralProps = InputFieldProps & {
	literalVariant?: typeof literalVariants[number];
	error?: boolean;
	helperText?: string;
} & HasItems<any>;

export type LiteralbaseProps = Omit<
	LiteralProps,
	"form" | "field" | "options"
> &
	LiteralProps["options"];

export function getLiteralBaseProps(props: LiteralProps): LiteralbaseProps {
	const {
		options,
		form: { errors, touched },
		field,
		helperText,
		errorDisplay,
		...rest
	} = props;

	return {
		...rest,
		...options,
		...getFieldError(errors, touched, field.name, errorDisplay)
	};
}

export function LiteralBaseInternal(
	props: LiteralbaseProps & {
		children: React.ReactNode;
	}
) {
	const {
		className,
		suffix,
		prefix,
		margin,
		children,
		error,
		helperText,
		literalVariant = "emphasized"
	} = props;

	const classes = useStyles(props);

	let label = props.label && props.label.trim();
	let hideValue = false;
	if (!label && typeof children === "string") {
		label = children.toString().trim();
		hideValue = true;
	}

	const styleParameters = merge(
		{},
		props.boldText ? { fontWeight: "bold" as const } : {},
		props.italicText ? { fontStyle: "italic" as const } : {}
	);

	if (literalVariant === "big") {
		return (
			<Grid container xs={12} className={clsx(classes.wrapper)}>
				<Grid
					container
					className={clsx(className, classes.root, classes.center, {
						[classes.variantBig]: literalVariant === "big",
						[classes.marginNormal]: margin === "normal",
						[classes.marginDense]: margin === "dense"
					})}
				>
					{label && (
						<Grid item xs={12}>
							<Typography className={classes.label} style={styleParameters}>
								{label}
							</Typography>
						</Grid>
					)}
					{!hideValue && (
						<Grid item xs={12}>
							<Typography
								variant="h3"
								className={clsx(classes.value, classes.bigVariantValue)}
								style={styleParameters}
							>
								{children} {suffix || prefix}
							</Typography>
						</Grid>
					)}
				</Grid>
				{helperText && (
					<Grid item xs={12}>
						<FormHelperText className={classes.center} error={error}>
							{helperText}
						</FormHelperText>
					</Grid>
				)}
			</Grid>
		);
	}
	return (
		<div className={clsx(classes.wrapper)}>
			<Typography
				paragraph
				className={clsx(className, classes.root, {
					[classes.variantEmphasized]: literalVariant === "emphasized",
					[classes.variantPlain]: literalVariant === "plain",
					[classes.variantContained]: literalVariant === "contained",
					[classes.variantInformation]: literalVariant === "information",
					[classes.variantNormal]: literalVariant === "normal",
					[classes.marginNormal]: margin === "normal",
					[classes.marginDense]: margin === "dense"
				})}
			>
				{label && (
					<span className={classes.label} style={styleParameters}>
						{label}
					</span>
				)}
				{!hideValue && (
					<span className={classes.value} style={styleParameters}>
						{children}
					</span>
				)}
				<span className={classes.suffix} style={styleParameters}>
					{suffix || prefix}
				</span>
			</Typography>
			{helperText && (
				<FormHelperText error={error}>{helperText}</FormHelperText>
			)}
		</div>
	);
}

LiteralBaseInternal.displayName = "LiteralBase";
export const LiteralBase = memo(LiteralBaseInternal);

export const baseEditableOptions: FieldEditorOptions["editableOptions"] = {
	variant: literalVariants
};

export const TextLiteral = (props: LiteralProps) => {
	const {
		items = [],
		getItemValue = defaultGetItemValue,
		getItemLabel = defaultGetItemLabel,
		multiple
	} = props;

	let value = props.field.value;

	if (multiple) {
		value =
			value
				?.map(
					(v: any) => getItemLabel(items?.find(x => getItemValue(x) === v)) || v
				)
				.join("\n") || (Number(value) === 0 ? "" : value);
	} else {
		value =
			getItemLabel(items?.find(x => getItemValue(x) === value)) ||
			(Number(value) === 0 ? "" : value);
	}

	return (
		<LiteralBase {...getLiteralBaseProps(props)}>
			{typeof value === "number" ? (
				<FormattedNumber maximumFractionDigits={0} value={value} />
			) : (
				value
			)}
		</LiteralBase>
	);
};

TextLiteral.displayName = "TextLiteral";

export const EditorTextLiterals = identityRecordOfFieldEditorOptions({
	TextLiteral: {
		editableOptions: {
			...baseEditableOptions
		}
	}
});
