import React from "react";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { FormattedMessage } from "react-intl";
import { SectionProps } from "../Section";
import Typography from "@material-ui/core/Typography";

addRegisteredSectionLayout({
	name: "ForbiddenPageSection",
	displayName: "ForbiddenPageSection",
	settings: {}
});

const ForbiddenPageSection = (props: SectionProps) => (
	<Typography variant="h4" className={props.className} onClick={props.onClick}>
		<FormattedMessage
			id="form.section.forbidden-product"
			description="Text for forbidden product"
			defaultMessage="You do not have access to this product. Contact your case manager if you need info."
		/>
	</Typography>
);

ForbiddenPageSection.displayName = "DployForbiddenPageSection";

export { ForbiddenPageSection };
