import {
	LimitRedistributionLimitDetail,
	LimitRedistributionLimitDetailEntity
} from "@ploy-lib/rest-resources";
import { FieldType, TableColumnMetadata } from "./types";

export class LimitRedistributionSchema extends LimitRedistributionLimitDetailEntity {
	static metaData: Partial<
		Record<keyof LimitRedistributionLimitDetail, TableColumnMetadata>
	> = {
		applicationNumber: {
			fieldType: FieldType.Text
		},
		limitGuid: {
			fieldType: FieldType.Select
		}
	};
}
