import { apiResourceUrl } from "@ploy-lib/core";
import { TemplateTableColumn, TableColumnOptionSource } from "@ploy-lib/types";
import { BaseCamelCasedResource } from "./BaseResource";

export class TableColumnResource
	extends BaseCamelCasedResource
	implements TemplateTableColumn
{
	readonly formTemplateTableColumnId: string;
	readonly name: string;
	readonly tableType: string;

	readonly showSum?: boolean;
	readonly editable?: boolean;
	readonly isMultipleSelect?: boolean;
	readonly optionValues?: { key: string; value: string }[];
	readonly allOptionValues?: { key: string; value: string }[];
	readonly optionSource?: TableColumnOptionSource;
	readonly isGroupingColumn?: boolean;
	readonly hrefLink?: string = "";
	readonly groupLabel?: string = "";
	readonly footerGroupLabel?: string = "";

	pk() {
		return this.formTemplateTableColumnId?.toString() || "";
	}

	static urlRoot = apiResourceUrl("tableColumn");
}
