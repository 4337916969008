import {
	CGFSection,
	CGFField,
	TemplateField,
	Variable,
	Service,
	CustomGuiFieldHandling
} from "@ploy-lib/types";
import mapValues from "lodash/mapValues";
import {
	FunctionTypes,
	fieldClickServiceName,
	fieldOptionListVariableName,
	fieldOptionSelectedVariableName,
	fieldOptionFilterVariableName,
	isNotNull,
	getFieldNameMatch,
	ServiceBodyType,
	ServiceBodyField,
	servicePendingName
} from "@ploy-lib/calculation";
import {
	selectItemValue,
	filterItems,
	getSelectItemValueKey
} from "@ploy-ui/template-form";
import { getIn } from "formik";
import isArray from "lodash/isArray";
import fromPairs from "lodash/fromPairs";
import flatten from "lodash/flatten";

// const setVariableRegex = /SetVariableValue\("([^"]+)",\s([^,]+),/;
// const getVariableRegex = /GetVariableValue\("([^"]+)"/;

// const getToggleVariable = (expr: string) => {
// 	const setMatch = expr.match(setVariableRegex);
// 	if (setMatch) {
// 		const variableName = setMatch[1];
// 		const valueExpr = setMatch[2];

// 		const getMatch = valueExpr.match(getVariableRegex);

// 		if (valueExpr.startsWith("!") && getMatch && getMatch[1] === variableName) {
// 			return variableName;
// 		}
// 	}
// };

const createFixItemValuesFunc =
	(idKey?: string, isNumeric = false) =>
	(items: any[]) => {
		if (!items || !isArray(items) || items.length === 0) return items;

		const fixed = items.map((item: any) => {
			const key = getSelectItemValueKey(idKey)(item);
			const value = key ? item[key] : item;
			if (isNumeric ? typeof value !== "number" : typeof value !== "string") {
				const fixedValue = isNumeric ? Number(value) : String(value);
				if (!key) return fixedValue;

				const clone = isArray(item) ? [...item] : { ...item };
				clone[key] = fixedValue;

				return clone;
			}

			return item;
		});

		if (fixed.some((item: any, idx) => item !== items[idx])) return fixed;

		return items;
	};

const createSelectFunc =
	(
		idKey?: string,
		isNumeric = false,
		canBeSetEmpty = false,
		isMultipleSelect = false
	) =>
	(value: any, inputItems: any, filter: any) => {
		if (!inputItems) return value;

		const getItemValue = selectItemValue(idKey);

		const items = filter ? filterItems(inputItems, filter, idKey) : inputItems;
		let newValue = value;

		if (items && items.length === 1 && !canBeSetEmpty)
			newValue = getItemValue(items[0]);
		else if (value != null && items && items.length > 0) {
			if (isMultipleSelect) {
				return items
					.map(getItemValue)
					.filter((x: any) => value.includes(String(x)));
			} else {
				const selectedValue = items
					.map(getItemValue)
					.find((x: any) => String(x) === String(value));

				if (selectedValue == null) newValue = null;
			}
		} else {
			newValue = null;
		}

		if (isNumeric && typeof newValue !== "number") {
			newValue = Number(newValue);
			if (Number.isNaN(newValue)) newValue = -1;
		}

		if (!isNumeric && typeof newValue !== "string") {
			newValue = newValue == null ? "" : String(newValue);
		}

		return newValue;
	};

const createSelectMissingFunc = (idKey?: string) => (value: any) => {
	return value == null || value === "" || value === -1 || Number.isNaN(value);
};

const fieldCanHaveOptionValues = (cgfField: CGFField) =>
	["DropdownList", "SearchSelectList"].includes(
		cgfField.options.renderAs || cgfField.options.controlType
	) ||
	cgfField.options.url ||
	cgfField.options.onSelect ||
	cgfField.options.fieldList != null ||
	(cgfField.options.optionValues != null &&
		cgfField.options.optionValues.length > 0) ||
	cgfField.options.optionDescKey ||
	cgfField.options.optionIdKey;

export interface CgfResults {
	cgfMap: Record<string, TemplateField>;
	mapTemplateFieldDefaults: (field: TemplateField) => TemplateField[];
	initialValues: Record<string, Record<string, any>>;
	initialVisible: Record<string, Record<string, boolean>>;
	initialChecked: Record<string, Record<string, boolean | null>>;
	additionalVariables: Record<string, Variable[]>;
	additionalFunctions: FunctionTypes[];
	serviceBodyFields: Record<string, ServiceBodyField[]>;
}

export function fromCGF<TNamespaces extends string>(
	namespacedCgfSections: Record<
		TNamespaces,
		Partial<Record<string, CGFSection>>
	>,
	variables: Record<TNamespaces, Variable[]>,
	services: Record<TNamespaces, Service[]>,
	customSubmitFields: Record<TNamespaces, Partial<Record<string, string>>>,
	debugEnabled?: boolean,
	templateFields?: TemplateField[]
): CgfResults {
	const cgfFieldLists = mapValues(
		namespacedCgfSections,
		(sections: Record<string, CGFSection>) =>
			Object.values(sections)
				.map(s => s.fieldList)
				.reduce((a, b) => [...a, ...b], [])
	) as Record<TNamespaces, CGFField[]>;

	const cgfFieldMaps = mapValues(
		cgfFieldLists,
		(fields: CGFField[]) =>
			new Map(fields.map(f => [f.name, f] as [string, CGFField]))
	);

	const cgfSectionMaps = mapValues(
		namespacedCgfSections,
		(sections: Record<string, CGFSection>) =>
			new Map(
				Object.entries(sections).map(
					([k, v]) => [`[${k}]`, v] as [string, CGFSection]
				)
			)
	);

	const additionalVariables = mapValues(
		cgfFieldLists,
		(fieldList: CGFField[]) => {
			const optionListVariables = fieldList
				.filter(fieldCanHaveOptionValues)
				.map(
					(f): Variable => ({
						name: fieldOptionListVariableName(f.name),
						controlID: fieldOptionListVariableName(f.name),
						defaultValue:
							f.options.optionValues.length === 0
								? null
								: f.options.optionValues,
						initializeOnce: true
					})
				);

			const optionSelectedVariables = fieldList
				.filter(fieldCanHaveOptionValues)
				.map(
					(f): Variable => ({
						name: fieldOptionSelectedVariableName(f.name),
						controlID: fieldOptionSelectedVariableName(f.name),
						defaultValue: null,
						initializeOnce: true
					})
				);

			const optionFilterVariables = fieldList
				.filter(f => f.options.optionFilter)
				.map(
					(f): Variable => ({
						name: fieldOptionFilterVariableName(f.name),
						controlID: fieldOptionFilterVariableName(f.name),
						defaultValue: f.options.optionFilter,
						initializeOnce: true
					})
				);

			return [
				...optionListVariables,
				...optionSelectedVariables,
				...optionFilterVariables
			];
		}
	) as Record<TNamespaces, Variable[]>;

	const additionalFunctions: FunctionTypes[] = Object.entries<CGFField[]>(
		cgfFieldLists
	).flatMap(([namespace, fieldList]) => {
		const itemSelectedFunctions = fieldList
			.filter(f => f.options.fieldList && f.options.fieldList.length > 0)
			.flatMap((field): FunctionTypes[] => {
				const updateFunctions = field.options.fieldList!.flatMap(
					(update): FunctionTypes[] => {
						// source and target is reversed when compared to fieldList on service
						const [targetStr, sourceStr, defaultValue] = update.split(";");

						const source = sourceStr || targetStr;
						const [target, ns = namespace] = (targetStr || sourceStr)
							.split(".")
							.reverse();

						const valueIsMissing = createSelectMissingFunc();

						return [
							{
								type: "value",
								output: `${ns}.${target}`,
								inputs: [
									`values.${ns}.${field.name}`,
									`values.${ns}.${fieldOptionListVariableName(field.name)}`,
									`values.${ns}.${fieldOptionSelectedVariableName(field.name)}`
								],
								call: (value, items, selectedItem) => {
									var retVal = undefined;

									if (Array.isArray(items) && value) {
										var getItemValue = selectItemValue(
											field.options.optionIdKey
										);
										var item = items.find(x => getItemValue(x) === value);
										retVal = getIn(item, source);
									} else if (selectedItem) {
										retVal = getIn(selectedItem, source);
									} else {
										retVal = getIn(value, source);
									}
									return retVal || defaultValue;
								}
							},
							{
								type: "missing",
								output: `${ns}.${target}`,
								inputs: [
									`values.${ns}.${field.name}`,
									`values.${ns}.${fieldOptionListVariableName(field.name)}`,
									`values.${ns}.${fieldOptionSelectedVariableName(field.name)}`
								],
								call: (value, items, selectedItem) => {
									if (!value && !items && !selectedItem) {
										return valueIsMissing(defaultValue);
									}
									var retVal = undefined;

									if (Array.isArray(items) && value) {
										var getItemValue = selectItemValue(
											field.options.optionIdKey
										);
										var item = items.find(x => getItemValue(x) === value);
										if (!item) return undefined;
										retVal = getIn(item, source);
									} else if (selectedItem) {
										retVal = getIn(selectedItem, source);
									} else {
										retVal = getIn(value, source);
									}
									return valueIsMissing(retVal || defaultValue);
								}
							}
						];
					}
				);

				return updateFunctions;
			})
			.filter(isNotNull);

		const selectFunctions = fieldList
			.flatMap((field): (FunctionTypes | null)[] => {
				const variable = variables[namespace].find(
					v =>
						v.controlID != null &&
						getFieldNameMatch(field.name, v.controlID!) != null
				);

				const hideWhenEmpty =
					!field.options.showWhenEmpty &&
					field.options.renderAs &&
					["SearchSelectList", "DropdownList"].includes(field.options.renderAs);

				const hideWhenEmptyFunc: FunctionTypes = {
					type: "visible",
					output: `${namespace}.${field.name}`,
					inputs: [
						`values.${namespace}.${fieldOptionListVariableName(field.name)}`,
						`values.${namespace}.${fieldOptionFilterVariableName(field.name)}`
					],
					call: (inputItems, filter) => {
						const items = filter
							? filterItems(inputItems, filter, field.options.optionIdKey)
							: inputItems;
						return Array.isArray(items) && items.length !== 0;
					}
				};

				const fixOptionTypesFunc: FunctionTypes = {
					type: "value",
					output: `${namespace}.${fieldOptionListVariableName(field.name)}`,
					inputs: [
						`values.${namespace}.${fieldOptionListVariableName(field.name)}`
					],
					call: createFixItemValuesFunc(
						field.options.optionIdKey,
						field.options.numeric
					)
				};
				const hasOptionList =
					field.options.optionValues.length > 0 ||
					field.options.renderAs === "DropdownList";

				if (!variable)
					return [
						hasOptionList ? fixOptionTypesFunc : null,
						hideWhenEmpty ? hideWhenEmptyFunc : null
					];

				return [
					hasOptionList ? fixOptionTypesFunc : null,
					hideWhenEmpty ? hideWhenEmptyFunc : null,
					hasOptionList
						? {
								type: "value",
								output: `${namespace}.${variable.name}`,
								inputs: [
									`values.${namespace}.${variable.name}`,
									`values.${namespace}.${fieldOptionListVariableName(
										field.name
									)}`,
									`values.${namespace}.${fieldOptionFilterVariableName(
										field.name
									)}`
								],
								call: createSelectFunc(
									field.options.optionIdKey,
									variable.isNumeric,
									field.options.canBeSetEmpty,
									field.options.isMultiSelect
								)
						  }
						: null,
					hasOptionList
						? {
								type: "missing",
								output: `${namespace}.${variable.name}`,
								inputs: [`values.${namespace}.${variable.name}`],
								call: createSelectMissingFunc(field.options.optionIdKey)
						  }
						: null,
					hasOptionList && field.options.chooseAndHideIfOneValue
						? {
								type: "visible",
								output: `${namespace}.${field.name}`,
								inputs: [
									`isMissing.${namespace}.${variable.name}`,
									`values.${namespace}.${fieldOptionListVariableName(
										field.name
									)}`,
									`values.${namespace}.${fieldOptionFilterVariableName(
										field.name
									)}`
								],
								call: (isMissing, inputItems, filter) => {
									const items = filter
										? filterItems(inputItems, filter, field.options.optionIdKey)
										: inputItems;

									return isMissing || !items || items.length !== 1;
								}
						  }
						: null
				];
			})
			.filter(isNotNull);

		return [...selectFunctions, ...itemSelectedFunctions];
	});

	const fieldsWithFillOptionValues = fromPairs(
		flatten(
			Object.entries<Service[]>(services).map(([serviceNs, nsServices = []]) =>
				nsServices
					.filter(s => s.fillOptionValues && s.fillOptionValues.trim())
					.map(s => {
						const [source, targetStr] = s.fillOptionValues!.split(";");
						const [target, fieldNs = serviceNs] = (targetStr || source)
							.split(".")
							.reverse();
						return [
							`${fieldNs}.${target}`,
							{ namespace: serviceNs, name: s.name }
						] as [string, { namespace: string; name: string }];
					})
			)
		)
	);
	//
	const cgfMap = {} as Record<string, TemplateField>;
	Object.entries<CGFField[]>(cgfFieldLists).forEach(([namespace, fieldList]) =>
		fieldList.forEach((cgfField, idx) => {
			const renderAs =
				cgfField.options.renderAs || cgfField.options.controlType;
			let canHaveOptionValues = fieldCanHaveOptionValues(cgfField);

			const disabled =
				(cgfField.options && cgfField.options.readOnly) ||
				(cgfField.checked && cgfField.hasCheckbox === 2);

			let hasClick = false;

			let clickService = fieldClickServiceName(cgfField.name);

			let target: string | undefined = undefined;

			const nsServices = services[cgfField.namespace as TNamespaces];

			let click_service =
				nsServices &&
				nsServices.find(
					s =>
						s.changeFilter &&
						(s.changeFilter.startsWith(cgfField.name + "_click") ||
							s.changeFilter.indexOf("|" + cgfField.name + "_click") > -1)
				);

			if (renderAs === "CustomButton" || renderAs === "LinkButton")
				click_service =
					nsServices &&
					nsServices.find(
						s => s.changeFilter && s.changeFilter.indexOf(cgfField.name) > -1
					);

			let optionSourceServiceName = undefined as string | undefined;
			const autofill_service =
				nsServices &&
				nsServices.find(
					s =>
						s.changeFilter &&
						s.changeFilter.indexOf(cgfField.name + "_autofill") > -1
				);

			let openServiceName = undefined as string | undefined;
			const open_service =
				nsServices &&
				nsServices.find(
					s =>
						s.changeFilter &&
						s.changeFilter.indexOf(cgfField.name + "_open") > -1
				);

			const optionfill_service =
				services &&
				fieldsWithFillOptionValues[cgfField.namespace + "." + cgfField.name];

			if (click_service) {
				hasClick = true;
				clickService = click_service.name;
			}
			if (autofill_service) {
				canHaveOptionValues = true;
				optionSourceServiceName =
					autofill_service.name !== null ? autofill_service.name : undefined;
			}
			if (open_service) {
				openServiceName =
					open_service.name !== null ? open_service.name : undefined;
				target = "_blank";
			}
			if (optionfill_service) {
				canHaveOptionValues = true;
			}

			cgfMap[`${namespace}.${cgfField.name}`] = {
				formTemplateFieldId: "",
				namespace: namespace,
				name: cgfField.name,
				renderAs,
				hasClick: hasClick || cgfField.hasClick,
				click: hasClick
					? {
							service: clickService
					  }
					: undefined,
				open: openServiceName
					? {
							service: openServiceName
					  }
					: undefined,
				optionSource: canHaveOptionValues
					? {
							labelKey: cgfField.options.optionDescKey,
							valueKey: cgfField.options.optionIdKey,
							filter: fieldOptionFilterVariableName(cgfField.name),
							name: fieldOptionListVariableName(cgfField.name),
							selected: fieldOptionSelectedVariableName(cgfField.name),
							pendingFillOptionValues: optionfill_service
								? [
										optionfill_service.namespace,
										servicePendingName(
											optionfill_service.namespace,
											optionfill_service.name
										)
								  ]
								: undefined,
							service: optionSourceServiceName
								? {
										service: optionSourceServiceName
								  }
								: undefined,
							additionalRequestData: customSubmitFields[namespace]
					  }
					: undefined,
				valueSuffix: cgfField.valueSuffix,
				placeholder: cgfField.shortDesc,
				globalEvent: cgfField.globalEvent,
				tooltip:
					cgfField.options.tooltip != null
						? cgfField.options.tooltip
						: cgfField.longDesc,
				label: cgfField.label,
				icon: cgfField.labelIcon || cgfField.options.iconClass,
				allowEmpty: cgfField.options.canBeSetEmpty,
				hiddenIfNoChoice:
					cgfField.options.chooseAndHideIfOneValue ||
					!cgfField.options.showWhenEmpty,
				searchable: cgfField.options.searchable,
				emptyEqualsZero: cgfField.options.emptyEqualsZero,
				literal: cgfField.options.literal,
				target,
				formatString: cgfField.options.formatString,
				disabled,
				multiple: cgfField.options.isMultiSelect,
				canResetWriteLocked: cgfField.hasCheckbox === 1,
				additionalChecks: cgfField.additionalChecks
			};
		})
	);

	const mapTemplateFieldDefaults = (field: TemplateField): TemplateField[] => {
		const { namespace, name } = field;

		const cgfSectionMap =
			namespace != null ? cgfSectionMaps[namespace as TNamespaces] : null;
		const cgfFieldMap =
			namespace != null ? cgfFieldMaps[namespace as TNamespaces] : null;

		const cgfSection = cgfSectionMap ? cgfSectionMap.get(name) : null;
		const cgfField = cgfFieldMap != null ? cgfFieldMap.get(name) : null;

		const cgfFields = cgfSection
			? cgfSection.fieldList
			: cgfField
			? [cgfField]
			: [];

		if (
			namespace === "Generic" ||
			field.cgfHandling === CustomGuiFieldHandling.Ignore
		) {
			return [field as TemplateField];
		}

		if (cgfFields.length === 0)
			// Fields with role don't require cgfField definition
			return field.role ||
				debugEnabled ||
				field.cgfHandling === CustomGuiFieldHandling.Auto
				? [field]
				: [];

		const filteredField = Object.entries(field).reduce((acc, [k, v]) => {
			if (v !== undefined) acc[k] = v;
			return acc;
		}, {} as TemplateField);

		return cgfFields.map((cgfField, idx) => {
			const mappedField = cgfMap[`${cgfField.namespace}.${cgfField.name}`];

			const modifier =
				cgfField.options.numeric &&
				(!cgfField.options.optionValues || // numeric fields with optionValues shouldn't get the numeric-field flag (can be force converted to NumberField component)
					cgfField.options.optionValues.length < 1)
					? `${cgfField.options.cssClass ?? ""} ${
							field.modifier ?? ""
					  } numeric-field`.trim()
					: `${cgfField.options.cssClass ?? ""} ${field.modifier ?? ""}`.trim();

			return {
				...mappedField,
				...filteredField,
				name: cgfField.name,
				modifier,
				formTemplateFieldId: `${field.formTemplateFieldId}_${idx}`
			} as TemplateField;
		});
	};

	const parseFormattedNumber = (val: any): number => {
		if (typeof val === "number") return val;

		const numString = String(val).replace(/\s/g, "").replace(",", ".");

		return numString === "" ? NaN : Number(numString);
	};

	const initialValues = mapValues(cgfFieldLists, (fieldList: CGFField[]) =>
		fieldList.reduce((acc, f) => {
			let value = f.rawValue;

			if (value === undefined) {
				if (f.options.isMultiSelect) value = String(f.value).split(", ");
				else value = f.value;
			}

			if (f.options.numeric) {
				if (f.options.isMultiSelect)
					value = (Array.isArray(value) ? value : [value]).map(
						parseFormattedNumber
					);
				else value = parseFormattedNumber(value);
			}

			acc[f.name] = value;

			if (fieldCanHaveOptionValues(f)) {
				const optionList = f.options.optionValues || [];
				const defaultSelected = optionList.find(
					item => selectItemValue(f.options.optionIdKey)(item) === f.value
				);

				acc[fieldOptionSelectedVariableName(f.name)] = defaultSelected || null;
			}

			return acc;
		}, {} as Record<string, any>)
	) as Record<TNamespaces, Record<string, any>>;
	let templateFieldNames = templateFields?.map(x => x.name);
	const serviceBodyFields = mapValues(
		cgfFieldLists,
		(fieldList: CGFField[], namespace: TNamespaces) =>
			fieldList
				.filter(
					x =>
						!(x.excludeFromSubmit || x.value === "BUTTON") &&
						!(x.excludeIfNotInForm && !templateFieldNames?.includes(x.name))
				)
				.map<ServiceBodyField>(f => ({
					type: f.typeName
						? ServiceBodyType.Object
						: f.options.numeric
						? ServiceBodyType.Number
						: ServiceBodyType.Text,
					typeName: f.typeName,
					multiple: f.options.isMultiSelect,
					field: f.name,
					namespace
				}))
	) as Record<TNamespaces, ServiceBodyField[]>;

	const initialVisible = mapValues(cgfFieldLists, (fieldList: CGFField[]) =>
		fieldList.reduce((acc, f) => {
			acc[f.name] = Boolean(f.isVisible);
			return acc;
		}, {})
	) as Record<TNamespaces, Record<string, boolean>>;

	const initialChecked = mapValues(cgfFieldLists, (fieldList: CGFField[]) =>
		fieldList.reduce((acc, f) => {
			acc[f.name] =
				f.hasCheckbox === 1 || f.hasCheckbox === 2 ? Boolean(f.checked) : null;
			return acc;
		}, {})
	) as Record<TNamespaces, Record<string, boolean | null>>;

	return {
		cgfMap,
		mapTemplateFieldDefaults,
		initialValues,
		initialVisible,
		initialChecked,
		additionalVariables,
		additionalFunctions,
		serviceBodyFields
	};
}
