import {
	ReadShape,
	Normalize,
	useFetchDispatcher,
	DispatchContext,
	ParamsFromShape,
	Schema,
	useMeta
} from "@rest-hooks/core";
import { useContext, useEffect, useMemo } from "react";

/** Returns whether the data at this url is fresh or stale */
export default function useExpiresAt<Params extends Readonly<object>>(
	fetchShape: Pick<ReadShape<any, Params>, "getFetchKey" | "options">,
	params: Params | null,
	entitiesExpireAt = 0
): number {
	const meta = useMeta(fetchShape, params);

	if (!meta) {
		return entitiesExpireAt;
	}

	// Temporarily prevent infinite loops until invalidIfStale is revised
	if (
		fetchShape.options?.invalidIfStale &&
		meta.prevExpiresAt &&
		meta.expiresAt - meta.prevExpiresAt < 1000
	) {
		return meta.expiresAt + 2000;
	}

	return meta.expiresAt;
}

function appendList<S extends Schema>(
	newResults: Normalize<S>[],
	existingResults: Normalize<S>[] | undefined
) {
	// In case there are duplicates, Set will eliminate them.
	const set = new Set([...(existingResults ?? []), ...newResults]);
	return [...set.values()];
}

/** Request a resource if it is not in cache. */
export function useRetrieveAppendList<Shape extends ReadShape<any, any>>(
	fetchShape: Shape,
	params: ParamsFromShape<Shape> | null,
	appendParams: Partial<ParamsFromShape<Shape>> = {},
	triggerFetch = false,
	entitiesExpireAt = 0
) {
	const dispatchFetch: any = useFetchDispatcher(true);
	const expiresAt = useExpiresAt(fetchShape, params, entitiesExpireAt);

	// Clears invalidIfStale loop blocking mechanism
	const dispatch = useContext(DispatchContext);
	useEffect(() => {
		if (params && fetchShape.options?.invalidIfStale)
			dispatch({
				type: "rest-hook/mounted",
				payload: fetchShape.getFetchKey(params)
			}); // set expiry
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params && fetchShape.getFetchKey(params)]);

	return useMemo(() => {
		// null params mean don't do anything
		if ((Date.now() <= expiresAt && !triggerFetch) || !params) return;
		return dispatchFetch(fetchShape, params, undefined, [
			[fetchShape, appendParams, appendList]
		]);
		// we need to check against serialized params, since params can change frequently
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		expiresAt,
		dispatchFetch,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		params && fetchShape.getFetchKey(params),
		triggerFetch
	]);
}
