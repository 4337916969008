import React, { lazy } from "react";
import ContentBox from "../../ContentBox";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { DashboardContentProps } from "../../types";
import { FormattedMessage } from "react-intl";
import { ChartContentProps, validChartData } from "./common";
import { GridComponent } from "../types";
import { ChartResource } from "@ploy-lib/rest-resources";

const ChartContent = lazy(
	() => import(/* webpackChunkName: "chart"  */ "./ChartContent")
);

export interface ChartsProps extends DashboardContentProps, ChartContentProps {}

const Charts: GridComponent<
	ChartsProps,
	ReturnType<typeof resources>,
	ReturnType<typeof resources>
> = props => {
	const { source, boxProps, placeholder } = props;

	return (
		<ContentBox
			avatar={<ShowChartIcon />}
			header={
				<FormattedMessage
					id="dealer.dashboard.statistics.header"
					defaultMessage="Statistics"
					description="Label for statistics header"
				/>
			}
			{...boxProps}
		>
			<ChartContent source={source} placeholder={placeholder} />
		</ContentBox>
	);
};

const resources = ({ source }: ChartContentProps) =>
	[[ChartResource[source ?? "detail"]?.(), source ? {} : null]] as const;

Charts.getPrefetch = resources;
Charts.getVisibilityFetch = resources;
Charts.getVisibility = (props, [chartData]) => validChartData(chartData);
Charts.displayName = "DployDashboardCharts";

export default Charts;
