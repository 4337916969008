import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import { AbstractInstanceType, Resource, SchemaList } from "@rest-hooks/rest";

export interface AvailableFormField {
	availableFormFieldId?: ID;
	name: string;
	namespace: string;
}

export class FormFieldResource
	extends BaseResource
	implements AvailableFormField
{
	readonly availableFormFieldId?: ID;
	readonly name: string;
	readonly namespace: string;

	pk() {
		return this.availableFormFieldId?.toString();
	}

	static create<T extends typeof Resource>(this: T) {
		return super.create().extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>
		});
	}

	static urlRoot = apiResourceUrl("formfield");
}
