import RecentApplications from "./RecentApplications";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage } from "react-intl";

applyPropertyControls(RecentApplications, {
	includeRejected: {
		type: ControlType.Boolean,
		title: (
			<FormattedMessage
				id="dploy.grid.recentApplications.includeRejected.label"
				defaultMessage="Include cancelled applications"
			/>
		),
		defaultValue: false,
		coerce: value => (String(value) === "1" ? true : false)
	},
	showDocumentStatus: {
		type: ControlType.Boolean,
		title: (
			<FormattedMessage
				id="dploy.grid.recentApplications.showDocumentStatus.label"
				defaultMessage="Show document status"
			/>
		),
		defaultValue: false,
		coerce: value => (String(value) === "1" ? true : false)
	},
	boxProps: contentBoxPropertyControls
});

export default RecentApplications;
