import React from "react";
import {
	LiteralBase,
	LiteralProps,
	getLiteralBaseProps,
	baseEditableOptions
} from "./TextLiteral";
import { makeStyles } from "@material-ui/core";
import { FormattedNumber } from "react-intl";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";

const useStylesTextAreaLiteral = makeStyles(
	theme => ({
		root: {
			whiteSpace: "pre-wrap",
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			padding: theme.spacing(1)
		}
	}),
	{ name: "DployTextAreaLiteral" }
);

export const TextArea = (props: LiteralProps) => {
	const classesTextAreaLiteral = useStylesTextAreaLiteral(props);
	return (
		<LiteralBase
			{...getLiteralBaseProps({
				...props,
				className: classesTextAreaLiteral.root,
				options: { ...props.options, literalVariant: "normal" }
			})}
		>
			{typeof props.field.value === "number" ? (
				<FormattedNumber maximumFractionDigits={0} value={props.field.value} />
			) : (
				props.field.value
			)}
		</LiteralBase>
	);
};
TextArea.displayName = "TextAreaLiteral";

export const TextAreaLiterals = identityRecordOfFieldEditorOptions({
	TextAreaLiteral: {
		editableOptions: {
			...baseEditableOptions
		}
	}
});
