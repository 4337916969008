import clsx from "clsx";
import { makeStyles, styled } from "@material-ui/core/styles";
import { palette } from "@material-ui/system";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
	SelectField,
	CheckboxWithLabelField,
	SelectionField,
	DatePickerField,
	TextField
} from "@ploy-ui/form-fields";

import { PendingButton } from "@ploy-ui/core";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete";

import { CancelOutlined } from "@material-ui/icons";
import AppFilterSelector from "./AppFilterSelector";
import AdvancedSearchToggle from "./AdvancedSearchToggle";
import Hidden from "@material-ui/core/Hidden";
import Chip from "@material-ui/core/Chip";
import { Field, useFormikContext } from "formik";
import {
	ProfileResource,
	ProductFilter,
	ProductResource,
	DocumentType,
	ApplicationInfoFilter
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { AllApplicationsSettingsResource } from "@ploy-lib/rest-resources";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		flex: "0 0 370px"
	},
	fixedWrapper: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.grey[200],
		[theme.breakpoints.up("lg")]: {
			padding: theme.spacing(4),
			width: 370,
			position: "fixed",
			overflowY: "auto",
			height: "100vh"
		}
	},
	topContainer: {
		display: "flex",
		flexDirection: "row-reverse",
		flexWrap: "wrap"
	},
	fullWidthField: {
		marginTop: "1em",
		width: "100%"
	},
	halfWithField: {
		marginTop: "1em",
		width: "48%"
	},
	checkboxField: {
		alignSelf: "center"
	},
	inputField: {
		background: "white"
	},
	multipleFieldContainer: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between"
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
		verticalAlign: "middle"
	}
}));

const GridItem = (props: GridProps) => (
	<Grid item xs={12} sm={6} md={4} lg={12} {...props} />
);

const StatusChip = styled(Chip)(palette);

type LabelValue = {
	label: React.ReactNode;
	value: string | number | boolean | null | undefined;
};

function SearchInputs({
	setAdvancedSearchOpen
}: {
	setAdvancedSearchOpen: (open: boolean) => void;
}) {
	const classes = useStyles();

	const {
		applicationStatusList,
		documentStatusList,
		salepersons,
		allowVendorHierarchySearch,
		shouldShowCarSearchFields,
		hideAllTasksSignedExceptType,
		hideDocumentStatusFilter,
		enableExternalOfferNumberSearch
	} = useResource(AllApplicationsSettingsResource.detail(), {});

	const intl = useIntl();

	const productFilter: ProductFilter = {
		searchContext: true
	};

	const [profile, products] = useResource(
		[ProfileResource.detail(), {}],
		[ProductResource.list(), productFilter]
	);

	const { values } = useFormikContext<ApplicationInfoFilter>();

	return (
		<div className={classes.root}>
			<div className={classes.fixedWrapper}>
				<div className={classes.topContainer}>
					<Hidden lgUp>
						<AdvancedSearchToggle
							open={true}
							onClick={() => {
								setAdvancedSearchOpen(false);
							}}
						/>
					</Hidden>
					<AppFilterSelector name="salepersonId" />
				</div>

				<Grid container spacing={1}>
					<GridItem>
						<Field
							className={clsx(classes.fullWidthField)}
							InputProps={{
								classes: {
									root: classes.inputField
								}
							}}
							label={
								<FormattedMessage
									id="dealer.allapplications.search.quicksearch.label"
									description="Label for quick search"
									defaultMessage="Freetext"
								/>
							}
							name="fullText"
							margin="normal"
							component={TextField}
							placeholder={intl.formatMessage(messages.quicksearch_placeholder)}
						/>
					</GridItem>
					{!shouldShowCarSearchFields ? null : (
						<GridItem>
							<div className={classes.multipleFieldContainer}>
								<Field
									className={clsx(classes.halfWithField)}
									InputProps={{
										classes: {
											root: classes.inputField
										}
									}}
									label={
										<FormattedMessage
											id="dealer.allapplications.search.carRegNo.label"
											description="Label for registration number field"
											defaultMessage="Registration number"
										/>
									}
									name="carRegNo"
									margin="normal"
									component={TextField}
									placeholder={intl.formatMessage(
										messages.carRegNo_placeholder
									)}
									disabled={values.missingCarRegNo}
								/>
								<Field
									className={clsx(classes.halfWithField, classes.checkboxField)}
									name="missingCarRegNo"
									component={CheckboxWithLabelField}
									label={
										<FormattedMessage
											id="dealer.allapplications.search.missingCarRegNo.label"
											description="Label for missing reg number checkbox"
											defaultMessage="Defects"
										/>
									}
									variant="switch"
									margin="normal"
								/>
							</div>
						</GridItem>
					)}
					{!shouldShowCarSearchFields ? null : (
						<GridItem>
							<div className={classes.multipleFieldContainer}>
								<Field
									className={clsx(classes.halfWithField)}
									InputProps={{
										classes: {
											root: classes.inputField
										}
									}}
									label={
										<FormattedMessage
											id="dealer.allapplications.search.chassisNr.label"
											description="Label for chassis number field"
											defaultMessage="Chassis number"
										/>
									}
									name="chassisNr"
									margin="normal"
									component={TextField}
									placeholder={intl.formatMessage(
										messages.chassisNr_placeholder
									)}
								/>
							</div>
						</GridItem>
					)}
					<GridItem>
						<Field
							className={clsx(classes.fullWidthField)}
							InputProps={{
								classes: {
									root: classes.inputField
								}
							}}
							label={
								<FormattedMessage
									id="dealer.allapplications.search.customerName.label"
									description="Label for customer name"
									defaultMessage="Customer name"
								/>
							}
							name="customerName"
							component={TextField}
							margin="normal"
						/>
						<Field
							name="customerNameContains"
							component={SelectionField}
							getItemLabel={(item: LabelValue) => item.label}
							getItemValue={(item: LabelValue) => item.value}
							horizontal
							// variant="switch"
							items={
								[
									{
										value: undefined,
										label: (
											<FormattedMessage
												id="dealer.allapplications.search.customerNameStartsWith.label"
												description="Label for customer name starts with radio button"
												defaultMessage="Starts with"
											/>
										)
									},
									{
										value: true,
										label: (
											<FormattedMessage
												id="dealer.allapplications.search.customerNameContains.label"
												description="Label for customer name contains radio button"
												defaultMessage="Contains"
											/>
										)
									}
								] as LabelValue[]
							}
							margin="normal"
						/>
					</GridItem>
					<GridItem>
						<Field
							className={clsx(classes.fullWidthField)}
							InputProps={{
								classes: {
									root: classes.inputField
								}
							}}
							label={
								<FormattedMessage
									id="dealer.allapplications.search.product.label"
									description="Label for product"
									defaultMessage="Product"
								/>
							}
							items={products}
							getItemLabel={(item: ProductResource) => item.name}
							getItemValue={(item: ProductResource) => item.id}
							component={SelectField}
							name="productList"
							disabled={products.length === 0}
							multiple
							disableCloseOnSelect
							clearable
							variant="input"
							margin="normal"
						/>
					</GridItem>
					<GridItem>
						<div className={classes.multipleFieldContainer}>
							<Field
								className={clsx(classes.halfWithField)}
								InputProps={{
									classes: {
										root: classes.inputField
									}
								}}
								label={
									<FormattedMessage
										id="dealer.allapplications.search.regDateFrom.label"
										description="Label for - fra registreringsdato"
										defaultMessage="From reg. date"
									/>
								}
								component={DatePickerField}
								name="fromDate"
								margin="normal"
							/>
							<Field
								className={clsx(classes.halfWithField)}
								InputProps={{
									classes: {
										root: classes.inputField
									}
								}}
								label={
									<FormattedMessage
										id="dealer.allapplications.search.regDateTo.label"
										description="Label for - til registreringsdato"
										defaultMessage="To reg. date"
									/>
								}
								component={DatePickerField}
								name="toDate"
								margin="normal"
							/>
						</div>
					</GridItem>
					<GridItem>
						<Field
							className={clsx(classes.fullWidthField)}
							InputProps={{
								classes: {
									root: classes.inputField
								}
							}}
							label={
								<FormattedMessage
									id="dealer.allapplications.search.status.label"
									description="Label for application status"
									defaultMessage="Status"
								/>
							}
							items={applicationStatusList.filter(x => x.text)}
							getItemLabel={(item: typeof applicationStatusList[number]) =>
								item.text
							}
							getItemValue={(item: typeof applicationStatusList[number]) =>
								item.key
							}
							component={SelectField}
							name="appStatus"
							disabled={applicationStatusList.filter(x => x.text).length === 0}
							multiple
							disableCloseOnSelect
							clearable
							renderTags={(
								value: typeof applicationStatusList,
								getTagProps: AutocompleteGetTagProps
							) =>
								value.map((v, index) => (
									<Box
										key={v.key}
										color="common.white"
										bgcolor={`status.${v.key}`}
										clone
									>
										<StatusChip label={v.text} {...getTagProps({ index })} />
									</Box>
								))
							}
							variant="input"
							margin="normal"
						/>
					</GridItem>
					{!hideDocumentStatusFilter && (
						<GridItem>
							<Field
								className={clsx(classes.fullWidthField)}
								InputProps={{
									classes: {
										root: classes.inputField
									}
								}}
								label={
									<FormattedMessage
										id="dealer.allapplications.search.docstatus.label"
										description="Label for document status"
										defaultMessage="Document status"
									/>
								}
								items={documentStatusList.filter(x => x.text)}
								getItemLabel={(item: typeof documentStatusList[number]) =>
									item.text
								}
								getItemValue={(item: typeof documentStatusList[number]) =>
									item.key
								}
								component={SelectField}
								name="docStatus"
								disabled={documentStatusList.filter(x => x.text).length === 0}
								multiple
								disableCloseOnSelect
								clearable
								variant="input"
								margin="normal"
							/>
						</GridItem>
					)}
					{enableExternalOfferNumberSearch && (
						<GridItem>
							<Field
								className={clsx(classes.fullWidthField)}
								InputProps={{
									classes: {
										root: classes.inputField
									}
								}}
								label={
									<FormattedMessage
										id="dealer.allapplications.search.externalOfferNumber.label"
										description="Label for external offer number"
										defaultMessage="External offer number"
									/>
								}
								name="externalOfferNumber"
								component={TextField}
								margin="normal"
							/>
						</GridItem>
					)}
					{values.salepersonId === profile.salespersonId ? null : (
						<GridItem>
							<Field
								className={clsx(classes.fullWidthField)}
								InputProps={{
									classes: {
										root: classes.inputField
									}
								}}
								label={
									<FormattedMessage
										id="dealer.allapplications.search.saleperson.label"
										description="Label for salesperson"
										defaultMessage="Salesperson"
									/>
								}
								items={salepersons}
								getItemLabel={(item: typeof salepersons[number]) =>
									item.fullName
								}
								getItemValue={(item: typeof salepersons[number]) =>
									item.salepersonId
								}
								component={SelectField}
								name="salepersonId"
								disabled={salepersons.length === 0}
								variant="input"
								margin="normal"
							/>
						</GridItem>
					)}
					{!shouldShowCarSearchFields || hideAllTasksSignedExceptType ? null : (
						<GridItem className={classes.checkboxField}>
							<Field
								name="allTasksSignedExceptType"
								component={CheckboxWithLabelField}
								getItemLabel={(item: LabelValue) => item.label}
								getItemValue={(item: LabelValue) => item.value}
								variant="switch"
								multiple
								items={
									[
										{
											value: DocumentType.Security,
											label: (
												<FormattedMessage
													id="dealer.allapplications.search.all_signed_but_security.label"
													description="Label for all signed but security checkmark"
													defaultMessage="Only security document missing signature"
												/>
											)
										}
									] as LabelValue[]
								}
								margin="normal"
							/>
						</GridItem>
					)}
					{allowVendorHierarchySearch ? (
						<GridItem className={classes.checkboxField}>
							<Field
								name="searchInVendorHierarchy"
								component={CheckboxWithLabelField}
								label={
									<FormattedMessage
										id="dealer.allapplications.search.search_in_vendor_hierarchy.label"
										description="Label for search in vendor hierarchy checkmark"
										defaultMessage="Search in vendor hierarchy"
									/>
								}
								variant="switch"
								margin="normal"
							/>
						</GridItem>
					) : null}
					<GridItem>
						<PendingButton variant="contained" size="medium" type="reset">
							{intl.formatMessage(messages.resetSearchParamsButtonLabel)}
							<CancelOutlined className={classes.rightIcon} />
						</PendingButton>
					</GridItem>
				</Grid>
			</div>
		</div>
	);
}

export const messages = defineMessages({
	quicksearch_placeholder: {
		id: "dealer.allapplications.search.quicksearch.placeholder",
		description: "Placeholder for quick search",
		defaultMessage: "What/who are you looking for?"
	},
	carRegNo_placeholder: {
		id: "dealer.allapplications.search.carRegNo.placeholder",
		description: "Placeholder for carRegNo",
		defaultMessage: "AB12345"
	},
	chassisNr_placeholder: {
		id: "dealer.allapplications.search.chassisNr.placeholder",
		description: "Placeholder for chassisNr",
		defaultMessage: "AB12345"
	},
	resetSearchParamsButtonLabel: {
		id: "dealer.allapplications.search.resetSearchParams.buttonlabel",
		description: "Button label for reseting searchParams",
		defaultMessage: "Reset filter"
	}
});

export default SearchInputs;
