import React, { useState, useEffect } from "react";
import { Typography, Container } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export interface ErrorPageContentProps {
	header: React.ReactNode;
	body?: React.ReactNode;
	className?: string;
	onClick?: () => void;
}

export const ErrorPageContent = (props: ErrorPageContentProps) => {
	const { header, body, ...rest } = props;

	return (
		<Container {...rest}>
			<Typography variant="h5" gutterBottom>
				{header}
			</Typography>
			{body}
		</Container>
	);
};

export const ErrorPage = ({ error }: { error: any }) => {
	const status = error?.response?.status ?? error.statusCode;

	let header = (
		<FormattedMessage
			id="dploy.error.default"
			defaultMessage="An error has occured"
		/>
	);

	let body: React.ReactNode | undefined = undefined;

	/* eslint-disable react-hooks/rules-of-hooks */
	if (process.env.NODE_ENV === "development") {
		const isHtml = error.response?.headers
			.get("Content-Type")
			?.includes("html");

		const [html, setHtml] = useState<string>();

		useEffect(() => {
			if (!isHtml || error.response.bodyUsed) return;

			let active = true;
			(async () => {
				const text = await error.response.text();
				if (active) setHtml(text);
			})();
			return () => {
				active = false;
			};
		}, [error, isHtml]);

		body = (
			<>
				<Typography
					component="pre"
					variant="caption"
					color="error"
					gutterBottom
				>
					{error.stack}
				</Typography>
				{html && (
					<iframe
						frameBorder={0}
						srcDoc={html}
						width="100%"
						height="800px"
						title="Network error response"
					/>
				)}
			</>
		);
	}
	/* eslint-disable react-hooks/rules-of-hooks */

	if (status) {
		header = (
			<FormattedMessage
				id="dploy.error.status"
				defaultMessage="{status}: {status, select, 401 {No access} 403 {No access} 404 {Not found} other {An error has occurred}}"
				values={{ status }}
			/>
		);
	}

	return <ErrorPageContent header={header} body={body} />;
};
