import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { AbstractInstanceType, SimpleRecord } from "@rest-hooks/rest";

export interface VendorSalesperson {
	readonly key?: ID;
	vendorId?: number;
	salepersonId?: number;
	name: string;
}

export class VendorSalespersonResource
	extends BaseResource
	implements VendorSalesperson
{
	readonly key?: ID;
	vendorId?: number;
	salepersonId?: number;
	name: string;
	pk() {
		return this.key?.toString();
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	) {
		return second;
	}

	static urlRoot = legacyApiResourceUrl(
		"Reassignment.ReassignApplications/GetAvailableSalespesonsWithVendor"
	);
}
