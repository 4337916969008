import { useRef, useLayoutEffect } from "react";
import stickybits from "stickybits";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useParentWidth } from "./useResizeCallback";

const useStickyStyles = makeStyles({
	sticky: (props: { parentWidth: number }) => ({ width: props.parentWidth })
});

export function useSticky() {
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));

	const ref = useRef<HTMLDivElement>(null);
	const parentWidth = useParentWidth(ref);

	const stickyClasses = useStickyStyles({ parentWidth });

	useLayoutEffect(() => {
		if (!ref.current || smDown) return;

		const sticky = stickybits(ref.current, {
			stickyClass: stickyClasses.sticky
		});

		return () => sticky.cleanup();
	}, [smDown, stickyClasses.sticky]);
	return {
		getStickyProps: () => ({
			ref
		})
	};
}
