import React, { useState } from "react";
import { useResource } from "@rest-hooks/core";
import {
	SalespersonResource,
	SalespersonInfo,
	ID,
	LoginResource
} from "@ploy-lib/rest-resources";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { apiResourceUrl } from "@ploy-lib/core";

interface SalespersonSelectProps {
	salespersonId?: ID;
	applicationId?: ID;
}

export const SalespersonSelector = (props: SalespersonSelectProps) => {
	const { salespersonId, applicationId } = props;

	const { vendor: currentVendor } = useResource(LoginResource.status(), {});

	const salespeople = (useResource(SalespersonResource.list(), {
		vendorId: currentVendor?.id
	}) || []) as SalespersonInfo[];

	const [selected, setSelected] = useState(
		salespeople.find(x => x.salespersonId === salespersonId)
	);

	const handleSalespersonChange = (
		e: React.ChangeEvent<{}>,
		value: SalespersonInfo | null
	) => {
		setSelected(value ?? undefined);

		if (!value) return;

		fetch(apiResourceUrl(`applications/${applicationId}/salespersonId`), {
			method: "POST",
			body: new Blob(
				[
					JSON.stringify({
						salespersonId: value.salespersonId
					})
				],
				{
					type: "application/json"
				}
			),
			headers: {
				accept: "application/json"
			}
		});
	};

	return (
		<DployAutocomplete
			style={{ width: 300 }}
			value={selected}
			items={salespeople}
			onChange={handleSalespersonChange}
			fullWidth
			getItemLabel={item =>
				item.fullName || `${item.firstname} ${item.lastname}`
			}
			disableClearable
		/>
	);
};
