import React from "react";

import { Grid } from "@material-ui/core";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Panel } from "../../../panel";
import { actionsTheme } from "./styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { TemplatePanel } from "@ploy-lib/types";
import { FunctionButtonResource } from "../../../components/AppActions";
import { WizardMainAndSummary } from ".";
import { useCache } from "@rest-hooks/core";

export type WizardPanels = {
	main: TemplatePanel;
	summary: TemplatePanel;
	summaryHeader: TemplatePanel;
	actions: TemplatePanel;
	appActions: TemplatePanel;
};
export type WizardPanelKeys = keyof WizardPanels;
export type WizardPage = LayoutPage<WizardPanelKeys, WizardPanels>;

const panelSettings: Record<WizardPanelKeys, PanelSettings> = {
	summaryHeader: {
		defaultOptions: {
			literal: true
		}
	},
	main: {
		editableOptions: {
			maxWidth: true
		}
	},
	summary: {
		editableOptions: {
			hideForMobile: true
		},
		defaultOptions: {
			literal: true
		}
	},
	actions: {},
	appActions: {}
};

addRegisteredLayout({
	name: "Wizard",
	displayName: "Wizard",
	panels: panelSettings,
	navigationButtonsSummary: true,
	displayInStepper: true,
});

export interface WizardProps extends TemplatePageProps<WizardPage> {}

export const Wizard = (props: WizardProps) => {
	const { header, panels } = props;

	const { appActions } = panels;

	const appActionsButtons = useCache(FunctionButtonResource.list(), {});

	const hasActionButtons = (appActionsButtons || []).length > 0;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} container>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						{header}
					</Grid>
					<Grid
						item
						xs={4}
						container
						alignItems="stretch"
						justifyContent="flex-end"
					>
						{hasActionButtons && (
							<ThemeProvider theme={actionsTheme}>
								<Panel
									{...appActions}
									transparent
									disableExpansion
									disableTabs
									fullWidth
									justifyContent="flex-end"
								/>
							</ThemeProvider>
						)}
					</Grid>
				</Grid>
			</Grid>
			<WizardMainAndSummary {...props} />
		</Grid>
	);
};

Wizard.displayName = "DployFormWizard";
