import React from "react";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	bold: {
		fontWeight: "bold"
	}
}));

export function ListCell(props: { rowData: Object; columns: Array<any> }) {
	const { rowData, columns } = props;
	const classes = useStyles(props);

	return (
		<>
			{columns.map((col, i) => {
				return (
					<Grid item container xs={12} key={i}>
						<Box flex="1 1 auto">
							<Typography className={classes.bold}>{col.title}</Typography>
						</Box>
						<Box flex="1 1 auto" textAlign="right">
							{columns[i].render ? (
								columns[i].render(rowData)
							) : (
								<Typography>{rowData[columns[i].field]}</Typography>
							)}
						</Box>
					</Grid>
				);
			})}
		</>
	);
}
