import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import {
	SchemaList,
	AbstractInstanceType,
	RestEndpoint,
	RestFetch,
	SimpleResource
} from "@rest-hooks/rest";

export enum CustomerType {
	NotSet = "NotSet",
	Private = "Private",
	Business = "Business",
	SelfEmployed = "SelfEmployed"
}

export interface ProductFilter {
	customerType?: CustomerType[];
	category?: ProductCategory;
	calculator?: boolean;
	wholesale?: boolean;
	active?: boolean;
	external?: boolean;
	creatable?: boolean;
	customerId?: number | string;
	newapplication?: boolean;
	searchContext?: boolean;
}

export enum ProductType {
	NotSet = "NotSet",
	Loan = "Loan",
	Leasing = "Leasing",
	Limit = "Limit",
	HomeEquityLOC = "HomeEquityLOC",
	Unsecured = "Unsecured",
	Service = "Service"
}

export enum ProductSelectorType {
	NotSet = "NotSet",
	Private = "Private",
	Business = "Business",
	Service = "Service",
	Calculator = "Calculator"
}

export enum ProductCategory {
	Finance = "Finance",
	Service = "Service"
}

export interface Product {
	readonly id?: ID;
	readonly name: string;
	readonly externalCode: string;
	readonly type: ProductType;
	readonly category: ProductCategory;
	readonly active: boolean;
	readonly wholesale: boolean;
	readonly external: boolean;
	readonly selectorType: ProductSelectorType;
}

export class ProductResource extends BaseResource implements Product {
	readonly id?: ID;
	readonly name: string = "";
	readonly externalCode: string = "";
	readonly type: ProductType = ProductType.NotSet;
	readonly category: ProductCategory = ProductCategory.Finance;
	readonly active: boolean = false;
	readonly wholesale: boolean = false;
	readonly external: boolean = false;
	readonly selectorType: ProductSelectorType = ProductSelectorType.NotSet;

	pk() {
		return this.externalCode;
	}

	static list<T extends typeof SimpleResource>(
		this: T
	): RestEndpoint<
		RestFetch<ProductFilter>,
		SchemaList<AbstractInstanceType<T>>,
		undefined
	> {
		return super.list();
	}

	static urlRoot = apiResourceUrl("products");
	static urlTemplates = [
		`${ProductResource.urlRoot}/externalCode/{externalCode}`
	];
}
