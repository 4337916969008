import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { ID } from "../types";

export class BudgetResponsibleResource extends BaseCamelCasedResource {
	readonly userId?: ID;
	readonly email: string = "";
	readonly firstName: string = "";
	readonly lastName: string = "";
	readonly phone: string = "";
	readonly fullName: string = "";

	pk() {
		return "GetBudgetResponsible";
	}

	static readonly automaticValidation = "silent";

	static urlRoot = legacyApiResourceUrl("VulcanData/GetBudgetResponsible");
}
