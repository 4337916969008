import React from "react";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import marked from "@ploy-lib/purify-marked";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { InputFieldProps } from "../types";

const colorVariantsValues = [
	"primary",
	"secondary",
	"warning",
	"default"
] as const; //	"as const" to ensure validation when checking props.color

const markdownClasses = makeStyles((theme: Theme) => ({
	typographyColorVariant: (props: InputFieldProps) => ({
		color:
			!props.color || props.color === "default"
				? "black"
				: props.color === "warning"
				? "orangered"
				: theme.palette[props.color].main
	})
}));

export const MarkdownField = (props: InputFieldProps) => {
	const {
		field: { value: fieldValue, name },
		form: { setFieldValue },
		placeholder,
		hasClick
	} = props;
	const { formatNumber } = useIntl();
	const classes = markdownClasses(props);

	var value = fieldValue || placeholder;

	if (value) {
		value = value.replace(/formatCurrency{(.*?)}/, (a, b) =>
			formatNumber(Number(b), {
				format: "currency",
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			})
		);
		value = value.replace(/formatPercent{(.*?)}/, (a, b) =>
			formatNumber(b / 100, {
				format: "percent",
				minimumFractionDigits: 0,
				maximumFractionDigits: 2
			})
		);
	}

	return value ? (
		<Typography className={classes.typographyColorVariant}>
			<div
				style={hasClick ? { cursor: "pointer" } : undefined}
				onClick={
					hasClick
						? () => setFieldValue(name + "_click", Date.now().toString())
						: undefined
				}
				dangerouslySetInnerHTML={{
					__html: marked(value) || ""
				}}
			/>
		</Typography>
	) : null;
};

export const EditorMarkdownFields = identityRecordOfFieldEditorOptions({
	MarkdownField: {
		editableOptions: {
			placeholder: true,
			color: colorVariantsValues
		}
	}
});
