import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface MenuItem {
	readonly menuItemId: number;
	readonly menuItemName?: string;
	readonly navigateUrl?: string;
	readonly defaultSubItemLookId?: string;
	readonly subGroupCssclass?: string;
	readonly subGroupItemSpacing?: number;
	readonly sortOrder?: number;
	readonly parentId?: number;
	readonly functionId?: number;
	readonly autoPostBackOnSelect?: boolean;
	readonly description?: string;
	readonly navigateUrlexternal?: string;
	readonly icon?: string;
	readonly pageComponent?: string;
}

export class MenuItemResource
	extends BaseCamelCasedResource
	implements MenuItem
{
	readonly menuItemId: number = 0;
	readonly menuItemName?: string = "";
	readonly navigateUrl?: string = "";
	readonly defaultSubItemLookId?: string = "";
	readonly subGroupCssclass?: string = "";
	readonly subGroupItemSpacing?: number = 0;
	readonly sortOrder?: number = 0;
	readonly parentId?: number = 0;
	readonly functionId?: number = 0;
	readonly autoPostBackOnSelect?: boolean = false;
	readonly description?: string = "";
	readonly navigateUrlexternal?: string = "";
	readonly icon?: string = "";
	readonly pageComponent?: string = "";

	pk() {
		return this.menuItemId.toString() || "";
	}

	static urlRoot = apiResourceUrl("menuitems");
}

export interface MenuItemSub extends MenuItem {
	readonly produtType?: number;
	readonly enabled?: boolean;
	readonly lookId?: string;
	readonly type?: string | number;
	readonly menuItemLabel?: string;
}

export class MenuItemSubResource
	extends MenuItemResource
	implements MenuItemSub
{
	readonly produtType?: number = 0;
	readonly enabled?: boolean = false;
	readonly lookId?: string = "";
	readonly type?: string | number = "";
	readonly menuItemLabel?: string;

	pk() {
		return this.menuItemId.toString() || "";
	}

	static urlRoot = apiResourceUrl("menuitems/menuSubs");
}
