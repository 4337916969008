import { Namespaced } from "./types";
import { isNotNull } from "./utils";
import mapValues from "lodash/mapValues";

const macroKeyRegex = /.*[$?]([a-zA-Z]+).*/;
const macroReplaceRegex = /[$?][a-zA-Z]+/;

function getMacroKey(str: string) {
	const match = str.match(macroKeyRegex);

	if (match) {
		return match[1];
	}
}

function replaceMacroKey(str: string, index: number) {
	return str.replace(macroReplaceRegex, index.toString());
}

export interface MacroUnroller {
	<T extends { macro?: string; macroIndex?: number | string }>(list: T[]): T[];
	<T extends {}>(list: T[], mainProperty: keyof T): T[];
}

export function createMacroUnroller<TNamespaces extends string>(
	namespace: TNamespaces,
	macros?: Namespaced<number, TNamespaces>
): MacroUnroller {
	const nsMacros = macros && macros[namespace];
	const macroMap = new Map(nsMacros && Object.entries(nsMacros));

	return function unrollMacros<
		T extends { macro?: string; macroIndex?: number | string }
	>(list: T[] = [], mainProperty?: keyof T): T[] {
		const macroItems = list
			.map((original, index) => {
				const macro = mainProperty
					? getMacroKey((original[mainProperty] as any).toString())
					: original.macro;
				return macro ? { original, macro, index } : null;
			})
			.filter(isNotNull);

		if (macroItems.length > 0) {
			const newList = [...list];

			// Splice unrolled items into the array, replacing the original.
			// From highest to lowest index, so we dont have to
			// account for the size changing when splicing.
			macroItems.reverse().forEach(({ original, macro, index }) => {
				const length = (macroMap.get(macro) || 0) + 1;

				const macroIndex = Number(original.macroIndex);

				const unrolled = Array.from({ length }, (_, i) => {
					if (!Number.isNaN(macroIndex) && macroIndex !== i) return null;
					return mapValues<T, any>(original, v =>
						typeof v === "string" ? replaceMacroKey(v, i) : v
					) as T;
				}).filter(isNotNull);

				newList.splice(index, 1, ...unrolled);
			});

			return newList;
		}

		return list;
	};
}
