import { Endpoint } from "@rest-hooks/endpoint";

export interface StandardText {
	standardText: string;
	standardTextGroup: string;
	appliesTo?: string;
	addAutomatically?: boolean;
}

const fetchStandardText = (data: {
	applicationNumber: string;
	code: string;
}): Promise<StandardText[]> =>
	fetch(
		`/api/applications/${data.applicationNumber}/standard-text/${data.code}`
	).then(res => {
		if (res.ok) return res.json();
		return [];
	});

export const StandardTextListEndpoint = new Endpoint(fetchStandardText, {});
