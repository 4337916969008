import { useMemo } from "react";

import {
	convertTemplate,
	convertTemplateField,
	mapAllTemplateFields
} from "@ploy-lib/template-converter";
import { FormTemplate, TemplateField } from "@ploy-lib/types";
import { EditorFields } from "@ploy-ui/template-form";

const identityToArray = <T>(i: T) => [i];
const emptyArray = [];

const convertField = (f: TemplateField) => [
	convertTemplateField(f, Object.keys(EditorFields))
];

export const useTemplateWithDefaults = (
	formTemplate: FormTemplate | null | undefined,
	mapTemplateFieldDefaults: (
		field: TemplateField
	) => TemplateField[] = identityToArray,
	formContext?: string,
	disallowedFieldRoles: readonly string[] = emptyArray
) => {
	const templateWithDefaults = useMemo(() => {
		if (formTemplate) {
			// Convert deprecated template layout formats
			let template = convertTemplate(formTemplate, formContext);

			// Add field defaults from DR CustomGuiFields,
			// must be done after converting the template layouts,
			// because new fields may have been added.
			template = mapAllTemplateFields(template, mapTemplateFieldDefaults);

			// Convert deprecated field types and options,
			// must be done after setting defaults from DR,
			// since these are also in the old format.
			template = mapAllTemplateFields(template, convertField);

			// Possibly remove fields with certain roles, relevant in snippet, etc.
			template = mapAllTemplateFields(template, f => {
				if (f.role && disallowedFieldRoles.includes(f.role)) {
					return [];
				}

				return [f];
			});

			return template;
		}
	}, [
		formTemplate,
		formContext,
		mapTemplateFieldDefaults,
		disallowedFieldRoles
	]);

	return templateWithDefaults;
};
