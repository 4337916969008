import React from "react";
import { useFormikContext } from "formik";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";

export function FormikSubmitButton(props: PendingButtonProps) {
	const formik = useFormikContext();

	return (
		<PendingButton
			{...props}
			type="submit"
			success={false}
			pending={formik.isSubmitting}
			onClick={formik.submitForm}
		/>
	);
}

export function FormikResetButton(props: PendingButtonProps) {
	const formik = useFormikContext();

	return (
		<PendingButton
			{...props}
			type="reset"
			success={false}
			disabled={formik.isSubmitting}
			onClick={formik.handleReset}
		/>
	);
}
