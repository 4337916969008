import React from "react";
import { WholesaleCar } from "@ploy-lib/rest-resources";
import { Grid, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const WholesaleOverviewStatus = (props: WholesaleCar) => {
	const {
		ShowEndDateStatus,
		DaysUntilEndDate,
		DaysUntilEndDateStatus,
		StatusName
	} = props;

	if (!DaysUntilEndDateStatus || !ShowEndDateStatus) {
		return <></>;
	}

	return (
		<Grid
			container
			spacing={1}
			direction="row"
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid item>{DaysUntilEndDate}</Grid>
			<Grid item>
				<Tooltip placement="bottom" arrow title={StatusName}>
					<Skeleton
						style={{ backgroundColor: DaysUntilEndDateStatus }}
						variant="circle"
						width={16}
						height={16}
						animation={false}
					/>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

export { WholesaleOverviewStatus };
