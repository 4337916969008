import {
	useResource,
	ReadShape,
	ParamsFromShape,
	useInvalidator
} from "@rest-hooks/core";
import { useEffect } from "react";

type ResourceArgs<
	S extends ReadShape<any, any>,
	P extends ParamsFromShape<S> | null
> = readonly [S, P];

export const useResourceWithInvalidate: typeof useResource = <
	Shape extends ReadShape<any, any>,
	Params extends ParamsFromShape<Shape> | null
>(
	...args: ResourceArgs<Shape, Params> | ResourceArgs<Shape, Params>[]
): any => {
	const ret = (useResource as any)(...args);

	const resourceArgs = Array.isArray(args[0])
		? (args as ResourceArgs<Shape, Params>[])
		: [args as ResourceArgs<Shape, Params>];

	for (const [shape, params] of resourceArgs) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useInvalidateOnUnmount(shape, params);
	}

	return ret as any;
};

export function useInvalidateOnUnmount<
	Shape extends ReadShape<any, any>,
	Params extends ParamsFromShape<Shape> | null
>(shape: Shape, params: Params) {
	const invalidate = useInvalidator(shape);

	useEffect(
		() => () => {
			if (params != null) invalidate(params);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[invalidate, params && shape.getFetchKey(params)]
	);
}
