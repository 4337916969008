import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export enum PasswordPolicyTimeUnit {
	Minute = "minute",
	Hour = "hour",
	Day = "day",
	Week = "week",
	Month = "month",
	Year = "year"
}

export interface PasswordPolicyDTO {
	oneTimePasswordTimeoutEnabled: boolean;
	oneTimePasswordTimeoutValue?: number;
	oneTimePasswordTimeoutUnit?: PasswordPolicyTimeUnit;

	passwordTimeoutEnabled: boolean;
	passwordTimeoutValue?: number;
	passwordTimeoutUnit?: PasswordPolicyTimeUnit;

	passwordFailAttemptValue: number;

	useForbiddenPasswordList: boolean;

	passwordHistoryEnabled: boolean;
	passwordHistoryCount?: number;

	passwordPolicyLengthEnabled: boolean;
	passwordPolicyLengthValue?: number;

	passwordPolicyMinRequiredCriteria?: number;

	passwordPolicySmallLettersEnabled: boolean;
	passwordPolicySmallLettersValue?: number;

	passwordPolicyBigLettersEnabled: boolean;
	passwordPolicyBigLettersValue?: number;

	passwordPolicyNumbersEnabled: boolean;
	passwordPolicyNumbersValue?: number;

	passwordPolicySpecialLettersEnabled: boolean;
	passwordPolicySpecialLettersValue?: number;

	passwordValidationErrorMessage?: string;
}

export class PasswordPolicyResource
	extends BaseCamelCasedResource
	implements PasswordPolicyDTO
{
	readonly oneTimePasswordTimeoutEnabled: boolean;
	readonly oneTimePasswordTimeoutValue?: number;
	readonly oneTimePasswordTimeoutUnit?: PasswordPolicyTimeUnit;

	readonly passwordTimeoutEnabled: boolean;
	readonly passwordTimeoutValue?: number;
	readonly passwordTimeoutUnit?: PasswordPolicyTimeUnit;

	readonly passwordFailAttemptValue: number;

	readonly useForbiddenPasswordList: boolean;

	readonly passwordHistoryEnabled: boolean;
	readonly passwordHistoryCount?: number;

	readonly passwordPolicyLengthEnabled: boolean;
	readonly passwordPolicyLengthValue?: number;

	readonly passwordPolicyMinRequiredCriteria?: number;

	readonly passwordPolicySmallLettersEnabled: boolean;
	readonly passwordPolicySmallLettersValue?: number;

	readonly passwordPolicyBigLettersEnabled: boolean;
	readonly passwordPolicyBigLettersValue?: number;

	readonly passwordPolicyNumbersEnabled: boolean;
	readonly passwordPolicyNumbersValue?: number;

	readonly passwordPolicySpecialLettersEnabled: boolean;
	readonly passwordPolicySpecialLettersValue?: number;

	readonly passwordValidationErrorMessage?: string;

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static urlRoot = apiResourceUrl("password-policy");
}
