import React, { Suspense } from "react";
import ContentBox from "../../ContentBox";
import ContentListItem from "../../ContentListItem";
import { List, Divider, Box } from "@material-ui/core";
import { LinkItemResource, LinkItemListKey } from "@ploy-lib/rest-resources";
import { useCache } from "@rest-hooks/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import { useMaxItems } from "../../hooks/useMaxItems";
import { GridComponent } from "../types";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { LinkListItems } from "./LinkListItems";
import { defineMessages } from "react-intl";

export const sources: LinkItemListKey[] = [
	"linksForVendor",
	"productInfoForUser"
];

export const sourceMapTitles = defineMessages({
	linksForVendor: {
		id: "dploy.grid.links.source.option.linksForVendor.label",
		defaultMessage: "Links for vendor"
	},
	productInfoForUser: {
		id: "dploy.grid.links.source.option.productInfoForUser.label",
		defaultMessage: "Product info for user"
	}
});

export interface LinkListItemsProps extends LinksProps {
	page?: number;
	pageSize?: number;
}

export interface LinksProps {
	source?: LinkItemListKey;
	emptyMessage?: string;
}

const Links: GridComponent<
	LinksProps,
	ReturnType<typeof resources>,
	ReturnType<typeof resources>
> = props => {
	const { boxProps, emptyMessage, source, placeholder } = props;

	const links = useCache(...resources(props)[0]);

	const [page, setPage] = React.useState(1);
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const [listRef, pageSize] = useMaxItems({
		itemHeight: 36,
		divider: true,
		wait: 500
	});
	// const pageSize = 4;

	const animation = !placeholder && undefined;
	const fallback = (
		<>
			<ContentListItem
				primary={<Skeleton animation={animation} variant="text" width="80%" />}
				chevronIcon={<OpenInNewIcon fontSize="small" />}
			/>
			<Divider />
			<ContentListItem
				primary={<Skeleton animation={animation} variant="text" width="80%" />}
				chevronIcon={<OpenInNewIcon fontSize="small" />}
			/>
		</>
	);

	return (
		<ContentBox
			{...boxProps}
			actions={
				links && pageSize && links.length > pageSize ? (
					<Pagination
						count={Math.ceil(links?.length / pageSize)}
						page={page}
						onChange={handleChange}
					/>
				) : null
			}
		>
			<Box height="100%" clone>
				<List component="nav" dense ref={listRef}>
					{placeholder ? (
						fallback
					) : (
						<Suspense fallback={fallback}>
							<LinkListItems
								page={page}
								pageSize={pageSize}
								source={source}
								emptyMessage={emptyMessage}
								placeholder={placeholder}
							/>
						</Suspense>
					)}
				</List>
			</Box>
		</ContentBox>
	);
};

export const resources = ({ source }: LinksProps) =>
	[
		[
			source && LinkItemResource[source]
				? (LinkItemResource[source] as typeof LinkItemResource["list"])()
				: LinkItemResource.list(),
			source ? {} : null
		]
	] as const;

Links.getPrefetch = resources;
Links.getVisibilityFetch = resources;
Links.getVisibility = (props, [links]) => links != null && links.length > 0;
Links.displayName = "DployDashboardLinks";

export default Links;
