import React, { useCallback, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles, getContrastRatio } from "@material-ui/core/styles";
import { useTenantImage } from "@ploy-ui/tenants";
import { ImageFit, PendingButtonProps } from "@ploy-ui/core";
import Typography from "@material-ui/core/Typography";
import { HeaderIcon, SubmitButton, SubmitStatusMessage } from "./components";
import {
	Formik,
	Field,
	Form,
	connect,
	FieldAttributes,
	FormikHelpers,
	FormikErrors
} from "formik";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	link: {
		color:
			getContrastRatio(theme.palette.primary.main, theme.palette.common.white) <
			2.5
				? theme.palette.text.secondary
				: theme.palette.primary.main,
		textDecoration: "none"
	},
	forgotten: {
		marginTop: 10.5
	},
	shrink: {
		transform: "translate(0, -20px) !important"
	},
	img: {
		width: "100%",
		height: 120
	},
	headerText: { textAlign: "center", marginBottom: "50px" }
}));

const AutoSubmit = connect(({ formik }) => {
	const { values, submitForm } = formik;

	const isMounting = useRef(true);

	useEffect(() => {
		if (!isMounting.current) submitForm();

		isMounting.current = false;
	}, [values, submitForm]);

	return null;
});

export interface FormBaseProps<ValueType> {
	submit: (
		values: ValueType,
		formik: FormikHelpers<ValueType>
	) => Promise<{ error?: string }>;
	initValues: ValueType;
	fieldConfig: FieldAttributes<any>[];
	submitButtonText: string;
	submitButtonClasses?: PendingButtonProps["classes"];
	hideHeaderText?: boolean;
	icon?: string;
	headerText?: string;
	children?: React.ReactNode;
	autoSubmit?: boolean;
	SubmitButtonProps?: PendingButtonProps;
}

const FormBase = <ValueType extends {}>(props: FormBaseProps<ValueType>) => {
	const {
		submit,
		initValues,
		fieldConfig,
		submitButtonText,
		submitButtonClasses,
		SubmitButtonProps,
		hideHeaderText = false,
		icon = "lock",
		headerText,
		children,
		autoSubmit = false
	} = props;
	const classes = useStyles();

	const onSubmit = useCallback(
		async (values: ValueType, formik: FormikHelpers<ValueType>) => {
			try {
				formik.setStatus({});
				const { error } = await submit(values, formik);

				if (error) {
					const errors: { [key: string]: string | boolean } = {};
					const valueKeys = Object.keys(initValues);

					valueKeys.forEach((value, id) => {
						errors[value] = id === valueKeys.length - 1 ? error : true;
					});
					formik.setErrors(errors as FormikErrors<ValueType>);
				}
			} catch (error: any) {
				formik.setStatus({
					submit: {
						status: "error",
						message: (
							<FormattedMessage
								id="dealer.login.login.error"
								description="An error occurred"
								defaultMessage="An error has occured"
							/>
						)
					}
				});
			}
		},
		[initValues, submit]
	);

	const loginLogo = useTenantImage("loginLogo");

	return (
		<div>
			{loginLogo ? (
				<>
					<ImageFit
						fit="contain"
						src={loginLogo}
						alt="logo"
						className={classes.img}
					/>
					{!hideHeaderText && (
						<Typography className={classes.headerText} variant="h6">
							<FormattedMessage
								id="external.login.header"
								description="Header on forhandlerportal login"
								defaultMessage="DEALER PORTAL"
							/>
						</Typography>
					)}
				</>
			) : headerText ? (
				<HeaderIcon text={headerText} />
			) : (
				<HeaderIcon icon={icon} />
			)}
			<Formik initialValues={initValues} onSubmit={onSubmit}>
				<Form noValidate>
					<Grid container spacing={2}>
						{fieldConfig.map((field, idx) => (
							<Grid key={field.name} item xs={12}>
								<Field key={`${idx}`} {...field} />
							</Grid>
						))}

						{autoSubmit && <AutoSubmit />}

						<Grid item xs={12}>
							<SubmitButton
								classes={submitButtonClasses}
								size="large"
								variant="contained"
								color="secondary"
								fullWidth
								{...SubmitButtonProps}
							>
								{submitButtonText}
							</SubmitButton>
						</Grid>
						<Grid item xs={12}>
							<SubmitStatusMessage />
						</Grid>
					</Grid>
				</Form>
			</Formik>
			{children}
		</div>
	);
};

export { FormBase };
