import qs from "qs";
import {
	useLocation,
	useParams,
	useResolvedPath,
	UNSAFE_NavigationContext as NavigationContext,
	To
} from "react-router-dom";
import mapValues from "lodash/mapValues";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import { useContext } from "react";

export function createSearchParams(params: any): string {
	return qs.stringify(params, {
		addQueryPrefix: false,
		allowDots: true
	});
}

export function parseSearchParams<Query extends {}>(search: string): Query {
	return qs.parse(search, {
		ignoreQueryPrefix: true,
		allowDots: true
	}) as Query;
}

export function useQuery<Query extends {}>(): Query {
	const { search } = useLocation();

	return parseSearchParams(search);
}

export function parseValues<T>(params: any): T {
	const handleValue = (v: any): any => {
		// Boolean
		if (v.toLowerCase?.() === "false") return false;
		if (v.toLowerCase?.() === "true") return true;

		// Number
		const num = Number.parseFloat(v);
		if (!Number.isNaN(num) && num.toString() === v) return num;

		if (isArray(v)) return v.map(handleValue);
		if (isObject(v)) return mapValues(v, handleValue);

		return v;
	};
	return mapValues(params, handleValue);
}

export function useParsedQuery<SearchParams extends {}>(): SearchParams {
	return parseValues(useQuery());
}

export function useParsedQueryAndParams<
	AllParams extends {} = any,
	P extends {} = {}
>(props?: P): AllParams & P {
	return {
		...parseValues({
			...useQuery(),
			...useParams()
		}),
		...props
	} as any;
}

export function useAbsolutePath(to: To) {
	const { navigator } = useContext(NavigationContext);
	const base = useResolvedPath(to);
	return navigator.createHref(base);
}
