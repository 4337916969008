import { Variable, Resolve, Validator } from "@ploy-lib/types";
import {
	GeneratedCalcRule,
	Namespaced,
	VariableField,
	CtrlField
} from "./types";
import { createInitialize } from "./createInitialize";
import { createUpdate } from "./createUpdate";
import { createCalculate, Calculate } from "./createCalculate";
import { createValidate, Validate } from "./createValidate";
import { createFieldResolver } from "./createFieldResolver";
import { createMacroUnroller } from "./createMacroUnroller";
import { isNotNull } from "./utils";
import { createIsFieldVisibleIn } from "./createIsFieldVisibleIn";
import {
	ServiceBodyType,
	ServiceBodyField,
	ServiceBodyVariable
} from "../types";
import { ValidationHelpers } from "@ploy-lib/validation-helpers";

const identity = (i => i) as any;

export function createCalculatorFunctions<TNamespaces extends string, TData>(
	namespace: TNamespaces,
	generatedCalcrules: Partial<Record<TNamespaces, GeneratedCalcRule<TData>>>,
	validationHelpers: ValidationHelpers,
	variableDefs: Record<TNamespaces, Variable[]>,
	validatorDefs: Record<TNamespaces, Validator[]>,
	resolveDefs: Partial<Record<string, Resolve<TNamespaces>>> = {},
	macros?: Namespaced<number, TNamespaces>,
	serviceBodyFieldsMap: Partial<Record<TNamespaces, ServiceBodyField[]>> = {}
) {
	const fieldResolver = createFieldResolver(resolveDefs);
	const macroUnroller = createMacroUnroller(namespace, macros);
	const isFieldVisibleIn = createIsFieldVisibleIn(namespace, fieldResolver);

	const variables = macroUnroller(variableDefs[namespace], "name");
	const validators = macroUnroller(validatorDefs[namespace]);

	const variableMap = variables.reduce((acc, v) => {
		acc[v.name] = v;
		return acc;
	}, {} as Record<string, Variable>);

	const variablesWithControl = variables.filter(v => v.controlID != null);

	const serviceBodyFields = (serviceBodyFieldsMap[namespace] ||
		[]) as ServiceBodyField[];
	const serviceBodyVariables = variables
		.filter(
			v =>
				v.explicitPostBack && !serviceBodyFields.some(f => f.field === v.name)
		)
		.map<ServiceBodyVariable>(v => ({
			namespace,
			variable: v.name,
			type: v.typeName
				? ServiceBodyType.Object
				: v.isNumeric
				? ServiceBodyType.Number
				: ServiceBodyType.Text,
			typeName: v.typeName
		}));

	const variableFields = variablesWithControl.map(
		({ controlID, name, overrideIndex }) => {
			const resolvedVariableField = fieldResolver(controlID!, name);

			if (resolvedVariableField) return resolvedVariableField;

			return {
				resolved: controlID,
				ctrl: controlID,
				variable: name,
				overrideIndex
			} as VariableField;
		}
	);

	const validatorCtrls = validators.flatMap(({ errorField }) => {
		const ctrls = errorField ? errorField.split(",") : [];

		return ctrls.map(controlID => {
			const resolvedVariableField = fieldResolver(controlID);

			if (resolvedVariableField) return resolvedVariableField;

			return {
				resolved: controlID,
				ctrl: controlID
			} as CtrlField;
		});
	});

	const calcrule = generatedCalcrules[namespace];

	const calculate = isNotNull(calcrule)
		? createCalculate(namespace, calcrule, validationHelpers, isFieldVisibleIn)
		: (identity as Calculate<TNamespaces, TData>);

	const validate = isNotNull(calcrule)
		? createValidate(namespace, calcrule, validationHelpers, isFieldVisibleIn)
		: (identity as Validate<TNamespaces, TData>);

	const variablesWithMaybeResolve = variables.map(variable => {
		const resolve = variable.controlID
			? fieldResolver(variable.controlID, variable.name)
			: undefined;
		return { variable, resolve };
	});

	return {
		namespace,
		variableMap,
		initialize: createInitialize<TData, TNamespaces>(
			namespace,
			validatorCtrls.concat(variableFields),
			serviceBodyVariables,
			serviceBodyFields
		),
		update: createUpdate<TData, TNamespaces>(
			namespace,
			variablesWithMaybeResolve
		),
		updateResolves: createUpdate<TData, TNamespaces>(
			namespace,
			variablesWithMaybeResolve.filter(x => x.resolve)
		),
		calculate,
		validate
	};
}
