import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";

export interface VendorDocumentGroup {
	documentCode: string;
	description: string;
	documentName: string;
	mustSign: boolean;
	allowMultipleUploads: boolean;
	isDefaultGroup: boolean;
}

export class VendorDocumentGroupResource
	extends BaseCamelCasedResource
	implements VendorDocumentGroup
{
	readonly documentCode: string = "";
	readonly description: string = "";
	readonly documentName: string = "";
	readonly mustSign: boolean = false;
	readonly allowMultipleUploads: boolean = false;
	readonly isDefaultGroup: boolean = false;

	pk() {
		return this.documentCode;
	}

	static urlRoot = legacyApiResourceUrl(
		"DocumentGrid/GetVendorDocumentGroupsJson"
	);
}
