import React, {
	useEffect,
	useState,
	useCallback,
	memo,
	useMemo,
	Suspense
} from "react";
import {
	//makeStyles,
	Box,
	FormControl,
	FormHelperText,
	FormLabel,
	Hidden
} from "@material-ui/core";
import { DataTable } from "@ploy-ui/core";
import {
	HasItems,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";
import { FieldProps } from "formik";
import { identity } from "@ploy-ui/form-fields";
import { FormattedDate } from "react-intl";
import { Cells } from "@ploy-ui/core";
import { TextLiteral } from "./literals";
import { LiteralProps } from "./literals/TextLiteral";

//const useStyles = makeStyles(theme => ({}));

interface RowData {
	[k: string]: string | number | boolean | Date | undefined;
}

interface OtherProps {
	disabled?: boolean;
	label?: string;
	className?: string;
	pending?: boolean;
}

export type SearchSelectListProps = FieldProps &
	Partial<HasItems<RowData>> &
	OtherProps;

export type SearchSelectListPropsInternal = Pick<
	FieldProps["form"],
	"setFieldValue"
> &
	Pick<FieldProps["field"], "name" | "value"> &
	Partial<HasItems<RowData>> &
	OtherProps;

const emptyArray = [];

const SearchSelectListInternal = memo(
	(props: SearchSelectListPropsInternal) => {
		const {
			items: defaultItems = emptyArray,
			getItemSuggestions,
			getItemValue = identity,
			onSelectItem,
			name: fieldName,
			value: fieldValue,
			setFieldValue,
			label,
			className,
			pending
		} = props;
		//const classes = useStyles(props);

		const [itemSuggestions, setItemSuggestions] = useState<
			SearchSelectListProps["items"] | null
		>(null);

		const [internalLoading, setInternalLoading] = useState<boolean>(false);

		useEffect(() => {
			if (getItemSuggestions) {
				const fetchItems = async () => {
					const newItems = await getItemSuggestions("");
					setItemSuggestions(newItems);
					setInternalLoading(false);
				};

				setInternalLoading(true);
				fetchItems();
			}
		}, [getItemSuggestions]);

		const loading = pending || internalLoading;

		const onChange = useCallback(
			item => {
				if (onSelectItem) onSelectItem(item);
				setFieldValue(fieldName, getItemValue(item));
			},
			[setFieldValue, fieldName, getItemValue, onSelectItem]
		);

		const remappedItems = useMemo(() => {
			const items =
				itemSuggestions && itemSuggestions.length > 0
					? itemSuggestions
					: defaultItems;

			return items.map(i => {
				return {
					...i,
					CreatedDate: new Date(Date.parse(i["CreatedDate"] as string))
				} as RowData;
			});
		}, [defaultItems, itemSuggestions]);

		const mappedSelectedItems = useMemo(() => {
			return !fieldValue
				? remappedItems
				: (remappedItems.map(i => {
						return {
							...i,
							tableData: {
								checked: getItemValue(i) === fieldValue
							}
						} as unknown as RowData;
				  }) as RowData[]);
		}, [fieldValue, getItemValue, remappedItems]);

		const columnSettings = useMemo(
			() =>
				[
					{
						field: "ProductName",
						render: row => (
							<div>
								<Box fontWeight="fontWeightBold">{row["ProductName"]}</Box>
								<Box>{row["CarBrandAndModel"]}</Box>
							</div>
						)
					},
					{
						field: "CreatedDate",
						render: row => (
							<FormattedDate value={row["CreatedDate"]} format="short" />
						)
					},
					{
						field: "DocumentStatusNameExternal",
						render: row => (
							<>
								<Hidden lgUp smDown>
									<Cells.ColoredStatus
										rowData={row}
										statusKey={"ApplicationStatusID"}
										mr={1}
									></Cells.ColoredStatus>
								</Hidden>
								<Hidden only={"md"}>
									<Cells.ColoredStatus
										rowData={row}
										statusKey={"ApplicationStatusID"}
										mr={1}
									>
										{row["DocumentStatusNameExternal"]}
									</Cells.ColoredStatus>
								</Hidden>
							</>
						)
					}
				].map(c => ({ ...c, cellStyle: { padding: "12px 6px" } })),
			[]
		);

		const options = {
			selection: true,
			showFirstLastPageButtons: false,
			emptyRowsWhenPaging: false
		};

		const onRowClick = useCallback(
			(e, rowData) => onChange(rowData),
			[onChange]
		);

		const onSelectionChange = useCallback(
			rows => {
				let itemToSet: typeof rows[0] | null = null;
				if (rows.length > 0) {
					const [first, second = null] = rows;
					itemToSet = first;
					if (second && fieldValue && getItemValue(first) === fieldValue) {
						itemToSet = second;
					}
				}
				onChange(itemToSet);
			},
			[fieldValue, getItemValue, onChange]
		);

		const error = "";

		return (
			<FormControl variant="standard" className={className} fullWidth>
				{label && <FormLabel>{label}</FormLabel>}

				<Suspense fallback={null}>
					<DataTable
						isLoading={loading}
						columns={columnSettings}
						data={mappedSelectedItems}
						onRowClick={onRowClick}
						onSelectionChange={onSelectionChange}
						options={options}
						components={{
							Header: () => null,
							Toolbar: () => null
						}}
					/>
				</Suspense>
				{mappedSelectedItems.length === 0 && (
					<FormHelperText>Ingen resultat</FormHelperText>
				)}
				{error && <FormHelperText error>{error}</FormHelperText>}
			</FormControl>
		);
	}
);

const SearchSelectList = (props: SearchSelectListProps) => {
	const { form, field, ...rest } = props;

	const { name, value } = field;
	const { setFieldValue } = form;

	return props.disabled ? (
		<TextLiteral
			{...(props as unknown as LiteralProps)}
			literalVariant="plain"
		/>
	) : (
		<SearchSelectListInternal
			{...rest}
			name={name}
			value={value}
			setFieldValue={setFieldValue}
		/>
	);
};

SearchSelectList.displayName = "DploySearchSelectList";

export const EditorSearchSelectFields = identityRecordOfFieldEditorOptions({
	SearchSelectField: {}
});

export { SearchSelectList };
