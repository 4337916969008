import * as React from "react";

import { Paper, Grid, Theme } from "@material-ui/core";
import MuiTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { PendingButton } from "@ploy-ui/core";

import { Panel } from "../../panel";
import { TemplatePageProps, LayoutPage } from "../../types";
import { makeStyles } from "@material-ui/core/styles";
import { usePageState } from "../../PageContext";
import { addRegisteredLayout } from "./layoutDescriptions";
import { TemplatePanel } from "@ploy-lib/types";

const useStyles = makeStyles((theme: Theme) => ({
	mainSections: {
		padding: theme.spacing(10),
		paddingTop: 0
	},
	sidebar: {
		padding: theme.spacing(4)
	}
}));

export type TabsPanelsKeys = "main" | "summary" | "summaryHeader";
export type TabsPanels = Record<TabsPanelsKeys, TemplatePanel>;
export type TabsPage = LayoutPage<TabsPanelsKeys, TabsPanels>;

addRegisteredLayout({
	name: "Tabs",
	displayName: "Tabs",
	panels: { main: {}, summaryHeader: {}, summary: {} }
});

export function Tabs(props: TemplatePageProps<TabsPage>) {
	const classes = useStyles(props);
	const { header, panels } = props;

	const { step, goto, labels } = usePageState();

	const main = panels.main;
	const summary = panels.summary;
	const summaryHeader = panels.summaryHeader;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} container spacing={3}>
				<Grid item xs={4}>
					{header}
				</Grid>
			</Grid>
			<Grid item md={8} container spacing={3} direction="column">
				<Grid item>
					<Paper>
						<MuiTabs
							value={step}
							onChange={(e, value) => goto(value)}
							indicatorColor="primary"
							textColor="primary"
							variant="fullWidth"
						>
							{labels.map((label, i) => (
								<Tab key={label || i} label={label || i} />
							))}
						</MuiTabs>
						{main && <Panel className={classes.mainSections} {...main} />}
					</Paper>
				</Grid>
			</Grid>
			{(summaryHeader || summary) && (
				<Grid item md={4} container spacing={3} direction="column">
					{summaryHeader && (
						<Grid item>
							<Panel
								className={classes.sidebar}
								elevation={2}
								{...summaryHeader}
							/>
						</Grid>
					)}
					<Grid item>
						<PendingButton
							variant="contained"
							color="secondary"
							// type="submit"
							// pending={Boolean(form.asyncValidating) || form.submitting}
							fullWidth
						>
							Ny søknad
						</PendingButton>
					</Grid>
					{summary && (
						<Grid item>
							<Panel className={classes.sidebar} elevation={2} {...summary} />
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	);
}
