import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import {
	DployTextField,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";
import { CommentType } from "@ploy-lib/rest-resources";
import { useDispatch, useVariableData } from "@ploy-lib/calculation";
import { InputFieldProps } from "../../types";
import { FormattedMessage } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const commentTypeEnumKeyLabels = Object.keys(CommentType).filter(
	x => typeof CommentType[x as any] === "string"
);

type Comments = Record<string, string>;

interface CommentFieldProps
	extends InputFieldProps<Comments | undefined | null> {
	placeholder?: string;
	label?: string;
	literal?: boolean;
	options: {
		textAreaRows?: number;
		commentType: string;
		alwaysEnabled?: boolean;
	};
}

const emptyComments: Readonly<Comments> = {};

export const CommentField = (props: CommentFieldProps) => {
	const {
		label,
		options: { commentType, textAreaRows, alwaysEnabled },
		literal,
		form: { setFieldValue },
		field
	} = props;

	const dispatch = useDispatch();
	const commentsVariable = useVariableData<Comments | undefined | null>(
		"Main",
		"Comments"
	);

	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [showExpandButton, setShowExpandButton] = useState(false);
	const [expandRows, setExpandRows] = useState(false);

	const camelCasedCommentType =
		commentType.charAt(0).toLowerCase() + commentType.slice(1);

	const caseAdjustedCommentType = commentsVariable.value
		? commentType
		: camelCasedCommentType;

	const namedComments = commentsVariable?.value ?? field.value ?? emptyComments;

	const [stringValueOfComment, setStringValueOfComment] = useState(
		namedComments[caseAdjustedCommentType]
	);

	const textAreaIsLiteral = !alwaysEnabled && literal;

	useEffect(() => {
		if (
			!expandRows &&
			textAreaRef.current!.scrollHeight > textAreaRef.current!.clientHeight
		) {
			if (textAreaIsLiteral) setShowExpandButton(true);
			else setExpandRows(true);
		}
	}, [expandRows, stringValueOfComment, textAreaIsLiteral]);

	useEffect(() => {
		textAreaRef.current!.focus();
		textAreaRef.current!.selectionStart =
			textAreaRef.current?.value.length || 0;
	}, [expandRows, textAreaRef]);

	const updateComments = (newCommentValue: string) => {
		let updatedComments = {
			...namedComments,
			[caseAdjustedCommentType]: newCommentValue
		};
		setStringValueOfComment(newCommentValue);

		if (field?.name) {
			setFieldValue(field.name, updatedComments);
		}
		dispatch({
			type: "patch",
			payload: {
				patches: [
					{
						target: "Comments",
						namespace: "Main",
						value: updatedComments,
						overwrite: true
					}
				]
			}
		});
	};

	return (
		<Grid item xs={12}>
			<DployTextField
				style={{ width: "100%" }}
				inputRef={textAreaRef}
				id={label}
				data-cr-field={label}
				label={label}
				value={stringValueOfComment}
				onChange={e => updateComments(e.target.value)}
				multiline
				rowsMax={
					expandRows || !textAreaIsLiteral ? undefined : textAreaRows || 10
				}
				rows={expandRows ? undefined : textAreaRows || 10}
				disabled={textAreaIsLiteral}
				margin="normal"
				variant={textAreaIsLiteral ? "noBorder" : "input"} //	"input" is default
			/>
			{showExpandButton && !expandRows && (
				<Button
					variant="outlined"
					style={{ textTransform: "none", padding: "0px 5px" }}
					onClick={() => setExpandRows(!expandRows)}
				>
					<FormattedMessage
						id="ploy-ui.template-form.CommentField.show-more-button"
						defaultMessage="Vis mer"
						description="Button to show more text in CommentField"
					/>
					<KeyboardArrowDownIcon />
				</Button>
			)}
		</Grid>
	);
};
export const EditorCommentFields = identityRecordOfFieldEditorOptions({
	CommentField: {
		editableOptions: {
			placeholder: true,
			commentType: commentTypeEnumKeyLabels,
			textAreaRows: true
		}
	}
});
