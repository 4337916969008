import { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigationState } from "../NavigationContext";
import { useAppActionState } from "../AppActionContext";
import {
	useServiceHandler,
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible
} from ".";
import { useCallback } from "react";
import { FunctionButton } from "../components/AppActions";
import { ServiceResult } from "@ploy-lib/calculation";
import { touchAllVisibleFields } from "../components/TouchVisibleFieldsOnPreviousPages";
import { usePages } from "../pagesContext";
import { FormikProps, FormikTouched } from "formik/dist/types";
import { useFormHasErrors } from "./useFormHasErrors";
import { defineMessages, useIntl } from "react-intl";
import { useAppLoad } from "../appLoadContext";
import { download } from "@ploy-lib/core";
import { ActionRole } from "../field/Buttons/Button";
import { Extends } from "../types";
import { usePageState } from "../PageContext";

export const messages = defineMessages({
	validationError: {
		id: "dealer.applications.actions.error.form-errors",
		description: "Error message for validation error",
		defaultMessage: "Skjema har valideringsfeil"
	}
});

export type SelectableAppActionRoles = Extends<ActionRole, "navigate_next">;

export const appActionButtonRoleLocalization =
	defineMessages<SelectableAppActionRoles>({
		navigate_next: {
			id: "buttonRoleScope.navigate_next",
			defaultMessage: "Navigate to next"
		}
	});

interface AppActionClickProps {
	button?: FunctionButton;
	actionEvent?: string;
	onClose?: () => any;
	disableValidation?: boolean;
	form: FormikProps<any>;
	role?: SelectableAppActionRoles;
}
export const useAppActionClick = (props: AppActionClickProps) => {
	const {
		button,
		actionEvent,
		onClose,
		disableValidation = button?.disableValidation ?? false,
		form,
		role
	} = props;
	const { touched, setTouched, errors } = form;

	const formHasErrors = useFormHasErrors();
	const pages = usePages();
	const { next, step, getTarget } = usePageState();

	const intl = useIntl();

	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();

	const [pending, setPending] = useState(false);
	const [success, setSuccess] = useState<boolean>(false);

	const { enqueueSnackbar = () => {} } = useSnackbar() || {};

	const { applicationNumber, refetchApp, refetchCalcRules } = useAppLoad();

	const navigation = useNavigationState();
	const appAction = useAppActionState();

	const [serviceName] = button?.name.split("_") ?? [];
	const service = serviceName
		? {
				namespace: "Main",
				service: serviceName
		  }
		: undefined;

	const [invokeButtonService] = useServiceHandler(service);

	const onClickButton = useCallback(
		async (e: React.MouseEvent<Element>) => {
			let allTouched: FormikTouched<unknown> = {};
			let includeLiteralFields = true;
			let relevantPages = pages;

			if (!disableValidation) {
				if (role === "navigate_next") {
					includeLiteralFields = false;
					relevantPages = pages.slice(0, step + 1);
				}

				allTouched = touchAllVisibleFields(
					touched,
					relevantPages,
					fieldIsVisible,
					sectionIsVisible
				);
				setTouched(allTouched);
			}
			setPending(true);

			if (invokeButtonService && button) {
				try {
					if (
						formHasErrors(
							{ errors, touched: allTouched },
							relevantPages,
							includeLiteralFields
						)
					) {
						enqueueSnackbar(intl.formatMessage(messages.validationError), {
							variant: "error"
						});

						return;
					}

					const response = (await invokeButtonService(
						e,
						undefined,
						actionEvent
							? {
									actionEvent: actionEvent
							  }
							: undefined
					)) as ServiceResult;

					if (!response.ok) {
						enqueueSnackbar(`Nettverksfeil: ${response.error}`, {
							variant: "error"
						});

						return;
					}

					const data = await response.data;

					if (data instanceof File) {
						download(data);
					} else if (data.Messages && data.Messages.length > 0) {
						for (const m of data.Messages) {
							enqueueSnackbar(m, {
								variant: "error"
							});
						}
					} else if (!data.ApplicationNumber) {
						enqueueSnackbar("En feil oppstod", {
							variant: "error"
						});
					} else {
						if (button.successMessage) {
							enqueueSnackbar(button.successMessage, {
								variant: "success"
							});
						}
						setSuccess(true);
						if (button.canResend) {
							setTimeout(() => setSuccess(false), 2500);
						}
						if (role === "navigate_next") {
							if (
								data.ApplicationNumber &&
								data.ApplicationNumber !== applicationNumber
							) {
								navigation.applicationWithStep(
									data.ApplicationNumber,
									getTarget?.(step + 1) ?? ""
								);
							} else {
								next();
								if (data.DoPostBack) refetchApp();
							}
						} else if (
							appAction.externalPostback &&
							(data.DoPostBack || data.ApplicationNumber !== applicationNumber)
						) {
							appAction.externalPostback(data);
						} else {
							if (data.DoPostBack) refetchApp();

							navigation.application(data.ApplicationNumber);
						}
					}
				} catch (e: any) {
					enqueueSnackbar(e.message, {
						variant: "error"
					});
					console.error(e);
				} finally {
					setPending(false);
					onClose && onClose();
				}
			}
		},
		[
			actionEvent,
			appAction,
			applicationNumber,
			button,
			disableValidation,
			enqueueSnackbar,
			errors,
			fieldIsVisible,
			formHasErrors,
			getTarget,
			intl,
			invokeButtonService,
			navigation,
			next,
			onClose,
			pages,
			refetchApp,
			role,
			sectionIsVisible,
			setTouched,
			step,
			touched
		]
	);

	return {
		pending,
		disabled: button == null ? true : button.disabled,
		success: success,
		onClickButton
	};
};
