import { Theme } from "@material-ui/core/styles";
import { CustomFormats } from "react-intl";
import { DployThemeOptions } from ".";
import { HiddenProps } from "@material-ui/core/Hidden";

export interface TenantSelectorProps {
	hidden?: boolean;
}

export interface Tenant {
	tenantName: string;
	designs: Record<string, Design>;
	getFormats: (locale: string) => CustomFormats;
	getMessages: (language: string) => Promise<Record<string, string>>;
	locales: string[];
	images: Record<string, string[]>;
	formIcons?: { name: string; icon: string }[];
	themeObject?: DployThemeOptions;
	designOverride?: (theme: Theme) => Theme;
	settings?: TenantSettings;
}

export interface HiddenMethod<TData extends object, TLoaded extends object> {
	(cell: any, row: TData, loaded: TLoaded | undefined): boolean;
}

export type ColumnDefinitionOverride<
	TData extends object,
	TLoaded extends object
> = {
	id?: string;
	hidden?: HiddenProps | boolean | HiddenMethod<TData, TLoaded>;
	hiddenInExpandable?: HiddenProps | boolean | HiddenMethod<TData, TLoaded>;
};

export interface TenantSettings {
	allApplicationsColumns?: ColumnDefinitionOverride<object, object>[];
	allApplicationsHideActions?: AllApplicationsActions[];
	hideField8Column?: boolean;
	hideField17Column?: boolean;
}

export enum AllApplicationsActions {
	DisallowChangeRegNumber
}

export interface Design {
	id: string;
	name: string;
	muiTheme: (theme: Theme) => Theme;
}
