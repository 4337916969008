import { makeStyles } from "@material-ui/core/styles";

import Drawer from "./Drawer";
import MobileAppBar from "./MobileAppBar";
import Content from "./Content";
import {
	DrawerContextProvider,
	AppSearchParamContextProvider,
	EditingContextProvider
} from "../../contexts";
import { Outlet } from "react-router-dom";
import {
	GlobalSuspense,
	ThirdPartyScripts,
	ThirdPartyScriptsContext,
	useThirdPartyScriptsContext
} from "@ploy-ui/core";
import { useThemeProps } from "@ploy-ui/tenants";
import Box from "@material-ui/core/Box";
import FixedBottomMenu from "./Drawer/FixedBottomMenu";
import { Hidden } from "@material-ui/core";
import InformUser from "./InformUser";
import { ErrorPageHandler } from "../ErrorPageHandler";
import { Suspense } from "react";

function Portal(props: React.PropsWithChildren<{ drawerColor?: string }>) {
	const { children = <Outlet /> } = props;
	const { drawerColor = "primary.main" } = useThemeProps(props, "Portal");

	const classes = useStyles(props);

	return (
		<DrawerContextProvider>
			<AppSearchParamContextProvider>
				<EditingContextProvider>
					<Box
						className={classes.root}
						flexGrow={1}
						zIndex={1}
						position="relative"
						display="flex"
						width="100%"
						flexDirection={["column", "row"]}
					>
						<ThirdPartyScriptsContext.Provider
							value={useThirdPartyScriptsContext()}
						>
							<InformUser />

							<Hidden xsDown>
								<Drawer color={drawerColor} />
							</Hidden>

							<Hidden smUp>
								<MobileAppBar />
							</Hidden>

							<Content>
								<Hidden smUp>
									<div className={classes.toolbarSpacer} />
								</Hidden>

								<GlobalSuspense>
									<div className={classes.page}>
										<ErrorPageHandler sx={{ p: 3 }}>{children}</ErrorPageHandler>
									</div>
								</GlobalSuspense>
							</Content>

							<Hidden smUp>
								<FixedBottomMenu />
							</Hidden>

							<Suspense fallback={null}>
								<ThirdPartyScripts />
							</Suspense>
						</ThirdPartyScriptsContext.Provider>
					</Box>
				</EditingContextProvider>
			</AppSearchParamContextProvider>
		</DrawerContextProvider>
	);
}

Portal.displayName = "Portal";

const useStyles = makeStyles(
	theme => ({
		root: {
			[theme.breakpoints.down("xs")]: {
				overflowX: "hidden"
			}
		},
		toolbarSpacer: {
			width: "100%",
			...theme.mixins.toolbar
		},
		page: {
			maxWidth: 1440,
			width: "100%",
			flexGrow: 1,
			display: "flex",
			flexDirection: "column"
		}
	}),
	{ name: "Portal" }
);

export default Portal;
