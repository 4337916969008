import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";

interface Item {
	key: number;
	text: string;
}

interface Salesperson {
	salepersonId: string;
	fullName: string;
}

export class AllApplicationsSettingsResource extends BaseCamelCasedResource {
	readonly canAccessAll: boolean = false;
	readonly allowVendorHierarchySearch: boolean = false;
	readonly applicationStatusList: Item[] = [];
	readonly documentStatusList: Item[] = [];
	readonly salepersons: Salesperson[] = [];
	readonly shouldShowCarSearchFields: boolean = false;
	readonly hideAllTasksSignedExceptType: boolean = false;
	readonly hideRegnrFieldInTable: boolean = false;
	readonly hideDocumentStatusFilter: boolean = false;
	readonly enableExternalOfferNumberSearch: boolean = false;

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		const data = await super.fetch(input, init);

		return data.initialData;
	}

	static urlRoot = legacyApiResourceUrl("AllApplications/Vulcan");
}
