import React from "react";
import {
	ReadShape,
	Denormalize,
	DenormalizeNullable,
	ParamsFromShape
} from "@rest-hooks/core";
import { DashboardContentProps } from "../types";

type CondNull<P, A, B> = P extends null ? A : B;

export type ResourceArgs<
	S extends ReadShape<any, any>,
	P extends ParamsFromShape<S> | null
> = readonly [S, P];

export type DataFromAllArgs<
	A extends readonly ResourceArgs<ReadShape<any, any>, any>[]
> = {
	[K in keyof A]: A[K] extends ResourceArgs<infer S, infer P>
		? CondNull<P, DenormalizeNullable<S["schema"]>, Denormalize<S["schema"]>>
		: never;
};

export interface GridComponentSettings<
	P,
	A extends readonly ResourceArgs<ReadShape<any, any>, any>[],
	V extends readonly ResourceArgs<ReadShape<any, any>, any>[]
> {
	getPrefetch?: (props: P) => readonly [...A];
	getVisibilityFetch?: (props: P) => readonly [...V];
	getVisibility?: (props: P, data: DataFromAllArgs<V>) => boolean;
	minHeight?: number;
	maxHeight?: number;
	minWidth?: number;
	maxWidth?: number;
}

export type GridComponent<
	P = any,
	A extends readonly ResourceArgs<
		ReadShape<any, any>,
		any
	>[] = readonly ResourceArgs<ReadShape<any, any>, any>[],
	V extends readonly ResourceArgs<
		ReadShape<any, any>,
		any
	>[] = readonly ResourceArgs<ReadShape<any, any>, any>[]
> = React.ComponentType<P & DashboardContentProps> &
	GridComponentSettings<Omit<P, keyof DashboardContentProps>, A, V>;

const GridComponentField: (keyof GridComponentSettings<any, any, any>)[] = [
	"getPrefetch",
	"getVisibilityFetch",
	"getVisibility",
	"minHeight",
	"maxHeight",
	"minWidth",
	"maxWidth"
];

export function isGridComponent(
	component: React.ComponentType<any>
): component is GridComponent {
	return GridComponentField.some(f => component.hasOwnProperty(f));
}
