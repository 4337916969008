import { LayoutPage } from "./types";
import {
	FormTemplate,
	TemplatePage,
	TemplatePanel,
	isDeprecatedPage
} from "@ploy-lib/types";
import { getRegisteredLayouts } from "./page/layouts/layoutDescriptions";
import { ensureActions, ensureAppActions } from "./defaultActions";
import {
	translateSideBar,
	translateSummary,
	translateMain,
	translateSidebarActions
} from "./translateSection";
import { IntlShape } from "react-intl";
import { v4 as uuid } from "uuid";

export const templateToPages = (
	intl: IntlShape,
	template: FormTemplate<TemplatePage>,
	canSubmit?: boolean
): LayoutPage[] => {
	let pages: LayoutPage[] = [];

	let templatePages = template.pages || [];

	const mappedPages = templatePages.map((page, step) => {
		// Sanity checks
		if (isDeprecatedPage(page))
			throw new Error(`Deprecated templates not supported: ${template.name}`);

		if (!page.layout || !getRegisteredLayouts().hasOwnProperty(page.layout))
			throw new Error(
				`Deprecated layout (${page.layout}) not supported: ${template.name}`
			);

		const isLastStep = step === templatePages.length - 1;

		let panels: Record<string, TemplatePanel> = {
			...page.panels,
			actions: ensureActions(
				intl,
				{ step, isLastStep },
				page.panels.actions,
				canSubmit,
				page.prevLabel,
				page.nextLabel,
				page.submitLabel
			),
			appActions: ensureAppActions(
				intl,
				{ step, isLastStep },
				page.panels.appActions
			),
			//Only translate if panel exist
			...(page.panels.sidebarActions && {
				sidebarActions: translateSidebarActions(
					intl,
					page.panels.sidebarActions
				)
			}),
			...(page.panels.sidebar && {
				sidebar: translateSideBar(intl, page.panels.sidebar)
			}),
			...(page.panels.summary && {
				summary: translateSummary(intl, page.panels.summary)
			}),
			...(page.panels.main && { main: translateMain(intl, page.panels.main) })
		};

		const layoutSettings = getRegisteredLayouts()[page.layout];

		// Set up possibly non existing panels
		for (let panelname of Object.keys(layoutSettings.panels)) {
			if (panels[panelname] == null) {
				panels[panelname] = {
					formPanelId: `non_existing_panel_tmp_id_${uuid()}`,
					sections: []
				};
			}
		}

		panels = Object.fromEntries(
			Object.entries(panels).map(([name, panel]) => {
				const { defaultOptions = {} } = layoutSettings.panels[name] ?? {};

				const defaultKeys = Object.keys(defaultOptions);
				if (defaultKeys.length > 0) {
					let panelWithDefaults = { ...panel };

					for (const k of defaultKeys) {
						if (panelWithDefaults[k] === undefined)
							panelWithDefaults[k] = defaultOptions[k];
					}

					return [name, panelWithDefaults] as const;
				}
				return [name, panel] as const;
			})
		);

		return {
			...page,
			panels
		} as LayoutPage;
	});

	return [...pages, ...mappedPages];
};
