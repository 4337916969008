import React, { useMemo, useState, useCallback, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import * as ContentComponent from "./content";
import { isNotNull } from "@ploy-lib/core";
import { ApplicationInfoFilter, ID } from "@ploy-lib/rest-resources";
import {
	Responsive as ResponsiveGridLayout,
	Layouts,
	Layout,
	ResponsiveProps
} from "react-grid-layout";
import { useWidth } from "./hooks/useWidth";
import { HeightChange } from "./types";
import { GridComponent, ResourceArgs } from "./content/types";
import usePreFetcher from "./hooks/usePreFetcher";
import { Endpoint, ReadShape, useResource } from "@rest-hooks/core";
import { ContentGridItem } from "./ContentGridItem";
import { GridItem, DashboardGrid } from "./resources/DashboardGridResource";
import clsx from "clsx";
import mapValues from "lodash/mapValues";

const GridContentComponents = ContentComponent as Record<
	Required<GridItem>["component"],
	GridComponent | undefined
>;

export interface ContentGridProps {
	grid: DashboardGrid;
	droppingComponent?: GridItem["component"];
	onLayoutChange?: (current: Layout[], layouts: Layouts) => void;
	onChangeMaxBreakpoint?: (breakpoint: string) => void;
	onItemChange?: (item: GridItem) => Promise<any> | void;
	onItemRemove?: (id: GridItem["id"]) => Promise<any> | void;
	editable?: boolean;
	applicationsSearchPath?: string;
	applicationOpenPath?: string;
	setApplicationsSearchParams?: (params: ApplicationInfoFilter) => void;
	onDrop?: (
		layout: Layout[],
		item: Layout,
		breakpoint: string,
		e: Event
	) => void;
	maxWidth?: number;
}

function usePrefetchedItems(items: Record<string, GridItem>) {
	const prefetch = useMemo(
		() =>
			Object.values(items).flatMap(
				item =>
					(item.component &&
						GridContentComponents[item.component]?.getPrefetch?.(
							item.properties
						)) ??
					[]
			),
		[items]
	);

	const prefetcher = usePreFetcher();

	return useMemo(() => {
		const promises = prefetch
			.map(([shape, params]) => prefetcher(shape, params))
			.filter(isNotNull);

		return promises.length > 0 ? Promise.all(promises) : undefined;
	}, [prefetch, prefetcher]);
}

const placeholderResourceArg: ResourceArgs<ReadShape<any, any, any>, any> = [
	new Endpoint(async () => {}),
	null
];

function useVisibleItems(
	items: Record<string, GridItem>,
	skipSuspend?: boolean
) {
	const visibilityFetch = useMemo(
		() =>
			Object.values(items).flatMap(
				item =>
					(item.component &&
						GridContentComponents[item.component]?.getVisibilityFetch?.(
							item.properties
						)) ??
					[]
			),
		// Length of arguments to useResource cannot change, so only do visibility fetch for items present during initial mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	let visibilityData: any[] = useMemo(
		() => visibilityFetch.map(() => undefined),
		[visibilityFetch]
	);

	let allVisible = false;

	try {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		visibilityData = useResource(
			...(visibilityFetch?.length > 0
				? visibilityFetch
				: [placeholderResourceArg])
		);
	} catch (e: any) {
		if (!(e instanceof Promise)) {
			// TODO: find a way to not show/hide all items when just one fetch caused an error
			allVisible = true;
		} else if (!skipSuspend) {
			throw e;
		}
	}

	return useMemo(() => {
		const fetchIndexes = new Map(
			visibilityFetch
				.map(
					([endpoint, params], i) =>
						[params ? endpoint.getFetchKey(params) : "", i] as const
				)
				.filter(([key]) => key !== "")
		);

		return Object.fromEntries(
			Object.entries(items).map(
				([key, item]): [string, GridItem & { hidden?: boolean }] => {
					const { component, properties } = item;

					const { getVisibilityFetch, getVisibility } =
						(component && GridContentComponents[component]) ?? {};

					if (!getVisibilityFetch || !getVisibility) return [key, item];

					const prefetched = getVisibilityFetch(properties);

					const data = prefetched
						.map(([endpoint, params]) => params && endpoint.getFetchKey(params))
						.map(key => {
							const visibilityFetchIdx = key && fetchIndexes.get(key);
							if (visibilityFetchIdx != null)
								return visibilityData[visibilityFetchIdx];
							return undefined;
						});

					if (getVisibility(properties, data)) return [key, item];

					return [key, { ...item, hidden: true }];
				}
			)
		);
	}, [allVisible, items, visibilityData, visibilityFetch]);
}

function heightPxToRows(
	pxHeight: number,
	rowHeight: number,
	gap: number
): number {
	return pxHeight / (rowHeight + gap);
}

const getComponentMinMax = (
	component: GridItem["component"],
	rowHeight: number,
	gap: number
) => {
	const { minHeight, minWidth, maxHeight, maxWidth } =
		(component && GridContentComponents[component]) ?? {};

	const minH =
		minHeight && Math.ceil(heightPxToRows(minHeight, rowHeight, gap));
	const maxH =
		maxHeight && Math.floor(heightPxToRows(maxHeight, rowHeight, gap));
	const minW = minWidth;
	const maxW = maxWidth;

	return { minH, maxH, minW, maxW };
};

const min = (a?: number, b?: number) => {
	if (a != null && b != null) return Math.min(a, b);
	return a ?? b;
};
const max = (a?: number, b?: number) => {
	if (a != null && b != null) return Math.max(a, b);
	return a ?? b;
};
const clamp = (value?: number, minValue?: number, maxValue?: number) =>
	max(minValue, min(value, maxValue));

const getLayoutMinMax =
	(items: DashboardGrid["items"], rowHeight: number, gap: number) =>
	<T extends { i: string } & Partial<Layout>>(l: T) => {
		const { component } = items[l.i] ?? {};

		const c = getComponentMinMax(component, rowHeight, gap);

		const minH = max(l.minH, c.minH);
		const maxH = min(l.maxH, c.maxH);
		const minW = max(l.minW, c.minW);
		const maxW = min(l.maxW, c.maxW);

		const h = clamp(l.h, minH, maxH);
		const w = clamp(l.w, minW, maxW);

		return {
			...l,
			h,
			w,
			minH,
			maxH,
			minW,
			maxW
		};
	};

const fixMinMaxWH = (
	{ items, layouts }: DashboardGrid,
	rowHeight: number,
	gap: number
) => {
	const layoutMinMax = getLayoutMinMax(items, rowHeight, gap);

	return Object.fromEntries(
		Object.entries(layouts).map(([brk, layout]) => [
			brk,
			layout.map(layoutMinMax)
		])
	);
};

function fixMissingLayoutItems(grid: DashboardGrid, layouts: Layouts) {
	let fixedLayouts = undefined;
	for (const item of Object.values(grid.items)) {
		for (const [brk, layout] of Object.entries(layouts)) {
			if (layout && !layout.some(l => l.i === item.id)) {
				const idx = grid.layouts[brk]?.findIndex(l => l.i === item.id);

				if (idx >= 0) {
					if (!fixedLayouts) fixedLayouts = { ...layouts };

					fixedLayouts[brk] = [
						...layout.slice(0, idx),
						grid.layouts[brk][idx],
						...layout.slice(idx)
					];
				}
			}
		}
	}
	return fixedLayouts ?? layouts;
}

function filterVisibleItems(
	visibleItems: Record<string, GridItem & { hidden?: boolean }>,
	layouts: Layouts
) {
	const fixedLayouts = { ...layouts };
	for (const [brk, layout] of Object.entries(layouts)) {
		fixedLayouts[brk] = layout.filter(l => !visibleItems[l.i]?.hidden);
	}
	return fixedLayouts ?? layouts;
}

ContentGrid.displayName = "ContentGrid";
function ContentGrid(props: ContentGridProps) {
	const {
		applicationsSearchPath,
		applicationOpenPath,
		setApplicationsSearchParams,
		editable,
		onItemChange,
		onItemRemove,
		onLayoutChange,
		onChangeMaxBreakpoint,
		grid,
		droppingComponent,
		onDrop,
		maxWidth
	} = props;

	const classes = useStyles(props);
	const theme = useTheme();

	const rowHeight = 32;
	const gap = theme.spacing(4);

	usePrefetchedItems(grid.items);

	const visibleItems = useVisibleItems(grid.items, editable);

	const fixedLayouts = useMemo(() => {
		let fixed = grid.layouts;

		fixed = fixMinMaxWH(grid, rowHeight, gap);
		fixed = fixMissingLayoutItems(grid, fixed);

		return fixed;
	}, [grid, gap]);

	const visibleLayouts = useMemo(
		() => filterVisibleItems(visibleItems, fixedLayouts),
		[fixedLayouts, visibleItems]
	);

	const [heightChanges, setHeightChanges] = useState<Record<ID, HeightChange>>(
		{}
	);

	const [currentBreakpoint, setCurrentBreakpoint] = useState(
		() => Object.keys(visibleLayouts)[0]
	);

	const handleChangeBreakpoint = useCallback(bp => {
		setCurrentBreakpoint(bp);
		setHeightChanges({});
	}, []);

	const currentLayouts = useMemo(() => {
		const mapLayout = (l: Layout) => {
			const change = heightChanges[l.i];

			if (!change) return l;

			let heightChange = 0;

			if (change.type === "relative") {
				heightChange = Math.ceil(change.value / (rowHeight + gap));
			}

			return { ...l, h: l.h + heightChange };
		};

		if (!visibleLayouts[currentBreakpoint]) return visibleLayouts;

		return {
			...visibleLayouts,
			[currentBreakpoint]: visibleLayouts[currentBreakpoint].map(mapLayout)
		};
	}, [currentBreakpoint, gap, heightChanges, visibleLayouts]);

	const layouts = editable ? fixedLayouts : currentLayouts;

	const changeHeight = useCallback(
		(id: string, change: HeightChange) =>
			setHeightChanges(changes => ({ ...changes, [id]: change })),
		[]
	);

	const resetHeight = useCallback(
		(id: string) =>
			setHeightChanges(({ [id]: _, ...remainingChanges }) => remainingChanges),
		[]
	);

	const items = useMemo(
		() =>
			mapValues(visibleItems, item => ({
				...item,
				properties: {
					...item.properties,
					boxProps: {
						...item.properties.boxProps,
						classes: {
							header: clsx({
								[classes.draggableBoxHeader]: editable
							}),
							headerAction: classes.boxHeaderAction
						}
					}
				}
			})),
		[
			classes.boxHeaderAction,
			classes.draggableBoxHeader,
			editable,
			visibleItems
		]
	);

	const currentLayoutIds = layouts[currentBreakpoint ?? Object.keys(layouts)[0]]
		?.map(({ i }) => i)
		.join("|");

	const children = useMemo(() => {
		const getResetHeight = (id: string) =>
			heightChanges[id] ? resetHeight : undefined;

		return currentLayoutIds
			?.split("|")
			.map(id => ({ item: items[id], id }))
			.filter(({ item }) => editable || (item && !item.hidden))
			.map(({ item, id }) => {
				const { component, properties } = item ?? {};

				return (
					<div
						key={id}
						data-dashboard-item={`${component} - ${
							properties?.boxProps?.header ?? ""
						}`}
						className={clsx({ [classes.notEditingGridItem]: !editable })}
					>
						{component && (
							<ContentGridItem
								id={id}
								component={component}
								properties={properties}
								applicationOpenPath={applicationOpenPath}
								applicationsSearchPath={applicationsSearchPath}
								setApplicationsSearchParams={setApplicationsSearchParams}
								onChangeHeight={editable ? undefined : changeHeight}
								onResetHeight={editable ? undefined : getResetHeight(id)}
								editable={editable}
								onSave={editable ? onItemChange : undefined}
								onRemove={editable ? onItemRemove : undefined}
								boxProps={properties.boxProps}
							/>
						)}
					</div>
				);
			});
	}, [
		currentLayoutIds,
		heightChanges,
		resetHeight,
		items,
		editable,
		classes.notEditingGridItem,
		applicationOpenPath,
		applicationsSearchPath,
		setApplicationsSearchParams,
		changeHeight,
		onItemChange,
		onItemRemove
	]);

	const [ref, width = 1200] = useWidth<HTMLDivElement>(100);

	const droppingItem = useMemo(() => {
		const c = getComponentMinMax(droppingComponent, rowHeight, gap);

		return {
			...c,
			i: "__DROPPING-ITEM__",
			w: c.minW ?? 4,
			h: c.minH ?? 4
		};
	}, [droppingComponent, gap]);

	const handleDrop = useCallback(
		(layout, layoutItem, event) =>
			onDrop?.(layout, layoutItem, currentBreakpoint, event),
		[currentBreakpoint, onDrop]
	);

	const responsiveProps = useMemo<Partial<ResponsiveProps>>(
		() => ({
			breakpoints: {
				md: theme.breakpoints.values.md - 0.01,
				sm: theme.breakpoints.values.sm - 0.01,
				xs: theme.breakpoints.values.xs - 0.01
			},
			cols: grid.columns as unknown as Record<string, number>,
			margin: [gap, gap],
			containerPadding: {
				md: [theme.spacing(4), theme.spacing(4)],
				sm: [theme.spacing(4), theme.spacing(4)],
				xs: [theme.spacing(0), theme.spacing(2)]
			}
		}),
		[gap, grid.columns, theme]
	);

	useEffect(() => {
		const breakpoint = getBreakpointFromWidth(
			responsiveProps.breakpoints!,
			width
		);
		onChangeMaxBreakpoint?.(breakpoint);
	}, [onChangeMaxBreakpoint, responsiveProps.breakpoints, width]);

	return (
		<div ref={ref} className={classes.root}>
			<ResponsiveGridLayout
				className={classes.grid}
				draggableHandle={`.${classes.draggableBoxHeader}`}
				draggableCancel={`.${classes.boxHeaderAction}`}
				isDraggable={editable}
				isDroppable={editable}
				isResizable={editable}
				width={maxWidth ? Math.min(width, maxWidth) : width}
				layouts={layouts}
				onLayoutChange={onLayoutChange}
				onBreakpointChange={handleChangeBreakpoint}
				{...responsiveProps}
				rowHeight={rowHeight}
				onDrop={handleDrop}
				droppingItem={droppingItem}
			>
				{children}
			</ResponsiveGridLayout>
		</div>
	);
}

const useStyles = makeStyles(
	theme => ({
		root: {
			width: "100%",
			display: "flex",
			alignItems: "stretch",
			justifyContent: "center",
			flexGrow: 1,
			position: "relative"
		},
		grid: {
			width: "100%",
			maxWidth: (props: ContentGridProps) => props.maxWidth ?? ""
		},
		draggableBoxHeader: {
			cursor: "move"
		},
		boxHeaderAction: {},
		notEditingGridItem: {
			transitionProperty: "transform, height"
		}
	}),
	{ name: "ContentGrid" }
);

export default ContentGrid;

export function getBreakpointFromWidth(
	breakpoints: {
		[P: string]: number;
	},
	width: number
): string {
	const sorted = sortBreakpoints(breakpoints);
	let matching = sorted[0];
	for (let i = 1, len = sorted.length; i < len; i++) {
		const breakpointName = sorted[i];
		if (width > breakpoints[breakpointName]) matching = breakpointName;
	}
	return matching;
}

export function sortBreakpoints(breakpoints: {
	[P: string]: number;
}): string[] {
	const keys = Object.keys(breakpoints);
	return keys.sort((a, b) => breakpoints[a] - breakpoints[b]);
}
