import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface CustomerChildrenType {
	customerId: number;
	childList: number[];
	recursiveChildList: number[];
	newChildIds: number[];
}

export class CustomerChildrenResource
	extends BaseCamelCasedResource
	implements CustomerChildrenType
{
	readonly customerId: number = 0;
	readonly childList: number[];
	readonly recursiveChildList: number[];
	readonly newChildIds: number[];

	pk() {
		return this.customerId?.toString() || "";
	}

	static urlRoot = apiResourceUrl("customer-children");
}
