import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface OptionPair {
	readonly optionPairId: number;
	readonly text: string;
	readonly value: string;
	readonly description: string;
	readonly optionPairCategoryId: number;
	readonly sortOrder?: number;
	readonly optionSource: string;
}

export interface OptionPairCategory {
	readonly optionPairCategoryId: number;
	readonly name: string;
	readonly text: string;
	readonly description: string;
	readonly editable: boolean;
	readonly optionPairs: OptionPair[];
}

//// Not yet implemented
//export class OptionPairResource
//	extends BaseCamelCasedResource
//	implements OptionPair
//{
//	optionPairId: number = 0;
//	text: string = "";
//	value: string = "";
//	description: string = "";
//	optionPairCategoryId: number = 0;
//	sortOrder?: number | undefined = undefined;
//	optionSource: string = "";

//	pk(): string {
//		return this.optionPairId.toString();
//	}

//	static urlRoot = legacyApiResourceUrl(
//		"OptionPair/GetOptionPairsInCategoryForExternalUsers/{category}"
//	);
//}

export class OptionPairCategoryResource
	extends BaseCamelCasedResource
	implements OptionPairCategory
{
	optionPairCategoryId: number = 0;
	name: string = "";
	text: string = "";
	description: string = "";
	editable: boolean = false;
	optionPairs: OptionPair[] = [];

	pk(): string {
		return this.optionPairCategoryId.toString();
	}

	static urlRoot = legacyApiResourceUrl(
		"OptionPair/GetOptionPairCategoriesForExternalUsers"
	);
}
