import React, { Suspense, useMemo, useCallback, useEffect } from "react";
import clsx from "clsx";

import { useResource, useFetcher, useInvalidator } from "@rest-hooks/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProofOfDeliverySmsActions } from "./ProofOfDeliverySmsActions";
import { LinearProgress, TablePagination } from "@material-ui/core";
import {
	ExpandableTable,
	ExpandedRow,
	Cells,
	ExpandableTableProps
} from "@ploy-ui/core";
import { ExpandedRowProps } from "@ploy-ui/core";
import { ApplicationStatus, DocumentStatus } from "@ploy-lib/types";
import {
	ApplicationInfo,
	ProofOfDeliveryInformationResource,
	ProofOfDeliveryInformation,
	ProductType,
	ApplicationInfoResource,
	ProductResource,
	ApplicationInfoFilter,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";

const useStyles = makeStyles(
	theme => ({
		root: {
			width: "100%",
			flexGrow: 1,
			padding: theme.spacing(2),
			[theme.breakpoints.up("lg")]: {
				padding: theme.spacing(4)
			}
		},
		applicationStatusNameExternal: {
			width: 160,
			textAlign: "center" as "center"
		},
		applicationNumber: {
			width: 100
		},
		customerNameInner: {
			display: "flex",
			alignItems: "center" as "center",
			"& > div": {
				marginRight: 10
			}
		},
		customerNameText: {
			fontWeight: "bold" as "bold"
		}
	}),
	{ name: "ProofOfDeliverySms" }
);

const defaultColumnData: ExpandableTableProps<
	ApplicationInfo,
	ProofOfDeliveryInformation
>["columnData"] = [
	{
		id: "applicationStatusNameExternal",
		label: "Status",
		component: props => (
			<Cells.ColoredStatus statusKey="applicationStatusId" {...props} />
		),
		hidden: { xsDown: true },
		hiddenInExpandable: { smUp: true }
	},
	{
		id: "documentStatusNameExternal",
		label: "Dokumentstatus"
	},
	{
		id: "applicationNumber",
		label: "Saksnr.",
		hidden: { smDown: true }
	},
	{
		id: "customerName",
		label: "Kunde",
		component: props => (
			<Cells.DoubleLineSmDown
				statusKey="applicationStatusId"
				secondLineKey="applicationNumber"
				{...props}
			/>
		)
	},
	{
		id: "field5",
		label: "Merke"
	},
	{
		id: "field8",
		label: "Regnr."
	},
	{
		id: "productName",
		label: "Produkt",
		hidden: { smDown: true },
		hiddenInExpandable: { mdUp: true }
	},
	{
		id: "salesPersonName",
		label: "Selger",
		hidden: true
	},
	{
		component: ProofOfDeliverySmsActions
	}
];

const proofOfDeliveryFilter: ApplicationInfoFilter = {
	appStatus: [
		ApplicationStatus.AutoApproved,
		ApplicationStatus.ManuallyApproved
	],
	fromDocStatus: DocumentStatus.CarOrderReceived,
	toDocStatus: DocumentStatus.ProofOfDeliveryAccepted,
	hasCarRegNo: true
};

export interface ProofOfDeliverySmsProps {
	columnData?: ExpandableTableProps<
		ApplicationInfo,
		ProofOfDeliveryInformation
	>["columnData"];
	className?: string;
}

export const PendingProofOfDelivery = ({
	className,
	columnData = defaultColumnData
}: ProofOfDeliverySmsProps) => {
	const classes = useStyles();

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const columns = useMemo<typeof columnData>(
		() =>
			columnData.map(c => ({
				...c,
				className:
					c.id == null
						? undefined
						: (classes as Record<string, string | undefined>)[c.id]
			})),
		[columnData, classes]
	);

	const products = useResource(ProductResource.list(), {});
	const params = useMemo<ApplicationInfoFilter>(() => {
		const productIds = products
			.filter(p => p.type === ProductType.Leasing)
			.map(p => p.id as number);

		return {
			productList: productIds,
			...proofOfDeliveryFilter,
			pageSize: rowsPerPage,
			page: page + 1,
			sortBy: "createdDate.desc",
			/* add caradm product id. Needed for displaying apps related to internal products
			Move to dt (See km reading module  for example) if other customers are going to use this module */
			productExceptions: [203]
			// productExceptions: [203]
		};
	}, [page, products, rowsPerPage]);

	const { records, pageSize, recordCount } = useResource(
		ApplicationInfoResource.list(),
		params
	);

	const fetchApplications = useFetcher(ApplicationInfoResource.list());

	const reload = useCallback(
		() => fetchApplications(params),
		[fetchApplications, params]
	);

	return (
		<div className={clsx(className, classes.root)}>
			<ExpandableTable
				idColumn="applicationId"
				tableData={records as ApplicationInfo[]}
				columnData={columns}
				renderExpandedSection={row => {
					return (
						<Suspense fallback={<LinearProgress />}>
							<ProofOfDeliveryExpandedRow
								columnData={columns}
								rowData={row}
								reload={reload}
							/>
						</Suspense>
					);
				}}
			/>
			<TablePagination
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={recordCount}
				rowsPerPage={pageSize}
				page={page}
				onPageChange={(_, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={event => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>
		</div>
	);
};

const ProofOfDeliveryExpandedRow = (
	props: ExpandedRowProps<ApplicationInfo, ProofOfDeliveryInformation> & {
		reload: () => Promise<any>;
	}
) => {
	const { rowData } = props;

	const proofOfDeliveryDetails = useResourceWithInvalidate(
		ProofOfDeliveryInformationResource.detail(),
		{
			applicationId: rowData.applicationId
		}
	);

	const invalidateProofOfDelivery = useInvalidator(
		ProofOfDeliveryInformationResource.detail()
	);

	useEffect(() => {
		invalidateProofOfDelivery({ applicationId: rowData.applicationId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchApplications = useFetcher(
		ProofOfDeliveryInformationResource.detail()
	);

	const reload = useCallback(
		() => fetchApplications({ applicationId: rowData.applicationId }),
		[fetchApplications, rowData.applicationId]
	);

	return (
		<ExpandedRow
			loadedData={proofOfDeliveryDetails}
			reloadData={reload}
			{...props}
		/>
	);
};
