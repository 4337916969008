import React from "react";
import { FieldInputProps, useField } from "formik";
import {
	FormControl,
	FormHelperText,
	Slider,
	FormLabel,
	SliderProps,
	FormControlProps
} from "@material-ui/core";

export type FormikSliderFieldProps = Omit<
	SliderProps & FormControlProps,
	keyof FieldInputProps<any>
> & { helperText?: React.ReactNode; name: string; label?: React.ReactNode };

export function FormikSliderField(props: FormikSliderFieldProps) {
	const {
		name,
		helperText,
		label,
		color,
		disabled,
		fullWidth,
		focused,
		hiddenLabel,
		margin,
		required,
		size,
		variant,
		...rest
	} = props;

	const [field, meta, form] = useField(name);

	const error = meta.error;

	const showError = Boolean(error) && meta.touched;

	const helperOrErrorText = showError ? error : helperText;

	return (
		<FormControl
			component="fieldset"
			error={showError}
			color={color}
			disabled={disabled}
			fullWidth={fullWidth}
			focused={focused}
			hiddenLabel={hiddenLabel}
			margin={margin}
			required={required}
			size={size}
			variant={variant}
		>
			<FormLabel>{label}</FormLabel>
			<Slider
				{...rest}
				{...field}
				value={field.value ?? 0}
				valueLabelDisplay="auto"
				onChange={(e, value) => form.setValue(value)}
				onBlur={e => form.setTouched(true)}
			/>
			{helperOrErrorText && (
				<FormHelperText>{helperOrErrorText}</FormHelperText>
			)}
		</FormControl>
	);
}
