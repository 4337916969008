import { useContext, useEffect } from "react";
import moment from "moment";
import { IThirdPartyScriptsContext } from "@ploy-ui/core";

export const getExtendedGTMData = (
	google: IThirdPartyScriptsContext["google"],
	applicationNumber?: string,
	productName?: string,
	vendorName?: string,
	tabName?: string,
	startIndex: number = 0,
	pageIndex: number = 0,
	totalSteps: number = 0,
	isPostProcess?: boolean,
	loanAmount?: number,
	objectCategory?: string,
	productId?: number,
	salesChannel?: string,
	fromSubmit?: boolean,
	virtualPageViewURLPostfix?: string
) => {
	let virtualPageViewTitle = "";
	let virtualPageViewURL = "";

	if (isPostProcess) {
		virtualPageViewURL = `/søknad_sendt${virtualPageViewURLPostfix}`;

		if (
			!fromSubmit ||
			google
				.getHistory()
				.filter(x => x.virtualPageURL?.includes(virtualPageViewURL)).length > 0
		) {
			// Application post-process form is opened some time after submission. Don't post "Søknad sendt" events to Google Analytics if this is not a new application
			return;
		}

		virtualPageViewTitle = "søknad sendt";
	} else {
		if (tabName) {
			virtualPageViewURL = `/${tabName}${virtualPageViewURLPostfix}`;
			virtualPageViewTitle = `${tabName}`;
		}
	}

	if (virtualPageViewTitle && virtualPageViewURL && objectCategory) {
		const windowDataLayerURLs: string[] = google
			.getHistory()
			.filter(
				(item: any) =>
					item.event === "VirtualPageview" ||
					item.virtualPageTitle === virtualPageViewTitle
			)
			.map((item: any) => item.virtualPageURL);

		// Avoid virtual pageview duplicates
		if (windowDataLayerURLs.some(x => x === virtualPageViewURL)) {
			return;
		}

		let event = "";
		let checkout_step = 0;

		if (isPostProcess) {
			event = "purchase";
			checkout_step = 4;
		} else if (pageIndex === 0) {
			event = "begin_checkout";
			checkout_step = 1;
		} else if (totalSteps - pageIndex === 2) {
			event = "add_payment_info";
			checkout_step = 2;
		} else if (totalSteps - pageIndex === 1) {
			event = "add_shipping_info";
			checkout_step = 3;
		}

		let data = {
			event: event,
			checkout_step: checkout_step,
			virtualPageURL: virtualPageViewURL,
			virtualPageTitle: virtualPageViewTitle,
			price: loanAmount,
			ecommerce: {
				currency: "nok",
				value: loanAmount,
				items: [
					{
						item_id: productId,
						item_name: productName,
						affiliation: vendorName,
						item_brand: salesChannel,
						item_category: objectCategory,
						price: loanAmount,
						quantity: "1",
						transaction_id: applicationNumber?.split("-")?.[0],
						application_date: moment(new Date()).format("YYYY-MM-DD"),
						application_status: isPostProcess ? "innsendt" : undefined
					}
				]
			}
		};

		return data;
	}
};
