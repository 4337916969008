import React from "react";
import {
	UpdateStatusRequest,
	BackofficeCommissionObject,
	UpdateStatusResponse
} from "@ploy-lib/commission-api";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { DataTable } from "@ploy-ui/core";
import { FormattedDate } from "react-intl";

export interface ProcessLogObject {
	agreementNumber: string;
	vendorName: string;
	amount: number;
	user: string;
	vendorId: string;
	date: Date;
	type: string;
	message: string;
}

export const ProcessLog = ({
	log,
	updateStatus,
	approved
}: {
	log: BackofficeCommissionObject[];
	updateStatus(request: UpdateStatusRequest): Promise<UpdateStatusResponse>;
	approved?: boolean;
}) => {
	const [processLog, setProcessLog] = React.useState<ProcessLogObject[]>(
		log.map(x => {
			let p: ProcessLogObject = {
				agreementNumber: x.agreementNumber,
				vendorName: x.vendorName,
				amount: x.upfrontAmount,
				user: x.userFullName,
				date: x.date,
				vendorId: x.vendorId,
				type: x.agreementType,
				message: ""
			};
			return p;
		})
	);

	const handleUpdateStatus = async (
		request: UpdateStatusRequest,
		status: string
	) => {
		const response = await updateStatus(request);
		if (response && response.success) {
			let p = processLog.map(x => {
				if (
					x.agreementNumber === request.agreementNumber &&
					x.type === request.type &&
					x.date.getTime() === request.date.getTime()
				) {
					x.message = `Avregningen ble merket som ${status}`;
				}
				return x;
			});
			setProcessLog(p);
		}
	};

	return (
		<>
			<DataTable<ProcessLogObject>
				options={{
					search: false,
					showTitle: false,
					paging: false,
					emptyRowsWhenPaging: false,
					toolbar: false,
					headerStyle: { padding: "6,9" }
				}}
				data={processLog}
				columns={[
					{ title: "Forhandler", field: "vendorName" },
					{ title: "Beløp", field: "amount", cellStyle: { padding: "6,9" } },
					{ title: "Type", field: "type" },
					{ title: "Avtalenummer", field: "agreementNumber" },
					{ title: approved ? "Godkjent av" : "Sendt til", field: "user" },
					{
						title: "Dato",
						render: row => (
							<FormattedDate value={row.date} month="long" year="numeric" />
						)
					},
					{
						title: "",
						render: row =>
							row.message ? (
								<Typography>{row.message}</Typography>
							) : (
								<Grid container>
									<Grid item xs={12}>
										<Box p={0.5} style={{ display: "inline-block" }}>
											<Button
												color="primary"
												variant="contained"
												onClick={() => {
													const t: UpdateStatusRequest = {
														agreementNumber: row.agreementNumber,
														amount: row.amount,
														date: row.date,
														vendorId: row.vendorId,
														newStatus: "Approved",
														type: row.type
													};
													handleUpdateStatus(t, "godkjent");
												}}
											>
												Godkjenn
											</Button>
										</Box>
										<Box p={0.5} style={{ display: "inline-block" }}>
											<Button
												color="primary"
												variant="contained"
												onClick={() => {
													const t: UpdateStatusRequest = {
														agreementNumber: row.agreementNumber,
														amount: row.amount,
														date: row.date,
														vendorId: row.vendorId,
														newStatus: "Declined",
														type: row.type
													};
													handleUpdateStatus(t, "avslått");
												}}
											>
												Avslå
											</Button>
										</Box>
									</Grid>
								</Grid>
							),
						hidden: approved
					},
					{
						title: "",
						render: row => (
							<>
								{row.message ? (
									<Typography>{row.message}</Typography>
								) : (
									<Button
										color="primary"
										variant="contained"
										onClick={() => {
											const t: UpdateStatusRequest = {
												agreementNumber: row.agreementNumber,
												amount: row.amount,
												date: row.date,
												vendorId: row.vendorId,
												newStatus: "Paid",
												type: row.type
											};
											handleUpdateStatus(t, "utbetalt");
										}}
									>
										Utbetalt
									</Button>
								)}
							</>
						),
						hidden: !approved
					}
				]}
			/>
		</>
	);
};
