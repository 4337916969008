import { TemplatePanel } from "@ploy-lib/types";

import { defineMessages, IntlShape } from "react-intl";

/**
 * Constructs a subtype containing _only_ the fields of Source that the type expression Condition can extend (string "extends" string | null, or "abc" extends string), that is, assignable to.
 */
type FilterConditionallyWhenWriting<Source, Condition> = Pick<
	Source,
	{
		[K in keyof Source]-?: [Condition] extends [Source[K]] ? K : never;
	}[keyof Source]
>;

type FilterConditionallyWhenReading<Source, Condition> = Pick<
	Source,
	{ [K in keyof Source]: Source[K] extends Condition ? K : never }[keyof Source]
>;

/**
 * Translate properties of each element in the list from translation-key to translation-value
 * if the property exist in the element of the list and the value is the same as translation-key
 * @param translation
 * @param list
 * @param getQualifyingValue extract translation candidate
 * @param setRelevantValue update relevant element with new value
 */
function translateListAdvanced<
	T extends FilterConditionallyWhenWriting<T, string>
>(
	translation: Partial<Record<string, string>>,
	list: T[] | null | undefined,
	getQualifyingValue: (element: T) => string | null | undefined,
	setRelevantValue: (element: T, newValue: string) => T
): T[] {
	if (list == null) return [];
	return list.map(element => {
		const val = getQualifyingValue(element);
		const translatedValue = val != null ? translation[val] : null;

		if (translatedValue) {
			element = setRelevantValue(element, translatedValue);
		}

		return element;
	});
}

/**
 * Translate properties of each element in the list from translation-key to translation-value
 * if the property exist in the element of the list and the value is the same as translation-key
 * @param translation
 * @param list
 * @param propertyName
 */
function translateList<T extends FilterConditionallyWhenWriting<T, string>>(
	translation: Partial<Record<string, string>>,
	list: T[] | null | undefined,
	propertyName: keyof FilterConditionallyWhenWriting<T, string>
): T[] {
	return translateListAdvanced(
		translation,
		list,
		e => e[propertyName],
		(e, newValue) => ({ ...e, [propertyName]: newValue })
	);
}

const translateFieldLabel = (
	translation: {},
	panel: TemplatePanel
): TemplatePanel => {
	const newSections = panel?.sections?.map(section => ({
		...section,
		fields: translateList(translation, section.fields ?? [], "label")
	}));
	return { ...panel, sections: newSections };
};
const translateGroup = (
	translation: {},
	panel: TemplatePanel
): TemplatePanel => {
	const newSections = translateListAdvanced(
		translation,
		panel?.sections ?? [],
		e => e.expansionTitle ?? e.group,
		(e, newValue) => ({ ...e, expansionTitle: newValue })
	);
	return { ...panel, sections: newSections };
};
const translateTabGroup = (
	translation: {},
	panel: TemplatePanel
): TemplatePanel => {
	if (panel?.sections === null) return panel;
	const newSections = translateList(
		translation,
		panel?.sections ?? [],
		"tabGroup"
	);
	return { ...panel, sections: newSections };
};

export const translateSideBar = (
	intl: IntlShape,
	sidebar: TemplatePanel
): TemplatePanel => {
	const translation = {
		Summary: intl.formatMessage(messages.summary),
		PaymentPlanSection: intl.formatMessage(messages.paymentplansection)
	};
	sidebar = translateTabGroup(translation, sidebar);
	return sidebar;
};

export const translateSummary = (
	intl: IntlShape,
	summary: TemplatePanel
): TemplatePanel => {
	const translation = {
		Summary: intl.formatMessage(messages.summary),
		PaymentPlanSection: intl.formatMessage(messages.paymentplansection)
	};
	summary = translateTabGroup(translation, summary);
	return summary;
};

export const translateMain = (
	intl: IntlShape,
	main: TemplatePanel
): TemplatePanel => {
	const translation = {
		Summary: intl.formatMessage(messages.summary),
		Commission: intl.formatMessage(messages.commissionsection),
		DocumentUploadSection: intl.formatMessage(messages.documentuploadsection),
		Cosigner: intl.formatMessage(messages.cosignersection),
		Signing: intl.formatMessage(messages.signingsection),
		Fulfillment: intl.formatMessage(messages.fulfillmentsection),
		Refinancing: intl.formatMessage(messages.refinancingsection),
		InputFields: intl.formatMessage(messages.inputfieldssection),
		IncomeExpenses: intl.formatMessage(messages.incomeexpensessection),
		Comments: intl.formatMessage(messages.commentsSection)
	};
	main = translateGroup(translation, main);
	return main;
};

export const translateSidebarActions = (
	intl: IntlShape,
	sidebarAction: TemplatePanel
): TemplatePanel => {
	const translation = {
		CalculatorContinue: intl.formatMessage(messages.calculatorcontinue)
	};
	sidebarAction = translateFieldLabel(translation, sidebarAction);
	return sidebarAction;
};
const messages = defineMessages({
	summary: {
		id: "form.sidebar.tab.summary",
		description: "Title of summary tab",
		defaultMessage: "Summary"
	},
	paymentplansection: {
		id: "form.sidebar.tab.paymentplan",
		description: "Title of paymentplansection",
		defaultMessage: "Payment plan"
	},
	commissionsection: {
		id: "form.summary.commission",
		description: "Provision summary section expansionGroup",
		defaultMessage: "Commissions"
	},
	documentuploadsection: {
		id: "form.summary.documentupload",
		description: "Document upload section expansionGroup",
		defaultMessage: "Document uploading"
	},
	cosignersection: {
		id: "form.summary.cosigner",
		description: "Cosigner section expansionGroup",
		defaultMessage: "Co-applicant"
	},
	signingsection: {
		id: "form.summary.signing",
		description: "Signing section expansionGroup",
		defaultMessage: "Signing"
	},
	fulfillmentsection: {
		id: "form.summary.fulfillment",
		description: "Fulfillment section expansionGroup",
		defaultMessage: "Fulfillment"
	},
	refinancingsection: {
		id: "form.summary.refinancing",
		description: "Refinancing section expansionGroup",
		defaultMessage: "Refinancing"
	},
	inputfieldssection: {
		id: "form.summary.inputfields",
		description: "Inputfields section expansionGroup",
		defaultMessage: "Additional information"
	},
	incomeexpensessection: {
		id: "form.summary.incomeexpenses",
		description: "Income expenses section expansionGroup",
		defaultMessage: "Income"
	},
	commentsSection: {
		id: "form.summary.comments",
		description: "Comments section group",
		defaultMessage: "Comments"
	},
	calculatorcontinue: {
		id: "dealer.applications.actions.new-application.label",
		description: "Label for continue button in calculator",
		defaultMessage: "New application"
	}
});
