import { InputAdornment, Paper } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DployTextField } from "@ploy-ui/form-fields";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

interface WholesaleOverviewSearchBarProps {
	searchFilter: (search: string) => void;
}

const formattedMessages = defineMessages({
	appNo: {
		id: "vendor.wholesale.search-appno",
		defaultMessage: "Search application number"
	},
	desc: {
		id: "vendor.wholesale.search-desc",
		defaultMessage: "Search description."
	},
	reg: {
		id: "vendor.wholesale.search-regno",
		defaultMessage: "Search registration number"
	},
	chassis: {
		id: "vendor.wholesale.search-chassisno",
		defaultMessage: "Search chassis number"
	}
});

const messageDict = [
	formattedMessages.appNo,
	formattedMessages.desc,
	formattedMessages.reg,
	formattedMessages.chassis
];

const WholesaleOverviewSearchBar = (props: WholesaleOverviewSearchBarProps) => {
	const { searchFilter } = props;

	const intl = useIntl();

	const [messageIndex, setMessageIndex] = useState(0);

	setTimeout(() => {
		setMessageIndex((messageIndex + 1) % messageDict.length);
	}, 3000);

	return (
		<Paper style={{ borderRadius: "24px" }}>
			<DployTextField
				onChange={e => searchFilter(e.target.value)}
				placeholder={intl.formatMessage(messageDict[messageIndex])}
				fullWidth
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Search />
						</InputAdornment>
					)
				}}
			/>
		</Paper>
	);
};

export { WholesaleOverviewSearchBar };
