import { BaseResource } from "./BaseResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { EndpointExtraOptions, Resource } from "@rest-hooks/rest";

export enum ReasonForOverride {
	NotSet = "NotSet",
	MissingRequiredCourse = "MissingRequiredCourse"
}

export type UserLimitations = Partial<Record<ReasonForOverride, boolean>>;

export class UserLimitationResource
	extends BaseResource
	implements UserLimitations
{
	readonly NotSet?: boolean;
	readonly MissingRequiredCourse?: boolean;

	pk() {
		return "all";
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: Infinity
		};
	}

	static url<T extends typeof Resource>(this: T) {
		return this.urlRoot;
	}

	static urlRoot = legacyApiResourceUrl("VulcanData/UserLimitations");
}
