import { Link } from "react-router-dom";
import { useTenantImage } from "@ploy-ui/tenants";

import { makeStyles } from "@material-ui/core/styles";

import { VulcanDataResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { ImageFit } from "@ploy-ui/core";

function MenuBanner() {
	const { homePage } = useResource(VulcanDataResource.detail(), {});

	const classes = useStyles();

	const fitProps = {
		fit: "contain",
		className: classes.img,
		alt: homePage
	};

	const mainlogo = useTenantImage("mainlogo");

	return (
		<Link to={homePage || "/"} className={classes.root}>
			<ImageFit src={mainlogo} {...fitProps} />
		</Link>
	);
}

MenuBanner.displayName = "MenuBanner";

const useStyles = makeStyles(
	{
		root: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: 6
		},
		img: {
			width: "100%",
			maxWidth: 124,
			height: 64
		}
	},
	{ name: "DrawerMenuBanner" }
);

export default MenuBanner;
