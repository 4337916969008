import React, { memo } from "react";
import { Formik, Form, Field } from "formik";
import { object, number, date } from "yup";
import { FormattedDate, FormattedNumber, useIntl } from "react-intl";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { ErrorDisplay } from "@ploy-lib/types";

import * as KmReadingAPI from "@ploy-lib/km-reading-api";
import {
	NumberField,
	KeyboardDatePickerField,
	TextArea
} from "@ploy-ui/form-fields";

import { SubmitHandler, KmReadingOptions } from "./KmReading";
import { LabeledProperty } from "./LabeledProperty";
import { dateAdd, calculate } from "./utils";
import { GetCalculationDetailsResponse } from "@ploy-lib/km-reading-api";

import { messages } from "./messages";

export interface RegisterFormValues {
	reading: number;
	readingDate: string;
	deliveryDate: Date;
	contractNo: string;
	regNo: string;
	limit: number;
	price: number;
	customerName: string;
	mvaPercentage: number;
	allowedKm: number;
	distanceKm: number;
	emailComment: string;
	objectType: number;
}

const RegistrationSchema = hideDeliveryDate => {
	return hideDeliveryDate
		? object().shape({
				reading: number().min(0, "Må være mer enn 0").required("Må fylles inn"),
				readingDate: date().required("Må velges")
		  })
		: object().shape({
				reading: number().min(0, "Må være mer enn 0").required("Må fylles inn"),
				readingDate: date().required("Må velges"),
				deliveryDate: date().required("Må velges")
		  });
};

export const ExpandableRegistrationObject = memo(
	({
		contractEndDate,
		contractNo,
		registrationNo,
		description,
		distanceKm,
		valueAmount,
		registeredKilometer,
		handleRegister,
		readingDate,
		deliveryDate,
		contractStartDate,
		customerName,
		options,
		priceExtraKm,
		isApproved,
		onExpand,
		emailComment,
		kmReadingAvailable,
		objectType
	}: KmReadingAPI.RegistrationObject & {
		handleRegister: SubmitHandler<RegisterFormValues>;
		options: KmReadingOptions;
		expanded?: boolean;
		onExpand(
			carRegNo: string,
			vendor: string
		): Promise<GetCalculationDetailsResponse | undefined>;
	}) => {
		const [loading, setLoading] = React.useState(false);
		const [errorLoading, setErrorLoading] = React.useState(false);

		const [calculationDetails, setCalculationDetails] =
			React.useState<GetCalculationDetailsResponse>({
				mileage: 0,
				vatPercentage: 0,
				priceExtraKilometer: 0
			});

		const intl = useIntl();

		const isRegistered = () => {
			if (options.canEditUntilApproved) return isApproved;
			return registeredKilometer > 0;
		};

		const registrationAllowed = () => {
			if (options.doCalculation) {
				return (
					kmReadingAvailable &&
					dateAdd({ months: options.allowedMonths }).getTime() >=
						new Date(contractEndDate).getTime() &&
					!isRegistered()
				);
			}
			return !isRegistered();
		};

		React.useEffect(() => {
			const getCalculationDetails = async (carRegNo: string) => {
				if (calculationDetails.priceExtraKilometer) return;
				setLoading(true);
				const response = await onExpand(carRegNo, "");
				if (response) {
					setCalculationDetails(oldState => ({
						...oldState,
						...response
					}));
				} else {
					setErrorLoading(true);
				}
				setLoading(false);
			};

			getCalculationDetails(registrationNo);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<Formik
				initialValues={{
					contractNo: contractNo,
					reading: registeredKilometer,
					readingDate: readingDate,
					deliveryDate: deliveryDate,
					price: options.doCalculation
						? calculationDetails.priceExtraKilometer
						: priceExtraKm,
					customerName: customerName,
					regNo: registrationNo,
					distanceKm: distanceKm,
					allowedKm: distanceKm + calculationDetails.mileage,
					limit:
						distanceKm +
						calculationDetails.mileage +
						(distanceKm * options.percentInterval) / 100,
					mvaPercentage: calculationDetails.vatPercentage,
					emailComment,
					objectType
				}}
				onSubmit={handleRegister}
				validationSchema={RegistrationSchema(options.hideDeliveryDate)}
				enableReinitialize
			>
				{({
					isSubmitting,
					submitForm,
					resetForm,
					dirty,
					values,
					isValid,
					status = {}
				}) => (
					<>
						<div>
							<Grid container spacing={3} justifyContent="space-between">
								<Grid xs={12} md={6} item>
									<LabeledProperty label="Avtale nr" value={contractNo} />
									<LabeledProperty label="Kunde" value={customerName} />
									<LabeledProperty
										label="Registreringsnummer"
										value={registrationNo}
									/>
									<LabeledProperty label="Modell / type" value={description} />
									<LabeledProperty
										label="Restverdibeløp"
										value={
											<FormattedNumber format="currency" value={valueAmount} />
										}
									/>
									<LabeledProperty
										label="Utløpsdato"
										value={
											<FormattedDate
												year="numeric"
												month="long"
												day="numeric"
												value={contractEndDate}
											/>
										}
									/>
									<LabeledProperty
										label="Start dato"
										value={
											<FormattedDate
												year="numeric"
												month="long"
												day="numeric"
												value={contractStartDate}
											/>
										}
									/>
									{registrationAllowed() && (
										<Typography variant="caption">
											For å registrere kilometer må du taste inn kilometerstand
											og dato for avlesing
										</Typography>
									)}
								</Grid>
								<Grid xs={12} md={4} item>
									<Form>
										<LabeledProperty
											label="Avtalte kilometer"
											value={
												<>
													<FormattedNumber value={distanceKm} /> km
												</>
											}
										/>
										{options.doCalculation ? (
											<LabeledProperty
												label="Kilometerstand ved oppstart"
												value={
													loading ? (
														<CircularProgress />
													) : (
														<>
															<FormattedNumber
																value={calculationDetails.mileage}
															/>{" "}
															km
														</>
													)
												}
											/>
										) : null}
										<LabeledProperty
											label="Pris overkjørte km"
											value={
												<>
													<FormattedNumber
														format="currency_full"
														value={
															options.doCalculation
																? calculationDetails.priceExtraKilometer
																: priceExtraKm
														}
													/>{" "}
													kr
												</>
											}
										/>
										<Field
											name="reading"
											label="Kilometerstand"
											component={NumberField}
											margin="normal"
											variant="outlined"
											disabled={isRegistered() || errorLoading || loading}
											fullWidth
											suffix="km"
											InputLabelProps={{ shrink: true }}
											errorDisplay={ErrorDisplay.Touched}
										/>
										{options.doCalculation ? (
											<DistanceCalculation
												actual={values.reading}
												planned={distanceKm}
												price={calculationDetails.priceExtraKilometer}
												allowedDeviation={options.allowedDeviation}
												mvaPercentage={calculationDetails.vatPercentage}
												mileage={calculationDetails.mileage}
											/>
										) : null}

										<Field
											name="readingDate"
											label="Avlesningsdato"
											component={KeyboardDatePickerField}
											margin="normal"
											variant="outlined"
											disabled={isRegistered() || errorLoading || loading}
											fullWidth
											autoOk
											disableFuture
											allowKeyboardControl
											format="long"
											InputLabelProps={{ shrink: true }}
											errorDisplay={ErrorDisplay.Touched}
										/>
										{!options.hideDeliveryDate ? (
											<Field
												name="deliveryDate"
												label="Innleveringsdato"
												component={KeyboardDatePickerField}
												margin="normal"
												variant="outlined"
												disabled={isRegistered() || errorLoading || loading}
												fullWidth
												autoOk
												allowKeyboardControl
												format="long"
												errorDisplay={ErrorDisplay.Touched}
											/>
										) : null}
										{!options.hideCommentSection ? (
											<Field
												name="emailComment"
												label="Kommentar"
												component={TextArea}
												margin="normal"
												variant="outlined"
												disabled={isRegistered() || errorLoading || loading}
												fullWidth
												autoOk
												allowKeyboardControl
												format="long"
											/>
										) : null}
									</Form>
								</Grid>
							</Grid>
						</div>
						{errorLoading ? (
							<Typography color={"error"}>
								{intl.formatMessage(messages.genericError)}
							</Typography>
						) : (
							<Typography color={!status.error ? "textPrimary" : "error"}>
								{status.error || status.message || " "}
							</Typography>
						)}

						{!isRegistered() && !errorLoading && (
							<>
								{((options.doCalculation && !kmReadingAvailable) || dirty) && (
									<Typography>
										{getMessage(
											values.reading,
											distanceKm + calculationDetails.mileage,
											options,
											kmReadingAvailable
										)}
									</Typography>
								)}

								<Button
									onClick={() => resetForm()}
									disabled={isSubmitting || !dirty}
								>
									Avbryt
								</Button>
								<Button
									onClick={() => submitForm()}
									variant="outlined"
									color="secondary"
									disabled={
										!registrationAllowed() ||
										loading ||
										isSubmitting ||
										!isValid
									}
								>
									{isSubmitting ? <CircularProgress size={20} /> : "Registrer"}
								</Button>
							</>
						)}
					</>
				)}
			</Formik>
		);
	}
);

interface DistanceCalculationProps {
	actual: number;
	planned: number;
	allowedDeviation: number;
	price: number;
	mvaPercentage: number;
	mileage: number;
}

const getMessage = (
	actual: number,
	planned: number,
	options: KmReadingOptions,
	kmReadingAvailable?: boolean
) => {
	if (!kmReadingAvailable) return options.messages.kmReadingNotAvailable;
	let difference = actual - planned;
	if (difference <= 0) return options.messages.lessThanPlanned;
	if (difference < options.allowedDeviation)
		return options.messages.lessThanAllowed;
	if (actual <= planned * (1 + options.percentInterval / 100))
		return options.messages.lessThanInterval;

	return options.messages.large;
};

const DistanceCalculation = ({
	actual,
	planned,
	allowedDeviation,
	price,
	mvaPercentage,
	mileage
}: DistanceCalculationProps) => {
	let difference = actual - planned - mileage;

	const value = calculate({
		price,
		amount: difference,
		mvaPercentage,
		allowedDeviation
	});
	return (
		<>
			<LabeledProperty
				label="Kostnad (netto)"
				value={
					<FormattedNumber
						format="currency_full"
						value={value.net ? value.net : 0}
					/>
				}
			/>
			<LabeledProperty
				label="MVA"
				value={
					<FormattedNumber
						format="currency_full"
						value={value.vat ? value.vat : 0}
					/>
				}
			/>
			<LabeledProperty
				label="Kostnad total (brutto)"
				value={
					<FormattedNumber
						format="currency_full"
						value={value.gross ? value.gross : 0}
					/>
				}
			/>
		</>
	);
};
