import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { RowProps } from "@ploy-ui/core";
import { ApplicationInfo, CarOrderDetails } from "@ploy-lib/rest-resources";

const useStyles = makeStyles(theme => ({
	root: {
		whiteSpace: "pre-line" as "pre-line",
		padding: theme.spacing(4),
		margin: theme.spacing(2)
	}
}));

export const CarOrderDescription = (
	props: RowProps<ApplicationInfo, CarOrderDetails>
) => {
	const classes = useStyles();
	return (
		<Paper className={classes.root}>
			<Typography variant="h4" paragraph>
				<FormattedMessage
					id="dealer.pendingorders.description.header"
					description="Car order information header"
					defaultMessage="Information"
				/>
			</Typography>
			<Typography>
				<FormattedMessage
					id="dealer.pendingorders.description.text"
					description="car order information "
					defaultMessage={`The car booking is returned to {name} after {return_days, plural, one {# days} other {# days}}.

					The car will be registered first on {legal_name}. Dealer confirms with stamp and signature the expected delivery date and confirmation that the car has been ordered.
					{legal_name} reserves the right to cancel the order if the requested date of delivery cannot be complied with.

					PS: Contact the customer for a review of all details in the car booking so that everything becomes as the customer wants. Any changes should be approved by {name}`}
					values={{
						legal_name: (
							<FormattedMessage
								id="tenant.legal_name"
								defaultMessage="Kreditor AS"
								description="The full legal name of the current tenant (financial institution)"
							/>
						),
						name: (
							<FormattedMessage
								id="tenant.name"
								defaultMessage="creditor"
								description="The name of the current tenant (financial institution)"
							/>
						),
						return_days: 3
					}}
				/>
			</Typography>
		</Paper>
	);
};
