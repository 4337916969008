import { ButtonField } from "./Button";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import {
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants
} from "./ButtonCommon";
import { apiResourceUrl } from "@ploy-lib/core";
import icons from "./Icons";
import { useAppLoad } from "../../appLoadContext";
import { useGTM } from "../../thirdPartyScripts/GoogleTagManagerHandler";

export const GoToSblButton = ({ muiEndIcon, ...props }: ButtonFieldProps) => {
	const appLoadData = useAppLoad();
	const pushGTMEvent = useGTM();

	const onClick = async (event: React.MouseEvent<Element, MouseEvent>) => {
		event.preventDefault();

		try {
			props.pending = true;

			const response = await fetch(
				apiResourceUrl(`/GoToSbl/${appLoadData.applicationId}/`),
				{ method: "GET" }
			);
	
			if (!response.ok) {
				throw new Error("Response from server is not OK");
			}
	
			const content = (await response.json()) as {
				success?: boolean;
				redirectUrl?: string;
			};
	
			if (!content.success) {
				throw new Error("Missing SBL redirect URL");
			}
	
			pushGTMEvent("add_to_cart");
			window.open(content.redirectUrl, "_parent");
		}
		catch(error: any) {
			throw new Error(`Error initiating SBL: ${error.message}`);
		}
		finally {
			props.pending = false;
		}
	};

	return (
		<ButtonField
			endIcon={muiEndIcon && icons[muiEndIcon]}
			{...props}
			onClick={onClick}
		/>
	);
};

GoToSblButton.displayName = "GoToSblButton";

export const EditorGoToSblButton = identityRecordOfFieldEditorOptions({
	GoToSblButton: {
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			muiEndIcons: Object.keys(icons)
		}
	}
});
