import React from "react";
import {
	NumberFieldProps,
	identityRecordOfFieldEditorOptions,
	SliderField,
	EditorSliderFields as EditorBaseSlider
} from "@ploy-ui/form-fields";
import { useVariableData } from "@ploy-lib/calculation";

export const EquitySlider = (props: NumberFieldProps) => {
	const { value: objectPriceField } = useVariableData(
		"Calculator",
		"ObjectPrice"
	);
	const { value: objectPriceExVatField } = useVariableData(
		"Calculator",
		"ObjectPriceExVat"
	);
	const objectPrice = objectPriceExVatField ?? objectPriceField;
	const { value: minEquity } = useVariableData("Calculator", "MinEquity");
	const { value: minEquityPercent } = useVariableData(
		"Calculator",
		"MinEquityPercent"
	);
	const minEquityPercentValue =
		(Number(objectPrice) / 100) * Number(minEquityPercent);
	const { value: maxEquity } = useVariableData("Calculator", "MaxEquity");
	const { value: maxEquityPercent } = useVariableData(
		"Calculator",
		"MaxEquityPercent"
	);
	const maxEquityPercentValue =
		Number(maxEquityPercent) > 0
			? (Number(objectPrice) / 100) * Number(maxEquityPercent)
			: Number(objectPrice);

	var maxEquityToUse = Number(
		Math.min(Number(maxEquityPercentValue), Number(maxEquity ?? objectPrice))
	);

	if (maxEquityToUse === 0 || maxEquityToUse === Number(objectPrice))
		maxEquityToUse = Number(objectPrice) - 50000;

	const minEquityToUse = Math.max(
		Number(minEquityPercentValue),
		Number(minEquity)
	);

	const minValue = Math.max(minEquityToUse, 0);
	const maxValue = Math.max(maxEquityToUse, minValue);
	return (
		<SliderField
			{...props}
			jumpToValidInterval={true}
			min={minValue}
			max={maxValue}
			step={1000}
		/>
	);
};

export const TermsYearsSlider = (props: NumberFieldProps) => {
	const { value: minTotalMonths } = useVariableData(
		"Calculator",
		"MinTotalMonths"
	);
	const { value: minTerms } = useVariableData("Calculator", "MinTerms");
	const { value: maxTotalMonths } = useVariableData(
		"Calculator",
		"MaxTotalMonths"
	);
	const { value: maxTerms } = useVariableData("Calculator", "MaxTerms");
	return (
		<SliderField
			{...props}
			jumpToValidInterval={true}
			min={Math.max(Number(minTerms) / 12, Number(minTotalMonths) / 12, 1)}
			max={Math.min(Number(maxTerms) / 12, Number(maxTotalMonths) / 12, 30)}
		/>
	);
};

export const ObjectPriceSlider = (props: NumberFieldProps) => {
	const { value: maxObjectPrice } = useVariableData(
		"Calculator",
		"MaxObjectPrice"
	);

	const { value: minObjectPrice } = useVariableData(
		"Calculator",
		"MinObjectPrice"
	);

	// maxObjectPrice defaults to 1000000000 if not defined in pricelist DT for the given product.
	const maxObjectPriceToUse =
		maxObjectPrice === 1000000000 ? 2000000 : maxObjectPrice;

	return (
		<SliderField
			{...props}
			jumpToValidInterval={true}
			min={Number(minObjectPrice) ?? 0}
			max={Number(maxObjectPriceToUse)}
			step={1000}
		/>
	);
};

export const EditorSliderFields = identityRecordOfFieldEditorOptions({
	EquitySlider: EditorBaseSlider.SliderField,
	TermsYearsSlider: EditorBaseSlider.SliderField,
	ObjectPriceSlider: EditorBaseSlider.SliderField
});
