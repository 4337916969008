import { BeneficialOwner } from "@ploy-lib/rest-resources";
import { ValidationHelpers } from "@ploy-lib/validation-helpers";
import { ConfigElement } from "@ploy-ui/core";
import { IntlShape } from "react-intl";
import * as yup from "yup";

const messages = {
	entNrLabel: {
		id: "modal.beneficialOwner.field.entNr.label",
		defaultMessage: "Personnummer",
		description: "FieldLabel for entNr"
	},
	firstnameLabel: {
		id: "modal.beneficialOwner.field.firstname.label",
		defaultMessage: "Fornavn",
		description: "FieldLabel for firstname"
	},
	lastnameLabel: {
		id: "modal.beneficialOwner.field.lastname.label",
		defaultMessage: "Etternavn",
		description: "FieldLabel for firstname"
	},
	addressLabel: {
		id: "modal.beneficialOwner.field.address.label",
		defaultMessage: "Adresse",
		description: "FieldLabel for address"
	},
	addressCityLabel: {
		id: "modal.beneficialOwner.field.addressCity.label",
		defaultMessage: "Poststed",
		description: "FieldLabel for addressCity"
	},
	addressZipLabel: {
		id: "modal.beneficialOwner.field.addressZip.label",
		defaultMessage: "Postnummer",
		description: "FieldLabel for addressZip"
	},
	shareLabel: {
		id: "modal.beneficialOwner.field.share.label",
		defaultMessage: "Andel",
		description: "FieldLabel for share"
	},
	shareTooLowError: {
		id: "modal.beneficialOwner.share.tooLowError",
		defaultMessage: "Andelen må være over 25%",
		description: "Error message when share is under 25%"
	},
	shareTooHighError: {
		id: "modal.beneficialOwner.share.tooHighError",
		defaultMessage: "Andelen kan ikke være over 100%",
		description: "Error message when share is over 100%"
	},
	invalidSSNError: {
		id: "modal.beneficialOwner.share.invalidSSN",
		defaultMessage: "Oppgitt personnummer er ugyldig",
		description: "Error message when SSN is not valid"
	},
	fieldRequired: {
		id: "modal.beneficialOwner.field.required",
		defaultMessage: "Feltet må fylles inn",
		description: "Error message when a required field is empty"
	}
};

function validShortDate(value: string, format: string) {
	if (value.length < format.length) return false;
	var y = value.substring(format.indexOf("y"), format.lastIndexOf("y") + 1);
	var m = value.substring(format.indexOf("m"), format.lastIndexOf("m") + 1);
	var d = value.substring(format.indexOf("d"), format.lastIndexOf("d") + 1);

	var date = new Date(`${y.length === 4 ? y : "20" + y}/${m}/${d}`);

	return !isNaN(date.getTime());
}

export const createBeneficialOwnerConfig = (
	intl: IntlShape,
	validation: ValidationHelpers,
	useAlternativeEntNrValidation: Boolean,
	dateFormat: string
) =>
	[
		{
			name: "entNr",
			title: intl.formatMessage(messages.entNrLabel),
			required: true,
			validation: yup
				.string()
				.required(intl.formatMessage(messages.fieldRequired))
				.test("validssn", intl.formatMessage(messages.invalidSSNError), value =>
					useAlternativeEntNrValidation
						? validShortDate(value, dateFormat) &&
						  validation.isLengthOfSSN(value)
						: validShortDate(value, dateFormat) || validation.validSsn(value)
				),
			autofillKey: true
		},
		{
			name: "firstname",
			title: intl.formatMessage(messages.firstnameLabel),
			required: true,
			validation: yup
				.string()
				.required(intl.formatMessage(messages.fieldRequired))
		},
		{
			name: "lastname",
			title: intl.formatMessage(messages.lastnameLabel),
			required: true,
			validation: yup
				.string()
				.required(intl.formatMessage(messages.fieldRequired))
		},
		{
			name: "address",
			title: intl.formatMessage(messages.addressLabel),
			validation: yup
				.string()
				.required(intl.formatMessage(messages.fieldRequired))
		},
		{
			name: "addressCity",
			title: intl.formatMessage(messages.addressCityLabel),
			validation: yup
				.string()
				.required(intl.formatMessage(messages.fieldRequired))
		},
		{
			name: "addressZip",
			title: intl.formatMessage(messages.addressZipLabel),
			validation: yup
				.string()
				.required(intl.formatMessage(messages.fieldRequired))
		},
		{
			name: "share",
			title: intl.formatMessage(messages.shareLabel),
			validation: yup
				.number()
				.min(25, intl.formatMessage(messages.shareTooLowError))
				.max(100, intl.formatMessage(messages.shareTooHighError))
				.required(intl.formatMessage(messages.fieldRequired))
		}
	] as ConfigElement<BeneficialOwner>[];
