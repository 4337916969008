import React, { useMemo, useState } from "react";
import { Formik, Form, useFormikContext, FormikHelpers, Field } from "formik";
import { useIntl, defineMessages } from "react-intl";

import {
	Grid,
	FormControl,
	FormHelperText,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { TextField, KeyboardDatePickerField } from "@ploy-ui/form-fields";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";
import { object, string } from "yup";

import {
	ApplicationInfo,
	ProofOfDeliveryInformation,
	ProofOfDeliveryInformationResource
} from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";

const messages = defineMessages({
	invalidDate: {
		id: "form_fields.datetimepicker.missing",
		defaultMessage: "Date is missing",
		description: "text for missing date"
	},
	missingPhoneNumber: {
		id: "form_fields.phoneNumber.missing",
		defaultMessage: "Missing mobile number",
		description: "text for missing phone number"
	},
	wrongPhoneNumber: {
		id: "form_fields.phoneNumber.wrong",
		defaultMessage: "Must be in XXXXXXXX format (8 digits)",
		description: "text for wrong phone number"
	}
});

interface ProofOfDeliveryConfirmation {
	deliveryDate: string | Date | null;
	phoneNumber: string | undefined;
	applicationId: number | string | null;
}

interface ProofOfDeliverySmsActionProps {
	loadedData?: ProofOfDeliveryInformation | undefined;
	rowData?: ApplicationInfo;
	reload?: () => Promise<void>;
	className?: string;
	reloadData?: () => Promise<ProofOfDeliveryInformationResource>;
	onClick?: () => void;
	regNumber?: string | undefined;
	errors?: boolean;
}

const ProofOfDeliverySmsActions = (props: ProofOfDeliverySmsActionProps) => {
	const {
		loadedData,
		reload,
		className,
		reloadData,
		onClick,
		regNumber,
		rowData,
		errors
	} = props;

	const intl = useIntl();

	const registrationNumber = rowData?.field8 || regNumber;
	const disableButton =
		errors ||
		loadedData?.wrongCausalOrder ||
		registrationNumber === undefined ||
		registrationNumber === null ||
		registrationNumber === "";

	const ProofOfDeliverySchema = useMemo(
		() =>
			object<Partial<ProofOfDeliveryConfirmation>>().shape({
				deliveryDate: string().required(
					intl.formatMessage(messages.invalidDate)
				),
				phoneNumber: string()
					.matches(/(^[0-9]{8}$)|(^[0-9]{3}[0-9]{2}[0-9]{3}$)/, {
						excludeEmptyString: true,
						message: intl.formatMessage(messages.wrongPhoneNumber)
					})
					.required(intl.formatMessage(messages.missingPhoneNumber))
			}),
		[intl]
	);

	if (!loadedData) return null;

	return (
		<Formik<Partial<ProofOfDeliveryInformation>>
			initialValues={{
				deliveryDate: loadedData.deliveryDate || "",
				phoneNumber: loadedData.phoneNumber || "",
				applicationId: loadedData.applicationId || ""
			}}
			onSubmit={async (data, formik) => {
				await onConfirm(data, formik, loadedData);
				await reload?.();
				await reloadData?.();
			}}
			validationSchema={ProofOfDeliverySchema}
			enableReinitialize
			validateOnMount
		>
			{({
				isSubmitting,
				status = {
					...(loadedData.deliverySent && {
						message: getFormattedConfirmationMessage(loadedData.receiver)
					})
				}
			}) => {
				return (
					<Form>
						<Grid container spacing={2} className={className} onClick={onClick}>
							<Grid item xs={"auto"} md={"auto"} lg={"auto"}>
								<Field
									name="deliveryDate"
									label={
										<FormattedMessage
											id="dealer.delivery.date"
											description="text for deliverydate"
											defaultMessage="Delivery date"
										/>
									}
									component={KeyboardDatePickerField}
									disabled={isSubmitting || disableButton}
									margin="normal"
									variant="outlined"
									helperText=" "
									fullWidth
									autoOk
									allowKeyboardControl
									format="long"
								/>
							</Grid>
							<Grid item xs={"auto"} md={"auto"} lg={"auto"}>
								<Field
									name="phoneNumber"
									label={
										<FormattedMessage
											id="dealer.mobilenumber"
											description="text for mobilenumber"
											defaultMessage="Mobile number"
										/>
									}
									component={TextField}
									disabled={isSubmitting || disableButton}
									margin="normal"
									variant="outlined"
									helperText=" "
									fullWidth
									type="tel"
								/>
							</Grid>
							{!disableButton && (
								<Grid item xs={"auto"} md={"auto"} lg={"auto"}>
									<FormControl variant="standard" margin="normal" fullWidth>
										<SubmitButton
											type="button"
											variant="contained"
											color="secondary"
											size="large"
											fullWidth
										>
											<FormattedMessage
												id="dealer.sendDeliveryOfProof.button.label"
												description="Label for send proof of delivery button"
												defaultMessage="Send leveringsbevis"
											/>
										</SubmitButton>
										<FormHelperText
											color={
												status.success && !status.error ? "inherit" : "error"
											}
										>
											{status.error || status.message || " "}
										</FormHelperText>
									</FormControl>
								</Grid>
							)}
						</Grid>
					</Form>
				);
			}}
		</Formik>
	);
};

const getFormattedConfirmationMessage = (number: number | null) =>
	number ? (
		<FormattedMessage
			id="dealer.deliveryOfProofSentSms.withNumber"
			description="text for delivery of proof sent with reciever number"
			defaultMessage="Leveringsbevis er sendt til: + {number}"
			values={{ number: number }}
		/>
	) : (
		<FormattedMessage
			id="dealer.deliveryOfProofSentSms.withoutNumber"
			description="text for delivery of proof sent without reciever number"
			defaultMessage="Leveringsbevis er sendt."
		/>
	);

const onConfirm = async (
	data: any,
	formik: FormikHelpers<any>,
	loadedData: ProofOfDeliveryInformation
) => {
	const response = await fetch(legacyApiResourceUrl("ProofOfDelivery/Send"), {
		method: "POST",
		body: new Blob([JSON.stringify(data)], { type: "application/json" }),
		headers: {
			accept: "application/json"
		}
	});
	if (response.ok) {
		formik.setSubmitting(false);
		if (!loadedData.phoneNumber || loadedData.phoneNumber !== data.phoneNumber)
			formik.resetForm();
	} else {
		formik.setStatus({
			error: (
				<FormattedMessage
					id="dealer.deliveryOfProof.error"
					description="Error text when delivery of proof fails"
					defaultMessage="An error has occured"
				/>
			)
		});
	}
};

export function SubmitButton(props: PendingButtonProps) {
	const formik = useFormikContext();

	const [open, setOpen] = useState(false);
	return (
		<>
			<PendingButton
				pending={formik.isSubmitting}
				disabled={!formik.isValid}
				onClick={() => {
					setOpen(true);
				}}
				{...props}
			/>
			<Dialog open={open}>
				<DialogContent>
					<DialogContentText>
						<FormattedMessage
							id="dealer.deliveryOfProof.dialog.message"
							description="DeliveryOfProof dialog message"
							defaultMessage="Bekreft utsendelse av leveringsbevis"
						/>
					</DialogContentText>
					<DialogActions style={{ justifyContent: "space-between" }}>
						<PendingButton onClick={() => setOpen(false)}>
							<FormattedMessage
								id="dealer.deliveryOfProof.dialog.cancel.button"
								description="Avbryt knapp i deliveryOfProof dialog"
								defaultMessage="Cancel"
							/>
						</PendingButton>
						<PendingButton
							onClick={() => {
								formik.submitForm();
								setOpen(false);
							}}
						>
							<FormattedMessage
								id="dealer.deliveryOfProof.dialog.send.button"
								description="Send knapp i deliveryOfProof dialog"
								defaultMessage="Send"
							/>
						</PendingButton>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
}

export { ProofOfDeliverySmsActions };
