import { useCallback } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { TemplateSection } from "@ploy-lib/types";
import { useTemplateFieldIsVisible } from "./useTemplateFieldIsVisible";
import { hasRegisteredSectionLayout } from "../section/sectionLayoutDescriptions";

export function useTemplateSectionIsVisible() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const isTemplateFieldVisible = useTemplateFieldIsVisible({
		ignoreRoles: true
	});

	return useCallback(
		(section: TemplateSection) => {
			const {
				layout,
				fields,
				hideForMobile = false,
				hideSectionIfNoVisibleFields = false
			} = section;
			if (isMobile && hideForMobile) return false;

			const LayoutComponent = layout && hasRegisteredSectionLayout(layout);

			// Will only be used if section has no layout, visibilityFilter fields are never shown
			const fieldsWithoutVisibilityFilters = fields.filter(
				f =>
					f.role !== "visibilityFilter" && f.role !== "visibilityFilterInverse"
			);

			// Hide standard sections without visible fields
			const hasSomethingToRender = hideSectionIfNoVisibleFields
				? fields.some(isTemplateFieldVisible)
				: LayoutComponent != null ||
				  fieldsWithoutVisibilityFilters.some(isTemplateFieldVisible);
			if (!hasSomethingToRender) return false;

			const visibilityFilters = fields.filter(
				f => f.role === "visibilityFilter"
			);

			const inverseVisibilityFilters = fields.filter(
				f => f.role === "visibilityFilterInverse"
			);

			const partialVisibilityFilters = fields.filter(
				f => f.role === "visibilityFilterPartial"
			);

			return (
				// Every "visibilityFilter" field must be visible
				visibilityFilters.every(isTemplateFieldVisible) &&
				// Every "visibilityFilterInverse" must be hidden
				!inverseVisibilityFilters.some(isTemplateFieldVisible) &&
				// Some "visibilityFilterPartial" field must be visible
				(partialVisibilityFilters.length === 0 ||
					partialVisibilityFilters.some(isTemplateFieldVisible))
			);
		},
		[isTemplateFieldVisible, isMobile]
	);
}
