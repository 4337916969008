import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { RowProps } from "../ExpandableTable";
import { useTheme } from "@material-ui/core";

export type ColoredStatusProps<
	TData extends object,
	TLoaded extends object
> = RowProps<TData, TLoaded> & { statusKey: string };

const ColoredStatus = <TData extends object, TLoaded extends object>({
	rowData,
	loadedData,
	statusKey,
	...props
}: ColoredStatusProps<TData, TLoaded> & BoxProps) => {
	const theme = useTheme();

	const status = rowData[statusKey];

	const statusColor = theme.palette?.status?.[status] as
		| string
		| null
		| undefined;
	const contrastText =
		statusColor && theme.palette?.getContrastText(statusColor);

	return (
		<Box
			borderRadius="100px"
			p={0.5}
			fontSize={10}
			color={contrastText}
			textAlign="center"
			bgcolor={`status.${status}`}
			{...props}
		>
			{props.children && props.children.toString().toLocaleUpperCase()}
		</Box>
	);
};

export default ColoredStatus;
