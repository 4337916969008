import {
	DashboardGrid,
	defaultColumns,
	GridItem,
	Layout,
	Layouts,
	DashboardGridResource
} from "@ploy-ui/dashboard";
import { isEqual, isUndefined, mapValues, omitBy } from "lodash";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { v4 as uuid } from "uuid";

interface PayloadActionType<Type, Payload> {
	type: Type;
	payload: Payload;
}

interface BareActionType<Type> {
	type: Type;
}

type ActionType<Type, Payload = undefined> = Payload extends undefined
	? BareActionType<Type>
	: PayloadActionType<Type, Payload>;

export type Actions =
	| ActionType<"START_EDIT_DASHBOARD", { id?: string; ifEditing?: string }>
	| ActionType<"STOP_EDIT_DASHBOARD", { id?: string }>
	| ActionType<
			"SAVE_DASHBOARD_DIALOG_FORM",
			{ dashboard: Partial<DashboardGrid> }
	  >
	| ActionType<"OPEN_DASHBOARD_DIALOG_FORM">
	| ActionType<"CLOSE_DASHBOARD_DIALOG_FORM">
	| ActionType<"ADD_DASHBOARD">
	| ActionType<"COPY_DASHBOARD">
	| ActionType<"CHANGE_LAYOUTS", { layouts: Layouts; current: Layout[] }>
	| ActionType<"CHANGE_ITEM", { item: GridItem }>
	| ActionType<"REMOVE_ITEM", { item: Pick<GridItem, "id"> }>
	| ActionType<
			"DROP_LAYOUT_ITEM",
			{
				layoutItem: Layout;
				layout: Layout[];
				breakpoint: string;
			}
	  >
	| ActionType<"DRAG_ITEM", { component: GridItem["component"] }>
	| ActionType<"DROP_ITEM", { component: GridItem["component"] }>
	| ActionType<
			"CHANGE_EDITOR_BREAKPOINT",
			{ breakpoint: Breakpoint | undefined }
	  >
	| ActionType<"CHANGE_MAX_BREAKPOINT", { breakpoint: Breakpoint }>;

export interface State {
	editedById: Record<string, DashboardGrid>;
	dirtyBreakpointsById: Record<string, Partial<Record<Breakpoint, boolean>>>;
	editing?: string;
	dialogEditing?: DashboardGrid;
	draggingComponent?: GridItem["component"];
	editorBreakpoint?: Breakpoint;
	maxBreakpoint?: Breakpoint;
}

export const initialState: State = {
	editedById: {},
	dirtyBreakpointsById: {}
};

export const isChangedSelector = (state: State, id?: string) =>
	id != null && state.editedById[id] != null;

export function createReducer(
	dashboards: Map<string | undefined, DashboardGrid>
) {
	return function reducer(state: State, action: Actions): State {
		const { editing, dialogEditing } = state;

		const originalGrid = dashboards.get(editing)?.layouts
			? dashboards.get(editing)
			: undefined;

		const editingGrid = editing
			? state.editedById[editing] ?? originalGrid
			: undefined;

		switch (action.type) {
			case "START_EDIT_DASHBOARD":
				if (
					action.payload.ifEditing != null &&
					action.payload.ifEditing !== editing
				)
					break;

				if (editing !== action.payload.id) {
					return {
						...state,
						editing: action.payload.id
					};
				}
				break;

			case "STOP_EDIT_DASHBOARD":
				return resetToOriginal(action.payload.id ?? editing, state);

			case "SAVE_DASHBOARD_DIALOG_FORM":
				if (dialogEditing && dialogEditing) {
					const id = DashboardGridResource.pk(dialogEditing) ?? uuid();

					const newGrid = {
						...state.editedById[id],
						...dialogEditing,
						...action.payload.dashboard
					};

					if (isEqual(newGrid, originalGrid))
						return resetToOriginal(id, {
							...state,
							editing: id,
							dialogEditing: undefined
						});

					return {
						...state,
						editedById: {
							...state.editedById,
							[id]: newGrid
						},
						editing: id,
						dialogEditing: undefined
					};
				}
				break;

			case "OPEN_DASHBOARD_DIALOG_FORM":
				if (editing) {
					return {
						...state,
						dialogEditing: editingGrid
					};
				}
				break;

			case "CLOSE_DASHBOARD_DIALOG_FORM":
				return {
					...state,
					dialogEditing: undefined
				};

			case "COPY_DASHBOARD":
				if (editing && editingGrid) {
					const { _legacyId, ...rest } = editingGrid;

					return {
						...state,
						dialogEditing: {
							...rest,
							name: `Kopi av ${editingGrid.name}`,
							id: undefined
						}
					};
				}
				break;

			case "ADD_DASHBOARD":
				return {
					...state,
					dialogEditing: {
						name: "",
						items: {},
						layouts: {},
						columns: defaultColumns
					}
				};

			case "DRAG_ITEM":
				return {
					...state,
					draggingComponent: action.payload.component
				};

			case "DROP_ITEM":
				if (state.draggingComponent) {
					return {
						...state,
						draggingComponent: undefined
					};
				}
				break;

			case "DROP_LAYOUT_ITEM":
				if (editing && state.draggingComponent && editingGrid) {
					const id = uuid();

					const brk = state.editorBreakpoint ?? state.maxBreakpoint;

					const newGrid = {
						...editingGrid,
						items: {
							...editingGrid.items,
							[id]: {
								id: id,
								component: state.draggingComponent,
								properties: {}
							}
						},
						layouts: mapValues(editingGrid.layouts, (layout, key) =>
							key === action.payload.breakpoint
								? action.payload.layout.map(item =>
										item.i === action.payload.layoutItem.i
											? { ...item, i: id }
											: item
								  )
								: // Other breakpoints:

								  // Will likely conflict with other items,
								  // but "react-grid-layout" will fix it as best it can.
								  // Result is likely not as intended, but fixing
								  // it manually here is not trivial
								  [...layout, { ...action.payload.layoutItem, i: id }]
						)
					};

					if (isEqual(newGrid, originalGrid))
						return resetToOriginal(editing, state);

					return {
						...state,
						dirtyBreakpointsById: brk
							? {
									...state.dirtyBreakpointsById,
									[editing]: mapValues(
										editingGrid.layouts,
										(_, key) => key !== brk
									)
							  }
							: state.dirtyBreakpointsById,
						editedById: {
							...state.editedById,
							[editing]: newGrid
						}
					};
				}
				break;

			case "CHANGE_ITEM":
				if (editing && editingGrid) {
					const newGrid = {
						...editingGrid,
						items: {
							...editingGrid.items,
							[action.payload.item.id]: action.payload.item
						}
					};

					if (isEqual(newGrid, originalGrid))
						return resetToOriginal(editing, state);

					return {
						...state,
						editedById: {
							...state.editedById,
							[editing]: newGrid
						}
					};
				}
				break;

			case "REMOVE_ITEM":
				if (editing && editingGrid) {
					const { [action.payload.item.id]: _, ...items } = editingGrid.items;

					const newGrid = {
						...editingGrid,
						items,
						layouts: mapValues(editingGrid.layouts, layout =>
							layout.filter(l => l.i !== action.payload.item.id)
						)
					};

					if (isEqual(newGrid, originalGrid))
						return resetToOriginal(editing, state);

					const brk = state.editorBreakpoint ?? state.maxBreakpoint;

					return {
						...state,
						dirtyBreakpointsById: brk
							? {
									...state.dirtyBreakpointsById,
									[editing]: mapValues(
										editingGrid.layouts,
										(_, key) => key !== brk
									)
							  }
							: state.dirtyBreakpointsById,
						editedById: {
							...state.editedById,
							[editing]: newGrid
						}
					};
				}
				break;

			case "CHANGE_LAYOUTS":
				if (editing && editingGrid && !state.draggingComponent) {
					let { layouts } = action.payload;

					layouts = mapValues(layouts, l =>
						l.map(i => omitBy(i, isUndefined) as typeof i)
					);

					const newGrid = {
						...editingGrid,
						layouts
					};

					if (isEqual(newGrid, originalGrid))
						return resetToOriginal(editing, state);

					if (!isEqual(newGrid.layouts, editingGrid.layouts)) {
						const brk = state.editorBreakpoint ?? state.maxBreakpoint;

						return {
							...state,
							dirtyBreakpointsById: brk
								? {
										...state.dirtyBreakpointsById,
										[editing]: {
											...state.dirtyBreakpointsById[editing],
											[brk]: false
										}
								  }
								: state.dirtyBreakpointsById,
							editedById: {
								...state.editedById,
								[editing]: newGrid
							}
						};
					}
				}
				break;

			case "CHANGE_MAX_BREAKPOINT":
				return {
					...state,
					maxBreakpoint: action.payload.breakpoint
				};

			case "CHANGE_EDITOR_BREAKPOINT":
				return {
					...state,
					editorBreakpoint: action.payload.breakpoint
				};
		}

		return state;
	};
}

function resetToOriginal(id: string | undefined, state: State) {
	if (!id) return state;

	if (!state.editedById[id] && !state.dirtyBreakpointsById[id]) return state;

	const { [id]: _, ...editedById } = state.editedById;
	const { [id]: _1, ...dirtyBreakpointsById } = state.dirtyBreakpointsById;

	return {
		...state,
		editedById,
		dirtyBreakpointsById
	};
}
