import { BaseResource } from "./BaseResource";
import { legacyApiResourceUrl, deeplyApplyKeyTransform } from "@ploy-lib/core";
import camelCase from "lodash/camelCase";
import { AbstractInstanceType, Resource, SchemaDetail } from "@rest-hooks/rest";

export interface ExternalViewSettings {
	readonly view: string;
	readonly component: string;
	readonly dtResult: Record<string, string>;
	readonly endpoint: string;
	readonly internalUser: boolean;
	readonly token: string;
}

export class ExternalViewSettingsResource
	extends BaseResource
	implements ExternalViewSettings
{
	readonly view: string = "";
	readonly component: string = "";
	readonly dtResult: Record<string, string> = {};
	readonly endpoint: string = "";
	readonly internalUser: boolean = false;
	readonly token: string = "";

	pk() {
		return this.view;
	}

	static detail<T extends typeof Resource>(this: T) {
		return super.detail().extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			method: "POST"
		});
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		// perform actual network request getting back json
		const jsonResponse = await super.fetch(input, init);

		const data = deeplyApplyKeyTransform(jsonResponse, camelCase);

		if (data.endpoint && data.FetchBeforeLoadingComponent) {
			await fetch(data.endpoint, {
				credentials: "same-origin",
				headers: {
					authorization: data.token
				}
			});
		}

		// do the conversion!
		return data;
	}

	static urlRoot = legacyApiResourceUrl("ExternalView/LoadJson");
}
