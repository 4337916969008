import { defineMessages } from "react-intl";

export const messages = {
	grouping: defineMessages({
		groupedBy: {
			id: "datatable.grouping.groupBy",
			description: "Grouped By:",
			defaultMessage: "Group by:"
		},
		placeholder: {
			id: "datatable.grouping.placeholder",
			description: "Drag headers here to group by",
			defaultMessage: "Drop title here to group"
		}
	}),
	pagination: defineMessages({
		labelDisplayedRows: {
			id: "datatable.pagination.labelDesplayedRows",
			description: "'{from}'-'{to}' of '{count}'",
			defaultMessage: "'{from}' - '{to}' of '{count}'"
		},
		labelRowsPerPage: {
			id: "datatable.pagination.labelRowsPerPage",
			description: "Rows per page:",
			defaultMessage: "Rows per page:"
		},
		labelRowsSelect: {
			id: "datatable.pagination.labelRowsSelect",
			description: "rows",
			defaultMessage: "rows"
		},
		firstAriaLabel: {
			id: "datatable.pagination.firstAriaLabel",
			description: "First Page",
			defaultMessage: "First page"
		},
		firstTooltip: {
			id: "datatable.pagination.firstTooltip",
			description: "First Page",
			defaultMessage: "First page"
		},
		previousAriaLabel: {
			id: "datatable.pagination.previousAriaLabel",
			description: "Previous Page",
			defaultMessage: "Last page"
		},
		previousTooltip: {
			id: "datatable.pagination.previousTooltip",
			description: "Previous Page",
			defaultMessage: "Last page"
		},
		nextAriaLabel: {
			id: "datatable.pagination.nextAriaLabel",
			description: "Next Page",
			defaultMessage: "Next page"
		},
		nextTooltip: {
			id: "datatable.pagination.nextTooltip",
			description: "Next Page",
			defaultMessage: "Next page"
		},
		lastAriaLabel: {
			id: "datatable.pagination.lastAriaLabel",
			description: "Last Page",
			defaultMessage: "Last page"
		},
		lastTooltip: {
			id: "datatable.pagination.lastTooltip",
			description: "Last Page",
			defaultMessage: "Last page"
		}
	}),
	toolbar: defineMessages({
		addRemoveColumns: {
			id: "datatable.toolbar.addRemoveColumns",
			description: "Add or remove columns",
			defaultMessage: "Add or delete columns"
		},
		nRowsSelected: {
			id: "datatable.toolbar.nRowsSelected",
			description: "'{0}' row(s) selected",
			defaultMessage: "'{0}' row (s) selected"
		},
		showColumnsTitle: {
			id: "datatable.toolbar.showColumnsTitle",
			description: "Show columns",
			defaultMessage: "Show columns"
		},
		showColumnsAriaLabel: {
			id: "datatable.toolbar.showColumnsAriaLabel",
			description: "Show columns",
			defaultMessage: "Show columns"
		},
		exportTitle: {
			id: "datatable.toolbar.exportTitle",
			description: "Export",
			defaultMessage: "Export"
		},
		exportAriaLabel: {
			id: "datatable.toolbar.exportAriaLabel",
			description: "Export",
			defaultMessage: "Export"
		},
		exportName: {
			id: "datatable.toolbar.exportName",
			description: "Export as CVS",
			defaultMessage: "Export as CSV"
		},
		searchPlaceholder: {
			id: "datatable.toolbar.searchPlaceholder",
			description: "Search",
			defaultMessage: "Search"
		},
		searchTooltip: {
			id: "datatable.toolbar.searchTooltip",
			description: "Search",
			defaultMessage: "Search"
		}
	}),
	header: defineMessages({
		actions: {
			id: "datatable.header.actions",
			description: "Actions",
			defaultMessage: "Actions"
		}
	}),
	bodyFilterRow: defineMessages({
		filterTooltip: {
			id: "datatable.body.filterRow.filterTooltip",
			description: "Filter",
			defaultMessage: " Filter"
		}
	}),
	bodyEditRow: defineMessages({
		saveTooltip: {
			id: "datatable.body.editRow.saveTooltip",
			description: "Save",
			defaultMessage: "Save"
		},
		cancelTooltip: {
			id: "datatable.body.editRow.cancelTooltip",
			description: "Cancel",
			defaultMessage: "Cancel"
		},
		deleteText: {
			id: "datatable.body.editRow.deleteText",
			description: "Are you sure delete this row?",
			defaultMessage: "Delete the selected row?"
		}
	}),
	body: defineMessages({
		addTooltip: {
			id: "datatable.body.addTooltip",
			description: "Add",
			defaultMessage: "Add"
		},
		deleteTooltip: {
			id: "datatable.body.deleteTooltip",
			description: "Delete",
			defaultMessage: "Delete"
		},
		editTooltip: {
			id: "datatable.body.editTooltip",
			description: "Edit",
			defaultMessage: "Change"
		},
		emptyDataSourceMessage: {
			id: "datatable.body.emptyDataSourceMessage",
			description: "No records to display",
			defaultMessage: "No results"
		}
	})
};
