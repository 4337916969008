import { SectionProps } from "../../Section";
import { Typography } from "@material-ui/core";
import { addRegisteredSectionLayout } from "../../sectionLayoutDescriptions";
import { FormattedMessage } from "react-intl";

import { Formik } from "formik";

import * as resources from "@ploy-lib/rest-resources";
import { SimpleResource } from "@rest-hooks/rest";

import {
	ResourceDataProvider,
	CalcRulesDataProvider
} from "./TableDataProvider";
import { getFieldName } from "@ploy-lib/core";

import { FormTable } from "./FormTable";

addRegisteredSectionLayout({
	name: "FormTableSection",
	displayName: "FormTableSection",
	settings: {
		editableOptions: {
			tableDataType: true,
			fullWidth: true
		}
	}
});

const FormTableSection = (props: SectionProps) => {
	const { dataSource, tableType = "", tableColumns = [] } = props;

	const resource = dataSource?.isResource
		? (resources[dataSource.sourceName] as typeof SimpleResource)
		: null;

	if (dataSource?.isResource) {
		if (resource && dataSource.entityId) {
			return (
				<ResourceDataProvider
					resource={resource}
					resourceId={dataSource.entityId}
					tableColumns={tableColumns}
				>
					{providerProps => (
						<Formik
							initialValues={{
								rows: providerProps.rows
							}}
							onSubmit={() => {}}
							enableReinitialize
						>
							<FormTable {...props} {...providerProps} />
						</Formik>
					)}
				</ResourceDataProvider>
			);
		}
	} else {
		if (tableType) {
			return (
				<CalcRulesDataProvider<object, object, object>
					tableType={tableType}
					namespace={dataSource?.namespace}
					name={getFieldName({
						name: tableType,
						namespace: dataSource?.namespace
					})}
					tableColumns={tableColumns}
				>
					{providerProps => <FormTable {...props} {...providerProps} />}
				</CalcRulesDataProvider>
			);
		}
	}

	return (
		<Typography>
			<FormattedMessage
				id="tableSection.noProvider"
				defaultMessage="Fant ikke datagrunnlag for tabellen"
			/>
		</Typography>
	);
};

FormTableSection.displayName = "DployFormTableSection";

export { FormTableSection };
