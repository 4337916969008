import { useMemo, useRef, useState } from "react";
import {
	StepLabel,
	StepConnector,
	Button,
	Typography,
	Paper,
	PaperProps
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import MobileStepper from "@material-ui/core/MobileStepper";
import Step from "@material-ui/core/Step";
import {
	getContrastRatio,
	alpha,
	makeStyles,
	Theme
} from "@material-ui/core/styles";

import StepButton from "@material-ui/core/StepButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import clsx from "clsx";
import { usePageState } from "../../../PageContext";
import { usePages } from "../../../pagesContext";
import { touchAllVisibleFields } from "../../../components/TouchVisibleFieldsOnPreviousPages";
import { useFormikContext } from "formik";
import { useResizeCallback } from "../../useResizeCallback";
import {
	useTemplateSectionIsVisible,
	useTemplateFieldIsVisible,
	useFormHasErrors
} from "../../../hooks";
import { getStepperHelpers } from "../DirWeb";

const useStyles = makeStyles(
	theme => ({
		stepper: {
			padding: "12px"
		},
		step: {
			flex: "1 1 auto"
		},
		stepActiveOrCompleted: {},
		stepConnector: {
			fontSize: "1.2rem"
		},
		stepButton: {
			margin: "-12px",
			padding: "12px",
			borderBottom: "4px solid " + getWizardStepperColor(theme).disabledColor,
			"&$stepButtonActiveOrCompleted": {
				borderBottomColor: getWizardStepperColor(theme).activeOrCompleteColor
			}
		},
		stepButtonActiveOrCompleted: {},
		stepIconContainer: {
			paddingRight: 0
		},
		stepIcon: {
			color: theme.palette.common.white,
			"&$stepIconActive": {
				color: theme.palette.common.white,
				fontWeight: 700
			}
		},
		stepIconActive: {},
		stepLabel: {
			fontSize: 12,
			textTransform: "uppercase" as "uppercase",
			"&$stepLabelActive": {
				fontWeight: 700
			}
		},
		stepLabelActive: {},
		stepIconText: {
			fill: theme.palette.common.black
		},
		header: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			height: 45,
			backgroundColor: theme.palette.background.paper
		},
		stepLabelMobile: {
			fontSize: 12,
			textTransform: "uppercase" as "uppercase",
			fontWeight: 700
		},
		rootMobile: {
			padding: 0
		},
		positionTopMobile: {
			position: "relative"
		},
		progressMobile: {
			width: "100%"
		},
		mobileStepper: {
			[theme.breakpoints.up("sm")]: {
				display: "none"
			}
		},
		mobileStepperOverflow: {
			[theme.breakpoints.up("sm")]: {
				display: "block"
			}
		},
		desktopStepper: {
			display: "none",
			[theme.breakpoints.up("sm")]: {
				display: "flex"
			}
		},
		desktopStepperOverflow: {
			visibility: "hidden",
			height: 0,
			paddingTop: 0,
			paddingBottom: 0
		}
	}),
	{ name: "DployWizardStepperStyle" }
);

const ChevronStepConnector = ({ className }) => (
	<ChevronRightIcon className={className} />
);

interface WizardStepperProps {
	className?: string;
	chevronConnector?: boolean;
	activeOrCompleteColor?: string;
	disabledColor?: string;
	elevation?: PaperProps["elevation"];
}

function getWizardStepperColor(theme: Theme): {
	activeOrCompleteColor: string;
	disabledColor: string;
} {
	const contrastThreshold = 2.5;
	const main = theme.palette.primary.main;
	const secondary = theme.palette.secondary.main;

	if (getContrastRatio(main, theme.palette.common.white) > contrastThreshold)
		return {
			activeOrCompleteColor: main,
			disabledColor: alpha(main, 0.1)
		};
	if (
		getContrastRatio(secondary, theme.palette.common.white) > contrastThreshold
	)
		return {
			activeOrCompleteColor: secondary,
			disabledColor: alpha(secondary, 0.1)
		};

	return {
		activeOrCompleteColor: "rgba(109,162,18,1)",
		disabledColor: "rgba(109,162,18,0.1)"
	};
}

export function WizardStepper(props: WizardStepperProps) {
	const { chevronConnector = true, className, elevation } = props;

	const formik = useFormikContext();

	//overflow is true if desktopStepper overflows wrapper
	const [overflow, setOverflow] = useState(false);
	const stepperForDesktopRef = useRef<null | HTMLElement>(null);
	const wrapperDivRef = useRef<null | HTMLDivElement>(null);
	useResizeCallback(
		(desktopStepper, wrapper) => {
			setOverflow(
				desktopStepper.scrollWidth > 0 &&
					wrapper.clientWidth < desktopStepper.scrollWidth
			);
		},
		stepperForDesktopRef,
		wrapperDivRef
	);

	const classes = useStyles({ ...props, overflow });

	const { step, goto, labels, getStepHref } = usePageState();
	const pages = usePages();
	const steps = pages.length;

	let stepLabel = 0;
	const { getDisplayNum } = getStepperHelpers(pages);

	const enableFilterLogic = useMemo(() => {
		return pages?.filter(p => p.displayInStepper)?.length > 0;
	}, [pages]);

	const formHasErrors = useFormHasErrors();
	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();
	const { touched, setTouched } = formik;

	const gotoValidPage = (requestedPageIdx: number) => {
		if (requestedPageIdx <= step) return goto(requestedPageIdx);

		const relevantTouched = touchAllVisibleFields(
			touched,
			pages.slice(0, requestedPageIdx),
			fieldIsVisible,
			sectionIsVisible
		);

		for (let pageIdx = requestedPageIdx; pageIdx > step; pageIdx--) {
			if (
				!formHasErrors(
					{ ...formik, touched: relevantTouched },
					pages.slice(0, pageIdx),
					false
				)
			) {
				setTouched(
					touchAllVisibleFields(
						touched,
						pages.slice(0, Math.min(pageIdx + 1, requestedPageIdx)),
						fieldIsVisible,
						sectionIsVisible
					)
				);

				return goto(pageIdx);
			}
		}

		setTouched(
			touchAllVisibleFields(
				touched,
				pages.slice(0, step + 1),
				fieldIsVisible,
				sectionIsVisible
			)
		);
	};

	const stepperForMobile = (
		<div
			className={clsx(classes.mobileStepper, {
				[classes.mobileStepperOverflow]: overflow
			})}
		>
			<Paper elevation={0} className={classes.header}>
				<Button
					component="a"
					href={getStepHref(step - 1)}
					size="small"
					onClick={e => {
						e.preventDefault();
						gotoValidPage(step - 1);
					}}
					disabled={step === 0}
				>
					<KeyboardArrowLeft />
				</Button>
				<Typography className={classes.stepLabelMobile}>
					{step + 1 + ". " + labels[step]}
				</Typography>
				<Button
					component="a"
					size="small"
					href={getStepHref(step + 1)}
					onClick={e => {
						e.preventDefault();
						gotoValidPage(step + 1);
					}}
					disabled={step === steps - 1}
				>
					<KeyboardArrowRight />
				</Button>
			</Paper>
			<MobileStepper
				classes={{
					root: classes.rootMobile,
					positionTop: classes.positionTopMobile,
					progress: classes.progressMobile
				}}
				variant="progress"
				position="top"
				steps={steps}
				activeStep={step}
				backButton={<></>}
				nextButton={<></>}
			></MobileStepper>
		</div>
	);

	const stepperForDesktop = (
		<Stepper
			className={clsx(classes.stepper, classes.desktopStepper, {
				[classes.desktopStepperOverflow]: overflow
			})}
			activeStep={enableFilterLogic ? getDisplayNum(step) : step}
			connector={
				chevronConnector ? (
					<ChevronStepConnector className={classes.stepConnector} />
				) : (
					<StepConnector className={classes.stepConnector} />
				)
			}
			ref={stepperForDesktopRef}
		>
			{pages.map((page, i) => {
				stepLabel =
					page.displayInStepper || !enableFilterLogic
						? stepLabel + 1
						: stepLabel;
				return page.displayInStepper || !enableFilterLogic ? (
					<Step key={page.pageTitle || i} className={classes.step}>
						<StepButton
							className={clsx(
								{
									[classes.stepButtonActiveOrCompleted]: i <= step
								},
								classes.stepButton
							)}
							component="a"
							href={getStepHref(i)}
							onClick={e => {
								e.preventDefault();
								gotoValidPage(i);
							}}
							disabled={
								i === step ||
								(i > step && formHasErrors(formik, pages.slice(0, i), false))
							}
						>
							<StepLabel
								classes={{
									label: classes.stepLabel,
									active: classes.stepLabelActive,
									iconContainer: classes.stepIconContainer
								}}
								StepIconProps={{
									completed: false,
									icon: `${stepLabel}.`,
									classes: {
										root: classes.stepIcon,
										active: classes.stepIconActive,
										text: classes.stepIconText
									}
								}}
							>
								{page.pageTitle}
							</StepLabel>
						</StepButton>
					</Step>
				) : null;
			})}
		</Stepper>
	);

	return (
		<Paper
			ref={wrapperDivRef}
			className={className}
			square
			elevation={elevation}
		>
			{stepperForMobile}
			{stepperForDesktop}
		</Paper>
	);
}

WizardStepper.displayName = "DployWizardStepper";
