import * as React from "react";
import { Fulfillment as PuiFulfillment } from "@ploy-ui/fulfillment";
import FulfillmentAPI from "@ploy-lib/fulfillment-api";
import { ExternalViewComponent } from "../types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(8),
		padding: theme.spacing(4)
	}
}));

export const Fulfillment: ExternalViewComponent = ({
	dtResult,
	token = "",
	endpoint,
	internalUser
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FulfillmentAPI token={token} endpoint={endpoint}>
				{api => <PuiFulfillment {...api} internalUser={internalUser} />}
			</FulfillmentAPI>
		</div>
	);
};

export default Fulfillment;
