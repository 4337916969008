import { useCallback } from "react";
import { FieldProps } from "formik";
import { identity } from "../utils";

export const useFormikValueOnChange = <V, P>(
	{ field, form }: FieldProps,
	parse: (value: V) => P = identity
): ((value: V) => void) => {
	const { setFieldValue } = form;
	const { name } = field;
	return useCallback(
		(value: V) => setFieldValue(name, parse(value)),
		[setFieldValue, name, parse]
	);
};
