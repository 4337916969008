import React, { createContext, useContext } from "react";

export interface FormContextState {
	formIsDisabled: boolean;
	formIsLiteral: boolean;
}

const defaultContext: FormContextState = {
	formIsDisabled: false,
	formIsLiteral: false
};

const Context = createContext<FormContextState>(defaultContext);
Context.displayName = "FormState";

export const FormProvider = Context.Provider;
export const FormConsumer = Context.Consumer;

export function useFormState() {
	return useContext(Context);
}

export function useFormStateManager(
	initialState: FormContextState
): FormContextState {
	return initialState;
}

export interface FormStateProviderProps {
	initialState: FormContextState;
	children: React.ReactNode;
}

export function FormStateProvider({
	initialState,
	children
}: FormStateProviderProps) {
	const state = useFormStateManager(initialState);

	return <FormProvider value={state}>{children}</FormProvider>;
}
