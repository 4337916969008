import React from "react";
import { PendingButton } from "@ploy-ui/core";
import {
	download,
	getFilenameFromResponse,
	apiResourceUrl
} from "@ploy-lib/core";

interface ExportWholesaleLimitButtonProps {
	label: string;
	details?: boolean;
	vendorId?: number;
}

const ExportWholesaleLimitButton = (props: ExportWholesaleLimitButtonProps) => {
	const { details = false, vendorId, label } = props;
	const url = details
		? `/wholesaleLimit/${vendorId}/detail/ExportToExcel`
		: `/wholesaleLimit/${vendorId}/ExportToExcel`;
	return vendorId ? (
		<PendingButton
			onClick={async () => {
				const response = await fetch(apiResourceUrl(url));

				if (!response.ok) {
					throw new Error("En feil har oppstått.");
				}

				const filename = getFilenameFromResponse(response) ?? "Excel.xlsx";
				const blob = await response.blob();

				download(new File([blob], filename));
			}}
			color="secondary"
			variant="contained"
		>
			{label}
		</PendingButton>
	) : null;
};

export { ExportWholesaleLimitButton };
