import { useCallback } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useFieldIsVisible } from "@ploy-lib/calculation";
import { CustomGuiFieldHandling, TemplateField } from "@ploy-lib/types";
import { useHiddenFields } from "./usehiddenFields";
import {
	FormValidationResource,
	IFormValidationExpression,
	ValidatorEnumTypes
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { useFormTemplate } from "../FormTemplateContext";
import { useValidationExpression } from "./useValidationExpression";

const excludedRoles = [
	"visibilityFilter",
	"visibilityFilterInverse",
	"visibilityFilterPartial"
];

const empty = {} as const;

export function useTemplateFieldIsVisible({
	ignoreRoles
}: {
	ignoreRoles?: boolean;
} = {}) {
	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
	const hiddenFields = useHiddenFields();

	const isCalcFieldVisible = useFieldIsVisible();

	const { formTemplateId } = useFormTemplate();

	const formValidations =
		useResource(FormValidationResource.formValidations(), formTemplateId) ??
		empty;

	const { fieldIsVisible: fieldIsInvisible } =
		useValidationExpression(formValidations);

	const fieldHasVisibilityExpressions = useCallback(
		(namespacedFieldName: string): boolean =>
			formValidations[namespacedFieldName]?.expressions?.some(
				(x: IFormValidationExpression) =>
					x.validatorType === ValidatorEnumTypes.Visibility
			),
		[formValidations]
	);

	return useCallback(
		<
			T extends Pick<
				TemplateField,
				| "name"
				| "namespace"
				| "hideForMobile"
				| "alwaysVisible"
				| "role"
				| "cgfHandling"
			>
		>(
			f: T
		) => {
			if (f.hideForMobile && isSmDown) return false;
			if (f.alwaysVisible) return true;
			if (hiddenFields.includes(f.name)) return false;

			const namespacedFieldName = `${f.namespace}.${f.name}`;

			if (fieldHasVisibilityExpressions(namespacedFieldName)) {
				return fieldIsInvisible(namespacedFieldName);
			}

			if (!ignoreRoles && f.role && excludedRoles.includes(f.role))
				return false;

			// Fields without CGF backing should be visible by defualt.
			const defaultVisible = f.cgfHandling === CustomGuiFieldHandling.Ignore;

			return isCalcFieldVisible(f, defaultVisible);
		},
		[
			hiddenFields,
			isSmDown,
			ignoreRoles,
			isCalcFieldVisible,
			fieldHasVisibilityExpressions,
			fieldIsInvisible
		]
	);
}
