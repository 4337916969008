import React from "react";
import {
	LiteralBase,
	LiteralProps,
	getLiteralBaseProps,
	baseEditableOptions
} from "./TextLiteral";
import { useFormattedParts } from "./NumberLiteral";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import { removeNegativeZero } from "../../utils";

export const CurrencyLiteral = ({
	//field,
	emptyEqualsZero,
	formatString,
	...rest
}: LiteralProps) => {
	const { suffix: suffixValue = undefined, prefix: prefixValue = undefined } =
		rest;
	const {
		prefix = prefixValue,
		str,
		suffix = suffixValue
	} = useFormattedParts(
		removeNegativeZero(rest.field.value),
		{
			format: "currency_input"
		},
		emptyEqualsZero,
		formatString
	);

	return (
		<LiteralBase {...getLiteralBaseProps(rest)} prefix={prefix} suffix={suffix}>
			{str}
		</LiteralBase>
	);
};

export const EditorCurrencyLiterals = identityRecordOfFieldEditorOptions({
	CurrencyLiteral: {
		editableOptions: {
			...baseEditableOptions,
			prefix: true,
			suffix: true
		}
	}
});
