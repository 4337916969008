import React, { Fragment, useMemo } from "react";
import { MarkdownElement } from "@ploy-ui/core";
import ContentListItem from "../../ContentListItem";
import { Collapse } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ID } from "@ploy-lib/types";

export enum ContentVisibility {
	Expanded = "Expanded",
	Opened = "Opened",
	Always = "Always"
}

export interface TextItem {
	id?: ID;
	primary?: string;
	secondary?: string;
	tertiary?: string;
	content?: string;
	contentVisibility?: ContentVisibility;
	hideDivider?: boolean;
	iconClass?: string;
	alwaysVisible?: boolean;
}

export interface TextListItemProps {
	primary?: React.ReactNode;
	secondary?: React.ReactNode;
	tertiary?: React.ReactNode;
	content?: string;
	contentVisibility?: ContentVisibility;
	hideDivider?: boolean;
	iconClass?: string;
	alwaysVisible?: boolean;
	onSelect?: () => void;
	selected?: boolean;
}

export function TextListItem(props: TextListItemProps) {
	const {
		primary,
		secondary,
		tertiary,
		content,
		contentVisibility = ContentVisibility.Always,
		hideDivider,
		onSelect,
		iconClass,
		selected
	} = props;

	const markdownTypographProps = useMemo<
		TypographyProps<
			typeof MarkdownElement,
			{ component?: typeof MarkdownElement }
		>
	>(() => {
		return {
			component: MarkdownElement,
			inline: true
		};
	}, []);

	try {
		return (
			<>
				<ContentListItem
					button={
						(!!content &&
							contentVisibility !== ContentVisibility.Always) as true
					}
					iconClass={iconClass}
					onClick={content ? onSelect : undefined}
					hideChevron={
						!content || contentVisibility === ContentVisibility.Always
					}
					chevronIcon={
						content && contentVisibility === ContentVisibility.Expanded ? (
							selected ? (
								<ExpandLessIcon />
							) : (
								<ExpandMoreIcon />
							)
						) : undefined
					}
					hideDivider={hideDivider}
					primary={primary}
					secondary={secondary}
					tertiary={tertiary}
					primaryTypographyProps={markdownTypographProps as any}
					secondaryTypographyProps={markdownTypographProps as any}
					tertiaryTypographyProps={markdownTypographProps as any}
				/>

				{content && contentVisibility === ContentVisibility.Expanded && (
					<Collapse in={selected} timeout="auto">
						<MarkdownElement text={content} />
					</Collapse>
				)}

				{content && contentVisibility === ContentVisibility.Always && (
					<MarkdownElement text={content} />
				)}
			</>
		);
	} catch {
		return null;
	}
}
