import { Grid, ListItemText } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { CheckBoxList, Item } from "@ploy-ui/core";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { useCallback } from "react";
import { TextLiteral } from "../literals";
import { useVariableData } from "@ploy-lib/calculation";
import { isArray } from "lodash";
import { InputFieldProps } from "../..";
import { useIntl } from "react-intl";

export interface Option {
	value: number;
	label: string;
}

type OnChangeFieldProps = Partial<Field> & {
	item: Item<Field>;
	onChange: (newItem: Item<Field>) => any;
};
export interface Field {
	fieldId: number;
	label: string;
	component: (props: OnChangeFieldProps) => JSX.Element;
	options?: Option[];
	value?: string;
}

interface Deductible {
	deductible: number;
	deductibleCode: string;
	pricePerMonth: number;
	pricePerYear: number;
}
interface AvailableAdittionalCoverage {
	coverageTypeCode: string;
	deductibles: Deductible[];
	description: string;
	insuranceAmount: number;
	legalInsuranceAmounts: number[];
	name: string | null;
}

interface AdditionalCoverage {
	insuranceAmount: number;
	coverageTypeCode: string;
	deductibleCode: string;
	coverageName?: string;
}

const emptyArray = [];

/**
 * Custom field used by DNB to dislay a list of AdditionalCoverages.
 * The additional coverages can be checked (selected) and input field can be edited
 *
 */
const AdditionalCoveragesField = memo(
	(props: InputFieldProps & { items: { key: string; value: string }[] }) => {
		const intl = useIntl();

		const { value: availableAdditionalCoverages } = useVariableData<
			AvailableAdittionalCoverage[]
		>("CAR_INSURANCE_EXTERNAL", "AvailableAdditionalCoverages");

		const { value: descriptions } = useVariableData<
			{ key: string; value: string }[]
		>(
			"CAR_INSURANCE_EXTERNAL",
			"options_EmbProd_AdditionalCoverages_Description"
		);

		const selectedCoverages: AdditionalCoverage[] = !isArray(props.field.value)
			? emptyArray
			: (props.field.value as AdditionalCoverage[]) || emptyArray;

		const [items, setItems] = useState<Item<Field>[]>([]);
		const [loading, setLoading] = useState(true);

		useEffect(() => {
			if (availableAdditionalCoverages) {
				setItems(
					mapAvailableAdditionalCoverages(
						availableAdditionalCoverages,
						selectedCoverages,
						intl,
						descriptions
					)
				);
			}
		}, [
			setItems,
			availableAdditionalCoverages,
			selectedCoverages,
			intl,
			descriptions
		]);

		useEffect(() => {
			setLoading(false);
		}, [setLoading, availableAdditionalCoverages]);

		const updateItems = useCallback(
			newItem => {
				setLoading(true);
				let itemsCopy = [...items];

				let newItems = itemsCopy.map(item =>
					item.id === newItem.id ? newItem : item
				);

				setItems(newItems);
				props.form.setFieldValue(
					props.field.name,
					newItems.reduce<AdditionalCoverage[]>(
						(acc, i) =>
							i.checked
								? [
										...acc,
										{
											insuranceAmount:
												i.fields?.length ?? 0
													? parseInt(i.fields?.[0]?.value ?? "") ?? 0
													: 0,
											coverageTypeCode: i.id,
											deductibleCode:
												availableAdditionalCoverages?.find(
													a => a.coverageTypeCode === i.id
												)?.deductibles?.[0]?.deductibleCode ?? "",
											coverageName: i.primary ?? ""
										}
								  ]
								: acc,
						[]
					)
				);
			},
			[
				items,
				setLoading,
				props.field.name,
				props.form,
				availableAdditionalCoverages
			]
		);
		return (
			<div style={{ width: "600px" }}>
				<Grid item xs={12}>
					<CheckBoxList<Field>
						items={items}
						loading={loading}
						onChange={newItem => updateItems(newItem)}
						summaryComponent={Summary}
						markdownPostProcess={addTargetBlank}
					/>
				</Grid>
			</div>
		);
	}
);

const mapAvailableAdditionalCoverages = (
	availableAdditionalCoverages: AvailableAdittionalCoverage[],
	selectedCoverages: AdditionalCoverage[],
	intl: ReturnType<typeof useIntl>,
	descriptions?: { key: string; value: string }[]
) => {
	return availableAdditionalCoverages.map((additionalCoverage, idx) => {
		const selected = selectedCoverages.find(
			s => s.coverageTypeCode === additionalCoverage.coverageTypeCode
		);

		const checked = selected != null;
		const pricePerMonth = additionalCoverage?.deductibles[0].pricePerMonth;

		return {
			id: additionalCoverage.coverageTypeCode,
			primary: additionalCoverage.name ?? "",
			description:
				(descriptions &&
					descriptions?.find(
						d => d.value === additionalCoverage.coverageTypeCode
					)?.key) ??
				"",
			secondary: checked ? pricePerMonth.toString() + " kr/mnd" : undefined,
			checked,
			value: pricePerMonth,
			fields: [
				additionalCoverage.legalInsuranceAmounts.length > 0
					? {
							fieldId: 1,
							label: "Forsikringssum",
							component: dropdownField,
							options: additionalCoverage.legalInsuranceAmounts.map(lia => ({
								label: lia.toString(),
								value: lia
							})),
							value:
								selected?.insuranceAmount.toString() ??
								additionalCoverage.insuranceAmount.toString()
					  }
					: null
			].filter(f => f !== null) as Field[]
		};
	});
};

const dropdownField = ({
	item,
	fieldId,
	value,
	onChange,
	options
}: OnChangeFieldProps) => (
	<DployAutocomplete
		variant="standard"
		searchable={false}
		margin="dense"
		items={options ?? []}
		getItemLabel={item => item?.label}
		value={options?.reduce<Option | undefined>(
			(acc, o) => (o.value.toString() === value ? o : acc),
			undefined
		)}
		onChange={(e, v) => {
			let newField = {
				...item,
				checked: true,
				fields: item.fields?.map(field =>
					field.fieldId === fieldId ? { ...field, value: v?.value } : field
				)
			} as Item<Field>;
			onChange(newField);
		}}
		disableClearable
	/>
);

const Summary = (items: Item<Field>[]) => {
	const intl = useIntl();
	const totalPrice = items.reduce<number>(
		(acc, item) =>
			item.checked && item.value && typeof item.value === "number"
				? acc + item.value
				: acc,
		0
	);
	return (
		<Grid container>
			<Grid
				item
				container
				xs={12}
				justifyContent="center"
				alignContent="center"
			>
				<ListItemText
					primary={"Sum"}
					secondary={intl.formatNumber(totalPrice) + " kr/mnd"}
				/>
			</Grid>
		</Grid>
	);
};

const AdditionalCoveragesLiteral = (props: InputFieldProps) => {
	const { literalVariant, suffix, ...rest } = props;
	const intl = useIntl();

	return props.field.value ? (
		<TextLiteral
			{...rest}
			field={{
				...props.field,
				value: !isArray(props.field.value) ? [] : props.field.value
			}}
			multiple
			items={props.field.value}
			getItemLabel={(item: AdditionalCoverage) => {
				const prettyNumber = intl.formatNumber(item.insuranceAmount);
				const selectedInsuranceAmount =
					(item.insuranceAmount ?? 0) > 0 ? ` - ${prettyNumber} kr` : "";
				return `${item.coverageName}${selectedInsuranceAmount}`;
			}}
		/>
	) : null;
};

const addTargetBlank = (html: string) => {
	const domParser = new DOMParser();
	const document = domParser.parseFromString(html, `text/html`);
	const serializer = new XMLSerializer();

	const links = document.querySelectorAll(`a`);

	links.forEach(link => {
		if (link.href) {
			link.target = `_blank`;
			link.rel = `noopener noreferrer`;
		}
	});

	return serializer.serializeToString(document);
};

AdditionalCoveragesField.displayName = "AdditionalCoveragesField";
AdditionalCoveragesLiteral.displayName = "AdditionalCoveragesLiteral";

export { AdditionalCoveragesField, AdditionalCoveragesLiteral };
