import {
	FunctionTypes,
	isVariableStateFunction,
	isCalculateFunction,
	isFieldErrorFunction,
	isFieldStateFunction
} from "./types";
import { CalculatorState } from "./createCalculator";
import { getIn, setIn } from "../service-manager/utils";

export interface FunctionRunner {
	<T extends Partial<CalculatorState<any, any>>>(
		input: T,
		changes?: string[]
	): [T, string[]];
}

const typeBaseMap = new Map<
	FunctionTypes["type"],
	keyof CalculatorState<any, any>
>([
	["value", "values"],
	["missing", "isMissing"],
	["writeLocked", "isWriteLocked"],
	["error", "errors"],
	["enabled", "isEnabled"],
	["visible", "isFieldVisible"]
]);

function executeFunction<T extends Partial<CalculatorState<any, any>>>(
	input: T,
	func: FunctionTypes,
	addChange: (path: string) => void
): T {
	const inputs = func.inputs.map(path => getIn(input, path));
	const result = func.call(...inputs);

	const path = `${typeBaseMap.get(func.type)}.${func.output}`;

	if (result !== undefined && result !== getIn(input, path)) {
		input = setIn(input, path, result);
		addChange(path);
	}

	return input;
}

type StateResults<F extends FunctionTypes> = Record<
	F["type"],
	Record<string, boolean>
>;
function executeStateFunction<
	T extends Partial<CalculatorState<any, any>>,
	F extends FunctionTypes
>(input: T, outputs: StateResults<F>, func: F): StateResults<F> {
	const inputs = func.inputs.map(path => getIn(input, path));
	const result = func.call(...inputs);

	if (result !== undefined) {
		const current = outputs[func.type][func.output] || true;
		outputs[func.type][func.output] = current && result;
	}

	return outputs;
}

export function createExecuteFunctions(functions: FunctionTypes[] = []) {
	const inputTriggers = new Map(
		functions.map(f => {
			const triggers = new Set(
				f.inputs
					.flatMap(input => {
						const triggers = input
							.split(".")
							.map((p, idx, arr) => arr.slice(0, idx + 1).join("."));

						return triggers;
					})
					.reverse()
			);

			return [f.inputs.join("|"), triggers];
		})
	);

	function shouldTrigger(changedSet: Set<string>, inputs: string[]): boolean {
		const triggers = inputTriggers.get(inputs.join("|"));
		if (triggers) {
			for (const trigger of triggers) {
				if (changedSet.has(trigger)) return true;
			}
		}
		return false;
	}

	const calculateFunctions = functions.filter(isCalculateFunction);
	const variableStateFunctions = functions.filter(isVariableStateFunction);
	const fieldErrorFunctions = functions.filter(isFieldErrorFunction);
	const fieldStateFunctions = functions.filter(isFieldStateFunction);

	const executeCalculateFunctions: FunctionRunner = (input, changes) => {
		const changedSet = new Set(changes || []);

		for (const func of calculateFunctions) {
			if (!changes || shouldTrigger(changedSet, func.inputs))
				input = executeFunction(input, func, path => {
					changedSet.add(path);
				});
		}

		return [input, [...changedSet]];
	};

	const executeVariableStateFunctions: FunctionRunner = (input, changes) => {
		const changedSet = new Set(changes || []);

		for (const func of variableStateFunctions) {
			if (!changes || shouldTrigger(changedSet, func.inputs))
				input = executeFunction(input, func, path => {
					changedSet.add(path);
				});
		}

		return [input, [...changedSet]];
	};

	const executeFieldErrorFunctions: FunctionRunner = (input, changes) => {
		const changedSet = new Set(changes || []);

		for (const func of fieldErrorFunctions) {
			if (!changes || shouldTrigger(changedSet, func.inputs))
				input = executeFunction(input, func, path => {
					changedSet.add(path);
				});
		}

		return [input, [...changedSet]];
	};

	function executeFieldStateFunctions(
		input: Partial<CalculatorState<any, any>>
	) {
		let output = {
			visible: {} as Record<string, boolean>,
			enabled: {} as Record<string, boolean>
		};

		for (const func of fieldStateFunctions) {
			output = executeStateFunction(input, output, func);
		}

		return output;
	}

	function processStateResults<T extends Partial<CalculatorState<any, any>>>(
		input: T,
		results: Partial<StateResults<FunctionTypes>>
	) {
		let type: FunctionTypes["type"];
		let path: string;

		for (type in results) {
			const outputType = typeBaseMap.get(type);
			if (!outputType) continue;

			let output: object = {};
			let hasChange = false;
			for (path in results[type]) {
				const fullPath = `${outputType}.${path}`;

				const result = results[type]![path];
				const current = getIn(input, fullPath);

				output = setIn(output, path, result);
				hasChange = hasChange || result !== current;
			}

			if (hasChange) {
				// only update the input if there was an actual change
				input = { ...input, [outputType]: output };
			}
		}

		return input;
	}

	return {
		executeCalculateFunctions,
		executeVariableStateFunctions,
		executeFieldErrorFunctions,
		executeFieldStateFunctions,
		processStateResults
	};
}
