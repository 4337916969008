import { makeStyles } from "@material-ui/core/styles";

import { DividerList } from "@ploy-ui/core";
import { navMigration } from "../routeMigration";
import { MenuItemNavLink } from "./MenuItemLink";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import { useIntl, defineMessages } from "react-intl";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import { EditAction } from "./EditAction";

function MenuItemList(props: { color?: string }) {
	const classes = useStyles();

	const intl = useIntl();

	const { menuItems = [] } = useResource(VulcanDataResource.detail(), {});

	const items = menuItems.map(item => ({
		...item,
		navigateUrl:
			navMigration[item.navigateUrl as keyof typeof navMigration] ??
			item.navigateUrl
	}));

	const dashboard = items.find(item => item.navigateUrl === "dashboard");

	const menuItemList = items
		.filter(item => item.navigateUrl !== "dashboard")
		.map(
			({
				menuItemName,
				icon,
				menuItemLabel,
				navigateUrl,
				navigateUrlexternal
			}) => (
				<MenuItemNavLink
					color={props.color}
					key={menuItemName}
					href={navigateUrlexternal}
					target={navigateUrlexternal ? "_blank" : undefined}
					to={navigateUrlexternal ? undefined : `/${navigateUrl}`}
					primary={menuItemLabel}
					iconClass={icon}
					secondaryAction={<EditAction />}
				/>
			)
		);

	return (
		<>
			<DividerList className={classes.root} dividerStart {...props}>
				<MenuItemNavLink
					color={props.color}
					to="/dashboard"
					primary={
						dashboard?.menuItemLabel ?? intl.formatMessage(messages.Dashboard)
					}
					iconClass={dashboard?.icon}
					icon={dashboard?.icon == null ? <DashboardIcon /> : undefined}
					secondaryAction={<EditAction />}
				/>

				{menuItemList}

				<MenuItemNavLink
					color={props.color}
					to="/profile"
					primary={intl.formatMessage(messages.Profile)}
					icon={<PersonIcon />}
					secondaryAction={<EditAction />}
				/>

				<MenuItemNavLink
					color={props.color}
					className={classes.logoutMenuItem}
					to="/logout"
					primary={intl.formatMessage(messages.Logout)}
					iconClass="sign-out"
				/>
			</DividerList>
		</>
	);
}
MenuItemList.displayName = "MenuItemList";

const useStyles = makeStyles(
	theme => ({
		root: {},
		logoutMenuItem: {}
	}),
	{ name: "MenuItemList" }
);

export const messages = defineMessages({
	Dashboard: {
		id: "dealer.menu.dashboard",
		description: "Label for menuItem dashboard",
		defaultMessage: "Dashboard"
	},
	Profile: {
		id: "dealer.menu.profile",
		description: "Label for menuItem profile",
		defaultMessage: "My profile"
	},
	Logout: {
		id: "dealer.menu.logout",
		description: "Label for menuItem logout",
		defaultMessage: "Log out"
	}
});

export default MenuItemList;
