import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface GiosgChatSettings {
	chatEnabled: boolean;
	companyId: string;
	token: string;
}

export class GiosgChatResource
	extends BaseCamelCasedResource
	implements GiosgChatSettings
{
	readonly chatEnabled: boolean;
	readonly companyId: string;
	readonly token: string;

	pk() {
		return "GiosgConfig";
	}

	static urlRoot = legacyApiResourceUrl("GiosgChat");
}
