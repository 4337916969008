import React, { useCallback } from "react";
import { FieldProps, getIn } from "formik";
import { FormHelperText, Omit, Box } from "@material-ui/core";
import { DployFormControl, DployFormControlProps } from "./DployTextField";
import isArray from "lodash/isArray";
import { Dropzone, DropzoneProps } from "./Dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { identityRecordOfFieldEditorOptions } from "./utils";

export type DropzoneFieldProps = Omit<
	DployFormControlProps,
	"onSelect" | "onDragEnter" | "onDragOver" | "onDragLeave" | "variant"
> &
	Omit<DropzoneProps, "files" | "onChange" | "onBlur" | "name"> & {
		label?: React.ReactNode;
		helperText?: React.ReactNode;
	};

export function useFieldToDropzone(props: FieldProps & DropzoneFieldProps) {
	const {
		field,
		form,
		label,
		disabled,
		error,
		helperText,
		accept,
		minSize,
		maxSize,
		preventDropOnDocument,
		noClick,
		noKeyboard,
		noDrag,
		noDragEventsBubbling,
		onDrop,
		onDropAccepted,
		onDropRejected,
		getFilesFromEvent,
		onFileDialogCancel,
		onSelect,
		selected,
		imgBackground,
		multiple,
		onDragEnter,
		onDragOver,
		onDragLeave,
		children,
		placeholder,
		variant,
		previewImages,
		...rest
	} = props;

	const dropZoneOptions = {
		accept,
		minSize,
		maxSize,
		preventDropOnDocument,
		noClick,
		noKeyboard,
		noDrag,
		noDragEventsBubbling,
		onDrop,
		onDropAccepted,
		onDropRejected,
		getFilesFromEvent,
		onFileDialogCancel,
		multiple,
		onDragEnter,
		onDragOver,
		onDragLeave
	};

	const { name, value: files } = field;
	const { touched, errors, isSubmitting } = form;

	let fieldError: React.ReactNode = getIn(errors, name);

	if (fieldError && isArray(fieldError)) {
		fieldError = (
			<ul>
				{fieldError.map((m, i) => (
					<li key={i}>{m}</li>
				))}
			</ul>
		);
	}

	const showError = getIn(touched, name) && !!fieldError;

	const onChange = useCallback(
		files => {
			form.setFieldValue(field.name, files, false);
		},
		[field.name, form]
	);

	const onError = useCallback(
		messages => {
			form.setFieldTouched(field.name, true, false);
			form.setFieldError(field.name, messages);
		},
		[form, field.name]
	);

	return {
		formControlProps: {
			error: showError,
			disabled: isSubmitting || props.disabled,
			...rest
		},
		helperText: showError ? fieldError : helperText,
		dropzoneProps: {
			...dropZoneOptions,
			onError,
			files,
			onChange,
			disabled: isSubmitting || props.disabled,
			onSelect,
			selected,
			imgBackground,
			placeholder,
			label,
			variant,
			previewImages,
			children
		}
	};
}

export function DropzoneField(props: FieldProps & DropzoneFieldProps) {
	const { formControlProps, helperText, dropzoneProps } =
		useFieldToDropzone(props);

	return (
		<DployFormControl {...formControlProps}>
			<Dropzone {...dropzoneProps} />

			{helperText && (
				<FormHelperText component="div">{helperText}</FormHelperText>
			)}
		</DployFormControl>
	);
}

export function PreviewDropzoneField(props: FieldProps & DropzoneFieldProps) {
	const { formControlProps, helperText, dropzoneProps } =
		useFieldToDropzone(props);

	return (
		<DployFormControl {...formControlProps}>
			<Dropzone
				{...dropzoneProps}
				previewImages
				label={
					<Box
						display="flex"
						flexDirection="column"
						p={2}
						alignItems="center"
						justifyContent="center"
					>
						<CloudUploadIcon />
						{dropzoneProps.label}
					</Box>
				}
			/>

			{helperText && (
				<FormHelperText component="div">{helperText}</FormHelperText>
			)}
		</DployFormControl>
	);
}

export function ButtonDropzoneField(props: FieldProps & DropzoneFieldProps) {
	const { formControlProps, helperText, dropzoneProps } =
		useFieldToDropzone(props);

	return (
		<DployFormControl {...formControlProps}>
			<Dropzone
				{...dropzoneProps}
				variant={dropzoneProps.variant ?? "button"}
			/>

			{helperText && (
				<FormHelperText component="div">{helperText}</FormHelperText>
			)}
		</DployFormControl>
	);
}

export const EditorDropzoneFields = identityRecordOfFieldEditorOptions({
	PreviewDropzoneField: {},
	ButtonDropzoneField: {},
	DropzoneField: {}
});
