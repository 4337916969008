import { useEffect } from "react";

import {
	LoginForm,
	VendorForm,
	UpdatePasswordForm,
	UpdateMobileForm
} from "./forms";
import { useNavigate, useLocation, Location } from "react-router-dom";
import { useResource } from "@rest-hooks/core";
import { LoginResource, LoginStatusEnum } from "@ploy-lib/rest-resources";
import { useBrowserDeprecationStatus } from "@ploy-lib/core";

function Login() {
	var location = useLocation();
	const { from } = (location.state as { from?: Location }) ?? {};

	const loginData = useResource(LoginResource.status(), {});

	const { supported } = useBrowserDeprecationStatus(
		loginData.browserDeprecation
	);

	const navigate = useNavigate();

	useEffect(() => {
		if (loginData.isAuthenticated && supported)
			return navigate(
				from
					? {
							hash: from.hash,
							pathname: from.pathname,
							search: from.search
					  }
					: "/",
				{ state: from?.state, replace: true }
			);
	}, [loginData.isAuthenticated, navigate, supported, from]);

	if (loginData.isAuthenticated && supported) return null;

	switch (loginData.status) {
		case LoginStatusEnum.ChooseVendor:
			return <VendorForm />;
		case LoginStatusEnum.ChangePassword:
			return <UpdatePasswordForm />;
		case LoginStatusEnum.SupplyMobile:
			return <UpdateMobileForm />;
		//case LoginStatusEnum.TwoFactorAuthValidation:
		default:
			return <LoginForm />;
	}
}

Login.displayName = "Login";

export default Login;
