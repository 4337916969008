import { EditorDatePickerLiterals } from "./DatePickerLiteral";
import { EditorNumberLiterals } from "./NumberLiteral";
import { EditorPercentLiterals } from "./PercentLiteral";
import { EditorTextLiterals } from "./TextLiteral";
import { EditorCurrencyLiterals } from "./CurrencyLiteral";

export { TextLiteral } from "./TextLiteral";
export { TextArea } from "./TextAreaLiteral";
export { DatePickerLiteral } from "./DatePickerLiteral";
export { NumberLiteral } from "./NumberLiteral";
export { CurrencyLiteral } from "./CurrencyLiteral";
export { PercentLiteral } from "./PercentLiteral";
export { OpenModalButton } from "../Buttons/OpenModalButton";
export { ButtonLink } from "../Buttons/Button";
export { SubmitButton } from "../Buttons/SubmitButton";
export { ButtonField as ButtonLiteral } from "../Buttons/Button";
export { CheckboxWithLabelField as CheckboxWithLabelLiteral } from "@ploy-ui/form-fields";

export const EditorLiterals = {
	...EditorDatePickerLiterals,
	...EditorNumberLiterals,
	...EditorPercentLiterals,
	...EditorTextLiterals,
	...EditorCurrencyLiterals
};
