import { apiResourceUrl } from "@ploy-lib/core";
import { Entity } from "@rest-hooks/rest";
import { BaseCamelCasedResource } from "./BaseResource";

export class LimitRedistributionLimitDetailEntity
	extends Entity
	implements LimitRedistributionLimitDetail
{
	readonly applicationId?: number = undefined;
	readonly applicationNumber?: string = undefined;
	readonly applicationStatus?: string = undefined;
	readonly objectGroup?: string = undefined;
	readonly objectCondition?: string = undefined;
	readonly limitApplicationNumber?: number = undefined;
	readonly limitGuid?: string = undefined;

	pk(parent?: any, key?: string) {
		return this.applicationId?.toString();
	}
}

export interface LimitRedistributionLimitDetail {
	readonly applicationId?: number;
	readonly applicationNumber?: string;
	readonly applicationStatus?: string;
	readonly objectGroup?: string;
	readonly objectCondition?: string;
	readonly limitApplicationNumber?: number;
	readonly limitGuid?: string;
}

export interface CustomerAgreementOption {
	readonly limitName?: string;
	readonly limitGuid?: string;
}

export class LimitRedistributionResource extends BaseCamelCasedResource {
	readonly customerId: number = 0;
	readonly limitGuid: string = "";
	readonly customerAgreements: CustomerAgreementOption[] = [];
	readonly applications?: LimitRedistributionLimitDetailEntity[] = [];

	static schema = {
		applications: [LimitRedistributionLimitDetailEntity]
	};

	pk() {
		return this.customerId.toString() || "";
	}

	static urlRoot = apiResourceUrl("limit-redistribution");
}
