import React, { useEffect } from "react";
import { useFormikValueOnChange } from "./hooks";
import {
	TextFieldProps as FormikTextFieldProps,
	fieldToTextField
} from "formik-material-ui";
import { Slider, SvgIcon, makeStyles, withStyles } from "@material-ui/core";
import { identityRecordOfFieldEditorOptions } from "./utils";
import { IntlNumberFieldProps } from "./helpers/IntlNumberField";
import { NumberFieldProps } from "./NumberFields";
import { RemoveCircleOutline, AddCircleOutline } from "@material-ui/icons";

export type SliderProps = {
	max: number;
	min: number;
	step?: number;
	color?: ColorType;
	jumpToValidInterval?: boolean;
};

const useStyles = makeStyles(() => ({
	iconButton: {
		width: "10%",
		height: "28px",
		"&:hover": {
			cursor: "pointer"
		}
	}
}));

const CustomSlider = withStyles(theme => ({
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: theme.palette.secondary.main,
		border: "1px solid " + theme.palette.secondary.main,
		marginTop: -11,
		marginLeft: -12,
		"&:focus, &:hover, &$active": {
			boxShadow: "inherit"
		}
	}
	//
}))(Slider);

const CustomRemoveCircle = ({ onClick, className }) => (
	<SvgIcon
		onClick={onClick}
		className={className}
		viewBox="0 0 210.414 210.414"
	>
		<path d="M105.207,0C47.196,0,0,47.196,0,105.207c0,58.011,47.196,105.207,105.207,105.207 c58.011,0,105.207-47.196,105.207-105.207C210.414,47.196,163.218,0,105.207,0z M105.207,202.621 c-53.715,0-97.414-43.699-97.414-97.414c0-53.715,43.699-97.414,97.414-97.414c53.715,0,97.414,43.699,97.414,97.414 C202.621,158.922,158.922,202.621,105.207,202.621z"></path>{" "}
		<path d="M155.862,101.31H54.552c-2.152,0-3.897,1.745-3.897,3.897c0,2.152,1.745,3.897,3.897,3.897h101.31 c2.152,0,3.897-1.745,3.897-3.897C159.759,103.055,158.014,101.31,155.862,101.31z"></path>{" "}
	</SvgIcon>
);

const CustomAddCircle = ({ onClick, className }) => (
	<SvgIcon onClick={onClick} className={className} viewBox="0 0 52 52">
		<path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26 S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"></path>{" "}
		<path d="M38.5,25H27V14c0-0.553-0.448-1-1-1s-1,0.447-1,1v11H13.5c-0.552,0-1,0.447-1,1s0.448,1,1,1H25v12c0,0.553,0.448,1,1,1 s1-0.447,1-1V27h11.5c0.552,0,1-0.447,1-1S39.052,25,38.5,25z"></path>{" "}
	</SvgIcon>
);

const colors = ["primary", "secondary", "default"] as const;

type ColorType = typeof colors[number];

export function SliderField(props: NumberFieldProps & SliderProps) {
	const {
		color,
		step = 1,
		min,
		max,
		field: fieldValue,
		jumpToValidInterval
	} = props;
	const onChange = useFormikValueOnChange(props);
	const styles = useStyles();

	const numberProps = fieldToTextField(
		props as FormikTextFieldProps
	) as IntlNumberFieldProps;

	useEffect(() => {
		if (jumpToValidInterval && fieldValue.value < min) {
			props.form.setFieldValue(fieldValue.name, min);
		} else if (jumpToValidInterval && fieldValue.value > max) {
			props.form.setFieldValue(fieldValue.name, max);
		}
	}, [min, max, fieldValue, props.form, jumpToValidInterval]);

	return (
		<>
			<CustomRemoveCircle
				className={styles.iconButton}
				onClick={() =>
					onChange(
						(typeof fieldValue.value === "number" ? fieldValue.value : 0) - step
					)
				}
			/>
			<CustomSlider
				color={color || "secondary"}
				step={step}
				min={min || 0}
				max={max || 100}
				value={numberProps.value || 0}
				onChange={(event, value) =>
					onChange(typeof value === "number" ? value : value[0] || 0)
				}
			/>
			<CustomAddCircle
				className={styles.iconButton}
				onClick={() =>
					onChange(
						(typeof fieldValue.value === "number" ? fieldValue.value : 0) + step
					)
				}
			/>
		</>
	);
}

export const EditorSliderFields = identityRecordOfFieldEditorOptions({
	SliderField: {
		editableOptions: {
			color: colors
		}
	}
});
