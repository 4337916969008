import React from "react";
import clsx from "clsx";

import { compose } from "@material-ui/system";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import CollapsableDrawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import CollapsableDrawerButton from "./CollapseDrawerButton";
import UserInfoToggleDrawer from "./UserInfoToggleDrawer";
import MenuItemList from "./MenuItemList";
import MenuBanner from "./MenuBanner";
import { useDrawer } from "../../../contexts";
import { QuickSearch } from "./QuickSearch";
import { ReleaseInfo } from "./ReleaseInfo";
import { LanguageSelector } from "../../LanguageSelector";
import { useSettings } from "@ploy-lib/core";
import { autocolor } from "@ploy-ui/core";
import { useTenant } from "@ploy-ui/tenants";
import { PaperProps } from "@material-ui/core";

const name = "Drawer";

const useStyles = makeStyles(
	theme => ({
		root: {
			whiteSpace: "nowrap",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			width: 240,
			overflowX: "hidden"
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "0 .5rem",
			...theme.mixins.toolbar
		},
		permanent: {
			position: "fixed"
		},
		closed: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(8)
			}
		}
	}),
	{ name }
);

export const ColoredDiv = styled("div")(compose(autocolor));

export interface DrawerProps {
	color?: string;
}

export function Drawer(props: DrawerProps) {
	const { color } = props;

	const theme = useTheme();
	const classes = useStyles(props);

	const { isOpen } = useDrawer();

	return (
		<CollapsableDrawer
			variant="permanent"
			anchor={theme.direction === "rtl" ? "right" : "left"}
			className={clsx(classes.root, !isOpen && classes.closed)}
			open={isOpen}
			PaperProps={
				{
					component: ColoredDiv,
					bgcolor: color,
					autoContrastText: true
				} as Partial<PaperProps>
			}
			classes={{
				paper: clsx(classes.root, !isOpen && classes.closed)
			}}
		>
			<MenuBanner />

			<UserInfoToggleDrawer color={color} />

			<MenuItemList color={color} />

			<QuickSearch color={color} />

			<ReleaseInfo />

			<Box flexGrow={1} />

			<Box p={1} display="flex" justifyContent="center" overflow="hidden">
				<LanguageSelectorToggleDrawer />
			</Box>

			<CollapsableDrawerButton color={color} />
		</CollapsableDrawer>
	);
}

function LanguageSelectorToggleDrawer() {
	const { open, isOpen } = useDrawer();
	const { locale } = useSettings();
	const { tenant } = useTenant();

	if ((tenant?.locales.length ?? 0) < 2) return null;

	if (!isOpen) {
		const [langCode] = locale.split("-");
		return (
			<Button onClick={open} color="inherit" size="small" variant="text">
				{langCode}
			</Button>
		);
	}

	return <LanguageSelector />;
}

Drawer.displayName = name;

export default Drawer;
