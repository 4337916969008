import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GiosgChatResource, GiosgChatSettings } from "@ploy-lib/rest-resources";
import { useFetcher } from "@rest-hooks/core";
import { useLocation } from "react-router-dom";

interface GiosgProps {
	children?: any;
}

// Declare giosg global property on window
declare global {
	interface Window {
		_giosg: any;
		giosg: any;
	}
}

enum GiosgLoadStates {
	Uninitialized,
	Loading,
	Loaded,
	SessionEnded,
	Error
}

const useStyles = makeStyles({});

// Code snippet from service.giosg.com
// prettier-ignore
const init = function(w, t, f) {
	var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;
	w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
};

const Giosg = (props: GiosgProps) => {
	const [config, setConfig] = useState({
		chatEnabled: false,
		companyId: "",
		token: ""
	});
	const loadState = useRef(GiosgLoadStates.Uninitialized);
	const loadTimeoutTime = useRef(0);
	const location = useLocation();
	const requestGiosgChatConfig = useFetcher(GiosgChatResource.detail());

	useStyles();

	useEffect(() => {
		requestGiosgChatConfig({})
			.then(conf => {
				if (configHasChanged(conf)) {
					setConfig({
						chatEnabled: conf.chatEnabled,
						companyId: conf.companyId ?? "",
						token: conf.token ?? ""
					});
				}
			})
			.catch(reason => {
				setConfig({
					chatEnabled: false,
					companyId: "",
					token: ""
				});
			});
	}, [location]);

	useEffect(() => {
		// Load chat
		if (config.chatEnabled && config.companyId?.length > 0) {
			switch (loadState.current) {
				case GiosgLoadStates.Uninitialized:
					setLoadState(GiosgLoadStates.Loading);
					init(window, document, config.companyId);
					setTimeout(setVisitorToken, 700);
					break;

				case GiosgLoadStates.Loaded:
				case GiosgLoadStates.SessionEnded:
					setLoadState(GiosgLoadStates.Loading);
					setVisitorToken();
					break;
			}
		}
		// Chat was previously loaded but is now disabled
		else if (
			!config.chatEnabled &&
			loadState.current !== GiosgLoadStates.Uninitialized
		) {
			endChatSession();
		}
		// Missing company ID
		else if (config.chatEnabled && !(config.companyId?.length > 0)) {
			console.error("Giosg chat company ID is missing!");
			setLoadState(GiosgLoadStates.Error);
		}
	}, [config]);

	function setVisitorToken(): void {
		if (loadState.current !== GiosgLoadStates.SessionEnded) {
			if (window.giosg.visitorCid !== undefined) {
				window.giosg.api.visitor
					.submit(
						config.token,
						"HS256",
						true,
						window.giosg.rooms.map((i: string) => ({ id: i }))
					)
					.then(
						() => setLoadState(GiosgLoadStates.Loaded),
						(reason: any) => {
							console.error(
								"Failed to set visitor token for Giosg chat.",
								reason
							);
							setLoadState(GiosgLoadStates.Error);
						}
					);
			} else if (Date.now() < loadTimeoutTime.current) {
				setTimeout(setVisitorToken, 250);
			} else {
				console.error("Giosg chat failed to load.");
				setLoadState(GiosgLoadStates.Error);
			}
		}
	}

	function endChatSession(): void {
		window.giosg.rooms.forEach((roomId: string) => {
			window.giosg.api.chat.close({ id: roomId, clearHistory: true });
			window.giosg.api.visitor.removeAll({ id: roomId });
		});
		setLoadState(GiosgLoadStates.SessionEnded);
	}

	function configHasChanged(updatedConfig: GiosgChatSettings): boolean {
		return (
			config.chatEnabled !== updatedConfig.chatEnabled ||
			config.companyId !== (updatedConfig.companyId ?? "") ||
			config.token !== (updatedConfig.token ?? "")
		);
	}

	function setLoadState(state: GiosgLoadStates) {
		switch (state) {
			case GiosgLoadStates.Loading:
				loadState.current = GiosgLoadStates.Loading;
				loadTimeoutTime.current = Date.now() + 20000;
				break;
			default:
				loadState.current = state;
				loadTimeoutTime.current = 0;
				break;
		}
	}

	return props.children || null;
};

Giosg.displayName = "Giosg";

export default Giosg;
