import React, { memo, Suspense, CSSProperties } from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useVariableData } from "@ploy-lib/calculation";
import { FormattedMessage } from "react-intl";
import { DployTable, DployColumn, ErrorPageContent } from "@ploy-ui/core";
import { BeneficialOwnerResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { ErrorHandler } from "@ploy-lib/core";
import { useTemplateFieldIsVisible } from "../../hooks/useTemplateFieldIsVisible";

addRegisteredSectionLayout({
	name: "ActualOwnerListSection",
	displayName: "ActualOwnerListSection",
	settings: {}
});

function columns(
	hideFNameLName: boolean,
	hideZip: boolean,
	hideLoggedInUserAndDate: boolean,
	cellStyle: CSSProperties
) {
	const columns: DployColumn<BeneficialOwnerResource>[] = [
		{
			field: "entNr",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.ssn.header"
					defaultMessage="SSN"
				/>
			),
			cellStyle
		},
		{
			field: "firstName",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.firstName.header"
					defaultMessage="First name"
				/>
			),
			cellStyle,
			hidden: hideFNameLName
		},
		{
			field: "lastName",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.lastName.header"
					defaultMessage="Last name"
				/>
			),
			cellStyle,
			hidden: hideFNameLName
		},
		{
			field: "name",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.name.header"
					defaultMessage="Name"
				/>
			),
			cellStyle
		},
		{
			field: "address",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.address.header"
					defaultMessage="Address"
				/>
			),
			cellStyle
		},
		{
			field: "addressCity",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.city.header"
					defaultMessage="City"
				/>
			),
			cellStyle
		},
		{
			field: "addressZip",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.zip.header"
					defaultMessage="Zip"
				/>
			),
			cellStyle,
			hidden: hideZip
		},
		{
			field: "share",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.share.header"
					defaultMessage="Share"
				/>
			),
			cellStyle
		},
		{
			field: "addUser",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.user.header"
					defaultMessage="User"
				/>
			),
			hidden: hideLoggedInUserAndDate,
			cellStyle
		},
		{
			field: "addDate",
			title: (
				<FormattedMessage
					id="dploy.actualOwnerListSection.date.header"
					defaultMessage="Date"
				/>
			),
			hidden: hideLoggedInUserAndDate,
			cellStyle
		}
	];
	return columns;
}

const ActualOwnerListSectionInternal = memo((props: SectionProps) => {
	const { className, onClick } = props;

	// const { applicationNumber } = useAppLoad();

	const nameSpace = "MONEY_LAUNDERING";

	const { value: epId } = useVariableData(nameSpace, "EpGuid");

	var isFieldVisible = useTemplateFieldIsVisible();

	var includeZip = isFieldVisible({
		name: "IncludeZip",
		namespace: nameSpace
	});

	var showLoggedInUserInformationAndDate = isFieldVisible({
		name: "ShowLoggedInUserInformationAndDate",
		namespace: nameSpace
	});

	var fnameAndLnameForWCService = isFieldVisible({
		name: "FnameAndLnameForWCService",
		namespace: nameSpace
	});

	const rows = useResource(
		BeneficialOwnerResource.list(),
		epId == null
			? null
			: {
					epId,
					includeFirstAndLastname: fnameAndLnameForWCService,
					includeZip
			  }
	);

	if (rows === undefined) return null;

	const cellStyle: CSSProperties = { fontSize: 13 };

	return (
		<div className={className} onClick={onClick}>
			<Suspense fallback={null}>
				<DployTable
					columns={columns(
						!fnameAndLnameForWCService,
						!includeZip,
						!showLoggedInUserInformationAndDate,
						cellStyle
					)}
					data={rows}
					listCellsBreakpoint={"xs"}
					options={{
						search: false,
						grouping: false,
						showEmptyDataSourceMessage: false,
						emptyRowsWhenPaging: false,
						paging: false,
						header: true,
						toolbar: false,
						sorting: false,
						draggable: false,
						headerStyle: cellStyle,
						idSynonym: "__id"
					}}
				/>
			</Suspense>
		</div>
	);
});

const ActualOwnerListSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={
					<span>{ActualOwnerListSection.displayName} failed to render.</span>
				}
			/>
		)}
	>
		<ActualOwnerListSectionInternal {...props} />
	</ErrorHandler>
);

ActualOwnerListSection.displayName = "DployActualOwnerListSection";

export { ActualOwnerListSection };
