import React from "react";
import {
	API,
	UpdateStatusRequest,
	GetBackofficeDataRequest,
	BackofficeCommissionObject,
	KeyValuePair,
	getQueryParams
} from "@ploy-lib/commission-api";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
	Box,
	Paper,
	Grid,
	CircularProgress,
	Checkbox,
	Typography,
	Button,
	FormControl
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { TableStructure } from "./TableStructure";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { ProcessLog } from "./ProcessLog";

export const Commission = ({
	getCommission,
	vendorSearch,
	underwriterSearch,
	sendToApproval,
	getAdditionalInformation,
	getApplicationInformation,
	updateStatus,
	internalUser
}: API) => {
	const [tab, setTab] = React.useState("marked-as-paid");
	const [searchInGroup, setSearchInGroup] = React.useState(true);
	const [loading, setLoading] = React.useState(false);
	const [commissions, setCommissions] = React.useState<
		BackofficeCommissionObject[]
	>([]);
	const [vendor, setVendor] = React.useState<KeyValuePair<string> | null>();
	const [underwriter, setUnderwriter] =
		React.useState<KeyValuePair<string> | null>();

	const getYearsAgo = (yearsAgo = 0) => {
		const today = new Date();
		if (yearsAgo === 0) {
			return today;
		}
		return new Date(today.getFullYear() - yearsAgo, 0, 1);
	};
	const [fromDate, setFromDate] = React.useState(getYearsAgo(5).toISOString());
	const [toDate, setToDate] = React.useState(getYearsAgo(0).toISOString());

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
		handleGetCommission(undefined, newValue);
	};

	const handleVendorChange = (
		e: React.ChangeEvent,
		newVendor: KeyValuePair<string>
	) => {
		setVendor(newVendor);
		handleGetCommission(newVendor);
	};

	const handleUnderwriterChange = (
		e: React.ChangeEvent,
		newUnderwriter: KeyValuePair<string>
	) => {
		setUnderwriter(newUnderwriter);
		handleGetCommission();
	};

	const handleChangeSearchInGroup = (newValue: boolean) => {
		setSearchInGroup(newValue);
		handleGetCommission();
	};

	const handleGetAdditionalInformation = (
		monthYear: Date,
		agreementNumber: string
	) => {
		return (
			!internalUser &&
			getAdditionalInformation(monthYear, agreementNumber, searchInGroup)
		);
	};

	const handleUpdateStatus = async (agreement: UpdateStatusRequest) => {
		var response = await updateStatus(agreement);
		return response;
	};

	const handleGetCommission = async (
		newVendor?: KeyValuePair<string>,
		type?: string
	) => {
		if (
			internalUser &&
			!vendor &&
			!newVendor &&
			((tab === "marked-as-paid" && type === undefined) ||
				type === "marked-as-paid")
		) {
			setCommissions([]);
			return;
		}
		setLoading(true);
		const [orgNo = "", backOfficeId = ""] = vendor
			? vendor.Value.split("|")
			: newVendor
			? newVendor.Value.split("|")
			: [];

		const searchParams: GetBackofficeDataRequest = {
			type: type ? type : tab,
			vendorNumber: orgNo,
			vendorId: backOfficeId,
			fromDate: "", //getYearsAgo(5).toISOString(),
			toDate: getYearsAgo(0).toISOString(),
			userId: "",
			searchInGroup,
			agreementNumber: ""
		};
		var response = await getCommission(searchParams);
		if (response && response.success) {
			setCommissions(response.commissions);
		}
		setLoading(false);
	};

	React.useEffect(() => {
		handleGetCommission(undefined, "marked-as-paid");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const downloadParamters: GetBackofficeDataRequest = {
		type: tab,
		vendorNumber: vendor ? vendor.Value.split("|")[0] : "",
		vendorId: vendor ? vendor.Value.split("|")[1] : "",
		fromDate: fromDate,
		toDate: toDate,
		userId: underwriter ? underwriter.Value : "",
		searchInGroup,
		agreementNumber: ""
	};
	return (
		<Grid container spacing={2}>
			{internalUser && (
				<Grid item xs={12}>
					<Paper>
						<Grid container xs={12}>
							<Grid item xs={6}>
								<Box p={2}>
									<DployAutocomplete
										value={vendor ?? null}
										onChange={handleVendorChange}
										getItemSuggestions={vendorSearch}
										minSearch={3}
										items={[]}
										getItemLabel={item => item.Key}
										label="Forhandler"
										variant="outlined"
										margin="normal"
										helperText=" "
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box p={2}>
									<DployAutocomplete
										value={underwriter ?? null}
										onChange={handleUnderwriterChange}
										getItemSuggestions={underwriterSearch}
										minSearch={3}
										items={[]}
										getItemLabel={item => item.Key}
										label="Saksbehandler"
										variant="outlined"
										margin="normal"
										helperText=" "
									/>
								</Box>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			)}
			{vendor && (
				<Grid item xs={12}>
					<Box p={1} display="inline-block">
						<DatePicker
							views={["year", "month"]}
							label="Fra"
							value={fromDate}
							onChange={val => setFromDate(val ? val.toString() : "")}
						/>
					</Box>
					<Box p={1} display="inline-block">
						<DatePicker
							views={["year", "month"]}
							label="Til"
							value={toDate}
							onChange={val => setToDate(val ? val.toString() : "")}
						/>
					</Box>
					<Box p={1} display="inline-block">
						<FormControl variant="standard">
							<Button
								color="primary"
								variant="outlined"
								endIcon={<CloudDownloadIcon />}
								href={`/api/backoffice-commission/download?${getQueryParams(
									downloadParamters
								)}`}
							>
								Last ned
							</Button>
						</FormControl>
					</Box>
				</Grid>
			)}
			<Grid item xs={12}>
				<Paper>
					<Tabs value={tab} onChange={handleChangeTab}>
						<Tab label="Utbetalt" value="marked-as-paid" />
						<Tab label="Opptjent" value="earned" />
						{internalUser && (
							<Tab label="Ikke behandlede avregninger" value="not-processed" />
						)}
						{internalUser && (
							<Tab label="Godkjente avregninger" value="approved" />
						)}
					</Tabs>
					<Box p={1}>
						<Controls
							searchInGroup={searchInGroup}
							setSearchInGroup={handleChangeSearchInGroup}
						/>
						<VisibleTab
							tab={tab}
							commissions={commissions}
							loading={loading}
							getAdditionalInformation={handleGetAdditionalInformation}
							sendToApproval={sendToApproval}
							getApplicationInformation={getApplicationInformation}
							updateStatus={handleUpdateStatus}
						/>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};

export const VisibleTab = ({
	tab,
	commissions,
	loading,
	getAdditionalInformation,
	sendToApproval,
	getApplicationInformation,
	updateStatus
}) => {
	if (loading) return <CircularProgress size={20} />;
	switch (tab) {
		case "marked-as-paid":
			return (
				<>
					<h1>Utbetalt</h1>
					{commissions ? (
						<TableStructure
							list={commissions}
							groupByLamda={c => c.date.getFullYear()}
							monthly={false}
							year={"0"}
						/>
					) : null}
				</>
			);
		case "earned":
			return (
				<>
					<h1>Opptjent</h1>
					{commissions ? (
						<TableStructure
							list={commissions}
							groupByLamda={c => c.startDate.getFullYear()}
							monthly={false}
							year={"0"}
							getAdditionalInformation={getAdditionalInformation}
							sendToApproval={sendToApproval}
							getApplicationInformation={getApplicationInformation}
						/>
					) : null}
				</>
			);
		case "not-processed":
			return (
				<>
					<h1>Ikke behandlede avregninger</h1>
					{commissions ? (
						<ProcessLog log={commissions} updateStatus={updateStatus} />
					) : null}
				</>
			);
		case "approved":
			return (
				<>
					<h1>Godkjente avregninger</h1>
					{commissions ? (
						<ProcessLog
							log={commissions}
							updateStatus={updateStatus}
							approved
						/>
					) : null}
				</>
			);
		default:
			return <h1>Velg en fane</h1>;
	}
};

export const Controls = ({ searchInGroup, setSearchInGroup }) => {
	return (
		<>
			<Typography component="span">Konsernsøk</Typography>
			<Checkbox
				checked={searchInGroup}
				onChange={() => setSearchInGroup(!searchInGroup)}
			/>
		</>
	);
};
