import React, { useCallback, useEffect, useState } from "react";
import { useAppLoad } from "../../appLoadContext";
import { SectionProps } from "../Section";
import { useResource, useFetcher } from "@rest-hooks/core";
import { ProofOfDeliveryInformationResource } from "@ploy-lib/rest-resources";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { ProofOfDeliverySmsActions } from "@ploy-ui/proof-of-delivery-views";
import { ErrorHandler } from "@ploy-lib/core";
import { ErrorPageContent } from "@ploy-ui/core";
import { useVariableData } from "@ploy-lib/calculation";
import { useFormikContext } from "formik";

addRegisteredSectionLayout({
	name: "SendDeliveryOfProofSection",
	displayName: "SendDeliveryOfProofSection",
	settings: { editableOptions: { showStatus: true } }
});

const SendDeliveryOfProofSectionInternal = (props: SectionProps) => {
	const {
		className,
		onClick,
		setSectionCompleted,
		formTemplateSectionId,
		fields
	} = props;

	const { applicationId, id: appSessionId } = useAppLoad();

	const { errors: formikErrors, touched: formikTouched } = useFormikContext();

	const [sectionError, setSectionError] = useState(false);

	useEffect(() => {
		if (
			fields.some(field => {
				const error = formikErrors?.[field.namespace ?? ""]?.[field.name];
				return error;
			})
		) {
			setSectionError(true);
		} else {
			setSectionError(false);
		}
	}, [formikErrors, formikTouched, fields, setSectionError]);

	const { value: registrationNumber } = useVariableData<string>(
		"Object",
		"RegNumber"
	);

	const proofOfDeliveryDetails = useResource(
		ProofOfDeliveryInformationResource.detail(),
		appSessionId
			? {
					applicationId
			  }
			: null
	);

	const fetchProofOfDelivery = useFetcher(
		ProofOfDeliveryInformationResource.detail()
	);

	const reload = useCallback(
		() => fetchProofOfDelivery({ applicationId: applicationId }),
		[fetchProofOfDelivery, applicationId]
	);

	const sectionCompleted = proofOfDeliveryDetails?.deliverySent || false;

	useEffect(() => {
		setSectionCompleted?.(formTemplateSectionId, sectionCompleted);
	}, [sectionCompleted, formTemplateSectionId, setSectionCompleted]);

	return (
		<ProofOfDeliverySmsActions
			loadedData={proofOfDeliveryDetails}
			className={className}
			onClick={onClick}
			reloadData={reload}
			regNumber={registrationNumber}
			errors={sectionError}
		/>
	);
};

const SendDeliveryOfProofSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={
					<span>
						{SendDeliveryOfProofSection.displayName} failed to render.
					</span>
				}
			/>
		)}
	>
		<SendDeliveryOfProofSectionInternal {...props} />
	</ErrorHandler>
);

SendDeliveryOfProofSection.displayName = "DploySendDeliveryOfProofSection";

export { SendDeliveryOfProofSection };
