import { useEffect, useState } from "react";
import { WholesaleOverview, WholesaleCar } from "@ploy-lib/rest-resources";
import {
	WholesaleOverviewSummary,
	WholesaleOverviewSummaryProps
} from "./WholesaleOverviewSummary";
import { WholesaleOverviewApplications } from "./WholesaleOverviewApplications";
import { WholesaleOverviewHeader } from "./WholesaleOverviewHeader";
import { WholesaleOverviewFulfillSection } from "./WholesaleOverviewFulfillSection";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { OverlayCircularProgress } from "@ploy-ui/core";
import { WholesaleOverviewSearchBar } from "./WholesaleOverviewSearchBar";
import { SharedCheckedSet } from "./WholesaleOverviewCheckbox";
import { Box, Grid } from "@material-ui/core";
import { WholesaleOverviewNewApp } from "./WholesaleOverviewNewApp";

const WholesaleOverviewPage = () => {
	const [dataFetchSent, setDataFetchSent] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [wholesaleData, setWholesaleData] = useState({} as WholesaleOverview);
	const [creatingApplication, setCreatingApplication] = useState(false);

	const [filteredCars, setFilteredCars] = useState({} as WholesaleCar[]);

	const { checkedSet } = SharedCheckedSet();

	const getData = () => {
		fetch(legacyApiResourceUrl(`WholesaleOverview/Vulcan`), {
			method: "POST",
			headers: {
				accept: "application/json"
			}
		}).then(r =>
			r.json().then(data => {
				setWholesaleData(data.InitialData);
				setFilteredCars(data.InitialData.Cars);
				setLoaded(true);
			})
		);
	};

	useEffect(() => {
		//Load fulfillments
		if (!dataFetchSent) {
			setDataFetchSent(true);
			getData();
		}
	}, [dataFetchSent]);

	const redeemApplications = (cars: WholesaleCar[]) => {
		fetch(legacyApiResourceUrl(`WholesaleOverview/RedeemApplications`), {
			method: "POST",
			body: new Blob([JSON.stringify({ cars: cars })], {
				type: "application/json"
			}),
			headers: {
				accept: "application/json"
			}
		}).then(r => {
			getData();
		});
	};

	const searchFilter = (searchVal: string) => {
		setFilteredCars(
			wholesaleData.Cars.filter(item => {
				return (
					searchVal === "" ||
					item.ApplicationNumber.indexOf(searchVal) !== -1 ||
					item.ChassiNumber.toLowerCase().indexOf(searchVal.toLowerCase()) !==
						-1 ||
					item.RegistrationNumber.toLowerCase().indexOf(
						searchVal.toLowerCase()
					) !== -1 ||
					item.ObjectDescription.toLowerCase().indexOf(
						searchVal.toLowerCase()
					) !== -1 ||
					checkedSet.has(item.ApplicationNumber)
				);
			})
		);
	};

	return (
		<>
			{loaded ? (
				<Box p={4}>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="stretch"
						spacing={2}
					>
						<Grid item>
							<WholesaleOverviewHeader
								onNew={() => {
									setCreatingApplication(true);
								}}
							/>
						</Grid>
						<Grid item>
							<WholesaleOverviewSummary {...calculateSummary(wholesaleData)} />
						</Grid>
						<Grid item>
							<WholesaleOverviewSearchBar
								searchFilter={searchFilter}
							></WholesaleOverviewSearchBar>
						</Grid>
						<Grid item>
							<WholesaleOverviewApplications
								cars={filteredCars}
								redeemApplications={redeemApplications}
							/>
						</Grid>
						<Grid item>
							<WholesaleOverviewFulfillSection
								cars={wholesaleData.Cars}
								redeemApplications={redeemApplications}
							/>
						</Grid>
					</Grid>
					<WholesaleOverviewNewApp
						open={creatingApplication}
						setOpen={setCreatingApplication}
					/>
				</Box>
			) : (
				<OverlayCircularProgress pending={!loaded} />
			)}
		</>
	);
};

const calculateSummary = (data: WholesaleOverview) => {
	const { Cars, CurrentStatus, PayoutStatus, RedeemStatus, AgreementLimit } =
		data;

	if (!Cars)
		return {
			totalCurrent: 0,
			totalPayout: 0,
			totalRedeem: 0,
			remainingLimit: 0
		};

	const totalCurrent = Cars.filter(
		car => CurrentStatus.indexOf(car.Status) !== -1
	)
		.map(car => car.LoanAmount)
		.reduce((a, b) => a + b, 0);
	const totalPayout = Cars.filter(
		car => PayoutStatus.indexOf(car.Status) !== -1
	)
		.map(car => car.LoanAmount)
		.reduce((a, b) => a + b, 0);
	const totalRedeem = Cars.filter(
		car => RedeemStatus.indexOf(car.Status) !== -1
	)
		.map(car => car.LoanAmount)
		.reduce((a, b) => a + b, 0);
	const remainingLimit = AgreementLimit - totalCurrent;

	return {
		totalCurrent,
		totalPayout,
		totalRedeem,
		remainingLimit,
		agreementLimit: AgreementLimit
	} as WholesaleOverviewSummaryProps;
};

export { WholesaleOverviewPage };
