import { ID } from "./util";

export enum ApplicationStatusId {
	NotSet = 0,
	Saved = 1,
	Submitted = 2,
	Scoring = 3,
	ManualUW = 4,
	AutoApproved = 5,
	ManuallyApproved = 6,
	ManuallyRejected = 7,
	AutoRejected = 8,
	Cancelled = 9,
	Paid = 10,
	Transferred = 11,
	Replaced = 12,
	Expired = 13,
	ApprovedWithConditions = 14,
	Prescore = 17,
	Processing = 18,
	Invoiced = 19,
	Signed = 20,
	InvestPaid = 21,
	PartlyPaid = 22,
	Offer = 23,
	RejectedMissingDocumentation = 24,
	Fulfilled = 25,
	RedeemProofOfFinancing = 26
}

export enum ApplicationStatus {
	NotSet = "NotSet",
	Saved = "Saved",
	Submitted = "Submitted",
	Scoring = "Scoring",
	ManualUW = "ManualUW",
	AutoApproved = "AutoApproved",
	ManuallyApproved = "ManuallyApproved",
	ManuallyRejected = "ManuallyRejected",
	AutoRejected = "AutoRejected",
	Cancelled = "Cancelled",
	Paid = "Paid",
	Transferred = "Transferred",
	Replaced = "Replaced",
	Expired = "Expired",
	ApprovedWithConditions = "ApprovedWithConditions",
	Prescore = "Prescore",
	Processing = "Processing",
	Invoiced = "Invoiced",
	Signed = "Signed",
	InvestPaid = "InvestPaid",
	PartlyPaid = "PartlyPaid",
	Offer = "Offer",
	RejectedMissingDocumentation = "RejectedMissingDocumentation",
	Fulfilled = "Fulfilled",
	RedeemProofOfFinancing = "RedeemProofOfFinancing"
}

export enum DocumentStatusId {
	NotSet = 0,
	MissingDocuments = 1,
	OriginalContractNotReceived = 3,
	ToAuthorization = 4,
	ConsiderationCaseworker = 9,
	ConsiderationAuthority = 10,
	SecurityRegistration = 12,
	DocumentsProduced = 15,
	DocumentsOK = 16,
	DocumentsReceived = 21,
	ToSecurityRegistration = 25,
	SecurityRegistrationPending = 26,
	SecurityRegistered = 27,
	Prescore = 31,
	NewApplication = 34,
	Scoring = 35,
	Error = 36,
	DocumentProduction = 37,
	InfoArrived = 38,
	NeedMoreInfo = 39,
	NewDocumentsReceived = 40,
	ToPayout = 42,
	FromAuthorization = 44,
	DocumentControl = 45,
	SecurityDocsReceived = 46,
	SecurityDocsPending = 47,
	Transferred = 48,
	Paid = 49,
	CheckDeletionOfSecurityOtherBank = 50,
	OriginalContractReceived = 51,
	AwaitingSpecification = 52,
	DocumentsNotReturned = 54,
	Rejected = 55,
	Offer = 58,
	ApprovalExpired = 59,
	DocumentsPartial = 60,
	ObjectOrderSent = 62,
	Activated = 63,
	ToESign = 64,
	ESigned = 65,
	ESignFailed = 66,
	CustomerAccepted = 67,
	PreESigned = 68,
	NewOfferCreated = 69,
	AwaitingOwnerRegistration = 70,
	OwnerRegistrationError = 71,
	PartiallySigned = 72,
	FullySigned = 73,
	PaymentControl = 56,
	ReturnFromPaymentControl = 74,
	ReturnFromDocumentControl = 75,
	ReturnFromUnderwriting = 76,
	FullySignedIncomplete = 77,
	SecurityRegistrationFailed = 78,
	SblRequestSent = 79,
	WaitingForInvoice = 80,
	InvoiceReceived = 81,
	ManualDocumentControl = 82,
	CarOrderConfirmationReceived = 83,
	CarOrderReceived = 84,
	ProofOfDeliverySent = 85,
	ProofOfDeliveryAccepted = 86,
	PreTransferSecurityRegisrationFailed = 87,
	CustomerRejectedInformationAccess = 88
}

export enum DocumentStatus {
	NotSet = "NotSet",
	MissingDocuments = "MissingDocuments",
	OriginalContractNotReceived = "OriginalContractNotReceived",
	ToAuthorization = "ToAuthorization",
	ConsiderationCaseworker = "ConsiderationCaseworker",
	ConsiderationAuthority = "ConsiderationAuthority",
	SecurityRegistration = "SecurityRegistration",
	DocumentsProduced = "DocumentsProduced",
	DocumentsOK = "DocumentsOK",
	DocumentsReceived = "DocumentsReceived",
	ToSecurityRegistration = "ToSecurityRegistration",
	SecurityRegistrationPending = "SecurityRegistrationPending",
	SecurityRegistered = "SecurityRegistered",
	Prescore = "Prescore",
	NewApplication = "NewApplication",
	Scoring = "Scoring",
	Error = "Error",
	DocumentProduction = "DocumentProduction",
	InfoArrived = "InfoArrived",
	NeedMoreInfo = "NeedMoreInfo",
	NewDocumentsReceived = "NewDocumentsReceived",
	ToPayout = "ToPayout",
	FromAuthorization = "FromAuthorization",
	DocumentControl = "DocumentControl",
	SecurityDocsReceived = "SecurityDocsReceived",
	SecurityDocsPending = "SecurityDocsPending",
	Transferred = "Transferred",
	Paid = "Paid",
	CheckDeletionOfSecurityOtherBank = "CheckDeletionOfSecurityOtherBank",
	OriginalContractReceived = "OriginalContractReceived",
	AwaitingSpecification = "AwaitingSpecification",
	DocumentsNotReturned = "DocumentsNotReturned",
	Rejected = "Rejected",
	Offer = "Offer",
	ApprovalExpired = "ApprovalExpired",
	DocumentsPartial = "DocumentsPartial",
	ObjectOrderSent = "ObjectOrderSent",
	Activated = "Activated",
	ToESign = "ToESign",
	ESigned = "ESigned",
	ESignFailed = "ESignFailed",
	CustomerAccepted = "CustomerAccepted",
	PreESigned = "PreESigned",
	NewOfferCreated = "NewOfferCreated",
	AwaitingOwnerRegistration = "AwaitingOwnerRegistration",
	OwnerRegistrationError = "OwnerRegistrationError",
	PartiallySigned = "PartiallySigned",
	FullySigned = "FullySigned",
	PaymentControl = "PaymentControl",
	ReturnFromPaymentControl = "ReturnFromPaymentControl",
	ReturnFromDocumentControl = "ReturnFromDocumentControl",
	ReturnFromUnderwriting = "ReturnFromUnderwriting",
	FullySignedIncomplete = "FullySignedIncomplete",
	SecurityRegistrationFailed = "SecurityRegistrationFailed",
	SblRequestSent = "SblRequestSent",
	SblRequestReceived = "SblRequestReceived",
	WaitingForInvoice = "WaitingForInvoice",
	InvoiceReceived = "InvoiceReceived",
	ManualDocumentControl = "ManualDocumentControl",
	CarOrderConfirmationReceived = "CarOrderConfirmationReceived",
	CarOrderReceived = "CarOrderReceived",
	ProofOfDeliverySent = "ProofOfDeliverySent",
	ProofOfDeliveryAccepted = "ProofOfDeliveryAccepted",
	PreTransferSecurityRegisrationFailed = "PreTransferSecurityRegisrationFailed",
	CustomerRejectedInformationAccess = "CustomerRejectedInformationAccess"
}

export interface ApplicationStatuses {
	readonly id?: ID;
	readonly documentStatusDesc?: string;
	readonly applicationStatus: ApplicationStatus;
	readonly documentStatus: DocumentStatus;
	readonly canSendToEsign: boolean;
	readonly canEditRegNumber: boolean;
	readonly fullPostProcessAfterPayout: boolean;
	readonly canEditChassisNumber: boolean;
	readonly canEditInvoiceNoRef: boolean;
	readonly canEditDeliveryDate: boolean;
}
