import {
	IntlConfig,
	Formatters,
	useIntl,
	FormatNumberOptions,
	ReactIntlError,
	ReactIntlErrorCode
} from "react-intl";
import { getFormatter } from "@formatjs/intl/src/number";
import { useReducer, useMemo, useCallback, useDebugValue } from "react";
import { DployFieldName } from "@ploy-lib/types";
import { useSelectedField } from "..";

interface ChangeAction {
	type: "CHANGE";
	payload: string;
}
interface BlurAction {
	type: "BLUR";
}
interface FocusAction {
	type: "FOCUS";
}

const prepareFraction = (fraction: string, maximumFractionDigits: number) => {
	const removeTrailingZero = false;
	const substrFrac = fraction.substr(
		0,
		maximumFractionDigits -
			(removeTrailingZero && fraction[maximumFractionDigits - 1] === "0"
				? 1
				: 0)
	);

	return substrFrac;
};

export const parseAndFormat = (
	str: string,
	isInitial: boolean,
	refuse: RegExp,
	decimalSeparator: string,
	emptyEqualsZero: boolean = false,
	resolvedOptions: FormatNumberOptions,
	formatNumber: (num: number) => string,
	min?: number,
	max?: number
) => {
	if (min && min > parseInt(str)) str = min.toString();
	if (max && max < parseInt(str)) str = max.toString();

	const { maximumFractionDigits = 0, minimumFractionDigits = 0 } =
		resolvedOptions;

	const clean = str.replace(refuse, "");

	const [base, fract = ""] = clean.split(decimalSeparator);
	const fraction = prepareFraction(fract, maximumFractionDigits);
	const preventRounding = !fraction
		? clean.length > minimumFractionDigits &&
		  minimumFractionDigits > 0 &&
		  isInitial !== true
			? `${clean.substr(
					0,
					clean.length - minimumFractionDigits
			  )}.${clean.substr(-minimumFractionDigits)}`
			: clean
		: `${base}.${fraction}`;

	const r = parseFloat(preventRounding);

	const formatted = formatNumber(r);
	const [, formattedFraction = ""] = formatted.split(decimalSeparator);

	const defaultValue = emptyEqualsZero ? 0 : NaN;

	const numValue = Number.isNaN(r) ? defaultValue : r;
	const textValue = Number.isNaN(r)
		? ""
		: formatted +
		  (isInitial === true
				? ""
				: (formattedFraction.length === 0 &&
				  clean.indexOf(decimalSeparator) > -1
						? decimalSeparator
						: "") +
				  (formattedFraction.length < maximumFractionDigits &&
				  fraction.length > formattedFraction.length
						? fraction.substring(
								formattedFraction.length,
								maximumFractionDigits
						  )
						: ""));

	return { numValue, textValue };
};

let _defaultOptions: FormatNumberOptions;
const getDefaultOptions = () =>
	_defaultOptions ||
	(_defaultOptions =
		new Intl.NumberFormat().resolvedOptions() as FormatNumberOptions);

export function resolveNumberOptions(
	config: Pick<IntlConfig, "locale" | "formats" | "onError">,
	getNumberFormat: Formatters["getNumberFormat"],
	options: FormatNumberOptions
): FormatNumberOptions {
	try {
		return getFormatter(
			config as any,
			getNumberFormat,
			options
		).resolvedOptions() as FormatNumberOptions;
	} catch (e: any) {
		config.onError(
			new ReactIntlError(
				ReactIntlErrorCode.FORMAT_ERROR,
				"Error resolving number options.",
				e
			)
		);
	}

	return getDefaultOptions();
}

const useNumberFormatOpts = (
	options: FormatNumberOptions = {},
	percentAsFraction?: boolean
) => {
	const intl = useIntl();

	return useMemo(
		() => {
			const resolvedOptions = resolveNumberOptions(
				intl,
				intl.formatters.getNumberFormat,
				options
			);

			const parts = intl.formatNumberToParts(1, options);

			const partsWithDecimal = intl.formatNumberToParts(1.1, {
				...options,
				minimumFractionDigits: 1,
				maximumFractionDigits: 1
			});

			const numberToString = (num: number) =>
				intl
					.formatNumber(
						!percentAsFraction && resolvedOptions.style === "percent"
							? num / 100
							: num,
						options
					)
					// Replace european minus sign with standard english one... Causes bugs if not
					.replace("−", "-")
					.replace(formatPrefix || "", "")
					.replace(formatSuffix || "", "")
					.trim();

			const decimalAllowed = (resolvedOptions.maximumFractionDigits ?? 0) > 0;

			if (isNullishOrEmpty(parts) || isNullishOrEmpty(partsWithDecimal))
				return {
					decimalSeparator: decimalAllowed ? "," : "",
					numberToString,
					resolvedOptions
				};

			const prefixSuffixTypes = ["currency", "percentSign", "literal"];

			const decimalPart = partsWithDecimal.find(x => x.type === "decimal");

			const formatPrefix = prefixSuffixTypes.includes(parts[0].type)
				? parts[0].value
				: undefined;

			const formatSuffix = prefixSuffixTypes.includes(
				parts[parts.length - 1].type
			)
				? parts[parts.length - 1].value
				: undefined;

			return {
				resolvedOptions,
				numberToString,
				formatPrefix,
				formatSuffix,
				decimalSeparator: decimalAllowed ? decimalPart!.value : ""
			};
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[
			intl,
			options.currency,
			options.currencyDisplay,
			options.localeMatcher,
			options.maximumFractionDigits,
			options.maximumSignificantDigits,
			options.minimumFractionDigits,
			options.minimumIntegerDigits,
			options.minimumSignificantDigits,
			options.style,
			options.useGrouping,
			options.format
		]
	);
};

export const useNumberFormatter = <BlurType = any, FocusType = any>(
	fieldName: DployFieldName | null,
	opts: FormatNumberOptions,
	numberValue?: number,
	emptyEqualsZero?: boolean,
	setNumberValue: (value: number) => void = () => {},
	onNumberBlur: (e: BlurType) => void = () => {},
	onNumberFocus: (e: FocusType) => void = () => {},
	min?: number,
	max?: number,
	percentAsFraction?: boolean,
	allowNegative?: boolean
) => {
	const {
		resolvedOptions,
		decimalSeparator,
		formatPrefix,
		formatSuffix,
		numberToString
	} = useNumberFormatOpts(opts, percentAsFraction);

	const selectedFieldCallback = useSelectedField(fieldName);

	const { onChange, format, parse, refuse } = useMemo(() => {
		const refuse = new RegExp(
			`[^${allowNegative ? "-" : ""}\\d${decimalSeparator}]+`,
			"gi"
		);

		const format = (str: string) => {
			if (allowNegative && str === "-") return "-";
			const { textValue } = parseAndFormat(
				str,
				false,
				refuse,
				decimalSeparator,
				emptyEqualsZero,
				resolvedOptions,
				numberToString,
				min,
				max
			);
			return textValue;
		};

		const parse = (str: string) => {
			if (str === "-") return 0;
			const { numValue } = parseAndFormat(
				str,
				false,
				refuse,
				decimalSeparator,
				emptyEqualsZero,
				resolvedOptions,
				numberToString,
				min,
				max
			);
			return numValue;
		};

		const onChange = (str: string) => {
			selectedFieldCallback("CHANGE");
			// Must use the raw input value internally while editing,
			// formatting might remove the decimalSeparator,
			// making it impossible to enter in the input
			dispatch({ type: "CHANGE", payload: str });
			setNumberValue(parse(str));
		};

		return {
			onChange,
			format,
			parse,
			refuse
		};
	}, [
		allowNegative,
		decimalSeparator,
		emptyEqualsZero,
		resolvedOptions,
		numberToString,
		min,
		max,
		selectedFieldCallback,
		setNumberValue
	]);

	const [internal, dispatch] = useReducer(
		(
			state: {
				textValue: string;
				propControlled: boolean;
				changeOrBlur?: boolean;
			},
			action: ChangeAction | BlurAction | FocusAction
		) => {
			switch (action.type) {
				case "BLUR":
					return {
						textValue: numberValue
							? numberToString(numberValue)
							: state.textValue,
						propControlled: true,
						changeOrBlur: true
					};
				case "CHANGE":
					return {
						textValue: action.payload,
						propControlled: false,
						changeOrBlur: true
					};
				case "FOCUS":
					if (emptyEqualsZero) {
						// Clear field on focus if displayed value is 0
						const textValue = numberValue ? numberToString(numberValue) : "";
						const parsedTextValue = parse(textValue);

						return {
							textValue: parsedTextValue === 0 ? "" : textValue,
							propControlled: false
						};
					}
					return state;
				default:
					return state;
			}
		},
		{
			textValue: numberValue ? numberToString(numberValue) : "",
			propControlled: numberValue !== undefined
		}
	);

	const onBlur = useCallback(
		(e: BlurType) => {
			dispatch({ type: "BLUR" });
			onNumberBlur(e);
		},
		[onNumberBlur]
	);

	const onFocus = useCallback(
		(e: FocusType) => {
			dispatch({ type: "FOCUS" });
			onNumberFocus(e);
		},
		[onNumberFocus]
	);

	const value =
		internal.propControlled && numberValue !== undefined
			? Number.isNaN(numberValue) ||
			  (emptyEqualsZero && numberValue === 0 && !internal.changeOrBlur)
				? ""
				: numberToString(numberValue)
			: internal.textValue;

	useDebugValue(value);

	return {
		value,
		onBlur,
		formatPrefix,
		formatSuffix,
		onChange,
		onFocus,
		format,
		refuse
	};
};

function isNullishOrEmpty<T>(item: T[] | null | undefined): boolean {
	if (!item || item.length === 0) return true;
	return false;
}
