import { memo } from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { ErrorPageContent } from "@ploy-ui/core";
import { ErrorHandler } from "@ploy-lib/core";
import { Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DployTextField, DploySelectionField } from "@ploy-ui/form-fields";
import { useCalculationField, useDispatch } from "@ploy-lib/calculation";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import clsx from "clsx";

addRegisteredSectionLayout({
	name: "ChildListSection",
	displayName: "ChildListSection",
	settings: {}
});

interface ApplicationChild {
	ApplicationChildId: Number;
	ApplicationId: Number;
	CustomerId: Number;
	Name: string;
	Ssn: string;
	BirthDate: string;
	CustomerResponsibility: 0 | 1 | 2;
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {},
		listItem: {
			backgroundColor: theme.palette.primary.light,
			border: `1px solid ${theme.palette.secondary.light}`,
			padding: theme.spacing(2)
		},
		childNumber: {
			paddingBottom: theme.spacing(2),
			paddingTop: theme.spacing(2)
		},
		header: {
			fontWeight: 700,
			paddingBottom: theme.spacing(4),
			padding: theme.spacing(0.5)
		}
	}),
	{ name: "DployChildListSection" }
);

const intlMessages = defineMessages({
	childNumber: {
		id: "template-form.sections.child-list.child-number",
		description: "Number of child",
		defaultMessage: "Child {index}"
	},
	childrenLivingSituation: {
		id: "template-form.sections.child-list.child-living-sitiation",
		description: "Is the child living at home or not?",
		defaultMessage: "Is the child living with you?"
	},
	childFullResponsibility: {
		id: "template-form.sections.child-list.child-living-sitiation.full",
		description: "Full responsibility for the child",
		defaultMessage: "Full"
	},
	childPartialResponsibility: {
		id: "template-form.sections.child-list.child-living-sitiation.partial",
		description: "Partial responsibility for the child",
		defaultMessage: "Partial"
	}
});

const ChildListSectionInternal = memo((props: SectionProps) => {
	const { className } = props;
	const intl = useIntl();
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const childrenField = useCalculationField<ApplicationChild[] | undefined>(
		"Calculator",
		"CHILDREN"
	);
	const children = childrenField?.value || [];

	if (children.length === 0) return null;
	return (
		<Grid container className={clsx(className, classes.root)}>
			<Grid container item xs={12} className={classes.header}>
				<Grid item xs={12}>
					<Typography variant="h5">
						<FormattedMessage
							id="template-form.sections.child-list.title"
							description="Title for child list section"
							defaultMessage="Children"
						/>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2">
						<FormattedMessage
							id="template-form.sections.child-list.sub-title"
							description="Your and divided children"
							defaultMessage="Your own and common children"
						/>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				{children.map((child, index) => (
					<Grid container item xs={12} className={classes.listItem}>
						<Grid item xs={12}>
							<DployTextField
								className={classes.childNumber}
								label={intl.formatMessage(intlMessages.childNumber, {
									index: index + 1
								})}
								value={child.Name}
								disabled
							/>
						</Grid>
						<Grid item xs={12}>
							<DploySelectionField
								label={intl.formatMessage(intlMessages.childrenLivingSituation)}
								items={[
									{
										key: "2",
										value: intl.formatMessage(
											intlMessages.childFullResponsibility
										)
									},
									{
										key: "1",
										value: intl.formatMessage(
											intlMessages.childPartialResponsibility
										)
									}
								]}
								horizontal
								value={child.CustomerResponsibility}
								onChange={selectedValue => {
									const newChildren = children.map(c => {
										if (c.Ssn === child.Ssn) {
											c.CustomerResponsibility = selectedValue;
										}
										return c;
									});
									dispatch({
										type: "patch",
										payload: {
											patches: [
												{
													target: "CHILDREN",
													namespace: "CALCULATOR",
													value: newChildren,
													overwrite: true
												}
											]
										}
									});
								}}
							/>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
});

const ChildListSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={<span>{ChildListSection.displayName} failed to render.</span>}
			/>
		)}
	>
		<ChildListSectionInternal {...props} />
	</ErrorHandler>
);

ChildListSection.displayName = "DployChildListSection";

export { ChildListSection };
