import { StepLabel, Grid } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import {
	getContrastRatio,
	alpha,
	makeStyles,
	Theme
} from "@material-ui/core/styles";

import StepButton from "@material-ui/core/StepButton";
import clsx from "clsx";
import { usePageState } from "../../PageContext";
import { usePages } from "../../pagesContext";
import { touchAllVisibleFields } from "../../components/TouchVisibleFieldsOnPreviousPages";
import { useFormikContext } from "formik";
import {
	useTemplateSectionIsVisible,
	useTemplateFieldIsVisible,
	useFormHasErrors
} from "../../hooks";
import { addRegisteredStepper } from "./stepperDescriptions";
import { BaseStepperProps } from "./BaseStepperProps";

addRegisteredStepper({
	name: "FlatStepper",
	displayName: "FlatStepper"
});

const useStyles = makeStyles(
	theme => ({
		stepper: {
			padding: "12px"
		},
		backgroundColor: {
			backgroundColor: theme.palette.background.paper
		},
		step: {
			flex: "1 1 auto"
		},
		stepActiveOrCompleted: {},
		stepButton: {
			margin: "-12px",
			padding: "12px",
			display: "flex",
			borderBottom: "4px solid " + getFlatStepperColor(theme).disabledColor,
			"&$stepButtonActiveOrCompleted": {
				borderBottomColor: theme.palette.secondary.main
			}
		},
		firstStepButton: {
			paddingLeft: 0,
			justifyContent: "left"
		},
		lastStepButton: {
			paddingRight: 0,
			justifyContent: "right"
		},
		stepButtonActiveOrCompleted: {},
		stepIconContainer: {
			display: "none"
		},
		stepLabel: {
			fontSize: 12,
			fontWeight: 500,
			textTransform: "uppercase" as "uppercase",
			"&$stepLabelActiveOrCompleted": {
				color: theme.palette.secondary.main
			}
		},
		stepLabelActiveOrCompleted: {},
		mobileStepper: {
			[theme.breakpoints.up("sm")]: {
				display: "none"
			}
		},
		desktopStepper: {
			display: "none",
			[theme.breakpoints.up("sm")]: {
				display: "flex"
			}
		},
		stepIcon: {
			color: theme.palette.background.paper,
			borderColor: theme.palette.primary.main,
			borderWidth: 1,
			borderRadius: "50%",
			borderStyle: "solid",

			"&$stepIconActive": {
				color: theme.palette.background.paper
			},
			"&$stepIconCompleted": {
				color: theme.palette.secondary.main,
				borderStyle: "none"
			}
		},
		stepIconActive: {},
		stepIconCompleted: {},
		stepIconText: {
			fill: theme.palette.primary.main,
			fontWeight: 700
		}
	}),
	{ name: "DployFlatStepperStyle" }
);

interface FlatStepperProps extends BaseStepperProps {
	className?: string;
	activeOrCompleteColor?: string;
	disabledColor?: string;
}

function getFlatStepperColor(theme: Theme): {
	activeOrCompleteColor: string;
	disabledColor: string;
} {
	const contrastThreshold = 2.5;
	const main = theme.palette.primary.main;
	const secondary = theme.palette.secondary.main;

	if (getContrastRatio(main, theme.palette.common.white) > contrastThreshold)
		return {
			activeOrCompleteColor: main,
			disabledColor: alpha(main, 0)
		};
	if (
		getContrastRatio(secondary, theme.palette.common.white) > contrastThreshold
	)
		return {
			activeOrCompleteColor: secondary,
			disabledColor: alpha(secondary, 0)
		};

	return {
		activeOrCompleteColor: "rgba(109,162,18,1)",
		disabledColor: "rgba(109,162,18,0)"
	};
}

export function FlatStepper(props: FlatStepperProps) {
	const formik = useFormikContext();

	const classes = useStyles({ ...props });

	const { step, goto, labels, getStepHref } = usePageState();
	const pages = usePages();

	const formHasErrors = useFormHasErrors();
	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();
	const { touched, setTouched } = formik;

	const gotoValidPage = (requestedPageIdx: number) => {
		if (requestedPageIdx <= step) return goto(requestedPageIdx);

		const relevantTouched = touchAllVisibleFields(
			touched,
			pages.slice(0, requestedPageIdx),
			fieldIsVisible,
			sectionIsVisible
		);

		for (let pageIdx = requestedPageIdx; pageIdx > step; pageIdx--) {
			if (
				!formHasErrors(
					{ ...formik, touched: relevantTouched },
					pages.slice(0, pageIdx),
					false
				)
			) {
				setTouched(
					touchAllVisibleFields(
						touched,
						pages.slice(0, Math.min(pageIdx + 1, requestedPageIdx)),
						fieldIsVisible,
						sectionIsVisible
					)
				);

				return goto(pageIdx);
			}
		}

		setTouched(
			touchAllVisibleFields(
				touched,
				pages.slice(0, step + 1),
				fieldIsVisible,
				sectionIsVisible
			)
		);
	};

	const stepperForMobile = (
		<div className={clsx(classes.mobileStepper)}>
			<Stepper
				activeStep={step}
				//@ts-ignore
				connector={null}
				className={classes.stepper}
			>
				{pages.map((page, i) => {
					return page.displayInStepper ? (
						<Step key={page.pageTitle || i}>
							<StepButton
								component="a"
								href={getStepHref(i)}
								onClick={e => {
									e.preventDefault();
									gotoValidPage(i);
								}}
								disabled={
									i === step ||
									(i > step && formHasErrors(formik, pages.slice(0, i), false))
								}
							>
								<StepLabel
									StepIconProps={{
										classes: {
											root: classes.stepIcon,
											active: classes.stepIconActive,
											completed: classes.stepIconCompleted,
											text: classes.stepIconText
										}
									}}
								></StepLabel>
							</StepButton>
						</Step>
					) : null;
				})}
			</Stepper>
		</div>
	);

	const stepperForDesktop = (
		<Stepper
			className={clsx(classes.stepper, classes.desktopStepper)}
			activeStep={step}
			//@ts-ignore
			connector={null}
		>
			{pages.map((page, i) => {
				return page.displayInStepper ? (
					<Step key={page.pageTitle || i} className={classes.step}>
						<StepButton
							className={clsx(
								{
									[classes.stepButtonActiveOrCompleted]: i <= step
								},
								classes.stepButton,
								{ [classes.firstStepButton]: i === 0 },
								{ [classes.lastStepButton]: i + 1 === labels.length }
							)}
							component="a"
							href={getStepHref(i)}
							onClick={e => {
								e.preventDefault();
								gotoValidPage(i);
							}}
							disabled={
								i === step ||
								(i > step && formHasErrors(formik, pages.slice(0, i), false))
							}
						>
							<StepLabel
								classes={{
									label: classes.stepLabel,
									active: classes.stepLabelActiveOrCompleted,
									completed: classes.stepLabelActiveOrCompleted,
									iconContainer: classes.stepIconContainer
								}}
							>
								{page.pageTitle}
							</StepLabel>
						</StepButton>
					</Step>
				) : null;
			})}
		</Stepper>
	);

	return (
		<>
			<Grid
				container
				justifyContent="center"
				className={clsx(classes.backgroundColor)}
			>
				<Grid item xs={12} md={8} lg={4}>
					{stepperForMobile}
					{stepperForDesktop}
				</Grid>
			</Grid>
		</>
	);
}

FlatStepper.displayName = "DployFlatStepper";
