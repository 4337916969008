import React from "react";
import {
	BackofficeCommissionObject,
	GetAdditionalInfoResponse,
	SendToApprovalRequest,
	SendToApprovalRepsonse,
	GetApplicationInfoResponse
} from "@ploy-lib/commission-api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "@ploy-ui/core";
import groupBy from "lodash/groupBy";
import {
	Typography,
	Box,
	Checkbox,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	CircularProgress
} from "@material-ui/core";
import { FormattedNumber } from "react-intl";

const getMonthNameFromIndex = index => {
	var month = new Date(0, index).toLocaleDateString("nb-NO", { month: "long" });
	return month.charAt(0).toUpperCase() + month.substring(1);
};

export const TableStructure = ({
	list,
	groupByLamda,
	monthly,
	year,
	getAdditionalInformation,
	sendToApproval,
	getApplicationInformation
}: {
	list: BackofficeCommissionObject[];
	groupByLamda: any;
	monthly: boolean;
	year: string;
	getAdditionalInformation?: (
		monthYear: Date,
		agreementNumber: string
	) => Promise<GetAdditionalInfoResponse>;
	sendToApproval?: (
		agreements: SendToApprovalRequest[]
	) => Promise<SendToApprovalRepsonse>;
	getApplicationInformation?: (
		contracts: string[]
	) => Promise<GetApplicationInfoResponse>;
}) => {
	let grouped = groupBy(list, groupByLamda);
	let tableStructure = Object.entries(grouped)
		.sort(([k1], [k2]) => k2.localeCompare(k1))
		.map(([x, commissions]) => {
			return (
				<Box key={x}>
					<Accordion elevation={0} defaultExpanded={!monthly}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">
								{monthly ? getMonthNameFromIndex(x) : x}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ display: "block" }}>
							{monthly ? (
								<CommissionType
									commissions={commissions}
									monthDate={
										new Date(Number.parseInt(year), Number.parseInt(x), 1)
									}
									getAdditionalInformation={getAdditionalInformation}
									sendToApproval={sendToApproval}
									getApplicationInformation={getApplicationInformation}
								/>
							) : (
								<TableStructure
									list={commissions}
									groupByLamda={c =>
										isNaN(c.startDate.getMonth())
											? c.date.getMonth()
											: c.startDate.getMonth()
									}
									year={x}
									monthly={true}
									getAdditionalInformation={getAdditionalInformation}
									sendToApproval={sendToApproval}
									getApplicationInformation={getApplicationInformation}
								/>
							)}
						</AccordionDetails>
					</Accordion>
				</Box>
			);
		});
	return <>{tableStructure}</>;
};

export const CommissionType = ({
	commissions,
	monthDate,
	getAdditionalInformation,
	sendToApproval,
	getApplicationInformation
}: {
	commissions: BackofficeCommissionObject[];
	monthDate: Date;
	getAdditionalInformation?: (
		monthYear: Date,
		agreementNumber: string
	) => Promise<GetAdditionalInfoResponse>;
	sendToApproval?: (
		agreements: SendToApprovalRequest[]
	) => Promise<SendToApprovalRepsonse>;
	getApplicationInformation?: (
		contracts: string[]
	) => Promise<GetApplicationInfoResponse>;
}) => {
	const [logRetrived, setLogRetrived] = React.useState<boolean>(false);
	const [retriving, setRetriving] = React.useState<boolean>(false);
	const [applicationInformation, setApplicationInformation] =
		React.useState<GetApplicationInfoResponse>();
	const [sent, setSent] = React.useState<string[]>([]);
	const [send, setSend] = React.useState<SendToApprovalRequest[]>([]);
	const [approved, setApproved] = React.useState<string[]>([]);
	const [declined, setDeclined] = React.useState<string[]>([]);
	const [message, setMessage] = React.useState<string>("");
	const [loading, setLoading] = React.useState<boolean>(false);

	const handleCheck = (agreement: SendToApprovalRequest) => {
		setMessage("");
		if (send.some(x => x.agreementNumber === agreement.agreementNumber))
			setSend(
				send.filter(x => x.agreementNumber !== agreement.agreementNumber)
			);
		else setSend(send.concat(agreement));
	};

	const handleSendToApproval = async () => {
		if (sendToApproval) {
			setLoading(true);
			const response = await sendToApproval(send);
			if (response.success) {
				setMessage("Sendt til avregning");
				setSent(sent.concat(send.map(x => x.agreementNumber)));
			} else setMessage(response.message);
			setLoading(false);
			setSend([]);
		}
	};

	const handleGetLog = async (agreements: string[]) => {
		if (logRetrived || !getAdditionalInformation) return;
		const responses = await Promise.all(
			agreements.map(agreementNumber =>
				getAdditionalInformation(monthDate, agreementNumber)
			)
		);
		setSent(
			responses.filter(x => x.status === "Sent").map(y => y.agreementNumber)
		);
		setApproved(
			responses
				.filter(x => x.status === "Approved" || x.status === "Paid")
				.map(y => y.agreementNumber)
		);
		setDeclined(
			responses.filter(x => x.status === "Declined").map(y => y.agreementNumber)
		);
		setLogRetrived(true);
	};

	const handleExpand = async (contracts: string[]) => {
		if (applicationInformation) {
			contracts = contracts.filter(
				x =>
					!applicationInformation.applications.some(y => y.contractNumber === x)
			);
		}
		setRetriving(true);
		if (!getApplicationInformation || contracts.length === 0) return;
		const response = await getApplicationInformation(contracts);
		if (response && response.applications) setApplicationInformation(response);
		setRetriving(false);
	};

	React.useEffect(() => {
		handleGetLog(
			Object.entries(groupBy(commissions, c => c.agreementNumber)).map(
				([x, list]) => {
					return x;
				}
			)
		);
	});

	let agreements = Object.entries(
		groupBy(commissions, c => c.agreementNumber)
	).map(([x, list]) => {
		var sum = Math.round(
			list.reduce((a, b) => {
				const {
					upfrontAmount = 0,
					marginAmount = 0,
					billCommissionAmount = 0
				} = b;
				return a + upfrontAmount + marginAmount + billCommissionAmount;
			}, 0)
		);
		const obj: SendToApprovalRequest = {
			agreementNumber: x,
			amount: sum,
			date: monthDate,
			agreementType: list[0].agreementType
		};
		let status = "";
		if (approved.includes(x)) status = "Godkjent";
		else if (declined.includes(x)) status = "Avslått";
		else if (sent.includes(x)) status = "Avventer godkjenning";
		return (
			<Box p={1} style={{ display: "inline-flex" }} key={x}>
				{sendToApproval && (
					<Checkbox
						checked={
							sent.includes(x) ||
							approved.includes(x) ||
							send.some(y => x === y.agreementNumber)
						}
						onChange={() => handleCheck(obj)}
						disabled={sent.includes(x) || approved.includes(x)}
					/>
				)}
				{!!sendToApproval ? (
					<Accordion
						elevation={0}
						onChange={() =>
							handleExpand(Object.keys(groupBy(list, c => c.contractNumber)))
						}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">
								<Box p={1} component="span" fontWeight="fontWeightBold">
									{list[0].agreementType}
								</Box>
								<Box p={1} component="span" fontWeight="fontWeightBold">
									<FormattedNumber value={sum} format="currency" />
								</Box>
								<Box p={1} component="span" fontWeight="fontWeightBold">
									{status}
								</Box>
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<DataTable<BackofficeCommissionObject>
								options={{
									search: false,
									showTitle: false,
									paging: false,
									emptyRowsWhenPaging: false,
									toolbar: false
								}}
								columns={[
									{ title: "Selger", field: "salespersonName" },
									{ title: "Kontraktnr.", field: "contractNumber" },
									{
										title: "Saksnr.",
										render: row =>
											retriving ? (
												<CircularProgress size={10} />
											) : applicationInformation &&
											  applicationInformation.applications &&
											  applicationInformation.applications.some(
													t => t.contractNumber === row.contractNumber
											  ) ? (
												<Box>
													{
														applicationInformation.applications.filter(
															t => t.contractNumber === row.contractNumber
														)[0].applicationNumber
													}
												</Box>
											) : null
									},
									{
										title: "Reg.nr.",
										render: row =>
											retriving ? (
												<CircularProgress size={10} />
											) : applicationInformation &&
											  applicationInformation.applications &&
											  applicationInformation.applications.some(
													t => t.contractNumber === row.contractNumber
											  ) ? (
												<Box>
													{
														applicationInformation.applications.filter(
															t => t.contractNumber === row.contractNumber
														)[0].regNumber
													}
												</Box>
											) : null
									},
									{ title: "Kunde", field: "customerName" },
									{
										title: "Upfront",
										field: "upfrontAmount",
										render: row =>
											row.upfrontAmount ? (
												<FormattedNumber
													value={row.upfrontAmount}
													format="currency"
												/>
											) : null
									},
									{
										title: "Stykk",
										field: "billCommissionAmount",
										render: row =>
											row.billCommissionAmount ? (
												<FormattedNumber
													value={row.billCommissionAmount}
													format="currency"
												/>
											) : null
									},
									{
										title: "Periodisk",
										field: "marginAmount",
										render: row =>
											row.marginAmount ? (
												<FormattedNumber
													value={row.marginAmount && row.marginAmount}
													format="currency"
												/>
											) : null
									}
								]}
								data={list}
							/>
						</AccordionDetails>
					</Accordion>
				) : (
					<>
						<Typography variant="h6">
							<Box p={1} component="span" fontWeight="fontWeightBold">
								{list[0].agreementType}
							</Box>
							<Box p={1} component="span" fontWeight="fontWeightBold">
								<FormattedNumber value={sum} format="currency" />
							</Box>
							<Box p={1} component="span" fontWeight="fontWeightBold">
								{status}
							</Box>
						</Typography>
					</>
				)}
			</Box>
		);
	});
	return (
		<Box p={1}>
			{agreements}
			{sendToApproval && (
				<Button
					color="primary"
					variant="contained"
					onClick={() => handleSendToApproval()}
					disabled={loading || send.length === 0}
				>
					Send til avregning
				</Button>
			)}
			{loading && (
				<Box p={1} component="span">
					<CircularProgress size={30} />{" "}
				</Box>
			)}
			{message && (
				<Box p={1} component="span">
					{message}
				</Box>
			)}
		</Box>
	);
};
