import { EditorFields as EditorFieldsInternal } from "@ploy-ui/form-fields";
import { EditorButtonFields } from "./Buttons/Button";
import { EditorSearchSelectFields } from "./SearchSelectList";
import { EditorCarSummaryFields } from "./CarInfoSummary";
import { EditorSliderFields } from "./Sliders";
import { EditorAppActionButtons } from "./Buttons/AppActionButton";
import { EditorSubmitButtons } from "./Buttons/SubmitButton";
import { EditorCommentFields } from "./Comment";
import { EditorMarkdownFields } from "./MarkdownField";
import { EditorNewTabButton } from "./Buttons/NewTabButton";
import { EditorProductSelector } from "./customFields/ProductSelector";
import { EditorSeparator } from "./SeparatorField";
import { EditorGoToSblButton } from "./Buttons/GoToSblButton";

export * from "./customFields";
export * from "./FieldContainer";
export * from "./CalculationField";
export * from "./Sliders";
export { SearchSelectList } from "./SearchSelectList";
export { default } from "./FieldContainer";

export const EditorFields = {
	...EditorFieldsInternal,
	...EditorButtonFields,
	...EditorSearchSelectFields,
	...EditorCarSummaryFields,
	...EditorSliderFields,
	...EditorAppActionButtons,
	...EditorSubmitButtons,
	...EditorCommentFields,
	...EditorMarkdownFields,
	...EditorNewTabButton,
	...EditorProductSelector,
	...EditorSeparator,
	...EditorGoToSblButton
};

export { EditorLiterals } from "./literals";
