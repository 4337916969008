import { ApplicationStatusId, ApplicationStatus } from "@ploy-lib/types";
import { Palette } from "@material-ui/core/styles/createPalette";
import { getIn } from "@ploy-lib/core";
import { decomposeColor } from "@material-ui/core";
import { StatusColor, StatusColorOptions } from "./dployTheme";

export const defaultStatusColorOptions: StatusColor = {
	NotSet: "grey.A200",
	Saved: "info.light",
	Submitted: "success.light",
	Scoring: "warning.light",
	ManualUW: "warning.light",
	AutoApproved: "success.light",
	ManuallyApproved: "success.light",
	ManuallyRejected: "error.light",
	AutoRejected: "error.light",
	Cancelled: "grey.A200",
	Paid: "success.light",
	Transferred: "success.light",
	Replaced: "grey.A200",
	Expired: "grey.A200",
	ApprovedWithConditions: "success.light",
	Prescore: "warning.light",
	Processing: "warning.light",
	Invoiced: "success.light",
	Signed: "info.light",
	InvestPaid: "info.light",
	PartlyPaid: "success.light",
	Offer: "info.light",
	RejectedMissingDocumentation: "error.light",
	Fulfilled: "success.light",
	RedeemProofOfFinancing: "info.light"
};

export function createStatusColors(
	options: StatusColorOptions = {},
	palette: Palette
): StatusColor {
	let colors: Partial<StatusColor> = {};

	for (const status of Object.keys(ApplicationStatus) as ApplicationStatus[]) {
		const statusId = ApplicationStatusId[status];

		const colorOption =
			options[status] ?? options[statusId] ?? defaultStatusColorOptions[status];

		let color =
			getIn(palette, `${colorOption}.main`) ??
			getIn(palette, colorOption) ??
			colorOption;

		try {
			ensureValidColor(color);
		} catch (e: any) {
			if (process.env.NODE_ENV === "development") throw e;

			console.warn(e);
			color = palette.grey[200];
		}

		colors[status] = color;

		// Backwards compatabilty: Status color by id
		colors[statusId as unknown as ApplicationStatus] = color;
	}

	return colors as StatusColor;
}

// throws if color is in incorrect format ('rgb', 'rgba', 'hsl', 'hsla', or hex)
function ensureValidColor(color: any) {
	decomposeColor(color);
}
