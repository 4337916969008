import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ColoredStatus, { ColoredStatusProps } from "./ColoredStatus";

const DoubleLineSmDown = <TData extends object, TLoaded extends object>(
	props: ColoredStatusProps<TData, TLoaded> & { secondLineKey: string }
) => {
	const { secondLineKey, children, ...rest } = props;

	const theme = useTheme();
	const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

	return (
		<Box display={isMobile ? "flex" : undefined} alignItems="center">
			<Hidden smUp>
				<ColoredStatus mr={1} {...rest} />
			</Hidden>
			<div>
				<Box fontWeight="bold">{children}</Box>
				<Hidden mdUp>
					<div>{props.rowData[secondLineKey]}</div>
				</Hidden>
			</div>
		</Box>
	);
};

export default DoubleLineSmDown;
