import {
	useState,
	useCallback,
	useMemo,
	useLayoutEffect,
	useRef,
	useEffect,
	useDebugValue
} from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import constate from "constate";
import { ApplicationInfoFilter } from "@ploy-lib/rest-resources";

function useAppSearchParamState() {
	const [searchParams, set] = useState<ApplicationInfoFilter>();

	const setSingle = useCallback(
		(id, value) => set(state => ({ ...state, [id]: value })),
		[]
	);

	return useMemo(
		() => ({
			searchParams,
			set,
			setSingle,
			resetSearchParams: set
		}),
		[searchParams, setSingle]
	);
}

function useDrawerState() {
	const theme = useTheme();
	const isLargeScreen = !useMediaQuery(theme.breakpoints.down("md"));
	const [isOpen, setIsOpen] = useState(isLargeScreen);

	// console.log(isLargeScreen);

	useLayoutEffect(() => {
		setIsOpen(isLargeScreen);
	}, [isLargeScreen]);

	const open = useCallback(() => setIsOpen(true), []);
	const collapse = useCallback(() => setIsOpen(false), []);
	const toggle = useCallback(() => setIsOpen(wasOpen => !wasOpen), []);

	return useMemo(
		() => ({ isOpen, open, collapse, toggle }),
		[isOpen, open, collapse, toggle]
	);
}

const isAwaitable = <T, Other>(
	r: Promise<T> | T | undefined | Other
): r is Promise<T> => r != null && typeof (r as any).then === "function";

function useEditingState() {
	const [isEditing, setEditing] = useState(false);
	const [isAwaitingEditing, setAwatingEditing] = useState(false);
	const [isSaving, setSaving] = useState(false);
	const [isCanceling, setCanceling] = useState(false);

	const optionsRef = useRef<EditableOptions[]>([]);

	const editingEnabledCountRef = useRef(0);

	const [isEditable, setIsEditable] = useState(false);

	const register = useCallback((options: EditableOptions) => {
		optionsRef.current.push(options);
		return () => {
			const idx = optionsRef.current.indexOf(options);
			optionsRef.current.splice(idx, 1);
		};
	}, []);

	const enableEdit = useCallback(() => {
		editingEnabledCountRef.current++;
		setIsEditable(s => editingEnabledCountRef.current > 0);

		return () => {
			editingEnabledCountRef.current--;
			setIsEditable(s => editingEnabledCountRef.current > 0);
		};
	}, []);

	const editing = useMemo(() => {
		return {
			isEditable,
			isEditing,
			isSaving,
			isCanceling,
			isAwaitingEditing,
			async edit() {
				setEditing(false);
				setAwatingEditing(true);

				const thenables = optionsRef.current
					.filter(o => o.canEdit?.() ?? true)
					.map(o => o.onEdit?.())
					.filter(isAwaitable);

				try {
					if (thenables.length > 0) await Promise.all(thenables);
					setEditing(true);
				} finally {
					setAwatingEditing(false);
				}
			},
			async cancel() {
				setCanceling(true);

				const thenables = optionsRef.current
					.filter(o => o.canEdit?.() ?? true)
					.map(o => o.onCancel?.())
					.filter(isAwaitable);

				try {
					if (thenables.length > 0) await Promise.all(thenables);
					setEditing(false);
				} finally {
					setCanceling(false);
				}
			},
			async save() {
				setSaving(true);

				const thenables = optionsRef.current
					.filter(o => o.canEdit?.() ?? true)
					.map(o => o.onSave())
					.filter(isAwaitable);

				try {
					if (thenables.length > 0) await Promise.all(thenables);
					setEditing(false);
				} finally {
					setSaving(false);
				}
			}
		};
	}, [isEditable, isEditing, isSaving, isCanceling, isAwaitingEditing]);

	const editable = isEditing && !isAwaitingEditing;

	const registration = useMemo(
		() => ({ editable, register, enableEdit }),
		[editable, enableEdit, register]
	);

	return { editing, registration };
}

export const [DrawerContextProvider, useDrawer] = constate(useDrawerState);
export const [AppSearchParamContextProvider, useAppSearchParams] = constate(
	useAppSearchParamState
);
export const [EditingContextProvider, useEditing, useEditingRegistration] =
	constate(
		useEditingState,
		c => c.editing,
		c => c.registration
	);

export interface EditableOptions {
	canEdit?: () => boolean;
	onSave: () => Promise<void> | void;
	onCancel?: () => Promise<void> | void;
	onEdit?: () => Promise<void> | void;
}

export function useEditable(options: EditableOptions) {
	const { editable, register, enableEdit } = useEditingRegistration();

	useEffect(() => register(options), [register, options]);

	const canEdit = options.canEdit?.() ?? true;

	useEffect(() => (canEdit ? enableEdit() : undefined), [canEdit, enableEdit]);

	useDebugValue(editable && canEdit);
	return editable && canEdit;
}
