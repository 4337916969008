import React from "react";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";
import { useFormikContext } from "formik";
import Typography from "@material-ui/core/Typography";

export const SubmitButton = (props: PendingButtonProps) => {
	const { children, ...rest } = props;
	const formik = useFormikContext();
	return (
		<PendingButton
			type="submit"
			pending={formik.isSubmitting}
			disabled={!formik.isValid}
			{...rest}
		>
			{children}
		</PendingButton>
	);
};

export const SubmitStatusMessage = () => {
	const formik = useFormikContext();
	const { status: { submit } = {} } = formik;

	return submit ? (
		<Typography color={submit.status || "info"}>{submit.message}</Typography>
	) : null;
};
