import { useMemo, useDebugValue } from "react";
import { useCalculation } from "../context";
import { getInNamespaced } from "../../calculator/utils";
import { TemplateField } from "@ploy-lib/types";

export interface VariableDef {
	namespace: TemplateField["namespace"];
	variableName: string;
}

export interface VariableData<TData> {
	namespace: TemplateField["namespace"];
	variableName: string;
	value?: TData;
	missing?: boolean;
	writeLocked?: boolean;
}

export type DefaultData = string | number | boolean | undefined;

export function useVariableData<TData = DefaultData>(
	namespace?: string,
	variableName?: string
): Partial<VariableData<TData>> {
	const [data] = useVariablesData(
		namespace && variableName
			? {
					namespace,
					variableName
			  }
			: undefined
	);

	useDebugValue(data);

	const { value, missing, writeLocked } =
		data || ({} as Partial<VariableData<TData>>);

	const variableData = useMemo(
		() =>
			({ value, missing, writeLocked, variableName, namespace } as Partial<
				VariableData<TData>
			>),
		[value, missing, writeLocked, variableName, namespace]
	);

	return variableData;
}

export function useVariablesData<
	TData = DefaultData,
	TDef extends VariableDef = VariableDef
>(...variables: (TDef | undefined)[]): (VariableData<TData> | undefined)[] {
	const { values, isMissing, isWriteLocked } = useCalculation(
		"values",
		"isMissing",
		"isWriteLocked"
	);

	const data = variables.map(v => {
		if (v == null) return undefined;
		const { namespace, variableName } = v;

		const value = getInNamespaced(
			values,
			namespace,
			variableName
		) as unknown as TData;
		const missing = getInNamespaced(isMissing, namespace, variableName);
		const writeLocked = getInNamespaced(isWriteLocked, namespace, variableName);

		return {
			value,
			missing,
			writeLocked,
			variableName,
			namespace
		};
	});

	return data;
}
