import {
	Card,
	CardContent,
	CardHeader,
	Dialog,
	IconButton,
	makeStyles,
	Theme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ProductCategory } from "@ploy-lib/rest-resources";
import { ProductView } from "@ploy-ui/form-views";
import { FormattedMessage } from "react-intl";

interface WholesaleOverviewNewAppProps {
	open: boolean;
	setOpen: (val: boolean) => void;
}

const FormattedHeaderText = (
	<FormattedMessage
		id="vendor.wholesale.new-fulfillment-application-header"
		description="New fulfillment application header"
		defaultMessage="New withdrawal"
	/>
);

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		overflow: "auto"
	},
	cardHeader: {
		color: theme.palette.secondary.main,
		fontWeight: "bold"
	}
}));

const WholesaleOverviewNewApp = (props: WholesaleOverviewNewAppProps) => {
	const { open, setOpen } = props;
	const classes = useStyles();

	return (
		<Dialog open={open} style={{ margin: "auto" }} maxWidth="md">
			<Card className={classes.card}>
				<CardHeader
					titleTypographyProps={{ className: classes.cardHeader }}
					title={FormattedHeaderText}
					action={
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					}
				/>
				<CardContent>
					<ProductView
						externalCode="BF_WHOLESALE"
						category={ProductCategory.Finance}
					/>
				</CardContent>
			</Card>
		</Dialog>
	);
};

export { WholesaleOverviewNewApp };
