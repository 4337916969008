import React from "react";
import { CarInfoSummary, CarInfoSummaryProps } from "./CarInfoSummary";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";

const carSummaryVariants = ["small"] as const;

interface CarSummaryFieldProps extends CarInfoSummaryProps {
	variant: string;
}

export const CarSummaryField = (props: CarSummaryFieldProps) => {
	const { variant } = props;

	switch (variant) {
		case "small":
			return <CarInfoSummary {...props} small />;
		default:
			return <CarInfoSummary {...props} />;
	}
};

export const EditorCarSummaryFields = identityRecordOfFieldEditorOptions({
	CarSummaryField: {
		editableOptions: {
			variant: carSummaryVariants
		}
	}
});
