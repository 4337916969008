import React, { Suspense, useState, CSSProperties } from "react";
import { useFetcher } from "@rest-hooks/core";
import { LinearProgress, Typography } from "@material-ui/core";
import ResetIcon from "@material-ui/icons/SettingsBackupRestore";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { DployTable } from "../DployTable";
import {
	SigningDocumentResource,
	SigningDocument,
	ID
} from "@ploy-lib/rest-resources";
import { defineMessages, useIntl } from "react-intl";
import { downloadBlob as downloadUtil } from "@ploy-lib/core";
import { useTenant } from "@ploy-ui/tenants";

export interface AppSigningStatusProps {
	applicationNumber: ID;
	documents: SigningDocumentResource[] | null;
	hideSigningActions?: boolean;
}
const messages = defineMessages({
	title: {
		id: "core.application-signing.status.title",
		defaultMessage: "Status"
	},
	read: {
		id: "core.application-signing.status.read",
		defaultMessage: "Read"
	},
	documentGroup: {
		id: "core.application-signing.status.document-group",
		defaultMessage: "Document group"
	},
	partlySigned: {
		id: "core.application-signing.status.partially-signed",
		defaultMessage: "Partially signed"
	},
	signed: {
		id: "core.application-signing.status.signed",
		defaultMessage: "Signed"
	},
	reSign: {
		id: "core.application-signing.status.reset",
		defaultMessage: "Reset"
	},
	download: {
		id: "core.application-signing.status.download",
		defaultMessage: "Download"
	},
	error: {
		id: "core.application-signing.status.error",
		defaultMessage: "There was an error"
	}
});

const AppSigningStatus = (props: AppSigningStatusProps) => {
	const { documents, hideSigningActions } = props;

	const intl = useIntl();

	const cellStyle: CSSProperties = { fontSize: 13 };
	const resetSignature = useFetcher(SigningDocumentResource.resetSignature());

	const [errorMessage, setErrorMessage] = useState("");

	const useColumnSort = !(useTenant().tenant?.tenantName === "DNBFI");

	return (
		<Suspense fallback={<LinearProgress />}>
			<DployTable
				title={intl.formatMessage(messages.title)}
				columns={[
					{
						title: intl.formatMessage(messages.documentGroup),
						field: "documentName",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.read),
						field: "read",
						type: "boolean",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.partlySigned),
						field: "partiallySigned",
						type: "boolean",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.signed),
						field: "signed",
						type: "boolean",
						sorting: useColumnSort,
						cellStyle
					}
				]}
				data={documents || []}
				actions={
					hideSigningActions
						? undefined
						: [
								({ allowResetSignature, canResetSignature }: SigningDocument) =>
									allowResetSignature && canResetSignature
										? {
												icon: () => <ResetIcon />,
												tooltip: intl.formatMessage(messages.reSign),
												onClick: (e, { esignTaskId }: SigningDocument) => {
													if (
														!window.confirm(
															"Dette vil og resette alle dokumenter hvor signatarer på dette dokumentet er berørt"
														)
													)
														return;
													if (esignTaskId)
														return resetSignature({}, { id: esignTaskId });
												}
										  }
										: (undefined as any),
								({
									download,
									allowDocumentDownload,
									documentName,
									applicationNumber
								}: SigningDocument) =>
									allowDocumentDownload && download
										? {
												icon: () => {
													return <DownloadIcon />;
												},
												tooltip: intl.formatMessage(messages.download),
												onClick: async e => {
													e.stopPropagation();
													setErrorMessage("");

													// const options = {
													// 	method: "get",
													// 	credentials: "same-origin",
													// 	headers: {
													// 		"Content-Type": "application/pdf"
													// 	}
													// };

													try {
														const response = await fetch(download);
														if (!response.ok) {
															throw new Error("Server error");
														}
														const blob = await response.blob();

														const fileName = `${documentName}_${applicationNumber}`;

														downloadUtil(blob, fileName);
													} catch (error: any) {
														console.error(error);
														setErrorMessage(intl.formatMessage(messages.error));
													}
												}
										  }
										: (undefined as any)
						  ]
				}
				options={{
					actionsColumnIndex: -1,
					search: false,
					draggable: false,
					sorting: false,
					headerStyle: cellStyle,
					paging: documents != null && documents.length > 5,
					showEmptyDataSourceMessage: false,
					emptyRowsWhenPaging: false,
					header: true,
					idSynonym: "esignTaskId"
				}}
			/>
			{errorMessage && (
				<Typography color="error" variant="subtitle2">
					{errorMessage}
				</Typography>
			)}
		</Suspense>
	);
};

AppSigningStatus.displayName = "DployAppSigningStatus";

export { AppSigningStatus };
