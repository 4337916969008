import { ApplicationDebt } from "@ploy-lib/types";

function mapLoanType(d: ApplicationDebt): number {
	if (d.loanType === "mortgage") {
		return 1;
	}

	return 0;
}

function debtComparer(a: ApplicationDebt, b: ApplicationDebt): number {
	const aLoanType = mapLoanType(a);
	const bLoanType = mapLoanType(b);

	if (aLoanType !== bLoanType) return aLoanType - bLoanType;

	return a.amount - b.amount;
}
// Please keep algorithm somewhat in sync with MapRefinancedApplicationDebtToPayment.cs
export function MapAndOrderRefinancedDebts(
	debts: ApplicationDebt[] = [],
	loanAmount: number = 0
): ApplicationDebt[] {
	if (loanAmount < 0) loanAmount = 0;

	let refinancedDebts = debts
		.filter(d => d.refinance)
		.map(debtToCopy => ({ ...debtToCopy }))
		.sort(debtComparer);

	for (const d of refinancedDebts) {
		d.amount = d.amount > loanAmount ? loanAmount : d.amount;
		loanAmount = loanAmount - d.amount;
	}

	return refinancedDebts;
}
