import { useAreAllFieldsVisible } from "@ploy-lib/calculation";
import { useDebugValue } from "react";

export function useSignerMatrixVisible() {
	const isVisible = useAreAllFieldsVisible({
		name: "SendToEsign",
		namespace: "DocumentProduction"
	});
	const isMainCustomerVisible = useAreAllFieldsVisible({
		name: "plApplicationSignerMatrix",
		namespace: "DocumentProduction"
	});

	const isAlwaysVisible = useAreAllFieldsVisible({
		name: "plAlwaysShowApplicationSignerMatrix",
		namespace: "DocumentProduction"
	});

	useDebugValue(isVisible);
	useDebugValue(isAlwaysVisible);

	return [isVisible || isAlwaysVisible, isMainCustomerVisible];
}
