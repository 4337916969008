import { FormatNumberOptions } from "react-intl";

export function extractNumberFormat(
	options: FormatNumberOptions,
	format?: string
) {
	const regex = /^((#{0,})(0{0,})(,| ))?(#{0,})(0{0,})(?:\.(0{0,})(#{0,}))?$/;

	if (!format) return options;

	const match = format.match(regex);

	// console.log("received format", format, "extracted", match);

	if (!match) return options;

	const results = { ...options };
	results.useGrouping = match[4] != null;

	results.minimumIntegerDigits = match[6] ? match[6].length : 1;

	results.minimumFractionDigits = match[7] ? match[7].length : 0;
	results.maximumFractionDigits = match[7] ? match[7].length : 0;

	results.maximumFractionDigits += match[8] ? match[8].length : 0;

	// console.log("received format", format, "results:", results);

	return results;
}
