import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import { Step, StepLabel, Grid, Typography } from "@material-ui/core";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { usePages } from "../../pagesContext";
import { addRegisteredStepper } from "./stepperDescriptions";
import { BaseStepperProps } from "./BaseStepperProps";
import { usePageState } from "../../PageContext";
import { Fragment } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

addRegisteredStepper({
	name: "SegmentStepper",
	displayName: "SegmentStepper"
});

const useColorlibStepIconStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			zIndex: 1,
			boxSizing: "border-box",
			mozBoxSizing: "border-box",
			webkitBoxSizing: "border-box",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "4px",
			width: "24px",
			borderRadius: "1px",
			backgroundColor: theme.palette.secondary.light
		},
		active: {
			backgroundColor: theme.palette.primary.main,
			height: "6px"
		},
		completed: {
			backgroundColor: theme.palette.primary.main
		}
	}),
	{ name: "SegmentStepperStepIcon" }
);

function ColorlibStepIcon(props: StepIconProps) {
	const classes = useColorlibStepIconStyles({});
	const { active, completed, icon } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed
			})}
		>
			{icon}
		</div>
	);
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			backgroundColor: "transparent",
			width: "auto",
			padding: 0
		},
		step: {
			padding: 0,
			"& > *": { width: "28px" },
			"& > * > *": { paddingRight: "4px" },
			/*
				The only way to target the first and last step is through this class.
				The last borderRadius could come from SegmentStepperIcon-root,
				but collecting all borderRadius styles in one place was deemed more readable
			*/
			"& > * > * > *": { borderRadius: "1px" },
			"&:first-child > * > * > *": { borderRadius: "2px 1px 1px 2px" },
			"&:last-child > * > * > *": { borderRadius: "1px 2px 2px 1px" }
		},
		icon: {
			margin: "0 auto"
		},
		outer: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.spacing(4),
				paddingRight: theme.spacing(4)
			}
		},
		box: {
			maxWidth: theme.spacing(4) + 550,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	}),
	{ name: "SegmentStepper" }
);

export const SegmentStepper = (props: BaseStepperProps) => {
	const classes = useStyles(props);
	const { step } = usePageState();

	const pages = usePages();

	var count = -1;

	const displayIndexArr: number[] = pages.map(p => {
		if (p.displayInStepper) {
			count += 1;
			return count;
		}
		return -1;
	});

	const getDisplayNum = (step: number) => {
		if (step === 0 && displayIndexArr[step] === -1) {
			return -1;
		}
		return displayIndexArr[step] === -1
			? getDisplayNum(step - 1)
			: displayIndexArr[step] + 1;
	};

	if (displayIndexArr[step] === -1) {
		if (getDisplayNum(step) === -1 || getDisplayNum(step) >= count + 1) {
			return <Fragment />;
		}
	}

	return (
		<Grid container justifyContent="center" className={classes.outer}>
			<Grid item xs={12} md={8} direction="column" className={classes.box}>
				<Typography variant="body1">
					<FormattedMessage
						id="dploy.segment-stepper.step"
						defaultMessage={"Step "}
					/>

					<FormattedNumber value={getDisplayNum(step)} />
					<FormattedMessage
						id="dploy.segment-stepper.of"
						defaultMessage={" of "}
					/>
					<FormattedNumber value={count + 1} />
				</Typography>
				<Stepper
					activeStep={getDisplayNum(step) - 1}
					connector={<Fragment />}
					className={classes.root}
				>
					{pages.map(page => {
						return page.displayInStepper ? (
							<Step key={page.pageTitle} className={classes.step}>
								<StepLabel
									StepIconProps={{ icon: "" }}
									StepIconComponent={ColorlibStepIcon}
								/>
							</Step>
						) : null;
					})}
				</Stepper>
			</Grid>
		</Grid>
	);
};
