import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	Grid,
	IconButton,
	Typography
} from "@material-ui/core";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import { WholesaleCar } from "@ploy-lib/rest-resources";
import {
	ExpandableTable,
	ExpandableTableProps,
	PendingButton
} from "@ploy-ui/core";
import { SharedCheckedSet } from "./WholesaleOverviewCheckbox";

interface WholesaleOverviewFulfillmentConfirmationProps {
	open: boolean;
	setOpen: (val: boolean) => void;
	cars: WholesaleCar[];
	redeemApplications: (cars: WholesaleCar[]) => any;
}

const WholesaleOverviewFulfillmentConfirmation = (
	props: WholesaleOverviewFulfillmentConfirmationProps
) => {
	const { open, setOpen, cars, redeemApplications } = props;

	const { checkedSet, removeAllChecked } = SharedCheckedSet();

	const intl = useIntl();

	const FormattedHeaderText = (
		<FormattedMessage
			id="vendor.wholesale.fulfill-selected-apps"
			description="Fullfill selected apps"
			defaultMessage="Fulfill withdrawal"
		/>
	);

	return (
		<Dialog open={open}>
			<Card>
				<CardHeader
					title={FormattedHeaderText}
					action={
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					}
				/>
				<CardContent>
					<Grid
						container
						spacing={2}
						direction="column"
						justifyContent="center"
						alignItems="stretch"
					>
						<Grid item>
							<ExpandableTable
								idColumn="ApplicationNumber"
								tableData={cars as WholesaleCar[]}
								columnData={columnData}
								expandable={false}
							/>
						</Grid>
						<Grid item>
							<Typography gutterBottom variant="subtitle1">
								<FormattedMessage
									id="vendor.wholesale.amount-to-fulfill"
									description="Amount to fulfill"
									defaultMessage="Amount to fulfill"
								/>
							</Typography>
							<Typography gutterBottom variant="h6">
								{intl.formatNumber(
									cars.reduce((sum, car) => sum + car.LoanAmount, 0),
									{
										maximumFractionDigits: 0
									}
								)}
								,-
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions disableSpacing>
					<PendingButton
						color="secondary"
						style={{ marginLeft: "auto" }}
						onClick={() => {
							redeemApplications(
								cars.filter(x => checkedSet.has(x.ApplicationNumber))
							);
							removeAllChecked();
							setOpen(false);
						}}
					>
						<FormattedMessage
							id="vendor.wholesale.fulfill-all-in-list"
							description="Fulfill apps"
							defaultMessage="Fulfill withdrawal"
						/>
					</PendingButton>
				</CardActions>
			</Card>
		</Dialog>
	);
};

const columnData: ExpandableTableProps<WholesaleCar, any>["columnData"] = [
	{
		id: "ApplicationNumber",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-appno"
				defaultMessage="App. number"
			/>
		)
	},
	{
		id: "ObjectDescription",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-desc"
				defaultMessage="Description"
			/>
		)
	},
	{
		id: "RegistrationNumber",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-regno"
				defaultMessage="Reg. number"
			/>
		)
	},
	{
		id: "LoanAmount",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-loanamount"
				defaultMessage="Loan amount"
			/>
		),
		component: props => (
			<div>
				<FormattedNumber
					value={props.rowData.LoanAmount}
					maximumFractionDigits={0}
				/>
				,-
			</div>
		)
	}
];

export { WholesaleOverviewFulfillmentConfirmation };
