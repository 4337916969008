﻿/* eslint-disable */

export function bootstrapZendesk(key: string) {
	window["zE"] ||
		(function (d, s) {
			var z = (window["zE"] = function () {}),
				$ = (z["s"] = d.createElement(s)),
				e = d.getElementsByTagName(s)[0];
			$["src"] = "https://static.zdassets.com/ekr/snippet.js?key=" + key;
			e.parentNode && e.parentNode.insertBefore($, e);
		})(document, "script");
}

export function setupZendeskIndentity(
	username: string,
	email: string,
	tags: string[]
) {
	window["zE"](function () {
		window["$zopim"](function () {
			window["$zopim"].livechat.setName(username);
			window["$zopim"].livechat.setEmail(email);
			tags.forEach(tag => {
				window["$zopim"].livechat.addTags(tag);
			});
		});
	});
}

export function conditionallyClearZendesk() {
	if (window["zE"]) {
		window["zE"](function () {
			window["$zopim"](function () {
				window["$zopim"].livechat.endChat();
				window["$zopim"].livechat.clearAll();
				const webWidget = document.querySelectorAll("#webWidget");

				for (let node of webWidget) {
					node.parentNode && node.parentNode.removeChild(node);
				}

				window["zE"] = undefined;
			});
		});
	}
}
