import {
	RadioGroupProps,
	SelectionField,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";
import {
	useParsedQuery,
	createSearchParams,
	useAbsolutePath
} from "@ploy-lib/routing";
import { ProductResource } from "@ploy-lib/rest-resources";
import { Field, useCalculationFields } from "@ploy-lib/calculation";
import { useResource } from "@rest-hooks/core";
import { useAppLoad } from "../../appLoadContext";

const variants = [
	"standard",
	"switch",
	"button",
	"toggleButton",
	"toggleButtonNoIcons"
] as const;
const colors = ["primary", "secondary", "default"] as const;

export const ProductSelector = (
	props: RadioGroupProps<{ key: string; value: string }>
) => {
	const {
		field: { value: currentProductCode }
	} = props;
	const { ext: queryParams, cookieless } = useParsedQuery<{
		ext: Record<string, string>;
		cookieless: string;
	}>();

	const { formContext } = useAppLoad();

	const productList = useResource(ProductResource.list(), {
		active: true,
		creatable: true,
		external: true,
		calculator: true,
		formContext: formContext
	});

	const basepath = useAbsolutePath(".");
	const fields: (Field & { formName: string })[] = [
		{
			namespace: "Calculator",
			name: "ObjectPrice",
			formName: "objectPrice"
		},
		{
			namespace: "Calculator",
			name: "ObjectPriceExVat",
			formName: "objectPriceExVat"
		},
		{
			namespace: "Calculator",
			name: "EquityPercent",
			formName: "equityPercent"
		},
		{
			namespace: "Calculator",
			name: "TotalTermsCount",
			formName: "termsCount"
		},
		{
			namespace: "Calculator",
			name: "TotalTermsCountInYears",
			formName: "totalTermsCountInYears"
		}
	];

	const calculationFields = useCalculationFields(
		...fields.map(f => ({ name: f.name, namespace: f.namespace }))
	);

	const doProductChange = (externalCode: string): void => {
		const product = productList.find(p => p.externalCode == externalCode);
		const { ext: fieldValues } = calculationFields.reduce<{
			ext: Record<string, string>;
		}>(
			(acc, field, index) => {
				const value = field?.value?.toString() ?? field?.fieldValue?.toString();
				if (typeof value !== "undefined") {
					if (
						product?.type === "Leasing" &&
						fields[index].name === "ObjectPrice"
					) {
						// From loan into leasing
						acc.ext["objectPriceExVat"] = value;
					} else if (
						product?.type === "Loan" &&
						fields[index].name === "ObjectPriceExVat"
					) {
						// From leasing into loan
						acc.ext["objectPrice"] = value;
					} else {
						acc.ext[fields[index].formName] = value;
					}
				}

				return acc;
			},
			{ ext: {} }
		);

		// Remove object. Don't want this to follow to next product.
		if (queryParams.categoryCombined) {
			delete queryParams.categoryCombined;
		}
		if (queryParams.objectTypeId) {
			delete queryParams.objectTypeId;
		}
		if (queryParams.objectSubTypeId) {
			delete queryParams.objectSubTypeId;
		}

		var newQueryParams = {
			ext: { ...queryParams, ...fieldValues },
			cookieless,
			formContext
		};

		let urlWithNextPage = encodeURI(
			decodeURI(basepath).replace(
				new RegExp(currentProductCode, "ig"),
				externalCode
			)
		);

		window.location.href = `${urlWithNextPage}?${createSearchParams(
			newQueryParams
		)}`;
	};

	return (
		<SelectionField<{ key: string; value: string }>
			{...props}
			customOnChange={selected => {
				doProductChange(selected.toString());
			}}
			items={productList.map(p => ({ key: p.externalCode, value: p.name }))}
		/>
	);
};

ProductSelector.displayName = "ProductSelector";

export const EditorProductSelector = identityRecordOfFieldEditorOptions({
	ProductSelector: {
		editableOptions: {
			variant: variants,
			color: colors,
			horizontal: true,
			multiple: false,
			optionPairs: false
		}
	}
});
