import React, { Suspense } from "react";
import Section, { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { Grid, Typography, Theme, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useAppLoad } from "../../appLoadContext";
import { DployTable, DployColumn, ErrorPageContent } from "@ploy-ui/core";
import { useResource } from "@rest-hooks/core";
import {
	ApplicationFormalitiesResource,
	ApplicationFormalities
} from "@ploy-lib/rest-resources";
import { ErrorHandler } from "@ploy-lib/core";

addRegisteredSectionLayout({
	name: "FormalityControlSection",
	displayName: "FormalityControlSection",
	settings: {}
});

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			headerMarginTop: theme.spacing(1)
		}
	}),
	{ name: "DployFormSection" }
);

const columns: DployColumn<ApplicationFormalities>[] = [
	{
		field: "key",
		title: (
			<FormattedMessage
				id="dploy.formalitiyControlSection.key.header"
				defaultMessage="Checkpoint group"
			/>
		),
		render: data => <Typography variant="body2">{data.key}</Typography>
	},
	{
		field: "checkpoint",
		title: (
			<Grid container style={{ justifyContent: "space-between" }}>
				<Grid item>
					<FormattedMessage
						id="dploy.formalitiyControlSection.checkpoint.header"
						defaultMessage="Checkpoint"
					/>
				</Grid>
				<Grid item>
					<FormattedMessage
						id="dploy.formalitiyControlSection.checkpoint.date"
						defaultMessage="Date Done"
					/>
				</Grid>
			</Grid>
		),
		render: data => (
			<Grid item container>
				{data.checkpoints.map(checkpoint => (
					<Grid
						key={checkpoint.formalitiesCheckId}
						item
						container
						alignItems="center"
						style={{
							justifyContent: "space-between",
							color: checkpoint.checkItem ? "inherit" : "red"
						}}
					>
						<Typography variant="body2">
							{checkpoint.formalitiesCheckName}
						</Typography>
						<Typography variant="body2">
							{checkpoint.checkItem ? (
								checkpoint.originalCheckedDate
							) : (
								<FormattedMessage
									id="dploy.formalityControlSection.checkpoint.missing"
									defaultMessage="Not executed"
								/>
							)}
						</Typography>
					</Grid>
				))}
			</Grid>
		)
	}
];

const FormalityControlSectionInternal = (props: SectionProps) => {
	const sectionClasses = useStyles(props);
	const { className, onClick, fields } = props;

	const { applicationNumber } = useAppLoad();

	const formalities = useResource(ApplicationFormalitiesResource.list(), {
		applicationNumber: applicationNumber
	});

	return (
		<div className={className} onClick={onClick}>
			<Suspense fallback={null}>
				<DployTable
					columns={columns}
					data={formalities.map(f => ({ ...f, id: f.pk() }))}
					listCellsBreakpoint={"xs"}
					options={{
						search: false,
						grouping: false,
						showEmptyDataSourceMessage: false,
						emptyRowsWhenPaging: false,
						paging: false,
						header: true,
						toolbar: false,
						sorting: false,
						draggable: false
					}}
				/>
				<Section className={sectionClasses.root} {...fields} {...props} />
			</Suspense>
		</div>
	);
};

const FormalityControlSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={
					<span>{FormalityControlSection.displayName} failed to render.</span>
				}
			/>
		)}
	>
		<FormalityControlSectionInternal {...props} />
	</ErrorHandler>
);

FormalityControlSection.displayName = "DployFormalityControlSection";

export { FormalityControlSection };
