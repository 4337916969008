import { useCallback, useEffect, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import Section, { SectionProps } from "../Section";
import { useCalculationFields } from "@ploy-lib/calculation";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import {
	ApplicationStatus,
	CustomGuiFieldHandling,
	TemplateField
} from "@ploy-lib/types";
import { useAppLoad } from "../../appLoadContext";
import { useFetcher } from "@rest-hooks/core";
import {
	ApplicationSignerResource,
	SigningDocumentResource
} from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { v4 as uuid } from "uuid";

type AppStatusGroup =
	| "Generic"
	| "Offer"
	| "ManualUnderwriting"
	| "Approved"
	| "Rejected"
	| "Cancelled"
	| "Replaced"
	| "Finished";

const titleMessages = defineMessages<AppStatusGroup>({
	Generic: {
		id: `form.application-status-simple.title.Generic`,
		description: `Generic status message`,
		defaultMessage: "Application {applicationNumber} is being processed"
	},
	Offer: {
		id: `form.application-status-simple.title.Offer`,
		description: `Application status message if status is Offer`,
		defaultMessage:
			"Application {applicationNumber} is awaiting customer acceptance"
	},
	ManualUnderwriting: {
		id: `form.application-status-simple.title.ManualUW`,
		description: `Application status message if status is ManualUW`,
		defaultMessage:
			"Application {applicationNumber} has been submitted for manual review"
	},
	Approved: {
		id: `form.application-status-simple.title.Approved`,
		description: `Application status message if status is Approved`,
		defaultMessage: "Application {applicationNumber} has been approved"
	},
	Rejected: {
		id: `form.application-status-simple.title.Rejected`,
		description: `Application status message if status is Rejected`,
		defaultMessage:
			"Unfortunately, application {applicationNumber} was rejected"
	},
	Cancelled: {
		id: `form.application-status-simple.title.Cancelled`,
		description: `Application status message if status is Cancelled`,
		defaultMessage:
			"Unfortunately, application {applicationNumber} was cancelled"
	},
	Replaced: {
		id: `form.application-status-simple.title.Replaced`,
		description: `Application status message if status is Replaced`,
		defaultMessage: "Application {applicationNumber} has been replaced"
	},
	Finished: {
		id: `form.application-status-simple.title.Finished`,
		description: `Application status message if application is finished`,
		defaultMessage: "Processing of the application has finished"
	}
});

const mainMessages = defineMessages<AppStatusGroup>({
	Generic: {
		id: `form.application-status-simple.main.Generic`,
		description: `Generic status message`,
		defaultMessage: "Application {applicationNumber} is being processed"
	},
	Offer: {
		id: `form.application-status-simple.main.Offer`,
		description: `Application status message if status is Offer`,
		defaultMessage:
			"Application {applicationNumber} is awaiting customer acceptance"
	},
	ManualUnderwriting: {
		id: `form.application-status-simple.main.ManualUW`,
		description: `Application status message if status is ManualUW`,
		defaultMessage:
			"Application {applicationNumber} has been submitted for manual review"
	},
	Approved: {
		id: `form.application-status-simple.main.Approved`,
		description: `Application status message if status is Approved`,
		defaultMessage: "Application {applicationNumber} has been approved"
	},
	Rejected: {
		id: `form.application-status-simple.main.Rejected`,
		description: `Application status message if status is Rejected`,
		defaultMessage:
			"Unfortunately, application {applicationNumber} was rejected"
	},
	Cancelled: {
		id: `form.application-status-simple.main.Cancelled`,
		description: `Application status message if status is Cancelled`,
		defaultMessage:
			"Unfortunately, application {applicationNumber} was cancelled"
	},
	Replaced: {
		id: `form.application-status-simple.main.Replaced`,
		description: `Application status message if status is Replaced`,
		defaultMessage: "Application {applicationNumber} has been replaced"
	},
	Finished: {
		id: `form.application-status-simple.main.Finished`,
		description: `Application status message if application is finished`,
		defaultMessage: "Processing of the application has finished"
	}
});

addRegisteredSectionLayout({
	name: "ApplicationStatusSectionSimple",
	displayName: "ApplicationStatusSectionSimple",
	settings: {
		editableOptions: {}
	}
});

const maxPollingDurationSeconds = 30 * 60;

const ApplicationStatusSectionSimple = (props: SectionProps) => {
	const { allFields } = props;
	const {
		applicationNumber,
		applicationStatus,
		applicationStatusDescription,
		documentStatusDescription,
		settings,
		productTypeId,
		isEsigned,
		coSignerId,
		refetchApp
	} = useAppLoad();
	const { distributionValues } = settings;

	const interval = useRef<NodeJS.Timeout>();
	const refetchApplicationSigners = useFetcher(
		ApplicationSignerResource.list()
	);
	const refetchDocuments = useFetcher(SigningDocumentResource.list());

	const checkAndRefetch = useCallback(async () => {
		if (applicationNumber) {
			const response = await fetch(
				legacyApiResourceUrl("AppLoad/GetAppStatusDesc/" + applicationNumber)
			);
			const newStatusDesc = await response.json();

			if (
				!newStatusDesc.IsReplaced && //ensures there are no seperate page loads if one replaces application
				(newStatusDesc.AppStatusDesc !== applicationStatusDescription ||
					newStatusDesc.DocStatusDesc !== documentStatusDescription)
			) {
				refetchApp();
				refetchApplicationSigners({
					signerType: 3,
					applicationNumber: applicationNumber
				});
				refetchDocuments({
					applicationNumber
				});
			}
		}
	}, [
		applicationNumber,
		applicationStatusDescription,
		documentStatusDescription,
		refetchApp,
		refetchApplicationSigners,
		refetchDocuments
	]);

	useEffect(() => {
		if (interval.current) clearTimeout(interval.current);
		const delay = 3000;
		const stopDate = new Date();
		stopDate.setSeconds(stopDate.getSeconds() + maxPollingDurationSeconds);
		let stillAlive = true;
		interval.current = setTimeout(async function reschedule() {
			try {
				await checkAndRefetch();
			} catch (e) {
				stillAlive = false;
			}
			// Just in case cleanup of effect happens while function is in await state
			if (stillAlive && new Date() < stopDate)
				interval.current = setTimeout(reschedule, delay);
		}, delay);
		return () => {
			stillAlive = false;
			interval.current && clearTimeout(interval.current);
		};
	}, [checkAndRefetch]);

	const calculationFields = [
		{
			name: "LoanAmount",
			namespace: "Calculator"
		},
		{
			name: "ObjectIcon",
			namespace: "Object"
		}
	];

	const [loanAmount, objectIcon] = useCalculationFields(...calculationFields);

	const productType = productTypeId === 2 ? "leasing" : "loan";
	const intl = useIntl();

	const values = {
		applicationNumber,
		objectIcon: objectIcon?.fieldValue,
		loanAmount: loanAmount?.fieldValue,
		productType: productType,
		isEsigned: isEsigned,
		hasCosigner: !!coSignerId,
		distributionsigningportalurl: () => ""
	};

	if (distributionValues !== undefined)
		Object.keys(distributionValues).forEach(key => {
			values[key.toLowerCase()] = (children: string) => (
				<a
					href={distributionValues[key]}
					rel="noopener noreferrer"
					target="_blank"
				>
					{children}
				</a>
			);
		});

	let statusGroup: AppStatusGroup = "Generic";

	switch (applicationStatus) {
		case ApplicationStatus.ManualUW:
			statusGroup = "ManualUnderwriting";
			break;
		case ApplicationStatus.AutoApproved:
		case ApplicationStatus.ManuallyApproved:
		case ApplicationStatus.ApprovedWithConditions:
			statusGroup = "Approved";
			break;
		case ApplicationStatus.ManuallyRejected:
		case ApplicationStatus.AutoRejected:
			statusGroup = "Rejected";
			break;
		case ApplicationStatus.Cancelled:
		case ApplicationStatus.Expired:
			statusGroup = "Cancelled";
			break;
		case ApplicationStatus.Replaced:
			statusGroup = "Replaced";
			break;
		case ApplicationStatus.Offer:
			statusGroup = "Offer";
			break;
		case ApplicationStatus.Paid:
		case ApplicationStatus.Transferred:
		case ApplicationStatus.Prescore:
		case ApplicationStatus.Processing:
		case ApplicationStatus.Invoiced:
		case ApplicationStatus.Signed:
		case ApplicationStatus.InvestPaid:
		case ApplicationStatus.PartlyPaid:
		case ApplicationStatus.RejectedMissingDocumentation:
		case ApplicationStatus.Fulfilled:
		case ApplicationStatus.RedeemProofOfFinancing:
			statusGroup = "Finished";
			break;
		default:
			break;
	}

	const title = intl.formatMessage(titleMessages[statusGroup], values);
	const mainMessage = intl.formatMessage(mainMessages[statusGroup], values);

	const fields =
		allFields?.map(f => {
			if (f.name === "MainMessage") {
				return {
					...f,
					label: mainMessage
				};
			}
			return f;
		}) || [];

	return <Section {...props} sectionTitle={title} fields={fields} />;
};

ApplicationStatusSectionSimple.displayName = "ApplicationStatusSectionSimple";

export { ApplicationStatusSectionSimple };
