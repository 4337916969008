import { apiResourceUrl } from "@ploy-lib/core";
import { Resource } from "@rest-hooks/rest";

export class ApplicationResource extends Resource {
	readonly applicationNumber: string | null;

	pk() {
		return this.applicationNumber || "";
	}

	static urlRoot = apiResourceUrl("applications");
}
