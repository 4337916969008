import {
	Field,
	useCalculationFields,
	useVariablesData,
	VariableDef
} from "@ploy-lib/calculation";
import { TemplateTableColumn } from "@ploy-lib/types";
import { useEffect, useMemo, useState } from "react";
import { OptionValue } from "./types";

import isEqual from "lodash/isEqual";
import { getIn, useFormikContext } from "formik";
import { isNotNull } from "../../../utils";

export const toFakeCamelCase = (field: string) => {
	return field.charAt(0).toLowerCase() + field.slice(1);
};

export const filterAndStripEntriesStartingWith = <TValue>(
	stringToMatchAndRemove: string,
	values: [string, TValue][]
) =>
	values
		.filter(([name, _]) => name.startsWith(stringToMatchAndRemove))
		.map(([name, value]): [string, TValue] => [
			name.substring(stringToMatchAndRemove.length),
			value
		]);

const defaultNameMaker = (col: TemplateTableColumn) =>
	`${col.tableType}.${col.name}`;

export const useOptionSourceFields = <
	TKey = string | number,
	TValue = string | number
>(
	columnsWithOptionSource: TemplateTableColumn[],
	nameMaker = defaultNameMaker
) => {
	const [optionSourceUnderlyingVariables, optionSourceFields]: [
		VariableDef[],
		Field[]
	] = useMemo(
		() => [
			columnsWithOptionSource.map(col => ({
				variableName: col.optionSource?.fieldName ?? "",
				namespace: col.optionSource?.namespace ?? ""
			})),
			columnsWithOptionSource.map(col => ({
				name: col.optionSource?.fieldName ?? "",
				namespace: col.optionSource?.namespace ?? ""
			}))
		],
		[columnsWithOptionSource]
	);

	const optionSourceCalcUnderlyingVariables = useVariablesData<{}[] | string>(
		...optionSourceUnderlyingVariables
	);

	const optionSourceCalcFields = useCalculationFields<{}[] | string>(
		...optionSourceFields
	);

	const { values } = useFormikContext();
	const optionSourceFormikFields = useMemo(
		() =>
			columnsWithOptionSource.map(col => ({
				variableName: col.optionSource?.fieldName,
				value: getIn(values, col.optionSource?.fieldName || "") as
					| {}[]
					| string
					| undefined
			})),
		[values, columnsWithOptionSource]
	);

	const [optionSources, setOptionSources] = useState<
		Record<string, OptionValue<TKey, TValue>[]>
	>({});

	useEffect(() => {
		if (!optionSourceCalcFields || !optionSourceFormikFields) return;

		const candidate = columnsWithOptionSource.reduce((acc, col) => {
			const value = [
				...optionSourceCalcUnderlyingVariables,
				...optionSourceCalcFields,
				...optionSourceFormikFields
			]
				.filter(isNotNull)
				.find(
					field =>
						Array.isArray(field.value) &&
						field.variableName === col.optionSource?.fieldName
				)?.value;

			acc[nameMaker(col)] = (Array.isArray(value) ? value : []).map(
				fieldValue => ({
					key: fieldValue[toFakeCamelCase(col.optionSource?.key ?? "")] ?? "",
					value:
						fieldValue[toFakeCamelCase(col.optionSource?.value ?? "")] ?? ""
				})
			);
			return acc;
		}, {} as Record<string, OptionValue<TKey, TValue>[]>);

		if (!isEqual(optionSources, candidate)) {
			setOptionSources(candidate);
		}
	}, [
		columnsWithOptionSource,
		nameMaker,
		optionSourceCalcFields,
		optionSourceCalcUnderlyingVariables,
		optionSourceFormikFields,
		optionSources
	]);

	return optionSources;
};

export function ensureStringValues(
	values: OptionValue<string | number, string | number>[]
): OptionValue<string, string>[] {
	return values.map(({ key, value }) => ({
		key: key.toString(),
		value: value.toString()
	}));
}

export const escapeFormikName = (name: string) => name.split(".").join("___");
export const unescapeFormikName = (name: string) => name.split("___").join(".");

export const isNumeric = (value: string | string[] | number) => {
	if (value && Array.isArray(value)) return false;
	return !isNaN(parseFloat(value?.toString()));
};

export const isDate = (value: string | string[] | number) => {
	if (!value || Array.isArray(value)) return false;
	return new Date(value).getMonth() >= 0;
};
