import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import {
	Dialog,
	DialogContent,
	DialogContentText,
	CircularProgress
} from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(
	theme => ({
		paper: {
			backgroundColor: "rgba(0,0,0,0)",
			opacity: "1",
			boxShadow: "none",
			overflow: "hidden"
		},
		backdrop: {
			background: "rgba(0,0,0,0)"
		},
		container: {
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			position: "absolute",
			textAlign: "center"
		},
		icon: {
			fontSize: "4rem"
		},
		content: {
			color: theme.palette.primary.main,
			margin: "1rem 0",
			fontSize: "2rem"
		}
	}),
	{ name: "DployOverlayCircularProgress" }
);

export interface OverlayCircularProgressProps {
	children?: React.ReactNode;
	pending?: boolean;
	loading?: boolean;
	error?: string | { message?: string };
	pendingIconClass?: string;
	errorIconClass?: string;
	className?: string;
}

export function OverlayCircularProgress(props: OverlayCircularProgressProps) {
	const {
		children,
		pending = false,
		loading,
		error,
		pendingIconClass,
		errorIconClass,
		className
	} = props;

	const classes = useStyles(props);

	const pendingIcon = pendingIconClass ? (
		<i
			className={clsx(`fa fa-${pendingIconClass} fa-spin fa-fw`, classes.icon)}
		/>
	) : (
		<CircularProgress size={38} />
	);

	const errorIcon = errorIconClass ? (
		<i
			className={clsx(`fa fa-${errorIconClass} fa-spin fa-fw`, classes.icon)}
		/>
	) : (
		<WarningIcon />
	);

	return (
		<Dialog
			fullScreen
			open={true}
			PaperProps={{
				classes: {
					root: clsx(className, classes.paper)
				}
			}}
			BackdropProps={{
				classes: {
					root: classes.backdrop
				}
			}}
		>
			<DialogContent className={classes.container}>
				{pending || loading ? pendingIcon : error ? errorIcon : null}
				<DialogContentText className={classes.content}>
					{(typeof error !== "string" && error && error.message) || error || (
						<FormattedMessage
							id="dealer.overlay.loadingLabel"
							description="Label for loading overlay"
							defaultMessage="Please wait"
						/>
					)}
					{children}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}

OverlayCircularProgress.displayName = "DployOverlayCircularProgress";
