import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

// https://github.com/facebook/react/issues/14099

const useIsomorphicLayoutEffect =
	typeof window !== "undefined" &&
	typeof window.document !== "undefined" &&
	typeof window.document.createElement !== "undefined"
		? useLayoutEffect
		: useEffect;

export function useEventCallback<T extends (...args: any[]) => any>(fn: T): T {
	const ref: any = useRef(fn);

	// we copy a ref to the callback scoped to the current state/props on each render
	useIsomorphicLayoutEffect(() => {
		ref.current = fn;
	});

	return useCallback(
		(...args: any[]) => ref.current.apply(void 0, args),
		[]
	) as T;
}
