import { TemplateField } from "@ploy-lib/types";
import { createContext, useContext } from "react";
import { CgfResults } from "./fromCGF";

export const cgfTemplateFieldDefaultsContext = createContext<
	CgfResults["mapTemplateFieldDefaults"]
>((i: TemplateField) => [i]);

cgfTemplateFieldDefaultsContext.displayName = "cgfTemplateFieldDefaults";

export function useCgfTemplateFieldDefaults() {
	return useContext(cgfTemplateFieldDefaultsContext);
}

export const CgfTemplateFieldDefaultsProvider =
	cgfTemplateFieldDefaultsContext.Provider;
export const CgfTemplateFieldDefaultsConsumer =
	cgfTemplateFieldDefaultsContext.Consumer;
