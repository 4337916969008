import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import { DashboardGrid } from "@ploy-ui/dashboard";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { MarkdownElement, PendingButton } from "@ploy-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export type ConfirmAction =
	| "save"
	| "save_all"
	| "delete"
	| "cancel"
	| "export_unsaved";

export interface DashboardActionConfirmDialogProps {
	onConfirm?: () => Promise<any> | void;
	onClose?: () => void;
	action?: ConfirmAction;
	changed?: {
		dashboard: DashboardGrid;
		dirty?: Partial<Record<Breakpoint, boolean>>;
	}[];
}

const titleMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.title",
		defaultMessage: "Save"
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.title",
		defaultMessage: "Save all"
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.title",
		defaultMessage: "Delete"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.title",
		defaultMessage: "Cancel"
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.title",
		defaultMessage: "There are unsaved changes"
	}
});

const contentMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.content",
		defaultMessage: `Are you sure you want to save {names}?
{dirty, select,
	none	{}
	other {

---

**New items have been added to {dirty}, but placement and sizing have not been verified for all screen sizes.**

*When new items are added to a dashboard they are added for all screen sizes, but the placement and size adjustment only applies to the selected screen size.
Please switch to the other screen sizes and make sure the items are placed and sized the way you want them to be.*
	}
}`
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.content",
		defaultMessage: `Are you sure you want to save {names}?
{dirty, select,
	none	{}
	other {

---

**New items have been added to {dirty}, but placement and sizing have not been verified for all screen sizes.**

*When new items are added to a dashboard they are added for all screen sizes, but the placement and size adjustment only applies to the selected screen size.
Please switch to the other screen sizes and make sure the items are placed and sized the way you want them to be.*
	}
}`
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.content",
		defaultMessage: "Er du sikker på at du vil slette {names}?"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.content",
		defaultMessage:
			"Er du sikker på at du vil avbryte redigering. Det er ulagrede endringer i {names}."
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.content",
		defaultMessage: `You have unsaved changes. These must be saved before they can be exported.

Are you sure you want to save {names}?
{dirty, select,
	none	{}
	other {

---

**New items have been added to {dirty}, but placement and sizing have not been verified for all screen sizes.**

*When new items are added to a dashboard they are added for all screen sizes, but the placement and size adjustment only applies to the selected screen size.
Please switch to the other screen sizes and make sure the items are placed and sized the way you want them to be.*
	}
}`
	}
});

const confirmMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.confirm.label",
		defaultMessage:
			"{dirty, select, none {Save} other {Save without verifying} }"
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.confirm.label",
		defaultMessage:
			"{dirty, select, none {Save all} other {Save all without verifying} }"
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.confirm.label",
		defaultMessage: "Delete"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.confirm.label",
		defaultMessage: "Discard changes"
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.confirm.label",
		defaultMessage:
			"{dirty, select, none {Save all} other {Save all without verifying} }"
	}
});

const cancelMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.cancel.label",
		defaultMessage: "{dirty, select, none {Cancel} other {Continue editing} }"
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.cancel.label",
		defaultMessage: "{dirty, select, none {Cancel} other {Continue editing} }"
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.cancel.label",
		defaultMessage: "Cancel"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.cancel.label",
		defaultMessage: "Continue editing"
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.cancel.label",
		defaultMessage: "Export without saving"
	}
});

export function DashboardActionConfirmDialog(
	props: DashboardActionConfirmDialogProps
) {
	const { onConfirm, onClose, action, changed } = props;

	const intl = useIntl();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const names = changed
		?.map(({ dashboard }) => `"${dashboard.name}"`)
		.filter(isNotNull);

	const dirty = changed
		?.filter(change => change.dirty && Object.values(change.dirty).some(d => d))
		.map(({ dashboard }) => `"${dashboard.name}"`)
		.filter(isNotNull);

	const values = {
		names: (names && intl.formatList(names, {})) ?? "",
		dirty: (dirty && intl.formatList(dirty, {})) || "none"
	};

	const primaryConfirm = action !== "cancel" && !dirty?.some(x => x);

	return (
		<Dialog
			open={!!action}
			fullScreen={isMobile}
			aria-labelledby="dashboard-action-confirm-dialog-title"
			onClose={onClose}
		>
			<DialogTitle id="dashboard-action-confirm-dialog-title">
				{action && intl.formatMessage(titleMessages[action], values)}
			</DialogTitle>
			<DialogContent>
				<MarkdownElement>
					{action && intl.formatMessage(contentMessages[action], values)}
				</MarkdownElement>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					color={!primaryConfirm ? "primary" : "default"}
					variant={!primaryConfirm ? "contained" : "text"}
				>
					{action && intl.formatMessage(cancelMessages[action], values)}
				</Button>
				<PendingButton
					onClick={onConfirm}
					color={primaryConfirm ? "primary" : "default"}
					variant={primaryConfirm ? "contained" : "text"}
				>
					{action && intl.formatMessage(confirmMessages[action], values)}
				</PendingButton>
			</DialogActions>
		</Dialog>
	);
}
