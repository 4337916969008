import { useCallback } from "react";
import { TextFieldProps as FormikTextFieldProps } from "formik-material-ui";
import {
	identityRecordOfFieldEditorOptions,
	getFieldError,
	defaultGetItemLabel,
	defaultGetItemValue
} from "./utils";
import { useFieldToTextField } from "./TextFields";
import { DployAutocomplete, DployAutocompleteProps } from "./DployAutocomplete";

export interface MuiAutocompleteFieldProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined
> extends Omit<
			FormikTextFieldProps,
			"autoComplete" | "defaultValue" | "variant"
		>,
		Omit<
			DployAutocompleteProps<T, Multiple, DisableClearable>,
			"onChange" | "onBlur" | "value"
		> {}

export function MuiAutocompleteField<
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined
>(props: MuiAutocompleteFieldProps<T, Multiple, DisableClearable>) {
	const {
		onSelectItem,
		valueKey,
		labelKey,
		emptyEqualsZero,
		options,
		formatString,
		italicText,
		boldText,
		form,
		field,
		errorDisplay,
		multiple,
		modalText,
		...rest
	} = props;

	const { getItemValue = defaultGetItemValue } = props;

	const { errors, touched, setFieldValue } = form;

	const onChangeSingle = useCallback<
		NonNullable<DployAutocompleteProps<T, false, DisableClearable>["onChange"]>
	>(
		(e, selected) => {
			const value = selected && getItemValue(selected);
			setFieldValue(field.name, value);
			if (onSelectItem) onSelectItem(selected);
		},
		[getItemValue, setFieldValue, field.name, onSelectItem]
	);

	const onChangeMultiple = useCallback<
		NonNullable<DployAutocompleteProps<T, true, DisableClearable>["onChange"]>
	>(
		(e, selected) => {
			const value = selected ? selected.map(getItemValue) : [];
			setFieldValue(field.name, value);
			if (onSelectItem) onSelectItem(selected as any);
		},
		[getItemValue, setFieldValue, field.name, onSelectItem]
	);

	const { autoComplete, value, ...textFieldProps } = useFieldToTextField(
		props as any
	);

	if (multiple) {
		return (
			<DployAutocomplete
				searchable
				{...rest}
				{...textFieldProps}
				{...getFieldError(errors, touched, field.name, errorDisplay)}
				multiple
				defaultValue={rest.defaultValue as T[] | undefined}
				value={value as T[]}
				onChange={onChangeMultiple}
			/>
		);
	}

	return (
		<DployAutocomplete<T, undefined, DisableClearable>
			searchable
			{...rest}
			{...textFieldProps}
			{...getFieldError(errors, touched, field.name, errorDisplay)}
			defaultValue={
				rest.defaultValue as
					| (DisableClearable extends true ? NonNullable<T> : T | null)
					| undefined
			}
			value={value as DisableClearable extends true ? NonNullable<T> : T | null}
			onChange={onChangeSingle}
		/>
	);
}

export function MuiSelectField<
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined
>(props: MuiAutocompleteFieldProps<T, Multiple, DisableClearable>) {
	const {
		onSelectItem,
		valueKey,
		labelKey,
		emptyEqualsZero,
		options,
		formatString,
		italicText,
		boldText,
		form,
		field,
		errorDisplay,
		multiple,
		modalText,
		disableClearable,
		...rest
	} = props;

	const { getItemValue = defaultGetItemValue } = props;

	const { errors, touched, setFieldValue } = form;

	const onChangeSingle = useCallback<
		NonNullable<DployAutocompleteProps<T, false, DisableClearable>["onChange"]>
	>(
		(e, selected) => {
			const value = selected && getItemValue(selected);
			setFieldValue(field.name, value);
			if (onSelectItem) onSelectItem(selected);
		},
		[getItemValue, setFieldValue, field.name, onSelectItem]
	);

	const onChangeMultiple = useCallback<
		NonNullable<DployAutocompleteProps<T, true, DisableClearable>["onChange"]>
	>(
		(e, selected) => {
			const value = selected ? selected.map(getItemValue) : [];
			setFieldValue(field.name, value);
			if (onSelectItem) onSelectItem(selected as any);
		},
		[getItemValue, setFieldValue, field.name, onSelectItem]
	);

	const { autoComplete, value, ...textFieldProps } = useFieldToTextField(
		props as any
	);

	if (multiple) {
		return (
			<DployAutocomplete
				searchable={false}
				{...rest}
				{...textFieldProps}
				{...getFieldError(errors, touched, field.name, errorDisplay)}
				multiple
				defaultValue={rest.defaultValue as T[] | undefined}
				value={value as T[]}
				onChange={onChangeMultiple}
			/>
		);
	}

	return (
		<DployAutocomplete<T, undefined, DisableClearable>
			searchable={false}
			{...rest}
			{...textFieldProps}
			{...getFieldError(errors, touched, field.name, errorDisplay)}
			defaultValue={
				rest.defaultValue as
					| (DisableClearable extends true ? NonNullable<T> : T | null)
					| undefined
			}
			value={value as DisableClearable extends true ? NonNullable<T> : T | null}
			onChange={onChangeSingle}
			clearable={!disableClearable}
		/>
	);
}

export const EditorAutocompleteFields = identityRecordOfFieldEditorOptions({
	MuiAutocompleteField: {
		editableOptions: {
			optionPairs: true
		}
	},
	MuiSelectField: {
		editableOptions: {
			optionPairs: true,
			multiple: true,
			disableClearable: true
		}
	}
});
