import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface VendorProductGroup {
	readonly vendorProductGroupId: ID;
	readonly description?: string;
}

export class VendorProductGroupResource
	extends BaseResource
	implements VendorProductGroup
{
	readonly vendorProductGroupId: ID;
	readonly description?: string;

	pk() {
		return this.vendorProductGroupId.toString();
	}

	static urlRoot = apiResourceUrl("vendorProductGroups");
}
