import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FullWidthProps } from "./FullWidth";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		pageMaxWidth: (props: FullWidthProps) => ({
			width: props.maxWidth
		}),
		alignRight: {
			padding: theme.spacing(2),
			float: "right",
			width: "fit-content"
		},
		background: (props: any) => ({
			backgroundColor:
				theme.palette?.[props.backgroundColor ?? ""]?.main ??
				props.backgroundColor,
			borderRadius: 20
		})
	}),
	{ name: "DployFormFullWidth" }
);

export const actionsTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormControl: {
			marginNormal: {
				marginTop: 0,
				marginBottom: 0
			}
		}
	} as any
});

// export const mainTheme = (theme: Theme): Theme => ({
// 	...theme,
// 	overrides: {
// 		...theme.overrides,
// 		DployFormSection: {
// 			header: {
// 				marginBottom: theme.spacing(1)
// 			}
// 		}
// 	} as any
// });
