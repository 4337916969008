import { GeneratedCalcRule, CalcUtils } from "./types";
import CalcRulesLib from "@ploy-lib/calc-rules";
import { CalculatorState } from "./createCalculator";
import { IsFieldVisibleIn } from "./createIsFieldVisibleIn";
import { withGetSet, withGetFromField } from "./utils";
import { ValidationHelpers } from "@ploy-lib/validation-helpers";

const CalcRulesLibCore: CalcUtils<any> = {
	mergeFunctions: CalcRulesLib.mergeFunctions,
	formatNumber: CalcRulesLib.formatNumber,
	round: CalcRulesLib.round,
	Financial: CalcRulesLib.Financial,
	FinancialUtils: CalcRulesLib.FinancialUtils,
	DebouncedStartLoanAmountCalculation: () => {},
	AutomaticObjectPriceCalculation: CalcRulesLib.AutomaticObjectPriceCalculation
};

export interface Calculate<TNamespaces extends string, TData> {
	(input: CalculatorState<TNamespaces, TData>, changedField?: string): [
		CalculatorState<TNamespaces, TData>,
		Set<string> | undefined
	];
}

export function createCalculate<TNamespaces extends string, TData>(
	namespace: TNamespaces,
	calcrule: GeneratedCalcRule<TData>,
	validationHelpers: ValidationHelpers,
	isFieldVisibleIn: IsFieldVisibleIn<TNamespaces>
): Calculate<TNamespaces, TData> {
	return function calculate(input, changedField) {
		let {
			values,
			isMissing,
			isWriteLocked,
			isFieldVisible,
			defaultFieldVisibility
		} = input;

		const variableOverrides = {};

		const missing = withGetSet(input.isMissing, isMissing, namespace);

		const data = withGetSet(input.values, values, namespace, (key, value) =>
			missing.set(key, value == null || (value as any).toString() === "")
		);

		const checked = withGetSet(input.isWriteLocked, isWriteLocked, namespace);
		const initial = withGetFromField(input, input.initialFormValues, namespace);

		let triggerChanges: Set<string> | undefined;

		try {
			const calcOutput = calcrule.calculate(
				changedField || "N/A",
				data,
				checked,
				missing,
				initial,
				variableOverrides,
				CalcRulesLibCore,
				{
					default: validationHelpers,
					validation: validationHelpers,
					...validationHelpers
				},
				{
					isFieldVisible: isFieldVisibleIn(
						isFieldVisible,
						defaultFieldVisibility
					)
				}
			);

			triggerChanges = calcOutput.triggerChanges;
		} catch (e: any) {
			console.warn(`${namespace}.calculate() failed: ${e.message}`);
		}

		values = data.getFinal();
		isMissing = missing.getFinal();
		isWriteLocked = checked.getFinal();

		// return input if unmodified
		if (
			values === input.values &&
			isMissing === input.isMissing &&
			isWriteLocked === input.isWriteLocked
		)
			return [input, undefined];

		return [{ ...input, values, isMissing, isWriteLocked }, triggerChanges];
	};
}
