import React from "react";
import { DployTextFieldProps } from "@ploy-ui/form-fields";
import { useField, FieldInputProps } from "formik";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import {
	Omit,
	Box,
	Typography,
	ThemeProvider,
	Theme,
	useTheme,
	SimplePaletteColorOptions,
	InputProps
} from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import Autocomplete, {
	createFilterOptions
} from "@material-ui/lab/Autocomplete";

type FormikColorFieldProps = Omit<
	DployTextFieldProps,
	keyof FieldInputProps<any>
> & { name: string };

export function FormikColorField(props: FormikColorFieldProps) {
	const { name, helperText, ...rest } = props;

	const [field, meta, { setValue }] = useField<string>(name);

	const error = meta.error;

	const showError = Boolean(error) && meta.touched;

	return (
		<ThemePaletteSelect
			{...rest}
			{...field}
			onChange={setValue}
			value={field.value ?? ""}
			error={showError}
			helperText={showError ? error : helperText}
		/>
	);
}

const palettMessages = defineMessages({
	"background.default": {
		id: "theme.palette.background.default",
		defaultMessage: "Bakgrunn standard"
	},
	"background.paper": {
		id: "theme.palette.background.paper",
		defaultMessage: "Bakgrunn kort"
	},
	"primary.main": {
		id: "theme.palette.primary.main",
		defaultMessage: "Primær"
	},
	"primary.light": {
		id: "theme.palette.primary.light",
		defaultMessage: "Primær lys"
	},
	"primary.dark": {
		id: "theme.palette.primary.dark",
		defaultMessage: "Primær mørk"
	},
	"primary.contrastText": {
		id: "theme.palette.primary.contrastText",
		defaultMessage: "Primær kontrasttekst"
	},
	"secondary.main": {
		id: "theme.palette.secondary.main",
		defaultMessage: "Sekundær"
	},
	"secondary.light": {
		id: "theme.palette.secondary.light",
		defaultMessage: "Sekundær lys"
	},
	"secondary.dark": {
		id: "color-picker.option.secondary.dark",
		defaultMessage: "Sekundær mørk"
	},
	"secondary.contrastText": {
		id: "theme.palette.secondary.contrastText",
		defaultMessage: "Sekundær kontrasttekst"
	},
	"grey.50": {
		id: "theme.palette.grey.50",
		defaultMessage: "Grå 50"
	},
	"grey.100": {
		id: "theme.palette.grey.100",
		defaultMessage: "Grå 100"
	},
	"grey.200": {
		id: "theme.palette.grey.200",
		defaultMessage: "Grå 200"
	},
	"grey.300": {
		id: "theme.palette.grey.300",
		defaultMessage: "Grå 300"
	},
	"grey.400": {
		id: "theme.palette.grey.400",
		defaultMessage: "Grå 400"
	},
	"grey.500": {
		id: "theme.palette.grey.500",
		defaultMessage: "Grå 500"
	},
	"grey.600": {
		id: "theme.palette.grey.600",
		defaultMessage: "Grå 600"
	},
	"grey.700": {
		id: "theme.palette.grey.700",
		defaultMessage: "Grå 700"
	},
	"grey.800": {
		id: "theme.palette.grey.800",
		defaultMessage: "Grå 800"
	},
	"grey.900": {
		id: "theme.palette.grey.900",
		defaultMessage: "Grå 900"
	}
});

const filter = createFilterOptions<{ value: string; label: string }>();

type ThemePaletteSelectProps = {
	InputProps?: InputProps;
	value?: string;
	onChange?: (color: string) => void;
	themeOverride?: Partial<Theme> | ((outerTheme: Theme) => Theme);
} & Omit<TextFieldProps, "value" | "onChange" | "variant">;

export function ThemePaletteSelect(props: ThemePaletteSelectProps) {
	const {
		InputProps,
		onChange,
		value,
		name,
		autoComplete,
		themeOverride,
		...other
	} = props;

	const intl = useIntl();
	const tryAugmentColor = useTryAugmentColor();

	const paletteOptions = Object.entries(palettMessages).map(
		([value, message]) => ({
			value,
			label: intl.formatMessage(message)
		})
	);

	const selected =
		paletteOptions.find(o => o.value === value) ??
		(value ? { label: value, value: value } : null);

	return (
		<Autocomplete
			value={selected}
			multiple={false}
			autoHighlight
			onChange={(e, option) => {
				onChange?.(option?.value ?? "");
			}}
			getOptionLabel={o => o.label}
			options={paletteOptions}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				const inputValue = params.inputValue || value;

				const hasExact = filtered.some(o => o.value === inputValue);

				const isValid =
					inputValue && tryAugmentColor({ main: inputValue }) != null;

				if (!hasExact && isValid && inputValue) {
					filtered.push({
						value: inputValue,
						label: inputValue
					});
				}

				return filtered;
			}}
			noOptionsText="Ugyldig farge"
			renderInput={params => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						...InputProps
					}}
					name={name}
					label="Free solo with text demo"
					variant="outlined"
					{...other}
				/>
			)}
			renderOption={item => {
				const preview = (
					<Box
						component="span"
						bgcolor={item.value}
						width={24}
						height={24}
						mr={2}
					/>
				);

				return (
					<>
						{themeOverride ? (
							<ThemeProvider theme={themeOverride}>
								{preview}
							</ThemeProvider>
						) : (
							preview
						)}
						<Typography component="span">{item.label}</Typography>
					</>
				);
			}}
		/>
	);
}

export function useTryAugmentColor() {
	const theme = useTheme();

	return function tryAugmentColor(options: SimplePaletteColorOptions) {
		try {
			return theme.palette.augmentColor(options);
		} catch {
			return undefined;
		}
	};
}
