import clsx from "clsx";
import React from "react";
import { useDrawer } from "../../../contexts";
import { Icon, ListItemIcon, ListItemText, MenuItem } from "@material-ui/core";
import { useIntl, defineMessages } from "react-intl";
import { useMenuItemStyles } from "./MenuItemLink";

export const messages = defineMessages({
	CollapseMenu: {
		id: "dealer.menu.collapse-menu",
		description: "Label for collapsing menu",
		defaultMessage: "Collapse menu"
	}
});

export interface CollapsableDrawerButtonProps {
	classes?: { [key in keyof ReturnType<typeof useMenuItemStyles>]?: string };
	color?: string;
}

function CollapsableDrawerButton(props: CollapsableDrawerButtonProps) {
	const { toggle, isOpen } = useDrawer();
	const classes = useMenuItemStyles(props);
	const intl = useIntl();

	return (
		<MenuItem
			className={clsx(classes.root, {
				[classes.collapsed]: !isOpen
			})}
			classes={{ selected: classes.selected }}
			onClick={toggle}
		>
			{isOpen ? (
				<ListItemIcon className={clsx(classes.icon)}>
					<Icon className={clsx(`fas fa-angle-double-left`, classes.faIcon)} />
				</ListItemIcon>
			) : (
				<ListItemIcon className={clsx(classes.icon)}>
					<Icon className={clsx(`fas fa-angle-double-right`, classes.faIcon)} />
				</ListItemIcon>
			)}
			{isOpen ? (
				<ListItemText
					classes={{
						primary: clsx({ [classes.primaryOpen]: isOpen }, classes.primary),
						secondary: clsx(classes.secondary)
					}}
					className={classes.text}
					primary={intl.formatMessage(messages.CollapseMenu)}
				/>
			) : undefined}
		</MenuItem>
	);
}

export default CollapsableDrawerButton;
