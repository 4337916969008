import React from "react";
import { WholesaleLimit } from "./WholesaleLimit";
import {
	Box,
	Paper,
	Grid,
	Typography,
	makeStyles,
	Theme
} from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { ExportWholesaleLimitButton } from "./ExportWholesaleLimitButton";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";

const useStyles = makeStyles((theme: Theme) => ({
	page: {
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(0)
		}
	},
	paper: {
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1)
		}
	}
}));

const messages = defineMessages({
	exportWholesaleLimitButton: {
		id: "dealer.wholesalelimit.exportbutton",
		description: "Label for exportWholesalelimitButton",
		defaultMessage: "Eksporter oversikt"
	}
});

const WholesaleLimitPage = () => {
	const classes = useStyles();
	const intl = useIntl();
	const { vendorId } = useResource(VulcanDataResource.detail(), {});

	return (
		<Box p={4} className={classes.page}>
			<Box p={4} component={Paper} className={classes.paper}>
				<Grid container spacing={3}>
					<Grid item>
						<Typography gutterBottom variant="h4">
							<FormattedMessage
								id="dealer.wholesalelimit.header"
								description="Header for wholesalelimit page"
								defaultMessage="Wholesale limit"
							/>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<WholesaleLimit vendorId={vendorId} />
					</Grid>

					<Grid item xs={12}>
						<ExportWholesaleLimitButton
							vendorId={vendorId}
							label={intl.formatMessage(messages.exportWholesaleLimitButton)}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export { WholesaleLimitPage };
