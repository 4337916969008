import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { SignerType } from "./ApplicationSignerResource";

export enum SignSource {
	NotSet = 0,
	BankId = 1,
	DigitalSignature = 2
}

export interface SigningDocument {
	readonly esignTaskId?: ID;
	readonly documentCode: string;
	readonly documentIdList: ID[];
	readonly documentName: string;
	readonly signerTypes: SignerType[];
	readonly signerTypesToRead: SignerType[];
	readonly hasSecurityDocument: boolean;
	readonly read: boolean;
	readonly partiallySigned: boolean;
	readonly signed: boolean;
	readonly canResetSignature: boolean;
	readonly allowResetSignature: boolean;
	readonly signers: ID[];
	readonly signSource: SignSource;
	readonly download?: string;
	readonly allowDocumentDownload: boolean;
	readonly applicationNumber: string;
	readonly guarantorMustSign?: number;
	readonly guarantorMustRead?: number;
}

export class SigningDocumentResource
	extends BaseCamelCasedResource
	implements SigningDocument
{
	readonly esignTaskId?: ID;
	readonly documentCode: string = "";
	readonly documentIdList: ID[] = [];
	readonly documentName: string = "";
	readonly hasSecurityDocument: boolean = false;
	readonly read: boolean = false;
	readonly partiallySigned: boolean = false;
	readonly signed: boolean = false;
	readonly canResetSignature: boolean = false;
	readonly allowResetSignature: boolean = false;
	readonly signers: ID[] = [];
	readonly signerTypes: SignerType[] = [];
	readonly signerTypesToRead: SignerType[] = [];
	readonly signSource: SignSource = SignSource.NotSet;
	readonly download?: string;
	readonly allowDocumentDownload: boolean = false;
	readonly applicationNumber: string = "";
	readonly guarantorMustSign?: number;
	readonly guarantorMustRead?: number;

	pk() {
		return this.esignTaskId?.toString();
	}

	static resetSignature<T extends typeof SigningDocumentResource>(this: T) {
		return this.update().extend({
			url: params =>
				this.url(params).replace(
					this.urlRoot,
					legacyApiResourceUrl("Signer/ResetEsignature")
				)
		});
	}

	static urlRoot = legacyApiResourceUrl("Signer/GetAvailableDocuments");
}
