/** Based on suggestions from https://github.com/jquense/yup/issues/345 */
import * as Yup from "yup";

declare module "yup" {
	interface ObjectSchema<
		TIn extends Yup.Maybe<Yup.AnyObject>,
		TContext = Yup.AnyObject,
		TDefault = any,
		TFlags extends Yup.Flags = ""
	> {
		uniqueProperty<P extends keyof TIn>(
			propertyName: P,
			message: string,
			compareFunction?: ((a: TIn[P], b: TIn[P]) => boolean) | null
		): ObjectSchema<TIn, TContext, TDefault, TFlags>;
	}
}

Yup.addMethod(Yup.object, "uniqueProperty", function <
	TIn extends Yup.Maybe<Yup.AnyObject>,
	P extends keyof TIn
>(this: Yup.ObjectSchema<TIn>, propertyName: P, message: string, compareFunction: ((a: TIn[P] | undefined, b: TIn[P] | undefined) => boolean) | null = null) {
	return this.test("unique", message, function (testedObject) {
		if (!Array.isArray(this.parent)) return true;

		if (
			this.parent
				// Exclude the same object (by reference)
				.filter((object: TIn) => object !== testedObject)

				// Check for property match among some of the other objects
				.some((object: TIn) => {
					const objectValue = (object as TIn)?.[propertyName];
					const testedObjectValue = (testedObject as TIn)?.[propertyName];

					return compareFunction
						? compareFunction(objectValue, testedObjectValue)
						: objectValue === testedObjectValue;
				})
		) {
			return this.createError({
				path: `${this.path}.${String(propertyName)}`,
				message
			});
		}

		return true;
	});
});
