import React, { useState } from "react";
import { useResource } from "@rest-hooks/core";
import {
	ID,
	IntegrationClient,
	IntegrationClientResource
} from "@ploy-lib/rest-resources";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { FormattedMessage } from "react-intl";

interface IntegrationSelectProps {
	integrationId?: ID;
	vendorId?: ID;
	setIntegration: (integration: IntegrationClient | null) => void;
}

export const IntegrationSelector = (props: IntegrationSelectProps) => {
	const { integrationId, vendorId, setIntegration } = props;

	const integrations = (useResource(
		IntegrationClientResource.list(),
		vendorId
			? {
					vendorId: vendorId
			  }
			: {}
	) || []) as IntegrationClient[];

	const [selected, setSelected] = useState(
		integrations.find(x => x.integrationClientId === integrationId)
	);

	return (
		<>
			<FormattedMessage
				id="integration.select.integration"
				description="Show applications from integration"
				defaultMessage="Vis saker fra integrasjon:"
			/>
			<DployAutocomplete
				style={{ width: 300 }}
				value={selected}
				items={integrations}
				onChange={(event, value) => {
					setSelected(value ?? undefined);
					setIntegration(value);
				}}
				fullWidth
				getItemLabel={item => item.code}
				disableClearable
			/>
		</>
	);
};
