import React, { Suspense } from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useAppLoad } from "../../appLoadContext";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { BasePlainResource } from "@ploy-lib/rest-resources/es/plain/BasePlainResource";
import { FetchOptions, useResource } from "@rest-hooks/core";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

addRegisteredSectionLayout({
	name: "CommisionSection",
	displayName: "CommisionSection",
	settings: {}
});

interface CommissionSummary {
	RevisionDate: string | null;
	TotalPayoutsVendor: number;
}

class ComissionSummaryResource
	extends BasePlainResource
	implements CommissionSummary
{
	readonly RevisionDate: string | null = null;
	readonly TotalPayoutsVendor: number = 0;

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static getEndpointExtra(): FetchOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: 0
			//invalidIfStale: true
		};
	}

	static urlRoot = legacyApiResourceUrl("Commission/Summary");
}

const CommisionSection = (props: SectionProps) => {
	const { className, onClick } = props;

	return (
		<Box p={2} mb={2} clone>
			<Paper className={className} onClick={onClick}>
				<Grid container spacing={2} wrap="wrap" justifyContent="space-between">
					<Grid item component={Typography}>
						<FormattedMessage
							id="template-form.sections.commissions.column-totals"
							defaultMessage="Totalt beløp, forhandler"
						/>
					</Grid>
					<Grid item component={Typography}>
						<Suspense fallback={<Skeleton variant="text" width="4ch" />}>
							<CommissionValue name="TotalPayoutsVendor">
								{value => (
									<FormattedNumber format="currency" value={value ?? 0} />
								)}
							</CommissionValue>
						</Suspense>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};

interface ComissionValueProps<K extends keyof CommissionSummary> {
	name: K;
	children: (
		value: CommissionSummary[K] | undefined
	) => React.ReactElement | null;
}

function CommissionValue<K extends keyof CommissionSummary>(
	props: ComissionValueProps<K>
) {
	const appLoad = useAppLoad();

	const commissionSummary = useResource(
		ComissionSummaryResource.detail(),
		appLoad.id ? {} : null
	);

	return props.children(commissionSummary?.[props.name]);
}

CommisionSection.displayName = "DployCommisionSection";

export { CommisionSection };
