import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useCalculationFields, Field } from "@ploy-lib/calculation";
import clsx from "clsx";
import { SectionProps } from "../Section";

addRegisteredSectionLayout({
	name: "SummarySection",
	displayName: "SummarySection",
	settings: {
		editableOptions: {}
	}
});

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			margin: "0 auto",
			padding: theme.spacing(1),
			maxWidth: "550px"
		}
	}),
	{ name: "DploySummarySection" }
);

interface SummarySectionProps extends SectionProps {}

export function SummarySection(props: SummarySectionProps) {
	const { allFields = [], className } = props;

	const calculationFields = useCalculationFields(
		...allFields.map(f => ({ name: f.name, namespace: f.namespace } as Field))
	);

	const classes = useStyles(props);

	return (
		<Grid container item xs={2} className={clsx(className, classes.root)}>
			<Grid item xs={12}>
				<Typography variant={"caption"}>{allFields[0]?.label}</Typography>
				<Grid container alignItems={"flex-end"} style={{ paddingTop: "6px" }}>
					<Typography variant={"h2"}>
						{calculationFields[0]?.fieldValue}
					</Typography>
					<Typography>&nbsp;</Typography>
					<Typography>{allFields[0]?.suffix}</Typography>
				</Grid>
			</Grid>
			<Grid item>
				<Typography variant={"body2"}>
					{calculationFields.map((x, i, fields) => {
						if (i === 0) return "";

						const fieldValue = x?.fieldValue;
						if (fieldValue === undefined) return "";

						let splitter = ", ";
						if (i + 1 === fields.length) splitter = "";

						let suffix = allFields[i]?.suffix;
						if (suffix === undefined) suffix = "";

						let label = " " + allFields[i]?.label;
						if (suffix === undefined) label = "";

						return String(fieldValue + suffix + label + splitter);
					})}
				</Typography>
			</Grid>
		</Grid>
	);
}
