import React from "react";
import { PendingIconButton } from "@ploy-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Tooltip } from "@material-ui/core";
import { useEditing } from "../../../contexts";

export function EditAction() {
	const { isEditing, isSaving, edit, save, cancel, isCanceling, isEditable } =
		useEditing();

	if (!isEditable) return null;

	if (isEditing)
		return (
			<>
				<PendingIconButton
					key="save"
					color="inherit"
					onClick={save}
					success={false}
					error={false}
					size="small"
					disabled={isSaving || isCanceling}
				>
					<Tooltip title="Lagre">
						<SaveIcon />
					</Tooltip>
				</PendingIconButton>
				<PendingIconButton
					key="cancel"
					color="inherit"
					onClick={cancel}
					success={false}
					error={false}
					size="small"
					disabled={isSaving || isCanceling}
				>
					<Tooltip title="Avbryt">
						<CancelIcon />
					</Tooltip>
				</PendingIconButton>
			</>
		);

	return (
		<PendingIconButton
			key="edit"
			color="inherit"
			onClick={edit}
			success={false}
			error={false}
			size="small"
		>
			<Tooltip title="Rediger">
				<SettingsIcon />
			</Tooltip>
		</PendingIconButton>
	);
}
