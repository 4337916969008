import { BaseResource } from "./BaseResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export class LogEntryExcerptResource extends BaseResource {
	readonly id: string;
	readonly path: string;
	readonly duration: number;
	readonly statusCode: number;
	readonly timestamp: Date;
	readonly contextMetadata: string;
	readonly traceId: string;

	pk() {
		return this.id;
	}

	static urlRoot = legacyApiResourceUrl("Admin/LiveLogger/logs/{context}");
}

export class LogEntryResource extends BaseResource {
	readonly id: string;
	readonly path: string;
	readonly duration: number;
	readonly request: LoggedMessage;
	readonly response: LoggedMessage;
	readonly statusCode: number;
	readonly timestamp: Date;
	readonly contextMetadata: string;
	readonly logs: LogStatement[];
	readonly traceId: string;

	pk() {
		return this.id;
	}

	static urlRoot = legacyApiResourceUrl("Admin/LiveLogger/logs/{context}/{id}");
}

export class LoggedMessage {
	readonly headers: LoggedHeader[];
	readonly content: string;
}

export class LoggedHeader {
	readonly key: string;
	readonly value: string;
}

export class LogStatement {
	readonly message: string;
	readonly level: string;
	readonly elapsed: number;
}
