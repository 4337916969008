import React from "react";
import { GeneralInformation } from "./GeneralInformation";
import { Box, Paper, Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const GeneralInformationPage = (props: any) => {
	return (
		<Box p={4}>
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<Box p={4} component={Paper}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography gutterBottom variant="h4">
									<FormattedMessage
										id="dealer.generalInfo.header"
										defaultMessage="Generell informasjon"
									/>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<GeneralInformation />
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export { GeneralInformationPage };
