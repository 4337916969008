import React, { useCallback, useMemo, memo } from "react";
import {
	DatePickerProps,
	DateTimePickerProps,
	KeyboardDatePickerProps,
	KeyboardTimePickerProps,
	KeyboardDateTimePickerProps,
	DatePicker,
	KeyboardDatePicker,
	TimePicker,
	KeyboardTimePicker,
	DateTimePicker,
	KeyboardDateTimePicker
} from "@material-ui/pickers";
import { TimePickerProps } from "@material-ui/pickers/TimePicker"; // "@material-ui/pickers/index.d.ts" exports the wrong type as TimePickerProps
import {
	defineMessages,
	IntlShape,
	MessageDescriptor,
	FormatDateOptions,
	useIntl
} from "react-intl";

import { DployTextField } from "../DployTextField";
import { useDateFormat } from "../formatters/date";
import { DateType } from "@date-io/type";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { DateTime } from "luxon";

export const allMessages = defineMessages({
	invalidLabel: {
		id: "form_fields.datetimepicker.invalid",
		description: "Message, appearing when date cannot be parsed",
		defaultMessage: "\\u200B"
	},
	emptyLabel: {
		id: "form_fields.datetimepicker.empty.message",
		description:
			"Message displaying in text field, if null passed (doesn't work in keyboard mode)",
		defaultMessage: "\\u200B"
	},
	invalidDateMessage: {
		id: "form_fields.datetimepicker.invalid_date.message",
		description:
			"Message displaying in text field, if date is invalid (doesn't work in keyboard mode)",
		defaultMessage: "Invalid format: must be {format}"
	},
	maxDateMessage: {
		id: "form_fields.datetimepicker.max_date.message",
		description: "Error message, shown if date is more then maximal date",
		defaultMessage: "Date cannot be after {maxDate, date}"
	},
	minDateMessage: {
		id: "form_fields.datetimepicker.min_date.message",
		description: "Error message, shown if date is less then minimal date",
		defaultMessage: "Date cannot be before {minDate, date}"
	},
	cancelLabel: {
		id: "form_fields.datetimepicker.cancel",
		description: "Label for cancel button on DatePicker",
		defaultMessage: "Cancel"
	},
	clearLabel: {
		id: "form_fields.datetimepicker.clear",
		description: "Label for clear button on DatePicker",
		defaultMessage: "Reset"
	},
	okLabel: {
		id: "form_fields.datetimepicker.ok",
		description: "Label for ok button on DatePicker",
		defaultMessage: "Choose"
	},
	todayLabel: {
		id: "form_fields.datetimepicker.today",
		description: "Label for today button on DatePicker",
		defaultMessage: "Today"
	}
});

function mapToFormatted(messageDefinitions: {
	[key: string]: MessageDescriptor | undefined;
}): (intl: IntlShape, values: any) => { [key: string]: string } {
	return (intl, values) =>
		Object.entries(messageDefinitions)
			.filter(([, m]) => m && m.id)
			.reduce((obj, [key, message]) => {
				if (message != null && message.id) {
					obj[key] =
						intl.messages[message.id] === "<nothing>" // react-intl considers empty string resource as an error
							? ""
							: intl.formatMessage(message, values);
				}
				return obj;
			}, {});
}

export interface IntlOptions extends FormatDateOptions {
	formatType?: "date" | "time";
	minDate?: Date;
	maxDate?: Date;
	allowNull?: boolean;
}

export type PickerProps =
	| DatePickerProps
	| DateTimePickerProps
	| TimePickerProps
	| KeyboardDatePickerProps
	| KeyboardDateTimePickerProps
	| KeyboardTimePickerProps;

export type IntlDateTimePickerProps<T = PickerProps> = IntlOptions & T;

export const withIntlPicker =
	(
		options: {
			keyboard?: boolean;
			messages?: Partial<typeof allMessages>;
		} = {}
	) =>
	<TProps extends PickerProps>(Wrapped: React.ComponentType<TProps>) => {
		const { keyboard, messages = allMessages } = options;

		const formatMessages = mapToFormatted(messages);

		function WrappedComponent({
			format: premadeFormat,
			formatType = "date",
			localeMatcher,
			weekday,
			era,
			year,
			month,
			day,
			hour,
			minute,
			second,
			timeZoneName,
			formatMatcher,
			hour12,
			timeZone,
			minDate = new Date("1900"),
			maxDate = new Date("2100"),
			placeholder,
			value,
			variant,
			autoOk,
			...props
		}: TProps & IntlOptions) {
			const intl = useIntl();

			const theme = useTheme();
			const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

			const { views, format, ampm, resolvedOptions } = useDateFormat(
				props as FormatDateOptions,
				formatType
			);

			// default placeholder is the format
			placeholder = useMemo(
				() => placeholder || format.toUpperCase(),
				[placeholder, format]
			);

			// TODO: Refactor backend to always send ISO format date values. For now we need to test and hack-convert if invalid
			if (value) {
				// Dates were used to be saved as UTC time in intern-web (See InputField.cs Date)
				// Convert these dates to valid iso8601 by replacing space with T
				let date = DateTime.fromISO(value.toString().replace(" ", "T"));

				if (!date.isValid)
					date = DateTime.fromFormat(value.toString(), "dd.MM.yyyy");

				value = date.isValid ? date.toISO() : null;
			} else {
				// value must not be empty string
				value = null;
			}

			// default variant based on screen size
			variant = variant || (smallScreen ? "dialog" : "inline");
			autoOk = autoOk != null ? autoOk : variant === "inline";

			const messages = useMemo(() => {
				const formatted = formatMessages(intl, {
					minDate,
					maxDate,
					format
				});

				const filteredMessages: Partial<typeof formatted> = {};

				if (props.clearable) filteredMessages.clearLabel = formatted.clearLabel;
				if (props.showTodayButton)
					filteredMessages.todayLabel = formatted.todayLabel;
				if (!autoOk) filteredMessages.okLabel = formatted.okLabel;
				if (variant === "dialog")
					filteredMessages.cancelLabel = formatted.cancelLabel;

				if (minDate || maxDate)
					filteredMessages.invalidDateMessage = formatted.invalidDateMessage;
				if (maxDate) filteredMessages.maxDateMessage = formatted.maxDateMessage;
				if (minDate) filteredMessages.minDateMessage = formatted.minDateMessage;

				return filteredMessages;
			}, [
				autoOk,
				format,
				intl,
				maxDate,
				minDate,
				props.clearable,
				props.showTodayButton,
				variant
			]);

			if (!keyboard) {
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const labelFunc = useCallback(
					(date: DateType | null, invalidLabel = "") => {
						if (date == null) return invalidLabel;

						const jsDate =
							typeof date.toJSDate === "function"
								? date.toJSDate()
								: new Date(date.toString());

						if (!jsDate) return invalidLabel;

						const formatted =
							formatType === "time"
								? intl.formatTime(jsDate, resolvedOptions)
								: intl.formatDate(jsDate, resolvedOptions);

						if (!formatted) return invalidLabel;

						return formatted;
					},
					[formatType, intl, resolvedOptions]
				);

				return (
					<Wrapped
						TextFieldComponent={DployTextField}
						views={views}
						ampm={ampm}
						{...messages}
						{...(props as unknown as TProps)}
						autoOk={autoOk}
						variant={variant}
						value={value}
						placeholder={placeholder}
						labelFunc={labelFunc}
					/>
				);
			}

			return (
				<Wrapped
					TextFieldComponent={DployTextField}
					views={views}
					format={format}
					ampm={ampm}
					minDate={minDate}
					maxDate={maxDate}
					{...messages}
					{...(props as unknown as TProps)}
					autoOk={autoOk}
					variant={variant}
					value={value}
					placeholder={placeholder}
				/>
			);
		}

		WrappedComponent.displayName = `withIntlPicker(${
			Wrapped.displayName ?? Wrapped.name
		})`;

		return WrappedComponent;
	};

export const IntlDatePicker: React.FC<
	IntlDateTimePickerProps<DatePickerProps>
> = memo(withIntlPicker()(DatePicker));

export const IntlKeyboardDatePicker: React.FC<
	IntlDateTimePickerProps<KeyboardDatePickerProps>
> = memo(
	withIntlPicker({
		keyboard: true
	})(KeyboardDatePicker)
);

export const IntlTimePicker: React.FC<
	IntlDateTimePickerProps<TimePickerProps>
> = memo(withIntlPicker()(TimePicker));

export const IntlKeyboardTimePicker: React.FC<
	IntlDateTimePickerProps<KeyboardTimePickerProps>
> = memo(
	withIntlPicker({
		keyboard: true
	})(KeyboardTimePicker)
);

export const IntlDateTimePicker: React.FC<
	IntlDateTimePickerProps<DateTimePickerProps>
> = memo(withIntlPicker()(DateTimePicker));

export const IntlKeyboardDateTimePicker: React.FC<
	IntlDateTimePickerProps<KeyboardDateTimePickerProps>
> = memo(
	withIntlPicker({
		keyboard: true
	})(KeyboardDateTimePicker)
);
