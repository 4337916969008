import React, { useMemo, memo } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Rifm } from "rifm";
import { useNumberFormatter } from "../formatters/number";
import { WrappedComponentProps, FormatNumberOptions } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { DployTextFieldProps, DployTextField } from "../DployTextField";
import { Overwrite } from "../types";
import { extractNumberFormat } from "../formatters/extractNumberFormat";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type IntlNumberFieldProps = Overwrite<
	DployTextFieldProps,
	{
		value?: number;
		onChange?: (value: number) => void;
	} & FormatNumberOptions
> & {
	suffix?: string;
	prefix?: string;
	percentValueAsFraction?: boolean;
	emptyEqualsZero?: boolean;
	formatString?: string;
	allowNegative?: boolean;
	nativeOnChange?: React.EventHandler<React.ChangeEvent>;
};

const useStyles = makeStyles(
	{
		input: {
			textAlign: ({ textAlign }: IntlNumberFieldProps) =>
				typeof textAlign === "string"
					? (textAlign as CSSProperties["textAlign"])
					: "right"
		}
	},
	{ name: "DployIntlNumberField" }
);

export const IntlNumberField: React.ComponentType<IntlNumberFieldProps> = memo(
	(props: IntlNumberFieldProps & WrappedComponentProps) => {
		const {
			fieldName: fieldNameNullable,
			prefix: propPrefix,
			suffix: propSuffix,
			format: intlFormat,
			style,
			maximumSignificantDigits,
			minimumSignificantDigits,
			maximumFractionDigits,
			minimumFractionDigits,
			minimumIntegerDigits,
			useGrouping,
			localeMatcher,
			currency,
			currencyDisplay,
			value: numberValue,
			onChange: onNumberChange,
			nativeOnChange,
			onBlur: onNumberBlur,
			onFocus: onNumberFocus,
			emptyEqualsZero,
			formatString,
			percentValueAsFraction,
			textAlign,
			allowNegative,
			...rest
		} = props;

		const fieldName = fieldNameNullable ?? { name: "unknown" };

		const fromFormatString = useMemo(
			() => extractNumberFormat({}, formatString),
			[formatString]
		);

		const {
			value,
			onChange,
			format,
			onBlur,
			onFocus,
			refuse,
			formatPrefix,
			formatSuffix
		} = useNumberFormatter(
			fieldName,
			{ ...props, ...fromFormatString },
			numberValue,
			emptyEqualsZero,
			onNumberChange,
			onNumberBlur,
			onNumberFocus,
			undefined,
			undefined,
			percentValueAsFraction,
			allowNegative
		);

		const classes = useStyles(props);

		var suffix = propSuffix || formatSuffix;
		var prefix = propPrefix || formatPrefix;

		if (suffix === prefix) {
			suffix = undefined;
		}
		return (
			<Rifm refuse={refuse} value={value} onChange={onChange} format={format}>
				{rifmProps => (
					<DployTextField
						{...rest}
						{...rifmProps}
						fieldName={fieldName}
						type="tel"
						onBlur={onBlur}
						onFocus={onFocus}
						InputProps={{
							classes: classes,
							endAdornment: suffix && (
								<InputAdornment position="end">{suffix}</InputAdornment>
							),
							startAdornment: prefix && (
								<InputAdornment position="start">{prefix}</InputAdornment>
							)
						}}
					/>
				)}
			</Rifm>
		);
	}
);
