import { Guarantor, GuarantorResource } from "@ploy-lib/rest-resources";
import { FieldType, TableColumnMetadata } from "./types";

export class GuarantorTableSchema extends GuarantorResource {
	static dployTesterContext: string = "Guarantor";

	static metaData: Partial<Record<keyof Guarantor, TableColumnMetadata>> = {
		customerNumberCombined: { fieldType: FieldType.Text }
	};
}
