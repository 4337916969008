import React from "react";
import { OverlayCircularProgress } from "@ploy-ui/core";
import { styled } from "@material-ui/core";
import { useThemeComponent } from "@ploy-ui/tenants";

const DarkOverlayCircularProgress = styled(OverlayCircularProgress)({
	background: "rgba(0,0,0,0.3)"
});

export type LoadingOverlayProps = {
	pending?: boolean;
};

export const LoadingOverlay = (props: LoadingOverlayProps) => {
	const { pending } = props;
	const loadingComponent = useThemeComponent("LoadingOverlay");

	return (
		loadingComponent?.(pending) || (
			<DarkOverlayCircularProgress pending={pending} />
		)
	);
};

export default LoadingOverlay;
