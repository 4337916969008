import React from "react";
import { useResource } from "@rest-hooks/core";
import { LoginResource } from "@ploy-lib/rest-resources";

export default function LoginRedirectLink() {
	const { redirectLink } = useResource(LoginResource.status(), {});

	return redirectLink ? (
		<a href={redirectLink.href}>{redirectLink.label}</a>
	) : null;
}

LoginRedirectLink.displayName = "LoginRedirectLink";
