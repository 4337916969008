import React, { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useFileToError, isImage } from "./helpers";
import { isNotNull } from "../utils";
import { DragStateButton, DropContainer, GridGrow } from "./styles";
import { DragStateProps, DropzoneProps, FileOrSrc } from "./types";
import { DropEvent, useDropzone } from "react-dropzone";
import { ImagePreview } from "./ImagePreview";
import { FileView } from "./FileView";

export function Dropzone(props: DropzoneProps) {
	const {
		files = [],
		onChange,
		onError,
		onSelect,
		selected,
		onDrop: drop,
		onDragEnter: dragEnter,
		onFileDialogCancel: fileDialogCancel,
		imgBackground,
		children,
		label,
		variant = "area",
		previewImages,
		className,
		...rest
	} = props;

	const fileToError = useFileToError(rest);

	const onDrop = useCallback(
		(accepted: File[], rejected: File[], event: DropEvent) => {
			if (drop) {
				drop(accepted, rejected, event);
			}
			if (onChange && accepted.length > 0) {
				onChange(props.multiple ? [...files, ...accepted] : [accepted[0]]);
			}
			if (onError) {
				const fileErrors = rejected.map(fileToError).filter(isNotNull);
				onError(fileErrors.length > 0 ? fileErrors : undefined);
			}
		},
		[drop, onChange, onError, props.multiple, files, fileToError]
	);

	const onDragEnter = useCallback(
		e => {
			if (dragEnter) dragEnter(e);
			if (onError) onError(undefined);
		},
		[dragEnter, onError]
	);

	const onFileDialogCancel = useCallback(() => {
		if (fileDialogCancel) fileDialogCancel();
		if (onError) onError(undefined);
	}, [fileDialogCancel, onError]);

	const onRemove = useCallback(
		(file: FileOrSrc) => {
			if (onChange) onChange(files.filter(f => f !== file));
			if (onError) onError(undefined);
		},
		[files, onChange, onError]
	);

	const dropzone = useDropzone({
		...rest,
		onDrop,
		onDragEnter,
		onFileDialogCancel,
		noKeyboard: variant === "button"
		// noClick: variant === "button"
	});

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		getRootProps,
		getInputProps,
		open
	} = dropzone;

	const rootProps = {
		...getRootProps(),
		...(variant === "button" ? { onClick: undefined } : undefined) // workaround for noClick not working.
	};

	const dragState: DragStateProps = {
		isDragActive,
		isDragAccept,
		isDragReject
	};

	let content: React.ReactNode;

	switch (variant) {
		case "button": {
			content = (
				<DragStateButton
					variant="outlined"
					{...dragState}
					size="large"
					onClick={open}
				>
					{label}
				</DragStateButton>
			);
			break;
		}
		default: {
			content = (
				<Typography color="inherit" component="span">
					{label}
				</Typography>
			);
			break;
		}
	}

	const preview = (
		<Grid container spacing={1} alignItems="stretch">
			{files.map((f, i) => {
				const file = (
					<FileView
						file={f}
						onRemove={onRemove}
						onSelect={onSelect}
						selected={f === selected}
						imgBackground={imgBackground}
					/>
				);

				const preview = (
					<ImagePreview
						file={f}
						onRemove={onRemove}
						onSelect={onSelect}
						selected={f === selected}
						imgBackground={imgBackground}
					/>
				);

				return (
					<Grid item key={i}>
						{previewImages &&
						(f instanceof File ? f.type.startsWith("image/") : isImage(f))
							? preview
							: file}
					</Grid>
				);
			})}
			<GridGrow item grow={1}>
				{content}
			</GridGrow>
		</Grid>
	);

	return (
		<DropContainer
			variant={variant}
			{...dragState}
			{...rootProps}
			className={className}
		>
			<input {...getInputProps()} />

			{typeof children === "function" ? (
				children(dropzone, preview)
			) : (
				<>
					{children}
					{preview}
				</>
			)}
		</DropContainer>
	);
}

Dropzone.displayName = "DployDropzone";
