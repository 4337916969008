import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	makeStyles,
	Theme,
	Typography
} from "@material-ui/core";
import { WholesaleCar } from "@ploy-lib/rest-resources";
import { PendingIconButton, PendingButton } from "@ploy-ui/core";
import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { SharedCheckedSet } from "./WholesaleOverviewCheckbox";
import CloseIcon from "@material-ui/icons/Close";
import { WholesaleOverviewFulfillmentConfirmation } from "./WholesaleOverviewFulfillmentConfirmation";

export interface WholesaleOverviewFulfillSectionProps {
	cars?: WholesaleCar[];
	redeemApplications: (cars: WholesaleCar[]) => any;
}

const useStyles = makeStyles((theme: Theme) => ({
	cardHeader: {
		paddingBottom: 0
	},
	cardContent: {
		paddingTop: 0
	}
}));

const WholesaleOverviewFulfillSection = (
	props: WholesaleOverviewFulfillSectionProps
) => {
	const { cars = [], redeemApplications } = props;
	const { checkedSet, removeAllChecked } = SharedCheckedSet();

	const [dialogOpen, setDialogOpen] = useState(false);

	let sumTotal = 0;
	cars.forEach(x => {
		if (checkedSet.has(x.ApplicationNumber)) sumTotal = sumTotal + x.LoanAmount;
	});

	const classes = useStyles();

	return (
		<>
			{checkedSet.size ? (
				<>
					<WholesaleOverviewFulfillmentConfirmation
						open={dialogOpen}
						setOpen={setDialogOpen}
						cars={cars.filter(x => checkedSet.has(x.ApplicationNumber))}
						redeemApplications={redeemApplications}
					/>
					<Card elevation={3}>
						<CardHeader
							title={
								<Typography variant="h5" style={{ fontWeight: "bold" }}>
									<FormattedMessage
										id="vendor.wholesale.selected-limits"
										description="Label for selected limits"
										defaultMessage="{amount} {amount, select, 1 {agreement} other {agreements}} selected"
										values={{ amount: checkedSet.size }}
									/>
								</Typography>
							}
							action={
								<PendingIconButton onClick={removeAllChecked}>
									<CloseIcon />
								</PendingIconButton>
							}
							className={classes.cardHeader}
						/>
						<CardContent className={classes.cardContent}>
							<Grid container spacing={2} justifyContent="space-between">
								<Grid item style={{ alignSelf: "center" }}>
									<Typography variant="subtitle1">
										<FormattedNumber
											value={sumTotal}
											maximumFractionDigits={0}
										/>
										,-
									</Typography>
								</Grid>
								<Grid item>
									<PendingButton
										onClick={() => {
											setDialogOpen(true);
										}}
										color="secondary"
										variant="contained"
									>
										<FormattedMessage
											id="vendor.wholesale.fulfill-button"
											description="Fulfill button"
											defaultMessage="Fulfill withdrawal"
										/>
									</PendingButton>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</>
			) : (
				<></>
			)}{" "}
		</>
	);
};

export { WholesaleOverviewFulfillSection };
