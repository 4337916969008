import type { Blocker, History, Transition } from "history";
import { ContextType, useContext, useEffect, useRef } from "react";
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext
} from "react-router-dom";

interface Navigator extends BaseNavigator {
	block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
	navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1094106064
 */
export function useBlocker(blocker: Blocker, when = true) {
	const { navigator } = useContext(
		NavigationContext
	) as NavigationContextWithBlock;

	//main tweak required to OP was wrapping unblock in ref so we're only pushing one blocker on the stack per each useBlocker hook declaration (i.e. not one for every render)
	const refUnBlock = useRef<() => void>();

	useEffect(() => {
		if (!when) {
			refUnBlock.current?.();
			return;
		}

		if (!refUnBlock.current)
			refUnBlock.current = navigator.block((tx: Transition) => {
				const autoUnblockingTx = {
					...tx,
					retry() {
						refUnBlock.current?.(); //need to unblock so retry succeeds
						tx.retry();
					}
				};

				blocker(autoUnblockingTx);
			});
	}, [navigator, blocker, when]);
}
