import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CalculationForm } from "@ploy-ui/calculation-form";
import { renderTemplateWithRouteState } from "../Product/renderTemplate";
import { useParsedQueryAndParams } from "@ploy-lib/routing";
import { useInvalidator, useResource } from "@rest-hooks/core";
import { AppLoadResource } from "@ploy-lib/rest-resources";
import { Typography } from "@material-ui/core";
import {
	AppActionProvider,
	FormStateProvider,
	useAppActionState
} from "@ploy-ui/template-form";
import { useInRouterContext } from "react-router-dom";

const appLoadShape = AppLoadResource.detail();

export interface ApplicationViewProps {
	applicationNumber?: string;
	appLoadPayload?: object;
	formContext?: string;
	context?: string;
	initialize?: boolean;
	disallowedFieldRoles?: readonly string[];
	fromSubmit?: boolean;
	customerContext?: string;
	externalPostback?: (result: any) => void;
	formIsDisabled?: boolean;
	formIsLiteral?: boolean;
	AuthorizationCode?: string;
	FailedAuthorizationCode?: string;
}
export const ApplicationView = (props: ApplicationViewProps) => {
	const { externalPostback, ...rest } = props;

	return (
		<AppActionProvider externalPostback={externalPostback}>
			<ApplicationViewInternal {...rest} />
		</AppActionProvider>
	);
};
export const ApplicationViewInternal = (props: ApplicationViewProps) => {
	const { disallowedFieldRoles, appLoadPayload } = props;
	const {
		formContext,
		context,
		applicationNumber,
		initialize = true,
		fromSubmit,
		customerContext,
		AuthorizationCode,
		FailedAuthorizationCode
	} = useParsedQueryAndParams(props);
	const { formIsLiteral = false, formIsDisabled = false } = props;
	const [refetchCounter, setRefetchCounter] = useState(0);

	const [
		loadingApplicationAfterSubmission,
		setLoadingApplicationAfterSubmission
	] = useState(fromSubmit);

	const innerAppLoadPayload: object = useMemo(
		() => ({
			applicationNumber,
			fromSession: !initialize ? true : undefined,
			fromSubmit: loadingApplicationAfterSubmission,
			sblAuthCode: FailedAuthorizationCode || AuthorizationCode, // Altinn sends parameters "AuthorizationCode" when consent is accepted, or "FailedAuthorizationCode" when consent is rejected,
			sblRejected: FailedAuthorizationCode ? true : undefined,
			customerContext,
			...appLoadPayload
		}),
		[
			applicationNumber,
			initialize,
			loadingApplicationAfterSubmission,
			FailedAuthorizationCode,
			AuthorizationCode,
			customerContext,
			appLoadPayload
		]
	);
	const appLoad = useResource(appLoadShape, innerAppLoadPayload);
	const invalidateApp = useInvalidator(appLoadShape);
	const hasRouteState = useInRouterContext();

	const appAction = useAppActionState();
	const header = (
		<Typography style={{ fontWeight: "bold" }}>
			{appLoad.productName}
		</Typography>
	);

	const onSubmit = useCallback(
		(values: any) => {
			const { __calculation: { submitResult } = {} as any } = values;

			if (submitResult && appAction.externalPostback)
				appAction.externalPostback(submitResult);

			setLoadingApplicationAfterSubmission(true);
			//Causes app and form refetch
			invalidateApp(innerAppLoadPayload);
			setRefetchCounter(refetchCounter + 1);
		},
		[appAction, refetchCounter, invalidateApp, innerAppLoadPayload]
	);

	useEffect(() => {
		if (appLoad.externalRedirectUrl) {
			setTimeout(
				() => (document.location.href = appLoad.externalRedirectUrl),
				0
			);
		}
	}, [appLoad.externalRedirectUrl]);

	return applicationNumber ? (
		<FormStateProvider
			initialState={{
				formIsLiteral: formIsLiteral,
				formIsDisabled: formIsDisabled
			}}
		>
			<CalculationForm
				applicationNumber={applicationNumber || ""}
				appLoadPayload={innerAppLoadPayload}
				formContext={formContext}
				refetchCounter={refetchCounter}
				context={context}
				onSubmit={onSubmit}
				onSubmitError={onSubmit}
				customerContext={customerContext}
				skipInitialize={!initialize}
				header={appLoad.settings.alwaysShowProductName ? header : null}
				disallowedFieldRoles={disallowedFieldRoles}
			>
				{hasRouteState ? renderTemplateWithRouteState : undefined}
			</CalculationForm>
		</FormStateProvider>
	) : null;
};
