import React from "react";
import {
	Grid,
	ListItemText,
	FormControlLabel,
	Checkbox,
	Paper
} from "@material-ui/core";
import { ExpandableListEditSingle, ConfigElement } from "@ploy-ui/core";
import { useFetcher, useResource } from "@rest-hooks/core";
import { ChevronRight } from "@material-ui/icons";
import { DatePickerField } from "@ploy-ui/form-fields";
import { v4 as uuidv4 } from "uuid";
import { defineMessages, useIntl } from "react-intl";
import * as Yup from "yup";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";
import {
	useResourceWithInvalidate,
	VendorSignerResource,
	CustomerSigner,
	LoginResource,
	BaseResource
} from "@ploy-lib/rest-resources";
import { apiResourceUrl } from "@ploy-lib/core";

const collapsedElement = (values: CustomerSigner) => (
	<Grid container alignItems="center">
		<ChevronRight />
		<ListItemText
			primary={`${values.firstName} ${values.lastName}`}
			secondary={values.role}
		/>
	</Grid>
);

const messages = defineMessages({
	header: {
		id: "dealer.vendorSigner.header",
		description: "Header for ExpandableList",
		defaultMessage: "Vendor signatures"
	},
	firstNameLabel: {
		id: "dealer.vendorSigner.firstName.label",
		description: "Label for firstName",
		defaultMessage: "First name"
	},
	firstNameValidationRequired: {
		id: "dealer.vendorSigner.firstName.validation.required",
		description: "Validation error: required for firstName",
		defaultMessage: "You must enter first name"
	},
	lastNameLabel: {
		id: "dealer.vendorSigner.lastName.label",
		description: "Label for lastName",
		defaultMessage: "Surname"
	},
	lastNameValidationRequired: {
		id: "dealer.vendorSigner.lastName.validation.required",
		description: "Validation error: required for lastName",
		defaultMessage: "You must fill in last name"
	},
	roleLabel: {
		id: "dealer.vendorSigner.role.label",
		description: "Label for role",
		defaultMessage: "Role"
	},
	roleValidationRequired: {
		id: "dealer.vendorSigner.role.validation.required",
		description: "Validation error: required for role",
		defaultMessage: "You must enter role"
	},
	ssnLabel: {
		id: "dealer.vendorSigner.ssn.label",
		description: "Label for ssn",
		defaultMessage: "Social security number"
	},
	ssnValidationMatches: {
		id: "dealer.vendorSigner.ssn.validation.matches",
		description: "Validation error: matches for ssn",
		defaultMessage: "SSN consists of 11 digits"
	},
	phoneLabel: {
		id: "dealer.vendorSigner.phone.label",
		description: "Label for phone",
		defaultMessage: "Mobile phone"
	},
	phoneValidationRequired: {
		id: "dealer.vendorSigner.phone.validation.required",
		description: "Validation error: required for phone",
		defaultMessage: "You must enter mobile number"
	},
	phoneValidationMatches: {
		id: "dealer.vendorSigner.phone.validation.matches",
		description: "Validation error: matches for phone",
		defaultMessage: "Mobile numbers consist of 8 digits"
	},
	emailLabel: {
		id: "dealer.vendorSigner.email.label",
		description: "Label for email",
		defaultMessage: "E-mail address"
	},
	emailValidationEmail: {
		id: "dealer.vendorSigner.phone.validation.email",
		description: "Validation error: email format",
		defaultMessage: "Invalid e-mail address"
	},
	emailValidationRequired: {
		id: "dealer.vendorSigner.email.validation.required",
		description: "Validation error: required for email",
		defaultMessage: "You must enter email address"
	},
	mustSignLabel: {
		id: "dealer.vendorSigner.mustSign.label",
		description: "Label for mustSign",
		defaultMessage: "Must sign"
	},
	validToDateLabel: {
		id: "dealer.vendorSigner.validToDate.label",
		description: "Label for validToDate",
		defaultMessage: "Valid until"
	}
});

interface UserSignerPermissions {
	canAddVendorSigners: boolean;
	canDeleteVendorSigners: boolean;
	canSetMustSign: boolean;
	canEditPersonalInfo: boolean;
	showSsn: boolean;
}

class UserSignerPermissionsResource
	extends BaseResource
	implements UserSignerPermissions
{
	readonly canAddVendorSigners: boolean = false;
	readonly canDeleteVendorSigners: boolean = false;
	readonly canSetMustSign: boolean = false;
	readonly canEditPersonalInfo: boolean = false;
	readonly showSsn: boolean = false;

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static urlRoot = apiResourceUrl("signers/currentUserPermissions");
}
//
const VendorSigner = (props: any) => {
	const intl = useIntl();
	const validation = createValidationHelpers(intl.locale);

	const permissions = useResource(UserSignerPermissionsResource.detail(), {});

	const config = [
		{
			name: "firstName",
			title: intl.formatMessage(messages.firstNameLabel),
			validation: Yup.string().required(
				intl.formatMessage(messages.firstNameValidationRequired)
			),
			disabled: !permissions?.canEditPersonalInfo,
			required: true
		},
		{
			name: "lastName",
			title: intl.formatMessage(messages.lastNameLabel),
			validation: Yup.string().required(
				intl.formatMessage(messages.lastNameValidationRequired)
			),
			disabled: !permissions?.canEditPersonalInfo,
			required: true
		},
		{
			name: "role",
			title: intl.formatMessage(messages.roleLabel),
			validation: Yup.string().required(
				intl.formatMessage(messages.roleValidationRequired)
			),
			disabled: !permissions?.canEditPersonalInfo
		},
		{
			name: "ssn",
			title: intl.formatMessage(messages.ssnLabel),
			validation: Yup.string().test(
				"Validate SSN",
				intl.formatMessage(messages.ssnValidationMatches),
				ssn => validation.validSsn(ssn)
			),
			disabled: !permissions?.canEditPersonalInfo,
			hidden: !permissions?.showSsn
		},
		{
			name: "phone",
			title: intl.formatMessage(messages.phoneLabel),
			validation: Yup.string()
				.test(
					"Validate phone number",
					intl.formatMessage(messages.phoneValidationMatches),
					phoneNumber => validation.phoneValidation(phoneNumber)
				)
				.required(intl.formatMessage(messages.phoneValidationRequired)),
			disabled: !permissions?.canEditPersonalInfo
		},
		{
			name: "email",
			title: intl.formatMessage(messages.emailLabel),
			validation: Yup.string()
				.email(intl.formatMessage(messages.emailValidationEmail))
				.required(intl.formatMessage(messages.emailValidationRequired)),
			disabled: !permissions?.canEditPersonalInfo
		},
		{
			name: "mustSign",
			title: intl.formatMessage(messages.mustSignLabel),
			render: props => {
				const { configElement, field, disabled } = props;
				return (
					<FormControlLabel
						label={configElement.title}
						control={
							<Checkbox checked={field.value} {...field} disabled={disabled} />
						}
					/>
				);
			},
			disabled: !permissions?.canSetMustSign
		},
		{
			name: "validToDate",
			title: intl.formatMessage(messages.validToDateLabel),
			render: props => {
				const { configElement, margin, ...rest } = props;
				return (
					<DatePickerField
						label={configElement.title}
						pickerVariant="inline"
						margin={margin as any}
						{...rest}
					/>
				);
			},
			disabled: !permissions?.canEditPersonalInfo
		}
	] as ConfigElement<CustomerSigner>[];

	const { vendor } = useResource(LoginResource.status(), {});

	const customerId = (vendor && vendor.id) || null;

	const signers = useResourceWithInvalidate(
		VendorSignerResource.list(),
		customerId
			? {
					customerId
			  }
			: null
	);

	const updateSigner = useFetcher(VendorSignerResource.update());
	const createSigner = useFetcher(VendorSignerResource.create());
	const deleteSigner = useFetcher(VendorSignerResource.delete());

	const onCreate = (signer: CustomerSigner) => {
		return createSigner({}, signer, undefined);
	};

	const onUpdate = (signer: CustomerSigner) => {
		return (
			signer &&
			signer.customerSignerId &&
			updateSigner({ customerSignerId: signer.customerSignerId }, signer)
		);
	};

	const onDelete = (signer: CustomerSigner) => {
		deleteSigner({ customerSignerId: signer.customerSignerId }, undefined);
	};

	return (
		<Paper elevation={1} square={false} style={{ margin: "16px" }}>
			<ExpandableListEditSingle<CustomerSigner>
				initialElementsList={signers || []}
				elementIdKey={"__id"}
				header={intl.formatMessage(messages.header)}
				elementConfig={config}
				onCreate={signer => onCreate(signer)}
				onUpdate={signer => onUpdate(signer)}
				onDelete={signer => onDelete(signer)}
				collapsedElement={collapsedElement}
				margin="dense"
				canAddElement={Boolean(permissions?.canAddVendorSigners)}
				canDelete={Boolean(permissions?.canDeleteVendorSigners)}
				elementCreator={() => ({
					__id: uuidv4(),
					birthDate: "",
					canSetMustSign: true,
					customerId,
					customerSignerId: null,
					email: "",
					firstName: "",
					isVendorSigner: true,
					lastName: "",
					lockPersonalInfo: false,
					mustSign: false,
					name: "",
					phone: "",
					role: "",
					ssn: "",
					ssnAddedByInternal: false,
					ssnIsHidden: false,
					validToDate: ""
				})}
			/>
		</Paper>
	);
};

export default VendorSigner;
