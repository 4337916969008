import { useState } from "react";
import {
	BottomNavigation,
	makeStyles,
	BottomNavigationAction,
	Icon,
	Drawer,
	BottomNavigationActionProps
} from "@material-ui/core";
import { navMigration } from "../routeMigration";
import { FixedBottomMenuItemList } from "./FixedBottomMenuItemList";
import clsx from "clsx";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import { zendeskIsLoaded, useLinkProps, useIsActive } from "@ploy-ui/core";
import { useIntl } from "react-intl";
import { messages } from "./MenuItemList";

import DashboardIcon from "@material-ui/icons/Dashboard";
import { To } from "react-router-dom";

const FixedBottomMenu = () => {
	const classes = useBottomNavStyles();
	const actionClasses = useBottomNavActionStyles();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const { menuItems } = useResource(VulcanDataResource.detail(), {});

	const items = menuItems.map(item => ({
		...item,
		navigateUrl:
			navMigration[item.navigateUrl as keyof typeof navMigration] ??
			item.navigateUrl
	}));

	const intl = useIntl();

	const dashboard = items.find(item => item.navigateUrl === "dashboard");

	const menuItemList = items.filter(item => item.navigateUrl !== "dashboard");

	const itemsInShortMenu = zendeskIsLoaded() ? 2 : 3;
	const menuItemListShort = menuItemList.slice(0, itemsInShortMenu);
	const menuItemsOverflow = menuItemList.slice(itemsInShortMenu);

	return (
		<footer>
			<BottomNavigation showLabels className={classes.root}>
				<BottomNavLink
					to={`/dashboard`}
					label={
						dashboard?.menuItemLabel ?? intl.formatMessage(messages.Dashboard)
					}
					iconClass={dashboard?.icon}
					classes={actionClasses}
					icon={dashboard?.icon == null ? <DashboardIcon /> : undefined}
				/>

				{menuItemListShort.map(item => (
					<BottomNavLink
						key={item.menuItemName}
						href={item.navigateUrlexternal}
						target={item.navigateUrlexternal && "_blank"}
						to={item.navigateUrlexternal ? undefined : `/${item.navigateUrl}`}
						label={item.menuItemLabel}
						iconClass={item.icon}
						classes={actionClasses}
					/>
				))}

				<BottomNavigationAction
					key="menu"
					label="Meny"
					icon={<Icon className="fa fa-bars" />}
					onClick={() => setIsMenuOpen(!isMenuOpen)}
					value="menu"
					classes={actionClasses}
				/>
			</BottomNavigation>

			<Drawer
				anchor="bottom"
				open={isMenuOpen}
				onClose={() => setIsMenuOpen(false)}
			>
				<FixedBottomMenuItemList
					menuItems={menuItemsOverflow}
					closeDrawer={() => setIsMenuOpen(false)}
				/>
			</Drawer>
		</footer>
	);
};

interface BottomNavLinkProps {
	className?: string;
	label?: React.ReactNode;
	iconClass?: string;
	icon?: React.ReactNode;
	secondaryAction?: React.ReactNode;
	href?: string;
	to?: To;
	target?: string;
	end?: boolean;
	caseSensitive?: boolean;
	color?: string;
	children?: React.ReactNode;
	classes?: BottomNavigationActionProps["classes"];
}

const BottomNavLink = (props: BottomNavLinkProps) => {
	const iconClasses = useBottomNavIconStyles();

	const isActive = useIsActive(props);

	const { label, className, iconClass, icon, ...rest } = useLinkProps(props);

	return (
		<BottomNavigationAction
			component="a"
			selected={isActive}
			icon={
				icon ??
				(iconClass && (
					<Icon className={clsx(`fa fa-${iconClass}`, iconClasses.root)} />
				))
			}
			label={label}
			className={className}
			{...rest}
		/>
	);
};

const useBottomNavStyles = makeStyles({
	root: {
		width: "100%",
		position: "fixed",
		bottom: 0,
		zIndex: 20,
		borderTop: "1px solid #ccc"
	}
});

const useBottomNavActionStyles = makeStyles({
	root: {
		minWidth: "auto",
		maxWidth: "none"
	},
	selected: {},
	label: {
		fontSize: ".65rem",
		whiteSpace: "nowrap"
	}
});

const useBottomNavIconStyles = makeStyles({
	root: {
		overflow: "initial",
		textAlign: "center"
	}
});

export default FixedBottomMenu;
