import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const DefaultLinearProgress = withStyles({
	colorPrimary: {
		backgroundColor: grey[500]
	},
	barColorPrimary: {
		backgroundColor: grey[300]
	}
})(LinearProgress);
