import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export interface BannerProps {
	iconComponent?: React.ReactNode;
	description: React.ReactNode;
}

function Banner({ iconComponent, description }: BannerProps) {
	return (
		<Box clone pt={2} pr={1} pb={1} pl={2}>
			<Paper elevation={0}>
				<Grid container spacing={2} alignItems="center" wrap="nowrap">
					{iconComponent && (
						<Grid item>
							<Box bgcolor="primary.main" clone>
								<Avatar>{iconComponent}</Avatar>
							</Box>
						</Grid>
					)}
					<Grid item>
						<Typography>{description}</Typography>
					</Grid>
				</Grid>
				{/* <Grid container justifyContent="flex-end" spacing={1}>
					<Grid item>
						<Button color="primary">Turn on wifi</Button>
					</Grid>
				</Grid> */}
			</Paper>
		</Box>
	);
}

Banner.displayName = "DployBanner";

export { Banner };
