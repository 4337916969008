import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface DocumentFieldType {
	documentFieldId: number;
	applicationId: number;
	documentId: string;
	fieldVariable: string;
	fieldValue: string;
}

export class DocumentFieldResource
	extends BaseCamelCasedResource
	implements DocumentFieldType
{
	readonly documentFieldId: number;
	readonly applicationId: number;
	readonly documentId: string;
	readonly fieldVariable: string;
	readonly fieldValue: string;

	pk() {
		return this.documentFieldId?.toString() || "";
	}

	static listUrl(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		const { applicationId } = allParams;
		if (applicationId !== undefined) {
			return `${this.urlRoot}/application/${applicationId}`;
		}
		throw new Error("DocumentFieldResource require applicationId");
	}

	static urlRoot = apiResourceUrl("document-fields");
}
