import { createContext, useContext, useMemo } from "react";

export interface AppActionContextValue {
	externalPostback?: (res: any) => void;
}
const defaultContext: AppActionContextValue = {};

const AppActionContext = createContext<AppActionContextValue>(defaultContext);

export interface AppActionProviderProps {
	children: React.ReactNode;
	externalPostback?: (result: any) => void;
}

export const AppActionProvider = (props: AppActionProviderProps) => {
	const { children, externalPostback } = props;

	const value: AppActionContextValue = useMemo(
		() => ({
			externalPostback: externalPostback
		}),
		[externalPostback]
	);

	return (
		<AppActionContext.Provider value={value}>
			{children}
		</AppActionContext.Provider>
	);
};

export const useAppActionState = () => useContext(AppActionContext);
