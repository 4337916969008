import React from "react";
import ContentListItem from "../../ContentListItem";
import { DividerItems } from "@ploy-ui/core";
import { Link } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Links, { LinksProps } from "./Links";
import { usePrefetchResources } from "../usePrefetchResources";

export interface LinkListItemsProps extends LinksProps {
	page?: number;
	pageSize?: number;
	placeholder?: boolean;
}

export function LinkListItems(props: LinkListItemsProps) {
	const { page = 0, pageSize, emptyMessage } = props;

	let [links] = usePrefetchResources(Links, props, props.placeholder);

	links = links?.slice().sort((a, b) => a.sortOrder - b.sortOrder);

	if (pageSize) links = links?.slice((page - 1) * pageSize, page * pageSize);

	if (links?.length === 0) {
		return (
			<ContentListItem
				secondary={
					emptyMessage ?? (
						<FormattedMessage
							id="dploy.dashboard.linklist.empty"
							defaultMessage="Ingen lenker"
						/>
					)
				}
				hideChevron
			/>
		);
	}

	return (
		<DividerItems>
			{links?.map(link => (
				<ContentListItem
					key={link.linkId}
					button
					component={Link}
					href={link.linkUrl}
					target="_blank"
					rel="noopener noreferrer"
					primary={link.linkText ?? link.linkUrl}
					chevronIcon={<OpenInNewIcon fontSize="small" />}
				/>
			))}
		</DividerItems>
	);
}
