import Links, { sources, sourceMapTitles } from "./Links";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { LinkItemResource } from "@ploy-lib/rest-resources";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage } from "react-intl";

applyPropertyControls(Links, {
	source: {
		type: ControlType.Enum,
		title: (
			<FormattedMessage
				id="dploy.grid.links.source.label"
				defaultMessage="Data source"
			/>
		),
		options: sources,
		optionTitles: sources.map(k => sourceMapTitles[k]),
		defaultValue: sources[0],
		coerce: (value, values) => {
			const source = sources.find(name =>
				LinkItemResource[name]?.()?.getFetchKey({}).includes(values.loadUrl)
			);
			return source;
		}
	},
	emptyMessage: {
		type: ControlType.String,
		title: (
			<FormattedMessage
				id="dploy.grid.links.emptyMessage.label"
				defaultMessage="Empty list message"
			/>
		),
		description: (
			<FormattedMessage
				id="dploy.grid.links.emptyMessage.description"
				defaultMessage="Alternative text explaining that the list is empty"
			/>
		),
		defaultValue: "Ingen lenker",
		multiline: true
	},
	boxProps: contentBoxPropertyControls
});

export default Links;
