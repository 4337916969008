import { ID } from "./util";

export enum ApplicationDebtSource {
	NotSet = 0,
	Taxdata = 1,
	DebtRegistry = 2,
	Applicant = 3
}

export interface ApplicationDebt {
	termAmount: number;
	refinance: boolean;
	nominalInterest?: number;
	maturity?: number;
	applicationDebtId?: ID;
	amount: number;
	comment: string;
	creditLimit?: number;
	customerId?: number;
	lender: string;
	loanNumber: string;
	loanType: string;
	source: ApplicationDebtSource;
	refinanceAccount: string;
}
