import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

interface ZendeskProps {
	email: string | undefined;
	username: string | undefined;
	accountKey: string | undefined;
	tags: (string | undefined | null)[];
	bootstrapper: any;
	children?: any;
}

//const styles = {};

const useStyles = makeStyles({
	"@global": {
		"@media screen and (max-width: 600px)": {
			".zopim": {
				left: `0px !important`,
				zIndex: `15 !important` as unknown as number,
				bottom: `60px !important`
			}
		}
	}
});

const Zendesk = (props: ZendeskProps) => {
	const { accountKey, bootstrapper, tags, username, email } = props;

	useStyles();

	useEffect(() => {
		bootstrapper.conditionallyClearZendesk();

		if (accountKey) {
			bootstrapper.bootstrapZendesk(accountKey);

			if (username && email) {
				bootstrapper.setupZendeskIndentity(username, email, tags);
			}
		}

		return () => {
			bootstrapper.conditionallyClearZendesk();
		};
		// }, [accountKey, bootstrapper, email, tags, username]);

		// avoid multiple updates and clearing of zendesk in DOM which breaks functionality
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return props.children || null;
};

Zendesk.displayName = "Zendesk";

export default Zendesk;
