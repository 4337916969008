import { CloudDownload } from "@material-ui/icons";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { PendingIconButton } from "@ploy-ui/core";
import { useIntl } from "react-intl";

const WholesaleOverviewSummaryDownloader = () => {
	const intl = useIntl();

	const fileDownloadName = `Cars ${intl.formatDate(new Date(), {
		dateStyle: "short"
	})}.csv`;

	return (
		<>
			<PendingIconButton
				color="primary"
				onClick={() =>
					fetch(legacyApiResourceUrl(`WholesaleOverview/GetCarsAsCSV`), {
						method: "GET",
						headers: {
							accept: "application/json"
						}
					}).then(response => {
						response.blob().then((blob: Blob) => {
							const url = window.URL.createObjectURL(blob);
							const a = document.createElement("a");
							a.href = url;
							a.download = fileDownloadName;
							a.click();
						});
					})
				}
			>
				<CloudDownload />
			</PendingIconButton>
		</>
	);
};

export { WholesaleOverviewSummaryDownloader };
