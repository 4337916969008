import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { defineMessages, useIntl } from "react-intl";
import {
	ProfileResource,
	AllApplicationsSettingsResource
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { FieldHookConfig, useField } from "formik";
import { isNotNull } from "@ploy-lib/core";
import { ID } from "@ploy-lib/types";

const useStyles = makeStyles(
	theme => ({
		root: {
			fontWeight: 700,
			marginBottom: 20,
			[theme.breakpoints.down("xs")]: {
				width: "100%"
			},
			[theme.breakpoints.up("sm")]: {
				width: 350
			}
		}
	}),
	{ name: "AppFilterSelector" }
);

const AppFilterSelector = (props: FieldHookConfig<ID | undefined>) => {
	const { canAccessAll } = useResource(
		AllApplicationsSettingsResource.detail(),
		{}
	);

	const profile = useResource(ProfileResource.detail(), {});

	const intl = useIntl();

	const allItem = { value: "", label: intl.formatMessage(messages.All) };

	const items = [
		{ value: profile.salespersonId, label: intl.formatMessage(messages.Mine) },
		canAccessAll ? allItem : null
	].filter(isNotNull);

	const classes = useStyles();

	const [field, , form] = useField(props);
	const item = items.find(x => x.value === field.value) ?? allItem;

	return (
		<div className={classes.root}>
			<DployAutocomplete
				variant="standard"
				items={items}
				getItemLabel={item => item.label}
				getItemValue={item => item.value || ""}
				{...field}
				value={item}
				onChange={(e, item) => form.setValue(item.value)}
				readonly={items.length <= 1}
				multiple={false}
				disableClearable
				searchable={false}
				AutocompleteProps={{
					getOptionSelected: (option, value) => option.value === value.value
				}}
			/>
		</div>
	);
};

export const messages = defineMessages({
	Mine: {
		id: "dealer.allapplications.appfilter.mine.label",
		description: "Label for my applications",
		defaultMessage: "My applications"
	},
	All: {
		id: "dealer.allapplications.appfilter.all.label",
		description: "Label for all applications",
		defaultMessage: "All applications"
	}
});

export default AppFilterSelector;
