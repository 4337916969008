import zip from "lodash/zip";
import { DefaultData, FieldCalculation } from "@ploy-lib/calculation";
import { ErrorDisplay, TemplateField } from "@ploy-lib/types";

export const fieldToKey = (f: TemplateField) =>
	`${f.namespace}.${f.name}.${f.renderAs}.${f.formTemplateFieldId}`;

export const sectionToKey = (formTemplateSectionId: string, index: number) =>
	`${formTemplateSectionId}.${index}`;

export function cssContains(...classes: (string | undefined | null)[]) {
	return (text: string) =>
		classes.filter(i => i != null).some(c => c!.includes(text));
}

export const isNotLiteral = <T extends { literal?: boolean }>(item: T) =>
	!item.literal;

export const isTouchyLiteralOrNotLiteral = <
	T extends { literal?: boolean; errorDisplayLiteral?: ErrorDisplay }
>(
	item: T
) => !item.literal || item.errorDisplayLiteral === ErrorDisplay.Touched;

export const mapToPaths = (obj: object): string[] =>
	Object.entries(obj).flatMap(([k, v]) =>
		typeof v === "object" ? mapToPaths(v).map(p => `${k}.${p}`) : k
	);

export const RIC: (cb: (...args: any[]) => void, options: any) => void =
	typeof (global as any).requestIdleCallback === "function"
		? (global as any).requestIdleCallback
		: cb => global.setTimeout(cb, 0);

export const idle = (options?: any) =>
	new Promise(resolve => RIC(resolve, options));

export const zipWithPeek = <T>(data: T[]) =>
	data.length > 0 ? zip(data, [...data.slice(1), null]) : [];

/** Can be removed/refactored when upgraded to TypeScript 3.4+ */
export type Lit = string | number | boolean | undefined | null | void | {};
export const tupleToType = <T extends Lit[]>(...args: T) => args;
export type Unpacked<T> = T extends (infer U)[] ? U : T;

export function extractFieldsWithRoles<LegalRoles extends string = string>(
	wantedRoles: LegalRoles[],
	fields: TemplateField[]
) {
	const candidates = fields.reduce((o, f) => {
		if (f.role && wantedRoles.includes(f.role as LegalRoles) && !o[f.role])
			o[f.role] = f;
		return o;
	}, {} as Record<LegalRoles, TemplateField | undefined>);

	return candidates;
}

export function extractCalcDataFromFieldsWithRoles<
	LegalRoles extends string = string,
	TData = DefaultData
>(
	wantedRoles: LegalRoles[],
	fields: TemplateField[],
	calcData: (FieldCalculation<TData> | undefined)[]
) {
	const candidates = fields.reduce((o, f, i) => {
		if (f.role && wantedRoles.includes(f.role as LegalRoles) && !o[f.role]) {
			const data = calcData[i];
			if (data && data.visible) {
				const { fieldValue, value } = data;
				o[f.role] = value !== undefined ? value : fieldValue;
			}
		}
		return o;
	}, {} as Record<LegalRoles, TData | undefined>);

	return candidates;
}

export function isNotNull<T>(x: T): x is NonNullable<T> {
	return x != null;
}

export function secondValueIsNotNull<FirstValue, SecondValue>(
	vals: [FirstValue, SecondValue]
): vals is [FirstValue, NonNullable<SecondValue>] {
	return isNotNull(vals[1]);
}

export function removeNegativeZero(x: number) {
	return Object.is(x, -0) ? 0 : x;
}
