import {
	TemplatePage,
	TemplatePageDeprecated,
	TemplateSection,
	TemplatePanel,
	TemplateField,
	FieldWidth
} from "@ploy-lib/types";
import { cssContains, isNotNull } from "./utils";
import { v4 as uuidv4 } from "uuid";
import groupBy from "lodash/groupBy";

function isSummaryHeader(section: TemplateSection) {
	return cssContains(section.style)("calculator-summary-section");
}

function isLiteralSection(section: TemplateSection) {
	return cssContains(section.style)("literal-section");
}

function isPaymentPlanButton(field: TemplateField) {
	return field.name === "PaymentPlanButton" && field.namespace === "Calculator";
}

const mapSectionStyle = (section: TemplateSection): TemplateSection => ({
	...section,
	style: undefined,
	literal: isLiteralSection(section) || isSummaryHeader(section)
});

export function convertSummaryHeader(
	sections: TemplateSection[]
): TemplatePanel {
	return {
		formPanelId: uuidv4(),
		elevation: 0,
		sections: sections.filter(isSummaryHeader).map(mapSectionStyle)
	};
}

export function splitMarginBottomFields(
	fields: TemplateField[]
): TemplateField[][] {
	return fields.reduce<TemplateField[][]>((acc, field, i, arr) => {
		if (
			i === 0 ||
			(arr[i - 1].modifier && arr[i - 1].modifier!.includes("margin-bottom"))
		)
			acc.push([field]);
		else {
			acc[acc.length - 1].push(field);
		}

		return acc;
	}, []);
}

export function convertSummary(
	summary: TemplateField[],
	layout?: string
): TemplatePanel {
	const hasPaymentPlan =
		layout === "SummaryWithPaymentTab" || summary.some(isPaymentPlanButton);

	const hideForMobile = layout === "SummaryHiddenOnMobile";
	return {
		formPanelId: uuidv4(),
		hideForMobile,
		elevation: 0,
		sections: [
			...splitMarginBottomFields(summary).map(
				(fields): TemplateSection => ({
					formTemplateSectionId: uuidv4(),
					tabGroup: "Summary",
					fields,
					literal: true
				})
			),
			hasPaymentPlan
				? {
						formTemplateSectionId: uuidv4(),
						layout: "PaymentPlanSection",
						fields: [
							{
								formTemplateFieldId: uuidv4(),
								name: "PaymentPlanButton",
								namespace: "Calculator",
								role: "visibilityFilter"
							}
						],
						literal: true,
						tabGroup: "PaymentPlanSection"
				  }
				: null
		].filter(isNotNull)
	};
}

export function convertMain(
	sections: TemplateSection[],
	panelTitle?: string
): TemplatePanel {
	return {
		panelTitle,
		formPanelId: uuidv4(),
		sections: sections
			.filter(s => !isSummaryHeader(s))
			.flatMap(s =>
				splitMarginBottomFields(s.fields)
					.map((fields, i) => ({
						...(i === 0 ? s : { formTemplateSectionId: uuidv4() }),
						fields
					}))
					.map(mapSectionStyle)
			)
	};
}

export function convertActions(
	nextLabel?: string,
	prevLabel?: string,
	submitLabel?: string
): TemplatePanel {
	const defaultAction = {
		namespace: "actions",
		renderAs: "ButtonField",
		alwaysVisible: true,
		width: "auto" as FieldWidth,
		alignWithPrevious: true,
		variant: "contained",
		margin: "none"
	};
	return {
		formPanelId: uuidv4(),
		sections: [
			{
				formTemplateSectionId: uuidv4(),
				fields: [
					prevLabel
						? {
								formTemplateFieldId: "navigate_before_button",
								name: "navigate_before_button",
								role: "navigate_before",
								label: prevLabel,
								...defaultAction
						  }
						: null,
					nextLabel
						? {
								formTemplateFieldId: "navigate_next_button",
								name: "navigate_next_button",
								role: "navigate_next",
								label: nextLabel,
								...defaultAction
						  }
						: null,
					submitLabel
						? {
								formTemplateFieldId: "submit_button",
								name: "submit_button",
								label: submitLabel,
								role: "submit",
								color: "secondary",
								click: {
									namespace: "Main",
									service: "Submit"
								},
								...defaultAction,
								renderAs: "SubmitButton"
						  }
						: null
				].filter(isNotNull)
			}
		].filter(isNotNull)
	};
}

export function convertPagesToTabs(
	pages: TemplatePageDeprecated[],
	panelTitle?: string
): TemplatePanel {
	return {
		panelTitle,
		formPanelId: uuidv4(),
		elevation: 0,
		sections: Object.entries(
			groupBy(
				pages.flatMap(p =>
					(convertMain(p.sections).sections || []).map<TemplateSection>(s => ({
						...s,
						tabGroup: p.pageTitle
					}))
				),
				s => s.tabGroup
			)
		)
			.map(([_, groupedSections]) =>
				groupedSections.map((s, i) => ({ ...s, useSeparator: i > 0 }))
			)
			.flatMap(x => x)
	};
}

export function convertFieldList(page: TemplatePageDeprecated): TemplatePage {
	const {
		sections,
		summary,
		summaryLayout,
		formPageId,
		pageTitle,
		nextLabel,
		prevLabel
	} = page;
	return {
		formPageId,
		pageTitle,
		layout: "Wizard",
		panels: {
			summaryHeader: convertSummaryHeader(sections || []),
			summary: convertSummary(summary || [], summaryLayout),
			main: convertMain(sections || []),
			actions: convertActions(nextLabel, prevLabel)
		}
	};
}

export function convertSummaryPage(page: TemplatePageDeprecated): TemplatePage {
	const {
		sections,
		summary,
		summaryLayout,
		formPageId,
		pageTitle,
		nextLabel,
		prevLabel,
		submitLabel
	} = page;
	return {
		formPageId,
		pageTitle,
		layout: "WizardSummary",
		panels: {
			summaryHeader: convertSummaryHeader(sections || []),
			summary: convertSummary(summary || [], summaryLayout),
			main: convertMain(sections || []),
			actions: convertActions(nextLabel, prevLabel, submitLabel)
		}
	};
}

export function convertMultiColumn(
	pages: TemplatePageDeprecated[]
): TemplatePage {
	const [page1, page2, page3] = pages;
	const { sections, formPageId } = page1;

	return {
		formPageId,
		layout: "ThreeColumns",
		panels: {
			column1: convertMain((page1 && page1.sections) || [], page1.pageTitle),
			column2: convertMain((page2 && page2.sections) || [], page2.pageTitle),
			column3: convertMain((page3 && page3.sections) || [], page3.pageTitle),
			summaryHeader: convertSummaryHeader(sections || []),
			actions: convertActions(
				undefined,
				undefined,
				(page3 && page3.nextLabel) ||
					(page2 && page2.nextLabel) ||
					(page1 && page1.nextLabel)
			)
		}
	};
}

export function convertCalculator(
	pages: TemplatePageDeprecated[]
): TemplatePage {
	const { sections, summary, summaryLayout, formPageId } = pages[0];

	return {
		formPageId,
		layout: "SimpleSidebar",
		panels: {
			sidebarHeader: convertSummaryHeader(sections || []),
			sidebar: convertSummary(summary || [], summaryLayout),
			main: convertPagesToTabs(pages),
			sidebarActions: {
				formPanelId: uuidv4(),
				sections: [
					{
						formTemplateSectionId: uuidv4(),
						fields: [
							{
								formTemplateFieldId: uuidv4(),
								label: "CalculatorContinue",
								renderAs: "ButtonField",
								name: "CalculatorContinue",
								namespace: "Main",
								color: "secondary",
								variant: "contained",
								role: "submit_no_validate",
								alwaysVisible: true,
								width: "12",
								alignWithPrevious: true,
								margin: "none"
							}
						]
					}
				]
			}
		}
	};
}

export function convertFieldListWithoutWizard(
	page: TemplatePageDeprecated
): TemplatePage {
	const { sections, formPageId, pageTitle } = page;

	return {
		formPageId,
		pageTitle,
		layout: "List",
		panels: {
			main: convertMain(sections)
		}
	};
}
