import toPath from "lodash/toPath";
import clone from "lodash/clone";
import { legacyApiBasePath, apiBasePath } from "./env";
import { TemplateField } from "@ploy-lib/types";
/**
 * Deeply get a value from an object via its path.
 */
export function getIn(
	obj: any,
	key: string | string[],
	def?: any,
	p: number = 0
) {
	const path = toPath(key);
	while (obj && p < path.length) {
		obj = obj[path[p++]];
	}
	return obj === undefined ? def : obj;
}

/**
 * Deeply set a value from in object via its path.
 *
 * Recursively shallow copies objects that are changed along the path
 *
 * @see https://github.com/jaredpalmer/formik/blob/master/src/utils.ts#L125
 */
export function setIn(obj: any, path: string | string[], value: any): any {
	let res: any = clone(obj); // this keeps inheritance when obj is a class
	let resVal: any = res;
	let i = 0;
	let pathArray = toPath(path);

	for (; i < pathArray.length - 1; i++) {
		const currentPath: string = pathArray[i];
		let currentObj: any = getIn(obj, pathArray.slice(0, i + 1));

		if (currentObj) {
			resVal = resVal[currentPath] = clone(currentObj);
		} else {
			const nextPath: string = pathArray[i + 1];
			resVal = resVal[currentPath] =
				isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
		}
	}

	// Return original object if new value is the same as current
	if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
		return obj;
	}

	if (value === undefined) {
		delete resVal[pathArray[i]];
	} else {
		resVal[pathArray[i]] = value;
	}

	// If the path array has a single element, the loop did not run.
	// Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
	if (i === 0 && value === undefined) {
		delete res[pathArray[i]];
	}

	return res;
}

/** @private is the given object an integer? */
export const isInteger = (obj: any): boolean =>
	String(Math.floor(Number(obj))) === obj;

export function isNotNull<T>(x: T): x is NonNullable<T> {
	return x != null;
}

export function download(file: File) {
	if ((window.navigator as any)?.msSaveOrOpenBlob) {
		//IE11 workaround
		(window.navigator as any).msSaveOrOpenBlob(file, file.name);
	} else {
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(file);
		link.download = file.name;

		link.dispatchEvent(
			new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
		);

		// document.body.appendChild(link);
		// link.click();
		// document.body.removeChild(link);
	}
}

/**
 * Get Filename from  Response if controller returns with File() method or filename is set in headers.content-disposition
 * @param response Response from fetch
 */
export const getFilenameFromResponse = (response: Response) => {
	return response.headers
		?.get("content-disposition")
		?.split(";")
		?.find(n => n.includes("filename="))
		?.replace("filename=", "")
		?.trim();
};

export function downloadBlob(blob: Blob, fileNameWithoutExtension: string) {
	const ext = blob.type.substr(blob.type.lastIndexOf("/") + 1);
	const fileNameWithExtension = `${fileNameWithoutExtension}.${ext}`;

	if ((window.navigator as any)?.msSaveOrOpenBlob) {
		//IE11 workaround
		(window.navigator as any).msSaveOrOpenBlob(blob, fileNameWithExtension);
	} else {
		download(new File([blob], fileNameWithExtension));
	}
}

export const stripTrailingSlash = (path: string) => path.replace(/\/$/, "");

export const stripLeadingAndTrailingSlash = (path: string) =>
	path.replace(/\/$/, "").replace(/^\//, "");

export const legacyApiResourceUrl = (resource: string) =>
	`${stripTrailingSlash(legacyApiBasePath)}/${stripLeadingAndTrailingSlash(
		resource
	)}`;

export const apiResourceUrl = (resource: string) =>
	`${stripTrailingSlash(apiBasePath)}/${stripLeadingAndTrailingSlash(
		resource
	)}`;

export function deeplyApplyKeyTransform(
	obj: any,
	transform: (key: string) => string
) {
	const ret: { [key: string]: any } = Array.isArray(obj) ? [] : {};
	Object.keys(obj).forEach(key => {
		if (obj[key] != null && typeof obj[key] === "object") {
			ret[transform(key)] = deeplyApplyKeyTransform(obj[key], transform);
		} else {
			ret[transform(key)] = obj[key];
		}
	});
	return ret;
}

export function deeplyApplyValueTransform(
	obj: any,
	transform: (v: any) => any
) {
	const ret: { [key: string]: any } = Array.isArray(obj) ? [] : {};
	Object.keys(obj).forEach(key => {
		if (obj[key] != null && typeof obj[key] === "object") {
			ret[key] = deeplyApplyValueTransform(obj[key], transform);
		} else {
			ret[key] = transform(obj[key]);
		}
	});
	return ret;
}

export function alphabeticalSort(a: string, b: string) {
	return a.localeCompare(b);
}

export function getFieldName(field: Pick<TemplateField, "name" | "namespace">) {
	return field.namespace ? `${field.namespace}.${field.name}` : field.name;
}
