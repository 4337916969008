import { Suspense, useCallback, useMemo, useRef } from "react";
import {
	FormikSubmitButton,
	FormikResetButton,
	DashboardGridResource,
	FormikAutocompleteField,
	FormikTextField,
	DashboardGrid
} from "@ploy-ui/dashboard";
import { DropzoneField } from "@ploy-ui/form-fields";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
	Switch,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
	FastField,
	FieldArray,
	FieldArrayRenderProps,
	Form,
	Formik,
	getIn,
	useField,
	useFormikContext
} from "formik";
import { Endpoint, useCache, useResource, useRetrieve } from "@rest-hooks/core";
import { array, mixed, object, string } from "yup";
import { CheckBox } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import "./yupArrayUnique";
import { v4 as uuid } from "uuid";

export interface DashboardsImportDialogProps {
	onClose: () => void;
	onImportSubmit: (dashboards: DashboardGrid[]) => Promise<void>;
	open: boolean;
}

const messages = defineMessages({
	filesRequired: {
		id: "dealer.dashboard.import.files.validation-required",
		defaultMessage: "Select one or more files"
	},
	selectedRequired: {
		id: "dealer.dashboard.import.selected.validation-required",
		defaultMessage: "Select one or more dashboards"
	},
	replaceUnique: {
		id: "dealer.dashboard.import.replace.validation-unique",
		defaultMessage: "Cannot replace the same dashboard multiple times"
	},
	nameUnique: {
		id: "dealer.dashboard.import.name.validation-unique",
		defaultMessage: "Enter a unique name"
	}
});

interface ImportForm {
	files: File[];
	import: {
		fromFile: string;
		original: DashboardGrid;
		replace: DashboardGrid | null;
		name: string;
	}[];
}

export function DashboardsImportDialog(
	props: DashboardsImportDialogProps
): JSX.Element {
	const { onClose, open, onImportSubmit } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const dashboards = useCache(DashboardGridResource.list(), {});

	const allDashboardsPromise = useRetrieve(
		DashboardGridResource.list(),
		open ? { includeContent: true } : null
	);

	const initialValues: ImportForm = {
		files: [],
		import: []
	};

	const dashboardsRef = useRef(dashboards);
	dashboardsRef.current = dashboards;

	const onSubmit = useCallback(
		async (values: ImportForm) => {
			// dashboardsRef will be updated with complete data when the "useRetrieve" resolves
			if (allDashboardsPromise) await allDashboardsPromise;

			const dashboards = values.import.map(({ original, replace, name }) => ({
				...original,
				name,
				id: replace?.id,
				_legacyId: replace?._legacyId
			}));

			await onImportSubmit(dashboards);

			onClose();
		},
		[allDashboardsPromise, onClose, onImportSubmit]
	);

	const intl = useIntl();

	const validationSchema = useMemo(() => {
		return object<Partial<ImportForm>>({
			files: array()
				.of(
					mixed().test(
						"is-json-file",
						intl.formatMessage(messages.filesRequired),
						value => value instanceof File && value.type === "application/json"
					)
				)
				.required(intl.formatMessage(messages.filesRequired))
				.min(1, intl.formatMessage(messages.filesRequired)),
			import: array()
				.of(
					object({
						fromFile: string(),
						original: object<DashboardGrid>(),
						replace: object<DashboardGrid>().nullable(),
						name: string().required(intl.formatMessage(messages.nameUnique))
					})
						.uniqueProperty(
							"replace",
							intl.formatMessage(messages.replaceUnique),
							(a, b) =>
								a != null &&
								b != null &&
								DashboardGridResource.pk(a) === DashboardGridResource.pk(b)
						)
						.uniqueProperty("name", intl.formatMessage(messages.nameUnique))
						.test(
							"name-available",
							intl.formatMessage(messages.nameUnique),
							async function (value) {
								const importArr = this.parent as ImportForm["import"];

								// dashboardsRef will be updated with complete data when the "useRetrieve" resolves
								if (allDashboardsPromise) await allDashboardsPromise;

								const notReplacedDashboards = dashboardsRef.current?.filter(
									d =>
										!importArr.some(
											x =>
												x.replace &&
												DashboardGridResource.pk(x.replace) === d.pk()
										)
								);
								if (notReplacedDashboards?.some(d => d.name === value.name)) {
									return this.createError({
										path: `${this.path}.name`,
										message: intl.formatMessage(messages.nameUnique)
									});
								}

								return true;
							}
						)
				)
				.required(intl.formatMessage(messages.selectedRequired))
				.min(1, intl.formatMessage(messages.selectedRequired))
		});
	}, [allDashboardsPromise, intl]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullScreen={isMobile}
			aria-labelledby="dashboard-name-import-dialog-title"
		>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onClose}
				validationSchema={validationSchema}
			>
				<>
					<DialogTitle id="dashboard-import-dialog-title">
						<FormattedMessage
							id="dealer.dasbhoard.import-dialog.title"
							defaultMessage="Import dashboard"
						/>
					</DialogTitle>
					<DialogContent>
						<Form>
							<FastField
								name="files"
								id="files"
								component={DropzoneField}
								multiple
								margin="dense"
								label={
									<FormattedMessage
										id="dealer.dasbhoard.import-dialog.files.label"
										defaultMessage="Select file to import"
									/>
								}
								fullWidth
							/>
							<DashboardImportSelector />
						</Form>
					</DialogContent>
					<DialogActions>
						<FormikResetButton color="primary">
							<FormattedMessage
								id="dealer.dasbhoard.import-dialog.reset.label"
								defaultMessage="Cancel"
							/>
						</FormikResetButton>
						<FormikSubmitButton>
							<FormattedMessage
								id="dealer.dasbhoard.import-dialog.submit.label"
								defaultMessage="Import"
							/>
						</FormikSubmitButton>
					</DialogActions>
				</>
			</Formik>
		</Dialog>
	);
}

function DashboardImportSelector() {
	const { values } = useFormikContext<ImportForm>();

	const { files } = values;

	const [, meta] = useField("import");

	return (
		<FormControl variant="standard" margin="dense" fullWidth>
			<FormLabel>
				<FormattedMessage
					id="dealer.dasbhoard.import-dialog.selected.label"
					defaultMessage="Select dashboard to import"
				/>
			</FormLabel>
			<FormGroup>
				<FieldArray name="import">
					{array =>
						files.map(file => (
							<Suspense
								key={file.name}
								fallback={
									<FormControlLabel
										disabled
										control={<CheckBox />}
										label={<Skeleton variant="text" width="10ch" />}
									/>
								}
							>
								<DashboardFileImportSelectors file={file} array={array} />
							</Suspense>
						))
					}
				</FieldArray>
			</FormGroup>
			{meta.touched && typeof meta.error === "string" && (
				<FormHelperText error>{meta.error}</FormHelperText>
			)}
		</FormControl>
	);
}

class ImportedDashboardGridResource extends DashboardGridResource {
	readonly __guid: string = uuid();

	pk() {
		return this.__guid;
	}

	static get key() {
		return "ImportedDashboardGrid";
	}
}

const readDashboardJsonFileEndpoint = new Endpoint(
	async function <T>({ file }: { file: File }): Promise<T | undefined> {
		return new Promise(resolve => {
			// even if we polyfill, on server we don't want to actually wait to resolve the File
			if (typeof window === "undefined" || typeof FileReader !== "function")
				resolve(undefined);

			const reader = new FileReader();

			reader.onload = () => {
				const data = reader.result as string | null;
				resolve(data ? JSON.parse(data) : undefined);
			};
			reader.onerror = error => {
				// in case we fail to load we actually don't want to error out but
				// let the browser display the normal image fallback
				const data = reader.result as string | null;
				resolve(data ? JSON.parse(data) : undefined);
			};

			reader.readAsText(file);
		});
	},
	{
		schema: [ImportedDashboardGridResource],
		key({ file }: { file: File }) {
			return `READ_JSON ${file.name}`;
		}
	}
);

function DashboardFileImportSelectors(props: {
	file: File;
	array: FieldArrayRenderProps;
}) {
	const { file, array } = props;

	const dashboards = useCache(DashboardGridResource.list(), {}) ?? [];

	const importDashboards = useResource(readDashboardJsonFileEndpoint, {
		file
	});

	const value: ImportForm["import"] = getIn(array.form.values, array.name);

	return (
		<>
			{importDashboards.map((imported, i) => {
				const idx = value.findIndex(
					v =>
						v.fromFile === file.name &&
						ImportedDashboardGridResource.pk(v.original) === imported.pk()
				);

				return (
					<Grid container spacing={2} key={i}>
						<Grid item xs={12} md={4}>
							<FormControlLabel
								control={<Switch />}
								label={imported.name}
								checked={idx >= 0}
								onChange={(_, checked) => {
									if (idx < 0 && checked)
										array.push({
											fromFile: file.name,
											original: imported,
											replace: dashboards.find(d => d.name === imported.name),
											name: imported.name
										});
									if (idx >= 0 && !checked) array.remove(idx);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							{idx >= 0 && (
								<FormikAutocompleteField
									name={`import[${idx}].replace`}
									options={dashboards}
									getOptionLabel={o => o.name}
									getOptionSelected={(o, v) => o.pk() === v.pk()}
									label={
										<FormattedMessage
											id="dealer.dasbhoard.import-dialog.replacing.label"
											defaultMessage="Replace"
										/>
									}
									margin="dense"
									variant="outlined"
								/>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							{idx >= 0 && (
								<FormikTextField
									name={`import[${idx}].name`}
									margin="dense"
									variant="outlined"
									label={
										<FormattedMessage
											id="dealer.dasbhoard.import-dialog.name.label"
											defaultMessage="Name"
										/>
									}
								/>
							)}
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}
