import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";
import flow from "lodash/fp/flow";
import zipWith from "lodash/fp/zipWith";
import { AbstractInstanceType, SimpleRecord } from "@rest-hooks/rest";

type StandardEnum<T> = {
	[id: string]: T | string;
	[nu: number]: string;
};

function makeEnumAsList<T extends StandardEnum<unknown>>(someEnumType: T) {
	const keys = Object.keys(someEnumType).filter(
		k => typeof someEnumType[k as any] === "number"
	); // ["A", "B"]
	const values = keys.map(k => someEnumType[k as any]); // [0, 1]

	return zipWith((k: string, v: string | number) => ({
		key: k,
		value: v
	}))(keys, values);
}

function makeEnumAsLookup<T extends StandardEnum<unknown>>(someEnumType: T) {
	const keys = Object.keys(someEnumType).filter(
		k => typeof someEnumType[k as any] === "number"
	); // ["A", "B"]
	const values = keys.map(k => someEnumType[k as any]); // [0, 1]

	return flow(
		zipWith((v: string | number, k: string) => [v, k]),
		Object.fromEntries
	)(values, keys);
}

export enum IntegrationVendorAccessMode {
	NotSet = 0,
	LimitVendorsToExplicitlyAllowed = 1,
	MappedVendorAndSalesperson = 2,
	AllVendors = 3,
	ExplicitVendorAndSalesperson = 4
}
export const IntegrationVendorAccessModeList = makeEnumAsList(
	IntegrationVendorAccessMode
);
export const IntegrationVendorAccessModeLookup = makeEnumAsLookup(
	IntegrationVendorAccessMode
);

export enum IntegrationEntityMode {
	NotSet = 0,
	Vendor = 1,
	Salesperson = 2
}
export const IntegrationEntityModeList = makeEnumAsList(IntegrationEntityMode);
export const IntegrationEntityModeLookup = makeEnumAsLookup(
	IntegrationEntityMode
);

export enum IntegrationPropertyMode {
	NotSet = 0,
	Other = 1,
	MembershipId = 2,
	SalespersonId = 3
}
export const IntegrationPropertyModeList = makeEnumAsList(
	IntegrationPropertyMode
);
export const IntegrationPropertyModeLookup = makeEnumAsLookup(
	IntegrationPropertyMode
);

export enum IntegrationValueMode {
	NotSet = 0,
	RawString = 1,
	Boolean = 2
}
export const IntegrationValueModeList = makeEnumAsList(IntegrationValueMode);
export const IntegrationValueModeLookup =
	makeEnumAsLookup(IntegrationValueMode);

export interface IntegrationClientEntitySetting {
	readonly integrationClientEntitySettingId?: ID;
	readonly integrationClientId?: number;
	entityMode: IntegrationEntityMode;
	propertyMode: IntegrationPropertyMode;
	valueMode: IntegrationValueMode;
	propertyName: string;
	displayName?: string;
}

export interface IntegrationClient {
	readonly integrationClientId?: ID;
	name?: string;
	code: string;
	enabled: boolean;
	applicationExternalSystemCode?: string;
	vendorAccessMode: IntegrationVendorAccessMode;
	vendorProductGroupId?: number;
	defaultVendorId?: number;
	defaultSalespersonId?: number;
	allowTokenLessPartnerWebAccess?: boolean;
	allowTokenLessPartnerServiceAccess?: boolean;
	entitySettings?: IntegrationClientEntitySetting[];
}

export class IntegrationClientResource
	extends BaseResource
	implements IntegrationClient
{
	readonly integrationClientId?: ID;
	name?: string;
	code: string;
	enabled: boolean = false;
	applicationExternalSystemCode?: string;
	vendorAccessMode: IntegrationVendorAccessMode =
		IntegrationVendorAccessMode.NotSet;
	vendorProductGroupId?: number;
	defaultVendorId?: number;
	defaultSalespersonId?: number;
	allowTokenLessPartnerWebAccess?: boolean;
	allowTokenLessPartnerServiceAccess?: boolean;
	entitySettings?: IntegrationClientEntitySetting[] = [];

	pk() {
		return this.integrationClientId?.toString();
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	) {
		return second;
	}

	static urlTemplates = [
		apiResourceUrl("vendors/{vendorId}/defaultSalespersonIntegrations")
	];
	static urlRoot = apiResourceUrl("integrationClients");
}
