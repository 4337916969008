import React, { useCallback, Suspense } from "react";
import { Tooltip, Box, CircularProgress, Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { useFileViewStyles, StyleFileImg, StyleImg } from "./styles";
import { FileViewProps } from "./types";

export function ImagePreview(props: FileViewProps) {
	const {
		file,
		onRemove,
		onSelect,
		imgBackground,
		selected,
		children,
		className,
		...rest
	} = props;

	const classes = useFileViewStyles();

	const onClickRemove = useCallback(
		e => {
			e.stopPropagation();
			if (onRemove && file) onRemove(file);
		},
		[file, onRemove]
	);

	const onClickImage = useCallback(
		e => {
			e.stopPropagation();
			if (onSelect && file) onSelect(file);
		},
		[file, onSelect]
	);

	const filename = decodeURI(
		file instanceof File ? file.name : file.substring(file.lastIndexOf("/") + 1)
	);

	return (
		<Tooltip title={filename}>
			<div
				className={clsx(className, classes.root, classes.preview, {
					[classes.selected]: selected,
					[classes.selectable]: !!onSelect
				})}
				{...rest}
			>
				<Suspense
					fallback={
						<Box
							className={classes.img}
							minWidth={100}
							display="flex"
							alignItems="center"
							justifyContent="center"
							onClick={onClickImage}
						>
							<CircularProgress />
						</Box>
					}
				>
					{file instanceof File ? (
						<StyleFileImg
							className={classes.img}
							bgcolor={imgBackground}
							role="presentation"
							file={file}
							onClick={onClickImage}
						/>
					) : (
						<StyleImg
							className={classes.img}
							bgcolor={imgBackground}
							role="presentation"
							src={file}
							onClick={onClickImage}
						/>
					)}
				</Suspense>

				{onRemove && (
					<Fab
						size="small"
						type="button"
						onClick={onClickRemove}
						aria-label="Delete"
						className={clsx(classes.removeBtn, classes.removeFab)}
					>
						<DeleteIcon />
					</Fab>
				)}
			</div>
		</Tooltip>
	);
}
