import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";

export interface ExcelFunction {
	name?: string;
	spreadsheet?: string;
	details?: ExcelFunctionDetails;
	validation?: ExcelFunctionValidation;
}

export enum ExcelFunctionType {
	Empty = 0,
	CopyPasteVariant = 1,
	FileVariant = 2
}

export interface ExcelFunctionDetails {
	type: ExcelFunctionType;
}

export interface ExcelFunctionValidation {
	inputHash?: string;
	valid: boolean;
	spreadsheet?: string;
	errorMessage?: string;
	inputs: SpreadsheetVariable[];
	outputs: SpreadsheetVariable[];
}

export interface SpreadsheetVariable {
	coord: string;
	originalCoord: string;
	name: string;
	isNumeric: boolean;
	value: string;
}

export class ExcelFunctionResource
	extends BaseResource
	implements ExcelFunction
{
	readonly name?: string;
	readonly spreadsheet?: string;
	readonly details?: ExcelFunctionDetails;
	readonly validation?: ExcelFunctionValidation;

	pk(): string | undefined {
		return this.name?.toString();
	}

	static getEndpointExtra() {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: 120 * 1000,
			invalidIfStale: true
		};
	}

	static urlRoot = apiResourceUrl("decisionTableFunctions");

	static generateDownloadLink<T extends typeof BaseResource>(
		this: T,
		name: string
	): string {
		return `${this.urlRoot}/${name}/download`;
	}
}

export class ExcelFunctionValidationResource
	extends BaseResource
	implements ExcelFunctionValidation
{
	readonly spreadsheet?: string;
	readonly valid: boolean = false;
	readonly inputHash?: string;
	readonly errorMessage?: string;
	readonly inputs: SpreadsheetVariable[] = [];
	readonly outputs: SpreadsheetVariable[] = [];

	pk(): string | undefined {
		return this.inputHash?.toString();
	}

	static urlRoot = apiResourceUrl("decisionTableFunctions/validate");
}
