import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export class HouseOwner {
	houseOwnerId: number;
	dateOfBirth: Date;
	organizationNumber: string;
	personalNumber: string;
	name: string;
	isNorwegianCitizen: boolean;
	owningFractionNumerator: number;
	owningFractionDenominator: number;
	isResident: boolean;

	get displayValue(): string {
		const fraction =
			this.owningFractionDenominator === 0
				? 100
				: (this.owningFractionNumerator / this.owningFractionDenominator) * 100;
		return `${this.name};${fraction}`;
	}

	set displayValue(value: string) {}
}

export class Lien {
	lienId: number;
	documentId: string;
	registrationDate: Date;
	lienType: string;
	infoAsText: string;
	value: number;
}

export class SpecialConstraint {
	specialConstraintId: number;
	documentId: string;
	registrationDate: Date;
	name: string;
	warningLevel?: number | null;
	isJudicialRegistered?: boolean | null;
}

export class ApplicationPropertiesResource extends BaseResource {
	applicationId: number;
	applicationPropertyId: number;
	propertyId: string;
	type: string;
	cadastralNumber: string;
	municipalityNumber: number;
	cadastralUnitNumber: number;
	unitNumber: number;
	leaseholdUnitNumber: number;
	sectionNumber: number;
	housingCooperativeName: string;
	housingCooperativeOrgNumber: string;
	housingCooperativeAccountant: string;
	shareNumber: number;
	streetName: string;
	addressNumber: number;
	addressLetter: string;
	postalCode: string;
	postalPlace: string;
	refinance: boolean;
	valueEnteredByCustomer: number;
	ownedByOthers: boolean;
	estimatedValue: number;
	jointDebt: number;
	eAppraisal: number;
	utilizedValue: number;
	utilizedSecurityValue: number;
	eAppraisalReference: string;
	mainCustomerResidence: boolean;
	useType: string;
	owners: HouseOwner[];
	liens: Lien[];
	specialConstraints: SpecialConstraint[];

	get address(): string {
		return `${this.streetName} ${this.addressNumber}${
			this.addressLetter ? this.addressLetter : ""
		}`;
	}

	pk() {
		return this.applicationPropertyId.toString();
	}

	static urlRoot = apiResourceUrl("property/{applicationId}");
}
