export function dateAdd(
	{ years = 0, months = 0, days = 0 },
	date = new Date()
) {
	return new Date(
		date.setFullYear(
			date.getFullYear() + years,
			date.getMonth() + months,
			date.getDay() + days
		)
	);
}

export function calculate(params: {
	price?: number;
	amount?: number;
	mvaPercentage?: number;
	allowedDeviation?: number;
}) {
	let {
		price = 0,
		amount = 0,
		mvaPercentage = 0,
		allowedDeviation = 0
	} = params;
	if (amount < allowedDeviation) {
		amount = 0;
	}

	const net = amount * price;
	const vat = (net * mvaPercentage) / 100;
	const gross = net + vat;
	return { net, vat, gross };
}
