import { apiResourceUrl } from "@ploy-lib/core";
import paramsToString, { BaseResource } from "./BaseResource";
import { Entity, SimpleResource } from "@rest-hooks/rest";

class ObjectVehicleDetailsEntity extends Entity {
	readonly carMake?: string = undefined;
	readonly carModel?: string = undefined;
	readonly carEngine?: string = undefined;
	readonly yearModel?: string = undefined;
	readonly regNr?: string = undefined;

	pk(parent?: any, key?: string) {
		return "objectVehicleDetails";
	}
}

class CommonFinancialDetailsEntity extends Entity {
	readonly listPrice?: number = undefined;
	readonly carPrice?: number = undefined;
	readonly equity?: number = undefined;
	readonly equityPercent?: number = undefined;
	readonly loanAmount?: number = undefined;
	readonly termsTotal?: number = undefined;
	readonly termLengthType?: string = undefined;
	readonly totalMonths?: number = undefined;

	pk(parent?: any, key?: string) {
		return "commonFinancialDetails";
	}
}

class RatesAndFeesDetailsEntity extends Entity {
	readonly netTotalTermAmount?: number = undefined;
	readonly nominalInterestRate?: number = undefined;
	readonly actualInterestRate?: number = undefined;
	readonly establishmentFee?: number = undefined;
	readonly documentFee?: number = undefined;
	readonly termFee?: number = undefined;

	pk(parent?: any, key?: string) {
		return "ratesAndFeesDetails";
	}
}

export interface FinancingInformationData {
	readonly applicationId?: number;

	readonly objectType?: string;
	readonly carType?: string;

	// For use of ObjectDTO
	readonly carObjectData?: ObjectVehicleDetailsEntity;

	// Common financial details
	readonly commonFinancialData?: CommonFinancialDetailsEntity;

	// Rates and fees
	readonly ratesAndFeesData?: RatesAndFeesDetailsEntity;
}

export class FinancingInformationResource
	extends BaseResource
	implements FinancingInformationData
{
	readonly applicationId?: number = undefined;

	readonly objectType?: string = undefined;
	readonly carType?: string = undefined;

	readonly carObjectData?: ObjectVehicleDetailsEntity = undefined;
	readonly commonFinancialData?: CommonFinancialDetailsEntity = undefined;
	readonly ratesAndFeesData?: RatesAndFeesDetailsEntity = undefined;

	pk(parent?: any, key?: string): string | undefined {
		return `${this.applicationId?.toString()}`;
	}

	static url<T extends typeof SimpleResource>(
		this: T,
		urlParams: Readonly<Record<string, any>>
	) {
		const { applicationId, ...searchParams } = urlParams;

		const url = super.url(urlParams);

		return `${url}${paramsToString(searchParams)}`;
	}

	static schema = {
		carObjectData: ObjectVehicleDetailsEntity,
		commonFinancialData: CommonFinancialDetailsEntity,
		ratesAndFeesData: RatesAndFeesDetailsEntity
	};

	static urlRoot = apiResourceUrl("financing-information");
}
