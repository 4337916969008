import {
	TemplatePage,
	TemplatePageDeprecated,
	TemplateSection,
	TemplatePanel,
	TemplateField
} from "@ploy-lib/types";
import { v4 as uuidv4 } from "uuid";
import { splitMarginBottomFields } from "./convertTemplateLayouts";
import { isNotNull } from "./utils";

export function createPostProcessHeader(): TemplatePanel {
	return {
		formPanelId: uuidv4(),
		sections: [
			{
				formTemplateSectionId: uuidv4(),
				layout: "ApplicationStatusSection",
				fields: []
			}
		]
	};
}

export function createPostProcessFooter(): TemplatePanel {
	return {
		formPanelId: uuidv4(),
		sections: [
			{
				formTemplateSectionId: uuidv4(),
				layout: "ESigningExplanationSection",
				fields: []
			}
		]
	};
}

export function createPostProcessSummaryHeader(
	section: TemplateSection = emptyTemplateSection()
): TemplatePanel {
	return {
		formPanelId: uuidv4(),
		elevation: 0,
		sections: [
			{
				...section,
				sectionTitle: undefined,
				fields: section.fields.map(f => ({
					...f,
					alignWithPrevious: true
				})),
				literal: true
			}
		]
	};
}

export function createPostProcessActions(
	section: TemplateSection = emptyTemplateSection()
): TemplatePanel {
	return {
		formPanelId: uuidv4(),
		sections: [
			{
				...section,
				sectionTitle: undefined,
				fields: section.fields.map(f =>
					f.name === "SendToHome"
						? {
								...f,
								role: "navigate_home",
								color: "secondary",
								variant: "contained",
								margin: "none",
								width: f.width || "auto"
						  }
						: {
								...f,
								margin: "none",
								width: f.width || "auto"
						  }
				),
				literal: true
			}
		]
	};
}

const emptyTemplateSection = (): TemplateSection => ({
	fields: [],
	formTemplateSectionId: uuidv4()
});

export function createPostProcessMain(
	sections: TemplateSection[],
	summary: TemplateField[]
): TemplatePanel {
	const [, customerInfoSection = undefined, , extraFieldsSection = undefined] =
		sections;

	const coSignerInfoSection = sections.find(
		s => s.style === "cosigner" || s.sectionTitle === "Cosigner info"
	);

	const vendorCommentSection = sections.find(
		s => s.style === "vendor-comment-list"
	);

	const incomeExpensesSection = sections.find(
		s => s.style === "income-expenses-fields"
	);

	return {
		formPanelId: uuidv4(),
		elevation: 0,
		sections: [
			{
				formTemplateSectionId: uuidv4(),
				literal: true,
				layout: "MarkdownSection",
				mainText: "{DocumentInformation}",
				hideSectionIfNoVisibleFields: true,
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						namespace: "Main",
						name: "DocumentInformation"
					}
				]
			},
			{
				formTemplateSectionId: uuidv4(),
				literal: true,
				layout: "MarkdownSection",
				mainText: "{EsignInformation}",
				hideSectionIfNoVisibleFields: true,
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						namespace: "Main",
						name: "EsignInformation"
					}
				]
			},
			{
				formTemplateSectionId: uuidv4(),
				layout: "CustomerInfoSection",
				fields: customerInfoSection?.fields ?? []
			},
			...splitMarginBottomFields(summary).map(fields => ({
				formTemplateSectionId: uuidv4(),
				group: "Summary",
				sectionTitle: "Oppsummering",
				fields,
				literal: true
			})),
			coSignerInfoSection
				? {
						...coSignerInfoSection,
						sectionTitle: "Medsøker",
						literal: true,
						group: "Cosigner",
						fields: [
							...coSignerInfoSection.fields.map(f => ({
								...f,
								literal: true
							})),
							{
								formTemplateFieldId: uuidv4(),
								name: "CoSignerHeader",
								namespace: "Main",
								role: "visibilityFilter"
							}
						],
						// TODO TS2783: safe to remove?
						formTemplateSectionId:
							coSignerInfoSection.formTemplateSectionId ?? uuidv4()
				  }
				: null,
			{
				formTemplateSectionId: uuidv4(),
				group: "Commission",
				sectionTitle: "Provisjoner",
				layout: "CommisionSection",
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						name: "CommissionResultUnconditionally",
						namespace: "Calculator",
						role: "visibilityFilter"
					}
				]
			},
			{
				formTemplateSectionId: uuidv4(),
				layout: "DocumentStatusSection",
				fields: []
			},
			{
				formTemplateSectionId: uuidv4(),
				group: "Signing",
				sectionTitle: "Signering",
				layout: "ApplicationSigningSection",
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						name: "SendToEsign",
						namespace: "DocumentProduction",
						role: "visibilityFilter"
					}
				]
			},
			{
				formTemplateSectionId: uuidv4(),
				group: "DocumentUploadSection",
				sectionTitle: "Dokumentopplasting",
				layout: "DocumentUploadSection",
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						name: "UploadDocumentButton",
						namespace: "Document",
						role: "visibilityFilter"
					}
				]
			},
			{
				formTemplateSectionId: uuidv4(),
				group: "Fulfillment",
				sectionTitle: "Innfrielser",
				layout: "FulfillmentSection",
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						name: "EP_FulfillmentAmount",
						namespace: "Calculator",
						role: "visibilityFilter"
					}
				]
			},
			{
				formTemplateSectionId: uuidv4(),
				group: "Refinancing",
				sectionTitle: "Refinansiering",
				layout: "RefinancingSection",
				fields: [
					{
						formTemplateFieldId: uuidv4(),
						name: "EP_RefinancingAmount",
						namespace: "Calculator",
						role: "visibilityFilter"
					}
				]
			},
			{
				...extraFieldsSection,
				sectionTitle: undefined,
				fields: extraFieldsSection
					? [
							...extraFieldsSection.fields.map(
								(f): TemplateField => ({
									...f,
									...(f.literal
										? {
												width: "12",
												variant: "plain"
										  }
										: { save: { type: "button" }, disabled: false })
								})
							),
							{
								formTemplateFieldId: uuidv4(),
								name: "InputFieldsSection",
								namespace: "Main",
								role: "visibilityFilterInverse"
							}
					  ]
					: [],
				group: "InputFields",
				expandInitially: true,
				// TODO TS2783: safe to remove?
				formTemplateSectionId:
					extraFieldsSection?.formTemplateSectionId ?? uuidv4()
			},
			incomeExpensesSection
				? {
						...incomeExpensesSection,
						sectionTitle: "Inntekter",
						group: "IncomeExpenses",
						fields: [
							...incomeExpensesSection.fields,
							{
								formTemplateFieldId: uuidv4(),
								name: "IncomeExpensesSection",
								namespace: "PRIVATE_CUSTOMER_DATA",
								role: "visibilityFilter"
							}
						],
						// TODO TS2783: safe to remove?
						formTemplateSectionId:
							incomeExpensesSection.formTemplateSectionId ?? uuidv4()
				  }
				: null,
			vendorCommentSection
				? {
						...vendorCommentSection,
						sectionTitle: undefined,
						group: "Kommentarer",
						// TODO TS2783: safe to remove?
						formTemplateSectionId:
							vendorCommentSection.formTemplateSectionId ?? uuidv4()
				  }
				: null
		].filter(isNotNull)
	};
}

export function convertPostProcessPage(
	page: TemplatePageDeprecated
): TemplatePage {
	const { sections, summary, formPageId, pageTitle } = page;

	const [summaryHeaderSection = undefined, , actionsSection = undefined] =
		sections;

	return {
		formPageId: formPageId || uuidv4(),
		pageTitle,
		layout: "List",
		panels: {
			header: createPostProcessHeader(),
			summaryHeader: createPostProcessSummaryHeader(summaryHeaderSection),
			main: createPostProcessMain(sections, summary || []),
			actions: createPostProcessActions(actionsSection),
			footer: createPostProcessFooter()
		}
	};
}
