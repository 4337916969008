import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1
		},
		contentWrapper: {
			paddingTop: theme.spacing(3),
			paddingRight: theme.spacing(3),
			paddingBottom: theme.spacing(2),
			paddingLeft: theme.spacing(3),
			[theme.breakpoints.down("sm")]: {
				paddingTop: theme.spacing(1),
				paddingLeft: theme.spacing(0.5),
				paddingRight: theme.spacing(0.5),
				paddingBottom: theme.spacing(1)
			}
		},
		contentWrapperSmall: {
			margin: "auto",
			marginLeft: theme.spacing(1),
			textAlign: "left"
		},
		imageStretch: {
			flexGrow: 1
		},
		carMakeModelHeader: {
			fontSize: "150%",
			fontWeight: 500
		},
		carMakeModelHeaderSmall: {
			flex: "1 0 100%",
			color: "grey"
		},
		carMakeModelContentSmall: {
			fontSize: "100%",
			fontWeight: 500
		},
		cardText: {
			fontSize: "130%",
			fontWeight: 500
		},
		carPriceText: {
			fontSize: "140%",
			[theme.breakpoints.down("sm")]: {
				marginBottom: theme.spacing(1)
			},
			[theme.breakpoints.up("md")]: {
				marginTop: theme.spacing(2)
			}
		},
		carYearText: {
			marginBottom: theme.spacing(1)
		},
		noWrap: {
			flexFlow: "nowrap"
		},
		changeCarLink: {
			color: theme.palette.primary.main,
			textDecoration: "underline",
			cursor: "pointer",
			marginBottom: 6,
			fontSize: 17,
			fontWeight: "bold"
		},
		noMargin: {
			margin: 0
		}
	})
);
