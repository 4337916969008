import { BrowserDeprecation } from "@ploy-lib/types";
import { UAParser } from "ua-parser-js";
import compareVersions from "compare-versions";
import { useMemo } from "react";

const parser = new UAParser();
const today = new Date();

/**
 * Matching or closest newer version (compare using the same number of segments (major/minor/patch))
 *
 * Example: "84.1.522.26" matches: "84", "85", or "84.1", but not "83" or "84.0"
 */
function getClosestNewerVersionMatch(version: string, versions: string[]) {
	if (versions.includes("all")) return "all";
	if (versions.includes("*")) return "*";
	if (versions.includes(version)) return version;

	return versions
		.sort(compareVersions)
		.find(
			v =>
				compareVersions(
					v,
					version.split(".").slice(0, v.split(".").length).join(".")
				) >= 0
		);
}

export function getBrowserDeprecationStatus(
	browserDeprecation: BrowserDeprecation
) {
	try {
		const browser = parser.getBrowser();
		const version = browser.version;

		const deprecatedVersions = browser.name
			? browserDeprecation[browser.name]
			: undefined;

		const deprecatedVersion =
			deprecatedVersions &&
			version &&
			getClosestNewerVersionMatch(version, Object.keys(deprecatedVersions));

		const dateStr =
			deprecatedVersion && deprecatedVersions?.[deprecatedVersion];

		const date = dateStr ? new Date(dateStr) : undefined;

		if (date)
			return {
				deprecated: true as const,
				supported: date > today,
				browser,
				os: parser.getOS(),
				date,
				deprecatedVersion
			};
	} catch (e: any) {
		console.warn("Failed to check for deprecated version");
		console.warn(e);
	}

	return {
		deprecated: false as const,
		supported: true as const
	};
}

export function useBrowserDeprecationStatus(
	browserDeprecation: BrowserDeprecation
) {
	return useMemo(
		() => getBrowserDeprecationStatus(browserDeprecation),
		[browserDeprecation]
	);
}
