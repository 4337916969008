import { useCallback, useMemo, useRef } from "react";
import {
	FormikFormControlFieldset,
	FormikSubmitButton,
	FormikResetButton,
	DashboardGridResource,
	DashboardGrid,
	FormikFormControlLabel,
	FormikTextField
} from "@ploy-ui/dashboard";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormGroup,
	InputAdornment,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Form, Formik } from "formik";
import { useCache, useRetrieve } from "@rest-hooks/core";
import { saveAs } from "file-saver";
import { array, object, string } from "yup";
import { isNotNull } from "@ploy-lib/core";

export interface DashboardsExportDialogProps {
	editedDashboards: Record<string, DashboardGrid>;
	onClose: () => void;
	open: boolean;
}

const messages = defineMessages({
	dashboardRequired: {
		id: "dealer.dashboard.export.dashboards.validation-required",
		defaultMessage: "Choose one or more dashboards to import"
	},
	filenameRequired: {
		id: "dealer.dashboard.export.filename.validation-required",
		defaultMessage: "Enter file name for the exported file"
	}
});

export function DashboardsExportDialog(props: DashboardsExportDialogProps) {
	const { onClose, open, editedDashboards } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const dashboards = useCache(DashboardGridResource.list(), {});

	const allDashboardOptions = useMemo(
		() => [
			...(dashboards?.map(d => ({
				value: d.pk()!,
				label: `${d.name}${editedDashboards?.[d.pk()!] ? " *" : ""}`
			})) ?? []),
			...Object.entries(editedDashboards ?? {})
				.filter(([pk]) => !dashboards?.some(d => d.pk() === pk))
				.map(([pk, d]) => ({
					value: pk,
					label: `${d.name} *`
				}))
		],
		[dashboards, editedDashboards]
	);

	const allDashboardsPromise = useRetrieve(
		DashboardGridResource.list(),
		open ? { includeContent: true } : null
	);

	const initialValues = {
		dashboards: allDashboardOptions.map(x => x.value),
		filename: `dashboards_${new Date().toISOString().slice(0, 10)}`
	};

	const dashboardsRef = useRef(dashboards ?? []);
	dashboardsRef.current = dashboards ?? [];

	const onSubmit = useCallback(
		async (values: typeof initialValues) => {
			// dashboardsRef will be updated with complete data when the "useRetrieve" resolves
			if (allDashboardsPromise) await allDashboardsPromise;

			const exporting = allDashboardOptions
				.map(o => o.value)
				.filter(id => values.dashboards.includes(id))
				.map(id => {
					if (editedDashboards[id]) return editedDashboards[id];
					else return dashboardsRef.current.find(d => d.pk() === id);
				})
				.filter(isNotNull);

			saveAs(
				new Blob([JSON.stringify(exporting, null, 2)], {
					type: "application/json"
				}),
				values.filename
			);

			onClose();
		},
		[allDashboardOptions, allDashboardsPromise, editedDashboards, onClose]
	);

	const intl = useIntl();

	const validationSchema = useMemo(() => {
		return object<typeof initialValues>({
			dashboards: array(string())
				.required(intl.formatMessage(messages.dashboardRequired))
				.min(1, intl.formatMessage(messages.dashboardRequired)),
			filename: string().required(intl.formatMessage(messages.filenameRequired))
		});
	}, [intl]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullScreen={isMobile}
			aria-labelledby="dashboard-name-export-dialog-title"
		>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onClose}
				validationSchema={validationSchema}
			>
				<>
					<DialogTitle id="dashboard-export-dialog-title">
						<FormattedMessage
							id="dealer.dasbhoard.export-dialog.title"
							defaultMessage="Export dashboard"
						/>
					</DialogTitle>
					<DialogContent>
						<Form>
							<FormikFormControlFieldset
								margin="dense"
								id="dashboards"
								name="dashboards"
								label={
									<FormattedMessage
										id="dealer.dasbhoard.export-dialog.name.label"
										defaultMessage="Select dashboards to export"
									/>
								}
								fullWidth
							>
								<FormGroup>
									{allDashboardOptions.map(({ label, value }) => (
										<FormikFormControlLabel
											key={value}
											type="checkbox"
											name="dashboards"
											label={label}
											value={value}
										/>
									))}
								</FormGroup>
							</FormikFormControlFieldset>
							<FormikTextField
								autoFocus
								margin="dense"
								id="filename"
								name="filename"
								label={
									<FormattedMessage
										id="dealer.dasbhoard.export-dialog.filename.label"
										defaultMessage="File name"
									/>
								}
								type="text"
								variant="outlined"
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">.json</InputAdornment>
									)
								}}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<FormikResetButton color="primary">
							<FormattedMessage
								id="dealer.dasbhoard.export-dialog.reset.label"
								defaultMessage="Cancel"
							/>
						</FormikResetButton>
						<FormikSubmitButton>
							<FormattedMessage
								id="dealer.dasbhoard.export-dialog.submit.label"
								defaultMessage="Export"
							/>
						</FormikSubmitButton>
					</DialogActions>
				</>
			</Formik>
		</Dialog>
	);
}
