import { PageContextValue } from "./PageContext";
import { TemplatePanel, TemplateField, TemplateSection } from "@ploy-lib/types";

import { defineMessages, IntlShape } from "react-intl";

import { ActionRole } from "./field/Buttons/Button";

const emptyActionsSection: TemplateSection = {
	formTemplateSectionId: "actions_section",
	fields: []
};

const createEmptyActionsPanel = <T extends string>(name: T): TemplatePanel => ({
	formPanelId: name,
	sections: []
});

const ensureActionExists = (
	role: ActionRole | undefined,
	actions: TemplatePanel,
	append: boolean,
	fieldOptions?: Partial<TemplateField>,
	fieldPredicate?: (field: TemplateField) => boolean
): TemplatePanel => {
	const defaultAction: TemplateField = {
		formTemplateFieldId: `${role}_button`,
		name: `${role}_button`,
		namespace: "default_actions",
		label: "",
		renderAs: "ButtonField",
		alwaysVisible: true,
		role,
		width: "auto",
		alignWithPrevious: true,
		variant: "contained",
		margin: "none",
		...fieldOptions
	};

	const actionSections = actions.sections || [];

	const innerFieldPredicate = fieldPredicate ?? (f => f.role === role);

	if (!actionSections.some(s => s.fields.some(innerFieldPredicate))) {
		const [section = emptyActionsSection, ...rest] = append
			? actionSections.reverse()
			: actionSections;

		const fields = append
			? [...section.fields, defaultAction]
			: [defaultAction, ...section.fields];

		const sectionWithAction: TemplateSection = {
			...section,
			fields
		};

		const sectionsWithActions = [sectionWithAction, ...rest];

		return {
			...actions,
			sections: append ? sectionsWithActions.reverse() : sectionsWithActions
		};
	}
	return actions;
};

const emptyActionsPanel = createEmptyActionsPanel("actions");
export const ensureActions = (
	intl: IntlShape,
	{ step, isLastStep }: Pick<PageContextValue, "step" | "isLastStep">,
	actions: TemplatePanel = emptyActionsPanel,
	canSubmit?: boolean,
	prevLabel?: string,
	nextLabel?: string,
	submitLabel?: string
): TemplatePanel => {
	if (step > 0) {
		actions = ensureActionExists("navigate_before", actions, false, {
			label: prevLabel ?? intl.formatMessage(messages.before)
		});
	}

	if (!isLastStep) {
		actions = ensureActionExists("navigate_next", actions, true, {
			color: "secondary",
			label: nextLabel ?? intl.formatMessage(messages.next)
		});
	} else if (canSubmit) {
		actions = ensureActionExists(
			"submit",
			actions,
			true,
			{
				label: submitLabel ?? intl.formatMessage(messages.submit),
				color: "secondary",
				click: {
					namespace: "Main",
					service: "Submit"
				},
				renderAs: "SubmitButton"
			},
			// Skip fields already with submit role or SubmitButton as renderAs
			f => f.role === "submit" || f.renderAs === "SubmitButton"
		);
	}
	return actions;
};

const emptyAppActionsPanel = createEmptyActionsPanel("appActions");
export const ensureAppActions = (
	intl: IntlShape,
	{ isLastStep }: Pick<PageContextValue, "step" | "isLastStep">,
	appActions: TemplatePanel = emptyAppActionsPanel
): TemplatePanel => {
	if (isLastStep) {
		appActions = ensureActionExists("appActions", appActions, false, {
			width: "auto",
			label: intl.formatMessage(messages.moreOptions),
			variant: "contained"
		});
	}
	return appActions;
};

const messages = defineMessages({
	next: {
		id: "dealer.applications.actions.nextButton.label",
		description: "Default label for next button",
		defaultMessage: "Next"
	},
	before: {
		id: "dealer.applications.actions.beforeButton.label",
		description: "Default label for before button",
		defaultMessage: "Back"
	},
	submit: {
		id: "dealer.applications.actions.submitButton.label",
		description: "Default label for submit button",
		defaultMessage: "Submit application"
	},
	moreOptions: {
		id: "dealer.applications.actions.moreOptions.label",
		description: "Default label for more options button",
		defaultMessage: "Options"
	}
});
