import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	Cell,
	Column,
	ColumnInstance,
	HeaderGroup,
	IdType,
	Row,
	useGlobalFilter,
	useGroupBy,
	useSortBy,
	useRowSelect,
	useTable,
	usePagination,
	CellProps
} from "react-table";
import "./react-table-config";
import "./types";
import { useIntl } from "react-intl";
import { Formik, useField, useFormikContext } from "formik";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { SectionProps } from "../../../Section";
import { TableToolbar } from "./TableToolbar";
import { DataProviderProps } from "../TableDataProvider";
import messages from "../messages";
import { useEditableActionsColumn, useSelectionColumn } from "./table-plugins";
import { toFakeCamelCase } from "../utils";
import { DployFormControl, SelectField } from "@ploy-ui/form-fields";
import { ErrorDisplay, TableColumnOptionSource } from "@ploy-lib/types";
import { EditingSchemaField, SimpleTextLiteral } from "./TableFields";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { RowActions } from "./types";
import {
	standardValidation,
	requiredValidation,
	TableColumnMetadata
} from "../tableSchema";
import { tableSchemas } from "../tableSchema";
import clsx from "clsx";
import { TableHelperFields } from "../types";
import { toLower } from "lodash";

const useStyles = makeStyles(theme => ({
	divider: {
		backgroundColor: "lightgrey",
		width: "4px",
		padding: "0px"
	},
	transparent: {
		color: theme.palette.text.primary,
		backgroundColor: "transparent !important",
		border: "0px"
	},
	lowContrastText: {
		color: theme.palette.text.disabled
	},
	formControl: {
		marginTop: 0
	},
	lastSection: {
		marginBottom: 10
	}
}));

export function FormTable<T extends {}>(
	props: SectionProps & DataProviderProps<T> & { lastSection?: boolean }
) {
	const {
		tableSchema,
		rows: tableData,
		tableColumns,
		sectionTitle: title,
		tableFilter,
		saveElement,
		deleteElement,
		mergeColumnValues,
		submitOnRowChange,
		emptyTableMessage,
		borderlessTable,
		collapsedSection,
		fullWidth,
		lastSection = false,
		literal,
		extendedMetadata
	} = props;

	const schema = tableSchema ? tableSchemas?.[tableSchema] : undefined;
	const metaData = schema?.metaData as Partial<
		Record<string, TableColumnMetadata>
	>;

	const { submitForm } = useFormikContext();
	const classes = useStyles();

	const canEdit = props.tableIsEditable && !literal;
	const canAdd = props.canAddNewRows && props.tableIsEditable && !literal;
	const canDelete = props.canDeleteRow && props.tableIsEditable && !literal;

	const intl = useIntl();
	const [editingRowIds, setEditingRowIds] = useState(
		{} as Record<IdType<T>, boolean>
	);

	const skipPageResetRef = React.useRef(false);
	useEffect(() => {
		// After the table has updated, always remove the flag
		skipPageResetRef.current = false;
	});

	const isEditing = Object.values(editingRowIds).some(x => x);

	const [newRow, setNewRow] = useState(null as T | null);
	const data = useMemo(
		() =>
			newRow != null
				? [...tableData.map((t, i) => ({ ...t, index: i })), newRow]
				: tableData.map((t, i) => ({ ...t, index: i })),
		[tableData, newRow]
	);

	const setRowEditing = useCallback(
		(id, editing) => {
			var newEditingRowIds = { ...editingRowIds };
			if (editing === true) newEditingRowIds[id as IdType<T>] = true;
			else {
				newEditingRowIds[id as IdType<T>] = false;
				if (data.length - 1 === id) setNewRow(null);
			}
			setEditingRowIds(newEditingRowIds);
		},
		[editingRowIds, setEditingRowIds, setNewRow, data.length]
	);

	const onRowSave = useCallback<RowActions<T>["onRowSave"]>(
		async (id, index, values) => {
			skipPageResetRef.current = true;
			var newRows = [...tableData];
			newRows[index] = { ...newRows[index], ...values, edited: true };
			if (saveElement) saveElement(newRows);
			if (submitOnRowChange) {
				await submitForm();
			}
		},
		[tableData, saveElement, submitOnRowChange, submitForm]
	);

	const onRowDelete = useCallback<RowActions<T>["onRowDelete"]>(
		async (id, index) => {
			skipPageResetRef.current = true;
			if (deleteElement) await deleteElement(data, index);
		},
		[deleteElement, data]
	);

	const handleAddRowClick = useCallback(
		(cell?: Cell<T>) => {
			const newRow = {} as T;

			if (cell) {
				(newRow as any)[cell.column.id] = cell.row.values[cell.column.id];
			}

			if (metaData) {
				Object.keys(metaData).forEach(fieldName => {
					if (metaData?.[fieldName]?.defaultValue !== undefined) {
						newRow[fieldName] = metaData[fieldName]?.defaultValue;
					}
				});
			}

			setRowEditing(data.length, true);
			setNewRow(newRow);
		},
		[metaData, setRowEditing, data.length]
	);

	const columns = useMemo(() => {
		let columns = [] as Column<T>[];
		columns.push(
			...(tableColumns?.map((tc): Column<T> => {
				const name = toFakeCamelCase(tc.name);
				const messageId = `${toLower(
					tc.tableType.split("[].")[0].replace("_", "")
				)}.${name}`;
				const isDivider = name === "divider";

				const schemaName = getSchemaName(name);

				const relevantMetadata =
					metaData?.[schemaName] ??
					extendedMetadata?.[schemaName] ??
					extendedMetadata?.[tc.name];

				const aggregateFooter = relevantMetadata?.aggregateFooter;
				const schemaAggregate = relevantMetadata?.aggregate;
				const aggregate = schemaAggregate
					? schemaAggregate
					: tc.showSum
					? rows => rows.reduce((sum, row) => (row?.[schemaName] ?? 0) + sum, 0)
					: undefined;

				return {
					id: name,
					accessor: name as keyof T,
					disableGlobalFilter: !tableFilter?.includes(name),
					defaultCanGroupBy: tc.isGroupingColumn,
					disableSortBy: true,
					aggregate: aggregate,
					canEdit: tc.editable,
					optionValues: tc.optionValues,
					groupLabel: tc.groupLabel,
					isDivider: isDivider,
					Header: tc.label
						? tc.label
						: messages[messageId]
						? intl.formatMessage(messages[messageId])
						: tc.name,
					Aggregated: ({ row }) => {
						if (aggregate) {
							const value = aggregate(row.leafRows.map(lr => lr.original));
							return (
								<SimpleTextLiteral
									tc={tc}
									value={value ?? ""}
									fieldMetadata={relevantMetadata}
								/>
							);
						}
						return null;
					},
					Footer: ({ data }) => {
						if (tc.isGroupingColumn && tc.footerGroupLabel)
							return (
								<Typography
									variant="subtitle2"
									style={{ fontWeight: "inherit" }}
								>
									{tc.footerGroupLabel}
								</Typography>
							);
						if (aggregate || aggregateFooter) {
							const value = aggregateFooter?.(data) ?? aggregate?.(data);
							return (
								<SimpleTextLiteral
									tc={tc}
									value={value ?? ""}
									fieldMetadata={relevantMetadata}
								/>
							);
						}
						return null;
					},
					Editing: ({ row, column, form }) => {
						return (
							<EditingSchemaField
								tc={tc}
								fieldId={column.id}
								form={form}
								fieldMetadata={relevantMetadata}
							/>
						);
					},
					Cell: ({
						value,
						column,
						row,
						cell,
						isFirstInGroup
					}: CellProps<T>) => {
						const [field] = useField(`${column.id}`);
						var displayValueFunc = metaData?.[name]?.displayValue;
						var displayValue = value;
						if (displayValueFunc)
							displayValue =
								displayValueFunc?.(
									value,
									row.original,
									row.cells,
									cell.isPlaceholder,
									isFirstInGroup
								) ?? value;
						return (
							<>
								<SimpleTextLiteral
									tc={tc}
									data-cr-field={field.name}
									value={displayValue ?? ""}
									items={tc.allOptionValues || tc.optionValues}
									multiple={tc.isMultipleSelect}
									hrefLink={tc.hrefLink?.replace("{{value}}", value)}
									fieldMetadata={relevantMetadata}
								/>

								{canAdd && cell.isPlaceholder && isFirstInGroup && (
									<div
										data-cr-field={
											schema ? schema["dployTesterContext"] : undefined
										}
										data-cr-component={"Button"}
										id={
											schema
												? "AddRowButton" + schema["dployTesterContext"]
												: undefined
										}
									>
										<IconButton
											color="secondary"
											onClick={() => handleAddRowClick(cell)}
										>
											<AddIcon />
										</IconButton>
									</div>
								)}
							</>
						);
					}
				};
			}) ?? [])
		);

		return columns;
	}, [
		canAdd,
		handleAddRowClick,
		intl,
		metaData,
		extendedMetadata,
		schema,
		tableColumns,
		tableFilter
	]);

	const initialState = useMemo(() => {
		const hiddenCols: string[] = [];
		if (!props.multiselect) hiddenCols.push("selection");
		if (literal || !props.tableIsEditable) hiddenCols.push("editing");

		const initialGroupBy = columns
			.filter(c => c.defaultCanGroupBy)
			.map(c => c.id as string);

		return {
			pageSize: 25,
			pageIndex: 0,
			groupBy: initialGroupBy,
			hiddenColumns: hiddenCols
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const disableGlobalFilter = (tableFilter?.length ?? 0) === 0;

	const onSelect = useCallback<
		RowActions<T & TableHelperFields>["onRowSelect"]
	>(
		async (index, select, globalFilteredRowsById) => {
			skipPageResetRef.current = true;

			var newRows = tableData.map((x, i) => ({
				...x,
				selected:
					index != null
						? index === i
							? select
							: x.selected
						: globalFilteredRowsById && globalFilteredRowsById[i]
						? select
						: x.selected
			}));

			if (saveElement) await saveElement(newRows);
		},
		[saveElement, tableData]
	);
	const {
		getTableProps,
		headerGroups,
		footerGroups,
		page,
		prepareRow,
		setGlobalFilter,
		visibleColumns,
		rowsById,
		deleteRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, selectedRowIds, globalFilter, sortBy, groupBy }
	} = useTable<T>(
		{
			columns,
			data: data,
			initialState: initialState,
			disableGlobalFilter,
			expandSubRows: false,
			autoResetPage: !skipPageResetRef.current,
			autoResetExpanded: !skipPageResetRef.current,
			autoResetGroupBy: !skipPageResetRef.current,
			autoResetGlobalFilter: !skipPageResetRef.current,
			autoResetSelectedRows: !skipPageResetRef.current,
			autoResetFilters: !skipPageResetRef.current,
			autoResetRowState: !skipPageResetRef.current,
			autoResetSortBy: false,
			editingRowIds,
			isEditing,
			canAdd,
			canEdit,
			canDelete,
			setRowEditing,
			onRowDelete,
			onSelect,
			schema,
			pageCount: -1
		},
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		// useExpanded,
		// useExpandedFooter,
		usePagination,
		useRowSelect,
		useSelectionColumn,
		useEditableActionsColumn
	);

	const handleDeleteSelected = useCallback(() => {
		const rowsToRemove = Object.entries(selectedRowIds)
			.filter(([_, selected]) => selected)
			.map(([id]) => rowsById[id])
			// Remove the higher index first, otherwise the indexes will change
			.sort((a, b) => b.index - a.index);

		for (const row of rowsToRemove) {
			deleteRow(row.id);
		}
	}, [deleteRow, rowsById, selectedRowIds]);

	const rowsToRender = useMemo(
		() =>
			mergeColumnValues
				? page
				: (data?.length > 0 && groupBy.length > 0 && sortBy.length === 0
						? page.sort(
								(a, b) =>
									data.findIndex(
										x => x[a.groupByID as string]?.toString() === a.groupByVal
									) -
									data.findIndex(
										x => x[b.groupByID as string]?.toString() === b.groupByVal
									)
						  )
						: page
				  ).flatMap(x => [...x.subRows, x]),
		[data, groupBy.length, mergeColumnValues, page, sortBy.length]
	);

	if (emptyTableMessage && rowsToRender.length === 0) {
		return (
			<Typography
				variant="body1"
				className={clsx({ [classes.lowContrastText]: collapsedSection })}
			>
				{emptyTableMessage}
			</Typography>
		);
	}
	return (
		<DployFormControl
			className={clsx(classes.formControl, {
				[classes.lastSection]: !lastSection
			})}
			fullWidth={fullWidth}
		>
			{disableGlobalFilter && !props.multiselect ? null : (
				<TableToolbar
					searchValue={globalFilter}
					onSearch={disableGlobalFilter ? undefined : setGlobalFilter}
					title={title}
					numSelected={
						Object.values(selectedRowIds ?? {}).filter(Boolean).length
					}
					canDelete={canDelete}
					canNextPage={canNextPage}
					onDelete={handleDeleteSelected}
				/>
			)}
			<TableContainer
				data-cr-field={
					schema ? schema["dployTesterContext"] + "TableContainer" : undefined
				}
			>
				<Table {...getTableProps()}>
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<TableCell
										{...column.getHeaderProps(column.getSortByToggleProps?.())}
										className={clsx({
											[classes.divider]: column.isDivider,
											[classes.transparent]: borderlessTable
										})}
									>
										{column.isDivider ? null : (
											<>
												{column.render("Header")}
												{column.canSort ? (
													<TableSortLabel
														active={column.isSorted}
														// react-table has a unsorted state which is not treated here
														direction={column.isSortedDesc ? "desc" : "asc"}
													/>
												) : null}
											</>
										)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{rowsToRender
							// .filter(row => row.subRows.length !== 1)
							.map((row, idx, arr) => {
								const previous = idx > 0 ? arr[idx - 1] : undefined;
								const next = idx < arr.length - 1 ? arr[idx + 1] : undefined;
								if (idx === 0) prepareRow(row);
								if (next) prepareRow(next);
								if (!mergeColumnValues && row.subRows.length === 1) return null;

								const isFirstInGroup =
									previous === undefined ||
									(previous.isGrouped && !row.isGrouped);

								const rowAggregatingAbove =
									previous &&
									(row.subRows?.includes(previous) ||
										row.leafRows?.includes(previous));

								const rowAggregatingBelow =
									next &&
									(row.subRows?.includes(next) || row.leafRows?.includes(next));

								return [
									rowAggregatingBelow && idx !== 0 && (
										<TableRow key={`${row.getRowProps().key}-spacing_above`}>
											<TableCell colSpan={visibleColumns.length} />
										</TableRow>
									),
									<FormTableRow<T>
										key={row.getRowProps().key}
										metaData={metaData}
										row={row}
										saveRow={onRowSave}
										isFirstInGroup={isFirstInGroup}
										mergeColumnValues={mergeColumnValues ?? false}
										canAdd={(canAdd && isFirstInGroup) ?? false}
										onClickAdd={handleAddRowClick}
										editingRowIds={editingRowIds}
										aggregating={
											rowAggregatingAbove
												? "above"
												: rowAggregatingBelow
												? "below"
												: undefined
										}
										borderlessTable={borderlessTable ?? false}
										isReadOnlyRowFunc={schema?.isReadOnlyRow}
										literal={literal}
									/>,
									rowAggregatingAbove && idx !== arr.length - 1 && (
										<TableRow key={`${row.getRowProps().key}-spacing_below`}>
											<TableCell colSpan={visibleColumns.length} />
										</TableRow>
									)
								];
							})}

						<FormTableFooterRow
							rows={rowsToRender}
							visibleColumns={visibleColumns}
							footerGroups={footerGroups}
							canAdd={canAdd ?? false}
							onClickAdd={handleAddRowClick}
							borderlessTable={borderlessTable ?? false}
							tableHasMergedValuesInRows={mergeColumnValues ?? false}
							schema={schema}
						/>
					</TableBody>
				</Table>
			</TableContainer>
			<TableNavigation
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				pageOptions={pageOptions}
				pageCount={pageCount}
				gotoPage={gotoPage}
				nextPage={nextPage}
				previousPage={previousPage}
				setPageSize={setPageSize}
				pageIndex={pageIndex}
			/>
		</DployFormControl>
	);
}

const TableNavigation = (props: any) => {
	const {
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		pageIndex
	} = props;
	if (!canPreviousPage && !canNextPage) return null;
	return (
		<div style={{ textAlign: "center" }}>
			<Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
				<FirstPageIcon />
			</Button>
			<Button onClick={() => previousPage()} disabled={!canPreviousPage}>
				<ChevronLeftIcon />
			</Button>
			{pageCount > 0 ? pageIndex + 1 : 0} / {pageCount}
			<Button onClick={() => nextPage()} disabled={!canNextPage}>
				<ChevronRightIcon />
			</Button>
			<Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
				<LastPageIcon />
			</Button>
		</div>
	);
};

function FormTableFooterRow<T extends object>(props: {
	rows: Row<T>[];
	visibleColumns: ColumnInstance<T>[];
	footerGroups: HeaderGroup<T>[];
	canAdd: boolean;
	onClickAdd: () => void;
	borderlessTable: boolean;
	tableHasMergedValuesInRows: boolean;
	schema: any;
}) {
	const {
		rows,
		canAdd,
		onClickAdd,
		footerGroups,
		visibleColumns,
		borderlessTable,
		tableHasMergedValuesInRows,
		schema
	} = props;
	const showFooterCells = rows.filter(row => row.subRows.length > 0).length > 1;
	// ATM we only use the first footerGroup.
	// Expand this component if more footer groups are needed
	const footerGroup = footerGroups[0];
	const rowClasses = useRowClasses();

	return (
		<>
			{showFooterCells && !tableHasMergedValuesInRows ? (
				<TableRow>
					<TableCell colSpan={visibleColumns.length} />
				</TableRow>
			) : null}
			{!canAdd && !showFooterCells ? null : (
				<TableRow {...footerGroup.getFooterGroupProps()}>
					{footerGroup.headers.map((column, index) => {
						if (column.id !== "editing" && !showFooterCells) return null;
						return (
							<TableCell
								{...column.getFooterProps()}
								className={clsx({ [rowClasses.borderless]: borderlessTable })}
								style={{
									border: showFooterCells ? undefined : "0",
									borderTop: showFooterCells ? "solid 2px black" : undefined,
									fontWeight: "bold"
								}}
							>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									{canAdd && column.id === "editing" ? (
										<IconButton
											data-cr-field={
												schema ? schema["dployTesterContext"] : undefined
											}
											data-cr-component={"Button"}
											id={
												schema
													? "AddRowButton" + schema["dployTesterContext"]
													: undefined
											}
											onClick={() => onClickAdd()}
											color="secondary"
										>
											<AddIcon />
										</IconButton>
									) : (
										column.render("Footer")
									)}
								</Box>
							</TableCell>
						);
					})}
				</TableRow>
			)}
		</>
	);
}

const useRowClasses = makeStyles(theme => ({
	aggregatingAbove: {
		borderTop: "solid 2px black"
	},
	aggregatingBelow: {
		borderBottom: "solid 2px black"
	},
	divider: {
		backgroundColor: "lightgrey",
		width: "4px",
		padding: "0px"
	},
	grouped: {
		fontWeight: "bold"
	},
	readOnlyRow: {
		color: theme.palette.text.disabled
	},
	borderless: {
		border: "0px !important"
	}
}));

function FormTableRow<T extends object>(props: {
	metaData?: Record<string, any>;
	row: Row<T>;
	saveRow: RowActions<T>["onRowSave"];
	editingRowIds: Record<IdType<T>, boolean>;
	isFirstInGroup: boolean;
	canAdd: boolean;
	mergeColumnValues: boolean;
	onClickAdd: (cell?: Cell<T>) => void;
	aggregating?: "above" | "below";
	borderlessTable: boolean;
	isReadOnlyRowFunc?: (rowData: any, cells: any) => boolean;
	literal?: boolean;
}) {
	const {
		metaData,
		row,
		editingRowIds,
		aggregating,
		isFirstInGroup,
		mergeColumnValues,
		saveRow,
		borderlessTable,
		isReadOnlyRowFunc,
		literal
	} = props;

	const rowClasses = useRowClasses();

	const editing = editingRowIds[row.id];
	const [editValues, setEditValues] = useState(row.original);
	const validationSchema = metaData
		? Yup.object().shape(
				Object.entries(metaData).reduce(
					(newObj, [key, value]: [string, any]) => {
						var validation = standardValidation[value.fieldType];
						// Consider moving isRequired to interface designer as column option
						if (value.isRequired)
							validation = validation.concat(
								requiredValidation[value.fieldType]
							);
						// Consider validatorFunctions to be configurable in interface designer as column option
						if (value.validatorSchemas)
							validation = validation.concat(
								Object.values(value.validatorSchemas)[0]
							);
						newObj[key] = validation;
						return newObj;
					},
					{}
				)
		  )
		: undefined;

	useEffect(() => {
		if (editing) setEditValues(row.original);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editing, setEditValues]);

	return (
		<Formik
			initialValues={editing ? { ...editValues } : { ...row.original }}
			enableReinitialize
			onSubmit={async values => {
				await saveRow(row.id, row.index, values as T);
			}}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{form => (
				<TableRow {...row.getRowProps()}>
					{row.cells.map((cell, cellIdx) => {
						const cellColumnIdParts = cell.column.id.split(":");
						const cellColumnIdString =
							cellColumnIdParts[cellColumnIdParts.length - 1];
						const cellColumnId =
							cellColumnIdString.charAt(0).toLocaleLowerCase() +
							cellColumnIdString.slice(1);

						const isGrouping =
							cell.isGrouping ??
							row.id.split(">").includes(`${cellColumnId}:${cell.value}`);

						const isGroupAggregate = row.isGrouped && !isGrouping;

						const cellAggregatingAbove =
							aggregating === "above" &&
							// !cell.column.disableGroupBy &&
							(isGroupAggregate || cell.isAggregated || cell.isGrouped);

						const cellAggregatingBelow =
							aggregating === "below" &&
							// !cell.column.disableGroupBy &&
							(isGroupAggregate || cell.isAggregated || cell.isGrouped);

						// if tc.isEditableFunction TBA in interface designer column options
						// MVP solution. Select first function, if any
						const isReadOnlyRow =
							isReadOnlyRowFunc?.(row.original, row.cells) ?? false;
						const isEditableFunctions =
							metaData?.[cellColumnId]?.isEditableFunctions;
						const isEditableFunction: any = isEditableFunctions
							? Object.values(isEditableFunctions)[0]
							: () => true;
						const isEditable =
							cell.column.canEdit &&
							!isReadOnlyRow &&
							isEditableFunction(form, row.cells);
						const isEditing =
							editing && !cell.column.disableEditing && isEditable;

						const isDivider = cell.column.isDivider;

						return (
							<TableCell
								{...cell.getCellProps()}
								className={clsx({
									[rowClasses.aggregatingAbove]: cellAggregatingAbove,
									[rowClasses.aggregatingBelow]: cellAggregatingBelow,
									[rowClasses.divider]: isDivider,
									[rowClasses.grouped]: row.isGrouped,
									[rowClasses.readOnlyRow]: isReadOnlyRow,
									[rowClasses.borderless]: borderlessTable
								})}
							>
								<Box
									display="flex"
									justifyContent={
										cellColumnId?.toLowerCase() === "editing"
											? "center"
											: "space-between"
									}
									alignItems="center"
								>
									{isDivider ? null : cell.isGrouped && mergeColumnValues ? (
										cell.render("Cell")
									) : cell.isGrouped ? (
										cell.column.groupLabel ? (
											<Typography
												variant="subtitle2"
												style={{ fontWeight: "inherit" }}
											>
												{cell.column.groupLabel}
											</Typography>
										) : (
											<div id="FillerFieldForTable" />
										)
									) : cell.isAggregated ? (
										cell.render("Aggregated")
									) : (
										<>
											{!literal &&
											(isEditing || metaData?.[cellColumnId]?.alwaysEditable)
												? cell.render("Editing", { form })
												: cell.render("Cell", {
														isFirstInGroup
												  })}
										</>
									)}
								</Box>
							</TableCell>
						);
					})}
				</TableRow>
			)}
		</Formik>
	);
}
FormTableRow.displayName = "FormTableRow";

const getSchemaName = (name: string) => {
	const nameArray = name.split(":");
	return toFakeCamelCase(nameArray[nameArray.length - 1]);
};
