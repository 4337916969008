import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export interface ChartPoint {
	x: number;
	y: number;
}

export interface ChartSeries {
	name: string;
	points: ChartPoint[];
}

export interface ChartData {
	axisTitle: string;
	hasDate: boolean;
	series: ChartSeries[];
}

export class ChartResource extends BaseCamelCasedResource implements ChartData {
	id: string;
	axisTitle: string;
	hasDate = false;
	series: ChartSeries[];

	pk() {
		return this.id;
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		const response = await super.fetch(input, init);

		return { id: typeof input === "string" ? input : input.url, ...response };
	}

	static salesOverview() {
		return this.detail().extend({
			url: () => legacyApiResourceUrl("Reporting/GetSalesOverview")
		});
	}

	static statusOverview() {
		return this.detail().extend({
			url: () => legacyApiResourceUrl("Reporting/GetStatusOverview")
		});
	}

	static financialVolumeOverview() {
		return this.detail().extend({
			url: () => legacyApiResourceUrl("Reporting/GetFinancialVolumeOverview")
		});
	}

	static urlRoot = legacyApiResourceUrl("Chart");
}

export type ChartDetailKey =
	| "salesOverview"
	| "statusOverview"
	| "financialVolumeOverview";
