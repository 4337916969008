import { apiResourceUrl } from "@ploy-lib/core";
import { Resource } from "@rest-hooks/rest";
import { BaseResource } from "./BaseResource";

export enum LandingPageStatusEnum {
	MissingContactInfo = -1,
	NotSent = 0,
	Sent = 1,
	Completed = 2
}

export interface LandingPageModel {
	readonly customerName: string;
	readonly status: LandingPageStatusEnum;
	readonly changedDate: Date;
	readonly shortReference: string;
	readonly canSendNewLink: boolean;
}

export class LandingPageStatusesResource
	extends BaseResource
	implements LandingPageModel
{
	readonly customerName: string;
	readonly status: LandingPageStatusEnum;
	readonly changedDate: Date;
	readonly shortReference: string;
	readonly canSendNewLink: boolean;
	pk() {
		return this.customerName;
	}

	static distribute<T extends typeof Resource>(this: T) {
		return super.create().extend({
			url: () => apiResourceUrl("landing-page/distribute-landing-page")
		});
	}

	static urlRoot = apiResourceUrl("landing-page/statuses/{applicationId}");
}
