import { BaseCamelCasedResource } from "./BaseResource";
import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import {
	ApplicationStatusId,
	ApplicationStatus,
	DocumentStatus
} from "@ploy-lib/types";
import {
	Resource,
	SchemaList,
	AbstractInstanceType,
	RestEndpoint,
	RestFetch
} from "@rest-hooks/rest";

export interface ApplicationInfo {
	readonly applicationId?: ID;
	readonly vendorName: string;
	readonly loanAmount?: number;
	readonly internalUser: string;
	readonly responsibleId: number;
	readonly finalized: boolean;
	readonly backOfficeAccountId: string;
	readonly applicationNumber: string;
	readonly customerName: string;
	readonly customerNumber: string;
	readonly customerCompanyNumber?: string;
	readonly customerId?: number;
	readonly createdDate: Date;
	readonly documentStatusId?: number;
	readonly documentStatusNameInternal: string;
	readonly documentStatusNameExternal: string;
	readonly applicationStatusId: ApplicationStatusId;
	readonly applicationStatusNameInternal: string;
	readonly applicationStatusNameExternal: string;
	readonly salesPersonName: string;
	readonly salesPersonId?: number;
	readonly productId?: number;
	readonly productName: string;
	readonly productNameExternal: string;
	readonly productCodeExternal: string;
	readonly coSignerName: string;
	readonly channel?: number;
	readonly pageIndex?: number;
	readonly recordCount?: number;
	readonly field5: string;
	readonly field7: string;
	readonly field8: string;
	readonly field17: string;
	readonly field18: string;
	readonly followUp?: number;
	readonly carType: string;
	readonly carBrandAndModel: string;
	readonly applicationLastChanged?: Date;
	readonly paidDate?: Date;
	readonly inChargeName: string;
	readonly vendorId?: number;
}

export enum DocumentType {
	Normal = "Normal",
	Security = "Security",
	Combined = "Combined",
	Miscellaneous = "Miscellaneous"
}

export interface ApplicationInfoFilter {
	// product?: string[] | string;
	productList?: ID[] | ID;
	appStatus?: ApplicationStatus[] | ApplicationStatus | number[] | number;
	fromAppStatus?: ApplicationStatus;
	toAppStatus?: ApplicationStatus;
	docStatus?: DocumentStatus[] | DocumentStatus | number[] | number;
	fromDocStatus?: DocumentStatus;
	toDocStatus?: DocumentStatus;
	fullText?: string;
	carRegNo?: string;
	hasCarRegNo?: boolean;
	missingCarRegNo?: boolean;
	salepersonId?: ID;
	fromDate?: string;
	customerNumber?: string;
	externalOfferNumber?: string;
	customerName?: string;
	toDate?: string;
	customerNameContains?: boolean;
	searchInVendorHierarchy?: boolean;
	vendorId?: ID;
	applicationNumber?: string;
	allTasksSignedExceptType?: DocumentType[] | DocumentType;
	allTasksCompletedExceptType?: DocumentType[] | DocumentType;
	productExceptions?: ID[] | ID;
	mustHaveHadDocStatus?: ID;
	useInternalDocStatus?: boolean;
	useVendorSpecifiedSearch?: boolean;

	// Paging
	pageSize?: number;
	page?: number;

	// Sorting
	sortBy?: string[] | string;
}

export class ApplicationInfoResource
	extends BaseCamelCasedResource
	implements ApplicationInfo
{
	readonly applicationId?: ID = undefined;
	readonly vendorName: string = "";
	readonly vendorId?: number = undefined;
	readonly loanAmount?: number = undefined;
	readonly internalUser: string = "";
	readonly responsibleId: number = 0;
	readonly finalized: boolean = false;
	readonly backOfficeAccountId: string = "";
	readonly applicationNumber: string = "";
	readonly customerName: string = "";
	readonly customerNumber: string = "";
	readonly customerCompanyNumber: string = "";
	readonly customerId?: number = undefined;
	readonly createdDate: Date;
	readonly documentStatusId?: number = undefined;
	readonly documentStatusNameInternal: string = "";
	readonly documentStatusNameExternal: string = "";
	readonly applicationStatusId: ApplicationStatusId =
		ApplicationStatusId.NotSet;
	readonly applicationStatusNameInternal: string = "";
	readonly applicationStatusNameExternal: string = "";
	readonly salesPersonName: string = "";
	readonly salesPersonId?: number = undefined;
	readonly productId?: number = undefined;
	readonly productName: string = "";
	readonly productNameExternal: string = "";
	readonly productCodeExternal: string = "";
	readonly coSignerName: string = "";
	readonly channel?: number = undefined;
	readonly field5: string = "";
	readonly field7: string = "";
	readonly field8: string = "";
	readonly field17: string = "";
	readonly field18: string = "";
	readonly followUp?: number = undefined;
	readonly carType: string = "";
	readonly carBrandAndModel: string = "";
	readonly applicationLastChanged?: Date = undefined;
	readonly paidDate?: Date = undefined;
	readonly inChargeName: string = "";
	readonly invoiceNoRef: string = "";

	pk() {
		return this.applicationNumber;
	}

	static getEndpointExtra() {
		return {
			...super.getEndpointExtra(),
			pollingFrequency: 5000,
			dataExpiryLength: 1000
		};
	}

	static list<T extends typeof Resource>(
		this: T
	): RestEndpoint<
		RestFetch<
			ApplicationInfoFilter,
			undefined,
			{
				records: AbstractInstanceType<T>[];
				recordCount: number;
				pageSize: number;
			}
		>,
		{
			records: SchemaList<Readonly<AbstractInstanceType<T>>>;
			recordCount: number;
			pageSize: number;
		},
		undefined
	> {
		return super.list().extend({
			schema: {
				records: [this] as SchemaList<Readonly<AbstractInstanceType<T>>>,
				recordCount: 0,
				pageSize: 1
			}
		});
	}

	static urlRoot = apiResourceUrl("application-summary");
}

export class ApplicationInfoDetailsResource extends BaseCamelCasedResource {
	readonly appNr: string;

	readonly canCancelApp: boolean = false;
	readonly responsible: {
		readonly email?: string;
		readonly mobile?: string;
	} = {};

	pk() {
		return this.appNr?.toString();
	}

	static urlRoot = apiResourceUrl("application-summary/{appNr}/details");
}
