import React, { forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import ListItem, {
	ListItemProps,
	ListItemTypeMap
} from "@material-ui/core/ListItem";
import Icon, { IconProps } from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { OverridableComponent } from "@ploy-ui/core";
import { ExtendButtonBase, TypographyProps } from "@material-ui/core";
import clsx from "clsx";

export interface ContentProps<
	PrimaryTypographyComponent extends React.ElementType = "div",
	SecondaryTypographyComponent extends React.ElementType = "div",
	TertiaryTypographyComponent extends React.ElementType = "div",
	QuaternaryTypographyComponent extends React.ElementType = "div"
> {
	iconClass?: string;
	icon?: React.ReactElement;
	iconColor?: IconProps["color"];
	primary?: React.ReactNode;
	secondary?: React.ReactNode;
	tertiary?: React.ReactNode;
	quaternary?: React.ReactNode;
	secondaryAction?: React.ReactNode;
	hideChevron?: boolean;
	hideDivider?: boolean;
	chevronIcon?: React.ReactElement;
	inset?: boolean;
	primaryTypographyProps?: TypographyProps<
		PrimaryTypographyComponent,
		{ component?: PrimaryTypographyComponent }
	>;
	secondaryTypographyProps?: TypographyProps<
		SecondaryTypographyComponent,
		{ component?: SecondaryTypographyComponent }
	>;
	tertiaryTypographyProps?: TypographyProps<
		TertiaryTypographyComponent,
		{ component?: TertiaryTypographyComponent }
	>;
	quaternaryTypographyProps?: TypographyProps<
		QuaternaryTypographyComponent,
		{ component?: QuaternaryTypographyComponent }
	>;
}

export type ContentListItemProps<
	D extends React.ElementType<any> = "li",
	P = {}
> = ContentProps<any, any, any, any> &
	Omit<ListItemProps<D, P>, "onClick" | "id">;

type ContentListItemType = OverridableComponent<
	ListItemTypeMap<{ button?: false } & ContentProps<any, any, any, any>, "li">
> &
	ExtendButtonBase<ListItemTypeMap<{ button: true } & ContentProps, "div">>;

const ContentListItem: ContentListItemType = forwardRef(
	<D extends React.ElementType<any> = "li">(
		props: ContentListItemProps<D>,
		ref: React.Ref<any>
	) => {
		const classes = useStyles(props);

		const {
			iconClass,
			icon,
			iconColor,
			primary,
			secondary,
			tertiary,
			quaternary,
			secondaryAction,
			hideChevron,
			chevronIcon = hideChevron ? (
				<div className={classes.chevron} />
			) : (
				<ChevronRightIcon className={classes.chevron} />
			),
			hideDivider,
			inset,
			primaryTypographyProps,
			secondaryTypographyProps,
			tertiaryTypographyProps,
			quaternaryTypographyProps,
			...rest
		} = props;

		return (
			<ListItem ref={ref} className={classes.root} {...rest}>
				{iconClass || icon ? (
					<ListItemIcon classes={{ root: classes.iconContainer }}>
						{icon ?? (
							<Icon color={iconColor} className={`fa fa-${iconClass}`} />
						)}
					</ListItemIcon>
				) : null}
				<ListItemText
					classes={{ primary: classes.primary, secondary: classes.secondary }}
					primary={primary}
					secondary={secondary}
					primaryTypographyProps={{
						noWrap: true,
						component: "div",
						...primaryTypographyProps
					}}
					secondaryTypographyProps={{
						noWrap: true,
						component: "div",
						...secondaryTypographyProps
					}}
					inset={inset && !(iconClass || icon)}
				/>
				<ListItemText
					classes={{
						root: classes.textRoot,
						primary: clsx(
							quaternary ? classes.primary : classes.tertiary,
							classes.rightAlign
						),
						secondary: clsx(classes.secondary, classes.rightAlign)
					}}
					primary={tertiary}
					secondary={quaternary}
					primaryTypographyProps={{
						noWrap: true,
						component: "div",
						align: "right",
						variant: quaternary ? "body2" : "body1",
						...tertiaryTypographyProps
					}}
					secondaryTypographyProps={{
						noWrap: true,
						component: "div",
						align: "right",
						...quaternaryTypographyProps
					}}
					inset={inset && !(iconClass || icon)}
				/>
				{chevronIcon}
				{secondaryAction && (
					<ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
				)}
			</ListItem>
		);
	}
) as any;

const useStyles = makeStyles(
	{
		root: { color: "inherit" },
		iconContainer: {},
		textRoot: {
			//Hacky way to allow for noWrap overflow and keeping the width of the ContentBox from overflowing
			maxWidth: 1000
		},
		primary: {},
		secondary: {},
		tertiary: {},
		chevron: {
			height: 24
		},
		rightAlign: {
			display: "flex",
			justifyContent: "flex-end"
		}
	},
	{ name: "DashboardContentListItem" }
);

(ContentListItem as any).displayName = "ContentListItem";

export default ContentListItem;
