import { memo } from "react";

import { ExternalViewSettingsResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";

import * as views from "./views";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	}
}));

type View = keyof typeof views;

function isView(str: string): str is View {
	return (views as any)[str] != null;
}

export const ExternalViewLoader = memo((props: { view?: string }) => {
	const classes = useStyles();

	const { view = props.view } = useParams<"view">();

	if (!view) throw new Error("Ingen ekstern visning angitt");

	const { component, endpoint, token, dtResult, internalUser } = useResource(
		ExternalViewSettingsResource.detail(),
		{
			view
		}
	);

	var viewComponent = isView(component)
		? component
		: isView(view)
		? view
		: undefined;

	const Component = viewComponent && views[viewComponent];

	if (!Component) throw new Error("Ugyldig ekstern visning angitt");

	return (
		<div className={classes.root}>
			<Component
				endpoint={endpoint}
				token={token}
				dtResult={dtResult}
				internalUser={internalUser}
			/>
		</div>
	);
});
