import React, { useDebugValue, useMemo } from "react";
import {
	LiteralBase,
	LiteralProps,
	getLiteralBaseProps,
	baseEditableOptions
} from "./TextLiteral";
import { useIntl, FormatNumberOptions } from "react-intl";
import {
	extractNumberFormat,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";
import { removeNegativeZero } from "../../utils";

const prefixSuffixTypes = ["currency", "percentSign", "literal"];

export const useFormattedParts = (
	number: number,
	options: FormatNumberOptions,
	emptyEqualsZero?: boolean,
	formatString?: string
) => {
	const fromFormatString = useMemo(
		() => extractNumberFormat({}, formatString),
		[formatString]
	);
	if (!options.format) options = { format: "default", ...options };

	options = {
		...options,
		...fromFormatString
	};

	const intl = useIntl();

	const parts = intl.formatNumberToParts(number, options);

	const str: string = parts
		.filter(p => !prefixSuffixTypes.includes(p.type))
		.map(p => p.value)
		.join("");

	const prefix: string | undefined =
		parts.length > 0 && prefixSuffixTypes.includes(parts[0].type)
			? parts[0].value
			: undefined;

	const suffix: string | undefined =
		parts.length > 0 && prefixSuffixTypes.includes(parts[parts.length - 1].type)
			? parts[parts.length - 1].value
			: undefined;

	useDebugValue(str);

	return {
		prefix,
		str: Number.isNaN(number) ? (emptyEqualsZero ? 0 : "") : str,
		suffix
	};
};

export const NumberLiteral = ({
	emptyEqualsZero,
	formatString,
	...rest
}: LiteralProps) => {
	const {
		suffix: suffixValue = undefined,
		prefix: prefixValue = undefined,
		maximumFractionDigits
	} = rest;
	const {
		prefix = prefixValue,
		str,
		suffix = suffixValue
	} = useFormattedParts(
		removeNegativeZero(rest.field.value),
		{ maximumFractionDigits: maximumFractionDigits },
		emptyEqualsZero,
		formatString
	);

	return (
		<LiteralBase {...getLiteralBaseProps(rest)} prefix={prefix} suffix={suffix}>
			{str}
		</LiteralBase>
	);
};

export const EditorNumberLiterals = identityRecordOfFieldEditorOptions({
	NumberLiteral: {
		editableOptions: {
			...baseEditableOptions,
			prefix: true,
			suffix: true
		}
	}
});
