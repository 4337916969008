import React, { Suspense, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	Backdrop,
	Button,
	Card,
	CardContent,
	CardHeader,
	Fade,
	IconButton,
	Modal,
	Typography,
	Checkbox,
	useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
	CustomerGroupResource,
	CustomerGroupItemType,
	LimitCustomerType
} from "@ploy-lib/rest-resources";
import { FormattedMessage } from "react-intl";
import { DployColumn, DployTable } from "@ploy-ui/core";
import { useFetcher } from "@rest-hooks/core";
import { useStatefulResource } from "@rest-hooks/legacy";
import { useModalStyles } from "./LimitCustomersSection";
import { DployFormControl } from "@ploy-ui/form-fields";

interface GroupInformationProps {
	customerId: number | undefined;
	limitCustomers: LimitCustomerType[];
	addLimitCustomers: (newLimitCustomers: LimitCustomerType[]) => void;
	customerSearchPending: boolean;
	disabled?: boolean;
}
export const GroupInformation = ({
	customerId,
	limitCustomers,
	addLimitCustomers,
	customerSearchPending,
	disabled
}: GroupInformationProps) => {
	const [open, setOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [organizations, setOrganizations] = useState<CustomerGroupItemType[]>(
		[]
	);

	const classes = useModalStyles();
	const theme = useTheme();

	const { data: customerGroup, loading } = useStatefulResource(
		CustomerGroupResource.detail(),
		(customerId ?? 0) > 0
			? {
					customerId: customerId
			  }
			: null
	);
	useEffect(() => {
		if (!loading && customerGroup?.organizations !== undefined)
			setOrganizations(
				customerGroup.organizations.map(o => ({
					...o,
					addToLimitCustomers: false
				})) as CustomerGroupItemType[]
			);
	}, [customerGroup, loading, setOrganizations]);

	const updateGroup = useFetcher(CustomerGroupResource.update());

	const isRowMatch = (row, g) => {
		return (
			g.customerNumber === row.customerNumber &&
			g.customerName === row.customerName
		);
	};

	const columns: DployColumn<CustomerGroupItemType>[] = [
		{
			title: (
				<FormattedMessage
					id={`limit-customers-section.addToLimitCustomers`}
					description="Add to LimitCustomers"
					defaultMessage="Legg til som rammekunde"
				/>
			),
			field: "addToLimitCustomers",
			render: row => {
				return limitCustomers.some(
					c => c.customerName === row.customerName
				) ? null : (
					<Checkbox
						checked={
							limitCustomers.some(c => isRowMatch(row, c)) ||
							row.addToLimitCustomers ||
							false
						}
						disabled={!row.import && !row.exists}
						onChange={(e, checked) => {
							const newGroup = organizations.map(g => {
								if (isRowMatch(row, g)) g.addToLimitCustomers = checked;
								return g;
							});
							setOrganizations(newGroup);
						}}
					/>
				);
			}
		},
		{
			title: (
				<FormattedMessage
					id={`limit-customers-section.import`}
					description="Import"
					defaultMessage="Importer"
				/>
			),
			width: 100,
			field: "import",
			render: row => (
				<Checkbox
					checked={row.exists || row.import || false}
					disabled={customerGroup?.organizations.some(
						g => isRowMatch(row, g) && g.exists
					)}
					onChange={(e, checked) => {
						const newGroup = organizations.map(g => {
							if (isRowMatch(row, g)) {
								g.import = checked;
								if (!checked) g.addToLimitCustomers = false;
							}
							return g;
						});
						setOrganizations(newGroup);
					}}
				/>
			)
		},
		{
			title: (
				<FormattedMessage
					id={`limit-customers-section.exclude`}
					description="Exclude"
					defaultMessage="Ekskluder"
				/>
			),
			width: 100,
			field: "exclude",
			render: row => (
				<Checkbox
					checked={row.exclude || false}
					onChange={() => {
						const newGroup = organizations.map(g => {
							if (isRowMatch(row, g)) g.exclude = !g.exclude;
							return g;
						});
						setOrganizations(newGroup);
					}}
				/>
			)
		},
		{
			title: (
				<FormattedMessage
					id={`limit-customers-section.customerCompanyNumber`}
					description="Organization number (bisnode modal)"
					defaultMessage="Organisasjonsnummer"
				/>
			),
			field: "customerNumber"
		},
		{
			title: (
				<FormattedMessage
					id={`limit-customers-section.customerName`}
					description="Organization name"
					defaultMessage="Navn"
				/>
			),
			field: "customerName"
		}
	];

	return (
		<>
			<DployFormControl margin="normal">
				<Button
					disabled={disabled}
					color="secondary"
					size="large"
					variant="outlined"
					onClick={() => setOpen(true)}
				>
					<Typography>
						<FormattedMessage
							id={`limit-customers-section.customerGroup`}
							description="Customer group options"
							defaultMessage="Bisnode"
						/>
					</Typography>
				</Button>
			</DployFormControl>
			<Modal
				aria-labelledby="customer-group-search-modal-title"
				aria-describedby="customer-group-modal-description"
				className={classes.modal}
				open={open}
				//onClose={() => setOpen(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={open}>
					<Card className={classes.card}>
						<CardHeader
							title={
								<Typography variant="h5">
									<FormattedMessage
										id={`limit-customers-section.customer-goup.bisnodeModalTitle`}
										description="Title of bisnode search modal"
										defaultMessage="Konsern"
									/>
								</Typography>
							}
							action={
								<IconButton
									onClick={() => setOpen(false)}
									aria-label={"modal-close-label"}
								>
									<CloseIcon />
								</IconButton>
							}
						/>
						<CardContent>
							{loading || customerSearchPending ? (
								<CircularProgress color="inherit" size={20} />
							) : (
								<Suspense fallback={null}>
									<DployTable
										data={organizations}
										columns={columns}
										options={{
											headerStyle: {
												background: theme.palette.secondary.main,
												color: theme.palette.secondary.contrastText
											},
											search: false,
											header: true,
											toolbar: false,
											pageSize: 15,
											pageSizeOptions: [],
											idSynonym: "customerId"
										}}
									/>
									<div className={classes.buttonContainer}>
										<Button
											className={classes.button}
											variant="contained"
											color="secondary"
											size="small"
											onClick={() => {
												setSaving(true);
												updateGroup(
													{},
													{
														customerId: customerId,
														organizations: organizations
													}
												).then(res => {
													const newLimitCustomers = res.organizations
														.filter(
															o => o.addToLimitCustomers && !!o.customerId
														)
														.map(
															org =>
																({
																	customerId: org?.customerId,
																	customerName: org?.customerName || ""
																} as LimitCustomerType)
														);
													addLimitCustomers(newLimitCustomers);
													setSaving(false);
													setOpen(false);
												});
											}}
										>
											<Typography>
												<FormattedMessage
													id={`limit-customers-section.customer-goup.save`}
													description="Save customer group options"
													defaultMessage="Lagre"
												/>
											</Typography>
											{saving ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
										</Button>
										<Button
											className={classes.button}
											variant="contained"
											color="secondary"
											size="small"
											onClick={() => setOpen(false)}
										>
											<Typography>
												<FormattedMessage
													id={`limit-customers-section.customer-goup.close`}
													description="Close customer group options"
													defaultMessage="Lukk"
												/>
											</Typography>
										</Button>
									</div>
								</Suspense>
							)}
						</CardContent>
					</Card>
				</Fade>
			</Modal>
		</>
	);
};
