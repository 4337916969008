import React from "react";
import { FormattedMessage } from "react-intl";
import ContentListItem from "../../ContentListItem";
import Skeleton from "@material-ui/lab/Skeleton";
import { StatusIcon, DividerItems } from "@ploy-ui/core";
import { useDebounce } from "use-debounce";
import {
	RecentApplicationResource,
	useRetrieveAppendList
} from "@ploy-lib/rest-resources";
import { useCache } from "@rest-hooks/core";
import { useMaxItems } from "../../hooks/useMaxItems";
import { List, Box } from "@material-ui/core";

export interface RecentApplicationsListProps {
	includeRejected?: boolean;
	showDocumentStatus?: boolean;
	onSelect?: (appNumber: string) => void;
	placeholder?: boolean;
}

export function RecentApplicationsList(props: RecentApplicationsListProps) {
	const { includeRejected, onSelect, showDocumentStatus, placeholder } = props;
	const [listRef, maxItems] = useMaxItems({ itemHeight: 60, divider: true });

	const [debouncedMaxItems] = useDebounce(maxItems, 500, { leading: true });

	useRetrieveAppendList(
		RecentApplicationResource.list(),
		!placeholder && debouncedMaxItems
			? { count: debouncedMaxItems, includeRejected }
			: null,
		{ includeRejected }
	);

	const cachedApps = useCache(RecentApplicationResource.list(), {
		includeRejected
	});
	const recentApps = cachedApps
		?.slice()
		.sort((a, b) => -a.lastChanged.localeCompare(b.lastChanged))
		.slice(0, maxItems);

	const animation = !placeholder && undefined;

	return (
		<Box clone height="100%">
			<List ref={listRef} dense>
				<DividerItems>
					{recentApps?.map(
						({
							customerName,
							statusId,
							applicationNumber,
							status,
							documentStatus
						}) =>
							onSelect ? (
								<ContentListItem
									key={applicationNumber}
									icon={<StatusIcon status={statusId} />}
									button
									primary={customerName}
									secondary={
										<FormattedMessage
											id="dealer.dashboard.contextlist.applicationNumber"
											description="applicationNumber message, {applicatonNumber} is parameter"
											defaultMessage="Application number {applicationNumber}"
											values={{
												applicationNumber: applicationNumber
											}}
										/>
									}
									tertiary={status}
									quaternary={showDocumentStatus ? documentStatus : null}
									onClick={() => onSelect(applicationNumber)}
								/>
							) : (
								<ContentListItem
									key={applicationNumber}
									icon={<StatusIcon status={statusId} />}
									primary={customerName}
									secondary={
										<FormattedMessage
											id="dealer.dashboard.contextlist.applicationNumber"
											description="applicationNumber message, {applicatonNumber} is parameter"
											defaultMessage="Application number {applicationNumber}"
											values={{
												applicationNumber: applicationNumber
											}}
										/>
									}
									tertiary={status}
									quaternary={showDocumentStatus ? documentStatus : null}
									hideChevron
								/>
							)
					) ?? (
						<>
							<ContentListItem
								icon={
									<Skeleton
										variant="circle"
										width={40}
										height={40}
										animation={animation}
									/>
								}
								primary={
									<Skeleton variant="text" width="30%" animation={animation} />
								}
								secondary={
									<Skeleton variant="text" width="40%" animation={animation} />
								}
								tertiary={
									<Skeleton variant="text" width="20%" animation={animation} />
								}
								quaternary={
									showDocumentStatus ? (
										<Skeleton
											variant="text"
											width="15%"
											animation={animation}
										/>
									) : null
								}
								hideChevron
							/>
							<ContentListItem
								icon={
									<Skeleton
										variant="circle"
										width={40}
										height={40}
										animation={animation}
									/>
								}
								primary={
									<Skeleton variant="text" width="30%" animation={animation} />
								}
								secondary={
									<Skeleton variant="text" width="40%" animation={animation} />
								}
								tertiary={
									<Skeleton variant="text" width="20%" animation={animation} />
								}
								quaternary={
									showDocumentStatus ? (
										<Skeleton
											variant="text"
											width="15%"
											animation={animation}
										/>
									) : null
								}
								hideChevron
							/>
						</>
					)}
				</DividerItems>
			</List>
		</Box>
	);
}
