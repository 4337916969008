import { IThirdPartyScriptsContext } from "@ploy-ui/core";

export const getGTMData = (
	google: IThirdPartyScriptsContext["google"],
	applicationNumber?: string,
	productName?: string,
	vendorName?: string,
	pageIndex?: number,
	isPostProcess?: boolean,
	loanAmount?: number
) => {
	let virtualPageViewTitle = "";
	let virtualPageViewURL = "";

	if (isPostProcess) {
		virtualPageViewURL = `/bekreftelse`;
		virtualPageViewTitle = "Bekreftelse";
	} else {
		switch (pageIndex) {
			case 0:
				virtualPageViewURL = `/finansiering`;
				virtualPageViewTitle = "Finansiering";
				break;
			case 1:
				virtualPageViewURL = `/gjenstand`;
				virtualPageViewTitle = "Gjenstand";
				break;
			case 2:
				virtualPageViewURL = `/kundeinfo`;
				virtualPageViewTitle = "Kundeinfo";
				break;
			case 3:
				virtualPageViewURL = `/oppsummering`;
				virtualPageViewTitle = "Oppsummering";
				break;
			default:
				break;
		}
	}

	if (virtualPageViewTitle && virtualPageViewURL) {
		let windowDataLayerURLs: string[] = google
			.getHistory()
			.filter((item: any) => item.event === "VirtualPageview")
			.map((item: any) => item.virtualPageURL);

		// Avoid virtual pageview duplicates
		if (windowDataLayerURLs.some(x => x === virtualPageViewURL)) {
			return;
		}

		return {
			event: "VirtualPageview",
			virtualPageURL: virtualPageViewURL,
			virtualPageTitle: virtualPageViewTitle,
			product: productName,
			vendor: vendorName,
			loanAmount: loanAmount,
			orderId: isPostProcess ? (applicationNumber?.split("-") ?? [""])[0] : null
		};
	}
};
