import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const Content = (props: React.PropsWithChildren<{ className?: string }>) => {
	const { className, children } = props;
	const classes = useStyles(props);

	return <main className={clsx(classes.root, className)}>{children}</main>;
};

const useStyles = makeStyles(
	theme => ({
		root: {
			position: "relative",
			flexGrow: 1,
			display: "flex",
			alignItems: "center",
			flexWrap: "wrap",
			maxWidth: "100%",
			minHeight: "100vh",
			flexDirection: "column",
			[theme.breakpoints.down("xs")]: {
				paddingBottom: 56
			}
		}
	}),
	{ name: "main" }
);

Content.displayName = "Content";
export default Content;
