import Box, { BoxProps } from "@material-ui/core/Box";
import { useLocation } from "react-router-dom";
import { ErrorHandler } from "@ploy-lib/core";
import { ErrorPage } from "@ploy-ui/core";

export function ErrorPageHandler<T>(
	props: Pick<BoxProps, "children" | "sx"> & T
) {
	const { children, sx, ...rest } = props;

	const location = useLocation();

	return (
		<ErrorHandler
			locationKey={location.key}
			{...rest}
			fallback={error => (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={props.sx}
				>
					<ErrorPage error={error} />
				</Box>
			)}
		>
			{children}
		</ErrorHandler>
	);
}
