import React from "react";
import { Omit } from "@material-ui/core";
import { useResource, Endpoint } from "@rest-hooks/core";

const readFile = new Endpoint(
	async function ({ file }: { file: File }): Promise<string | undefined> {
		return new Promise(resolve => {
			// even if we polyfill, on server we don't want to actually wait to resolve the File
			if (typeof window === "undefined" || typeof FileReader !== "function")
				resolve(undefined);

			const reader = new FileReader();

			reader.onload = () => {
				resolve((reader.result as string) ?? undefined);
			};
			reader.onerror = error => {
				// in case we fail to load we actually don't want to error out but
				// let the browser display the normal image fallback
				resolve((reader.result as string) ?? undefined);
			};

			reader.readAsDataURL(file);
		});
	},
	{
		key({ file }: { file: File }) {
			return `READ ${file.name}`;
		},
		schema: ""
	}
);

export function FileImg(
	props: Omit<
		React.DetailedHTMLProps<
			React.ImgHTMLAttributes<HTMLImageElement>,
			HTMLImageElement
		>,
		"src"
	> & { file: File }
) {
	const { file } = props;

	const src = useResource(readFile, { file });

	return <img {...props} alt={props.alt} src={src} />;
}
