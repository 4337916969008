export { ID } from "@ploy-lib/types";

export interface AccessDeniedResult {
	AccessDenied?: boolean;
}

export function isAccessDenied<T>(
	result: T | AccessDeniedResult
): result is AccessDeniedResult {
	return (
		(result as AccessDeniedResult)?.AccessDenied != null ||
		(result as any)?.accessDenied != null
	);
}

export type CondNull<P, A, B> = P extends null ? A : B;
