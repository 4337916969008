import { ChartDetailKey, ChartData } from "@ploy-lib/rest-resources";
import { defineMessages } from "react-intl";

export interface ChartContentProps {
	source?: ChartDetailKey;
	placeholder?: boolean;
}

export const sources: ChartDetailKey[] = [
	"salesOverview",
	"statusOverview",
	"financialVolumeOverview"
];

export const sourceMapTitles = defineMessages<typeof sources[number]>({
	salesOverview: {
		id: "dploy.grid.charts.source.option.salesOverview.label",
		defaultMessage: "Sales overview"
	},
	statusOverview: {
		id: "dploy.grid.charts.source.option.statusOverview.label",
		defaultMessage: "Status overview"
	},
	financialVolumeOverview: {
		id: "dploy.grid.charts.source.option.financialVolumeOverview.label",
		defaultMessage: "Financial volume overview"
	}
});

export function validChartData(chartData?: ChartData): chartData is ChartData {
	return chartData != null && chartData.series.some(s => s.points.length > 0);
}
