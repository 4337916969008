import React, { Suspense } from "react";
import {
	Button,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
	UserLimitationResource,
	MissingCourseResource
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.down("sm")]: {
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			position: "relative"
		}
	},
	actions: {
		[theme.breakpoints.down("sm")]: {
			justifyContent: "space-evenly"
		}
	},
	content: {
		whiteSpace: "pre-line"
	}
}));

function InformUserInternal() {
	const userLimitations = useResource(UserLimitationResource.detail(), {});
	const courses = useResource(MissingCourseResource.list(), {});

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const classes = useStyles();

	const [open, setOpen] = React.useState(true);

	function handleClose() {
		setOpen(false);
	}

	if (!userLimitations || !userLimitations.MissingRequiredCourse) return null;

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<Box className={classes.container}>
				<DialogTitle id="responsive-dialog-title">
					<FormattedMessage
						id="dealer.inform-user.title"
						description="Title for dialog informing user about missing courses"
						defaultMessage="Missing required courses"
					/>
				</DialogTitle>
				<DialogContent>
					<DialogContentText className={classes.content}>
						<FormattedMessage
							id="dealer.inform-user.content"
							description="Detailed message about missing courses"
							defaultMessage="Your user is missing required courses for submitting applications. You can not submit applications, but you can save them. Finish the courses and sign in again. {CourseLinks}"
							values={{
								CourseLinks: courses?.map(course => {
									return (
										course.courseUrl && (
											<>
												<br />
												<a
													href={course.courseUrl}
													target="_blank"
													rel="noopener noreferrer"
												>
													{`  ${course.courseName || "Zelo Kurs"}`}
												</a>
											</>
										)
									);
								})
							}}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Button component={Link} to="/logout" color="primary">
						<FormattedMessage
							id="dealer.inform-user.logout"
							description="Log out"
							defaultMessage="Log out"
						/>
					</Button>
					<Button onClick={handleClose} color="primary" autoFocus>
						<FormattedMessage
							id="dealer.inform-user.continue"
							description="Continue"
							defaultMessage="Continue"
						/>
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default function InformUser() {
	return (
		<Suspense fallback={null}>
			<InformUserInternal />
		</Suspense>
	);
}
