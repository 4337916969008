import { v4 as uuidv4 } from "uuid";
import { ID } from "..";
import { BaseCamelCasedResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface CustomerSigner {
	readonly __id: ID;
	readonly customerSignerId: ID | null;
	readonly customerId: ID | null;
	readonly name: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly birthDate: string;
	readonly ssn: string;
	readonly role: string;
	readonly phone: string;
	readonly email: string;
	readonly ssnAddedByInternal: boolean;
	readonly isVendorSigner: boolean;
	readonly mustSign: boolean;
	readonly validToDate: string;
}

export class VendorSignerResource
	extends BaseCamelCasedResource
	implements CustomerSigner
{
	readonly customerSignerId: ID | null = null;
	readonly customerId: ID | null = null;
	readonly __id: ID = this.customerSignerId || uuidv4();
	readonly name: string = "";
	readonly firstName: string = "";
	readonly lastName: string = "";
	readonly birthDate: string = "";
	readonly ssn: string = "";
	readonly role: string = "";
	readonly phone: string = "";
	readonly email: string = "";
	readonly ssnAddedByInternal: boolean = false;
	readonly isVendorSigner: boolean = true;
	readonly mustSign: boolean = false;
	readonly validToDate: string = "";

	pk() {
		return `${this.customerSignerId || this.__id}`;
	}

	static urlRoot = apiResourceUrl("signers/vendor/");
}
