import { apiResourceUrl } from "@ploy-lib/core";
import { schema } from "@rest-hooks/rest";
import { BaseResource } from "./BaseResource";

export interface ConditionParamCategory {
	conditionParamCategoryId: number;
	name: string;
	description: string;
	operator?: string;
	unit?: string;
	inputRegex?: string;
	xpath?: string;
	sortOrder?: number;
	optionSource?: string;
	isNumeric?: boolean;
	inputDataRequirement?: string;
	formatOptions?: string;
	tableTypes?: string;
}

export class ConditionParamCategoryResource
	extends BaseResource
	implements ConditionParamCategory
{
	conditionParamCategoryId: number = 0;
	name: string = "";
	description: string = "";
	operator?: string = undefined;
	unit?: string = undefined;
	inputRegex?: string = undefined;
	xpath?: string = undefined;
	sortOrder?: number | undefined = undefined;
	optionSource?: string = undefined;
	isNumeric?: boolean | undefined = undefined;
	inputDataRequirement?: string = undefined;
	formatOptions?: string = undefined;
	tableTypes?: string = undefined;

	pk() {
		return this.conditionParamCategoryId?.toString();
	}

	static urlRoot = apiResourceUrl("decision-tables/condition-param-categories");
}

export interface DynamicFieldCategory {
	dynamicFieldCategoryId: number;
	name: string;
	description: string;
	sortOrder?: number;
	isNumeric?: boolean;
	source?: string;
	target?: string;
	operator?: string;
	inputDataRequirement?: string;
	optionSource?: string;
	formatOptions?: string;
	tableTypes?: string;
}

export class DynamicFieldCategoryResource
	extends BaseResource
	implements DynamicFieldCategory
{
	dynamicFieldCategoryId: number = 0;
	name: string = "";
	description: string = "";
	sortOrder?: number | undefined = undefined;
	isNumeric?: boolean | undefined = undefined;
	source?: string = undefined;
	target?: string = undefined;
	operator?: string = undefined;
	inputDataRequirement?: string = undefined;
	optionSource?: string = undefined;
	formatOptions?: string = undefined;
	tableTypes?: string = undefined;

	pk() {
		return this.dynamicFieldCategoryId.toString();
	}

	static urlRoot = apiResourceUrl("decision-tables/dynamic-field-categories");
}

interface DecisionTableOptionList {
	id: number;
	values: { key: string; value: string }[];
}

export class DynamicFieldCategoryOptionListResource
	extends BaseResource
	implements DecisionTableOptionList
{
	id: number = 0;
	values: { key: string; value: string }[] = [];

	pk() {
		return this.id.toString();
	}

	static urlRoot = apiResourceUrl(
		"decision-tables/dynamic-field-categories/option-lists"
	);
}

export class ConditionParamCategoryOptionListResource
	extends BaseResource
	implements DecisionTableOptionList
{
	id: number = 0;
	values: { key: string; value: string }[] = [];

	pk() {
		return this.id.toString();
	}

	static urlRoot = apiResourceUrl(
		"decision-tables/condition-param-categories/option-lists"
	);
}

export interface DecisionTableRow {
	decisionTableRowId?: number;
	decisionTableId?: number;
	conditions: DecisionTableCondition[];
	dynamicFields: DecisionTableDynamicField[];
}

export class DecisionTableRowResource
	extends BaseResource
	implements DecisionTableRow
{
	decisionTableRowId?: number | undefined = undefined;
	decisionTableId?: number | undefined = undefined;
	conditions: DecisionTableCondition[] = [];
	dynamicFields: DecisionTableDynamicField[] = [];

	pk(parent?: any, key?: string): string | undefined {
		return this.decisionTableRowId?.toString();
	}
}

export interface DecisionTable {
	decisionTableId?: number;
	name?: string | null;
	type?: string | null;
	rows: DecisionTableRow[];
	conditionCategories?: Record<number, ConditionParamCategory>;
	dynamicFieldCategories?: Record<number, DynamicFieldCategory>;
	conditionOptionMap?: Record<number, DecisionTableOptionList>;
	dynamicFieldOptionMap?: Record<number, DecisionTableOptionList>;
}

export class DecisionTableResource
	extends BaseResource
	implements DecisionTable
{
	decisionTableId?: number | undefined = undefined;
	name?: string | null | undefined = undefined;
	type?: string | null | undefined = undefined;
	rows: DecisionTableRow[] = [];
	conditionCategories?: Record<number, ConditionParamCategory> = undefined;
	dynamicFieldCategories?: Record<number, DynamicFieldCategory> = undefined;
	conditionOptionMap?: Record<number, DecisionTableOptionList> = undefined;
	dynamicFieldOptionMap?: Record<number, DecisionTableOptionList> = undefined;

	pk(parent?: any, key?: string): string | undefined {
		throw this.decisionTableId?.toString();
	}

	static schema = {
		rows: [DecisionTableRowResource],
		conditionCategories: new schema.Values(ConditionParamCategoryResource),
		dynamicFieldCategories: new schema.Values(DynamicFieldCategoryResource),
		conditionOptionMap: new schema.Values(
			ConditionParamCategoryOptionListResource
		),
		dynamicFieldOptionMap: new schema.Values(
			DynamicFieldCategoryOptionListResource
		)
	};
}

export interface DecisionTableCategory {
	type: string;
}

export interface DecisionTableCondition {
	decisionTableConditionId?: number;
	conditionParamCategoryId: number;
	value?: string;
}

export interface DecisionTableDynamicField {
	decisionTableDynamicFieldId?: number;
	dynamicFieldCategoryId: number;
	value?: string;
}
