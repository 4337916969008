import React from "react";
import camelcaseKeys from "camelcase-keys";
import { apiResourceUrl, legacyApiResourceUrl } from "@ploy-lib/core";

export interface BackofficeCommissionObject {
	salespersonName: string;
	amount: number;
	upfrontAmount: number;
	billCommissionAmount: number;
	marginAmount: number;
	agreementNumber: string;
	startDate: Date;
	vendorId: string;
	agreementType: string;
	date: Date;
	user: string;
	userFullName: string;
	contractNumber: string;
	vendorName: string;
	description: string;
	customerName: string;
	termCount: number;
	currentTerm: number;
	establishmentFee: number;
	termFee: number;
	financedAmount: number;
}

export interface KeyValuePair<T> {
	Key: string;
	Value: T;
}

export interface UpdateStatusRequest {
	vendorId?: string;
	agreementNumber: string;
	amount: number;
	date: Date;
	newStatus?: string;
	type?: string;
}

export interface UpdateStatusResponse {
	success: boolean;
	message: string;
}

export interface GetBackofficeDataResponse {
	commissions: BackofficeCommissionObject[];
	success: boolean;
}

export interface GetBackofficeDataRequest {
	type: string;
	vendorNumber?: string;
	vendorId?: string;
	fromDate?: string;
	toDate?: string;
	userId?: string;
	searchInGroup: boolean;
	agreementNumber?: string;
}

export interface GetAdditionalInfoResponse {
	status: string;
	agreementNumber: string;
	agreementType: string;
}

export interface GetApplicationInfoResponse {
	applications: [
		{
			applicationInterest: number;
			applicationNumber: string;
			contractNumber: string;
			regNumber: string;
			residualValue: number;
		}
	];
}

export interface SendToApprovalRequest {
	agreementNumber: string;
	amount: number;
	date: Date;
	agreementType: string;
}

export interface SendToApprovalRepsonse {
	success: boolean;
	message: string;
}

export interface API {
	getCommission(
		searchParams: GetBackofficeDataRequest
	): Promise<GetBackofficeDataResponse>;
	vendorSearch(searchString: string): Promise<KeyValuePair<string>[]>;
	underwriterSearch(searchString: string): Promise<KeyValuePair<string>[]>;
	sendToApproval(
		agreements: SendToApprovalRequest[]
	): Promise<SendToApprovalRepsonse>;
	getAdditionalInformation(
		monthYear: Date,
		agreementNumber: string,
		useEntireHierarki: boolean
	): Promise<GetAdditionalInfoResponse>;
	getApplicationInformation(
		contracts: string[]
	): Promise<GetApplicationInfoResponse>;
	updateStatus(agreement: UpdateStatusRequest): Promise<UpdateStatusResponse>;
	internalUser?: boolean;
}

export interface CommissionAPIProps {
	children(api: API): React.ReactElement<any>;
	token: string;
	endpoint: string;
}

export class CommissionAPI extends React.Component<CommissionAPIProps> {
	callAPI = async (method: string, action: string, params: object) => {
		const { token } = this.props;
		const noCacheToken = new Date().getTime();
		const response = await fetch(
			apiResourceUrl(
				`backoffice-commission/${action}?${getQueryParams(
					params
				)}&t=${noCacheToken}`
			),
			{
				method,
				headers: token ? new Headers({ Authorization: token }) : undefined,
				credentials: "include" // Include session cookie
			}
		);
		const data = await response.json();
		return camelcaseKeys(data) as any;
	};

	handleGetCommission = async (
		searchParams: GetBackofficeDataRequest
	): Promise<GetBackofficeDataResponse> => {
		try {
			const response = await this.callAPI("GET", searchParams.type, {
				...searchParams
			});
			return {
				...response,
				commissions: response.commissions.map((x: any) => ({
					...x,
					startDate: new Date(x.startDate),
					date: new Date(x.date)
				}))
			};
		} catch (err: any) {
			throw Error("Error getting backoffice commission. " + err.message);
		}
	};

	handleGetAdditionalInformation = async (
		monthYear: Date,
		agreementNumber: string,
		useEntireHierarki: boolean
	): Promise<GetAdditionalInfoResponse> => {
		try {
			const response = await this.callAPI("GET", "additional-info", {
				monthYear,
				agreementNumber,
				useEntireHierarki
			});
			return {
				...response,
				agreementNumber
			};
		} catch (err: any) {
			throw Error("Error getting backoffice commission. " + err.message);
		}
	};

	handleGetApplicationInformation = async (
		contracts: string[]
	): Promise<GetApplicationInfoResponse> => {
		try {
			const response = await this.callAPI("GET", "application-info", {
				contracts
			});
			return { ...response };
		} catch (err: any) {
			throw Error(`Error getting backoffice commission. ${err.message}`);
		}
	};

	handleSendToApproval = async (
		agreements: SendToApprovalRequest[]
	): Promise<SendToApprovalRepsonse> => {
		const { token } = this.props;
		try {
			const response = await fetch(
				apiResourceUrl("backoffice-commission/log"),
				{
					method: "POST",
					headers: token ? new Headers({ Authorization: token }) : undefined,
					credentials: "include", // Include session cookie
					body: new Blob([JSON.stringify(agreements)], {
						type: "application/json"
					})
				}
			);
			const data = await response.json();
			return camelcaseKeys(data) as any;
		} catch (err: any) {
			throw Error("Error getting backoffice commission. " + err.message);
		}
	};

	handleSearchVendor = async (
		searchString: string
	): Promise<KeyValuePair<string>[]> => {
		try {
			var response = await fetch(
				legacyApiResourceUrl(
					`/VendorSelect/SearchVendorsByPartialName?data=${encodeURIComponent(
						searchString
					)}`
				),
				{
					method: "GET",
					credentials: "include" // Include session cookie
				}
			);
			const data = await response.json();
			return data;
		} catch (err: any) {
			throw Error("Error when searching vendors. " + err.message);
		}
	};

	handleSearchUnderwriter = async (
		searchString: string
	): Promise<KeyValuePair<string>[]> => {
		try {
			var response = await fetch(
				legacyApiResourceUrl(
					`/User/SearchUserByPartialName?data=${encodeURIComponent(
						searchString
					)}`
				),
				{
					method: "GET",
					credentials: "include" // Include session cookie
				}
			);
			const data = await response.json();
			return data;
		} catch (err: any) {
			throw Error("Error when searching vendors. " + err.message);
		}
	};

	handleUpdateStatus = async (
		agreement: UpdateStatusRequest
	): Promise<UpdateStatusResponse> => {
		const { token } = this.props;
		try {
			var response = await fetch(
				apiResourceUrl(`backoffice-commission/update-status`),
				{
					method: "POST",
					headers: token ? new Headers({ Authorization: token }) : undefined,
					credentials: "include", // Include session cookie
					body: new Blob([JSON.stringify(agreement)], {
						type: "application/json"
					})
				}
			);
			const data = await response.json();
			return data;
		} catch (err: any) {
			throw Error("Error when searching vendors. " + err.message);
		}
	};

	render() {
		const { children } = this.props;
		return children({
			getCommission: this.handleGetCommission,
			sendToApproval: this.handleSendToApproval,
			vendorSearch: this.handleSearchVendor,
			underwriterSearch: this.handleSearchUnderwriter,
			getAdditionalInformation: this.handleGetAdditionalInformation,
			getApplicationInformation: this.handleGetApplicationInformation,
			updateStatus: this.handleUpdateStatus
		});
	}
}

export const getQueryParams = (params: Record<string, any>) => {
	return Object.entries(params)
		.map(
			([k, v]) =>
				encodeURIComponent(k) +
				"=" +
				encodeURIComponent(v instanceof Date ? v.toISOString() : v)
		)
		.join("&");
};

export default CommissionAPI;
