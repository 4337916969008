import React, { memo } from "react";
import { content } from "@ploy-ui/dashboard";
import Box from "@material-ui/core/Box";
import Carousel from "react-material-ui-carousel";
import { useMediaQuery, useTheme } from "@material-ui/core";

export interface DashboardContentCarouselProps {
	className?: string;
	onDragItemStart: (component: keyof typeof content) => void;
	onDragItemEnd: (component: keyof typeof content) => void;
	height?: string;
	maxHeight?: string;
}

DashboardContentCarouselImpl.displayName = "DashboardContentCarousel";
function DashboardContentCarouselImpl(props: DashboardContentCarouselProps) {
	const { onDragItemStart, onDragItemEnd, className, height, maxHeight } =
		props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const itemsPerSlide = isMobile ? 2 : 3;

	const carouselComponentsGroups = (
		Object.keys(content) as (keyof typeof content)[]
	).reduce((acc, component, i) => {
		if (i % itemsPerSlide === 0) acc.push([component]);
		else acc[acc.length - 1].push(component);
		return acc;
	}, [] as (keyof typeof content)[][]);

	return (
		<Carousel
			className={className}
			autoPlay={false}
			animation="slide"
			navButtonsAlwaysVisible
			swipe={false}
		>
			{carouselComponentsGroups.map((group, i) => (
				<Box
					key={i}
					display="flex"
					alignItems="stretch"
					justifyContent="space-between"
					height={height}
					maxHeight={maxHeight}
				>
					{group.map(component => {
						const Component = content[component];

						return (
							<Box
								key={component}
								m={2}
								flex={1}
								maxWidth={`${100 / itemsPerSlide}%`}
								draggable
								unselectable="on"
								onDragStart={e => {
									onDragItemStart(component);

									// this is a hack for firefox
									// Firefox requires some kind of initialization
									// which we can do by adding this attribute
									// @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
									e.dataTransfer.setData("text/plain", "");
								}}
								onDragEnd={e => onDragItemEnd(component)}
							>
								<Component id="" boxProps={{ header: component }} placeholder />
							</Box>
						);
					})}
				</Box>
			))}
		</Carousel>
	);
}

export const DashboardContentCarousel = memo(DashboardContentCarouselImpl);
