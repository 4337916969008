import React, { Children, forwardRef } from "react";

import List, { ListProps, ListTypeMap } from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { OverridableComponent } from "../OverridableComponent";

interface DividerItemsProps {
	divider?: boolean;
	dividerStart?: boolean;
	dividerEnd?: boolean;
	children: React.ReactNode;
}

export function DividerItems(props: DividerItemsProps) {
	const { children, divider = true, dividerStart, dividerEnd } = props;

	let first = true;

	return (
		<>
			{dividerStart && <Divider />}
			{divider
				? Children.map(children, node => {
						if (!node) return node;
						const divider = !(first || (node as any).props?.hideDivider);
						first = false;
						return (
							<>
								{divider && <Divider />}
								{node}
							</>
						);
				  })
				: children}
			{dividerEnd && <Divider />}
		</>
	);
}
DividerItems.displayName = "DployDividerItems";

export type DividerListProps<
	D extends React.ElementType<any> = "ul",
	P = {}
> = ListProps<D, P> & DividerItemsProps;

type DividerListType = OverridableComponent<ListTypeMap<DividerItemsProps>>;

export const DividerList: DividerListType = forwardRef((props, ref) => {
	const { children, divider = true, dividerStart, dividerEnd, ...rest } = props;

	return (
		<List {...rest} ref={ref}>
			<DividerItems
				divider={divider}
				dividerStart={dividerStart}
				dividerEnd={dividerEnd}
			>
				{children}
			</DividerItems>
		</List>
	);
});

(DividerList as any).displayName = "DployDividerList";
