import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import { Step, StepLabel, StepConnector } from "@material-ui/core";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { usePages } from "../../pagesContext";
import { addRegisteredStepper } from "./stepperDescriptions";
import { BaseStepperProps } from "./BaseStepperProps";
import { usePageState } from "../../PageContext";

addRegisteredStepper({
	name: "CircleStepper",
	displayName: "CircleStepper"
});

const ColorlibConnector = withStyles(
	(theme: Theme) => ({
		alternativeLabel: {
			top: 22
		},
		active: {
			"& $line": {
				borderColor: theme.palette.secondary.main
			}
		},
		completed: {
			"& $line": {
				borderColor: theme.palette.secondary.main
			}
		},
		line: {
			borderWidth: 3,
			borderColor: theme.palette.text.primary,
			borderRadius: 1,
			margin: "0 auto",
			width: "90%",
			[theme.breakpoints.down("sm")]: {
				width: "75%"
			}
		}
	}),
	{ name: "CircleStepperConnector" }
)(StepConnector);

const useColorlibStepIconStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			color: theme.palette.text.primary,
			zIndex: 1,
			borderStyle: "solid",
			borderWidth: 3,
			borderColor: theme.palette.text.primary,
			boxSizing: "border-box",
			mozBoxSizing: "border-box",
			webkitBoxSizing: "border-box",
			width: 50,
			height: 50,
			display: "flex",
			borderRadius: "50%",
			justifyContent: "center",
			alignItems: "center"
		},
		active: {
			borderStyle: "none",
			backgroundColor: theme.palette.secondary.main,
			boxShadow: "0 0px 10px 0 rgba(0,0,0,.25)",
			color: theme.palette.secondary.contrastText
		},
		completed: {
			borderStyle: "none",
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText
		},
		circle: {
			fontWeight: "bold",
			textAlign: "center"
		}
	}),
	{ name: "CircleStepperStepIcon" }
);

function ColorlibStepIcon(props: StepIconProps) {
	const classes = useColorlibStepIconStyles({});
	const { active, completed, icon } = props;

	return (
		<div
			className={clsx(
				classes.root,
				{
					[classes.active]: active,
					[classes.completed]: completed
				},
				classes.circle
			)}
		>
			{icon}
		</div>
	);
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		button: {
			marginRight: theme.spacing(1)
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1)
		},
		root: {
			margin: "0 auto",
			backgroundColor: "transparent"
		},
		label: {
			color: theme.palette.text.primary,

			"&$activeLabel": {
				color: theme.palette.text.primary
			},

			"&$completedLabel": {
				color: theme.palette.text.primary
			}
		},
		activeLabel: {},
		completedLabel: {}
	}),
	{ name: "CircleStepper" }
);

export const CircleStepper = (props: BaseStepperProps) => {
	const classes = useStyles(props);
	const { step } = usePageState();

	const pages = usePages();

	var count = -1;
	let stepLabel = 0;

	const displayIndexArr: number[] = pages.map(p => {
		if (p.displayInStepper) {
			count += 1;
			return count;
		}
		return -1;
	});

	const getDisplayNum = (step: number) => {
		if (step === 0 && displayIndexArr[step] === -1) {
			return -1;
		}
		return displayIndexArr[step] === -1
			? getDisplayNum(step - 1)
			: displayIndexArr[step];
	};

	const activeStep = getDisplayNum(step);

	return (
		<Stepper
			alternativeLabel
			activeStep={activeStep}
			connector={<ColorlibConnector />}
			className={classes.root}
		>
			{pages.map(page => {
				stepLabel = page.displayInStepper ? stepLabel + 1 : stepLabel;
				return page.displayInStepper ? (
					<Step key={page.pageTitle}>
						<StepLabel
							StepIconProps={{ icon: `${stepLabel}` }}
							StepIconComponent={ColorlibStepIcon}
							classes={{
								label: classes.label,
								active: classes.activeLabel,
								completed: classes.completedLabel
							}}
						>
							{page.pageTitle}
						</StepLabel>
					</Step>
				) : null;
			})}
		</Stepper>
	);
};
