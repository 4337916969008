import { Resource } from "@rest-hooks/rest";
import { DployContextStore } from "@ploy-lib/core";
import qs from "qs";
import {
	deeplyApplyKeyTransform,
	deeplyApplyValueTransform,
	alphabeticalSort
} from "@ploy-lib/core";
import camelCase from "lodash/camelCase";
import isTypedArray from "lodash/isTypedArray";

const paramRegex = /{(\w+)}/g;

class MissingParameterError extends Error {
	templates: string[];
	missingParameters: string[];

	constructor(templates: string[], missingParameters: string[]) {
		const message = `The parameters does not satisfy any of the url templates:
	${templates.map(x => `- ${x}`).join("\n")}

Add some or all of the missing parameters to satisfy one of the templates:
	${missingParameters.map(x => `- ${x}`).join("\n")}`;

		super(message);
		this.name = "MissingParameterError";
		this.templates = templates;
		this.missingParameters = missingParameters;
	}
}

export default function paramsToString(
	searchParams?: Readonly<Record<string, string | number>>
) {
	return qs.stringify(searchParams, {
		arrayFormat: "repeat",
		sort: alphabeticalSort,
		addQueryPrefix: true
	});
}

function getBody(data: BodyInit | null | undefined) {
	if (data == null) return data;

	// Possible types that are accepted as body
	if (typeof Blob !== "undefined" && data instanceof Blob) return data;
	if (typeof FormData !== "undefined" && data instanceof FormData) return data;
	if (typeof URLSearchParams !== "undefined" && data instanceof URLSearchParams)
		return data;
	if (typeof ArrayBuffer !== "undefined" && data instanceof ArrayBuffer)
		return data;
	if (isTypedArray(data)) return data;
	if (typeof ReadableStream !== "undefined" && data instanceof ReadableStream)
		return data;
	if (typeof data === "string") return data;

	// Otherwise create a JSON blob
	return new Blob([JSON.stringify(data)], {
		type: "application/json"
	});
}

export abstract class BaseResource extends Resource {
	static getFetchInit = (options: RequestInit): RequestInit => {
		return super.getFetchInit({
			...options,
			credentials: "same-origin",
			headers: {
				...options.headers,
				...(DployContextStore.context ?? {}),
				accept: "application/json"
			},
			body: getBody(options.body)
		});
	};

	static async fetch(input: RequestInfo, init: RequestInit) {
		return this.fetchResponse(input, init).then((response: Response) => {
			if (
				!response.headers.get("content-type")?.includes("json") ||
				response.status === 204
			)
				return response.text();
			DployContextStore.context ??= {};
			response.headers.forEach((val: string, key: string) => {
				if (key.toLowerCase().startsWith("dploycontext-"))
					DployContextStore.context[key] = val;
			});

			return response.json().catch(error => {
				error.status = 400;
				throw error;
			});
		});
	}

	/** Get the url for many SimpleResources
	 *
	 * Default implementation conforms to common REST patterns
	 */
	static listUrl(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		if (
			Object.prototype.hasOwnProperty.call(allParams, "url") &&
			allParams.url &&
			typeof allParams.url === "string"
		) {
			return allParams.url;
		}

		const [urlRoot, searchParams] = this.interpolatedUrlRoot(allParams);

		return `${urlRoot}${paramsToString(searchParams)}`;
	}

	/** Get the url for a SimpleResource
	 *
	 * Default implementation conforms to common REST patterns
	 */
	static url(urlParams: Readonly<Record<string, any>>): string {
		if (
			Object.prototype.hasOwnProperty.call(urlParams, "url") &&
			urlParams.url &&
			typeof urlParams.url === "string"
		) {
			return urlParams.url;
		}

		const [urlRoot, params] = this.interpolatedUrlRoot(urlParams);

		if (this.pk(params as any) !== undefined) {
			if (urlRoot.endsWith("/")) {
				return `${urlRoot}${this.pk(params as any)}`;
			}
			return `${urlRoot}/${this.pk(params as any)}`;
		}
		return urlRoot;
	}

	/** Interpolate the url template of a BaseResource */
	static interpolatedUrlRoot<T extends typeof BaseResource>(
		this: T,
		params?: Readonly<Record<string, any>>
	): [string, typeof params] {
		let allMissingParams = [];

		const templates = this.urlTemplates
			? [...this.urlTemplates, this.urlRoot]
			: [this.urlRoot];

		for (const urlTemplate of templates) {
			let url = urlTemplate;
			let unusedParams: Record<string, any> | undefined = { ...params };

			let missingParams = [];

			let match: RegExpExecArray | null; // Replace with matchAll when it works
			while ((match = paramRegex.exec(urlTemplate))) {
				let param = match[1];
				if (
					!params ||
					!Object.prototype.hasOwnProperty.call(params, param) ||
					params[param] == null
				) {
					missingParams.push(param);
					continue;
				}

				url = url.replace(`{${param}}`, params[param]);

				delete unusedParams![param];
			}

			if (missingParams.length > 0) allMissingParams.push(...missingParams);
			else return [url, unusedParams];
		}

		throw new MissingParameterError(templates, allMissingParams);
	}

	/** An array of url templates of the format `/resource/{param}/something/`
	 *
	 * In order of priority.
	 *
	 * Default value [urlRoot]
	 */
	static urlTemplates?: string[];

	static calcPathToFields = "";
	static calcPathToValue = "";
}

export abstract class BaseCamelCasedResource extends BaseResource {
	static async fetch(input: RequestInfo, init: RequestInit) {
		const jsonResponse = await super.fetch(input, init);
		const data = deeplyApplyKeyTransform(jsonResponse, camelCase);
		const dataWithoutNull = deeplyApplyValueTransform(data, v =>
			v == null ? undefined : v
		);
		return dataWithoutNull;
	}
}
