import { useResource } from "@rest-hooks/core";
import {
	useLocation,
	useNavigate,
	useSearchParams,
	Outlet
} from "react-router-dom";
import { LoginResource } from "@ploy-lib/rest-resources";
import React, { useEffect } from "react";

interface AuthenticatedProps {
	authenticate: string;
}

export function Authenticated(
	props: React.PropsWithChildren<AuthenticatedProps>
) {
	const [searchParams, setSearchParams] = useSearchParams();

	const token = searchParams.get("token");

	useEffect(() => {
		if (token) {
			setSearchParams(
				Array.from(searchParams.entries()).filter(([key]) => key !== "token"),
				{ replace: true }
			);
		}
	}, [searchParams, setSearchParams, token]);

	// If the component suspends effects aren't fired.
	// A child component suspending don't stop the parents effects.
	// No need to wait for the token login request before removing the token from the search params,
	// so make sure the suspend happens in a child component so the effect is fired immediately.
	return <CheckLogin {...props} />;
}

function CheckLogin(props: React.PropsWithChildren<AuthenticatedProps>) {
	const { authenticate, children = <Outlet /> } = props;
	const [searchParams] = useSearchParams();

	const token = searchParams.get("token");

	const { isAuthenticated } = useResource(
		token ? LoginResource.tokenLogin() : LoginResource.status(),
		token ? { token } : {}
	);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated)
			navigate(authenticate, { state: { from: location }, replace: true });
	});

	return isAuthenticated ? <>{children}</> : null;
}
