import { useIsAnyFieldVisible } from "@ploy-lib/calculation";
import { useDebugValue } from "react";
import {
	ProofOfDeliveryReleasedInformationResource,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";
import { useAppLoad } from "../appLoadContext";

export function useHiddenFields() {
	const podFieldVisible = useIsAnyFieldVisible({
		name: "ReleaseProofOfDelivery",
		namespace: "DocumentProduction"
	});

	const { applicationNumber, vulcanContext, isDirectUser } = useAppLoad();

	const canLoadResources =
		!isDirectUser &&
		podFieldVisible &&
		applicationNumber &&
		vulcanContext === "PostProcess";

	const hiddenFields = [] as string[];
	const podResource = useResourceWithInvalidate(
		ProofOfDeliveryReleasedInformationResource.detail(),
		canLoadResources
			? {
					applicationNumber
			  }
			: null
	);

	if (podResource && podResource.isReleased)
		hiddenFields.push("ReleaseProofOfDelivery");

	useDebugValue(hiddenFields);

	return hiddenFields;
}
