import { ColumnSortOrderEnum } from "./ExpandableTable";

export const compareSorting = (
	obj1: any,
	obj2: any,
	sortOrder: ColumnSortOrderEnum
) => {
	const valuesAreNumeric = isNumeric(obj1) && isNumeric(obj2);
	if (
		typeof obj1 === "string" &&
		typeof obj2 === "string" &&
		!valuesAreNumeric
	) {
		const obj1AsSortableString = parseDateToSortableFormat(obj1);
		const obj2AsSortableString = parseDateToSortableFormat(obj2);

		return getSortOrder(obj1AsSortableString, obj2AsSortableString, sortOrder);
	} else {
		return sortOrder === ColumnSortOrderEnum.Asc ? obj1 - obj2 : obj2 - obj1;
	}
};

//	Returns original value if string can't be parsed as date
const parseDateToSortableFormat = (possibleDateString: string): string => {
	//	PossibleFormat: 12.07.2022
	const dateSplit = possibleDateString.split(".");
	if (dateSplit.length !== 3) return possibleDateString;

	const dateString = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
	const newDate = new Date(dateString);
	if (newDate instanceof Date && newDate.getMonth() >= 0) return dateString;

	return possibleDateString;
};

const getSortOrder = (
	comparingValue: string,
	currentValue: string,
	sortOrder: ColumnSortOrderEnum
) => {
	// Let "e" and "E" both evaluate to the same ASCII-number, and be sorted before "F" using .toLowerCase()
	const value1 = comparingValue.toLowerCase();
	const value2 = currentValue.toLowerCase();

	if (sortOrder === ColumnSortOrderEnum.Asc) {
		return boolToAscendingSortOrder(value1 < value2);
	} else {
		return boolToAscendingSortOrder(value1 > value2);
	}
};

//	If true, value is smaller than the compared value, and we return -1 so its sorted ascendingly
const boolToAscendingSortOrder = (booleanValue: boolean) =>
	booleanValue ? -1 : 1;

const isNumeric = (value: any) => {
	if (value && Array.isArray(value)) return false;
	return !isNaN(parseFloat(value?.toString()));
};
