import React, { memo } from "react";
import { ApplicationSigning, ErrorPageContent } from "@ploy-ui/core";

import { SectionProps } from "../Section";

import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useSignerMatrixVisible } from "../../hooks";
import { useAppLoad } from "../../appLoadContext";
import { useVariableData } from "@ploy-lib/calculation";
import { ErrorHandler } from "@ploy-lib/core";

addRegisteredSectionLayout({
	name: "ApplicationSigningSection",
	displayName: "ApplicationSigningSection",
	settings: {
		editableOptions: {
			showStatus: true,
			hideSignerList: true,
			hideSignerEditing: true,
			showAlternativeMessage: true
		}
	}
});

const ApplicationSigningSectionInternal = memo((props: SectionProps) => {
	const {
		className,
		onClick,
		setSectionCompleted,
		formTemplateSectionId,
		hideSignerList,
		hideSignerEditing,
		showAlternativeMessage
	} = props;

	const { value: customerTypeId = "0" } = useVariableData<string>(
		"Customer",
		"CustomerType"
	);

	const { value: readOnlyCustomerSignerMatrix = false } =
		useVariableData<boolean>("DocumentProduction", "SignerMatrixReadOnly");

	const [signerMatrixVisible, mainSignerMatrixVisible] =
		useSignerMatrixVisible();
	const {
		applicationNumber,
		settings: {
			esignType,
			ssnServiceEnabled,
			externalUserCanNotAddSigners,
			showValidPeriod,
			canAddAndDeleteVendorSigners,
			canChooseSigners,
			canEditIndividualDocumentsForSigners,
			disableVendorSigner,
			hideVendorSigner,
			disableSupplierSigner,
			hideSupplierSigner,
			disableSignerEditing,
			mustSignDefaultChecked,
			hideSigningActions,
			hideDocumentsToSign
		},
		isDirectUser,
		equityStatus
	} = useAppLoad();

	return !isDirectUser ? (
		<ApplicationSigning
			selfEmployedCustomer={customerTypeId === "3"}
			ssnServiceEnabled={ssnServiceEnabled}
			esignType={esignType}
			className={className}
			onClick={onClick}
			applicationNumber={applicationNumber}
			signerMatrixVisible={signerMatrixVisible}
			mainSignerMatrixVisible={mainSignerMatrixVisible}
			setSectionCompleted={setSectionCompleted}
			formTemplateSectionId={formTemplateSectionId}
			externalUserCanNotAddSigners={externalUserCanNotAddSigners}
			showValidPeriod={showValidPeriod}
			canAddAndDeleteVendorSigners={canAddAndDeleteVendorSigners}
			canChooseSigners={canChooseSigners}
			canEditIndividualDocuments={canEditIndividualDocumentsForSigners}
			disableVendorSigner={disableVendorSigner}
			hideVendorSigner={hideVendorSigner}
			disableSupplierSigner={disableSupplierSigner}
			hideSupplierSigner={hideSupplierSigner}
			disableSignerEditing={disableSignerEditing}
			customerSignerMatrixReadOnly={readOnlyCustomerSignerMatrix}
			mustSignDefaultChecked={mustSignDefaultChecked}
			hideSignerList={hideSignerList}
			hideSignerEditing={hideSignerEditing}
			showAlternativeMessage={showAlternativeMessage && customerTypeId !== "1"}
			hideSigningActions={hideSigningActions}
			hideDocumentsToSign={hideDocumentsToSign}
			equityStatus={equityStatus}
		/>
	) : null;
});

const ApplicationSigningSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={
					<span>{ApplicationSigningSection.displayName} failed to render.</span>
				}
			/>
		)}
	>
		<ApplicationSigningSectionInternal {...props} />
	</ErrorHandler>
);

ApplicationSigningSection.displayName = "DployApplicationSigningSection";

export { ApplicationSigningSection };
