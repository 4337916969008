import {
	useMemo,
	useCallback,
	useState,
	useRef,
	useEffect,
	useDebugValue
} from "react";
import { FieldProps as FormikFieldProps } from "formik";
import { useIsMountedRef } from "./useIsMountedRef";

function wait(time: number) {
	return new Promise(resolve => setTimeout(resolve, time));
}

/*
setFieldValue() and onChange() functions contain a very ugly hack to ensure no changes are lost or handled out of order:

	If the current value differs from the previous form value (because it was calculated),
	we need to update the form state with the calculated value first, or the change will be lost.
	We also need to make sure the next events wait for this change, or the changes will be handled out of order.

	https://git.knowitsolutions.tools/r/Applikator/Applikator/issues/1557
*/

export const useAlternativeFormik = (
	field: FormikFieldProps["field"],
	form: FormikFieldProps["form"],
	alternativeValue: any
) => {
	const { onChange: formikOnChange, onBlur: formikOnBlur } = field;

	const previousFormValueRef = useRef(field.value);
	const previousAltValueRef = useRef(alternativeValue);

	useEffect(() => {
		previousFormValueRef.current = field.value;
		previousAltValueRef.current = alternativeValue;
	});

	const { setFieldValue: formSetFieldValue } = form;

	const syncRef = useRef<Promise<any>>();

	const setFieldValue = useCallback(
		async (name: string, value: any, shouldValidate?: boolean) => {
			const shouldSync = previousFormValueRef.current === value;
			const canSync =
				previousAltValueRef.current !== previousFormValueRef.current;

			if (shouldSync && canSync) {
				formSetFieldValue(name, previousAltValueRef.current, false);

				syncRef.current = wait(0);
			}

			if (syncRef.current) {
				await syncRef.current;
				syncRef.current = undefined;
			}

			formSetFieldValue(name, value, shouldValidate);
		},
		[formSetFieldValue]
	);

	const wrappedForm = useMemo(
		(): FormikFieldProps["form"] => ({
			...form,
			setFieldValue
		}),
		[form, setFieldValue]
	);

	const isMountedRef = useIsMountedRef();
	const [isEditing, setIsEditing] = useState(false);

	const onChange = useCallback<typeof formikOnChange>(
		(e: React.ChangeEvent<any>) => {
			const target = e.target ? e.target : e.currentTarget;

			const { type, name, id, value, checked, outerHTML, options, multiple } =
				target;

			// The shouldSync logic is fragile and based around how formik internally handles change events
			// The goal is to skip the extra sync change if the change will be detected anyway
			// Formik does some magic to handle checkboxes in particular
			// https://github.com/jaredpalmer/formik/blob/v2.1.4/packages/formik/src/Formik.tsx#L646

			let shouldSync = previousFormValueRef.current === value;
			const canSync =
				previousAltValueRef.current !== previousFormValueRef.current;

			if (/number|range/.test(type)) {
				// Numeric value
				const parsed = parseFloat(value);
				shouldSync =
					previousFormValueRef.current === (isNaN(parsed) ? "" : parsed);
			} else if (/checkbox/.test(type)) {
				if (typeof previousFormValueRef.current === "boolean")
					// Single checkbox, boolean value
					shouldSync = previousFormValueRef.current === Boolean(checked);
				else {
					// Checkbox list, array of values
					shouldSync = true;
				}
			} else if (multiple) {
				// <select multiple>
				shouldSync = false;
			}

			if (shouldSync && canSync) {
				formSetFieldValue(field.name, previousAltValueRef.current, false);

				syncRef.current = wait(0);
			}

			(async () => {
				if (syncRef.current) {
					await syncRef.current;
					syncRef.current = undefined;

					if (!isMountedRef.current) return;
				}

				setIsEditing(true);

				// Mock change event due to async
				const event = {
					target: {
						type,
						name,
						id,
						value,
						checked,
						outerHTML,
						options,
						multiple
					}
				};

				formikOnChange(event);
			})();
		},
		[field.name, formSetFieldValue, formikOnChange, isMountedRef]
	);

	const onBlur = useCallback<typeof formikOnBlur>(
		(e: React.FocusEvent<any>) => {
			setIsEditing(false);
			formikOnBlur(e);
		},
		[formikOnBlur]
	);

	const wrappedField = useMemo(
		() => ({
			...field,
			value:
				isEditing || alternativeValue === undefined
					? field.value
					: alternativeValue,
			onChange,
			onBlur
		}),
		[field, alternativeValue, onChange, onBlur, isEditing]
	);

	useDebugValue(alternativeValue);

	return { form: wrappedForm, field: wrappedField };
};
