import { defineMessages, IntlShape } from "react-intl";
import * as yup from "yup";
import { ConfigElement } from "@ploy-ui/core";
import { VendorDriver } from "@ploy-lib/rest-resources";

const messages = defineMessages({
	firstNameTitle: {
		id: "driver.formConfig.title.firstname",
		defaultMessage: "First name"
	},
	lastNameTitle: {
		id: "driver.formConfig.title.lastname",
		defaultMessage: "Surname"
	},
	mobilePhoneTitle: {
		id: "driver.formConfig.title.mobilePhone",
		defaultMessage: "Mobiltelefon"
	},
	emailTitle: {
		id: "driver.formConfig.title.email",
		defaultMessage: "Email address"
	},
	employeeNumberTitle: {
		id: "driver.formConfig.title.employeenumber",
		defaultMessage: "Employee number"
	},
	costPlaceTitle: {
		id: "driver.formConfig.title.costplace",
		defaultMessage: "Cost place"
	},
	regNumberTitle: {
		id: "driver.formConfig.title.regnumber",
		defaultMessage: "Registration number",
		description: "registration number"
	},
	privateMileageTitle: {
		id: "driver.formConfig.title.privatemileage",
		defaultMessage: "Mileage, private"
	},
	workMileageTitle: {
		id: "driver.formConfig.title.workmileage",
		defaultMessage: "Mileage, service"
	},
	requiredFirstName: {
		id: "driver.formConfig.required.firstname",
		defaultMessage: "Fyll inn fornavn"
	},
	requiredLastName: {
		id: "driver.formConfig.required.lastname",
		defaultMessage: "Fyll inn etternavn"
	},
	invalidEmail: {
		id: "driver.formConfig.invalid.Email",
		defaultMessage: "Ikke gyldig epost"
	},
	invalidRegNumber: {
		id: "driver.formConfig.invalid.RegNumber",
		defaultMessage: "Ikke gyldig registreringsnummer"
	}
});

export const driverFormConfig = (intl: IntlShape) =>
	[
		{
			name: "firstname",
			title: intl.formatMessage(messages.firstNameTitle),
			validation: yup
				.string()
				.required(intl.formatMessage(messages.requiredFirstName)),
			required: true
		},
		{
			name: "lastname",
			title: intl.formatMessage(messages.lastNameTitle),
			validation: yup
				.string()
				.required(intl.formatMessage(messages.requiredLastName)),
			required: true
		},
		{
			name: "mobile",
			title: intl.formatMessage(messages.mobilePhoneTitle)
		},
		{
			name: "email",
			title: intl.formatMessage(messages.emailTitle),
			validation: yup.string().email(intl.formatMessage(messages.invalidEmail))
		},
		{
			name: "employeeNumber",
			title: intl.formatMessage(messages.employeeNumberTitle)
		},
		{
			name: "costPlace",
			title: intl.formatMessage(messages.costPlaceTitle)
		},
		{
			name: "regNumber",
			title: intl.formatMessage(messages.regNumberTitle),
			validation: yup
				.string()
				.matches(
					/(^([a-zA-Z]{2})([0-9]{5})$)/,
					intl.formatMessage(messages.invalidRegNumber)
				)
		},
		{
			name: "privateMileage",
			title: intl.formatMessage(messages.privateMileageTitle)
		},
		{
			name: "workMileage",
			title: intl.formatMessage(messages.workMileageTitle)
		}
	] as ConfigElement<VendorDriver>[];
