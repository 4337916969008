import { FieldResolver } from "./createFieldResolver";
import { getInNamespaced, stripControlIdPrefixAndSuffix } from "./utils";
import { Namespaced } from "./types";

export interface IsFieldVisibleIn<TNamespaces extends string> {
	(
		isVisible: Partial<Namespaced<boolean, TNamespaces>>,
		defaultFieldVisibility?: Partial<Namespaced<boolean, TNamespaces>>
	): (controlId: string) => boolean;
}

export function createIsFieldVisibleIn<TNamespaces extends string>(
	namespace: TNamespaces,
	fieldResolver: FieldResolver<TNamespaces>
): IsFieldVisibleIn<TNamespaces> {
	return function isFieldVisibleIn(isVisible, defaultFieldVisibility) {
		return function isFieldVisible(controlId) {
			const resolved = fieldResolver(controlId);
			const fieldNamespace = resolved ? resolved.namespace : namespace;
			const control = resolved ? resolved.resolved : controlId;
			const fieldName = stripControlIdPrefixAndSuffix(control);

			const visible = getInNamespaced(isVisible, fieldNamespace, fieldName);

			if (visible != null) return visible;

			return getInNamespaced(
				defaultFieldVisibility,
				fieldNamespace,
				fieldName,
				false
			);
		};
	};
}
