import * as React from "react";
import { ExternalViewComponent } from "../types";
import { Navigate } from "react-router-dom";

export const AuthenticatedView: ExternalViewComponent = ({
	dtResult,
	token = "",
	endpoint,
	internalUser
}) => <GenericAuthenticatedIframe token={token} endpoint={endpoint} />;

export default AuthenticatedView;

export interface GenericAuthenticatedIframeProps {
	token: string;
	endpoint: string;
}

interface State {
	externalUrl: string;
	redirect: boolean;
}

declare global {
	interface Window {
		checkSession: () => Promise<boolean>;
	}
}

class GenericAuthenticatedIframe extends React.Component<
	GenericAuthenticatedIframeProps,
	State
> {
	constructor(props: GenericAuthenticatedIframeProps) {
		super(props);
		this.state = {
			externalUrl: props.endpoint,
			redirect: false
		};
	}

	setRedirect() {
		this.setState({
			redirect: true
		});
	}

	componentDidMount() {
		const that = this;
		window.checkSession = async function () {
			return await that.isValidSession();
		};

		window.addEventListener("message", async event => {
			switch (event.data) {
				case "checkSession":
					if (event.source !== null) {
						event.source.postMessage(
							{ type: "checkSession", value: await window.checkSession() },
							{ targetOrigin: event.origin }
						);
					}
					break;
				default:
					break;
			}
		});
	}

	isValidSession = async () => {
		try {
			const res = await fetch(`${window.location.origin}/Login/Status`);
			const result = await res.json();
			if (result.status === "AccountStatusOK") {
				return true;
			} else {
				this.setRedirect();
				return false;
			}
		} catch (error) {
			this.setRedirect();
			return false;
		}
	};

	render() {
		this.isValidSession();

		if (this.state.redirect) {
			return <Navigate to="/Logout" />;
		}

		return (
			<div className="stuff_container">
				<iframe
					title="title"
					src={this.state.externalUrl}
					style={{
						border: "none",
						width: "100%",
						maxWidth: "100%",
						height: "calc(100vh - 3rem)"
					}}
				/>
			</div>
		);
	}
}
