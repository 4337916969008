import { useMemo, useDebugValue } from "react";
import { useCalculation } from "../context";
import { getInNamespaced, existsInNamespaced } from "../../calculator/utils";
import { TemplateField } from "@ploy-lib/types";
import { fieldDef } from "./fieldDef";

export type Field = Pick<TemplateField, "name" | "namespace">;

export interface FieldData {
	error: string | boolean | undefined;
	enabled: boolean;
	visible: boolean;
	fieldValue: string | number | boolean;
	initialFieldValue: string | number | boolean;
	exists: boolean;
}

export function useFieldData<TDef extends Field = Field>(
	field?: TDef
): Partial<FieldData>;
export function useFieldData(
	namespace: string,
	fieldName: string
): Partial<FieldData>;
export function useFieldData<TDef extends Field = Field>(
	namespaceOrField?: string | TDef,
	fieldName?: string
): Partial<FieldData> {
	const { namespace, name } = fieldDef(namespaceOrField, fieldName) ?? {};

	const [data] = useFieldsData(name != null ? { namespace, name } : undefined);

	useDebugValue(data);

	const { error, enabled, visible, fieldValue, exists, initialFieldValue } =
		data || ({} as Partial<FieldData>);
	const fieldData = useMemo(
		() => ({ error, enabled, visible, fieldValue, exists, initialFieldValue }),
		[error, enabled, visible, fieldValue, exists, initialFieldValue]
	);

	return fieldData;
}

export function useFieldsData<TDef extends Field = Field>(
	...fields: (TDef | undefined)[]
): (FieldData | undefined)[] {
	const {
		errors,
		isEnabled,
		isFieldVisible,
		defaultFieldVisibility,
		formValues,
		initialFormValues
	} = useCalculation(
		"errors",
		"isEnabled",
		"isFieldVisible",
		"defaultFieldVisibility",
		"formValues",
		"initialFormValues"
	);

	const data = fields.map(f => {
		if (!f) return undefined;
		const { namespace, name } = f;
		const error = getInNamespaced(errors, namespace, name);
		const enabled = getInNamespaced(isEnabled, namespace, name) !== false;

		let visible = getInNamespaced(isFieldVisible, namespace, name);
		if (visible == null) {
			visible = getInNamespaced(defaultFieldVisibility, namespace, name, false);
		}

		const fieldValue = getInNamespaced(formValues, namespace, name);
		const initialFieldValue = getInNamespaced(
			initialFormValues,
			namespace,
			name
		);
		const exists = existsInNamespaced(formValues, namespace, name);

		return { error, enabled, visible, fieldValue, initialFieldValue, exists };
	});

	return data;
}
