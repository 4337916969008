import { useEffect } from "react";
import { LoginInfoResource, NewsResource } from "@ploy-lib/rest-resources";
import { useInvalidator } from "@rest-hooks/core";
import { useStatefulResource } from "@rest-hooks/legacy";

type Options = {
	showInfoMessage?: boolean;
	placeholder?: boolean;
};

export function useNewsListResources({
	showInfoMessage = false,
	placeholder = false
}: Options): [
	{
		newsList: Readonly<NewsResource>[] | undefined;
		info: Readonly<LoginInfoResource> | undefined;
	},
	{ loading: boolean; error: Error | undefined }
] {
	const { data: newsList, ...newsListState } = useStatefulResource(
		NewsResource.list(),
		!placeholder ? {} : null
	);
	const { data: info, ...infoState } = useStatefulResource(
		LoginInfoResource.detail(),
		!placeholder && showInfoMessage ? {} : null
	);

	const invalidateNews = useInvalidator(NewsResource.list());
	const invalidateLoginInfo = useInvalidator(LoginInfoResource.detail());

	useEffect(
		() => () => {
			invalidateNews({});
			invalidateLoginInfo({});
		},
		[invalidateLoginInfo, invalidateNews]
	);

	return [
		{
			newsList,
			info
		},
		{
			loading:
				!placeholder &&
				(newsListState.loading || (showInfoMessage && infoState.loading)),
			error:
				newsListState.error ?? (showInfoMessage ? infoState.error : undefined)
		}
	];
}
