import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Link,
	Typography,
	Grid,
	makeStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useResource } from "@rest-hooks/core";
import {
	ProductInfoLinkResource,
	ProductInfoLink,
	ProductResource
} from "@ploy-lib/rest-resources";

interface ProductInfoProps {
	productExternalCode: string;
	header: string;
}

export const ProductInfo = (props: ProductInfoProps) => {
	const { productExternalCode, header } = props;

	const products = useResource(ProductResource.list(), {});

	const classes = useStyles();

	const productId = products.find(
		p => p.externalCode === productExternalCode
	)?.id;
	const productInfoLinks = useResource(
		ProductInfoLinkResource.list(),
		productId
			? {
					productId
			  }
			: null
	);
	return (productInfoLinks?.length ?? 0) > 0 ? (
		<Grid item xs={12} className={classes.productInfoContainer}>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Grid item xs={12}>
						<Typography align="center">{header}</Typography>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<div>
						{productInfoLinks?.map((productInfoLink: ProductInfoLink) => (
							<Typography
								key={productInfoLink.linkId}
								className={classes.typoClass}
							>
								<Link
									href={productInfoLink.linkUrl}
									target="_blank"
									rel="noopener noreferrer"
									underline="hover"
								>
									{productInfoLink.linkText}
								</Link>
							</Typography>
						))}
					</div>
				</AccordionDetails>
			</Accordion>
		</Grid>
	) : null;
};

const useStyles = makeStyles({
	productInfoContainer: {
		position: "relative",
		height: "50px",
		zIndex: 20
	},
	typoClass: {
		overflowWrap: "anywhere"
	}
});
