import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export class ThirdPartyScriptSettingsResource extends BaseCamelCasedResource {
	//	CookieBot
	loadCookieBot = false;
	cookieBotId?: string;

	// Giosg Chat
	loadGiosgChat = false;

	//	GTM
	loadGoogleTagManager = false;
	googleTagManagerCode?: string;
	googleTagManagerIncludeProduct?: boolean;
	googleTagManagerIncludeObjectCategory?: boolean;
	loadGoogleTagManagerExtended = false;

	//	Zendesk
	loadZendeskChat = false;
	zendeskAccountKey?: string;
	zendeskAlternativeAccountKey?: string;
	loadDNBFIZendesk = false;

	pk() {
		return "ThirdPartyScriptSettings";
	}

	static urlRoot = legacyApiResourceUrl("Login");
}
