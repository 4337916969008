import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import {
	TemplateField,
	SectionTemplate,
	TemplateTableColumn
} from "@ploy-lib/types";

export class SectionTemplateResource
	extends BaseResource
	implements SectionTemplate
{
	readonly sectionTemplateId?: ID;
	readonly name: string;
	readonly description?: string;
	readonly fields: TemplateField[];
	readonly tableColumns: TemplateTableColumn[];
	readonly tableDataType?: string;

	pk() {
		return this.sectionTemplateId?.toString();
	}

	static urlRoot = apiResourceUrl("sectiontemplate");
}
