import { useCallback, useDebugValue, useMemo } from "react";
import { useVariableData } from "@ploy-lib/calculation";
import isArray from "lodash/isArray";
import { OptionSource, TemplateField } from "@ploy-lib/types";
import { HasItems } from "@ploy-ui/form-fields";
import { FieldProps, FormikProps, useField } from "formik";
import { useServiceHandler } from "./useServiceHandler";
import { getFieldName } from "@ploy-lib/core";

export const getSelectItemValueKey = (idKey?: string) => (item: any) => {
	if (item != null && typeof item === "object") {
		if (idKey && item.hasOwnProperty(idKey)) return idKey;
		if (item.hasOwnProperty("key")) return "key";
		if (item.hasOwnProperty("Key")) return "Key";
		if (isArray(item) && item.length > 0) return 0;

		const keys = Object.keys(item);
		if (keys.length > 0) return keys[0];
	}
	return undefined;
};

export const selectItemValue = (idKey?: string) => {
	const getItemValueKey = getSelectItemValueKey(idKey);
	return (item: any) => {
		const key = getItemValueKey(item);
		if (key) return item[key];
		return item;
	};
};

export const selectItemLabel =
	(labelKey?: string, idKey?: string) => (item: any) => {
		if (item != null && typeof item === "object") {
			if (labelKey && item.hasOwnProperty(labelKey))
				return item[labelKey].trim();
			if (item.hasOwnProperty("value")) return item["value"].trim();
			if (item.hasOwnProperty("Value")) return item["Value"].trim();
			if (isArray(item) && item.length > 1) return item[1].trim();

			const keys = Object.keys(item);
			if (keys.length > 1) return item[keys[1]].toString().trim();
			return selectItemValue(idKey);
		}
		return item;
	};

export const filterItems = <TOptions extends any[] | undefined>(
	items: TOptions,
	keepItems: string,
	idKey?: string
): TOptions => {
	if (!items) return items;

	keepItems = keepItems.replace(/\^|\$/gi, "");

	const keepItemsList = new Set(keepItems.split("|"));

	const getItemValue = selectItemValue(idKey);

	return items.filter(x =>
		keepItemsList.has(getItemValue(x).toString())
	) as TOptions;
};

export const useOptionSource = <
	TOption extends string | number | boolean | object
>(
	field: FieldProps["field"],
	form: FormikProps<any>,
	namespace: TemplateField["namespace"],
	optionSource?: OptionSource,
	onSearch?: () => {}
): HasItems<TOption> | undefined => {
	const optionSourceName = optionSource && optionSource.name;
	const [formikOptionsourceField] = useField(
		getFieldName({ namespace, name: optionSourceName ?? "" })
	);

	const { value: baseItems = formikOptionsourceField.value } = useVariableData<
		TOption[]
	>(namespace, optionSourceName);

	const { value: keepItems } = useVariableData<string>(
		namespace,
		optionSource ? optionSource.filter : ""
	);

	const items = useMemo(() => {
		if (keepItems && optionSource)
			return filterItems(baseItems || [], keepItems, optionSource.valueKey);
		return baseItems || [];
	}, [baseItems, keepItems, optionSource]);

	const valueType = typeof (isArray(field.value)
		? field.value[0]
		: field.value);

	const getItemValue = useCallback(
		(item: TOption) => {
			const value = selectItemValue(optionSource && optionSource.valueKey)(
				item
			);

			switch (valueType) {
				case "number":
					return Number(value);
				case "boolean":
					return Boolean(value);
				case "string":
					return String(value);
				default:
					return value;
			}
		},
		[optionSource, valueType]
	);
	const { labelKey, valueKey } = optionSource || {};

	const getItemLabel = useCallback(
		item => {
			if (labelKey) {
				return labelKey
					.split("|")
					.map(k => selectItemLabel(k, valueKey)(item))
					.join("|");
			}
			return selectItemLabel(labelKey, valueKey)(item);
		},
		[labelKey, valueKey]
	);

	const [fetchOptions] = useServiceHandler(
		optionSource && optionSource.service,
		field,
		namespace
	);

	const getItemSuggestions = useCallback(
		async (search: string) => {
			if (fetchOptions) {
				const { ok, data } = await fetchOptions(undefined, search);
				if (ok) return data;
				return [];
			}
			return [];
		},
		[fetchOptions]
	);

	const { setFieldValue } = form;

	const onSelectItem = useCallback(
		(item: TOption | null) => {
			if (optionSource) {
				setFieldValue(`${namespace}.${optionSource.selected}`, item);
			}
			if (onSearch) onSearch();
		},
		[optionSource, onSearch, setFieldValue, namespace]
	);

	useDebugValue(optionSourceName);

	return optionSourceName
		? {
				items,
				labelKey,
				valueKey,
				getItemLabel,
				getItemValue,
				getItemSuggestions: fetchOptions ? getItemSuggestions : undefined,
				onSelectItem
		  }
		: undefined;
};
