import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";
import { ID } from "../types";

export class NewsResource extends BaseCamelCasedResource {
	readonly newsItemId?: ID;
	readonly newsHeader?: string;
	readonly newsIngress?: string;
	readonly newsContent?: string;

	pk() {
		return this.newsItemId?.toString();
	}

	static urlRoot = legacyApiResourceUrl("Information/GetNews");
}
