import { useMemo } from "react";
import {
	IntlConfig,
	Formatters,
	useIntl,
	FormatDateOptions,
	ReactIntlError,
	ReactIntlErrorCode
} from "react-intl";
import { getFormatter } from "@formatjs/intl/src/dateTime";

let _defaultDateTimeOptions: FormatDateOptions;
const getDefaultOptions = () =>
	_defaultDateTimeOptions ||
	(_defaultDateTimeOptions =
		new Intl.DateTimeFormat().resolvedOptions() as FormatDateOptions);

export function resolveDateOptions(
	config: Pick<IntlConfig, "locale" | "formats" | "onError" | "timeZone">,
	getDateTimeFormat: Formatters["getDateTimeFormat"],
	options: FormatDateOptions = {}
): FormatDateOptions {
	try {
		return getFormatter(
			config as any,
			"date",
			getDateTimeFormat,
			options
		).resolvedOptions() as FormatDateOptions;
	} catch (e: any) {
		config.onError(
			new ReactIntlError(
				ReactIntlErrorCode.FORMAT_ERROR,
				"Error resolving date options.",
				e
			)
		);
	}

	return getDefaultOptions();
}

export function resolveTimeOptions(
	config: Pick<IntlConfig, "locale" | "formats" | "onError" | "timeZone">,
	getDateTimeFormat: Formatters["getDateTimeFormat"],
	options: FormatDateOptions = {}
): FormatDateOptions {
	try {
		return getFormatter(
			config as any,
			"time",
			getDateTimeFormat,
			options
		).resolvedOptions() as FormatDateOptions;
	} catch (e: any) {
		config.onError(
			new ReactIntlError(
				ReactIntlErrorCode.FORMAT_ERROR,
				"Error resolving time options.",
				e
			)
		);
	}

	return getDefaultOptions();
}

function partsToFormatString(parts: Intl.DateTimeFormatPart[]) {
	return parts
		.map((p, i, arr) => {
			switch (p.type) {
				// nb-NO locale formats {day: 2-digit, month: 2-digit} to "d.m." for some reason (tested in Chrome and Firefox)
				// force 2-digit format for everything except year, and skip the last '.'
				case "literal":
					if (i === arr.length - 1 && p.value === ".") return "";
					return p.value;
				case "day":
					return "dd";
				case "month":
					return "MM";
				case "year":
					return p.value.replace(/./g, "y");
				case "hour":
					return "HH";
				case "minute":
					return "mm";
				case "second":
					return "ss";
				default:
					return "";
			}
		})
		.join("");
}

const viewKeysMap = {
	day: "date",
	hour: "hours",
	minute: "minutes",
	second: "seconds"
};

const dateKeys = new Set(["year", "month", "day"]);
const timeKeys = new Set(["hour", "minute", "second"]);

function partsToViews(parts: Intl.DateTimeFormatPart[]) {
	return [...dateKeys, ...timeKeys]
		.filter(x => parts.some(p => p.type === x))
		.map(x => viewKeysMap[x] || x);
}

export function useDateFormat(
	options: FormatDateOptions = {},
	formatType: "date" | "time"
) {
	const intl = useIntl();

	return useMemo(
		() => {
			const resolvedOptions =
				formatType === "time"
					? resolveTimeOptions(intl, intl.formatters.getDateTimeFormat, options)
					: resolveDateOptions(
							intl,
							intl.formatters.getDateTimeFormat,
							options
					  );

			let fixedOptions = { ...resolvedOptions };

			dateKeys.forEach(k => {
				if (fixedOptions[k])
					if (!(k === "year" && fixedOptions[k] === "numeric"))
						fixedOptions[k] = "2-digit";
			});
			timeKeys.forEach(k => {
				if (fixedOptions[k]) fixedOptions[k] = "2-digit";
			});
			if (fixedOptions.hour) fixedOptions.hour12 = false;

			let parts =
				formatType === "time"
					? intl.formatTimeToParts(new Date(), fixedOptions)
					: intl.formatDateToParts(new Date(), fixedOptions);

			return {
				views: parts ? partsToViews(parts) : ["year", "month", "date"],
				format: parts ? partsToFormatString(parts) : "dd.MM.yyyy",
				ampm: Boolean(fixedOptions.hour12),
				fixedOptions,
				resolvedOptions
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			formatType,
			intl,
			options.era,
			options.formatMatcher,
			options.hour,
			options.hour12,
			options.localeMatcher,
			options.minute,
			options.month,
			options.second,
			options.timeZone,
			options.timeZoneName,
			options.weekday,
			options.year,
			options.format,
			options.day
		]
	);
}
