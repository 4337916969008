import { useMemo, useEffect } from "react";
import { useSnackbar } from "notistack";
import { getIn, useFormikContext } from "formik";
import { defineMessages, useIntl } from "react-intl";

const submitErrorMessages = defineMessages({
	submitErrorMessage: {
		id: `error-snackbar.submit-error`,
		description: `Snackbar error message when submit fails`,
		defaultMessage: `Noe gikk galt ved innsending av søknad`
	}
});

export const useSubmitErrorSnackbar = () => {
	const formik = useFormikContext();
	const intl = useIntl();
	const { enqueueSnackbar = () => {} } = useSnackbar() || {};
	const submitErrorMessage = intl.formatMessage(
		submitErrorMessages.submitErrorMessage
	);
	const submitErrorsValue = useMemo(() => {
		return (getIn(formik.values, "__calculation") ?? {})?.submitResult
			?.Messages;
	}, [formik]);

	useEffect(() => {
		if (submitErrorsValue?.length > 0)
			enqueueSnackbar(submitErrorMessage, { variant: "error" });
	}, [submitErrorsValue, submitErrorMessage, enqueueSnackbar]);
};
