import { useCallback } from "react";
import { FieldProps } from "formik";

export const useFormikOnError = ({ field, form }: FieldProps) => {
	const { setFieldError } = form;
	const { name } = field;
	return useCallback(
		(error, value) => {
			setFieldError(name, error);
		},
		[setFieldError, name]
	);
};
