import Paper from "@material-ui/core/Paper";
import { styled, Theme, emphasize } from "@material-ui/core/styles";
import { palette, StyleFunction, PropsFor, compose } from "@material-ui/system";

export const autocolor: StyleFunction<
	{
		autoContrastText?: boolean;
		emphasizeText?: number;
		theme?: Theme;
	} & PropsFor<typeof palette>
> = props => {
	if (!props.autoContrastText) {
		return palette(props);
	}

	let color = props.color;

	if (!color && props.bgcolor?.endsWith(".main")) {
		color = props.bgcolor.replace(".main", ".contrastText");
	}

	const theme = props.theme;

	const styles = palette({ ...props, color });

	let contrastText =
		styles.color ?? styles.backgroundColor
			? theme?.palette.getContrastText(styles.backgroundColor)
			: undefined;

	if (contrastText && props.emphasizeText != null) {
		contrastText = emphasize(contrastText, props.emphasizeText);
	}

	return {
		...styles,
		color: contrastText
	};
};

(autocolor as any).filterProps = [
	...((palette as any).filterProps ?? []),
	"autoContrastText",
	"emphasizeText"
];

export const ColoredPaper = styled(Paper)(compose(autocolor));
