import React from "react";
import {
	LiteralBase,
	LiteralProps,
	getLiteralBaseProps,
	baseEditableOptions
} from "./TextLiteral";
import { FormattedDate, FormatDateOptions } from "react-intl";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";

export const DatePickerLiteral = ({
	localeMatcher,
	weekday,
	era,
	year,
	month,
	day,
	hour,
	minute,
	second,
	timeZoneName,
	formatMatcher,
	hour12,
	timeZone,
	format,
	...rest
}: LiteralProps & FormatDateOptions) => {
	const formatOptions = {
		localeMatcher,
		formatMatcher,
		timeZone,
		hour12,
		weekday,
		era,
		year,
		month,
		day,
		hour,
		minute,
		second,
		timeZoneName,
		format
	};

	return (
		<LiteralBase {...getLiteralBaseProps(rest)}>
			{rest.field.value ? (
				<FormattedDate
					value={rest.field.value}
					{...formatOptions}
					dateStyle="short"
				/>
			) : (
				<div></div>
			)}
		</LiteralBase>
	);
};

export const EditorDatePickerLiterals = identityRecordOfFieldEditorOptions({
	DatePickerLiteral: {
		editableOptions: {
			...baseEditableOptions
		}
	}
});
