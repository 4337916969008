import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import { StyleFunction, compose, bgcolor, style } from "@material-ui/system";
import { Img } from "@rest-hooks/img";
import { FileImg } from "./FileImage";
import { DragStateProps, DropzoneProps } from "./types";
import { Grid } from "@material-ui/core";

export function getDragStateColor(props: DragStateProps & { theme: Theme }) {
	if ((props.theme.palette as any).success && props.isDragAccept) {
		return (props.theme.palette as any).success.main;
	}
	if (props.isDragReject) {
		return props.theme.palette.error.main;
	}
	if (props.isDragActive) {
		return props.theme.palette.grey[600];
	}
	return undefined;
}

const backgroundPadding: StyleFunction<{ bgcolor?: any }> = ({ bgcolor }) => ({
	padding: bgcolor ? 20 : 0
});

export const StyleImg = styled(Img)(compose(bgcolor, backgroundPadding));

export const StyleFileImg = styled(FileImg)(
	compose(bgcolor, backgroundPadding)
);

export const flexGrow = style({
	prop: "grow",
	cssProperty: "flexGrow"
}) as StyleFunction<{ grow: number }>;

export const GridGrow = styled(Grid)(flexGrow);

export const useFileViewStyles = makeStyles(theme => ({
	root: {
		...theme.shape,
		display: "flex",
		alignItems: "center",
		width: "100%",
		textAlign: "center" as "center",
		"&:hover $selectable": {
			opacity: 0.3
		},
		"&:hover $img": {
			opacity: 0.3
		},
		"&:hover $removeBtn": {
			opacity: 1
		},
		color: "rgba(0, 0, 0, 0.87)",
		transition: "all .24s ease-in-out",
		boxSizing: "border-box" as "border-box",
		boxShadow: theme.shadows[1],
		"&$selected": {
			boxShadow: theme.shadows[10],
			transform: "scale(1.1)"
		},
		position: "relative" as "relative"
	},
	removeBtn: {
		marginRight: theme.spacing(1),
		transition: ".24s ease",
		opacity: 0
	},
	removeFab: {
		position: "absolute" as "absolute",
		zIndex: 10,
		top: -5,
		right: -5,
		marginRight: 0
	},
	filename: {
		transition: "all .24s ease-in-out",
		padding: theme.spacing(1),
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap" as "nowrap",
		maxWidth: "12rem"
	},
	preview: {
		height: 100,
		opacity: 1,
		cursor: "auto"
	},
	img: {
		maxWidth: "100%",
		maxHeight: "100%"
	},
	dropzone: {},
	previewDropzoneGrid: {
		display: "flex",
		alignItems: "stretch",
		flexGrow: 1
	},
	selectable: {
		opacity: 1,
		cursor: "pointer"
	},
	selected: {}
}));

const dropzoneStyles: StyleFunction<
	DragStateProps & Pick<DropzoneProps, "variant">
> = (
	props: DragStateProps & Pick<DropzoneProps, "variant"> & { theme: Theme }
) => ({
	...props.theme.shape,
	borderWidth: 2,
	margin: -4,
	padding: 2,
	transition: "border .24s ease-in-out",
	borderColor:
		getDragStateColor(props) ||
		(props.variant === "button"
			? "transparent"
			: props.theme.palette.grey[400]),
	borderStyle: "dashed",
	outline: "none",
	...(props.variant === "area"
		? {
				transitionProperty: "border,color",
				color: getDragStateColor(props) || props.theme.palette.grey[400],
				"&:hover": {
					borderColor: getDragStateColor({ ...props, isDragActive: true }),
					color: getDragStateColor({ ...props, isDragActive: true })
				},
				cursor: "pointer"
		  }
		: undefined)
});

(dropzoneStyles as any).filterProps = [
	"isDragActive",
	"isDragAccept",
	"isDragReject",
	"variant"
];

export const DropContainer = styled("div")(dropzoneStyles);

const dragStateStyles: StyleFunction<
	DragStateProps & Pick<ButtonProps, "variant"> & ButtonProps
> = (
	props: DragStateProps & Pick<ButtonProps, "variant"> & { theme: Theme }
) => {
	const stateColor = getDragStateColor(props);

	switch (props.variant) {
		case "outlined":
			return {
				color: stateColor,
				borderColor: stateColor
			};
		case "contained":
			return {
				backgroundColor: stateColor,
				color: stateColor && props.theme.palette.getContrastText(stateColor)
			};
		default:
			return {
				color: stateColor
			};
	}
};

(dragStateStyles as any).filterProps = [
	"isDragActive",
	"isDragAccept",
	"isDragReject"
];

export const DragStateButton = styled(Button)(dragStateStyles);
