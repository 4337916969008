import { useMemo } from "react";
import { useResource } from "@rest-hooks/core";
import {
	Product,
	LoginResource,
	ProductSelectorType
} from "@ploy-lib/rest-resources";
import { usePageState } from "@ploy-ui/template-form";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { makeStyles, getContrastRatio } from "@material-ui/core/styles";
import clsx from "clsx";
import { RouterLink } from "@ploy-ui/core";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(
	theme => ({
		root: {
			fontWeight: 700,
			[theme.breakpoints.down("xs")]: {
				padding: 4,
				marginTop: 8
			}
		},
		option: {
			padding: 0
		},
		optionLink: {
			paddingTop: 6,
			paddingBottom: 6,
			paddingLeft: 16,
			paddingRight: 16,
			flexGrow: 1,
			color:
				getContrastRatio(
					theme.palette.primary.main,
					theme.palette.common.white
				) > 2.5
					? theme.palette.primary.main
					: theme.palette.text.primary
		},
		headingText: {},
		productGroupingText: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(3)
		},
		buttonContainer: {},
		buttonMdContainer: {
			paddingTop: theme.spacing(1),
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
			paddingBottom: theme.spacing(1)
		},
		buttonPaper: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: "8px",
			height: 80,
			marginTop: theme.spacing(2),
			cursor: "pointer",
			width: "300px",
			"&:hover": {
				backgroundColor: theme.palette.primary.light,
				boxShadow: theme.shadows[12]
			}
		},
		buttonItemText: {
			color: theme.palette.primary.contrastText,
			fontWeight: "bold"
		}
	}),
	{ name: "DployProductSelector" }
);

export enum ProductSelectorVariant {
	Dropdown = "dropdown",
	Buttons = "buttons"
}

export interface ProductSelectorProps {
	products: Product[];
	variant?: ProductSelectorVariant;
	basepath?: string;
	selected?: Product;
	disabled?: boolean;
}

export const ProductSelector = (props: ProductSelectorProps) => {
	const { isDirectUser } = useResource(LoginResource.status(), {});

	const { variant = ProductSelectorVariant.Dropdown, ...rest } = props;

	if (isDirectUser) return null;

	if (variant === ProductSelectorVariant.Buttons) {
		return <ProductSelectorButtons {...rest} />;
	}
	return <ProductSelectorDropdown {...rest} />;
};

const ProductSelectorDropdown = (props: ProductSelectorProps) => {
	const { products, selected, disabled, basepath = "" } = props;

	const { step } = usePageState();

	const isHeader = disabled || step > 0 || !products || products.length === 0;

	const items = useMemo(
		() => (isHeader ? [selected] : products),
		[isHeader, products, selected]
	);

	const classes = useStyles(props);
	const navigate = useNavigate();

	return (
		<DployAutocomplete
			className={clsx(classes.root)}
			AutocompleteProps={{
				classes: {
					option: classes.option
				},
				getOptionSelected: (option, value) =>
					option?.externalCode === value?.externalCode
			}}
			data-test-id="product-selector"
			items={items}
			getItemLabel={p => p?.name ?? ""}
			value={selected}
			onChange={(e, product) =>
				product && navigate(`${basepath}${product.externalCode.toLowerCase()}`)
			}
			renderOption={(product, state) => (
				<RouterLink
					className={classes.optionLink}
					underline="none"
					to={`${basepath}${product?.externalCode.toLowerCase()}`}
					data-product-external-code={product?.externalCode}
				>
					{product?.name}
				</RouterLink>
			)}
			variant="standard"
			searchable={false}
			fullWidth
			disableClearable
			readonly={isHeader || items.length === 1}
		/>
	);
};

const ProductSelectorButtons = (props: ProductSelectorProps) => {
	const { products, basepath } = props;
	const classes = useStyles(props);

	const intl = useIntl();

	const renderGroups = () =>
		Object.values(ProductSelectorType).map(productSelectorType => {
			const availableProductsForSelectorType = products.filter(
				product => product.selectorType === productSelectorType
			);

			if (availableProductsForSelectorType.length === 0) {
				return null;
			}

			return (
				<div key={productSelectorType}>
					<Typography variant="h5" className={classes.productGroupingText}>
						{intl.formatMessage(selectorTypeEnumMessages[productSelectorType])}
					</Typography>
					<Grid container direction="row" className={classes.buttonContainer}>
						{renderFilteredProducts(availableProductsForSelectorType)}
					</Grid>
				</div>
			);
		});

	const renderFilteredProducts = (availableProducts: Product[]) =>
		availableProducts.map(product => (
			<ProductSelectionItem
				key={product.id}
				basepath={basepath}
				product={product}
			/>
		));

	return (
		<Grid container direction="column" data-test-id="product-selector-buttons">
			<Typography
				variant="h4"
				color="secondary"
				className={classes.headingText}
			>
				<FormattedMessage
					id="dealer.productView.productSelector.buttonsVariant.header"
					description="header message in productselector"
					defaultMessage="Start new process"
				/>
			</Typography>
			{renderGroups()}
		</Grid>
	);
};

interface ProductSelectionItemProps {
	product: Product;
	basepath?: string;
}

const ProductSelectionItem = (props: ProductSelectionItemProps) => {
	const { product, basepath } = props;
	const { name, externalCode } = product;

	const classes = useStyles(props);
	const navigate = useNavigate();

	return (
		<Grid item md={4} className={classes.buttonMdContainer}>
			<Grid
				item
				container
				justifyContent="center"
				alignItems="center"
				className={classes.buttonPaper}
				onClick={() => {
					navigate(`${basepath}${externalCode.toLowerCase()}`);
				}}
			>
				<RouterLink
					underline="none"
					to={`${basepath}${externalCode.toLowerCase()}`}
					data-product-external-code={externalCode}
				>
					<Typography className={classes.buttonItemText}>{name}</Typography>
				</RouterLink>
			</Grid>
		</Grid>
	);
};

const selectorTypeEnumMessages = defineMessages({
	[ProductSelectorType.Business]: {
		id: "dealer.productView.productSelector.buttonsVariant.Business",
		description:
			"ProductSelectorType translation for ProductSelectorType.Businsess",
		defaultMessage: "Business"
	},
	[ProductSelectorType.Calculator]: {
		id: "dealer.productView.productSelector.buttonsVariant.Calculator",
		description:
			"ProductSelectorType translation for ProductSelectorType.Calculator",
		defaultMessage: "Calculator"
	},
	[ProductSelectorType.NotSet]: {
		id: "dealer.productView.productSelector.buttonsVariant.NotSet",
		description:
			"ProductSelectorType translation for ProductSelectorType.NotSet",
		defaultMessage: "NotSet"
	},
	[ProductSelectorType.Private]: {
		id: "dealer.productView.productSelector.buttonsVariant.Private",
		description:
			"ProductSelectorType translation for ProductSelectorType.Private",
		defaultMessage: "Private"
	},
	[ProductSelectorType.Service]: {
		id: "dealer.productView.productSelector.buttonsVariant.Service",
		description:
			"ProductSelectorType translation for ProductSelectorType.Service",
		defaultMessage: "Service"
	}
});
