import { useEffect, useState } from "react";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid, IconButton, Modal, Typography } from "@material-ui/core";
import { Panel, PanelProps } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Formik, useFormikContext } from "formik";

export type ModalPopupPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type ModalPopupType = keyof ModalPopupPanels;
export type ModalPopupPage = LayoutPage<ModalPopupType, ModalPopupPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};

const panelSettings: Record<ModalPopupType, PanelSettings> = {
	main: mainPanelSettings,
	actions: {}
};

addRegisteredLayout({
	name: "ModalPopup",
	displayName: "ModalPopup",
	panels: panelSettings,
	canDecideMaxWidth: true
});

enum ModalCloseModeEnum {
	EnableDefaultClose_And_EnableCloseOnSubmit = 1,
	EnableDefaultClose_And_DisableCloseOnSubmit = 2,
	DisableDefaultClose_And_EnableCloseOnSubmit = 3,
	DisableDefaultClose_And_DisableCloseOnSubmit = 4
}

export interface ModalPopupProps extends TemplatePageProps<ModalPopupPage> {}

export const ModalPopup = (props: ModalPopupProps) => {
	const {
		panels,
		maxWidth,
		pageTitle,
		modalClick,
		modalCloseMode = ModalCloseModeEnum.EnableDefaultClose_And_EnableCloseOnSubmit
	} = props;

	const { main, actions } = panels;

	const classes = useStyles(props);

	const pageDefinedPanelSpecificProps: Partial<PanelProps> = {
		maxWidth
	};

	const [open, setOpen] = useState(true);

	const { submitForm, setValues, initialValues, values, ...formikProps } =
		useFormikContext();

	useEffect(() => {
		setOpen(true);
	}, [modalClick]);

	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={() => {
				if (
					modalCloseMode ===
						ModalCloseModeEnum.EnableDefaultClose_And_EnableCloseOnSubmit ||
					modalCloseMode ===
						ModalCloseModeEnum.EnableDefaultClose_And_DisableCloseOnSubmit
				) {
					setOpen(!open);
				}
			}}
		>
			<Formik
				initialValues={values as any}
				enableReinitialize={true}
				onSubmit={async values => {
					setValues(values);
					await submitForm();
					if (
						modalCloseMode ===
							ModalCloseModeEnum.EnableDefaultClose_And_EnableCloseOnSubmit ||
						modalCloseMode ===
							ModalCloseModeEnum.DisableDefaultClose_And_EnableCloseOnSubmit
					) {
						setOpen(false);
					}
				}}
				{...formikProps}
			>
				<Grid
					container
					spacing={3}
					justifyContent="center"
					className={clsx(classes.pageMaxWidth, classes.modalInternalGrid)}
					direction="column"
				>
					<Grid
						item
						container
						direction="row"
						style={{ justifyContent: "space-between" }}
					>
						<Grid item>
							<Typography variant="h5">{pageTitle}</Typography>
						</Grid>
						<Grid item>
							<IconButton onClick={() => setOpen(!open)}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs={12} md={12}>
						{main && (
							<Panel transparent {...pageDefinedPanelSpecificProps} {...main} />
						)}
					</Grid>

					{actions && (
						<Grid item xs={12} md={12}>
							<Panel
								transparent
								justifyContent="center"
								{...pageDefinedPanelSpecificProps}
								{...actions}
							/>
						</Grid>
					)}
				</Grid>
			</Formik>
		</Modal>
	);
};

ModalPopup.displayName = "DployFormModalPopup";
