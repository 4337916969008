import React, { createContext, useContext, useDebugValue } from "react";
import { CalculatorState } from "../calculator";
import { ActionTypes } from "../types";
import { ServiceManager } from "../service-manager/createServiceManager";
import {
	emptyEventManager,
	EventManager
} from "../event-manager/createEventManager";
import { DispatchWithPriority } from "./provider/useRenderReducer";

declare module "react" {
	// unstable observedBits
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface ConsumerProps<T> {
		unstable_observedBits?: number;
	}
	function createContext<T>(
		defaultValue: unknown,
		unstable_calculateChangedBits?: (prev: T, next: T) => number
	): React.Context<T>;

	// unstable Suspense API
	interface SuspenseProps {
		/**
		 * Tells React whether to “skip” revealing this boundary during the initial load.
		 * This API will likely be removed in a future release.
		 */
		// NOTE: this is unflagged and is respected even in stable builds
		unstable_avoidThisFallback?: boolean;
	}
}

/**
 * ObservedBits functionality is not public API and can break in the future
 * Once/if that happens, it's hopefully replaced by functionality
 * that can achieve the same purpose without the same limitations
 * Using unstable internal react API, ref: https://github.com/facebook/react/issues/14815#issuecomment-462194923
 *
 */
function useUnstableContextWithoutWarning<T>(
	Context: React.Context<T>,
	observedBits?: number | boolean
): T {
	const { ReactCurrentDispatcher } = (React as any)
		.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
	const dispatcher = ReactCurrentDispatcher.current;
	if (!dispatcher) {
		throw new Error(
			"Hooks can only be called inside the body of a function component. (https://fb.me/react-invalid-hook-call)"
		);
	}
	return dispatcher.useContext(Context, observedBits);
}

export const dispatchContext = createContext<
	DispatchWithPriority<ActionTypes<any, any>>
>(() => {});

dispatchContext.displayName = "CalculationDispatch";

export const DispatchProvider = dispatchContext.Provider;

export const serviceManagerContext = createContext<ServiceManager<any, any>>({
	call: () => Promise.reject("Service manager missing"),
	trigger: () => Promise.reject("Service manager missing"),
	getHref: () => undefined
});

serviceManagerContext.displayName = "ServiceManager";

export const ServiceManagerProvider = serviceManagerContext.Provider;

const calculationObserveFlags: Record<
	keyof CalculatorState<string, any>,
	number
> = {
	values: 1 << 0,
	isMissing: 1 << 1,
	isWriteLocked: 1 << 2,
	isFieldVisible: 1 << 3,
	isEnabled: 1 << 4,
	errors: 1 << 5,
	formValues: 1 << 6,
	controlFieldMaps: 1 << 7,
	fieldControlMaps: 1 << 8,
	resolvedVariableMaps: 1 << 9,
	serviceBodyValuesMap: 1 << 10,
	variableControlMaps: 1 << 11,
	initialFormValues: 1 << 12,
	defaultFieldVisibility: 1 << 13
};

const calculateChangedBits = (
	prev: Partial<CalculatorState<string, number | string | boolean>>,
	next: Partial<CalculatorState<string, number | string | boolean>>
) =>
	Object.entries(calculationObserveFlags).reduce(
		(res, [key, bit]) => (prev[key] !== next[key] ? res | bit : res),
		0
	);

const defaultState = {};

export const calculationContext = createContext<
	Partial<CalculatorState<string, number | string | boolean>>
>(defaultState, calculateChangedBits);

calculationContext.displayName = "CalculationState";

export const CalculationProvider = calculationContext.Provider;

export const useHasCalculation = () => {
	const state = useUnstableContextWithoutWarning(calculationContext, 0);

	return state !== defaultState;
};

export const useCalculation = <
	T extends (keyof CalculatorState<string, any>)[]
>(
	...observedKeys: [...T]
) => {
	const flags =
		observedKeys.length > 0
			? observedKeys.reduce((res, key) => res | calculationObserveFlags[key], 0)
			: undefined;

	const state = useUnstableContextWithoutWarning(calculationContext, flags);

	useDebugValue(
		`Observing: ${
			observedKeys.length > 0 ? observedKeys.join(", ") : "calculation"
		}`
	);

	return Object.fromEntries(
		Object.entries(state).filter(([key]) =>
			observedKeys.includes(key as keyof CalculatorState<string, any>)
		)
	) as Pick<Partial<CalculatorState<string, any>>, T[number]>;
};

export const eventContext =
	createContext<EventManager<any, any>>(emptyEventManager);

export const useEventManager = <
	TN extends string = string,
	TD = number | string | boolean
>() => {
	const state = useContext(eventContext) as EventManager<TN, TD>;
	// useDebugValue(state ? "patchManager" : "no patchManager");

	return state;
};

export const EventProvider = eventContext.Provider;
