import * as React from "react";

import { Grid } from "@material-ui/core";
import { GlobalSuspenseActivator } from "@ploy-ui/core";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";

interface MissingPageProps {
	error?: Error | string | boolean;
	loading?: boolean;
	header: React.ReactNode;
}

const getErrorMessage = (
	error?: Error | string | boolean
): string | undefined => {
	const defaultMessage = "Skjemaoppsett for produktet mangler";
	switch (typeof error) {
		case "undefined":
			return;
		case "string":
			return error;
		case "boolean":
			return defaultMessage;
		default:
			return error.message || defaultMessage;
	}
};

export const MissingPage = ({ loading, error, header }: MissingPageProps) => (
	<Grid container>
		<Grid item xs={12} container>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					{header}
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						direction="column"
					>
						<Grid item>
							{loading ? (
								<GlobalSuspenseActivator />
							) : (
								<WarningIcon
									fontSize="large"
									color={error ? "error" : "disabled"}
								/>
							)}
						</Grid>
						{!loading && (
							<>
								<Grid item>
									<Typography variant="h5">En feil har oppstått</Typography>
								</Grid>
								<Grid item>
									<Typography variant="subtitle1">
										{getErrorMessage(error)}
									</Typography>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);
