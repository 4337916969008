import React from "react";

import { Grid } from "@material-ui/core";

import { Panel } from "../../../panel";
import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { ThemeProvider } from "@material-ui/core/styles";
import { TemplatePanel } from "@ploy-lib/types";
import {
	columnsSummaryHeaderTheme,
	useStyles,
	actionsTheme,
	reducedSummaryTheme
} from "./styles";

export type ColumnSummaryPanels = {
	summary: TemplatePanel;
	main: TemplatePanel;
	summaryHeader: TemplatePanel;
	actions: TemplatePanel;
	appActions: TemplatePanel;
};

export type ColumnSummaryPanelKeys = keyof ColumnSummaryPanels;
export type ColumnSummaryPage = LayoutPage<
	ColumnSummaryPanelKeys,
	ColumnSummaryPanels
>;

type ThreeColumnPanelSettings = Record<ColumnSummaryPanelKeys, PanelSettings>;

const editableOptions: PanelSettings["editableOptions"] = {
	title: false,
	elevation: true,
	square: true
};

const panels: ThreeColumnPanelSettings = {
	summaryHeader: {},
	summary: {
		editableOptions,
		defaultOptions: {
			literal: true
		}
	},
	main: { editableOptions },
	actions: {},
	appActions: {}
};

addRegisteredLayout({
	name: "ColumnSummary",
	displayName: "ColumnSummary",
	panels,
	reduceFormHeightForLaptops: true,
	displayInStepper: true
});

export function ColumnSummary(props: TemplatePageProps<ColumnSummaryPage>) {
	const classes = useStyles(props);
	const { header, panels, reduceFormHeightForLaptops } = props;

	const { summaryHeader, main, summary, actions, appActions } = panels;

	return (
		<Grid container spacing={3} justifyContent="center">
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item md={4} container alignItems="stretch">
						{header}
					</Grid>
					<Grid item md={4} container alignItems="stretch">
						{summaryHeader && (
							<ThemeProvider theme={columnsSummaryHeaderTheme}>
								<Panel {...summaryHeader} className={classes.summaryHeader} />
							</ThemeProvider>
						)}
					</Grid>
					<Grid
						item
						md={4}
						container
						alignItems="stretch"
						wrap="nowrap"
						justifyContent="flex-end"
					>
						{appActions && (
							<ThemeProvider theme={actionsTheme}>
								<Panel
									{...appActions}
									className={classes.actionsPanel}
									justifyContent="flex-end"
								/>
							</ThemeProvider>
						)}
					</Grid>
				</Grid>
			</Grid>
			{summary && (
				<Grid item xs={12} sm={8}>
					{reduceFormHeightForLaptops ? (
						<ThemeProvider theme={reducedSummaryTheme}>
							<Panel
								className={classes.mainSectionsReducedPadding}
								{...summary}
							/>
						</ThemeProvider>
					) : (
						<Panel className={classes.mainSections} {...summary} />
					)}
				</Grid>
			)}
			{main && (
				<Grid item xs={12} sm={8}>
					<Panel
						className={
							reduceFormHeightForLaptops
								? classes.mainSectionsReducedPadding
								: classes.mainSections
						}
						{...main}
					/>
				</Grid>
			)}
			<Grid item xs={12} sm={8}>
				{actions && (
					<ThemeProvider theme={actionsTheme}>
						<Panel
							justifyContent="flex-end"
							transparent
							fullWidth
							{...actions}
						/>
					</ThemeProvider>
				)}
			</Grid>
		</Grid>
	);
}
