export const fieldOptionListVariableName = (fieldName: string) =>
	`options_${fieldName}`;

export const fieldOptionSelectedVariableName = (fieldName: string) =>
	`optionselected_${fieldName}`;

export const fieldOptionFilterVariableName = (fieldName: string) =>
	`optionfilter_${fieldName}`;

export const fieldSearchServiceName = (fieldName: string) =>
	`search_${fieldName}`;

export const fieldClickServiceName = (fieldName: string) =>
	`click_${fieldName}`;

export const servicePendingName = (
	serviceNamespace: string,
	serviceName: string
) => `${serviceNamespace}_Service_${serviceName}_Pending`;

export { unstable_batchedUpdates as batch } from "react-dom";
