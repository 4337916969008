import { useMemo } from "react";
import {
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
	makeStyles
} from "@material-ui/core";

import {
	fieldOptionListVariableName,
	useTemplateDebugManager,
	useVariableData
} from "@ploy-lib/calculation";
import { CheckboxWithLabelField, TextField } from "@ploy-ui/form-fields";
import { useCallback } from "react";
import { FormattedNumber, defineMessages, useIntl } from "react-intl";
import clsx from "clsx";
import { Formik, useField, Field as FormikField } from "formik";
import { SectionProps } from "../../section";
import { addRegisteredSectionLayout } from "../../section/sectionLayoutDescriptions";
import {
	ExtendedApplicationDebt,
	FormikAutoSubmit,
	debtExistencePredicate,
	ensureCollectionHasIds
} from "./LimitedDebtField";

import { ApplicationDebt, ApplicationDebtSource } from "@ploy-lib/types";

import * as Yup from "yup";

const messages = defineMessages({
	whatLoansToFulfill: {
		id: "template-form.field.limitedDebtFulfillment.whatLoansToFulfill",
		defaultMessage: "Which loans should be fulfilled or paid down"
	},
	accountToPayDownLoan: {
		id: "template-form.field.limitedDebtFulfillment.accountToPayDownLoan",
		defaultMessage: "Account number for paying down loan"
	},
	formatLoanTypeAndBank: {
		id: "template-form.field.limitedDebtFulfillment.formatLoanTypeAndBank",
		defaultMessage: "{loanType} from {lender}"
	},
	validAccountNumberisRequired: {
		id: "template-form.field.limitedDebtFulfillment.validAccountNumberisRequired",
		defaultMessage: "A valid bank account number is required"
	}
});

const useStylesFulfillmentItem = makeStyles(theme => ({
	component: {
		width: "100%"
	},
	label: {
		display: "content",
		overflowWrap: "anywhere"
	},
	spacingWhenNotLast: {
		paddingBottom: theme.spacing(3)
	}
}));

function LimitedDebtToFulfill(props: {
	debt: ExtendedApplicationDebt;
	isLastItem?: boolean;
	onUpdate?: (updatedDebt: ExtendedApplicationDebt) => void;
}) {
	const intl = useIntl();
	const classes = useStylesFulfillmentItem();

	const loanTypes = (useVariableData(
		"Calculator",
		fieldOptionListVariableName("LoanTypeOptionHelper")
	).value ?? []) as { key: string; value: string }[];

	const localizedLoanType =
		loanTypes.find(l => l.key === props.debt.loanType)?.value ??
		props.debt.loanType;

	const applicationDebtToFulfillSchema = useMemo(
		(): Yup.ObjectSchema<
			Pick<ApplicationDebt, "refinance" | "refinanceAccount">
		> =>
			Yup.object({
				refinance: Yup.bool().required(),
				refinanceAccount: Yup.string()
					.default("")
					.when("refinance", {
						is: true,
						then: schema =>
							schema.required(
								intl.formatMessage(messages.validAccountNumberisRequired)
							)
					})
			}),
		[intl]
	);

	return (
		<Formik
			initialValues={props.debt}
			enableReinitialize
			validateOnMount
			validationSchema={applicationDebtToFulfillSchema}
			onSubmit={(debtToUpdate, helpers) => {
				props.onUpdate?.(debtToUpdate);
				return Promise.resolve(debtToUpdate);
			}}
		>
			{formikProps => (
				<>
					<FormikAutoSubmit />
					<Grid
						container
						spacing={3}
						className={clsx({
							[classes.spacingWhenNotLast]:
								!props.isLastItem && formikProps.values.refinance
						})}
					>
						<Grid item xs={12}>
							<FormikField
								name="refinance"
								fullWidth
								disableTypography
								className={classes.component}
								component={CheckboxWithLabelField}
								label={
									<Grid container spacing={1} alignItems="center" wrap="nowrap">
										<Grid item xs={12}>
											<span className={classes.label}>
												{intl.formatMessage(messages.formatLoanTypeAndBank, {
													loanType: localizedLoanType,
													lender: props.debt.lender
												})}
											</span>
										</Grid>
										<Grid item xs>
											<Typography noWrap>
												<FormattedNumber
													value={props.debt.amount}
													format="currency"
												/>
											</Typography>
										</Grid>
									</Grid>
								}
							/>
						</Grid>
						{formikProps.values.refinance ? (
							<Grid item xs={12}>
								<FormikField
									name="refinanceAccount"
									component={TextField}
									label={intl.formatMessage(messages.accountToPayDownLoan)}
									disabled={props.onUpdate === undefined}
									fullWidth
								/>
							</Grid>
						) : null}
					</Grid>
				</>
			)}
		</Formik>
	);
}

export function LimitedDebtsFulfillmentSection(props: SectionProps) {
	const intl = useIntl();

	const [field, , { setValue }] = useField("Calculator.Debts");

	const debtList = useMemo(
		() => ensureCollectionHasIds(field.value),
		[field.value]
	);

	const relevantDebts = useMemo(
		() =>
			debtList.filter(
				d =>
					d.loanType != null &&
					d.loanType !== "credit_card" &&
					// Filter out loans from debt registry because we do not know their origin
					d.source !== ApplicationDebtSource.DebtRegistry
			) ?? [],
		[debtList]
	);

	const onUpdate = useCallback(
		(updatedData: ExtendedApplicationDebt) => {
			const updatedList = debtList.map(debt =>
				debtExistencePredicate(debt, updatedData) ? updatedData : debt
			);
			setValue(updatedList);
		},
		[debtList, setValue]
	);

	const { enabled: debugEnabled } = useTemplateDebugManager();

	if (relevantDebts.length === 0) {
		return debugEnabled ? (
			<Grid
				container
				spacing={1}
				className={props.className}
				onClick={props.onClick}
			>
				<Grid item xs>
					<Typography>No content here, just helping debugging :)</Typography>
				</Grid>
			</Grid>
		) : null;
	}

	return (
		<Grid
			container
			spacing={1}
			className={props.className}
			onClick={props.onClick}
		>
			<Grid item xs={12}>
				<Typography variant="body2">
					{intl.formatMessage(messages.whatLoansToFulfill)}
				</Typography>
			</Grid>
			{relevantDebts.map((d, i) => (
				<Grid item key={d._frontendOnlyId ?? d.applicationDebtId} xs={12}>
					<LimitedDebtToFulfill
						debt={d}
						isLastItem={i === relevantDebts.length - 1}
						onUpdate={props.literal ? undefined : onUpdate}
					/>
				</Grid>
			))}
		</Grid>
	);
}

addRegisteredSectionLayout({
	displayName: "LimitedDebtsFulfillmentSection",
	name: "LimitedDebtsFulfillmentSection",
	settings: {}
});

LimitedDebtsFulfillmentSection.displayName = "LimitedDebtFulfillment";
