import React, { useState, Suspense, useEffect } from "react";
import { SectionProps } from "../Section";
import {
	Grid,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	LinearProgress,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { PendingButton, DataTable, DployColumn } from "@ploy-ui/core";
import { useFetcher, useInvalidator } from "@rest-hooks/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { FormattedMessage, useIntl } from "react-intl";
import {
	SigningDocumentResource,
	useResourceWithInvalidate,
	ApplicationSignerResource,
	UploadedDocumentResource,
	VendorDocumentGroupResource,
	ID
} from "@ploy-lib/rest-resources";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { useAppLoad } from "../../appLoadContext";
import { useResource } from "@rest-hooks/core";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { Alert } from "@material-ui/lab";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	input: {
		display: "none"
	},
	documentTable: {
		marginTop: theme.spacing(2)
	},
	fullWidth: {
		width: "100%"
	},
	encouragingText: {
		marginBottom: 10
	}
}));

const uploadDocument = (
	file: File,
	documentType: string | null,
	customerContext: string | null
) => {
	const formData = new FormData();
	formData.append("file", file, file.name);
	if (documentType) formData.append("documentType", documentType);
	if (customerContext) formData.append("customerContext", customerContext);

	return fetch(legacyApiResourceUrl("DocumentGrid/Upload"), {
		method: "POST",
		body: formData
	});
};
// @todo: This is a legacy API call, and should be replaced with a REST call using the rest-hooks library.
const deleteDocument = documentId => {
	return fetch(legacyApiResourceUrl("DocumentGrid/DeleteUploadedDocument"), {
		method: "POST",
		headers: new Headers({
			"Content-type": "application/json"
		}),
		body: JSON.stringify({
			documentId: documentId
		})
	});
};

const ConfirmDialog = ({ open, text, onConfirm, onDeny }) => {
	return (
		<Dialog open={open}>
			<DialogTitle>{text}</DialogTitle>
			<DialogActions>
				<Button onClick={onDeny} color="primary">
					<FormattedMessage
						id="template-form.sections.document-upload.confirm-no"
						defaultMessage="No"
					/>
				</Button>
				<Button onClick={onConfirm} color="primary">
					<FormattedMessage
						id="template-form.sections.document-upload.confirm-yes"
						defaultMessage="Yes"
					/>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

addRegisteredSectionLayout({
	name: "DocumentUploadSection",
	displayName: "DocumentUploadSection",
	settings: {
		editableOptions: {
			allowEditSameSession: true
		}
	}
});

const DocumentUploadSection = (props: SectionProps) => {
	// Fetch hooks start
	const intl = useIntl();
	const { isDirectUser, applicationNumber, id: appSessionId } = useAppLoad();

	const [searchParams, _] = useSearchParams();
	const customerContext = searchParams.get("customerContext");

	const refetchSigningDocuments = useFetcher(SigningDocumentResource.list());
	const refetchSigners = useFetcher(ApplicationSignerResource.list());

	const documentList = useResourceWithInvalidate(
		UploadedDocumentResource.list(),
		appSessionId ? { applicationNumber, customerContext } : null
	);

	const [initialDocuments, setInitialDocuments] =
		useState<(ID | undefined)[]>();

	useEffect(() => {
		if (initialDocuments) return;
		setInitialDocuments(documentList?.map(x => x.documentId) ?? []);
	}, [documentList]);

	const refetchDocuments = useFetcher(UploadedDocumentResource.list());
	// only deletes from the cache, we don't have a method that overrides the delete method in rest-hooks
	const deleteDocumentFromResource = useFetcher(
		UploadedDocumentResource.delete()
	);

	const fetchDocGroups = !isDirectUser && !!appSessionId;

	const documentGroups = useResource(
		VendorDocumentGroupResource.list(),
		fetchDocGroups ? { applicationNumber } : null
	);

	// Fetch hooks end
	const [selectedGroup, setSelectedGroup] =
		useState<VendorDocumentGroupResource | null>(null);

	useEffect(() => {
		let defaultGroup = documentGroups?.find(dg => dg.isDefaultGroup);
		if (defaultGroup) setSelectedGroup(defaultGroup);
	}, [documentGroups]);

	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const classes = useStyles(props);
	const { className, onClick, allowEditSameSession } = props;

	const filteredDocumentGroups =
		documentGroups &&
		documentGroups.filter(
			dg =>
				dg.allowMultipleUploads ||
				!(
					documentList &&
					documentList.some(d => d.documentType === dg.documentCode)
				)
		);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [selectedDocument, setSelectedDocument] =
		useState<UploadedDocumentResource | null>(null);
	const [uploadLoading, setUploadLoading] = useState(false);

	useEffect(() => {
		if (filteredDocumentGroups && filteredDocumentGroups.length === 1)
			setSelectedGroup(filteredDocumentGroups[0]);
		else if (
			selectedGroup &&
			filteredDocumentGroups &&
			!filteredDocumentGroups.some(
				dg => dg.documentCode === selectedGroup.documentCode
			)
		)
			setSelectedGroup(null);
	}, [filteredDocumentGroups, selectedGroup]);

	const hideDocTypeColumn =
		isDirectUser || !documentGroups || documentGroups.length === 0;
	const hideMustSignColumn =
		isDirectUser || !documentGroups || !documentGroups.some(d => d.mustSign);
	// can only delete documents that can be signed (customer wish)
	const showActionColumn =
		(documentList?.some(d => d.canBeDeleted) || allowEditSameSession) ?? false;
	const hideHeader = hideDocTypeColumn;

	const showDocumentTypes =
		filteredDocumentGroups && filteredDocumentGroups.length > 0;

	const isSmDown = useMediaQuery(useTheme().breakpoints.down("sm"));

	const uploadButtonText = (
		<FormattedMessage
			id="template-form.sections.document-upload.upload-button"
			description="Label for documentUpload Button"
			defaultMessage="Upload document"
		/>
	);

	return (
		<Grid
			container
			spacing={isSmDown ? 2 : 3}
			className={className}
			onClick={onClick}
		>
			<Suspense fallback={<LinearProgress />}>
				<Grid container>
					{showDocumentTypes && (
						<Grid item xs={6}>
							<DployAutocomplete
								label={
									<FormattedMessage
										id="template-form.sections.document-upload.document-type"
										description="Label documenttype dropdown"
										defaultMessage="Document type"
									/>
								}
								items={filteredDocumentGroups || []}
								value={selectedGroup}
								getItemLabel={item => item.description || item.documentName}
								onChange={(e, item) => {
									setErrorMessage(undefined);
									setSelectedGroup(item);
								}}
							/>
						</Grid>
					)}

					<Grid item xs={showDocumentTypes ? 6 : 12}>
						{isDirectUser && (
							<div className={classes.encouragingText}>
								<FormattedMessage
									id="template-form.sections.document-upload.encouraging-text"
									defaultMessage={""}
									values={{
										uploadText: uploadButtonText
									}}
								/>
							</div>
						)}
						<input
							type="file"
							id="document-upload"
							className={classes.input}
							disabled={fetchDocGroups && !selectedGroup && showDocumentTypes}
							onChange={({ target: { files } }) => {
								if (files && files.length === 1) {
									setUploadLoading(true);
									uploadDocument(
										files[0],
										selectedGroup && selectedGroup!.documentCode,
										customerContext
									).then(response => {
										response
											.json()
											.then(result => {
												if (result.error) {
													setErrorMessage(result.error);
												} else {
													setErrorMessage(undefined);
												}
											})
											.catch(error => {
												setErrorMessage(
													intl.formatMessage({
														id: "template-form.sections.document-upload.genericuploaderror",
														defaultMessage: "Det skjedde en feil."
													})
												);
											});
										setUploadLoading(false);
										if (selectedGroup && selectedGroup.mustSign) {
											refetchSigningDocuments({ applicationNumber });
											refetchSigners({ applicationNumber, signerType: 0 });
										}
										refetchDocuments({ applicationNumber, customerContext });
									});
								}
							}}
						/>
						<label htmlFor="document-upload" className={classes.fullWidth}>
							<PendingButton
								variant="contained"
								color="primary"
								size="large"
								pending={uploadLoading}
								component="span"
								fullWidth
								disabled={fetchDocGroups && !selectedGroup && showDocumentTypes}
								endIcon={<CloudUploadIcon />}
							>
								{uploadButtonText}
							</PendingButton>
						</label>
					</Grid>
					{errorMessage && (
						<Grid item xs={12}>
							<Alert severity="error">{errorMessage}</Alert>
						</Grid>
					)}
				</Grid>
				{documentList && documentList.length > 0 ? (
					<Grid item xs={12}>
						<Suspense fallback={<LinearProgress />}>
							<DataTable<UploadedDocumentResource>
								data={documentList}
								columns={
									[
										{
											title: (
												<FormattedMessage
													id="template-form.sections.document-upload.name-column"
													description="Uploaded document name"
													defaultMessage="Name"
												/>
											),
											grouping: false,
											cellStyle: {
												wordBreak: "break-word"
											},
											render: rowData => (
												<Link
													href={legacyApiResourceUrl(
														"/DocumentGrid/GetDocument" +
															"?documentId=" +
															rowData.documentId
													)}
													className="file-upload__document-name"
													underline="hover"
												>
													{rowData.filename}
												</Link>
											)
										},
										hideDocTypeColumn
											? null
											: {
													title: (
														<FormattedMessage
															id="template-form.sections.document-upload.document-type-column"
															description="Uploaded document type"
															defaultMessage="Type"
														/>
													),
													field: "documentTypeDisplayname"
											  },
										hideMustSignColumn
											? null
											: {
													title: (
														<FormattedMessage
															id="template-form.sections.document-upload.must-sign-column"
															description="Document should be signed"
															defaultMessage="Must be signed"
														/>
													),
													type: "boolean",
													render: rowData =>
														rowData.mustSign ? (
															<div className="file-upload__must-sign">
																<i className="fa fa-check fa-lg checked-color" />
															</div>
														) : null
											  }
									].filter(
										x => x !== null
									) as DployColumn<UploadedDocumentResource>[]
								}
								actions={
									showActionColumn
										? [
												rowData => ({
													icon: () => <DeleteIcon />,
													tooltip: intl.formatMessage({
														id: "template-form.sections.document-upload.delete-document-action",
														defaultMessage: "Slett dokument"
													}),
													onClick: () => {
														setSelectedDocument(rowData);
														setOpenConfirmDialog(true);
													},
													hidden:
														!rowData.canBeDeleted ||
														allowEditSameSession === false
												})
										  ]
										: undefined
								}
								options={{
									actionsColumnIndex: Infinity,
									search: false,
									toolbar: false,
									paging: documentList.length > 5,
									showEmptyDataSourceMessage: false,
									emptyRowsWhenPaging: false,
									idSynonym: "documentId"
								}}
								components={
									hideHeader
										? {
												Header: props => null
										  }
										: {}
								}
							/>
						</Suspense>
					</Grid>
				) : null}
				<ConfirmDialog
					open={openConfirmDialog}
					text={
						<FormattedMessage
							id="template-form.sections.document-upload.delete-document-confirm"
							description="Confirm deletion of uploaded document"
							defaultMessage="Er du sikker på at du vil slette dokumentet?"
						/>
					}
					onDeny={() => setOpenConfirmDialog(false)}
					onConfirm={() => {
						setOpenConfirmDialog(false);
						deleteDocument(selectedDocument!.documentId).then(() => {
							deleteDocumentFromResource({
								documentId: selectedDocument!.documentId
							});
							refetchSigningDocuments({ applicationNumber });
							refetchSigners({ applicationNumber, signerType: 0 });
							refetchDocuments({ applicationNumber });
						});
					}}
				/>
			</Suspense>
		</Grid>
	);
};

DocumentUploadSection.displayName = "DployDocumentUploadSection";

export { DocumentUploadSection };
