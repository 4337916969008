import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { PendingIconButton } from "@ploy-ui/core";

import {
	IconButton,
	InputAdornment,
	Toolbar,
	Tooltip,
	Typography
} from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";

import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { DployTextField } from "@ploy-ui/form-fields";

const messages = defineMessages({
	search: {
		id: "dploy.template-form.table-section.search.placeholder",
		descrption: "Search",
		defaultMessage: "Søk"
	}
});

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
			  },
	title: {
		flex: "1 1 100%"
	}
}));

export interface TableToolbarProps {
	title?: React.ReactNode;
	numSelected?: number;
	searchValue?: string;
	canDelete?: boolean;
	canNextPage?: boolean;
	onSearch?: (query: string) => void;
	onDelete?: () => void | Promise<void>;
}

export function TableToolbar(props: TableToolbarProps) {
	const classes = useToolbarStyles();
	const {
		numSelected = 0,
		title,
		searchValue = "",
		canDelete,
		onSearch,
		onDelete,
		canNextPage
	} = props;

	const intl = useIntl();

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0
			})}
		>
			{canNextPage && numSelected > 0 ? (
				<Typography
					className={classes.title}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					<FormattedMessage
						id="dploy.template-form.table-section.selected.title"
						description="{numSelected} selected"
						defaultMessage="{numSelected} valgt"
						values={{ numSelected }}
					/>
				</Typography>
			) : (
				<Typography
					className={classes.title}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					{title}
				</Typography>
			)}

			{numSelected > 0 && canDelete ? (
				<Tooltip title="Delete">
					<PendingIconButton
						aria-label="delete"
						onClick={onDelete}
						success={false}
						error={false}
					>
						<DeleteIcon />
					</PendingIconButton>
				</Tooltip>
			) : onSearch ? (
				<DployTextField
					placeholder={intl.formatMessage(messages.search)}
					value={searchValue}
					onChange={e => onSearch(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{searchValue === "" ? (
									<IconButton>
										<SearchIcon />
									</IconButton>
								) : (
									<IconButton onClick={() => onSearch("")}>
										<ClearIcon />
									</IconButton>
								)}
							</InputAdornment>
						)
					}}
				/>
			) : null}
		</Toolbar>
	);
}
