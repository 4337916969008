import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		summaryHeader: {
			textAlign: "center" as "center",
			backgroundColor: theme.palette.grey[200]
		},
		errorSection: {
			backgroundColor: "transparent",
			[theme.breakpoints.up("sm")]: {
				paddingLeft: theme.spacing(10),
				paddingRight: theme.spacing(10)
			}
		}
	}),
	{ name: "DployList" }
);

export const listSummaryHeaderTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormLiteral: {
			root: {
				flexWrap: "wrap",
				alignItems: "flex-end",
				justifyContent: "center"
			},
			value: {
				flexGrow: 0,
				fontSize: 24,
				lineHeight: 1,
				fontWeight: 700,
				paddingLeft: 0
			},
			label: {
				fontSize: 12,
				flexGrow: 0,
				maxWidth: "100%",
				flexBasis: "100%",
				marginRight: 0,
				marginBottom: theme.spacing(1)
			},
			suffix: {
				flexGrow: 0,
				fontSize: 16,
				fontWeight: 700,
				width: "auto"
			},
			variantEmphasized: {
				borderBottom: "none"
			}
		},
		DployFormSectionGroup: {
			expansionContent: {
				padding: "1.5rem .5rem"
			},
			sectionGroup: {
				maxWidth: "none",
				justifyContent: "center"
			}
		},
		DployCustomerInfoSection: {
			root: {
				textAlign: "left",
				paddingLeft: "8%",
				paddingRight: "8%",
				paddingBottom: "16px",
				[theme.breakpoints.down("xs")]: {
					paddingLeft: 8,
					paddingRight: 8
				}
			}
		}
	} as any
});

export const mainHeaderTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				padding: theme.spacing(1),
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2)
			}
		},
		DployFormLiteral: {
			marginNormal: {
				marginBottom: theme.spacing(0)
			}
		}
	} as any
});

export const mainTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSection: {
			header: {
				marginBottom: theme.spacing(1)
			}
		}
	} as any
});

export const appActionsTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormControl: {
			marginNormal: {
				marginTop: 0,
				marginBottom: 0
			}
		},
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			sectionGroup: {
				maxWidth: "none"
			}
		}
	} as any
});

export const footerTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSectionGroup: {
			sectionGroup: {
				maxWidth: "unset"
			}
		}
	}
});
