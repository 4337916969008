import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface CustomerGroupType {
	customerId: number;
	organizations: CustomerGroupItemType[];
}

export interface CustomerGroupItemType {
	customerId?: number;
	customerNumber: string;
	customerName: string;
	import: boolean;
	exclude: boolean;
	exists: boolean;
	addToLimitCustomers: boolean;
}

export class CustomerGroupResource
	extends BaseCamelCasedResource
	implements CustomerGroupType
{
	readonly customerId: number;
	readonly organizations: CustomerGroupItemType[];

	pk() {
		return this.customerId?.toString() || "";
	}

	static urlRoot = apiResourceUrl("customer-group");
}
