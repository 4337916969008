import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		errorSection: {
			backgroundColor: theme.palette.grey[300],
			[theme.breakpoints.up("sm")]: {
				paddingLeft: theme.spacing(10),
				paddingRight: theme.spacing(10)
			}
		},
		panelGrid: {
			"&:empty": {
				display: "none"
			},
			maxWidth: "100%",
			width: "100%"
		},
		summaryHeader: {
			backgroundColor: theme.palette?.accent1?.main ?? theme.palette.grey[200]
		},
		summaryHeaderAlternative: {
			backgroundColor: "#fff"
		},
		actions: {},
		stickyActions: { paddingTop: theme.spacing(1) },
		panelRoot: {},
		panelRounded: {
			"$panelRoot &": {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0
			},
			"$panelRoot:first-child &": {
				borderTopLeftRadius: theme.shape.borderRadius,
				borderTopRightRadius: theme.shape.borderRadius
			},
			"$panelRoot:last-child &": {
				borderBottomLeftRadius: theme.shape.borderRadius,
				borderBottomRightRadius: theme.shape.borderRadius
			}
		},
		rounded: {
			overflow: "hidden",
			"&:first-child": {
				borderTopLeftRadius: theme.shape.borderRadius,
				borderTopRightRadius: theme.shape.borderRadius
			},
			"&:last-child": {
				borderBottomLeftRadius: theme.shape.borderRadius,
				borderBottomRightRadius: theme.shape.borderRadius
			}
		}
	}),
	{ name: "DployFormWizard" }
);

export const wizardSummaryTheme = theme =>
	({
		...theme,
		overrides: {
			...theme.overrides,
			DployFormSectionGroup: {
				...(theme.overrides && theme.overrides.DployFormSectionGroup),
				sectionGroupWrapper: {
					paddingTop: theme.spacing(4),
					padding: theme.spacing(4)
				}
			},
			DployFormLiteral: {
				root: {
					fontSize: 14
				},
				label: {
					fontSize: "inherit"
				},
				value: {
					fontSize: "inherit",
					fontWeight: 700
				},
				suffix: {
					fontSize: "inherit"
				},
				marginNormal: {
					marginTop: 0,
					marginBottom: theme.spacing(1)
				}
			}
		} as any
	} as unknown as Theme);

export const wizardSummaryHeaderTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				paddingTop: theme.spacing(4),
				padding: theme.spacing(4)
			}
		},
		DployFormLiteral: {
			root: {
				borderBottom: "none",
				flexWrap: "wrap",
				alignItems: "flex-end"
			},
			value: {
				flexGrow: 0,
				fontSize: 32,
				lineHeight: 1,
				fontWeight: 700,
				paddingLeft: 0
			},
			label: {
				fontSize: 12,
				flexGrow: 0,
				maxWidth: "100%",
				flexBasis: "100%",
				marginBottom: theme.spacing(1)
			},
			suffix: {
				flexGrow: 1,
				fontSize: 16,
				fontWeight: 700
			}
		}
	} as any
});

export const wizardSummaryHeaderThemeAlternative = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				padding: theme.spacing(3),
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(1)
			}
		},
		DployFormLiteral: {
			root: {
				borderBottom: "none",
				fontSize: 24,
				alignItems: "center"
			},
			marginNormal: {
				marginBottom: 0
			},
			value: {
				flexGrow: 1,
				textAlign: "end",
				fontWeight: 700
			},
			label: {
				paddingLeft: theme.spacing(1),
				flexGrow: 1,
				fontSize: 16
			},
			suffix: {
				flexGrow: 1,
				fontSize: 14,
				width: "auto",
				textTransform: "uppercase",
				textAlign: "left"
			}
		}
	} as any
});

export const actionsTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			sectionGroup: {
				maxWidth: "none"
			}
		},
		DployCalculationField: {
			field: {
				[theme.breakpoints.down("xs")]: {
					width: "100%"
				}
			}
		},
		DployFormFieldContainer: {
			fieldWrapper: {
				[theme.breakpoints.down("xs")]: {
					flexGrow: 1
				}
			}
		},
		MuiButton: {
			...(theme.overrides && theme.overrides.MuiButton),
			root: {
				...(theme.overrides &&
					theme.overrides.MuiButton &&
					theme.overrides.MuiButton.root),
				whiteSpace: "nowrap"
			}
		}
	} as any
});
