import { Service, CalculationResponse } from "@ploy-lib/types";
import { CalculatorState } from "../calculator";
import { isNotNull } from "../calculator/utils";
import { Patch, NamespaceService, ServiceTrigger } from "../types";
import {
	createServiceCaller,
	HandlerResponse,
	HandlerOptions
} from "./createServiceCaller";
import { calculationToParams } from "./calculationToParams";
import { ServiceBodyValue } from "..";

export interface ServiceResult extends HandlerResponse {
	cancelled?: boolean;
	timeout?: boolean;
}

export interface CallServiceInput<TNamespaces> extends HandlerOptions {
	namespace: TNamespaces;
	name: string;
}

export interface ServiceManager<TNamespaces extends string, TData> {
	getHref(
		input: CallServiceInput<TNamespaces>,
		calculation?: Partial<CalculatorState<TNamespaces, TData>>
	): string | undefined;

	call(
		input: CallServiceInput<TNamespaces>,
		calculation?: Partial<CalculatorState<TNamespaces, TData>>,
		urlParams?: { [key: string]: string } | undefined,
		payload?: any
	): Promise<ServiceResult>;

	trigger(
		triggers: readonly ServiceTrigger<TNamespaces>[],
		calculation?: Partial<CalculatorState<TNamespaces, TData>>
	): Promise<ServiceResult[]>;
}

export function createServiceManager<TNamespaces extends string, TData>(
	services: Record<TNamespaces, Service[]>,
	additionalServiceBody:
		| Partial<Record<TNamespaces, ServiceBodyValue[]>>
		| undefined,
	handler: (
		service: NamespaceService<TNamespaces>,
		body: any,
		params: any,
		options: HandlerOptions
	) => Promise<HandlerResponse>,
	createServiceUrl: (baseUrl: string, searchParams: any) => string,
	onServiceSuccess: (
		namespace: TNamespaces,
		service: Service,
		patches: Patch<TNamespaces, TData>[]
	) => void,
	onUpdateDataModel?: (calc: CalculationResponse) => void
): ServiceManager<TNamespaces, TData> {
	const serviceCaller = createServiceCaller(
		handler,
		onServiceSuccess,
		additionalServiceBody,
		onUpdateDataModel
	);

	const serviceMaps = new Map(
		Object.entries<Service[]>(services).map(([namespace, serviceList]) => {
			const serviceMap = new Map(
				serviceList.map(service => {
					return [service.name, { ...service, namespace }] as [
						string,
						NamespaceService<TNamespaces>
					];
				})
			);
			return [namespace, serviceMap] as [TNamespaces, typeof serviceMap];
		})
	);

	async function call(
		{ name, namespace, ...options }: CallServiceInput<TNamespaces>,
		calculation?: Partial<CalculatorState<TNamespaces, TData>>,
		urlParams?: { [key: string]: string } | undefined,
		payload?: any
	): Promise<ServiceResult> {
		const serviceMap = serviceMaps.get(namespace);
		const service = serviceMap && serviceMap.get(name);

		if (!service) throw new Error(`Service ${namespace}.${name} not found`);

		return serviceCaller(service, options, calculation, urlParams, payload);
	}

	async function trigger(
		triggers: readonly ServiceTrigger<TNamespaces>[],
		calculation?: Partial<CalculatorState<TNamespaces, TData>>
	): Promise<ServiceResult[]> {
		const servicesToTrigger = triggers
			.map(t => {
				const serviceMap = serviceMaps.get(t.namespace);
				return serviceMap && serviceMap.get(t.service);
			})
			.filter(isNotNull);

		return Promise.all(
			Array.from(servicesToTrigger).map(s =>
				serviceCaller(s, undefined, calculation, undefined, undefined)
			)
		);
	}

	function getHref(
		{ name, namespace }: CallServiceInput<TNamespaces>,
		calculation?: Partial<CalculatorState<TNamespaces, TData>>
	) {
		const serviceMap = serviceMaps.get(namespace);
		const service = serviceMap && serviceMap.get(name);

		if (!service) return undefined;

		let params = calculationToParams(
			calculation,
			service.parameterList,
			service.namespace
		);

		return createServiceUrl(service.url, params);
	}

	return {
		getHref,
		call,
		trigger
	};
}
