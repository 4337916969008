import { apiResourceUrl } from "@ploy-lib/core";
import paramsToString, { BaseResource } from "./BaseResource";
import { SimpleResource } from "@rest-hooks/rest";

export interface EmbeddedProductFieldMetadata {
	readonly changeInformation: string;
}

export interface EmbeddedProductSchemaMetadata {
	readonly hasCoSigner: boolean;
	readonly hasGuarantor: boolean;
}

export interface EmbeddedProductFieldData {
	readonly value: string;
	readonly metadata: EmbeddedProductFieldMetadata;
}

export interface EmbeddedProductData {
	readonly applicationId?: number;
	readonly externalCode?: string;
	readonly schemaMetadata?: EmbeddedProductSchemaMetadata;
	readonly fields?: Record<string, EmbeddedProductFieldData>;
}

export class EmbeddedProductFieldResource
	extends BaseResource
	implements EmbeddedProductData
{
	readonly applicationId?: number = undefined;
	readonly externalCode?: string = undefined;
	readonly schemaMetadata?: EmbeddedProductSchemaMetadata = undefined;
	readonly fields?: Record<string, EmbeddedProductFieldData> = undefined;

	pk(parent?: any, key?: string): string | undefined {
		return `${this.applicationId?.toString()}/${this.externalCode}`;
	}
	static url<T extends typeof SimpleResource>(
		this: T,
		urlParams: Readonly<Record<string, any>>
	) {
		const { applicationId, externalCode, ...searchParams } = urlParams;

		const url = super.url(urlParams);

		return `${url}${paramsToString(searchParams)}`;
	}

	static calcPathToFields = "fields";
	static calcPathToValue = "value";

	static urlRoot = apiResourceUrl("ep-field");
}
