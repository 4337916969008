import React from "react";

import { useResource, useFetcher } from "@rest-hooks/core";

import { ExpandableListEditSingle } from "@ploy-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { VendorDriver, DriverResource, ID } from "@ploy-lib/rest-resources";

import { Grid, ListItemText } from "@material-ui/core";
import { useIntl } from "react-intl";
import { driverFormConfig } from "./DriverFormConfig";

const CollapsedElement = (values: VendorDriver) => (
	<Grid container alignItems="center">
		<ChevronRight />
		<ListItemText
			primary={`${values.firstname} ${values.lastname}`}
			secondary={values.regNumber}
		/>
	</Grid>
);

export function DriverForm(props) {
	const { customerId, onDriverSaved, requestClose } = props;

	const intl = useIntl();

	const driverConfig = driverFormConfig(intl);

	const drivers =
		useResource(
			DriverResource.list(),
			customerId && !isNaN(customerId) && Number(customerId) !== 0
				? { customerId }
				: null
		) || [];

	const create = useFetcher(DriverResource.create());
	const update = useFetcher(DriverResource.update());

	// const onCreate = (driver: VendorDriver) => {
	// 	return create({}, { ...driver, customerId }, undefined);
	// };

	const onCreate = (driver: VendorDriver) => {
		return create({}, { ...driver, customerId }, [
			[
				DriverResource.list(),
				{ customerId },
				(newDriverId: ID, driverIds: ID[] | undefined) => [
					...(driverIds || []),
					newDriverId
				]
			]
		]);
	};

	const onUpdate = (driver: VendorDriver) => {
		return update({ driverId: driver.driverId }, driver);
	};

	return (
		<ExpandableListEditSingle<VendorDriver>
			initialElementsList={drivers}
			elementIdKey={"id"}
			elementConfig={driverConfig}
			collapsedElement={CollapsedElement}
			requestClose={requestClose}
			onCreate={driver =>
				onCreate(driver).then(onDriverSaved && onDriverSaved(driver))
			}
			onUpdate={driver =>
				onUpdate(driver).then(onDriverSaved && onDriverSaved(driver))
			}
			elementCreator={() => DriverResource.fromJS({})}
			useThemedButtons
		/>
	);
}
