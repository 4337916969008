import { apiResourceUrl } from "@ploy-lib/core";
import { DecisionTableTemplate, ID } from "@ploy-lib/types";
import { Entity } from "@rest-hooks/rest";
import paramsToString, { BaseCamelCasedResource } from "./BaseResource";
import { LimitCustomerType } from "./LimitCustomerResource";
export interface LimitDetail {
	readonly limitId?: ID;
	readonly customerId?: number;
	readonly customers?: string[];
	readonly engagementCustomerId?: number;
	readonly customerName?: string;
	readonly products?: string[];
	readonly objectTypes?: string[];
	readonly limitAmount?: number;
	readonly minEquity?: number;
	readonly loanAmount?: number;
	readonly objectCondition?: number;
	readonly terms?: number;
	readonly vatRule?: number;
	readonly remainingAmount?: number;
	readonly toDate?: Date | string;
	readonly name?: string;
	readonly limitApplicationNumber?: string;

	//	Calculated members:
	readonly depotValueRate?: number;
	readonly pd?: number;
	readonly lgd?: number;
	readonly lgdAmount?: number;
	readonly usedAmount?: number;
	readonly depotValue?: number;
	readonly usedDepotValue?: number;
	readonly normalizedLoss?: number;
	readonly mainCustomerPd?: number;

	readonly isMainCustomer?: boolean;
}

export class LimitDetailEntity extends Entity implements LimitDetail {
	readonly limitId?: ID = "";
	readonly customerId?: number = undefined;
	readonly customers?: string[] = [];
	readonly engagementCustomerId?: number = undefined;
	readonly customerName?: string = undefined;
	readonly products?: string[] = [];
	readonly objectTypes?: string[] = [];
	readonly limitAmount?: number = undefined;
	readonly minEquity?: number = undefined;
	readonly loanAmount?: number = undefined;
	readonly objectCondition?: number = undefined;
	readonly terms?: number = undefined;
	readonly vatRule?: number = undefined;
	readonly remainingAmount?: number = undefined;
	readonly toDate?: Date | string = undefined;
	readonly name?: string = "";
	readonly limitApplicationNumber?: string = "";

	//	Calculated members:
	readonly depotValueRate?: number = undefined;
	readonly pd?: number = undefined;
	readonly lgd?: number = undefined;
	readonly lgdAmount?: number = undefined;
	readonly usedAmount?: number = undefined;
	readonly depotValue?: number = undefined;
	readonly usedDepotValue?: number = undefined;
	readonly normalizedLoss?: number = undefined;
	readonly mainCustomerPd?: number = undefined;

	readonly isMainCustomer?: boolean = false;
	pk(parent?: any, key?: string) {
		return this.limitId?.toString();
	}
}

export interface LimitApplicationData {
	readonly limitApplicationDataId?: number;
	readonly applicationId?: number;
	readonly fromDate?: Date | string;
	readonly toDate?: Date | string;
	readonly transferToCore?: boolean;
	readonly committed?: boolean;
	readonly limitDetails?: LimitDetail[];
	readonly limitCustomers?: LimitCustomerType[];
	readonly decisionTableTemplates?: DecisionTableTemplate[];
	readonly pDMain?: number;
	readonly riskGroupCustomer?: string;
	readonly lGDTotal?: number;
	readonly normalizedLossTotal?: number;
	readonly comments?: Record<string, string>;
	readonly applicationLink?: string;
	readonly informationText?: string;
	readonly warningText?: string;
	readonly AutomaticPayout?: boolean;
}

export class LimitApplicationDataResource
	extends BaseCamelCasedResource
	implements LimitApplicationData
{
	readonly limitApplicationDataId?: number = 0;
	readonly applicationId?: number = undefined;
	readonly customerId?: number = undefined;
	readonly fromDate?: Date | string = undefined;
	readonly toDate?: Date | string = undefined;
	readonly transferToCore?: boolean = false;
	readonly committed?: boolean = false;
	readonly limitDetails?: LimitDetailEntity[] = [];
	readonly limitCustomers?: LimitCustomerType[] = [];
	readonly decisionTableTemplates?: DecisionTableTemplate[] = [];
	readonly pDMain?: number = undefined;
	readonly riskGroupCustomer?: string = undefined;
	readonly lGDTotal?: number = undefined;
	readonly normalizedLossTotal?: number = undefined;
	readonly comments?: Record<string, string> = {};
	readonly applicationLink?: string = undefined;
	readonly informationText?: string = undefined;
	readonly warningText?: string = undefined;

	pk(parent?: any, key?: string): string | undefined {
		return this.applicationId?.toString();
	}

	static url<T extends typeof BaseCamelCasedResource>(
		this: T,
		urlParams: Readonly<Record<string, any>> = {}
	) {
		const { applicationId, customerId, ...searchParams } = urlParams;
		const url = super.url(urlParams);
		return `${url}${paramsToString(searchParams)}`;
	}

	static schema = {
		limitDetails: [LimitDetailEntity]
	};

	static urlRoot = apiResourceUrl("application-limit");
}

export class LimitApplicationDataCustomerResource extends LimitApplicationDataResource {
	pk() {
		return this.customerId?.toString() || "";
	}

	static urlRoot = apiResourceUrl("application-limit/customer");
}
