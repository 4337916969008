import { useLayoutEffect } from "react";
import { usePageState } from "../PageContext";
import { connect } from "formik";
import { usePages } from "../pagesContext";
import { touchAllVisibleFields } from "./TouchVisibleFieldsOnPreviousPages";
import {
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible,
	useFormHasErrors
} from "../hooks";

export const RedirectToFirstError = connect(({ formik }) => {
	const pages = usePages();
	const { step, goto } = usePageState();

	const formHasErrors = useFormHasErrors();
	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();
	const { errors = {}, touched, setTouched } = formik;

	const errorHasKeys = Object.keys(errors).some(x => x);

	useLayoutEffect(() => {
		const relevantTouched = touchAllVisibleFields(
			touched,
			pages.slice(0, step),
			fieldIsVisible,
			sectionIsVisible
		);

		let errorPage = step;

		for (let i = step; i >= 0; i--) {
			if (
				formHasErrors(
					{ ...formik, touched: relevantTouched },
					pages.slice(0, i + 1),
					true
				)
			) {
				errorPage = i;
			} else {
				break;
			}
		}
		if (errorHasKeys && errorPage !== step) {
			setTouched(
				touchAllVisibleFields(
					touched,
					pages.slice(0, errorPage + 1),
					fieldIsVisible,
					sectionIsVisible
				)
			);

			goto(errorPage, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorHasKeys, step]);

	// const previousPages = useMemo(() => pages.slice(0, step), [pages, step]);

	// useEffect(
	// 	() => {
	// 		if (errorHasKeys)
	// 			setTouched(
	// 				touchAllVisibleFields(touched, previousPages, fieldIsVisible)
	// 			);
	// 	},
	// 	[errorHasKeys, fieldIsVisible, previousPages, setTouched, step, touched]
	// );

	return null;
});
