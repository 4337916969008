import React, { Suspense, useCallback, useMemo } from "react";
import { SectionProps } from "../../Section";
import { Grid, LinearProgress, TextField } from "@material-ui/core";
import { addRegisteredSectionLayout } from "../../sectionLayoutDescriptions";
import { useResource } from "@rest-hooks/core";
import {
	ConditionParamCategoryOptionListResource,
	ConditionParamCategoryResource,
	DynamicFieldCategoryOptionListResource,
	DynamicFieldCategoryResource
} from "@ploy-lib/rest-resources";
import {
	DecisionTableTemplate,
	DecisionTableTemplateRow,
	DecisionTableTemplateValue,
	TemplateTableColumn
} from "@ploy-lib/types";
import { v4 as uuid } from "uuid";

import {
	escapeFormikName,
	filterAndStripEntriesStartingWith,
	unescapeFormikName
} from "./utils";
import { CalcRulesDataProvider } from "./TableDataProvider";
import { secondValueIsNotNull } from "../../../utils";
import { FormTable } from "./FormTable";
import { FieldType, TableColumnMetadata } from "./tableSchema";

addRegisteredSectionLayout({
	name: "DecisionTableSection",
	displayName: "DecisionTableSection",
	settings: {
		editableOptions: {
			decisionTableType: true,
			fullWidth: true
		}
	}
});

const emptyArray = [];

type RowSimple = Record<string, string | string[] | undefined>;

const NumericOrFormat = "numericor";
const DateFormat = "date";

const emptyDecisionTable = (): DecisionTableTemplate => ({
	decisionTableTemplateId: uuid(),
	type: "",
	rows: []
});

function sortDecisionTableColumns(
	columns: TemplateTableColumn[],
	mappedColumns: Record<
		string,
		Pick<ConditionParamCategoryResource, "sortOrder">
	>
): TemplateTableColumn[] {
	return [...columns].sort(
		(a, b) =>
			(mappedColumns[a.name]?.sortOrder ?? 0) -
			(mappedColumns[b.name]?.sortOrder ?? 0)
	);
}

function makeDecisionTableTemplateValue(
	name: string,
	value: string
): DecisionTableTemplateValue {
	return {
		decisionTableTemplateValueId: uuid(),
		category: name,
		value
	};
}

//
// Functions for converting raw values between what we save vs what the table operate on (eg. conversion of string to list)
//

const conditionText = "condition";
const dynamicFieldText = "dynamicField";

const convertStorageValueToRowCell = (
	value: string,
	category: ConditionParamCategoryResource | DynamicFieldCategoryResource
): string | string[] => {
	if (category?.formatOptions?.toLowerCase() === NumericOrFormat) {
		return value
			.split("|")
			.filter(v => v)
			.map(v => v.substring(1, v.length - 1));
	}

	return value;
};

function convertRowCellValueToStorageValue(
	name: string,
	value: string | string[],
	category: ConditionParamCategoryResource | DynamicFieldCategoryResource
): DecisionTableTemplateValue {
	let stringValue = !Array.isArray(value) ? value : "";

	if (
		Array.isArray(value) &&
		category?.formatOptions?.toLowerCase() === NumericOrFormat
	) {
		stringValue = value
			.filter(v => v)
			.map(v => `^${v}$`)
			.join("|");
	}

	return makeDecisionTableTemplateValue(name, stringValue);
}

//
// Conversion between lists of DecisionTableTemplateValue and row cells according
// condition categories or dynamic field categaries
//

function convertRowCellsAccordingToCategory(
	prefix: string,
	rowData: RowSimple,
	categories:
		| Record<string, ConditionParamCategoryResource>
		| Record<string, DynamicFieldCategoryResource>
) {
	return filterAndStripEntriesStartingWith(
		`${prefix}:`,
		Object.entries(rowData)
	)
		.filter(secondValueIsNotNull)
		.map(([name, value]) => [unescapeFormikName(name), value] as const)
		.map(([name, value]) =>
			convertRowCellValueToStorageValue(name, value, categories[name])
		);
}

function makeRowCellsAccordingToCategory(
	prefix: string,
	values: DecisionTableTemplateValue[],
	categories:
		| Record<string, ConditionParamCategoryResource>
		| Record<string, DynamicFieldCategoryResource>
) {
	return values.map(
		c =>
			[
				`${prefix}:${escapeFormikName(c.category)}`,
				convertStorageValueToRowCell(c.value, categories[c.category])
			] as const
	);
}

//
// Factories for constructing functions that converts a DecisionTableTemplateRow into row cells and back again
//

const convertRowDataToStorageDataFactory =
	(
		conditionCategories: Record<string, ConditionParamCategoryResource>,
		dynamicFieldCategories: Record<string, DynamicFieldCategoryResource>
	) =>
	(rowData: RowSimple): DecisionTableTemplateRow => ({
		decisionTableTemplateRowId: uuid(),
		conditions: convertRowCellsAccordingToCategory(
			conditionText,
			rowData,
			conditionCategories
		),
		dynamicFields: convertRowCellsAccordingToCategory(
			dynamicFieldText,
			rowData,
			dynamicFieldCategories
		)
	});

const convertStorageDataToRowDataFactory =
	(
		conditionCategories: Record<string, ConditionParamCategoryResource>,
		dynamicFieldCategories: Record<string, DynamicFieldCategoryResource>
	) =>
	(decisionTemplateRow: Partial<DecisionTableTemplateRow>): RowSimple =>
		Object.fromEntries([
			...makeRowCellsAccordingToCategory(
				conditionText,
				decisionTemplateRow.conditions ?? [],
				conditionCategories
			),
			...makeRowCellsAccordingToCategory(
				dynamicFieldText,
				decisionTemplateRow.dynamicFields ?? [],
				dynamicFieldCategories
			)
		]);

//

function filterAndSortTemplateColumns(
	prefix: string,
	tableColumns: TemplateTableColumn[],
	mappedColumns:
		| Record<string, ConditionParamCategoryResource>
		| Record<string, DynamicFieldCategoryResource>
) {
	let columns = tableColumns.filter(tc => tc.tableType === prefix);
	columns = sortDecisionTableColumns(columns, mappedColumns);

	return columns;
}

const DecisionTableSectionInternal = (props: SectionProps) => {
	const {
		decisionTableType,
		tableColumns = emptyArray,
		allowRuntimeColumnAdditions,
		literal
	} = props;

	//
	// Retrieve necessary data (column definitions and option value lists) for displaying the decision table correctly
	//

	const tableTypeParams = decisionTableType
		? {
				tableType: decisionTableType
		  }
		: null;

	const [
		availableConditionColumns = emptyArray,
		availableDynamicFieldColumns = emptyArray
	] = useResource(
		[ConditionParamCategoryResource.list(), tableTypeParams],
		[DynamicFieldCategoryResource.list(), tableTypeParams]
	);

	const [mappedConditionColumns, idMappedConditionColumns] = useMemo(
		() => [
			Object.fromEntries(availableConditionColumns.map(c => [c.name, c])),
			Object.fromEntries(
				availableConditionColumns.map(c => [c.conditionParamCategoryId, c])
			)
		],
		[availableConditionColumns]
	);

	const [mappedDynamicFieldColumns, idMappedDynamicFieldColumns] = useMemo(
		() => [
			Object.fromEntries(availableDynamicFieldColumns.map(c => [c.name, c])),
			Object.fromEntries(
				availableDynamicFieldColumns.map(c => [c.dynamicFieldCategoryId, c])
			)
		],
		[availableDynamicFieldColumns]
	);

	const conditionColumns = useMemo(
		() =>
			filterAndSortTemplateColumns(
				conditionText,
				tableColumns,
				mappedConditionColumns
			),
		[mappedConditionColumns, tableColumns]
	);

	const dynamicFieldColumns = useMemo(
		() =>
			filterAndSortTemplateColumns(
				dynamicFieldText,
				tableColumns,
				mappedDynamicFieldColumns
			),
		[mappedDynamicFieldColumns, tableColumns]
	);

	const conditionOptionColumns = conditionColumns
		.filter(
			c => c.optionSource == null && mappedConditionColumns[c.name].optionSource
		)
		.map(c => mappedConditionColumns[c.name].conditionParamCategoryId);
	const dynamicFieldOptionColumns = dynamicFieldColumns
		.filter(
			c =>
				c.optionSource == null && mappedDynamicFieldColumns[c.name].optionSource
		)
		.map(c => mappedDynamicFieldColumns[c.name].dynamicFieldCategoryId);

	const [
		conditionOptionValues = emptyArray,
		dynamicFieldOptionValues = emptyArray
	] = useResource(
		[
			ConditionParamCategoryOptionListResource.list(),
			conditionOptionColumns.length > 0
				? {
						ids: conditionOptionColumns
				  }
				: null
		],
		[
			DynamicFieldCategoryOptionListResource.list(),
			dynamicFieldOptionColumns.length > 0
				? {
						ids: dynamicFieldOptionColumns
				  }
				: null
		]
	);

	const mappedConditionOptionValues = useMemo(() => {
		return {
			...Object.fromEntries(
				conditionOptionValues.map(c => [
					idMappedConditionColumns[c.id]?.name ?? "",
					c.values
				])
			)
		} as const;
	}, [conditionOptionValues, idMappedConditionColumns]);

	const mappedDynamicFieldOptionValues = useMemo(
		() => ({
			...Object.fromEntries(
				dynamicFieldOptionValues.map(c => [
					idMappedDynamicFieldColumns[c.id]?.name ?? "",
					c.values
				])
			)
		}),
		[dynamicFieldOptionValues, idMappedDynamicFieldColumns]
	);

	//
	// Set up value converters
	//

	const convertRawStorageToStorageDataRows = useCallback(
		(
			decisionTableTemplates:
				| Readonly<DecisionTableTemplate[]>
				| null
				| undefined
		): Readonly<DecisionTableTemplateRow[]> => {
			if (!Array.isArray(decisionTableTemplates)) return [];

			return (
				decisionTableTemplates.find(dtt => dtt.type === decisionTableType)
					?.rows ?? []
			);
		},
		[decisionTableType]
	);

	const convertStorageDataToRowCells = useMemo(
		() =>
			convertStorageDataToRowDataFactory(
				mappedConditionColumns,
				mappedDynamicFieldColumns
			),
		[mappedConditionColumns, mappedDynamicFieldColumns]
	);

	const convertRowCellsToStorageData = useMemo(
		() =>
			convertRowDataToStorageDataFactory(
				mappedConditionColumns,
				mappedDynamicFieldColumns
			),
		[mappedConditionColumns, mappedDynamicFieldColumns]
	);

	const convertStorageDataRowsToRawStorage = useCallback(
		(
			storageRows: DecisionTableTemplateRow[],
			currentRawStorage: Readonly<DecisionTableTemplate[]> | undefined | null
		): Readonly<DecisionTableTemplate[]> => {
			const tables = Array.isArray(currentRawStorage)
				? (currentRawStorage as DecisionTableTemplate[])
				: [];
			const existingTable = tables.find(dtt => dtt.type === decisionTableType);

			const updatedTable = {
				...emptyDecisionTable(),
				...existingTable,
				type: decisionTableType ?? "",
				rows: storageRows
			};

			// Replace or add the updated table
			return existingTable
				? tables.map(dtt =>
						dtt.type === decisionTableType ? updatedTable : dtt
				  )
				: [...tables, updatedTable];
		},
		[decisionTableType]
	);

	//
	// Set up column definitions
	//

	const [columns, extraMetadata] = useMemo((): [
		TemplateTableColumn[],
		Partial<Record<string, TableColumnMetadata>>
	] => {

		// Captures some state (template columns and decision table defitinition) and returns a function
		// that will patch a template table column with relevant name, description, option values and so forth.
		const columnPatcherMaker =
			(
				prefix: string,
				dtColumns:
					| typeof mappedConditionColumns
					| typeof mappedDynamicFieldColumns,
				dtColumnOptionValues:
					| typeof mappedConditionOptionValues
					| typeof mappedDynamicFieldOptionValues
			) =>
			(
				column: Readonly<TemplateTableColumn>
			): {
				patchedColumn: TemplateTableColumn;
				columnMetadata: TableColumnMetadata;
			} => {
				const dtColumn = dtColumns[column.name];

				const patchedColumn: TemplateTableColumn = { ...column };

				patchedColumn.name = `${prefix}:${escapeFormikName(column.name)}`;
				patchedColumn.label =
					column.label ?? dtColumn.description ?? column.name;
				patchedColumn.optionValues = patchedColumn.optionValues
					? patchedColumn.optionValues
					: patchedColumn.optionSource
					? undefined
					: dtColumnOptionValues[column.name];
				patchedColumn.isMultipleSelect =
					dtColumn?.formatOptions?.toLowerCase() === NumericOrFormat;
				patchedColumn.editable = !literal;

				const columnMetadata: TableColumnMetadata = {
					fieldType: patchedColumn.isMultipleSelect
						? FieldType.Multiselect
						: patchedColumn.optionSource != null ||
						  patchedColumn.optionValues != null
						? FieldType.Select
						: dtColumn.formatOptions?.toLowerCase() === DateFormat
						? FieldType.Date
						: dtColumn.isNumeric
						? FieldType.Number
						: FieldType.Text
				};

				return { patchedColumn, columnMetadata };
			};

		const mappedTableData = {
			conditions: conditionColumns.map(
				columnPatcherMaker(
					conditionText,
					mappedConditionColumns,
					mappedConditionOptionValues
				)
			),
			dynamicFields: dynamicFieldColumns.map(
				columnPatcherMaker(
					dynamicFieldText,
					mappedDynamicFieldColumns,
					mappedDynamicFieldOptionValues
				)
			)
		};

		// Actual table columns used in FormTable
		const tableColumns = [
			...mappedTableData.conditions.map(
				cond => cond.patchedColumn
			),
			...(mappedTableData.conditions.length > 0 && mappedTableData.dynamicFields.length > 0
				? [
						{
							name: "divider",
							formTemplateTableColumnId: "",
							tableType: decisionTableType || ""
						}
				  ]
				: []),
			...mappedTableData.dynamicFields.map(
				dynField => dynField.patchedColumn
			)
		];

		// Setup custom metadata, mostly to create relevant display type
		const metadata: Partial<Record<string, TableColumnMetadata>> =
			Object.fromEntries(
				[
					...mappedTableData.conditions.map(
						columnData =>
							[
								columnData.patchedColumn.name,
								columnData.columnMetadata
							] as const
					),
					...mappedTableData.dynamicFields.map(
						columnData =>
							[
								columnData.patchedColumn.name,
								columnData.columnMetadata
							] as const
					)
				]
			);

		return [tableColumns, metadata];
	}, [
		conditionColumns,
		decisionTableType,
		dynamicFieldColumns,
		literal,
		mappedConditionColumns,
		mappedConditionOptionValues,
		mappedDynamicFieldColumns,
		mappedDynamicFieldOptionValues
	]);

	//
	// We are ready to render
	//

	if (!decisionTableType) return null;

	return (
		<div className={props.className} onClick={props.onClick}>
			{allowRuntimeColumnAdditions && (
				<Grid container>
					<Grid item xs={6}>
						<TextField variant="standard" />
					</Grid>
					<Grid item xs={6}>
						<TextField variant="standard" />
					</Grid>
				</Grid>
			)}
			<CalcRulesDataProvider<
				RowSimple,
				DecisionTableTemplateRow,
				DecisionTableTemplate[]
			>
				tableType="DecisionTableTemplates"
				namespace="Calculator"
				name="Calculator.DecisionTableTemplates"
				tableColumns={columns}
				convertRawStorageToStorageDataRows={convertRawStorageToStorageDataRows}
				convertStorageDataToRowCells={convertStorageDataToRowCells}
				convertRowCellsToStorageData={convertRowCellsToStorageData}
				convertStorageDataRowsToRawStorage={convertStorageDataRowsToRawStorage}
			>
				{providerProps => (
					<FormTable<RowSimple>
						literal={literal}
						{...props}
						{...providerProps}
						tableSchema={"DTTableSchema"}
						extendedMetadata={extraMetadata}
					/>
				)}
			</CalcRulesDataProvider>
		</div>
	);
};

const DecisionTableSection = (props: SectionProps) => {
	return (
		<Suspense fallback={<LinearProgress style={{ marginBottom: 20 }} />}>
			<DecisionTableSectionInternal {...props} />
		</Suspense>
	);
};
DecisionTableSection.displayName = "DployDecisionTableSection";

export { DecisionTableSection };
