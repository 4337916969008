import News from "./News";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage } from "react-intl";

applyPropertyControls(News, {
	showInfoMessage: {
		type: ControlType.Boolean,
		title: (
			<FormattedMessage
				id="dploy.grid.news.showInfoMessage.label"
				defaultMessage="Show information message"
			/>
		),
		defaultValue: true
	},
	showTasks: {
		type: ControlType.Boolean,
		title: (
			<FormattedMessage
				id="dploy.grid.news.showTasks.label"
				defaultMessage="Show todo items"
			/>
		),
		defaultValue: true // DNB compatibility (TODO: default false)
	},
	boxProps: contentBoxPropertyControls
});

export default News;
