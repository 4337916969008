import React from "react";
import camelcaseKeys from "camelcase-keys";
import { apiResourceUrl } from "@ploy-lib/core";

export interface GetFulfillmentRequest {
	registrationNumber: string;
	phoneNumber: string;
	fulfillmentDate?: Date;
}

export interface GetFulfillmentResponse {
	accountNumber: string;
	customerIdentification: string;
	registrationNumber: string;
	carMake: string;
	carModel: string;
	fulfillmentInformation: FulfillmentInformation[];
	invalidContractType: boolean;
}

export interface FulfillmentInformation {
	amount: number;
	fulfillmentDate: Date;
}

export interface API {
	internalUser?: boolean;
	getFulfillment: (
		params: GetFulfillmentRequest
	) => Promise<GetFulfillmentResponse>;
}

export interface FulfillmentAPIProps {
	children(api: API): React.ReactElement<any>;
	token: string;
	endpoint: string;
}

export class FulfillmentAPI extends React.Component<FulfillmentAPIProps> {
	callAPI = async (method: string, action: string, params: object) => {
		const { token } = this.props;
		const noCacheToken = new Date().getTime();
		const response = await fetch(
			apiResourceUrl(
				`fulfillment/${action}?${getQueryParams(params)}&t=${noCacheToken}`
			),
			{
				method,
				headers: token ? new Headers({ Authorization: token }) : undefined,
				credentials: "include" // Include session cookie
			}
		);
		const data = await response.json();
		return camelcaseKeys(data) as any;
	};

	handleGetFulfillment = async (
		searchParams: GetFulfillmentRequest
	): Promise<GetFulfillmentResponse> => {
		try {
			const response = await this.callAPI("GET", "fulfillment", {
				...searchParams
			});
			return response;
		} catch (err: any) {
			throw Error("Error getting fulfillment. " + err.message);
		}
	};

	render() {
		const { children } = this.props;
		return children({
			getFulfillment: this.handleGetFulfillment
		});
	}
}

export const getQueryParams = (params: Record<string, any>) => {
	return Object.entries(params)
		.map(
			([k, v]) =>
				encodeURIComponent(k) +
				"=" +
				encodeURIComponent(v instanceof Date ? v.toISOString() : v)
		)
		.join("&");
};

export default FulfillmentAPI;
