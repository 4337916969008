import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	useTheme,
	useMediaQuery,
	Box,
	Breadcrumbs,
	Link,
	Typography,
	IconButton
} from "@material-ui/core";
import { ControlFields } from "./property-controls/ControlFields";
import {
	Formik,
	Form,
	useFormikContext,
	FormikConfig,
	Field,
	FieldProps
} from "formik";
import { isGridComponent } from "./content/types";
// import { useDebounce } from "use-debounce";

import * as ContentComponentsWithControls from "./content/with-property-controls";
import {
	ControlType,
	isControlComponent,
	PropertyControls
} from "./property-controls";
import { isNotNull } from "@ploy-lib/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { makeStyles } from "@material-ui/core/styles";
import { FormikSubmitButton } from "./property-controls/fields/FormikSubmitButton";
import { GridItemWrapper } from "./GridItemWrapper";
import { GridItem } from ".";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { defineMessage } from "@formatjs/intl";

export type GridComponentWithControl =
	keyof typeof ContentComponentsWithControls;

interface GridItemEditorProps<P> {
	component: GridComponentWithControl;
	initialValues: Partial<P>;
	onSubmit: FormikConfig<Partial<P>>["onSubmit"];
	open: boolean;
	onClose?: () => void;
}

const useStyles = makeStyles(theme => ({
	dialogPaper: {
		overflow: "hidden"
	},
	dialogContent: {
		[theme.breakpoints.up("md")]: {
			overflow: "hidden"
		}
	}
}));

export function GridItemEditor<P = {}>(props: GridItemEditorProps<P>) {
	const classes = useStyles(props);
	const { component, initialValues, onSubmit, open, onClose } = props;

	const intl = useIntl();

	const Component = ContentComponentsWithControls[component];

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const [selected, setSelected] = useState<string>();

	if (!isControlComponent(Component)) return null;

	let controls: PropertyControls<any> | null = Component.propertyControls;
	let arrayControl: typeof controls[any] | null = null;
	const selectedControls = selected
		?.split(".")
		.map((p, i, arr) => {
			let title: React.ReactNode = arrayControl ? `[${p}]` : undefined;
			const control = arrayControl ?? controls?.[p];

			controls = null;
			arrayControl = null;

			const fieldName = arr.slice(0, i + 1).join(".");

			if (control?.type === ControlType.Object) {
				controls = control.propertyControls;
				title = title ?? control.title;
			} else if (
				control?.type === ControlType.Array &&
				control.propertyControl.type !== ControlType.Enum
			) {
				arrayControl = control.propertyControl;
				title = title ?? control.title;
			}
			if (!control) return null;

			return { title, fieldName };
		})
		.filter(isNotNull);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			maxWidth="md"
			fullWidth
			fullScreen={fullScreen}
			classes={{
				paper: classes.dialogPaper
			}}
		>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validateOnBlur={false}
				validateOnChange={false}
				validateOnMount={false}
			>
				<>
					<Box clone display="flex" alignItems="center">
						<DialogTitle disableTypography>
							<IconButton
								onClick={() =>
									setSelected(
										selectedControls?.[selectedControls.length - 2]?.fieldName
									)
								}
								disabled={!selectedControls}
								title={intl.formatMessage(
									defineMessage({
										id: "dploy.grid.item-edit.breadcrumbs.back-button.label",
										defaultMessage: "Back"
									})
								)}
							>
								<ChevronLeftIcon />
							</IconButton>
							<Breadcrumbs>
								{!selected ? (
									<Typography variant="h6" id="form-dialog-title">
										<Field name="boxProps.header">
											{(props: FieldProps) =>
												props.field.value ??
												intl.formatMessage(componentNamesMessages[component])
											}
										</Field>
									</Typography>
								) : (
									<Link
										id="form-dialog-title"
										component="button"
										type="button"
										variant="h6"
										color="inherit"
										onClick={() => setSelected(undefined)}
										disabled={!selected}
										underline="hover"
									>
										<Field name="boxProps.header">
											{(props: FieldProps) =>
												props.field.value ??
												intl.formatMessage(componentNamesMessages[component])
											}
										</Field>
									</Link>
								)}

								{selectedControls?.map(({ title, fieldName }) =>
									selected === fieldName ? (
										<Typography key={fieldName} variant="h6">
											{title}
										</Typography>
									) : (
										<Link
											component="button"
											type="button"
											variant="h6"
											color="inherit"
											key={fieldName}
											onClick={() => setSelected(fieldName)}
											underline="hover"
										>
											{title}
										</Link>
									)
								)}
							</Breadcrumbs>
						</DialogTitle>
					</Box>
					<DialogContent className={classes.dialogContent}>
						<Form>
							<Grid container spacing={2}>
								<Box
									clone
									overflow={[undefined, undefined, "auto"]}
									maxHeight={[undefined, undefined, "calc(100vh - 200px)"]}
								>
									<Grid item xs={12} md={6}>
										<ControlFields
											propertyControls={Component.propertyControls}
											margin="dense"
											onSelect={setSelected}
											selected={selected}
										/>
									</Grid>
								</Box>
								<Box
									clone
									overflow="auto"
									maxHeight={[undefined, undefined, "calc(100vh - 200px)"]}
								>
									<Grid item xs={12} md={6}>
										<Preview
											component={component}
											minHeight={
												isGridComponent(Component)
													? Component.minHeight
													: undefined
											}
										/>
									</Grid>
								</Box>
							</Grid>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setSelected(undefined);
								onClose?.();
							}}
						>
							<FormattedMessage
								id="dploy.grid.item-edit.cancel-button.label"
								defaultMessage="Cancel"
							/>
						</Button>
						<FormikSubmitButton variant="outlined" color="primary">
							<FormattedMessage
								id="dploy.grid.item-edit.save-button.label"
								defaultMessage="Save"
							/>
						</FormikSubmitButton>
					</DialogActions>
				</>
			</Formik>
		</Dialog>
	);
}

function Preview(props: {
	component: Required<GridItem>["component"];
	minHeight?: number;
}) {
	const { component, minHeight = 250 } = props;

	const form = useFormikContext();

	// const [previewProps] = useDebounce(form.values, 1000);
	const previewProps = form.values as Record<string, any>;

	return (
		<Box minHeight={minHeight} clone>
			<GridItemWrapper
				id="editing-item"
				component={component}
				properties={previewProps}
			/>
		</Box>
	);
}

const componentNamesMessages = defineMessages({
	Charts: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.Charts",
		defaultMessage: "Charts",
		description: "Charts component container for dashboard"
	},
	Competitions: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.Competitions",
		defaultMessage: "Competitions",
		description: "Competitions component container for dashboard"
	},
	GeneralInformation: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.GeneralInformation",
		defaultMessage: "GeneralInformation",
		description: "GeneralInformation component container for dashboard"
	},
	Links: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.Links",
		defaultMessage: "Links",
		description: "Links component container for dashboard"
	},
	News: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.News",
		defaultMessage: "News",
		description: "News component container for dashboard"
	},
	RecentApplications: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.RecentApplications",
		defaultMessage: "RecentApplications",
		description: "RecentApplications component container for dashboard"
	},
	Text: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.Text",
		defaultMessage: "Text",
		description: "Text component container for dashboard"
	},
	TextList: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.TextList",
		defaultMessage: "TextList",
		description: "TextList component container for dashboard"
	},
	Todos: {
		id: "dploy.grid.item-edit.breadcrumbs.form-dialog-title.Todos",
		defaultMessage: "Todos",
		description: "Todos component container for dashboard"
	}
});
