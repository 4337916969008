import React, { ReactNode } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3)
		}
	})
);

export interface ModalContainerProps {
	open: boolean;
	handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
	children?: ReactNode | ReactNode[];
}

export const ModalContainer = (props: ModalContainerProps) => {
	const { open, handleClose, children } = props;

	const classes = useStyles();

	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>{children}</div>
			</Fade>
		</Modal>
	);
};
