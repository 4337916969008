import { useMemo } from "react";
import { useCalculation } from "../context";
import { getInNamespaced, isVariableField } from "../../calculator/utils";
import { useFieldData, FieldData, useFieldsData, Field } from "./useFieldData";
import {
	useVariableData,
	VariableData,
	DefaultData,
	useVariablesData
} from "./useVariableData";
import { CalculatorState } from "../../calculator";
import { fieldDef } from "./fieldDef";

export type FieldCalculation<TData> = Partial<VariableData<TData>> & FieldData;

export function getFieldVariableName(
	{
		fieldControlMaps,
		controlFieldMaps
	}: Pick<
		Partial<CalculatorState<string, any>>,
		"fieldControlMaps" | "controlFieldMaps"
	>,
	namespace?: string,
	name?: string
) {
	const controlRef = getInNamespaced(fieldControlMaps, namespace, name);

	const resolveInfo = getInNamespaced(controlFieldMaps, namespace, controlRef);

	return isVariableField(resolveInfo) ? resolveInfo.variable : undefined;
}

export function useCalculationField<
	TData = DefaultData,
	TDef extends Field = Field
>(field?: TDef): FieldCalculation<TData>;
export function useCalculationField<TData = DefaultData>(
	namespace: Field["namespace"],
	fieldName: Field["name"]
): FieldCalculation<TData>;
export function useCalculationField<
	TData = DefaultData,
	TDef extends Field = Field
>(
	namespaceOrField?: Field["namespace"] | TDef,
	fieldName?: Field["name"]
): Partial<FieldCalculation<TData>> {
	const { namespace, name } = fieldDef(namespaceOrField, fieldName) ?? {};

	const { fieldControlMaps, controlFieldMaps } = useCalculation(
		"fieldControlMaps",
		"controlFieldMaps"
	);

	const variableName = getFieldVariableName(
		{ fieldControlMaps, controlFieldMaps },
		namespace,
		name
	);

	const fieldData = useFieldData(
		name != null ? { namespace, name } : undefined
	);
	const variableData = useVariableData<TData>(namespace, variableName || "");

	const fieldCalculationState = useMemo(
		() => ({ ...fieldData, ...variableData }),
		[fieldData, variableData]
	);

	return fieldCalculationState;
}

export function useCalculationFields<
	TData = DefaultData,
	TDef extends Field = Field
>(...fields: TDef[]): (FieldCalculation<TData> | undefined)[] {
	const { fieldControlMaps, controlFieldMaps } = useCalculation(
		"fieldControlMaps",
		"controlFieldMaps"
	);

	const variableFields = fields.map(({ namespace, name }) => {
		const variableName = getFieldVariableName(
			{ fieldControlMaps, controlFieldMaps },
			namespace,
			name
		);

		return { namespace, variableName: variableName || "", name };
	});

	const fieldsData = useFieldsData(...variableFields);
	const variablesData = useVariablesData<TData>(...variableFields);

	const data = fieldsData.map((fieldData, i) => {
		const variableData = variablesData[i];
		return fieldData
			? {
					...fieldData,
					...variableData
			  }
			: undefined;
	});

	return data;
}

export function useWriteLockedFields<
	TData = DefaultData,
	TDef extends Field = Field
>(
	...fields: TDef[]
): (
	| Pick<FieldCalculation<TData>, "writeLocked" | "variableName" | "namespace">
	| undefined
)[] {
	const { fieldControlMaps, controlFieldMaps, isWriteLocked, isEnabled } =
		useCalculation(
			"fieldControlMaps",
			"controlFieldMaps",
			"isWriteLocked",
			"isEnabled"
		);

	const variableFields = fields
		.filter(
			({ namespace, name }) =>
				getInNamespaced(isEnabled, namespace, name) !== false
		)
		.map(({ namespace, name }) => {
			const variableName = getFieldVariableName(
				{ fieldControlMaps, controlFieldMaps },
				namespace,
				name
			);

			return { namespace, variableName: variableName || "", name };
		});

	const data = variableFields.map((variableData, i) => ({
		...variableData,
		writeLocked: getInNamespaced(
			isWriteLocked,
			variableData.namespace,
			variableData.variableName
		)
	}));

	return data;
}
