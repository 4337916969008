import React from "react";
import { MarkdownElement } from "@ploy-ui/core";
import ContentBox from "../../ContentBox";
import { DashboardContentProps } from "../../types";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const placeholderText = (
	<>
		<Skeleton animation={false} variant="text" width="50%" height="30px" />
		<Skeleton animation={false} variant="text" width="100%" />
		<Skeleton animation={false} variant="text" width="100%" />
		<Skeleton animation={false} variant="text" width="100%" />
		<Skeleton animation={false} variant="text" width="100%" />
		<Skeleton animation={false} variant="text" width="70%" />
	</>
);

export interface MarkdownProps extends DashboardContentProps {
	text?: string;
}

const Text = (props: MarkdownProps) => {
	let { boxProps, text, placeholder } = props;

	return (
		<ContentBox {...boxProps}>
			<Box overflow="auto">
				{!placeholder ? <MarkdownElement text={text} /> : placeholderText}
			</Box>
		</ContentBox>
	);
};

Text.displayName = "DployDashboardText";

export default Text;
