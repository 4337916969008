import { defineMessages } from "react-intl";

export const messages = defineMessages({
	genericError: {
		id: "km_reading.error.generic",
		defaultMessage: "An error occured."
	},
	sendMessageError: {
		id: "km_reading.error.send_message",
		defaultMessage: "Error sending message"
	},
	genericSuccess: {
		id: "km_reading.register.success",
		defaultMessage: "Avlesning er sent inn"
	},
	registerError: {
		id: "km_reading.error.register",
		defaultMessage:
			"Det oppstod en feil ved registrering av kilometer. Prøv igjen om litt eller ta kontakt med support om problemet vedvarer."
	}
});
