import React, { memo } from "react";
import { usePageState } from "../PageContext";
import { TemplatePageProps } from "../types";
import * as layouts from "./layouts";
import * as steppers from "./steppers";
import { TemplatePage } from "@ploy-lib/types";
import { BaseStepperProps } from "./steppers/BaseStepperProps";

export const FormPages = memo<{
	pages: TemplatePage[];
	header: React.ReactNode;
	modalClick?: number;
	modalCloseMode?: number;
	stepper?: string;
}>(({ pages, header, modalClick, modalCloseMode, stepper }) => {
	const { step } = usePageState();

	const { layout, ...page } = pages[step];

	const PageComponent: React.ComponentType<TemplatePageProps<typeof page>> =
		layout && layouts[layout] ? layouts[layout] : layouts.Wizard;

	const StepperComponent: React.ComponentType<BaseStepperProps> =
		steppers && stepper && steppers[stepper];

	return (
		<>
			{StepperComponent && <StepperComponent />}
			<PageComponent
				header={header}
				{...page}
				modalClick={modalClick}
				modalCloseMode={modalCloseMode}
				stepper={stepper}
			/>
		</>
	);
});
