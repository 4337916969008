import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { DployTextField } from "@ploy-ui/form-fields";
import { DployTable, DployColumn } from "../DployTable";
import { FormattedMessage } from "react-intl";
import { BaseCamelCasedResource } from "@ploy-lib/rest-resources";
import { useDebounce } from "use-debounce";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useStatefulResource } from "@rest-hooks/legacy";

export interface BisnodeCompany {
	customerCompanyNumber: string;
	customerName: string;
	address: string;
	zipCode: string | number;
	postal: string;
}

class BisnodeCompanyResource
	extends BaseCamelCasedResource
	implements BisnodeCompany
{
	readonly customerCompanyNumber: string;
	readonly customerName: string;
	readonly address: string;
	readonly zipCode: string | number;
	readonly postal: string;

	pk() {
		return this.customerCompanyNumber;
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		const response = await super.fetch(input, init);
		if (response.error) return [];
		return response as any;
	}

	static urlRoot = legacyApiResourceUrl("CustomerSearchJson/CompanyNameSearch");
}

export interface BisnodeSearchProps {
	initialSearchValue?: string;
	onSelect?: (companyNumber: string) => void;
}

const columns: DployColumn<BisnodeCompany>[] = [
	{
		title: (
			<FormattedMessage
				id="ploy-ui.core.bisnodeSearch.orgNumber.text"
				description="Organization number (bisnode modal)"
				defaultMessage="Organization number"
			/>
		),
		field: "customerCompanyNumber"
	},
	{
		title: (
			<FormattedMessage
				id="ploy-ui.core.bisnodeSearch.orgName.text"
				description="Organization name (bisnode modal)"
				defaultMessage="Name"
			/>
		),
		field: "customerName"
	},
	{
		title: (
			<FormattedMessage
				id="ploy-ui.core.bisnodeSearch.orgAddress.text"
				description="Organization address (bisnode modal)"
				defaultMessage="Address"
			/>
		),
		field: "address"
	},
	{
		title: (
			<FormattedMessage
				id="ploy-ui.core.bisnodeSearch.orgZipcode.text"
				description="Organization zip code (bisnode modal)"
				defaultMessage="ZIP code"
			/>
		),
		field: "zipCode"
	},
	{
		title: (
			<FormattedMessage
				id="ploy-ui.core.bisnodeSearch.orgPostal.text"
				description="Organization postal area (bisnode modal)"
				defaultMessage="City"
			/>
		),
		field: "postal"
	}
];

export function BisnodeSearch(props: BisnodeSearchProps) {
	const { initialSearchValue = "", onSelect } = props;

	const [searchValue, setSearchValue] = useState(initialSearchValue);

	const [searchString] = useDebounce(searchValue, 350);

	const { data: companies = [], loading } = useStatefulResource(
		BisnodeCompanyResource.list(),
		searchString && searchString.length > 3
			? {
					searchString
			  }
			: null
	);

	return (
		<div>
			<DployTextField
				autoFocus
				variant="outlined"
				label={
					<FormattedMessage
						id="ploy-ui.core.bisnodeSearch.searchButton.text"
						description="Text value of the search button (bisnode modal)"
						defaultMessage="Search"
					/>
				}
				value={searchValue}
				onChange={e => setSearchValue(e.target.value)}
				margin="normal"
			/>
			<Box position="relative">
				<DployTable
					data={companies}
					isLoading={loading}
					columns={columns}
					options={{
						search: false,
						header: true,
						toolbar: false,
						pageSize: 5,
						pageSizeOptions: [],
						idSynonym: "customerCompanyNumber"
					}}
					onRowClick={(e, rowData) => {
						if (rowData && onSelect) onSelect(rowData.customerCompanyNumber);
					}}
				/>
			</Box>
		</div>
	);
}
