import React, { useState } from "react";
import {
	DividerList,
	MarkdownElement,
	useFormatRuntimeMessage
} from "@ploy-ui/core";
import ContentBox from "../../ContentBox";
import { DashboardContentProps } from "../../types";
import { Box, Icon } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ID } from "@ploy-lib/types";

import DetailsBox from "../../DetailsBox";
import { BudgetResponsibleResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { ContentVisibility, TextItem, TextListItem } from "./TextListItem";
import Skeleton from "@material-ui/lab/Skeleton";

export interface TextListProps extends DashboardContentProps {
	items?: TextItem[];
}

const TextList = (props: TextListProps) => {
	let { items = [], boxProps, placeholder } = props;

	const [selected, setSelected] = useState<ID | TextItem>();
	const selectedItem = items.find(
		item => selected === item || selected === item.id
	);

	const format = useFormatRuntimeMessage();

	const budgetResponsible = useResource(
		BudgetResponsibleResource.detail(),
		!placeholder ? {} : null
	);

	const formatText = (text?: string, alwaysVisible?: boolean) => {
		if (!text) return;

		const missingPlaceholder = alwaysVisible ? "" : "%%missing%%";

		// TODO: only fetch necessary resources

		const formatted = format(
			text,
			{
				budgetResponsible_fullName:
					budgetResponsible?.fullName || missingPlaceholder,
				budgetResponsible_phone: budgetResponsible?.phone || missingPlaceholder,
				budgetResponsible_email: budgetResponsible?.email || missingPlaceholder
			},
			{ ignoreTag: true }
		);

		if (!alwaysVisible && formatted.includes(missingPlaceholder))
			throw new Error("Missing variable");

		return formatted;
	};

	if (
		selectedItem?.content &&
		selectedItem?.contentVisibility === ContentVisibility.Opened
	) {
		const { primary, secondary, tertiary, content, iconClass } = selectedItem;
		const icon = iconClass && <Icon className={`fa fa-${iconClass}`} />;

		return (
			<DetailsBox {...boxProps} onClick={() => setSelected(undefined)}>
				<Box component="article" overflow="auto">
					{(primary || tertiary || icon) && (
						<Box display="flex" justifyContent="space-between">
							<Typography variant="h5">
								{icon}
								{primary && <MarkdownElement component="span" text={primary} />}
							</Typography>

							<Typography>
								{tertiary && <MarkdownElement text={tertiary} />}
							</Typography>
						</Box>
					)}

					{secondary && (
						<Typography variant="caption" gutterBottom>
							<MarkdownElement text={secondary} />
						</Typography>
					)}

					{content && (
						<Typography variant="body2" component="div">
							<MarkdownElement text={content} />
						</Typography>
					)}
				</Box>
			</DetailsBox>
		);
	}

	return (
		<ContentBox {...boxProps}>
			<Box overflow="auto">
				<DividerList component="nav" dense divider>
					{!placeholder ? (
						items.map((item, idx) => {
							try {
								return (
									<TextListItem
										key={item.id ?? idx}
										{...item}
										primary={formatText(item.primary, item.alwaysVisible)}
										secondary={formatText(item.secondary, item.alwaysVisible)}
										tertiary={formatText(item.tertiary, item.alwaysVisible)}
										content={formatText(item.content, item.alwaysVisible)}
										onSelect={() =>
											setSelected(
												item === selectedItem ? undefined : item.id ?? item
											)
										}
										selected={item === selectedItem}
									/>
								);
							} catch {
								return null;
							}
						})
					) : (
						<>
							<TextListItem
								primary={
									<Skeleton animation={false} variant="text" width="50%" />
								}
								tertiary={
									<Skeleton animation={false} variant="text" width="20%" />
								}
							/>
							<TextListItem
								primary={
									<Skeleton animation={false} variant="text" width="40%" />
								}
								tertiary={
									<Skeleton animation={false} variant="text" width="25%" />
								}
							/>
						</>
					)}
				</DividerList>
			</Box>
		</ContentBox>
	);
};

TextList.displayName = "DployDashboardTextList";

export default TextList;
