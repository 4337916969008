import React from "react";
import clsx from "clsx";
import { Footer, Header } from "./components";
import { useTenantImage } from "@ploy-ui/tenants";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box } from "@material-ui/core";
import { ImageFit } from "@ploy-ui/core";

interface LoginWrapperProps {
	classes: Record<string, string>;
	footerVisible?: boolean;
	elevation?: number;
	children: React.ReactNode;
	isInternal?: boolean;
}
const LoginWrapper = (props: LoginWrapperProps) => {
	const loginBackground = useTenantImage("loginBackground");
	const loginInternalBackground = useTenantImage("loginInternalBackground");

	const {
		classes,
		footerVisible,
		elevation = loginBackground ? 2 : 0,
		children,
		isInternal,
	} = props;

	const headerVisible = !loginBackground;

	return (
		<LoginBackground image={isInternal ? loginInternalBackground : loginBackground} className={classes?.root}>
			{headerVisible && <Header />}
			<Box
				display="flex"
				alignItems={headerVisible ? "flex-start" : "center"}
				className={classes?.formWrapper}
			>
				<Paper elevation={elevation} className={classes?.form}>
					{children}
				</Paper>
			</Box>
			{footerVisible && <Footer />}
		</LoginBackground>
	);
};

const useStyles = makeStyles({
	root: {
		height: "100vh",
		top: 0,
		resize: "both"
	}
});

function LoginBackground(props: {
	image: string | null;
	className?: string;
	children?: any;
}) {
	const { image, className, children } = props;
	const classes = useStyles(props);
	return (
		<ImageFit src={image} fit="cover" className={clsx(classes.root, className)}>
			{children}
		</ImageFit>
	);
}

export { LoginWrapper };
