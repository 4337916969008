import React, { useMemo, useState } from "react";
import { ExpandableTable, Cells, ExpandableTableProps } from "@ploy-ui/core";
import {
	ApplicationInfo,
	ApplicationInfoFilter,
	ApplicationInfoResource,
	ID,
	LoginResource,
	IntegrationClientResource,
	IntegrationClient
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { TablePagination, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { SalespersonSelector } from "./SalespersonSelector";
import { IntegrationSelector } from "./IntegrationSelector";
import { VendorSalespersonSelector } from "./VendorSalespersonSelector";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles(
	theme => ({
		root: {
			width: "100%",
			flexGrow: 1,
			padding: theme.spacing(2),
			[theme.breakpoints.up("lg")]: {
				padding: theme.spacing(4)
			}
		},
		applicationStatusNameExternal: {
			width: 160,
			textAlign: "center" as "center"
		},
		applicationNumber: {
			width: 100
		},
		customerNameInner: {
			display: "flex",
			alignItems: "center" as "center",
			"& > div": {
				marginRight: 10
			}
		},
		customerNameText: {
			fontWeight: "bold" as "bold"
		},
		datePickerInput: {
			fontWeight: "bold" as "bold",
			fontSize: "0.875rem"
		}
	}),
	{ name: "ApplicationList" }
);

interface rowDataProps {
	rowData?: {
		salesPersonId?: ID;
		vendorId?: ID;
		applicationId?: ID;
	};
}

const fromDataToSalespersonSelector = (props: rowDataProps) => {
	return (
		<SalespersonSelector
			salespersonId={props.rowData?.salesPersonId}
			applicationId={props.rowData?.applicationId}
		/>
	);
};

const fromDataToVendorSalespersonSelector = (props: rowDataProps) => {
	return (
		<VendorSalespersonSelector
			salespersonId={props.rowData?.salesPersonId}
			vendorId={props.rowData?.vendorId}
			applicationId={props.rowData?.applicationId}
		/>
	);
};

const getColumnData: (
	vendorSalespeople: boolean
) => ExpandableTableProps<
	ApplicationInfo,
	any
>["columnData"] = vendorSalespeople => [
	{
		id: "applicationStatusNameExternal",
		label: "Status",
		component: props => (
			<Cells.ColoredStatus statusKey="applicationStatusId" {...props} />
		),
		hidden: { xsDown: true },
		hiddenInExpandable: { smUp: true }
	},
	{
		id: "applicationNumber",
		label: "Saksnr.",
		hidden: { smDown: true }
	},
	{
		id: "customerName",
		label: "Kunde",
		component: props => (
			<Cells.DoubleLineSmDown
				statusKey="applicationStatusId"
				secondLineKey="applicationNumber"
				{...props}
			/>
		)
	},
	{
		id: "productName",
		label: "Produkt",
		hidden: { smDown: true },
		hiddenInExpandable: { mdUp: true }
	},
	vendorSalespeople ? { id: "customerNumber", label: "Kundenummer" } : {},
	vendorSalespeople ? { id: "vendorName", label: "Salgskanal" } : {},
	!vendorSalespeople
		? {
				id: "documentStatusNameExternal",
				label: "Dokumentstatus"
		  }
		: {},
	{
		id: "salespersonName",
		label: "Selger",
		component: vendorSalespeople
			? fromDataToVendorSalespersonSelector
			: fromDataToSalespersonSelector
	}
];

export interface SalespersonAssignmentProps {
	className?: string;
}

export const SalespersonAssignment = ({
	className
}: SalespersonAssignmentProps) => {
	const classes = useStyles();

	const { vendor: currentVendor } = useResource(LoginResource.status(), {});

	const integrations = (useResource(IntegrationClientResource.list(), {
		vendorId: currentVendor?.id
	}) || []) as IntegrationClient[];

	const [integration, setIntegration] = useState<IntegrationClient | null>(
		integrations[0]
	);

	const today = () => {
		const currentDate = new Date();
		currentDate.setHours(23, 59, 0, 0);
		return currentDate;
	};
	const threeMonthsAgo = () => {
		const backInTheDay = new Date();
		backInTheDay.setHours(23, 59, 0, 0);
		backInTheDay.setDate(backInTheDay.getDate() - 91);
		return backInTheDay;
	};

	const [fromDate, setFromDate] = useState<string>(
		threeMonthsAgo().toISOString()
	);
	const [toDate, setToDate] = useState<string>(today().toISOString());

	const useCarwebView = integrations.some(x => x.code === "carweb");

	const columns = useMemo(
		() =>
			getColumnData(!useCarwebView).map(c => ({
				...c,
				className:
					c.id == null
						? undefined
						: (classes as Record<string, string | undefined>)[c.id]
			})),
		[classes, useCarwebView]
	);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const params = useMemo<ApplicationInfoFilter>(
		() => ({
			integrationClientId: useCarwebView
				? integration?.integrationClientId
				: undefined,
			salepersonId: useCarwebView
				? integration?.defaultSalespersonId
				: undefined,
			vendorId: currentVendor?.id,
			pageSize: rowsPerPage,
			page: page + 1,
			sortBy: "createdDate.desc",
			useVendorSpecifiedSearch: !useCarwebView,
			fromDate: useCarwebView ? undefined : fromDate,
			toDate: useCarwebView ? undefined : toDate
		}),
		[
			currentVendor?.id,
			fromDate,
			integration?.defaultSalespersonId,
			integration?.integrationClientId,
			page,
			rowsPerPage,
			toDate,
			useCarwebView
		]
	);

	const { records, pageSize, recordCount } = useResource(
		ApplicationInfoResource.list(),
		params
	);

	return (
		<div className={clsx(className, classes.root)}>
			{integrations.length > 1 && (
				<IntegrationSelector
					integrationId={integration?.integrationClientId}
					vendorId={currentVendor?.id}
					setIntegration={integration => setIntegration(integration)}
				/>
			)}
			{!useCarwebView && (
				<>
					<DatePicker
						label={"Fra"}
						value={fromDate}
						format="dd.MM.yyyy"
						onChange={date => {
							var newDate = date?.toJSDate() ?? threeMonthsAgo();
							newDate.setHours(23, 59, 0, 0);
							setFromDate(newDate.toISOString());
						}}
						inputProps={{ className: classes.datePickerInput }}
					/>
					<DatePicker
						label={"Til"}
						value={toDate}
						format="dd.MM.yyyy"
						onChange={date => {
							var newDate = date?.toJSDate() ?? today();
							newDate.setHours(23, 59, 0, 0);
							setToDate(newDate.toISOString());
						}}
						inputProps={{ className: classes.datePickerInput }}
					/>
				</>
			)}
			<>
				<ExpandableTable
					idColumn="applicationId"
					tableData={records as ApplicationInfo[]}
					columnData={columns}
					expandable={false}
				/>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50]}
					component="div"
					count={recordCount}
					rowsPerPage={pageSize}
					page={page}
					onPageChange={(_, newPage) => {
						setPage(newPage);
					}}
					onRowsPerPageChange={event => {
						setRowsPerPage(parseInt(event.target.value, 10));
						setPage(0);
					}}
				/>
			</>
		</div>
	);
};
