import {
	Grid,
	List,
	ListItem,
	Theme,
	Typography,
	makeStyles
} from "@material-ui/core";
import { Overwrite } from "@material-ui/types";
import { BaseFieldProps, FieldEditorOptions, HasItems } from "./types";
import { FieldProps } from "formik";
import {
	defaultGetItemLabel,
	identityRecordOfFieldEditorOptions
} from "./utils";

const useStyles = makeStyles(
	(theme: Theme) => ({
		list: {},
		listItem: {}
	}),
	{ name: "DployListField" }
);

export interface ListFieldProps<T>
	extends Overwrite<FieldProps, HasItems<T>>,
		Overwrite<BaseFieldProps, HasItems<T>> {}

export function ListField<T extends any>(props: ListFieldProps<T>) {
	const { field, label, suffix, getItemLabel = defaultGetItemLabel } = props;
	const classes = useStyles(props);
	const items = field.value;
	if (!items) {
		return null;
	}
	return (
		<Grid container direction="column">
			{label && label?.toString().trim().length > 0 ? (
				<Typography variant="caption">{label}</Typography>
			) : null}
			<List className={classes.list}>
				{items.map(x => {
					const label: string = getItemLabel(x);
					const labelSplit = label.split(";");
					return (
						<ListItem key={label} className={classes.listItem} disableGutters>
							<Typography variant="body1">{labelSplit[0]}</Typography>
							<Typography variant="body1">
								{labelSplit[1]}
								{suffix}
							</Typography>
						</ListItem>
					);
				})}
			</List>
		</Grid>
	);
}

const listFieldEditorProps: FieldEditorOptions = {};

export const EditorListFields = identityRecordOfFieldEditorOptions({
	ListField: listFieldEditorProps
});
