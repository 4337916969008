import { useTheme } from "@material-ui/core";

export function useThemeComponent(type: string) {
	const theme = useTheme();

	const tenantComponents = theme.components;

	const tenantComponent = tenantComponents?.[type];

	return tenantComponent;
}
