import React, { useMemo } from "react";
import {
	FormikTextField,
	FormikSubmitButton,
	FormikResetButton,
	DashboardGrid,
	DashboardGridResource
} from "@ploy-ui/dashboard";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { useCache } from "rest-hooks";

export interface DashboardEditDialogProps {
	grid?: DashboardGrid;
	editedById?: Record<string, DashboardGrid>;
	onSubmit: (
		values: DashboardGrid,
		form: FormikHelpers<DashboardGrid>
	) => Promise<any> | void;
	onReset: (values: DashboardGrid, form: FormikHelpers<DashboardGrid>) => void;
}

export function DashboardEditDialog(props: DashboardEditDialogProps) {
	const { grid, editedById, onSubmit, onReset } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const dashboards = useCache(DashboardGridResource.list(), {});

	const intl = useIntl();

	const gridValidation = useMemo(() => {
		const originalGrids =
			dashboards?.filter(
				// Allow the original name of this grid
				d => !grid || d.pk() !== DashboardGridResource.pk(grid)
			) ?? [];

		const editedGrids = Object.values(editedById ?? {});

		const takenNames = [...originalGrids, ...editedGrids]
			.map(d => d.name)
			// Allow the current name of this grid
			.filter(n => n !== grid?.name);

		return object<Partial<DashboardGrid>>({
			name: string()
				.required(
					intl.formatMessage(
						defineMessage({
							id: "dealer.dashboard.edit.edit.validation-required",
							defaultMessage: "Enter a unique name"
						})
					)
				)
				.notOneOf(
					takenNames,
					intl.formatMessage(
						defineMessage({
							id: "dealer.dashboard.edit.edit.validation-unique",
							defaultMessage: "This name is in use, the name must be unique"
						})
					)
				)
		});
	}, [dashboards, editedById, grid, intl]);

	return (
		<Dialog
			open={!!grid}
			fullScreen={isMobile}
			aria-labelledby="dashboard-name-edit-dialog-title"
		>
			<Formik
				initialValues={grid!}
				onSubmit={onSubmit}
				onReset={onReset}
				validationSchema={gridValidation}
			>
				<>
					<DialogTitle id="dashboard-edit-dialog-title">
						<FormattedMessage
							id="dealer.dasbhoard.edit-dialog.title"
							defaultMessage="Dashboard"
						/>
					</DialogTitle>
					<DialogContent>
						<Form>
							<FormikTextField
								autoFocus
								margin="dense"
								id="name"
								name="name"
								label={
									<FormattedMessage
										id="dealer.dasbhoard.edit-dialog.name.label"
										defaultMessage="Name"
									/>
								}
								type="text"
								variant="outlined"
								fullWidth
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<FormikResetButton color="primary">
							<FormattedMessage
								id="dealer.dasbhoard.edit-dialog.cancel.label"
								defaultMessage="Cancel"
							/>
						</FormikResetButton>
						<FormikSubmitButton>
							<FormattedMessage
								id="dealer.dasbhoard.edit-dialog.ok.label"
								defaultMessage="OK"
							/>
						</FormikSubmitButton>
					</DialogActions>
				</>
			</Formik>
		</Dialog>
	);
}
