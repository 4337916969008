import { Suspense } from "react";
import Typography from "@material-ui/core/Typography";
import { ErrorHandler } from "@ploy-lib/core";
import { DashboardContentProps } from "./types";
import { GridItem } from "./resources/DashboardGridResource";
import * as ContentComponents from "./content";
import ContentBox from "./ContentBox";
import { SnackbarMessage } from "./ContentGridItem";
import { FormattedMessage } from "react-intl";

export function GridItemWrapper(
	props: GridItem & {
		component: Required<GridItem>["component"];
	} & DashboardContentProps
) {
	const { component, properties, id, boxProps, ...rest } = props;

	const Component = ContentComponents[component];

	if (!Component)
		throw new Error(
			`Dashboard component implementation not found: ${component}`
		);

	return (
		<ErrorHandler
			fallback={error => (
				<>
					<ContentBox {...boxProps} error={error} />
					<SnackbarMessage variant="error">
						<div>
							<Typography variant="subtitle2">
								<FormattedMessage
									id="dploy.grid.grid-item.an-error-occurred"
									defaultMessage="An error occurred in {header} ({component})"
									values={{ component, header: boxProps?.header }}
								/>
							</Typography>
							<Typography variant="caption">{String(error)}</Typography>
						</div>
					</SnackbarMessage>
				</>
			)}
		>
			<ErrorHandler
				fallback={error => (
					<Component
						{...rest}
						{...properties}
						id={id}
						boxProps={{
							error,
							...boxProps
						}}
					/>
				)}
			>
				<Suspense fallback={<ContentBox {...boxProps} loading />}>
					<Component {...rest} {...properties} id={id} boxProps={boxProps} />
				</Suspense>
			</ErrorHandler>
		</ErrorHandler>
	);
}
