import {
	FormValidationResource,
	IFormValidationExpression,
	ValidatorEnumTypes
} from "@ploy-lib/rest-resources";
import { useFormikContext } from "formik";
import { useCallback, useMemo } from "react";

const getPropByString = (
	values: Record<string, any>,
	propStringPath: string
) => {
	if (!propStringPath) return values;

	var prop,
		props = propStringPath.split(".");

	for (var i = 0; i < props.length - 1; i++) {
		prop = props[i];

		var candidate = values[prop];
		if (candidate !== undefined) {
			values = candidate;
		} else {
			break;
		}
	}
	return values[props[i]];
};

type IValidationExpressionSignature = (namespacedFieldName: string) => boolean;

interface EvaluationValidationExpression {
	fieldIsVisible: IValidationExpressionSignature;
	fieldIsDisabled: IValidationExpressionSignature;
}

/**
 * Evaluate each expression for a given field
 * @param expressions An array of `IFormValidationExpression` objects belonging to a certain field
 * @param namespacedFieldName A string representing the field with namespace. Example: `Calculator.EquityPercent`.
 * @param formValues The `values` object provided by `useFormikContext()`
 * @returns The default return value is `false`. Will only return `true`, if _all_ validation expressions evaluate: `true`
 */
const evaluateExpressionsForField = (
	expressions: IFormValidationExpression[],
	namespacedFieldName: string,
	formValues: Record<string, any>
) => {
	const expressionsForField = expressions.filter(
		x => x._owningFieldName === namespacedFieldName
	);

	// Array.every() returns true when it's empty..
	if (expressionsForField.length === 0) return false;

	return expressionsForField.every(expression =>
		doEvaluation(expression, namespacedFieldName, formValues)
	);
};

/**
 * Evaluate the given `IFormValidationExpression`, compare the target field's value against another value to evaluate.
 * @param expression An `IFormValidationExpression` object.
 * @param namespacedFieldName A string representing the field with namespace. Example: `Calculator.EquityPercent`.
 * @param formValues The `values` object provided by `useFormikContext()`
 * @returns A boolean, representing the result of the expression
 */
const doEvaluation = (
	expression: IFormValidationExpression,
	namespacedFieldName: string,
	formValues: Record<string, any>
): boolean => {
	// Default value
	let evaluation = false;

	const operator: string = expression.operator;
	const targetField: string = expression.targetField; // The field we wish to compare. This is the field whose value must match a given evaluation-value.

	// The value we wish to compare targetField against
	// This value is either a static value (such as "true", "false", "0", "1", "undefined", etc..),
	//	 or it is a string, like `Field:{Namespace}.{FieldName}`, which is used to look up the value of <Namespace>.<FieldName>
	let evaluationValue: string = expression.evaluationValue;

	if (evaluationValue.startsWith("Field:")) {
		// EvaluationValue is a string like `Field:{Namespace}.{FieldName}`, and not a static value,
		// we need to look up the value of <Namespace>.<FieldName>.
		// 	(PS: Just to be clear, and avoid confusion,
		//		the evaluationValue here is actually stored like for example: "Field:Calculator.Equity",
		//		without the curly-braces)
		let [evaluationNamespace, evaluationFieldName] = evaluationValue.split(".");
		evaluationNamespace = evaluationNamespace.split(":")[1];
		evaluationValue = formValues[evaluationNamespace]?.[evaluationFieldName];
	}

	// The value of the targetField, which we want to compare against the evaluation value.
	const targetValue = getPropByString(
		formValues,
		targetField.replace("undefined.", "") //namespace will be undefined for resource schemas
	);

	switch (operator) {
		case "===":
			evaluation =
				targetValue?.toString().toLowerCase() ===
				evaluationValue.toString().toLowerCase();
			break;
		case "!==":
			evaluation =
				targetValue?.toString().toLowerCase() !==
				evaluationValue.toString().toLowerCase();
			break;
		case ">":
			evaluation =
				Number(targetValue?.toString()) > Number(evaluationValue.toString());
			break;
		case ">=":
			evaluation =
				Number(targetValue?.toString()) >= Number(evaluationValue.toString());
			break;
		case "contains":
			const targetCompare =
				evaluationValue.toString() !== "FieldName"
					? evaluationValue.toString().toLowerCase()
					: namespacedFieldName.split(".")[1]?.toLowerCase();
			if (typeof targetValue?.filter === "function") {
				evaluation =
					targetValue?.length > 0 &&
					targetValue.filter(
						(multiselectEntry: string) =>
							multiselectEntry.toLowerCase() === targetCompare
					).length > 0;
			} else {
				evaluation = targetValue
					?.toString()
					.toLowerCase()
					.includes(targetCompare);
			}
			break;
		default:
			console.warn(
				`A validation expression (type: ${expression.validatorType}) for '${expression._owningFieldName}', targeting '${expression.targetField}', uses an unimplemented operator: '${expression.operator}'.`
			);
			break;
	}
	return evaluation;
};

export const useValidationExpression = (
	formValidations: FormValidationResource
): EvaluationValidationExpression => {
	const { values }: Record<string, any> = useFormikContext();

	const visibiltyTypes = Object.values(formValidations)
		.flatMap(x => x.expressions)
		.filter(x => x.validatorType === ValidatorEnumTypes.Visibility);

	const disabledTypes = Object.values(formValidations)
		.flatMap(x => x.expressions)
		.filter(x => x.validatorType === ValidatorEnumTypes.Disabled);

	const visibilityFunc = useCallback(
		(namespacedFieldName: string) =>
			evaluateExpressionsForField(visibiltyTypes, namespacedFieldName, values),
		[visibiltyTypes, values]
	);

	const disabledFunc = useCallback(
		(namespacedFieldName: string) =>
			evaluateExpressionsForField(disabledTypes, namespacedFieldName, values),
		[disabledTypes, values]
	);

	return useMemo(
		() => ({
			fieldIsVisible: visibilityFunc,
			fieldIsDisabled: disabledFunc
		}),
		[disabledFunc, visibilityFunc]
	);
};
