import { TemplateField } from "@ploy-lib/types";
import { useCallback } from "react";
import { useDispatch } from "./useDispatch";

export function useUpdateSavedValues() {
	const dispatch = useDispatch();

	return useCallback(
		<T extends Pick<TemplateField, "name" | "namespace"> & { value: any }>(
			field: T | T[] = [],
			...fields: T[]
		) => {
			fields = fields.concat(field);

			dispatch({
				type: "field_patch",
				payload: {
					patches: fields.map(f => ({
						fieldName: f.name,
						namespace: f.namespace,
						initialValue: f.value
					}))
				}
			});
		},
		[dispatch]
	);
}
