import React from "react";
import {
	Checkbox,
	Link,
	makeStyles,
	Theme,
	Typography
} from "@material-ui/core";
//
import { OptionValue } from "../types";
import { FormattedDate, FormattedNumber } from "react-intl";
import { isDate, isNumeric } from "../utils";
import { FieldType, TableColumnMetadata } from "../tableSchema";
import {
	DployTextField,
	IntlNumberField,
	NumberField,
	SelectField,
	CheckboxWithLabelField,
	CurrencyField,
	PercentField
} from "@ploy-ui/form-fields";
import { FieldInputProps, FormikProps, useField } from "formik";
import clsx from "clsx";
import {
	ErrorDisplay,
	TableColumnOptionSource,
	TemplateTableColumn
} from "@ploy-lib/types";

const useStyles = makeStyles((theme: Theme) => ({
	tableCellTextValue: (props?: { width?: string }) => ({
		fontWeight: "inherit",
		width: props?.width ?? "100%"
	}),
	tableCellNumericValue: (props?: { width?: string }) => ({
		textAlign: "right",
		fontWeight: "inherit",
		width: props?.width ?? "100%"
	}),
	tableCell: (props?: { width?: string }) => ({
		width: props?.width ?? undefined
	}),
	multiline: {
		whiteSpace: "pre"
	}
}));

const getItemValue = (item: any, optionSource?: TableColumnOptionSource) => {
	let keyLabelToReturn: any;
	if (typeof item === "object") {
		keyLabelToReturn = item?.key
			? item.key
			: item?.[optionSource?.key ?? "key"];
	} else {
		keyLabelToReturn = item;
	}
	return keyLabelToReturn?.toString();
};

const getItemLabel = (item: any, optionSource?: TableColumnOptionSource) => {
	let itemValueToReturn: any;
	if (typeof item === "object") {
		itemValueToReturn = item?.value
			? item.value
			: item?.[optionSource?.value ?? "value"];
	} else {
		itemValueToReturn = item;
	}
	return itemValueToReturn?.toString();
};

const isValidNumber = (value: any) => {
	return value !== "" && value !== undefined && value !== null && !isNaN(value);
};

export const SimpleTextLiteral = <TRow extends {} = {}>(props: {
	value: any | any[];
	items?: OptionValue[];
	multiple?: boolean;
	hrefLink?: string;
	fieldMetadata?: TableColumnMetadata<TRow>;
	tc: TemplateTableColumn;
}) => {
	const { value, items, multiple, hrefLink, fieldMetadata, tc, ...rest } =
		props;
	let valueForDisplay = value;
	const classes = useStyles(tc);

	if (hrefLink) {
		return (
			<Typography
				variant="subtitle2"
				className={classes.tableCellTextValue}
				data-cr-component="LinkLiteral"
				{...rest}
			>
				<Link href={hrefLink} underline="hover">
					{valueForDisplay}
				</Link>
			</Typography>
		);
	}
	if (fieldMetadata) {
		return <SchemaField {...props} />;
	}

	if (multiple) {
		valueForDisplay = Array.isArray(value)
			? value
					?.map(v => {
						const item = items?.find(
							x => getItemValue!(x) === getItemValue!(v)
						);
						return item && getItemLabel ? getItemLabel(item) : getItemLabel!(v);
					})
					.join(",\n")
			: value;
	} else if ((items?.length ?? 0) > 0) {
		const item = items?.find(x => getItemValue!(x) === getItemValue!(value));
		valueForDisplay = item ? getItemLabel!(item) : value;
	} else if (isNumeric(value)) {
		const numericFieldValue = Number(value);
		return (
			<Typography
				variant="subtitle2"
				className={classes.tableCellNumericValue}
				data-cr-component="NumberLiteral"
				{...rest}
			>
				<FormattedNumber value={numericFieldValue} />
			</Typography>
		);
	} else if (isDate(value)) {
		const dateFieldValue = new Date(value.toString());
		return (
			<Typography
				variant="subtitle2"
				className={classes.tableCellNumericValue}
				data-cr-component="DateLiteral"
				{...rest}
			>
				<FormattedDate value={dateFieldValue} format="short_numeric" />
			</Typography>
		);
	}

	return (
		<Typography
			variant="subtitle2"
			className={clsx(classes.tableCellTextValue, {
				[classes.multiline]: multiple
			})}
			data-cr-component="StringLiteral"
			{...rest}
		>
			{valueForDisplay}
		</Typography>
	);
};

// Select,
// Multiselect,
// Text,
// Number,
// Currency,
// Percent
const SchemaField = <TRow extends {} = {}>(props: {
	value?: any | any[];
	multiple?: boolean;
	fieldMetadata?: TableColumnMetadata<TRow>;
	items?: OptionValue[];
	tc: TemplateTableColumn;
}) => {
	const {
		value,
		multiple,
		items,
		fieldMetadata = {} as TableColumnMetadata,
		tc,
		...rest
	} = props;
	const classes = useStyles(tc);
	const dataCrComponent = FieldType[fieldMetadata.fieldType] + "Literal";

	switch (fieldMetadata.fieldType) {
		case FieldType.Percent:
			return (
				<Typography
					variant="subtitle2"
					className={classes.tableCellNumericValue}
					data-cr-component={dataCrComponent}
					{...rest}
				>
					{isValidNumber(value) ? (
						<FormattedNumber
							value={value / 100}
							format={"percent"}
							maximumFractionDigits={fieldMetadata.maximumFractionDigits ?? 2}
							minimumFractionDigits={fieldMetadata.minimumFractionDigits ?? 2}
						/>
					) : (
						""
					)}
				</Typography>
			);
		case FieldType.Currency:
		case FieldType.Number:
			//Currency is currently identical to number, but there exists posibilities format currencies differently in the future
			return (
				<Typography
					variant="subtitle2"
					className={classes.tableCellNumericValue}
					data-cr-component={dataCrComponent}
					{...rest}
				>
					{isValidNumber(value) ? (
						<FormattedNumber
							value={value}
							maximumFractionDigits={fieldMetadata.maximumFractionDigits ?? 0}
							minimumFractionDigits={fieldMetadata.minimumFractionDigits ?? 0}
						/>
					) : (
						""
					)}
				</Typography>
			);
		case FieldType.Multiselect:
			const multiValue = Array.isArray(value)
				? value
						?.map(v => {
							const item = items?.find(
								x => getItemValue!(x) === getItemValue!(v)
							);
							return item && getItemLabel
								? getItemLabel(item)
								: getItemLabel!(v);
						})
						.join(",\n")
				: value;
			return (
				<Typography
					variant="subtitle2"
					className={clsx(classes.tableCellTextValue, {
						[classes.multiline]: multiple
					})}
					data-cr-component={dataCrComponent}
					{...rest}
				>
					{multiValue}
				</Typography>
			);
		case FieldType.Select:
			const item = items?.find(x => getItemValue!(x) === getItemValue!(value));
			const selectValue = item ? getItemLabel!(item) : value;
			return (
				<Typography
					variant="subtitle2"
					className={clsx(classes.tableCellTextValue, {
						[classes.multiline]: multiple
					})}
					data-cr-component={dataCrComponent}
					{...rest}
				>
					{selectValue}
				</Typography>
			);
		case FieldType.Date:
			const dateFieldValue = new Date(value.toString());
			return (
				<Typography
					variant="subtitle2"
					className={classes.tableCellNumericValue}
					data-cr-component={dataCrComponent}
					{...rest}
				>
					<FormattedDate value={dateFieldValue} format="short_numeric" />
				</Typography>
			);
		case FieldType.Checkbox:
			return (
				<Checkbox className={classes.tableCell} checked={value} disabled />
			);
		default:
			return (
				<Typography
					variant="subtitle2"
					className={clsx(classes.tableCellTextValue, {
						[classes.multiline]: multiple
					})}
					data-cr-component="StringLiteral"
					{...rest}
				>
					{value}
				</Typography>
			);
	}
};

export const EditingSchemaField = <TRow extends {} = {}>(props: {
	fieldId: string;
	form: FormikProps<any>;
	fieldMetadata?: TableColumnMetadata<TRow>;
	tc: TemplateTableColumn;
}) => {
	const { fieldId, form, fieldMetadata, tc } = props;
	const classes = useStyles(tc);
	const [field, meta] = useField(`${fieldId}`);
	const error = !!form?.errors?.[fieldId];
	if (!field) return null;

	switch (fieldMetadata?.fieldType) {
		case FieldType.Currency:
		case FieldType.Percent:
		case FieldType.Number:
			let Component = NumberField;
			let formatString: string | undefined = "#,###";
			if (fieldMetadata.fieldType === FieldType.Percent) {
				Component = PercentField;
				formatString = undefined;
			}
			if (fieldMetadata.fieldType === FieldType.Currency)
				Component = CurrencyField;
			return (
				<Component
					formatString={formatString}
					data-cr-field={field.name}
					data-cr-component={"input"}
					variant="standard"
					size="small"
					field={field}
					form={form}
					meta={meta}
					className={classes.tableCellNumericValue}
					emptyEqualsZero={
						fieldMetadata?.fieldType !== FieldType.Percent &&
						fieldMetadata?.fieldType !== FieldType.Number
					}
					fullWidth
					error={error}
					allowNegative={fieldMetadata?.allowNegative}
					maximumFractionDigits={
						fieldMetadata?.maximumFractionDigits ??
						(fieldMetadata?.fieldType === FieldType.Percent ? 2 : 0)
					}
					errorDisplay={ErrorDisplay.AlwaysNoMessage}
					onBlur={e => fieldMetadata?.alwaysEditable && form.submitForm()}
				/>
			);
		case FieldType.Checkbox:
			return (
				<CheckboxWithLabelField
					data-cr-field={field.name}
					data-cr-component={"CheckboxField"}
					className={classes.tableCell}
					field={field}
					form={form}
					meta={meta}
					error={error}
					errorDisplay={ErrorDisplay.AlwaysNoMessage}
					onChange={(e, checked) => {
						form.setFieldValue(field.name, checked);
						fieldMetadata?.alwaysEditable && form.submitForm();
					}}
				/>
			);
		case FieldType.Select:
		case FieldType.Multiselect:
			return (
				<SelectField
					data-cr-field={field.name}
					data-cr-component={"SelectionField"}
					className={classes.tableCell}
					variant="standard"
					size="small"
					field={field}
					form={form}
					meta={meta}
					error={error}
					errorDisplay={ErrorDisplay.AlwaysNoMessage}
					disableClearable={tc.isMultipleSelect}
					searchable
					multiple={tc.isMultipleSelect}
					items={
						tc.optionValues
							?.filter(item => item.key || item.value)
							.sort((a, b) => a.key.localeCompare(b.key)) || []
					}
					getItemLabel={(item?: any) => getItemLabel(item, tc.optionSource)}
					getItemValue={(item?: any) => getItemValue(item, tc.optionSource)}
					fullWidth
					minWidthFocused={200}
				/>
			);
		default:
			return (
				<DployTextField
					data-cr-field={field.name}
					data-cr-component={"TextField"}
					className={classes.tableCell}
					variant="standard"
					size="small"
					{...field}
					fullWidth
					error={error}
				/>
			);
	}
};
