import React, { Component } from "react";

export type ErrorRenderer = (
	error: Error,
	retry: () => void
) => React.ReactNode;

export interface ErrorHandlerProps {
	fallback?: React.ReactNode | ErrorRenderer;
	children: React.ReactNode;
}

function isRenderer(
	fallback: React.ReactNode | ErrorRenderer
): fallback is ErrorRenderer {
	return typeof fallback === "function";
}

export class ErrorHandler<T> extends Component<ErrorHandlerProps & T> {
	static defaultProps: Partial<ErrorHandlerProps> = {
		fallback: error => error.message
	};

	state = {
		error: undefined as Error | undefined
	};

	retry = () => {
		return this.setState({ error: undefined });
	};

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		if (error instanceof Error) {
			return { error };
		}
		return null;
	}

	componentDidUpdate(prevProps: ErrorHandlerProps & T) {
		const props = this.props;
		const keys = new Set([...Object.keys(props), ...Object.keys(prevProps)]);

		for (const key of keys) {
			if (key === "fallback" || key === "children") continue;
			if (
				(props as { [k: string]: any })[key] !==
				(prevProps as { [k: string]: any })[key]
			) {
				this.retry();
				break;
			}
		}
	}

	componentDidCatch(error: any, info: any) {
		if (!(error instanceof Error)) {
			throw error;
		}

		// Log the error to an error reporting service
		// logErrorToMyService(error, info);
	}

	render() {
		const { fallback, children } = this.props;
		const { error } = this.state;

		if (error) {
			if (isRenderer(fallback)) {
				return fallback(error, this.retry);
			}

			return fallback;
		}

		return children;
	}
}
