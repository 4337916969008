import { TemplatePage, TemplatePageBase } from "@ploy-lib/types";

export function cssContains(...classes: (string | undefined | null)[]) {
	return (text: string) =>
		classes.filter(i => i != null).some(c => c!.includes(text));
}

export const mapToPaths = (obj: object): string[] =>
	Object.entries(obj).flatMap(([k, v]) =>
		typeof v === "object" ? mapToPaths(v).map(p => `${k}.${p}`) : k
	);

export const hasPanels = (page: TemplatePageBase): page is TemplatePage =>
	(page as TemplatePage).panels != null &&
	Object.keys((page as TemplatePage).panels).length > 0;

export function isNotNull<T>(x: T): x is NonNullable<T> {
	return x != null;
}
