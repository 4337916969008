import { Checkbox } from "@material-ui/core";
import { WholesaleCar } from "@ploy-lib/rest-resources";
import { useState } from "react";
import { useBetween } from "use-between";

const WholesaleOverviewCheckbox = (props: WholesaleCar) => {
	const { checkedSet, addApp, removeApp } = SharedCheckedSet();
	const { DaysUntilEndDateStatus, Status } = props;
	return (
		<Checkbox
			checked={checkedSet.has(props.ApplicationNumber)}
			disabled={DaysUntilEndDateStatus === "black" || Status !== 5}
			onChange={(_, checkedValue) => {
				checkedValue
					? addApp(props.ApplicationNumber)
					: removeApp(props.ApplicationNumber);
			}}
		/>
	);
};

const WholesaleCheckedSet = () => {
	const [checkedSet, setCheckedSet] = useState<Set<string>>(new Set<string>());

	//Creates new Set instances, so that react re-renders checkboxes
	const addApp = (app: string) => {
		setCheckedSet(_ => new Set([...checkedSet, app]));
	};
	const removeApp = (app: string) => {
		setCheckedSet(_ => new Set([...checkedSet].filter(x => x !== app)));
	};
	const removeAllChecked = () => {
		setCheckedSet(_ => new Set());
	};

	return { checkedSet, addApp, removeApp, removeAllChecked };
};

const SharedCheckedSet = () => useBetween(WholesaleCheckedSet);

export { WholesaleOverviewCheckbox, SharedCheckedSet };
