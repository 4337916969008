import { defineMessages, useIntl } from "react-intl";
import { CSSProperties } from "react";
import { DployTable } from "../DployTable";


export interface EquityStatusProps {
	equityStatus: number;
}

const EquityStatus = (props: EquityStatusProps) =>{
	const intl = useIntl();
	const cellStyle: CSSProperties = { fontSize: 13 };
	
	const messages = defineMessages({
		title: {
			id: "core.equitystatus.equity",
			defaultMessage: "Equity"
		},
		received: {
			id: "core.equitystatus.received",
			defaultMessage: "Received"
		},
	})

	const data = {
		equity: intl.formatMessage(messages.title),
		received: props.equityStatus === 1
	}

	return (
		<DployTable
			title={""}
			columns={[
				{
					title: "",
					field: "equity",
					cellStyle
				},
				{
					title: intl.formatMessage(messages.received),
					field: "received",
					type: "boolean",
					align: "right",
					cellStyle
				},
			]}
			data={[data]}
			options={{
				search: false,
				draggable: false,
				sorting: false,
				headerStyle: cellStyle,
				paging: false,
				showEmptyDataSourceMessage: false,
				emptyRowsWhenPaging: false,
				header: true,
			}}
		/>
	)
};

export {EquityStatus};
