import { useState, useMemo, useDebugValue } from "react";
import useResizeObserver from "use-resize-observer/polyfilled";
import debounce from "lodash/debounce";

export function useWidth<T extends HTMLElement>(wait?: number) {
	const [value, rawSet] = useState<number>();

	const set = useMemo(
		() => (wait ? debounce(rawSet, wait, { leading: true }) : rawSet),
		[wait]
	);

	const { ref } = useResizeObserver<T>({
		onResize: size => {
			set(size.width);
		}
	});

	// useEffect(() => {
	// 	const onWindowResize = () => {
	// 		if (ref.current) rawSet(ref.current.offsetWidth);
	// 	};

	// 	window.addEventListener("resize", onWindowResize);

	// 	return () => window.removeEventListener("resize", onWindowResize);
	// });

	useDebugValue(value);

	return [ref, value] as const;
}
