import { Grid, Typography } from "@material-ui/core";
import { PendingButton } from "@ploy-ui/core";
import { FormattedMessage } from "react-intl";

interface WholesaleOverviewHeaderProps {
	onNew: () => void;
}

const WholesaleOverviewHeader = (props: WholesaleOverviewHeaderProps) => {
	const { onNew } = props;

	return (
		<Grid container spacing={2} justifyContent="space-between">
			<Grid item>
				<Typography gutterBottom variant="h4">
					<FormattedMessage
						id="vendor.wholesale.header-title"
						description="Wholesale overview title"
						defaultMessage="Wholesale"
					/>
				</Typography>
			</Grid>
			<Grid item>
				<PendingButton
					onClick={() => {
						onNew();
					}}
					color="secondary"
					variant="contained"
				>
					<FormattedMessage
						id="vendor.wholesale.new-whole-sale-app"
						description="new wholesale application"
						defaultMessage="New withdrawal"
					/>
				</PendingButton>
			</Grid>
		</Grid>
	);
};

export { WholesaleOverviewHeader };
