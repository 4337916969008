import { Resolve } from "@ploy-lib/types";
import { ResolvedVariable, ResolvedCtrl } from "./types";
import { caseInsensitiveMap, getResolveReference } from "./utils";

export interface FieldResolver<TNamespaces> {
	(ctrl: string, variable: string): ResolvedVariable<TNamespaces> | undefined;
	(ctrl: string): ResolvedCtrl<TNamespaces> | undefined;
}

export function createFieldResolver<TNamespaces>(
	resolves: Partial<Record<string, Resolve<TNamespaces>>>
): FieldResolver<TNamespaces> {
	// TODO: Remove case insensitivity handling when response no longer converts dictionary keys to camelCase.
	const resolveMap = caseInsensitiveMap(resolves);

	function resolve(ctrl: string): ResolvedCtrl<TNamespaces> | undefined;
	function resolve(
		ctrl: string,
		variable: string
	): ResolvedVariable<TNamespaces> | undefined;
	function resolve(ctrl: string, variable?: string) {
		const resolveRef = getResolveReference(ctrl);

		if (resolveRef) {
			const [ref, resolved] = resolveMap.getEntry(resolveRef);

			if (ref && resolved) {
				return {
					resolved: resolved.field,
					namespace: resolved.namespace,
					ref,
					ctrl,
					variable
				};
			}

			console.warn(`Failed to resolve ${resolveRef}`);
		}
	}

	return resolve;
}
