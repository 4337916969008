import { DropzoneOptions } from "react-dropzone";
import { useCallback } from "react";
import { useIntl, defineMessages } from "react-intl";

const imageExtensions = [
	"apng",
	"png",
	"jpg",
	"jpeg",
	"bmp",
	"png",
	"gif",
	"svg",
	"ico",
	"cur",
	"jfif",
	"pjpeg",
	"pjp",
	"webp"
];

export function isImage(filename?: string) {
	const fileName = filename || "";
	const suffix = fileName
		.substring(fileName.lastIndexOf(".") + 1)
		.toLowerCase();
	if (imageExtensions.includes(suffix)) {
		return true;
	}
}

const SIprefixValues = {
	terabyte: 2 ** 40,
	gigabyte: 2 ** 30,
	megabyte: 2 ** 20,
	kilobyte: 2 ** 10
};

const messages = defineMessages({
	mimeTypeError: {
		id: "form_fields.dropzone.mimetype.error",
		defaultMessage:
			"{name}{type}: The file type must be {typesCount, plural, one {} other {one of}} {typesList}"
	},
	tooLargeError: {
		id: "form_fields.dropzone.too_large.error",
		defaultMessage: "{name} ({size}): file cannot be larger than {maxSize}"
	},
	tooSmallError: {
		id: "form_fields.dropzone.too_small.error",
		defaultMessage: "{name} ({size}): file must be larger than {minSize}"
	}
});

export function useFileToError(
	options: DropzoneOptions,
	unit: keyof typeof SIprefixValues = "megabyte"
) {
	const { accept, minSize, maxSize } = options;

	const intl = useIntl();

	return useCallback(
		function (file: File) {
			if (accept) {
				const types = typeof accept === "string" ? accept.split(",") : accept;

				const mimetypes = types.filter(t => t.includes("/"));
				const filetypes = types.filter(t => t.startsWith("."));

				if (
					!filetypes.some(t => file.name.endsWith(t)) &&
					!mimetypes
						.map(t => t.replace("*", ""))
						.some(t => file.type.startsWith(t))
				) {
					return intl.formatMessage(messages.mimeTypeError, {
						name: file.name,
						type: mimetypes.length > 0 && file.type ? ` (${file.type})` : "",
						typesCount: types.length,
						typesList: types.join(", ")
					});
				}
			}

			if (maxSize && maxSize < file.size) {
				return intl.formatMessage(messages.tooLargeError, {
					name: file.name,
					size: intl.formatNumber(file.size / SIprefixValues[unit], {
						// notation: "compact",
						style: "unit",
						unit,
						unitDisplay: "narrow"
					}),
					maxSize: intl.formatNumber(maxSize / SIprefixValues[unit], {
						// notation: "compact",
						style: "unit",
						unit,
						unitDisplay: "narrow"
					})
				});
			}

			if (minSize && minSize > file.size) {
				return intl.formatMessage(messages.tooSmallError, {
					name: file.name,
					size: intl.formatNumber(file.size / SIprefixValues[unit], {
						// notation: "compact",
						style: "unit",
						unit,
						unitDisplay: "narrow"
					}),
					minSize: intl.formatNumber(minSize / SIprefixValues[unit], {
						// notation: "compact",
						style: "unit",
						unit,
						unitDisplay: "narrow"
					})
				});
			}
		},
		[accept, intl, maxSize, minSize, unit]
	);
}
