import { BaseCamelCasedResource } from "../resources/BaseResource";
import { ID } from "@ploy-lib/types";
import { legacyApiResourceUrl, apiResourceUrl } from "@ploy-lib/core";

export interface LinkItem {
	linkId: ID;
	linkText?: string;
	linkUrl: string;
}

export class LinkItemResource
	extends BaseCamelCasedResource
	implements LinkItem
{
	readonly linkId: ID;
	readonly disabled = false;
	readonly linkPublic = false;
	readonly linkEndDate?: string;
	readonly linkStartDate?: string;
	readonly linkText?: string;
	readonly linkTextInternal?: string;
	readonly linkUrl: string;
	readonly sortOrder = 0;

	pk() {
		return `${this.linkId}`;
	}

	static linksForVendor<T extends typeof LinkItemResource>(this: T) {
		return this.list().extend({
			url: (params: any) =>
				legacyApiResourceUrl("Information/GetLinksForVendor")
		});
	}

	static productInfoForUser<T extends typeof LinkItemResource>(this: T) {
		return this.list().extend({
			url: (params: any) =>
				legacyApiResourceUrl("Product/GetProductInfoForUser")
		});
	}

	static urlRoot = apiResourceUrl("links");
}

export type LinkItemListKey = "linksForVendor" | "productInfoForUser";
