import { PendingButtonProps } from "@ploy-ui/core";
import { InputFieldProps } from "../../types";

export const commonButtonColors = [
	"inherit",
	"primary",
	"secondary",
	"default"
] as const;
export type CommonButtonColors = typeof commonButtonColors[number];

export function isButtonColor(color?: string): color is CommonButtonColors {
	return commonButtonColors.includes(color as CommonButtonColors);
}

export const commonButtonVariants = ["text", "outlined", "contained"] as const;
export type CommonButtonVariants = typeof commonButtonVariants[number];

export function isButtonVariant(
	variant?: string
): variant is CommonButtonVariants {
	return commonButtonVariants.includes(variant as CommonButtonVariants);
}

export type ButtonFieldProps = InputFieldProps &
	Pick<PendingButtonProps, "endIcon" | "startIcon"> & {
		disabled?: boolean;
		pending?: boolean;
		success?: boolean;
		showOverlay?: boolean;
		fullWidth?: boolean;
		mailto?: boolean;
		size?: "small" | "medium" | "large";
		muiStartIcon?: string;
		muiEndIcon?: string;
	};
