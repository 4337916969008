import * as zopimBootstrapper from "../utils/ZendeskZopimChat";
import * as unifiedBootstrapper from "../utils/ZendeskUnifiedChat";
import Zendesk from "./Zendesk";
import GoogleTagManagerInitializer from "./GoogleTagManagerInitializer";
import { LoginResource, VulcanDataResource } from "@ploy-lib/rest-resources";
import { useResource, useCache } from "@rest-hooks/core";
import { CookieBotPostGtm, CookieBotPreGtm } from "./CookieBot";
import Giosg from "./Giosg";
import { useContext } from "react";
import { ThirdPartyScriptsContext } from "./ThirdPartyScriptsContext";
export { zendeskIsLoaded } from "../utils/zendeskUtils";

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e: any) {
		return true;
	}
}

export const ThirdPartyScripts = () => {
	const { isAuthenticated, cookiebotId, googleTagManagerCode } =
		useCache(LoginResource.status(), {}) ?? {};

	const isInIframe = inIframe();

	const thirdPartySettings = useContext(ThirdPartyScriptsContext);

	const vulcanData = useResource(
		VulcanDataResource.detail(),
		isAuthenticated && !isInIframe ? {} : null
	);

	const { userFullName, userEmail, roleName, vendorName, customerCategoryId } =
		vulcanData || {};

	const evaluateLogin = () => {
		if (thirdPartySettings.settings.loadZendeskOnLoginPage) {
			return true;
		}
		return isAuthenticated;
	};

	const zendesk = !(
		(thirdPartySettings.settings.loadZendeskChat ||
			thirdPartySettings.settings.loadDNBFIZendesk) &&
		evaluateLogin()
	) ? null : (
		<Zendesk
			accountKey={
				customerCategoryId === 2
					? thirdPartySettings.settings.zendeskAlternativeAccountKey
					: thirdPartySettings.settings.zendeskAccountKey
			}
			username={userFullName}
			email={userEmail}
			tags={[roleName, vendorName, "Vestlandet"]}
			bootstrapper={
				thirdPartySettings.settings.loadDNBFIZendesk
					? unifiedBootstrapper
					: zopimBootstrapper
			}
		/>
	);

	const giosg = !thirdPartySettings.settings.loadGiosgChat ? null : <Giosg />;

	const cookieBotPreGtm = !thirdPartySettings.settings.loadCookieBot ? null : (
		<CookieBotPreGtm />
	);

	const gtm =
		thirdPartySettings.settings.loadGoogleTagManager && googleTagManagerCode ? (
			<GoogleTagManagerInitializer
				CustomerCategoryId={customerCategoryId}
				GoogleTagManagerCode={googleTagManagerCode}
			/>
		) : null;

	const cookieBotPostGtm =
		thirdPartySettings.settings.loadCookieBot && cookiebotId ? (
			<CookieBotPostGtm cookiebotId={cookiebotId} />
		) : null;

	return (
		<>
			{zendesk}
			{giosg}
			{cookieBotPreGtm}
			{gtm}
			{cookieBotPostGtm}
		</>
	);
};
