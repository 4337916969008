import React, { memo, useEffect, useState } from "react";
import { DashboardContentProps } from "./types";
import { useSnackbar, VariantType } from "notistack";
import { GridItem } from "./resources/DashboardGridResource";

import { GridItemEditor } from "./GridItemEditor";
import { Box, IconButton } from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import { PendingIconButton } from "@ploy-ui/core";
import { GridItemWrapper } from "./GridItemWrapper";
import { useIntl } from "react-intl";
import { defineMessage } from "@formatjs/intl";

interface SnackbarMessageProps {
	children: React.ReactNode;
	variant?: VariantType;
}

export function SnackbarMessage({ children, variant }: SnackbarMessageProps) {
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		enqueueSnackbar(children, {
			variant
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
}

const ContentGridItemImpl = (
	props: GridItem & {
		component: Required<GridItem>["component"];
		editable?: boolean;
		onSave?: (item: GridItem) => Promise<any> | void;
		onRemove?: (id: GridItem["id"]) => Promise<any> | void;
	} & DashboardContentProps
) => {
	const { component, editable, onSave, onRemove, properties, id, ...rest } =
		props;

	const [open, setOpen] = useState(false);

	const intl = useIntl();

	let boxProps = { ...props.boxProps, ...properties.boxProps };
	if (editable && (onSave || onRemove)) {
		boxProps = {
			...boxProps,
			headerAction: (
				<Box display="flex">
					{onRemove && (
						<PendingIconButton
							title={intl.formatMessage(
								defineMessage({
									id: "dploy.grid.item.delete.title",
									defaultMessage: "Delete"
								})
							)}
							size="small"
							onClick={() => onRemove(id)}
						>
							<DeleteIcon />
						</PendingIconButton>
					)}
					{onSave && (
						<IconButton
							title={intl.formatMessage(
								defineMessage({
									id: "dploy.grid.item.settings.title",
									defaultMessage: "Settings"
								})
							)}
							size="small"
							onClick={() => setOpen(true)}
						>
							<SettingsIcon />
						</IconButton>
					)}
				</Box>
			)
		};
	}

	return (
		<>
			{onSave && (
				<GridItemEditor
					open={open}
					onClose={() => setOpen(false)}
					component={component}
					initialValues={properties}
					onSubmit={async values => {
						await onSave({ id, component, properties: values });
						setOpen(false);
					}}
				/>
			)}
			<GridItemWrapper
				{...rest}
				{...properties}
				component={component}
				properties={properties}
				id={id}
				boxProps={boxProps}
			/>
		</>
	);
};

ContentGridItemImpl.displayName = "DployContentGridItem";

export const ContentGridItem = memo(
	ContentGridItemImpl
) as typeof ContentGridItemImpl;
