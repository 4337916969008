import React, { useMemo, Fragment } from "react";
import {
	ListItemText,
	ListItem,
	ListSubheader,
	Typography,
	Paper,
	PaperProps
} from "@material-ui/core";
import List, { ListProps } from "@material-ui/core/List";
import { useFormikContext, getIn } from "formik";
import { isNotNull } from "@ploy-lib/calculation";
import { usePages } from "../pagesContext";
import { usePageState } from "../PageContext";
import { isNotLiteral, mapToPaths } from "../utils";
import { getFieldName } from "@ploy-lib/core";
import {
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible
} from "../hooks";

export function FormErrors(
	props: ListProps & { elevation?: PaperProps["elevation"] }
) {
	const { elevation, ...rest } = props;

	const { touched, errors } = useFormikContext();

	const { step, goto } = usePageState();
	const pages = usePages();
	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();

	const activePageFields = useMemo(
		() =>
			new Map(
				Object.values(pages[step].panels)
					.filter(isNotLiteral)
					.flatMap(panel => panel.sections || [])
					.filter(isNotLiteral)
					.filter(sectionIsVisible)
					.flatMap(section => section.fields)
					.filter(isNotLiteral)
					.filter(fieldIsVisible)
					.map(field => [getFieldName(field), field] as [string, typeof field])
			),
		[fieldIsVisible, sectionIsVisible, pages, step]
	);

	const allFieldsWithStep = useMemo(() => {
		const fieldsWithStep = pages.flatMap((page, i) =>
			Object.values(page.panels)
				.filter(isNotLiteral)
				.flatMap(panel => panel.sections || [])
				.filter(isNotLiteral)
				.flatMap(section => section.fields)
				.filter(isNotLiteral)
				.map(field => ({ field, fieldStep: i }))
		);
		return new Map(
			fieldsWithStep.map(
				fieldWithStep =>
					[getFieldName(fieldWithStep.field), fieldWithStep] as [
						string,
						typeof fieldWithStep
					]
			)
		);
	}, [pages]);

	const formErrorsToDisplay = useMemo(() => {
		return mapToPaths(touched)
			.map(path => {
				if (activePageFields.has(path)) return null;

				const fieldWithStep = allFieldsWithStep.get(path);
				if (!fieldWithStep) return null;

				const { field, fieldStep } = fieldWithStep;
				if (!fieldIsVisible(field) || fieldStep > step) return null;

				const message = getIn(errors, path);
				if (!message) return null;

				const label = field.label ? field.label : path;
				return { message, label, path, fieldStep };
			})
			.filter(isNotNull)
			.reduce(
				(acc, item) => {
					acc[item.fieldStep] = acc[item.fieldStep]
						? [...acc[item.fieldStep], item]
						: [item];
					return acc;
				},
				{} as Record<
					string,
					{
						message: string;
						label: string;
						path: string;
						fieldStep: number;
					}[]
				>
			);
	}, [
		activePageFields,
		allFieldsWithStep,
		errors,
		fieldIsVisible,
		step,
		touched
	]);

	const entries = Object.entries(formErrorsToDisplay);

	return entries.length === 0 ? null : (
		<Paper square elevation={elevation}>
			<List dense {...rest}>
				{entries.map(([stepKey, items]) => (
					<Fragment key={stepKey}>
						<ListSubheader>{pages[stepKey].label}</ListSubheader>
						{items.map(({ label, message, path, fieldStep }) => (
							<ListItem key={path} button onClick={() => goto(fieldStep)}>
								<ListItemText
									primary={message}
									primaryTypographyProps={{ color: "error" }}
								/>
								<Typography>{label}</Typography>
							</ListItem>
						))}
					</Fragment>
				))}
			</List>
		</Paper>
	);
}

FormErrors.displayName = "DployFormErrors";

export default FormErrors;
