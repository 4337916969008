import { useState } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

import ContentBox from "../../ContentBox";
import DetailsBox from "../../DetailsBox";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { RecentApplicationResource } from "@ploy-lib/rest-resources";
import { useCache } from "@rest-hooks/core";
import { GridComponent } from "../types";
import { RecentApplicationsList } from "./RecentApplicationsList";

export interface RecentApplicationsProps {
	includeRejected?: boolean | "0" | "1";
	showDocumentStatus?: boolean | "0" | "1";
}

const RecentApplications: GridComponent<RecentApplicationsProps> = props => {
	const {
		className,
		boxProps,
		includeRejected,
		applicationOpenPath,
		showDocumentStatus,
		applicationsSearchPath,
		placeholder
	} = props;
	const [selected, setSelected] = useState<string>();

	const classes = useStyles(props);

	const selectedApp = useCache(
		RecentApplicationResource.detail(),
		selected
			? {
					applicationNumber: selected
			  }
			: null
	);

	if (selectedApp) {
		return (
			<DetailsBox
				className={className}
				avatar={<DescriptionOutlinedIcon />}
				iconClass="file-o"
				{...boxProps}
				onClick={() => setSelected(undefined)}
				actions={
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						to={`${applicationOpenPath}/${selectedApp.applicationNumber}`}
						disabled={!applicationOpenPath}
					>
						<FormattedMessage
							id="dealer.dashboard.contextlist.gotoapp"
							description="Label for go to application"
							defaultMessage="Go to application"
						/>
					</Button>
				}
			>
				<article>
					<Typography variant="subtitle1" className={classes.detailsHeader}>
						{selectedApp.status}
					</Typography>
					<Typography variant="h6" className={classes.detailsHeader}>
						{selectedApp.customerName}
					</Typography>
					<Typography paragraph>
						<FormattedMessage
							id="dealer.dashboard.contextlist.appnumber"
							description="Label for application number. Params:"
							defaultMessage="Case number {applicationNumber}"
							values={{
								applicationNumber: selectedApp.applicationNumber
							}}
						/>
					</Typography>

					<Typography variant="caption" className={classes.detailsHeaderSmall}>
						<FormattedMessage
							id="dealer.dashboard.contextlist.product"
							description="Label for product"
							defaultMessage="Product"
						/>
					</Typography>
					<Typography gutterBottom>{selectedApp.productName}</Typography>

					<Typography variant="caption" className={classes.detailsHeaderSmall}>
						<FormattedMessage
							id="dealer.dashboard.contextlist.object"
							description="Label for object"
							defaultMessage="Asset"
						/>
					</Typography>
					<Typography gutterBottom>{selectedApp.category}</Typography>

					<Typography variant="caption" className={classes.detailsHeaderSmall}>
						<FormattedMessage
							id="dealer.dashboard.contextlist.documentstatus"
							description="Label for document status"
							defaultMessage="Document status"
						/>
					</Typography>
					<Typography gutterBottom>{selectedApp.documentStatus}</Typography>
				</article>
			</DetailsBox>
		);
	}

	return (
		<ContentBox
			className={className}
			avatar={<DescriptionOutlinedIcon />}
			actions={
				<Button
					color="primary"
					component={Link}
					to={applicationsSearchPath ?? ""}
					disabled={!applicationsSearchPath}
				>
					<FormattedMessage
						id="dealer.dashboard.contextlist.allapplications"
						description="Label for go to all applications button"
						defaultMessage="View all applications"
					/>
				</Button>
			}
			{...boxProps}
		>
			<RecentApplicationsList
				includeRejected={
					typeof includeRejected === "string"
						? includeRejected === "1"
						: includeRejected
				}
				showDocumentStatus={
					typeof showDocumentStatus === "string"
						? showDocumentStatus === "1"
						: showDocumentStatus
				}
				onSelect={setSelected}
				placeholder={placeholder}
			/>
		</ContentBox>
	);
};

const useStyles = makeStyles(
	{
		detailsHeader: {},
		detailsHeaderSmall: {}
	},
	{ name: "DashboardContextList" }
);

RecentApplications.displayName = "DployDashboardRecentApplications";

export default RecentApplications;
