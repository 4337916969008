import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";

import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const mapStatusIdToIcon = (statusId: number | string) => {
	switch (statusId && statusId.toString()) {
		case "17": // Saved
			return SaveIcon;
		case "4": // Manual
			return VisibilityIcon;
		case "3":
		case "23": // Awaiting
			return HourglassEmptyIcon;
		case "5":
		case "6":
		case "14": // Approved
			return CheckCircleIcon;
		case "7":
		case "8": // Rejected
			return CancelIcon;
		case "10": // Paid
			return DoneIcon;
		case "9":
		case "13": // Cancelled
			return DeleteIcon;
		case "2": // Sent (service)
			return MailOutlineIcon;
		default:
			return MailOutlineIcon;
	}
};

interface StatusIconProps {
	status: number | string;
}

const StatusIcon = ({ status, ...props }: StatusIconProps & BoxProps) => {
	return (
		<Box
			color={`status.${status}`}
			component={mapStatusIdToIcon(status)}
			{...props}
		/>
	);
};

StatusIcon.displayName = "DployStatusIcon";

export { StatusIcon };
