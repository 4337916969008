import { cssContains } from "./utils";
import {
	TemplateField,
	FieldWidth,
	FieldSave,
	ErrorDisplay,
	mapFieldWidthToGridSize
} from "@ploy-lib/types";
import { v4 as uuidv4 } from "uuid";

const defaultToFieldProp = <T>(prop: T | null | undefined, defaultProp: T) =>
	prop != null ? prop : defaultProp;

export function mapFieldToWidth(
	hasStyle: (style: string) => boolean
): FieldWidth | undefined {
	if (hasStyle("full-width")) return "12";
	if (hasStyle("x-large-field")) return "8";
	if (hasStyle("large-field")) return "5";
	if (hasStyle("medium-field")) return "4";
	if (hasStyle("small-field")) return "2";
	if (hasStyle("side-aligned")) return "6";
	if (hasStyle("auto-field")) return "auto";
	return;
}

export function mapFieldToLiteralVariant(
	hasStyle: (style: string) => boolean
): string | undefined {
	if (hasStyle("selected-result-item")) return "contained";
	return;
}

export function fieldToComponent(templateField: TemplateField): TemplateField {
	let {
		label,
		renderAs,
		variant,
		multiple,
		horizontal,
		manual,
		color,
		width,
		valueSuffix,
		hiddenLabel
	} = templateField;

	const hasStyle = cssContains(templateField.modifier);
	var save = undefined as FieldSave | undefined;

	if (renderAs) {
		const renderAsTypes = renderAs.split("|");
		renderAs = renderAsTypes.length > 1 ? renderAsTypes[1] : renderAsTypes[0];
		const maybeUpdate = renderAsTypes.length > 1 ? renderAsTypes[0] : undefined;

		switch (renderAs) {
			case "BisnodeSearch":
				variant = "input";
				break;
			case "DropdownList":
				renderAs = "SelectField";
				break;
			case "SearchField":
				renderAs = "AutocompleteField";
				variant = "input";
			// eslint-disable-next-line no-fallthrough
			case "LinkButton":
			case "CustomButton":
			case "Button":
			case "OpenModalButton":
				if (renderAs === "CustomButton") {
					renderAs = "ButtonLink";
					variant = "contained";
				} else if (renderAs === "LinkButton") {
					renderAs = "ButtonLink";
					variant = "text";
				} else
					variant = variant || (hasStyle("transparent") ? "text" : "contained");

				if (renderAs === "Button") renderAs = "ButtonField";

				color = hasStyle("button--accent")
					? "secondary"
					: hasStyle("button--white")
					? "white"
					: "primary";

				if (hasStyle("star-button")) {
					width = "auto";
					color = "secondary";
				}
				hiddenLabel = false;
				break;
			case "Radio":
			case "RadioButtonGroup":
			case "RadioGroup": {
				renderAs = "SelectionField";
				if (hasStyle("is-button")) variant = "button";
				if (hasStyle("horizontal")) horizontal = true;
				break;
			}
			case "Checkbox":
				if (hasStyle("star-button")) {
					variant = variant || "star";
				}
				width = "auto";
				renderAs = "CheckboxWithLabelField";
				break;
			case "Fulfillment":
			case "FulfillmentReadOnly":
			case "Refinancing":
			case "RefinancingReadOnly":
				variant = variant || "contained";
				color = "primary";
				break;
			case "NotSet":
				renderAs = hasStyle("numeric-field") ? "NumberField" : "TextField";

				if (
					valueSuffix &&
					["eur", "€", "kr"].includes(valueSuffix.toLowerCase())
				) {
					renderAs = "CurrencyField";
					valueSuffix = undefined;
				}

				if (valueSuffix === "%") {
					renderAs = "PercentField";
					valueSuffix = undefined;
				}

				break;
			case "DatePicker":
				renderAs = "DatePickerField";
				break;
			case "Date":
				renderAs = "KeyboardDatePickerField";
				break;
			case "SelectListWithEdit":
				renderAs = "DriverSelect";
				variant = "input";
				break;
			case "FieldWithUpdate":
				renderAs = undefined;
				save = { type: "button" };
		}

		if (maybeUpdate && maybeUpdate === "FieldWithUpdate") {
			save = { type: "button" };
		}
	}

	if (
		templateField.optionSource &&
		templateField.optionSource.url &&
		renderAs !== "BisnodeSearch"
	) {
		renderAs = "AutocompleteField";
		manual = true;
	}

	if (templateField.searchable) {
		renderAs = "AutocompleteField";
	}

	return {
		...templateField,
		label,
		renderAs,
		variant,
		multiple,
		horizontal,
		manual,
		color,
		width,
		valueSuffix,
		hiddenLabel,
		save
	};
}

function fieldStyleToOptions(templateField: TemplateField): TemplateField {
	const hasStyle = cssContains(templateField.modifier);

	const alignWithPrevious =
		hasStyle("side-aligned") && !hasStyle("side-aligned-left");

	return {
		...templateField,
		alwaysVisible: defaultToFieldProp(
			templateField.alwaysVisible,
			hasStyle("always-show")
		),
		width: defaultToFieldProp(templateField.width, mapFieldToWidth(hasStyle)),
		literalVariant: defaultToFieldProp(
			templateField.literalVariant,
			mapFieldToLiteralVariant(hasStyle)
		),
		hiddenLabel: defaultToFieldProp(
			templateField.hiddenLabel,
			hasStyle("hide-label") || alignWithPrevious
		),
		uppercaseLabel: defaultToFieldProp(
			templateField.uppercaseLabel,
			hasStyle("uppercase")
		),
		alignWithPrevious: defaultToFieldProp(
			templateField.alignWithPrevious,
			alignWithPrevious
		),
		disabled: defaultToFieldProp(templateField.disabled, hasStyle("read-only"))
	};
}

export function fieldPropRename(templateField: TemplateField): TemplateField {
	const { valueSuffix, tooltip, hideErrorWhenLiteral, ...field } =
		templateField;

	return {
		...field,

		// TODO: hideErrorWhenLiteral is deprecated and will be removed, ErrorDisplay.Never will be the new default
		errorDisplayLiteral:
			field.errorDisplayLiteral ??
			(!hideErrorWhenLiteral ? ErrorDisplay.Touched : ErrorDisplay.Never),

		suffix: defaultToFieldProp(field.suffix, valueSuffix),
		helperText: defaultToFieldProp(field.helperText, tooltip)
	};
}

function fieldRemoveDeadProps(field: TemplateField): TemplateField {
	const {
		modifier,
		valueSuffix,
		tooltip,
		hideErrorWhenLiteral,
		...updatedField
	} = field;

	return updatedField;
}

type OldWidthFormat = "full" | "wide" | "half" | "large" | "medium" | "small";

// Uses conversion rules as if we were converting to the sm-width, not xs
const oldWidthToNewWidth: Record<OldWidthFormat, FieldWidth> = {
	full: "12",
	wide: "8",
	half: "6",
	large: "5",
	medium: "4",
	small: "2"
};

function fieldWidthConversion(field: TemplateField): TemplateField {
	// Skip conversion if width is valid
	if (!field.width || mapFieldWidthToGridSize(field.width) != null)
		return field;

	const oldWidth = field.width as OldWidthFormat;

	return {
		...field,
		width: oldWidthToNewWidth[oldWidth]
	};
}

export function convertTemplateField(
	templateField: TemplateField,
	skipConversion: string[] = []
): TemplateField {
	const { renderAs } = templateField;
	let field = templateField;

	if (!renderAs || !skipConversion.includes(renderAs)) {
		field.formTemplateFieldId = field.formTemplateFieldId || uuidv4();
		field = fieldToComponent(field);
		field = fieldStyleToOptions(field);
	}

	field = fieldPropRename(field);
	field = fieldRemoveDeadProps(field);
	field = fieldWidthConversion(field);

	return field;
}
