import React, { memo, useEffect } from "react";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { TemplateField } from "@ploy-lib/types";
import { OptionalConsentsResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { useDispatch } from "@ploy-lib/calculation";
import { Section } from "..";
import { ErrorHandler } from "@ploy-lib/core";
import { ErrorPageContent } from "@ploy-ui/core";
import { SectionProps } from "../Section";

addRegisteredSectionLayout({
	name: "OptionalConsentsSection",
	displayName: "OptionalConsentsSection",
	settings: {}
});

const OptionalConsentsSectionInternal = memo(props => {
	const dispatch = useDispatch();
	var optionalConsents = useResource(OptionalConsentsResource.list(), {});

	useEffect(() => {
		dispatch({
			type: "field_patch",
			payload: {
				patches: [
					{
						fieldName: `consents`,
						namespace: "AdditionalSubmitValues",
						initialValue: optionalConsents.reduce(
							(obj, c) => ({ ...obj, [c.id]: false }),
							{}
						)
					}
				]
			}
		});
	}, [dispatch, optionalConsents]);

	var fields = optionalConsents.map(
		c =>
			({
				formTemplateFieldId: "consents." + c.id,
				namespace: "AdditionalSubmitValues",
				name: "consents." + c.id,
				renderAs: "CheckboxWithLabelField",
				label: c.description,
				alwaysVisible: true
			} as TemplateField)
	);

	return (
		<Section {...props} formTemplateSectionId={"consents"} fields={fields} />
	);
});

const OptionalConsentsSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={
					<span>{OptionalConsentsSection.displayName} failed to render.</span>
				}
			/>
		)}
	>
		<OptionalConsentsSectionInternal {...props} />
	</ErrorHandler>
);

OptionalConsentsSection.displayName = "OptionalConsentsSection";

export { OptionalConsentsSection };
