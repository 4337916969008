import { BaseCamelCasedResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface WholesaleLimit {
	vendorId: number;
	countNotApproved: string;
	customerId: string;
	customerName: string;
	limit: string;
	limitRemaining: string;
	other: string;
	sumApproved: string;
	sumView: string;
}

export class WholesaleLimitResource
	extends BaseCamelCasedResource
	implements WholesaleLimit
{
	readonly vendorId: number;
	readonly countNotApproved: string;
	readonly customerId: string;
	readonly customerName: string;
	readonly limit: string;
	readonly limitRemaining: string;
	readonly other: string;
	readonly sumApproved: string;
	readonly sumView: string;

	pk() {
		return `${this.customerId}`;
	}

	static urlRoot = apiResourceUrl("wholesaleLimit/{vendorId}");
}
