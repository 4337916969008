import { useEffect } from "react";
import {
	Resolve,
	Variable,
	Validator,
	TemplateField,
	Service
} from "@ploy-lib/types";
import { GeneratedCalcRule, Namespaced, FunctionTypes } from "../../calculator";
import { createReducer, initialState } from "../../state/reducer";
import { useRenderReducer } from "./useRenderReducer";
import { useCreatedCalculator } from "./useCreatedCalculator";
import { ServiceBodyField } from "../../types";
import { ValidationHelpers } from "@ploy-lib/validation-helpers";

export function useCalculation<
	TN extends string,
	TD extends string | number | boolean
>(
	cgfMap: Record<string, TemplateField>,
	calcRules: Partial<Record<TN, GeneratedCalcRule<TD>>>,
	validationHelpers: ValidationHelpers,
	variables: Record<TN, Variable[]>,
	validators: Record<TN, Validator[]>,
	functions: FunctionTypes[],
	services: Record<TN, Service[]>,
	resolves?: Partial<Record<string, Resolve<TN>>>,
	macros?: Record<TN, Partial<Record<string, number>>>,
	formValues?: Partial<Namespaced<TD, TN>>,
	initialWriteLocked?: Partial<Namespaced<boolean | null, TN>>,
	initialVisible?: Partial<Namespaced<boolean, TN>>,
	initialValues?: Partial<Namespaced<TD, TN>>,
	serviceBodyFields?: Partial<Record<TN, ServiceBodyField[]>>,
	namespaces?: TN[],
	clear?: TN[]
) {
	const calculator = useCreatedCalculator(
		cgfMap,
		calcRules,
		validationHelpers,
		variables,
		validators,
		functions,
		services,
		resolves,
		macros,
		serviceBodyFields
	);

	const reducer = createReducer(
		calculator,
		initialWriteLocked,
		initialVisible,
		initialValues
	);

	const [state, dispatch] = useRenderReducer(
		reducer,
		formValues && [
			{ type: "calculate_and_validate", payload: { formValues } },
			{ type: "update_service_triggers" }
		],
		initialState,
		[formValues]
	);

	useEffect(() => {
		dispatch({
			type: "reinit",
			payload: { namespaces, clear }
		});
	}, [calculator, dispatch, namespaces, clear]);

	return [state, dispatch] as const;
}
