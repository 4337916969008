import { memo, useCallback, useMemo } from "react";
import { ErrorPageContent } from "@ploy-ui/core";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useDispatch, useVariableData } from "@ploy-lib/calculation";
import { ErrorHandler } from "@ploy-lib/core";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Grid,
	lighten,
	makeStyles,
	Typography
} from "@material-ui/core";
import { FormattedMessage, FormattedNumber } from "react-intl";
import clsx from "clsx";

addRegisteredSectionLayout({
	name: "PropertiesSection",
	displayName: "PropertiesSection",
	settings: {
		editableOptions: {}
	}
});

const useStyles = makeStyles(
	theme => ({
		cardBorder: {
			borderColor: theme.palette.secondary.main
		},
		selectedCard: {
			backgroundColor: lighten(theme.palette.secondary.main, 0.9)
		},
		houseBox: {
			padding: theme.spacing(1),
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column"
			},
			[theme.breakpoints.up("xs")]: { flexDirection: "row-reverse" }
		},
		svg: {
			stopColor: theme.palette.success.light,
			stroke: theme.palette.grey[500]
		}
	}),
	{ name: "PropertiesSection" }
);

interface Property {
	propertyId: string;
	streetName: string;
	addressNumber: number;
	addressLetter: string;
	municipalityNumber: number;
	postalCode: string;
	postalPlace: string;
	refinance: boolean;
	valueEnteredByCustomer: number;
	estimatedValue: number;
	pledgeValue: number;

	cadastralUnitNumber: number; // Only for cadastres

	housingCooperativeName: string; // Only for shares
	jointDebt: number; // Only for shares
}

const PropertiesSectionInternal = memo((props: SectionProps) => {
	const styles = useStyles(props);
	let { value: properties } = useVariableData<Property[]>(
		"Calculator",
		"PROPERTIES"
	);
	if (typeof properties === "string") properties = [];
	const dispatch = useDispatch();

	const updateProperties = useCallback(
		newProperties =>
			dispatch({
				type: "patch",
				payload: {
					patches: [
						{
							target: "PROPERTIES",
							namespace: "Calculator",
							value: [...newProperties],
							overwrite: true
						}
					]
				}
			}),
		[dispatch]
	);

	const onCardSelect = useCallback(
		(property: Property) => {
			const newProperties = [...properties!].map(p => {
				if (property.propertyId === p.propertyId) p.refinance = !p.refinance;
				return p;
			});
			updateProperties(newProperties);
		},
		[properties, updateProperties]
	);

	var propertyCards = useMemo(
		() =>
			properties?.map(p => {
				if (!p.pledgeValue) p.pledgeValue = 0;
				var name = "";
				if (p.housingCooperativeName)
					name = `${p.housingCooperativeName} ${p.addressNumber}`;
				else name = `${p.streetName} ${p.addressNumber} ${p.addressLetter}`;
				return (
					<Grid item xs={12} key={p.propertyId}>
						<Card
							variant="outlined"
							className={clsx(styles.cardBorder, {
								[styles.selectedCard]: p.refinance
							})}
						>
							<Grid
								container
								alignItems="center"
								alignContent="center"
								justifyContent="center"
								className={clsx(styles.houseBox)}
							>
								<Grid item xs={12} sm={9}>
									<CardHeader
										action={
											<Checkbox
												checked={!!p.refinance}
												onChange={() => onCardSelect(p)}
											/>
										}
										disableTypography
										title={<Typography variant="h5">{name}</Typography>}
										subheader={
											<Typography variant="subtitle1">
												{p.postalCode + " " + p.postalPlace}
											</Typography>
										}
									/>

									<CardContent>
										{p.jointDebt && (
											<Grid container>
												<Grid item xs={6}>
													<Typography variant="body2">
														<FormattedMessage
															id="dploy.properties-section.jointDebt"
															defaultMessage="Joint debt"
															description="Equivalent to Fellesgjeld in Norwegian"
														/>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant="body2">
														<FormattedNumber value={p.jointDebt} />
													</Typography>
												</Grid>
											</Grid>
										)}
										<Grid container>
											<Grid item xs={6}>
												<Typography variant="body2">
													<FormattedMessage
														id="dploy.properties-section.housingValue"
														defaultMessage="Housing value"
														description="Equivalent to Boligverdi in Norwegian"
													/>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body2">
													<FormattedNumber
														value={
															p.valueEnteredByCustomer > 0
																? p.valueEnteredByCustomer
																: p.estimatedValue
														}
													/>
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={6}>
												<Typography variant="body2">
													<FormattedMessage
														id="dploy.properties-section.mortgageValue"
														defaultMessage="Mortgage value"
														description="Equivalent to Panteverdi in Norwegian"
													/>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body2">
													<FormattedNumber
														value={p.pledgeValue}
														format="currency"
													/>
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={6}>
												<Typography variant="body2">
													<FormattedMessage
														id="dploy.properties-section.loanToValueRatio"
														defaultMessage="Loan-to-value ratio"
														description="Equivalent to Belåningsgrad in norwegian"
													/>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body2">
													<FormattedNumber
														value={(p.pledgeValue / p.estimatedValue) * 100}
														style="percent"
														maximumFractionDigits={0}
													/>
												</Typography>
											</Grid>
										</Grid>
									</CardContent>
								</Grid>
								<Grid
									item
									xs={12}
									sm={3}
									alignItems="center"
									alignContent="center"
									justifyContent="center"
								>
									<Box margin="auto" maxWidth={"160px"} minWidth={"140px"}>
										<LoanHouseSVG
											pledgeValue={p.pledgeValue}
											estimatedValue={p.estimatedValue}
											maxLoanPercentage={0.65}
											id={p.propertyId}
										/>
									</Box>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				);
			}),
		[onCardSelect, properties, styles]
	);

	return (
		<Grid container spacing={1}>
			{propertyCards}
		</Grid>
	);
});

const PropertiesSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={<span>{PropertiesSection.displayName} failed to render.</span>}
			/>
		)}
	>
		<PropertiesSectionInternal {...props} />
	</ErrorHandler>
);

PropertiesSection.displayName = "PropertiesSection";

export { PropertiesSection };

interface LoanHouseSVGProps {
	pledgeValue: number;
	estimatedValue: number;
	maxLoanPercentage: number;
	id: string;
}

const LoanHouseSVG = (props: LoanHouseSVGProps) => {
	const styles = useStyles(props);
	let loanPercentage = props.pledgeValue / props.estimatedValue;
	if (loanPercentage > 0.65) {
		loanPercentage = 0.65;
	}
	return (
		<svg
			version="1.1"
			viewBox="0 0 140 150"
			role="img"
			aria-labelledby={props.id + "-title " + props.id + "-desc"}
		>
			<title id={props.id + "-title"}>
				<FormattedMessage
					id="dploy.properties-section.houseVisualDisplayTitle"
					defaultMessage="Visual overview of loan-to-value ratio"
					description="Title of visual equivalent of Belåningsgrad in Norwegian"
				/>
			</title>
			<desc id={props.id + "-desc"}>
				<FormattedMessage
					id="dploy.properties-section.houseVisualDisplayDesc"
					defaultMessage="A house filling up from the bottom with a green gradient displaying the current loan-to-value ratio, as well as an indicator for the max possible ratio."
					description="Explanation of SVG"
				/>
			</desc>
			{loanPercentage > 0 && (
				<linearGradient id="Gradient2" x1="1" x2="1" y1="1" y2="0">
					<stop className={clsx(styles.svg)} offset="0%" />
					<stop className={clsx(styles.svg)} offset={loanPercentage} />
					<stop stop-opacity="0" offset={loanPercentage} />
					<stop stop-opacity="0" offset="100%" />
				</linearGradient>
			)}
			<polygon //Outline
				points="60 40 20 76 20 140 100 140 100 76"
				className={clsx(styles.svg)}
				fill="transparent"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<polygon //Fill
				points="60 44 23 78 23 137 97 137 97 78"
				stroke-width="0"
				fill="url(#Gradient2)"
				stroke-linejoin="round"
			/>
			<line //Dashed line across
				x1="15"
				y1={137 - (95 - 0.35 * 100)}
				x2="105"
				y2={137 - (95 - 0.35 * 100)}
				stroke-dasharray="2"
				stroke="black"
			/>
			<text x="60" y="25" textAnchor="middle">
				<FormattedMessage
					id="dploy.properties-section.houseVisualDisplay"
					defaultMessage="Loan-to-value ratio"
					description="Display title for visual loan-to-value ratio display"
				/>
			</text>
			<text x="60" y={136 - (88 - 0.6 * 100)} textAnchor="middle">
				<FormattedNumber
					value={loanPercentage}
					style="percent"
					maximumFractionDigits={1}
				/>
			</text>
			<text x="106" y={142 - (95 - 0.35 * 100)} textAnchor="start">
				<FormattedNumber
					value={props.maxLoanPercentage}
					style="percent"
					maximumFractionDigits={0}
				/>
			</text>
		</svg>
	);
};
