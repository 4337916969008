const _registeredSteppers: Record<string, RegisteredStepper> = {};

export interface RegisteredStepper {
	name: string;
	displayName: string;
}

export function getRegisteredSteppers(): Readonly<typeof _registeredSteppers> {
	return _registeredSteppers;
}

export function hasRegisteredStepper(name: string): boolean {
	return _registeredSteppers.hasOwnProperty(name);
}

export function addRegisteredStepper(registeredStepper: RegisteredStepper) {
	_registeredSteppers[registeredStepper.name] = registeredStepper;
}
