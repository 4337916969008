import React from "react";
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuList,
	Icon,
	makeStyles,
	Divider,
	Button,
	MenuItem,
	MenuItemProps
} from "@material-ui/core";
import { navMigration } from "../routeMigration";
import clsx from "clsx";
import {
	ChevronRight as ChevronRightIcon,
	Close as CloseIcon
} from "@material-ui/icons";
import { RouterMenuItem, RouterMenuItemProps } from "@ploy-ui/core";
import { MenuItemSub as MenuItemType } from "@ploy-lib/rest-resources";
import { ReleaseInfo } from "./ReleaseInfo";
import { LanguageSelector } from "../../LanguageSelector";
import { useIntl } from "react-intl";
import { messages } from "./MenuItemList";

import PersonIcon from "@material-ui/icons/Person";

export interface FixedBottomMenuItemListProps {
	menuItems: MenuItemType[];
	closeDrawer: () => void;
}

export const FixedBottomMenuItemList = (
	props: FixedBottomMenuItemListProps
) => {
	const { menuItems } = props;

	const intl = useIntl();

	return (
		<div>
			<MenuList>
				{menuItems.map(
					({
						menuItemName,
						icon,
						menuItemLabel,
						navigateUrl,
						navigateUrlexternal
					}) => (
						<LinkMenuItem
							key={menuItemName}
							href={navigateUrlexternal}
							target={navigateUrlexternal && "_blank"}
							to={
								navigateUrlexternal
									? undefined
									: `/${
											navMigration[navigateUrl as keyof typeof navMigration] ||
											navigateUrl
									  }`
							}
							primary={menuItemLabel}
							iconClass={icon}
						/>
					)
				)}
			</MenuList>

			<Divider />

			<MenuList>
				<LinkMenuItem
					to={`/profile`}
					primary={intl.formatMessage(messages.Profile)}
					icon={<PersonIcon />}
				/>

				<LinkMenuItem
					to={`/logout`}
					primary={intl.formatMessage(messages.Logout)}
					iconClass="sign-out"
				/>

				<LanguageSelector />

				<ListItem>
					<ListItemText>
						<ReleaseInfo />
					</ListItemText>
					<Button onClick={() => props.closeDrawer()}>
						<CloseIcon style={{ fontSize: "2rem" }} />
					</Button>
				</ListItem>
			</MenuList>
		</div>
	);
};

interface LinkMenuItemProps {
	iconClass?: string;
	icon?: React.ReactNode;
	primary?: React.ReactNode;
}

const LinkMenuItem = ({
	iconClass,
	icon,
	primary,
	...props
}: LinkMenuItemProps & (RouterMenuItemProps | MenuItemProps<"a">)) => {
	const classes = useStyles();

	if ("to" in props && props.to != null)
		return (
			<RouterMenuItem {...props}>
				<ListItemIcon className={classes.icon}>
					{icon ?? (
						<Icon className={clsx(`fa fa-${iconClass}`, classes.icon)} />
					)}
				</ListItemIcon>
				<ListItemText primary={primary} />
				<ChevronRightIcon />
			</RouterMenuItem>
		);

	if ("href" in props)
		return (
			<MenuItem component="a" {...(props as any)}>
				<ListItemIcon className={classes.icon}>
					{icon ?? (
						<Icon className={clsx(`fa fa-${iconClass}`, classes.icon)} />
					)}
				</ListItemIcon>
				<ListItemText primary={primary} />
				<ChevronRightIcon />
			</MenuItem>
		);

	return null;
};

const useStyles = makeStyles({
	root: {},
	selected: {},
	icon: {
		overflow: "initial",
		textAlign: "center"
	},
	blackText: {
		color: "black"
	}
});
