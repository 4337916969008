import React, {
	useMemo,
	useRef,
	useReducer,
	useEffect,
	useCallback
} from "react";
import { isNotNull } from "../../calculator/utils";
import { batch } from "../../utils";

export enum DispatchPriority {
	Normal = "Normal",
	Defer = "Defer"
}

export type DispatchWithPriority<A> = (
	action: A,
	priority?: DispatchPriority
) => void;

export function useRenderReducer<R extends React.Reducer<any, any>>(
	reducer: R,
	renderActions: React.ReducerAction<R>[] = [],
	initialState: React.ReducerState<R>,
	deps: React.DependencyList = []
): [React.ReducerState<R>, DispatchWithPriority<React.ReducerAction<R>>] {
	const handledRef = useRef<React.ReducerAction<R>[]>([]);
	const isHandled = (actions: React.ReducerAction<R>[]) =>
		actions === handledRef.current;

	const [actions, dispatch] = useReducer(
		(state: React.ReducerAction<R>[], action: React.ReducerAction<R>) =>
			isHandled(state) ? [action] : [...state, action],
		handledRef.current
	);

	const deferredHandledRef = useRef<React.ReducerAction<R>[]>([]);
	const deferredIsHandled = useCallback(
		(actions: React.ReducerAction<R>[]) =>
			actions === deferredHandledRef.current,
		[]
	);

	const [deferredActions, deferredDispatch] = useReducer(
		(state: React.ReducerAction<R>[], action: React.ReducerAction<R>) =>
			deferredIsHandled(state) ? [action] : [...state, action],
		handledRef.current
	);

	const currentlyDeferredActions = useRef(deferredActions);
	useEffect(() => {
		currentlyDeferredActions.current = deferredActions;

		const dispatchDeferredActions = () =>
			batch(() => {
				for (const a of currentlyDeferredActions.current) {
					dispatch(a);
				}
				deferredHandledRef.current = deferredActions;
			});

		if (!deferredIsHandled(currentlyDeferredActions.current)) {
			const timeout = setTimeout(dispatchDeferredActions, 100);

			return () => clearTimeout(timeout);
		}
	}, [deferredActions, deferredIsHandled]);

	const stateRef = useRef<React.ReducerState<R>>(initialState);
	let state = stateRef.current;
	state = useMemo(() => {
		// console.log("\n--- Process actions ---");
		// console.time("\n--- Process actions ---  ");

		const newState = [
			...(isHandled(actions) ? [] : actions),
			...(deferredIsHandled(currentlyDeferredActions.current)
				? []
				: currentlyDeferredActions.current),
			...renderActions
		]
			.filter(isNotNull)
			.reduce(reducer, state);

		// console.timeEnd("\n--- Process actions ---  ");

		return newState;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps, actions]);

	useEffect(() => {
		stateRef.current = state;
		handledRef.current = actions;
		deferredHandledRef.current = currentlyDeferredActions.current;
	}, [state, actions]);

	const dispatchWithPriority = useCallback<
		DispatchWithPriority<React.ReducerAction<R>>
	>((action, priority) => {
		switch (priority) {
			case DispatchPriority.Defer:
				return deferredDispatch(action);
			case DispatchPriority.Normal:
			default:
				return dispatch(action);
		}
	}, []);

	return [state, dispatchWithPriority];
}
