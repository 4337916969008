import React from "react";
import { useTenantImage } from "@ploy-ui/tenants";
import { makeStyles } from "@material-ui/core/styles";

import { ImageFit } from "@ploy-ui/core";

export const useStyles = makeStyles(
	{
		img: {
			height: (props: BackgroundProps) => props.height,
			width: "100%",
			maxHeight: (props: BackgroundProps) => props.maxHeight,
			filter: (props: BackgroundProps) => props.filter
		}
	},
	{ name: "DashboardBackground" }
);

export interface BackgroundProps {
	height?: string | number;
	maxHeight?: string | number;
	filter?: string;
}

export function Background(props: BackgroundProps) {
	const classes = useStyles(props);

	const src = useTenantImage("dashboard");

	return (
		<ImageFit className={classes.img} src={src} fit="cover" position="bottom" />
	);
}

Background.displayName = "Background";

export default Background;
