import mapValues from "lodash/mapValues";
import {
	FormTemplate,
	TemplateField,
	TemplateSection,
	TemplatePanel,
	TemplatePage,
	TemplatePageDeprecated,
	TemplatePageBase
} from "@ploy-lib/types";
import { hasPanels, isNotNull } from "./utils";

export function mapAllTemplateFields(
	template: FormTemplate<TemplatePage>,
	fieldMapper: (field: TemplateField) => TemplateField[]
): FormTemplate<TemplatePage> {
	const sectionWithFieldDefaults = (s: TemplateSection): TemplateSection => ({
		...s,
		fields: (s.fields || []).flatMap(fieldMapper).filter(isNotNull)
	});

	const panelWithFieldDefaults = (panel: TemplatePanel): TemplatePanel => ({
		...panel,
		sections: (panel.sections || []).map(sectionWithFieldDefaults)
	});

	const pageWithDefaults = (p: TemplatePage) => {
		if (!hasPanels(p))
			throw new Error(
				`Page without panels (${
					(p as TemplatePageDeprecated)?.layout
				}) not supported: ${template.name}`
			);

		return {
			...p,
			panels: mapValues(p.panels, panelWithFieldDefaults)
		};
	};

	return {
		...template,
		pages: (template.pages || []).map(pageWithDefaults)
	};
}

export function* getAllTemplateFields(template: FormTemplate<TemplatePage>) {
	for (const page of template.pages) {
		if (!hasPanels(page))
			throw new Error(
				`Page without panels (${
					(page as TemplatePageDeprecated)?.layout
				}) not supported: ${template.name}`
			);

		for (const panel of Object.values(page.panels)) {
			for (const section of panel.sections) {
				for (const field of section.fields) {
					yield field;
				}
			}
		}
	}
}

export function* getAllFormTemplateFields(
	template: FormTemplate<TemplatePageBase> | undefined
) {
	if (template) {
		for (const page of template.pages) {
			if (!hasPanels(page))
				throw new Error(
					`Page without panels (${
						(page as TemplatePageDeprecated)?.layout
					}) not supported: ${template.name}`
				);

			for (const panel of Object.values(page.panels)) {
				for (const section of panel.sections) {
					for (const field of section.fields) {
						yield field;
					}
				}
			}
		}
	}
}
