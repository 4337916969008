import { memo, useEffect, useMemo, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	FormLabel,
	Grid,
	Theme,
	Typography,
	makeStyles
} from "@material-ui/core";

import {
	CurrencyField,
	MuiAutocompleteField,
	TextField
} from "@ploy-ui/form-fields";
import { useCallback } from "react";
import { isArray } from "lodash";
import { InputFieldProps } from "../..";
import { defineMessages, useIntl } from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { v4 as uuid } from "uuid";
import {
	Formik,
	Field as FormikField,
	useFormikContext,
	FormikComputedProps
} from "formik";
import { SectionProps } from "../../section";
import { addRegisteredSectionLayout } from "../../section/sectionLayoutDescriptions";
import {
	fieldOptionListVariableName,
	useVariableData
} from "@ploy-lib/calculation";
import * as Yup from "yup";

import {
	ErrorDisplay,
	ApplicationDebt,
	ApplicationDebtSource
} from "@ploy-lib/types";

export interface ExtendedApplicationDebt extends ApplicationDebt {
	_frontendOnlyId?: string;
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {},
		expanded: {
			backgroundColor: theme.palette.primary.light,
			border: `1px solid ${theme.palette.secondary.light}`
		},
		listItem: {
			padding: theme.spacing(2)
		},
		childNumber: {
			paddingBottom: theme.spacing(2),
			paddingTop: theme.spacing(2)
		},
		header: {
			fontWeight: 700,
			paddingBottom: theme.spacing(4),
			padding: theme.spacing(0.5)
		}
	}),
	{ name: "DployLimitedDebtField" }
);

const messages = defineMessages({
	loanType: {
		id: "template-form.field.limitedDebt.loanType",
		defaultMessage: "Loan type"
	},
	amount: {
		id: "template-form.field.limitedDebt.amount",
		defaultMessage: "Amount"
	},
	delete: {
		id: "template-form.field.limitedDebt.delete",
		defaultMessage: "Delete"
	},
	update: {
		id: "template-form.field.limitedDebt.update",
		defaultMessage: "Update"
	},
	addMore: {
		id: "template-form.field.limitedDebt.addMore",
		defaultMessage: "Add more"
	},
	lender: {
		id: "template-form.field.limitedDebt.lender",
		defaultMessage: "Lender"
	},
	missingLenderHender: {
		id: "template-form.field.limitedDebt.missingLenderHeader",
		defaultMessage: "Supply lender"
	},
	loanTypeIsRequired: {
		id: "template-form.field.limitedDebt.loanTypeIsRequired",
		defaultMessage: "Loan type is required"
	},
	lenderIsRequired: {
		id: "template-form.field.limitedDebt.lenderIsRequired",
		defaultMessage: "Lender is required"
	},
	amountIsRequired: {
		id: "template-form.field.limitedDebt.amountIsRequired",
		defaultMessage: "Amount is required and must be equal or larger than zero"
	}
});

const debtSourceMessages = defineMessages<ApplicationDebtSource>({
	[ApplicationDebtSource.NotSet]: {
		id: "template-form.field.limitedDebt.debtSource.notSet",
		defaultMessage: "Not set"
	},
	[ApplicationDebtSource.Applicant]: {
		id: "template-form.field.limitedDebt.debtSource.applicant",
		defaultMessage: "Applicant"
	},
	[ApplicationDebtSource.DebtRegistry]: {
		id: "template-form.field.limitedDebt.debtSource.debtRegistry",
		defaultMessage: "Debt registry"
	},
	[ApplicationDebtSource.Taxdata]: {
		id: "template-form.field.limitedDebt.debtSource.taxdata",
		defaultMessage: "Tax data"
	}
});

interface LimitedDebtProps {
	canEditEverything?: boolean;
	canEditAmount?: boolean;
	canEditLoanType?: boolean;
	data: Readonly<ExtendedApplicationDebt>;
	onUpdate?: (data: ExtendedApplicationDebt) => void;
	onDelete?: (data: ExtendedApplicationDebt) => void;
}

const fieldsToTouch = [
	"loanType",
	"lender",
	"amount"
] as (keyof ExtendedApplicationDebt)[];

const fieldsToTouchDictionary = Object.fromEntries(
	fieldsToTouch.map(key => [key, true])
);

const LimitedDebtElement = (props: LimitedDebtProps) => {
	const classes = useStyles();
	const intl = useIntl();

	const [expanded, setExpanded] = useState(false);

	type KeyValue = {
		key: string;
		value: string;
	};

	const loanTypesRaw = useVariableData(
		"Calculator",
		fieldOptionListVariableName("LoanTypeOptionHelper")
	).value as KeyValue[] | undefined;

	const [loanTypesDict, loanTypesValues] = useMemo(
		() => [
			Object.fromEntries(
				(loanTypesRaw ?? []).map(lt => [lt.key, lt.value] as const)
			),
			(loanTypesRaw ?? []).map(l => l.key)
		],
		[loanTypesRaw]
	);

	const applicationDebtSchema = useMemo(
		() =>
			Yup.object({
				loanType: Yup.string()
					.required(intl.formatMessage(messages.loanTypeIsRequired))
					.oneOf(loanTypesValues),
				lender: Yup.string().required(
					intl.formatMessage(messages.lenderIsRequired)
				),
				amount: Yup.number()
					.required(intl.formatMessage(messages.amountIsRequired))
					.min(0, intl.formatMessage(messages.amountIsRequired))
			}),
		[intl, loanTypesValues]
	);

	const isExpanded = (formikProps: FormikComputedProps<unknown>) =>
		!formikProps.isValid || formikProps.dirty || expanded;

	if (props.onUpdate == null && props.onDelete == null) {
		return (
			<Grid container justifyContent="space-between">
				<Typography>
					{loanTypesDict[props.data.loanType] ?? props.data.loanType}
				</Typography>
				<Typography>
					{intl.formatNumber(props.data.amount, {
						format: "currency"
					})}
				</Typography>
			</Grid>
		);
	}

	return (
		<Formik
			initialValues={props.data}
			enableReinitialize
			validationSchema={applicationDebtSchema}
			validateOnMount
			initialTouched={fieldsToTouchDictionary}
			onSubmit={(debtToUpdate, helpers) => {
				props.onUpdate?.(debtToUpdate);
				return Promise.resolve(debtToUpdate);
			}}
		>
			{formikProps => (
				<Accordion
					expanded={isExpanded(formikProps)}
					onChange={(_, updatedExpanded) => {
						if (!formikProps.isValid) {
							formikProps.setTouched(fieldsToTouchDictionary, true);
							return;
						}
						if (!formikProps.dirty) setExpanded(updatedExpanded);
					}}
					className={clsx(classes.listItem, {
						[classes.expanded]: isExpanded(formikProps)
					})}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						{isExpanded(formikProps) ? (
							<Typography style={{ overflowWrap: "anywhere" }}>
								{props.data.lender
									? props.data.lender
									: // <i>{intl.formatMessage(messages.missingLenderHender)}</i>
									  null}
							</Typography>
						) : (
							<Grid container justifyContent="space-between">
								<Typography>
									{loanTypesDict[formikProps.values.loanType] ??
										formikProps.values.loanType}
								</Typography>
								<Typography>
									{intl.formatNumber(formikProps.values.amount, {
										format: "currency"
									})}
								</Typography>
							</Grid>
						)}
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={4} direction="column">
							{props.canEditEverything ? (
								<Grid item>
									<FormikField
										name="lender"
										component={TextField}
										label={intl.formatMessage(messages.lender)}
										errorDisplay={ErrorDisplay.Touched}
										fullWidth
									/>
								</Grid>
							) : null}
							<Grid item>
								<FormikField
									name="loanType"
									component={MuiAutocompleteField}
									items={loanTypesValues}
									disableClearable={true}
									label={intl.formatMessage(messages.loanType)}
									getItemLabel={(lt: string) => loanTypesDict[lt]}
									getItemValue={(lt: string) =>
										loanTypesDict[lt] != null ? lt : undefined
									}
									errorDisplay={ErrorDisplay.Touched}
									fullWidth
								/>
							</Grid>
							<Grid item>
								<FormikField
									name="amount"
									component={CurrencyField}
									label={intl.formatMessage(messages.amount)}
									errorDisplay={ErrorDisplay.Touched}
									fullWidth
								/>
							</Grid>
							{props.onUpdate != null &&
							(props.canEditAmount ||
								props.canEditLoanType ||
								props.canEditEverything) ? (
								<Grid item>
									<Button
										disabled={!formikProps.dirty || !formikProps.isValid}
										onClick={() => {
											formikProps.submitForm();
										}}
										variant="contained"
									>
										{intl.formatMessage(messages.update)}
									</Button>
									{props.onDelete != null && props.canEditEverything ? (
										<Button onClick={() => props.onDelete?.(props.data)}>
											{intl.formatMessage(messages.delete)}
										</Button>
									) : null}
								</Grid>
							) : null}
						</Grid>
					</AccordionDetails>
				</Accordion>
			)}
		</Formik>
	);
};

const LimitedDebtsInternal = memo(
	(props: {
		label?: string;
		debtList: Readonly<ExtendedApplicationDebt[]>;
		onAdd?: () => void;
		onUpdate?: (debt: ExtendedApplicationDebt) => void;
		onDelete?: (debt: ExtendedApplicationDebt) => void;
	}) => {
		const intl = useIntl();
		const classes = useStyles();

		const debtList = props.debtList;

		return (
			<Grid container spacing={1}>
				{props.label ? (
					<Grid item xs={12}>
						<FormLabel component="legend">{props.label}</FormLabel>
					</Grid>
				) : null}
				{debtList.map(data => (
					<Grid
						item
						xs={12}
						key={data._frontendOnlyId ?? data.applicationDebtId}
					>
						<LimitedDebtElement
							data={data}
							onUpdate={props.onUpdate}
							onDelete={props.onDelete}
							canEditEverything={
								data.source === ApplicationDebtSource.Applicant
							}
							canEditAmount={data.source === ApplicationDebtSource.Taxdata}
							canEditLoanType={data.source === ApplicationDebtSource.Taxdata}
						/>
					</Grid>
				))}
				{props.onAdd != null ? (
					<Grid item xs={12}>
						{/* TODO: Missing correct button type here! */}
						<Button onClick={props.onAdd} variant="outlined">
							{intl.formatMessage(messages.addMore)}
						</Button>
					</Grid>
				) : null}
			</Grid>
		);
	}
);

export function debtExistencePredicate(
	itemFromCollection: ExtendedApplicationDebt,
	needle: ExtendedApplicationDebt
) {
	return (
		(needle._frontendOnlyId != null &&
			needle._frontendOnlyId === itemFromCollection._frontendOnlyId) ||
		(needle._frontendOnlyId == null &&
			needle.applicationDebtId === itemFromCollection.applicationDebtId)
	);
}

export function ensureCollectionHasIds(items: any): ExtendedApplicationDebt[] {
	return (
		!isArray(items) ? [] : (items as ExtendedApplicationDebt[]) || []
	).map(debt =>
		// Just in case application / calc rules are reloaded but debt are not saved to database. May cause rerenders.
		debt.applicationDebtId === 0 && !debt._frontendOnlyId
			? { ...debt, _frontendOnlyId: uuid() }
			: debt
	);
}

const LimitedDebtsField = memo((props: InputFieldProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const debtList = useMemo(
		() => ensureCollectionHasIds(props.field.value),
		[props.field.value]
	);

	const addItem = useCallback(() => {
		const updatedList = [
			...debtList,
			{
				lender: "",
				amount: 0,
				loanType: "",
				source: ApplicationDebtSource.Applicant,
				applicationDebtId: undefined,
				loanNumber: "",
				termAmount: 0,
				refinance: false,
				comment: "",
				_frontendOnlyId: uuid()
			} as ExtendedApplicationDebt
		];
		props.form.setFieldValue(props.field.name, updatedList);
	}, [debtList, props.field.name, props.form]);

	const updateItem = useCallback(
		(updatedData: ExtendedApplicationDebt) => {
			const updatedList = debtList.map(debt =>
				debtExistencePredicate(debt, updatedData) ? updatedData : debt
			);
			props.form.setFieldValue(props.field.name, updatedList);
		},
		[debtList, props.field.name, props.form]
	);

	const deleteItem = useCallback(
		(debtToDelete: ExtendedApplicationDebt) => {
			const updatedList = debtList.filter(
				debt => !debtExistencePredicate(debt, debtToDelete)
			);
			props.form.setFieldValue(props.field.name, updatedList);
		},
		[debtList, props.field.name, props.form]
	);

	const userSuppliedDebts = useMemo(
		() =>
			debtList.filter(debt => debt.source === ApplicationDebtSource.Applicant),
		[debtList]
	);
	const debtRegistryDebts = useMemo(
		() =>
			debtList.filter(
				debt => debt.source === ApplicationDebtSource.DebtRegistry
			),
		[debtList]
	);
	const taxRegistryDebts = useMemo(
		() =>
			debtList.filter(debt => debt.source === ApplicationDebtSource.Taxdata),
		[debtList]
	);

	return (
		<Grid
			container
			spacing={1}
			className={clsx(classes.root, props.className)}
			onClick={props.onClick}
		>
			{props.label ? (
				<Grid item xs={12}>
					<FormLabel component="legend">{props.label}</FormLabel>
				</Grid>
			) : null}
			{taxRegistryDebts.length > 0 ? (
				<Grid item xs={12}>
					<LimitedDebtsInternal
						debtList={taxRegistryDebts}
						label={intl.formatMessage(
							debtSourceMessages[ApplicationDebtSource.Taxdata]
						)}
						onUpdate={updateItem}
					/>
				</Grid>
			) : null}
			<Grid item xs={12}>
				<LimitedDebtsInternal
					debtList={userSuppliedDebts}
					label={intl.formatMessage(
						debtSourceMessages[ApplicationDebtSource.Applicant]
					)}
					onAdd={addItem}
					onUpdate={updateItem}
					onDelete={deleteItem}
				/>
			</Grid>
			{debtRegistryDebts.length > 0 ? (
				<Grid item xs={12}>
					<LimitedDebtsInternal
						debtList={debtRegistryDebts}
						label={intl.formatMessage(
							debtSourceMessages[ApplicationDebtSource.DebtRegistry]
						)}
						// onUpdate={updateItem}
					/>
				</Grid>
			) : null}
		</Grid>
	);
});

export const LimitedDebtsSection = (sectionProps: SectionProps) => {
	// This section started as a custom field 😅
	return (
		<FormikField
			component={LimitedDebtsField}
			name="Calculator.Debts"
			className={sectionProps.className}
			onClick={sectionProps.onClick}
			literal={sectionProps.literal}
		/>
	);
};

// Credit: https://github.com/jaredpalmer/formik/issues/2769#issuecomment-1872382784
export const FormikAutoSubmit = () => {
	/*
	  This component is used to automatically submit the form when the form is valid
	  and has been changed(dirty).
	 */
	const { isValid, values, dirty, submitForm } = useFormikContext();

	useEffect(() => {
		if (isValid && dirty) {
			submitForm();
			return;
		}
	}, [isValid, values, dirty, submitForm]);

	return null;
};

addRegisteredSectionLayout({
	displayName: "LimitedDebtsSection",
	name: "LimitedDebtsSection",
	settings: {}
});

LimitedDebtsField.displayName = "LimitedDebtsField";
LimitedDebtsSection.displayName = "LimitedDebtsSection";

// export const EditorDebtFields = identityRecordOfFieldEditorOptions({
// 	LimitedDebtsField: {
// 		editableOptions: {}
// 	}
// });

// export const EditorDebtLiterals = identityRecordOfFieldEditorOptions({
// 	// LimitedDebtsLiteral: {
// 	// 	editableOptions: {}
// 	// }
// });

// export {
// 	LimitedDebtsField
// 	//,LimitedDebtsLiteral
// };
