import React from "react";
import ContentBox, { ContentBoxProps } from "./ContentBox";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

DetailsBox.displayName = "DetailsBox";

export type DetailsBoxProps = {
	onClick: IconButtonProps["onClick"];
} & ContentBoxProps;

function DetailsBox(props: DetailsBoxProps) {
	const { onClick, ...rest } = props;

	return (
		<ContentBox
			{...rest}
			headerAction={
				<IconButton onClick={onClick}>
					<ChevronLeftIcon />
				</IconButton>
			}
		/>
	);
}
export default DetailsBox;
