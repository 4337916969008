import React from "react";
import { TemplateForm, TemplateFormProps } from "@ploy-ui/template-form";
import { RoutePageProvider } from "../../components/RoutePageProvider";

export const renderTemplateWithRouteState = (props: TemplateFormProps) => (
	<RoutePageProvider
		labels={props.template ? props.template.pages.map(p => (p.pageUri?.trim().length ?? 0) > 0 ? p.pageUri : p.pageTitle) : []}
	>
		<TemplateForm {...props} />
	</RoutePageProvider>
);
