import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { CustomerType } from "./ProductResource";
import {
	AbstractInstanceType,
	RestEndpoint,
	RestFetch,
	schema,
	SchemaDetail,
	SimpleResource
} from "@rest-hooks/rest";
import { ApplicationResource } from "./ApplicationResource";

export enum CustomerRole {
	NotSet = "NotSet",
	Customer = "Customer",
	CoSigner = "CoSigner",
	Guarantor = "Gurantor",
	OldAunt = "OldAunt",
	Supplier = "Supplier",
	Custom = "Custom"
}

export enum CustomerRoleId {
	NotSet = 0,
	Customer = 1,
	CoSigner = 2,
	Guarantor = 3,
	OldAunt = 4,
	Supplier = 5,
	Custom = 6
}

export interface ApplicationContactInfo {
	[CustomerRole.Customer]?: ContactInfo;
	[CustomerRole.CoSigner]?: ContactInfo;
	[CustomerRole.Supplier]?: ContactInfo;
}

export interface ContactInfo {
	id: ID;
	role: CustomerRole;
	type: CustomerType;
	contactPerson: string;
	contactPersonEmail: string;
	phoneMobile: string;
	email: string;
	phoneHome: string;
}

export class ContactInfoResource extends BaseResource implements ContactInfo {
	id: ID;
	role: CustomerRole = CustomerRole.NotSet;
	type: CustomerType = CustomerType.NotSet;
	contactPerson: string = "";
	contactPersonEmail: string = "";
	phoneMobile: string = "";
	email: string = "";
	phoneHome: string = "";

	pk() {
		return this.id?.toString();
	}

	static detail<T extends typeof SimpleResource>(
		this: T
	): RestEndpoint<
		RestFetch<{ applicationNumber: string; role: CustomerRole }>,
		SchemaDetail<AbstractInstanceType<T>>,
		undefined
	> {
		return super.detail();
	}

	static detailAll<T extends typeof SimpleResource>(
		this: T
	): RestEndpoint<
		RestFetch<{ applicationNumber: string }>,
		schema.Values<T>,
		undefined
	> {
		return this.detail().extend({ schema: new schema.Values(this) });
	}

	static update<T extends typeof SimpleResource>(
		this: T
	): RestEndpoint<
		RestFetch<{ applicationNumber: string; role: CustomerRole }, ContactInfo>,
		SchemaDetail<AbstractInstanceType<T>>,
		true
	> {
		return super.update();
	}

	static updateAll<T extends typeof SimpleResource>(
		this: T
	): RestEndpoint<
		RestFetch<{ applicationNumber: string }, ApplicationContactInfo>,
		schema.Values<T>,
		true
	> {
		return this.update().extend({ schema: new schema.Values(this) });
	}

	static urlTemplates = [
		`${ApplicationResource.urlRoot}/{applicationNumber}/contact-info/{role}`,
		`${ApplicationResource.urlRoot}/{applicationNumber}/contact-info`
	];

	static urlRoot = `${ApplicationResource.urlRoot}/{applicationNumber}/contact-info/{role}`;
}
