import React from "react";
import TextList from "./TextList";
import { applyPropertyControls, ControlType } from "../../property-controls";

import { v4 as uuidv4 } from "uuid";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { ContentVisibility, TextItem } from "./TextListItem";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage, defineMessages } from "react-intl";

const contentVisibilityMessages = defineMessages<ContentVisibility>({
	[ContentVisibility.Always]: {
		id: "dploy.grid.TextList.item.contentVisibility.always.label",
		defaultMessage: "Always visible"
	},
	[ContentVisibility.Expanded]: {
		id: "dploy.grid.TextList.item.contentVisibility.expanded.label",
		defaultMessage: "Expand"
	},
	[ContentVisibility.Opened]: {
		id: "dploy.grid.TextList.item.contentVisibility.opened.label",
		defaultMessage: "Open"
	}
});

applyPropertyControls(TextList, {
	items: {
		type: ControlType.Array,
		title: (
			<FormattedMessage
				id="dploy.grid.TextList.items.title"
				defaultMessage="List"
			/>
		),
		description: (
			<FormattedMessage
				id="dploy.grid.TextList.items.description"
				defaultMessage="All text supports markdown formatting"
			/>
		),
		coerce: (_, { content }: { content?: string }) => {
			if (!content) return;

			const lines = content.split("\n");

			const [, tableHeader, , ...rest] = lines;

			if (/^\s*\|.*\|\s*$/.test(tableHeader)) {
				const tableRows = rest.filter(x => x.startsWith("|"));
				const restMarkdown = rest.filter(x => !x.startsWith("|")).join("\n");
				const listItems = tableRows
					.map(x => x.match(/([^|]+)/g))
					.filter(isNotNull)
					.map<TextItem>(match => {
						return {
							id: uuidv4(),
							primary: match?.[0].trim(),
							tertiary: match?.[match.length - 1].trim()
						};
					});

				if (restMarkdown.trim()) {
					listItems[listItems.length - 1].content = restMarkdown;
				}

				return listItems;
			} else {
				const listItems = lines
					.map(x => x.match(/([^||]+)/g))
					.filter(isNotNull)
					.map<TextItem>(match => {
						const [label, value = undefined, field = undefined] = match;

						return {
							id: uuidv4(),
							primary: label.trim(),
							tertiary: value
								?.trim()
								.replace(/{value}/g, `{budgetResponsible_${field?.trim()}}`)
						};
					});

				return listItems;
			}
		},
		propertyControl: {
			type: ControlType.Object,
			title: "",
			propertyControls: {
				id: {
					type: ControlType.Hidden,
					title: "Id"
				},
				iconClass: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.iconClass.label"
							defaultMessage="Icon"
						/>
					),
					description: (
						<FormattedMessage
							id="dploy.grid.TextList.item.iconClass.description"
							defaultMessage="Enter the name of an icon from Font Awesome"
						/>
					)
				},
				primary: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.primary.label"
							defaultMessage="Main header"
						/>
					)
				},
				secondary: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.secondary.label"
							defaultMessage="Sub header"
						/>
					)
				},
				tertiary: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.tertiary.label"
							defaultMessage="Value"
						/>
					)
				},
				content: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.content.label"
							defaultMessage="Content"
						/>
					),
					multiline: true
				},
				contentVisibility: {
					type: ControlType.Enum,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.contentVisibility.label"
							defaultMessage="Content display"
						/>
					),
					options: Object.values(ContentVisibility),
					optionTitles: Object.values(ContentVisibility).map(
						cv => contentVisibilityMessages[cv]
					),
					defaultValue: ContentVisibility.Always
				},
				hideDivider: {
					type: ControlType.Boolean,
					title: (
						<FormattedMessage
							id="dploy.grid.TextList.item.hideDivider.label"
							defaultMessage="Hide divider"
						/>
					)
				}
			}
		}
	},
	boxProps: {
		...contentBoxPropertyControls,
		propertyControls: {
			header: {
				...contentBoxPropertyControls.propertyControls.header,
				coerce: (_, boxProps, props: { content?: string }) => {
					if (props.content) {
						const [mdHeader] = props.content.split("\n");

						const [, header] = mdHeader.match(/\s*([^>#]+)$/) ?? [];
						return header;
					}
				}
			},
			iconClass: {
				...contentBoxPropertyControls.propertyControls.iconClass,
				coerce: (_, boxProps, props: { content?: string }) => {
					if (props.content) {
						const [mdHeader] = props.content.split("\n");

						const [, iconClass] = mdHeader.match(/fa fa-([-\w]+)/) ?? [];
						return iconClass;
					}
				}
			},
			backgroundColor:
				contentBoxPropertyControls.propertyControls.backgroundColor,
			headerColor: contentBoxPropertyControls.propertyControls.headerColor
		}
	}
});

export default TextList;
