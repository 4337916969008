import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BasePlainResource } from "./BasePlainResource";
import { SimpleResource } from "@rest-hooks/rest";
import paramsToString from "../resources/BaseResource";

export interface CustomerSignatureInfo {
	readonly signerStatusMessage: string;
	readonly customerSignersHTML: string;
}

export class CustomerSignatureInfoResource
	extends BasePlainResource
	implements CustomerSignatureInfo
{
	readonly applicationNumber?: ID;
	readonly signerType?: number;
	readonly signerStatusMessage: string = "";
	readonly customerSignersHTML: string = "";

	pk() {
		return (
			this.applicationNumber?.toString() + "-" + this.signerType?.toString()
		);
	}

	static url<T extends typeof SimpleResource>(
		this: T,
		urlParams: Readonly<Record<string, any>>
	) {
		const url = super.url({});

		return `${url}${paramsToString(urlParams)}`;
	}

	static urlRoot = legacyApiResourceUrl("Signer/GetCustomerSignatureInfo");
}
