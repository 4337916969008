import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { LoginInfoResource } from "@ploy-lib/rest-resources";
import { useStatefulResource } from "@rest-hooks/legacy";

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 35
	},
	header: {
		textDecoration: "underline",
		color: "#666",
		fontWeight: "bold",
		marginBottom: 10.5
	},
	icon: {
		color: theme.palette.error.main
	}
}));

const InformationMessage = () => {
	const classes = useStyles();

	const { data: info } = useStatefulResource(LoginInfoResource.detail(), {});

	if (!info?.message && !info?.header) return null;

	return (
		<div className={classes.root}>
			<Typography align="center" className={classes.header}>
				<i
					className={clsx(
						"fa fa-fw fa-exclamation-triangle fa-lg",
						classes.icon
					)}
				/>
				{info.header}
			</Typography>
			<Typography align="center">{info.message}</Typography>
		</div>
	);
};

export default InformationMessage;
