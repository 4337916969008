import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { Icon } from "@material-ui/core";

const useStyles = makeStyles(
	theme => ({
		root: {
			borderRadius: 8
		},
		rootSmall: {
			width: 75,
			height: 75
		},
		rootLarge: {
			width: 175,
			height: 175,
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				height: 135
			}
		},
		iconSmall: { fontSize: 50, width: "auto" },
		iconLarge: { fontSize: 112, width: "auto" },
		img: {
			margin: "auto",
			display: "block",
			maxWidth: "100%",
			maxHeight: "100%",
			height: "100%",
			width: "100%",
			objectFit: "cover",
			borderRadius: 8
		}
	}),
	{ name: "DployCarInfoSummaryImageOrIcon" }
);

export interface ImageOrIconProps {
	src?: string;
	alt: string;
	className?: string;
	icon?: string;
	size?: "small" | "large";
	bgcolor?: BoxProps["bgcolor"];
	iconColor?: SvgIconProps["color"];
}

export function ImageOrIcon(props: ImageOrIconProps) {
	const {
		src,
		alt,
		className,
		icon,
		size = "large",
		bgcolor,
		iconColor
	} = props;

	const classes = useStyles(props);

	if (src) {
		return (
			<div
				className={clsx(className, classes.root, {
					[classes.rootSmall]: size === "small",
					[classes.rootLarge]: size === "large"
				})}
			>
				<img className={classes.img} alt={alt} src={src} />
			</div>
		);
	}

	return (
		<Box
			className={clsx(className, classes.root, {
				[classes.rootSmall]: size === "small",
				[classes.rootLarge]: size === "large"
			})}
			bgcolor={bgcolor}
			display="flex"
			alignItems="center"
			justifyContent="center"
			fontSize={size === "small" ? 40 : 120}
		>
			<Icon
				className={clsx(`fas fa-${icon}`, {
					[classes.iconSmall]: size === "small",
					[classes.iconLarge]: size === "large"
				})}
				color={iconColor}
			/>
		</Box>
	);
}
