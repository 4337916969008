import { BasePlainResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import {
	ID,
	ApplicationStatuses,
	ApplicationStatusId,
	ApplicationStatus,
	DocumentStatus
} from "@ploy-lib/types";
import { EndpointExtraOptions } from "@rest-hooks/rest";

export class ApplicationStatusesResource
	extends BasePlainResource
	implements ApplicationStatuses
{
	readonly id?: ID;
	readonly documentStatusDesc?: string;
	readonly applicationStatusId: ApplicationStatusId =
		ApplicationStatusId.NotSet;
	readonly applicationStatus: ApplicationStatus = ApplicationStatus.NotSet;
	readonly documentStatus: DocumentStatus = DocumentStatus.NotSet;
	readonly canSendToEsign: boolean = false;
	readonly canEditRegNumber: boolean = false;
	readonly fullPostProcessAfterPayout: boolean = false;
	readonly canEditChassisNumber: boolean = false;
	readonly canEditInvoiceNoRef: boolean = false;
	readonly canEditDeliveryDate: boolean = false;
	readonly canEditDueDay: boolean = false;

	pk() {
		return this.id?.toString() || "unknown";
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			invalidIfStale: true,
			dataExpiryLength: Infinity
		};
	}

	static urlRoot = legacyApiResourceUrl(
		"AppLoanLeasing/GetApplicationStatuses"
	);
}
