import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";

////////////// Standard styles //////////////
export const useStyles = makeStyles(
	theme => ({
		root: {
			// maxHeight: "75%",
			left: 0,
			right: 0,
			margin: "0 auto",
			width: "100%",
			maxWidth: theme.breakpoints.values.md,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			[theme.breakpoints.down("sm")]: {
				borderRadius: 0,
				maxWidth: "100%"
			}
		},
		appBarHeader: {
			borderRadius: "inherit"
		},
		appBarBottom: {
			top: "auto",
			bottom: 0
		},
		expandedPanel: {
			overflowY: "auto"
		},
		saveButton: {
			justifyContent: "flex-end"
		},
		openButtonLabel: {
			flexDirection: "row",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column-reverse"
			}
		},
		openButtonEndIcon: {
			marginLeft: "0 0 0 12px",
			[theme.breakpoints.down("sm")]: {
				margin: 0
			}
		},
		openButtonText: {
			padding: "6px 8px",
			whiteSpace: "nowrap",
			[theme.breakpoints.down("sm")]: {
				padding: 0
			},
			[theme.breakpoints.down(350)]: {
				fontSize: 12
			}
		}
	}),
	{ name: "DployStickyFooterStandard" }
);

////////////// Expanded styles //////////////
export const expandedFooterTheme = (theme: Theme) => {
	const overrides = {
		overrides: {
			DployFormSection: {
				root: {
					"&:not(:last-child)": {
						marginBottom: theme.spacing(2),
						justifyContent: "center"
					}
				}
			},
			DployFormSectionGroup: {
				sectionGroup: {
					alignSelf: "center",
					margin: "0 auto",
					[theme.breakpoints.down("sm")]: {
						paddingLeft: 4,
						paddingRight: 4
					}
				}
			},
			DployFormLiteral: {
				wrapper: {
					display: "flex",
					marginLeft: 8,
					marginRight: 8,
					maxWidth: "100%"
				},
				label: {
					color: "grey",
					fontWeight: 500
				}
			},
			MuiFormControlLabel: {
				root: {
					paddingBottom: theme.spacing(2)
				}
			}
		}
	};
	return merge({}, theme, overrides) as Theme;
};

////////////// Minimized styles //////////////
export const minimizedFooterTheme = (theme: Theme) => {
	const overrides = {
		overrides: {
			DployFormSectionGroup: {
				sectionGroupWrapper: {
					paddingTop: 0,
					paddingBottom: 0
				},
				sectionGroup: {
					maxWidth: 800,
					justifyContent: "flex-end"
				}
			},
			DployFormFieldContainer: {
				fieldSeparator: {
					borderRight: "1px solid rgba(0,0,0,0.1)",
					[theme.breakpoints.down("sm")]: {
						borderRight: "none"
					}
				},
				rowContainer: {
					flexWrap: "nowrap"
				},
				fieldWrapper: {
					display: "flex"
				}
			},
			DployFormLiteral: {
				root: {
					flexWrap: "wrap",
					[theme.breakpoints.down("sm")]: {
						justifyContent: "flex-end"
					}
				},
				wrapper: {
					marginLeft: 8,
					display: "flex"
				},
				label: {
					flex: "1 0 100%",
					color: "grey",
					[theme.breakpoints.down("sm")]: {
						fontSize: 14,
						width: "max-content",
						marginRight: 0,
						textAlign: "end"
					},
					[theme.breakpoints.down(350)]: {
						fontSize: 12
					}
				},
				value: {
					flex: "none",
					textAlign: "left",
					flexGrow: 0,
					[theme.breakpoints.up("md")]: {
						marginTop: 16,
						fontSize: "120%"
					},
					[theme.breakpoints.down("sm")]: {
						fontSize: 14
					}
				},
				suffix: {
					[theme.breakpoints.up("md")]: {
						marginLeft: theme.spacing(1),
						fontWeight: 500,
						marginTop: 16,
						fontSize: "120%"
					},
					[theme.breakpoints.down("sm")]: {
						marginLeft: 4,
						fontSize: 14,
						width: "auto"
					}
				},
				marginNormal: {
					[theme.breakpoints.down("sm")]: {
						marginBottom: 0
					}
				},
				variantContained: {
					padding: theme.spacing(1),
					background: theme.palette.grey[300],
					"& > $value": {
						fontSize: 12,
						textAlign: "inherit" as "inherit"
					}
				},
				variantEmphasized: {
					"& > $value": {
						fontWeight: 700
					}
				},
				variantPlain: {
					marginBottom: 0,
					alignItems: "center"
				}
			}
		}
	};
	return merge({}, theme, overrides) as Theme;
};
