import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { ID } from "../types";

export interface Profile {
	email: string;
	firstName: string;
	lastName: string;
	mobileNo: string;
	roleName: string;
}

export class ProfileResource extends BaseCamelCasedResource implements Profile {
	readonly salespersonId: ID;
	readonly email: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly mobileNo: string;
	readonly roleName: string;
	readonly fullName: string;
	readonly initials: string;

	readonly error: string;
	readonly canUpdateVendorProfile?: boolean = false;

	get() {
		return `${this.firstName} ${this.lastName}`;
	}

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return `${this.urlRoot}/CurrentUserProfile`;
	}

	static updateProfile(this: typeof ProfileResource) {
		return this.update().extend({
			url: () => `${this.urlRoot}/UpdateProfile`
		});
	}

	static urlRoot = legacyApiResourceUrl("VulcanData");
}
