import { WholesaleCar } from "@ploy-lib/rest-resources";
import { Suspense } from "react";
import {
	ColumnSortOrderEnum,
	ExpandableTable,
	ExpandableTableProps,
	PendingButton
} from "@ploy-ui/core";
import { LinearProgress, Grid, Paper } from "@material-ui/core";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { WholesaleOverviewStatus } from "./WholesaleOverviewStatus";
import { WholesaleOverviewCheckbox } from "./WholesaleOverviewCheckbox";
import { WholesaleOverviewSummaryDownloader } from "./WholesaleOverviewSummaryDownloader";

export interface WholesaleOverviewApplicationsProps {
	cars?: WholesaleCar[];
	redeemApplications: (cars: WholesaleCar[]) => any;
}

const WholesaleOverviewApplications = (
	props: WholesaleOverviewApplicationsProps
) => {
	const { cars, redeemApplications } = props;

	return (
		<Paper
			style={{
				left: "10%",
				right: "10%"
			}}
			elevation={3}
		>
			<ExpandableTable
				idColumn="ApplicationNumber"
				tableData={cars as WholesaleCar[]}
				columnData={columnData}
				expandable={true}
				tableIsSortable
				renderExpandedSection={datarow => (
					<Suspense fallback={<LinearProgress />}>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							style={{ padding: "0.5em" }}
						>
							<Grid item style={{ padding: "1em", justifyContent: "center" }}>
								<FormattedMessage
									id="vendor.wholesale.click-to-fulfill"
									description="Click to fulfill"
									defaultMessage="Click «Fulfill withdrawal» to fulfill withdrawal. Amount automatically be withdrawn from your account."
								/>
							</Grid>
							<Grid item>
								<PendingButton
									onClick={() => {
										redeemApplications([datarow]);
									}}
									color="secondary"
									variant="contained"
									disabled={
										datarow.DaysUntilEndDateStatus === "black" ||
										datarow.Status !== 5
									}
								>
									<FormattedMessage
										id="vendor.wholesale.fulfill-button"
										description="Fulfill button"
										defaultMessage="Fulfill withdrawal"
									/>
								</PendingButton>
							</Grid>
						</Grid>
					</Suspense>
				)}
			/>
		</Paper>
	);
};

const columnData: ExpandableTableProps<WholesaleCar, any>["columnData"] = [
	{
		id: "WholesaleOverviewCheckbox",
		label: <WholesaleOverviewSummaryDownloader />,
		component: props => <WholesaleOverviewCheckbox {...props.rowData} />,
		sortOrder: ColumnSortOrderEnum.Disabled
	},
	{
		id: "DaysUntilEndDate",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-status"
				defaultMessage="Status"
			/>
		),
		component: props => <WholesaleOverviewStatus {...props.rowData} />
	},
	{
		id: "ApplicationNumber",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-appno"
				defaultMessage="App. number"
			/>
		)
	},
	{
		id: "ObjectDescription",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-desc"
				defaultMessage="Description"
			/>
		)
	},
	{
		id: "RegistrationNumber",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-regno"
				defaultMessage="Reg. number"
			/>
		)
	},
	{
		id: "ChassiNumber",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-chassis"
				defaultMessage="Chassis number"
			/>
		)
	},
	{
		id: "EndDate",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-enddate"
				defaultMessage="End date"
			/>
		)
	},
	{
		id: "LoanAmount",
		label: (
			<FormattedMessage
				id="vendor.wholesale.fulfillment-list-loanamount"
				defaultMessage="Loan amount"
			/>
		),
		component: props => (
			<div>
				<FormattedNumber
					value={props.rowData.LoanAmount}
					maximumFractionDigits={0}
				/>
				,-
			</div>
		)
	}
];

export { WholesaleOverviewApplications };
