import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
	root: {
		height: 70,
		textAlign: "center",
		fontWeight: "normal",
		marginBottom: 28
	},
	title: {
		fontSize: "2rem"
	}
}));

interface HeaderIconProps {
	icon?: string;
	text?: React.ReactNode;
}

const HeaderIcon = (props: HeaderIconProps) => {
	const { icon, text } = props;
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{icon && (
				<Typography>
					<i className={`fa fa-5x fa-${icon} fa-fw`} />
				</Typography>
			)}
			{text && <Typography className={classes.title}>{text}</Typography>}
		</div>
	);
};

HeaderIcon.displayName = "HeaderIcon";

export { HeaderIcon };
