import { FieldRoleData, SectionEditorOptions } from "@ploy-ui/form-fields";

const _registeredSectionLayouts: Record<string, RegisteredSectionLayout> = {};

export interface SectionSettings {
	fieldRoles?: readonly FieldRoleData[];
	editableOptions?: SectionEditorOptions;
	forceRenderAsHeaderWithoutGroup?: boolean;
}

/**
 * Intended to help interface-designer (and possibly others) with determining how to treat various propeties on Panel
 */
export interface RegisteredSectionLayout {
	name: string;
	displayName: string;
	settings: SectionSettings;
}

export function getRegisteredSectionLayouts(): Readonly<
	typeof _registeredSectionLayouts
> {
	return _registeredSectionLayouts;
}

export function hasRegisteredSectionLayout(layoutName: string) {
	return _registeredSectionLayouts.hasOwnProperty(layoutName);
}

export function addRegisteredSectionLayout(
	registeredSectionLayout: RegisteredSectionLayout
) {
	// if (_registeredSectionLayouts[registeredSectionLayout.name]) {
	// 	console.warn(
	// 		`_registeredSectionLayouts already contain a layout named ${
	// 			registeredSectionLayout.name
	// 		}, will override`
	// 	);
	// }

	_registeredSectionLayouts[registeredSectionLayout.name] =
		registeredSectionLayout;
}
