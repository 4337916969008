import { useDebugValue } from "react";
import { useTheme } from "@material-ui/core/styles";
import { ComponentsPropsList } from "@material-ui/core/styles/props";
import { getThemeProps } from "@material-ui/styles";

import "../dployTheme";

interface ThemeWithProps<Components> {
	props?: { [K in keyof Components]: Partial<Components[K]> };
}

export function useThemeProps<T extends {}>(
	props: T,
	name: keyof ComponentsPropsList
): T {
	const theme = useTheme() as ThemeWithProps<ComponentsPropsList>;

	const themeProps = getThemeProps({ theme, name, props: { ...props } });

	useDebugValue(themeProps);

	return themeProps;
}
