import { useDebugValue, useCallback } from "react";
import { useCalculation, useHasCalculation } from "../context";
import { getInNamespaced, existsInNamespaced } from "../../calculator/utils";
import { TemplateField } from "@ploy-lib/types";
import { useTemplateDebugManager } from "../debugContext";

/**
 * _NOT_ influenced by whether or not "debug mode" is enabled
 */
export function useFieldIsVisibleInternal(): <
	T extends Pick<TemplateField, "name" | "namespace">
>(
	field: T,
	defaultValue?: boolean
) => boolean {
	const hasCalc = useHasCalculation();
	const { defaultFieldVisibility, isFieldVisible } = useCalculation(
		"isFieldVisible",
		"defaultFieldVisibility"
	);

	return useCallback(
		(field, defaultValue = false) => {
			if (!hasCalc) return true;

			const visible = getInNamespaced(
				isFieldVisible,
				field.namespace,
				field.name
			);

			if (visible != null) return visible;

			return getInNamespaced(
				defaultFieldVisibility,
				field.namespace,
				field.name,
				defaultValue
			);
		},
		[defaultFieldVisibility, hasCalc, isFieldVisible]
	);
}

export function useFieldExists(): <
	T extends Pick<TemplateField, "name" | "namespace">
>(
	field: T
) => boolean {
	const { defaultFieldVisibility } = useCalculation("defaultFieldVisibility");

	return useCallback(
		field =>
			existsInNamespaced(defaultFieldVisibility, field.namespace, field.name),
		[defaultFieldVisibility]
	);
}

/**
 * Influenced by whether or not "debug mode" is enabled
 */
export function useFieldIsVisible(): <
	T extends Pick<TemplateField, "name" | "namespace">
>(
	field: T,
	defaultValue?: boolean
) => boolean {
	const isFieldVisible = useFieldIsVisibleInternal();

	const { isFieldVisible: isFieldvisibleDebug } = useTemplateDebugManager();

	return useCallback(
		(field, defaultValue = false) =>
			isFieldvisibleDebug(
				field as unknown as TemplateField,
				isFieldVisible(field, defaultValue)
			),
		[isFieldVisible, isFieldvisibleDebug]
	);
}

export function useIsAnyFieldVisible<
	T extends Pick<TemplateField, "name" | "namespace">
>(field: T | T[] = [], ...fields: T[]) {
	const fieldIsVisible = useFieldIsVisible();

	const isAnyFieldVisible = fields.concat(field).some(f => fieldIsVisible(f));

	useDebugValue(isAnyFieldVisible);

	return isAnyFieldVisible;
}

export function useAreAllFieldsVisible<
	T extends Pick<TemplateField, "name" | "namespace">
>(field: T | T[] = [], ...fields: T[]) {
	const fieldIsVisible = useFieldIsVisible();

	const areAllFieldsVisible = fields
		.concat(field)
		.every(f => fieldIsVisible(f));

	useDebugValue(areAllFieldsVisible);

	return areAllFieldsVisible;
}
