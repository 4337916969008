import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { AppBar, AppBarProps, Toolbar } from "@material-ui/core";
import { useTenantImage } from "@ploy-ui/tenants";

const useStyles = makeStyles(
	theme => ({
		root: {
			flexGrow: 1
		},
		appBar: {
			boxShadow: "none"
		},
		link: {
			display: "inline-block",
			verticalAlign: "top",
			maxHeight: 100
		},
		logo: {
			textAlign: "center",
			flexGrow: 1
		},
		flag: {
			height: 100,
			maxWidth: "100%"
		},
		toolbar: {},
		banner: {
			height: 40,
			minHeight: 40,
			color: theme.palette.secondary.contrastText,
			background: theme.palette.secondary.main,
			width: "100%",
			fontSize: 18
		},
		info: {
			alignSelf: "flex-end",
			fontSize: 11
		},
		name: {
			fontWeight: "bold",
			textTransform: "uppercase"
		},
		logout: {
			fontWeight: "bold",
			textDecoration: "none"
		}
	}),
	{ name: "Header" }
);

const resolveUrl = (url: string) =>
	url.replace("~", "").replace(/[^:]\/\//g, "/");

interface HeaderProps {
	showBanner?: boolean;
	showInfo?: boolean;
	homePage?: string;
	userFullName?: string;
}

function Header(props: HeaderProps & AppBarProps) {
	const { showBanner, showInfo, homePage, userFullName, ...rest } = props;

	const classes = useStyles(props);

	const homeUrl = homePage && resolveUrl(homePage);

	const loginInfo = userFullName ? (
		<Typography className={classes.info}>
			Du er logget inn som <span className={classes.name}>{userFullName} </span>
			<Link to="/logout" className={classes.logout}>
				Logg ut
			</Link>
		</Typography>
	) : null;

	const headerLogo = useTenantImage("headerLogo");

	return (
		<AppBar position="static" {...rest} className={classes.appBar}>
			<Toolbar className={classes.toolbar}>
				<div className={classes.logo}>
					{homeUrl ? (
						<a className={classes.link} href={homeUrl}>
							<img
								className={classes.flag}
								src={headerLogo ?? undefined}
								alt="Logo"
							/>
						</a>
					) : (
						<img
							className={classes.flag}
							src={headerLogo ?? undefined}
							alt="Logo"
						/>
					)}
				</div>
				{showInfo && loginInfo}
			</Toolbar>

			{showBanner && (
				<Toolbar className={classes.banner}>
					<Typography
						component={homeUrl ? "a" : Link}
						to={homeUrl ? undefined : "/"}
						href={homeUrl ?? undefined}
						color="inherit"
					>
						<i className="fa fa-home" />
					</Typography>
				</Toolbar>
			)}
		</AppBar>
	);
}

export { Header };
