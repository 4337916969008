import React from "react";
import Box from "@material-ui/core/Box";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";

export function ReleaseInfo() {
	const { buildDate } = useResource(VulcanDataResource.detail(), {});

	if (!buildDate) return null;

	return (
		<Box
			whiteSpace="normal"
			mt={2}
			fontSize={[10, 10, 10, 12]}
			textAlign="center"
			color="inherit"
		>
			Release: {buildDate}
		</Box>
	);
}
