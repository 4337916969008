import {
	LimitRedistributionSchema,
	DTDetailTableSchema,
	LimitDetailTableSchema,
	GuarantorTableSchema,
	ApplicationCustomersSchema,
	PropertySchema
} from "./index";

export const tableSchemas: Record<string, any> = {
	LimitDetailSchema: LimitDetailTableSchema,
	DTTableSchema: DTDetailTableSchema,
	LimitRedistributionSchema: LimitRedistributionSchema,
	GuarantorSchema: GuarantorTableSchema,
	ApplicationCustomersSchema: ApplicationCustomersSchema,
	PropertySchema: PropertySchema
};
