import React, { forwardRef } from "react";
import {
	useHref,
	useLinkClickHandler,
	useLocation,
	useResolvedPath,
	To
} from "react-router-dom";
import MuiLink, { LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import MuiButton, {
	ButtonProps as MuiButtonProps
} from "@material-ui/core/Button";
import MuiMenuItem, {
	MenuItemProps as MuiMenuItemProps
} from "@material-ui/core/MenuItem";
import MuiListItem, {
	ListItemProps as MuiListItemProps
} from "@material-ui/core/ListItem";
import { Omit } from "./types";
interface LinkProps {
	replace?: boolean;
	state?: any;
	to?: To;
	target?: string;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export function useLinkProps<TRest extends {} = {}>(
	props: LinkProps & TRest & { to: To }
): {
	href: string;
	onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
} & TRest;

export function useLinkProps<TRest extends {} = {}>(
	props: LinkProps & TRest & { to?: undefined }
): TRest;

export function useLinkProps<TRest extends {} = {}>(
	props: LinkProps & TRest & { to?: To }
): {
	href?: string;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
} & TRest;

export function useLinkProps<TRest extends {} = {}>(props: LinkProps & TRest) {
	const { to, target, replace, state, onClick, ...rest } = props;

	let href = useHref(to ?? "");
	let internalOnClick = useLinkClickHandler(to ?? "", {
		replace,
		state,
		target
	});

	if (to == null) return { ...rest, target };

	function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		onClick?.(event);
		if (!event.defaultPrevented) {
			internalOnClick(event);
		}
	}

	return { ...rest, href, onClick: handleClick };
}

interface ActiveStateProps {
	caseSensitive?: boolean;
	end?: boolean;
	to?: To;
}

export function useIsActive(props: ActiveStateProps): boolean {
	const { to, caseSensitive = false, end = false } = props;

	const location = useLocation();
	const path = useResolvedPath(to ?? "");

	if (to == null) return false;

	let locationPathname = location.pathname;
	let toPathname = path.pathname;
	if (!caseSensitive) {
		locationPathname = locationPathname.toLowerCase();
		toPathname = toPathname.toLowerCase();
	}

	const isActive =
		locationPathname === toPathname ||
		(!end &&
			locationPathname.startsWith(toPathname) &&
			locationPathname.charAt(toPathname.length) === "/");

	return isActive;
}

export type RouterLinkProps = MuiLinkProps & LinkProps;

export const RouterLink = forwardRef(
	(props: RouterLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
		<MuiLink ref={ref} {...useLinkProps(props)} underline="hover" />
	)
);

export type RouterMenuItemProps = Omit<
	MuiMenuItemProps<"a"> & LinkProps & ActiveStateProps,
	"button" | "selected" | "component"
>;

export const RouterMenuItem = forwardRef(
	(props: RouterMenuItemProps, ref: React.Ref<HTMLAnchorElement>) => {
		return (
			<MuiMenuItem
				{...useLinkProps(props)}
				ref={ref}
				component="a"
				button
				selected={useIsActive(props)}
			/>
		);
	}
);

export type RouterListItemProps = Omit<
	MuiListItemProps<"a"> & LinkProps & ActiveStateProps,
	"button" | "selected" | "component"
>;

export const RouterListItem = forwardRef(
	(props: RouterListItemProps, ref: React.Ref<HTMLAnchorElement>) => {
		return (
			<MuiListItem
				{...useLinkProps(props)}
				component="a"
				ref={ref}
				button
				selected={useIsActive(props)}
			/>
		);
	}
);

export type RouterButtonProps = Omit<
	MuiButtonProps<"a"> & LinkProps,
	"component"
>;

export const RouterButton = forwardRef(
	(props: RouterButtonProps, ref: React.Ref<HTMLAnchorElement>) => {
		return <MuiButton {...useLinkProps(props)} ref={ref} component="a" />;
	}
);
