import React from "react";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import { Divider } from "@material-ui/core";

export const SeparatorField = () => {
	return <Divider style={{ width: "100%" }} />;
};

SeparatorField.displayName = "SeparatorField";

export const EditorSeparator = identityRecordOfFieldEditorOptions({
	SeparatorField: {
		editableOptions: {}
	}
});
