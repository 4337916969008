import { ConfigElement } from ".";
import { Dispatch, SetStateAction, useState } from "react";
import { FormikErrors, Field } from "formik";
import React from "react";
import {
	ListItem,
	Card,
	CardActions,
	IconButton,
	CardMedia,
	Grid,
	GridSize
} from "@material-ui/core";
import {
	ExpandMore,
	ArrowBack,
	Delete,
	Save,
	ChevronRight
} from "@material-ui/icons";
import { Button } from "..";
import { TextField } from "@ploy-ui/form-fields";
import { UnsavedChangesCard } from "./UnsavedChangesCard";
import isEqual from "lodash/isEqual";

export interface CloseRequest {
	closeCallback: () => any;
	cancelCallback: () => any;
}

interface ElemementProps<RowData extends Object> {
	index: number;
	elementIdKey: string;
	element: RowData;
	elementConfig: ConfigElement<RowData>[];
	isExpandable: boolean;
	initialOpen: boolean;
	editSingle: boolean;
	margin: string;
	formValue?: RowData;
	setFormValues?: any;
	collapsedElement?: (values: RowData) => JSX.Element;
	isSelected?: boolean;
	setSelected?: Dispatch<SetStateAction<string | undefined>>;
	onSave?: () => Promise<void | undefined>;
	onDelete?: (value: RowData, index: number) => any;
	errors?: FormikErrors<RowData> | string | undefined;
	useThemedButtons?: boolean;
	closeRequest?: CloseRequest;
	isNewElement?: (item: RowData) => boolean;
	canDelete?: boolean;
	removeElementFromForm?: (values: RowData, index: number) => any;
	divider?: boolean;
	autofillFunction?: (row: RowData, setData: any) => void;
	columns?: number;
	canSave?: Boolean;
}

export function ExpandableElement<RowData extends Object>(
	props: ElemementProps<RowData>
) {
	const {
		index,
		elementIdKey,
		element,
		elementConfig,
		isExpandable,
		collapsedElement = SmallElement,
		initialOpen,
		isSelected,
		setSelected,
		onSave,
		onDelete,
		editSingle,
		margin,
		formValue,
		setFormValues,
		errors = false,
		useThemedButtons,
		closeRequest,
		isNewElement,
		canDelete,
		removeElementFromForm,
		divider = true,
		autofillFunction,
		columns = 3,
		canSave = true
	} = props;
	const [open, setOpen] = useState(initialOpen);
	const prefix = editSingle ? "" : `elements.${index}.`;

	!editSingle && errors && !open && setOpen(true);

	const [unsavedChanges, setUnsavedChanges] = useState(
		closeRequest ? true : false
	);

	if (
		closeRequest &&
		isSelected &&
		formValue === element &&
		isNewElement &&
		!isNewElement(element)
	) {
		closeRequest.closeCallback();
	}

	return (
		<>
			{editSingle && (
				<UnsavedChangesCard
					open={closeRequest !== undefined || unsavedChanges}
					onCancel={() => {
						setUnsavedChanges(false);
						closeRequest && closeRequest.cancelCallback();
					}}
					onDiscard={() => {
						setFormValues && setFormValues(element);
						setUnsavedChanges(false);
						setOpen(false);
						setSelected && setSelected(undefined);
						closeRequest && closeRequest.closeCallback();
						if (isNewElement && isNewElement(element)) {
							removeElementFromForm &&
								removeElementFromForm(formValue || element, index);
						}
					}}
				/>
			)}
			{!isExpandable || open || isSelected ? (
				<ListItem
					divider={!editSingle || (!open && !isSelected && isExpandable)}
					key={`listItem.${element[elementIdKey]}`}
				>
					<Card elevation={0} style={{ width: "100%" }}>
						{!editSingle && isExpandable && (
							<CardActions>
								<FormButton
									onClick={() => {
										setOpen(false);
										setSelected && setSelected(undefined);
									}}
									Icon={ExpandMore}
								/>
							</CardActions>
						)}
						<CardMedia style={{ margin: "25px" }}>
							<Grid item container spacing={2}>
								{elementConfig.map((configElement, i) => {
									const hidden =
										typeof configElement.hidden === "function"
											? configElement.hidden(element)
											: configElement.hidden || false;
									return (
										!hidden && (
											<Grid
												xs={12}
												md={Math.max(6, 12 / columns) as GridSize}
												lg={(12 / columns) as GridSize}
												key={configElement.name}
												item
												style={{
													marginTop: !isExpandable ? "30px" : undefined
												}}
											>
												<Field
													name={prefix + configElement.name}
													component={configElement.render || CustomField}
													configElement={configElement}
													autofillFunction={autofillFunction}
													margin={margin}
													disabled={
														typeof configElement.disabled === "function"
															? configElement.disabled(element)
															: configElement.disabled || false
													}
												/>
											</Grid>
										)
									);
								})}
							</Grid>
						</CardMedia>
						<CardActions style={{ marginLeft: "20px", marginRight: "20px" }}>
							{editSingle && (
								<FormButton
									onClick={() => {
										if (
											editSingle &&
											(!isEqual(formValue, element) ||
												(isNewElement && isNewElement(element)))
										) {
											setUnsavedChanges(true);
										} else {
											setOpen(false);
											setSelected && setSelected(undefined);
										}
									}}
									Icon={ArrowBack}
									tennantTheme={useThemedButtons ? "primary" : undefined}
									style={{ marginRight: "auto" }}
								/>
							)}
							{(canDelete || !editSingle) && (
								<FormButton
									onClick={() => {
										onDelete && onDelete(element, index);
										setSelected && setSelected(undefined);
										setOpen(false);
									}}
									Icon={Delete}
									tennantTheme={useThemedButtons ? "secondary" : undefined}
								/>
							)}
							{onSave && canSave && (
								<FormButton
									onClick={onSave}
									Icon={Save}
									tennantTheme={useThemedButtons ? "secondary" : undefined}
								/>
							)}
						</CardActions>
					</Card>
				</ListItem>
			) : (
				<ListItem
					key={`listItem.collapsed.${element[elementIdKey]}`}
					divider={divider}
					button
					onClick={() => {
						!open && setOpen(true);
						setSelected && setSelected(element[elementIdKey]);
					}}
				>
					{collapsedElement(element)}
				</ListItem>
			)}
		</>
	);
}

const CustomField = ({
	configElement,
	autofillFunction,
	field,
	form,
	onBlur,
	...props
}) => (
	<TextField
		label={configElement.title}
		field={field}
		form={form}
		fullWidth
		meta={{ value: field.value, touched: false, initialTouched: false }}
		onBlur={
			configElement.autofillKey && autofillFunction ? autofillFunction : onBlur
		}
		{...props}
	/>
);

const SmallElement = values => {
	return (
		<Grid container>
			<ChevronRight />
		</Grid>
	);
};

function FormButton(props: {
	onClick: () => any;
	Icon: (props) => JSX.Element;
	tennantTheme?: "primary" | "secondary";
	style?: Object;
}) {
	const { onClick, Icon, tennantTheme, style } = props;
	return tennantTheme ? (
		<Button
			style={style}
			color={tennantTheme}
			variant="contained"
			onClick={onClick}
		>
			<Icon />
		</Button>
	) : (
		<IconButton style={style} onClick={onClick}>
			<Icon />
		</IconButton>
	);
}
