import React from "react";
import { DployTextFieldProps, DployTextField } from "@ploy-ui/form-fields";
import { useField, FieldInputProps } from "formik";

type FormikTextFieldProps = Omit<
	DployTextFieldProps,
	keyof FieldInputProps<any>
> & { name: string };

export function FormikTextField(props: FormikTextFieldProps) {
	const { name, helperText, ...rest } = props;

	const [field, meta] = useField<string>(name);

	const error = meta.error;

	const showError = Boolean(error) && meta.touched;

	return (
		<DployTextField
			{...rest}
			{...field}
			value={field.value ?? ""}
			error={showError}
			helperText={showError ? error : helperText}
		/>
	);
}
