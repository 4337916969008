import {
	ServiceBodyValue,
	ServiceBodyVariable,
	ServiceBodyField,
	ServiceBodyVariableField
} from "../types";
import { NetworkError } from "@ploy-lib/types";
import { ServiceBodyStaticValue } from "..";
import { DployContextStore } from "@ploy-lib/core";

export { getIn, setIn } from "@ploy-lib/core";

export function isBodyVariable(
	value: ServiceBodyValue
): value is ServiceBodyVariable {
	return (value as ServiceBodyVariable).variable != null;
}
export function isBodyField(
	value: ServiceBodyValue
): value is ServiceBodyField {
	return (value as ServiceBodyField).field != null;
}
export function isBodyVariableField(
	value: ServiceBodyValue
): value is ServiceBodyVariableField {
	return isBodyField(value) && isBodyVariable(value);
}
export function isBodyStaticValue(
	value: ServiceBodyValue
): value is ServiceBodyStaticValue {
	return (value as ServiceBodyStaticValue).name != null;
}

const validHttpVerbs = ["POST", "GET", "PUT", "DELETE", "PATCH"] as const;
export type Method = typeof validHttpVerbs[number];

export function isMethod(methodCandidate?: string): methodCandidate is Method {
	if (methodCandidate && validHttpVerbs.includes(methodCandidate as any))
		return true;
	return false;
}

export async function fetchResponse(
	method: Method,
	url: string,
	body?: Readonly<object | string>
) {
	const headers = {
		Accept: "application/json;application/pdf",
		...(DployContextStore.context ?? {})
	};

	let options: RequestInit = {
		method,
		headers
	};

	if (body) {
		options.body =
			body instanceof Blob
				? body
				: new Blob([JSON.stringify(body)], {
						type: "application/json"
				  });
	}

	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			throw new NetworkError(response);
		}

		return response;
	} catch (error: any) {
		// ensure CORS, network down, and parse errors are still caught by NetworkErrorBoundary
		if (error instanceof TypeError) {
			(error as any).status = 400;
		}

		throw error;
	}
}

export async function fetchData(
	method: Method,
	url: string,
	body?: Readonly<object | string>
) {
	const response = await fetchResponse(method, url, body);

	const contentType = response.headers.get("Content-Type");

	if (contentType?.includes("application/pdf")) {
		const blob = await response.blob();

		const contentDisposition = response.headers.get("Content-Disposition");
		const filenameMatch = contentDisposition?.match(
			/filename\*?=(.*'')?"?([^"']*)"?$/
		);

		const filename =
			(filenameMatch?.length ?? 0) >= 2
				? filenameMatch![2]
				: contentDisposition ?? "";

		//IE workaround
		if (window.navigator?.hasOwnProperty("msSaveBlob")) {
			(blob as any).name = filename;
			return blob;
		}

		return new File([blob], filename);
	}

	if (!contentType?.includes("json") || response.status === 204)
		return response.text();

	try {
		return await response.json();
	} catch (error: any) {
		error.status = 400;
		throw error;
	}
}
