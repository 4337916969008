import Charts from "./Charts";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { sources, sourceMapTitles } from "./common";
import { ChartDetailKey, ChartResource } from "@ploy-lib/rest-resources";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage } from "react-intl";

applyPropertyControls(Charts, {
	source: {
		type: ControlType.Enum,
		title: (
			<FormattedMessage
				id="dploy.grid.charts.source.label"
				defaultMessage="Data source"
			/>
		),
		options: sources,
		optionTitles: sources.map(k => sourceMapTitles[k]),
		defaultValue: sources[0],
		coerce: (value, values) => {
			const source = sources.find(name =>
				ChartResource[name]?.()?.getFetchKey({}).includes(values.loadUrl)
			);
			return source;
		},
		toolTipHelperText: (resourceDetailKey: ChartDetailKey) =>
			ChartResource[resourceDetailKey]?.()?.key({})?.split(" ")[1] //Returns e.g. /Reporting/GetSalesOverview
	},
	boxProps: contentBoxPropertyControls
});

export default Charts;
