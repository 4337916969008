import { Suspense, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { LanguageSelector } from "./LanguageSelector";
import { Box } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import { useResource } from "@rest-hooks/core";
import { LoginResource } from "@ploy-lib/rest-resources";
import { LoginWrapper } from "@ploy-ui/login";
import { useBrowserDeprecationStatus } from "@ploy-lib/core";
import {
	DeprecationMessage,
	ThirdPartyScripts,
	ThirdPartyScriptsContext,
	useThirdPartyScriptsContext
} from "@ploy-ui/core";
import { useThemeProps } from "@ploy-ui/tenants";
import { ErrorPageHandler } from "./ErrorPageHandler";

const useStyles = makeStyles(
	theme => ({
		root: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper
		},
		form: {
			width: "100%",
			maxWidth: 320 + theme.spacing(4),
			margin: "31px auto",
			minHeight: 278,
			padding: theme.spacing(2)
		},
		formWrapper: {
			flexGrow: 1
		}
	}),
	{ name: "SimpleLayout" }
);

interface SimpleLayoutProps {
	elevation?: number;
	footerVisible?: boolean;
}

SimpleLayout.displayName = "SimpleLayout";
export function SimpleLayout(
	props: React.PropsWithChildren<SimpleLayoutProps>
) {
	const {
		elevation,
		footerVisible,
		children = <Outlet />
	} = useThemeProps(props, "SimpleLayout");

	const classes = useStyles(props);

	const loginData = useResource(LoginResource.status(), {});

	const status = useBrowserDeprecationStatus(loginData.browserDeprecation);

	const [dismissed, setDismissed] = useState(false);

	return (
		<LoginWrapper
			classes={classes}
			footerVisible={footerVisible}
			elevation={elevation}
		>
			<ThirdPartyScriptsContext.Provider value={useThirdPartyScriptsContext()}>
				{!status.supported || (status.deprecated && !dismissed) ? (
					<Box p={1}>
						<DeprecationMessage
							onDismiss={() => setDismissed(true)}
							browserDeprecation={loginData.browserDeprecation}
						/>
					</Box>
				) : (
					<ErrorPageHandler sx={{ p: 3 }}>{children}</ErrorPageHandler>
				)}

				<Box p={1}>
					<LanguageSelector />
				</Box>

				<Suspense fallback={null}>
					<ThirdPartyScripts />
				</Suspense>
			</ThirdPartyScriptsContext.Provider>
		</LoginWrapper>
	);
}
