import { useMemo, lazy } from "react";
import type { MaterialTableProps, Column } from "@material-table/core";
import type MaterialTableType from "@material-table/core";
import { MessageDescriptor, IntlShape, useIntl } from "react-intl";
import mapValues from "lodash/mapValues";

import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery, useTheme, Typography } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ListCell } from "./ListCell";
import { tableIcons } from "./tableIcons";
import { messages } from "./messages";
import { DployColumn } from "./types";
import {
	getNumberEdit,
	getDateEdit,
	getDateTimeEdit,
	getTimeEdit,
	TextEdit
} from "./cellEditors";
import {
	getNumberRenderer,
	getDateRenderer,
	getTimeRenderer
} from "./cellRenderers";

const MaterialTable = lazy(
	() => import(/* webpackChunkName: "material-table"  */ "@material-table/core")
) as unknown as typeof MaterialTableType;

const mapToFormattedMessage = (
	intl: IntlShape,
	obj: Record<string, MessageDescriptor>
) => mapValues(obj, m => intl.formatMessage(m));

export type DataTableProps<T extends object> = Omit<
	MaterialTableProps<T>,
	"columns"
> & {
	listCellsBreakpoint?: Breakpoint | number;
	columns: DployColumn<T>[];
};

function fromDployColumn<RowData extends object>(
	column: DployColumn<RowData>
): Column<RowData> {
	switch (column.type) {
		case "numeric":
			return {
				render: getNumberRenderer(column),
				editComponent: getNumberEdit(column),
				...column,
				type: column.type
			};
		case "currency":
			return {
				render: getNumberRenderer(column, "currency"),
				editComponent: getNumberEdit(column, "currency_input"),
				...column,
				type: column.type
			};
		case "percent":
		case "percentFraction":
			return {
				render: getNumberRenderer(column, "percent"),
				editComponent: getNumberEdit(column, "percent_input"),
				...column,
				type: "numeric"
			};
		case "date":
			return {
				render: getDateRenderer(column),
				editComponent: getDateEdit(column),
				...column,
				type: column.type
			};
		case "datetime":
			return {
				render: getDateRenderer(column, "long_datetime"),
				editComponent: getDateTimeEdit(column, "long_datetime"),
				...column,
				type: column.type
			};
		case "time":
			return {
				render: getTimeRenderer(column),
				editComponent: getTimeEdit(column),
				...column,
				type: column.type
			};
		case "boolean":
			return column as Column<RowData>;
		case "string":
			return {
				editComponent: TextEdit,
				...column,
				type: undefined
			};
		default:
			return {
				editComponent: TextEdit,
				...column,
				type: column.type
			};
	}
}

function DployTable<RowData extends object>(props: DataTableProps<RowData>) {
	const { listCellsBreakpoint = 0, ...rest } = props;

	const icons = useMemo<typeof props.icons>(
		() => ({
			...tableIcons,
			...props.icons
		}),
		[props.icons]
	);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down(listCellsBreakpoint));

	const options = useMemo<typeof props.options>(
		() =>
			isMobile
				? {
						...props.options,
						header: false
				  }
				: props.options,
		[isMobile, props.options]
	);

	let columns = useMemo(
		() => props.columns.map(fromDployColumn),
		[props.columns]
	);

	columns = useMemo<typeof columns>(() => {
		if (isMobile) {
			return [
				{
					field: "mobileField",
					title: "mobil",
					render: rowData => (
						<ListCell
							rowData={rowData}
							columns={columns.filter(x => !x.hidden)}
						/>
					)
				}
			];
		}
		return columns;
	}, [isMobile, columns]);

	const intl = useIntl();

	const localization = useMemo<typeof props.localization>(() => {
		return {
			grouping: {
				...mapToFormattedMessage(intl, messages.grouping),
				...props.localization?.grouping
			},
			pagination: {
				...mapToFormattedMessage(intl, messages.pagination),
				...props.localization?.pagination
			},
			toolbar: {
				...mapToFormattedMessage(intl, messages.toolbar),
				...props.localization?.toolbar
			},
			header: {
				...mapToFormattedMessage(intl, messages.header),
				...props.localization?.header
			},
			body: {
				...mapToFormattedMessage(intl, messages.body),
				...props.localization?.body,
				filterRow: {
					...mapToFormattedMessage(intl, messages.bodyFilterRow),
					...props.localization?.body?.filterRow
				},
				editRow: {
					...mapToFormattedMessage(intl, messages.bodyEditRow),
					...props.localization?.body?.editRow
				}
			}
		};
	}, [intl, props.localization]);

	const components = useMemo<typeof props.components>(
		() => ({
			Container: ({ children }) => children,
			...props.components
		}),
		[props.components]
	);

	// const data = useMemo(
	// 	() =>
	// 		props.data && typeof (props.data as any).map === "function"
	// 			? (props.data as any).map(d => ({ ...d }))
	// 			: props.data,
	// 	[props.data]
	// );

	const title = useMemo(
		() =>
			typeof props.title === "string" ? (
				<Typography variant="h6" style={{ margin: 11, marginLeft: 0 }}>
					{props.title}
				</Typography>
			) : (
				props.title
			),
		[props.title]
	);

	return (
		<>
			<MaterialTable
				{...rest}
				title={title}
				options={options}
				columns={columns as object[]}
				components={components}
				localization={localization}
				icons={icons}
			/>
		</>
	);
}

DployTable.displayName = "DployTable";

export { DployTable };
