import Competitions, { allColumns } from "./Competitions";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { CompetitionResult } from "@ploy-lib/rest-resources";
import { columnTitleMessages } from ".";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage } from "react-intl";

const columnNames = allColumns
	.map(c => c.field as keyof CompetitionResult)
	.filter(isNotNull);

const columnTitles = allColumns
	.map(c =>
		typeof c.field === "string"
			? columnTitleMessages[c.field]
			: c.field?.join(", ")
	)
	.filter(isNotNull);

applyPropertyControls(Competitions, {
	visibleColumns: {
		type: ControlType.Array,
		title: (
			<FormattedMessage
				id="dploy.grid.competitions.visibleColumns.label"
				defaultMessage="Visible columns"
			/>
		),
		propertyControl: {
			type: ControlType.Enum,
			title: "",
			options: columnNames,
			optionTitles: columnTitles
		},
		defaultValue: columnNames,
		coerce: (_, { rawColumns }: { rawColumns?: string }) =>
			rawColumns
				?.toLowerCase()
				.split("\n")
				.map(n => {
					if (n) {
						return columnNames.find(name => name.toLowerCase() === n);
					}
					return null;
				})
				.filter(isNotNull)
	},
	scrollable: {
		type: ControlType.Boolean,
		title: (
			<FormattedMessage
				id="dploy.grid.competitions.scrollable.label"
				defaultMessage="Scrollable table"
			/>
		),
		defaultValue: false,
		coerce: value => (String(value) === "1" ? true : false)
	},
	boxProps: contentBoxPropertyControls
});

export default Competitions;
