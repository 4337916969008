import { Section, SectionProps } from "..";
import { useSignerSetup } from "../../hooks";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useAppLoad } from "../../appLoadContext";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import { makeStyles, Typography } from "@material-ui/core";
import { defineMessages } from "react-intl";

addRegisteredSectionLayout({
	name: "ESignMessageSection",
	displayName: "ESignMessageSection",
	settings: {}
});

const useStyles = makeStyles(theme => ({
	redAndCenter: {
		color: theme.palette.error.main,
		textAlign: "center"
	}
}));

const messages = defineMessages({
	incompleteSignerSetup: {
		id: "ploy-ui.template-form.esignMessageSection.SelectSigners",
		description: "Shown when signers are missing",
		defaultMessage: "(Select people to sign)"
	},
	sentByInternalUser: {
		id: "ploy-ui.template-form.esignMessageSection.DocumentsSentToESignByInternalUser",
		description: "Sent by internal user",
		defaultMessage: "(Sent by internal user)"
	},
	sentToEsign: {
		id: "ploy-ui.template-form.esignMessageSection.DocumentsSentToESign",
		description: "Shown when documents has previously been sent to eSign",
		defaultMessage: "Documents sent to eSign {date} {sender}"
	}
});

const ESignMessageSection = (props: SectionProps) => {
	const intl = useIntl();
	const classes = useStyles();
	const setup = useSignerSetup();
	const { sentToEsignature, isSentToEsignatureByInternal } = useAppLoad();
	const { vendorName } = useResource(VulcanDataResource.detail(), {});
	const errorMessages: MessageDescriptor[] = [];

	if (!setup.loading && setup.incompleteSignerSetup && !setup.errors) {
		errorMessages.push(messages.incompleteSignerSetup);
	}

	return (
		<>
			{sentToEsignature && (
				<Typography className={classes.redAndCenter}>
					<FormattedMessage
						{...messages.sentToEsign}
						values={{
							date: new Date(sentToEsignature).toLocaleString(intl.locale),
							sender:
								isSentToEsignatureByInternal == null
									? ""
									: isSentToEsignatureByInternal
									? intl.formatMessage(messages.sentByInternalUser)
									: "(" + vendorName + ")"
						}}
					/>
				</Typography>
			)}
			<Section {...props} />

			{setup.errors?.map((message, index) => (
				<Typography key={index} className={classes.redAndCenter}>
					{message}
				</Typography>
			))}
			{errorMessages.map((message, index) => (
				<Typography key={index} className={classes.redAndCenter}>
					{intl.formatMessage(message)}
				</Typography>
			))}
		</>
	);
};

export { ESignMessageSection };
