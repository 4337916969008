import { BaseResource } from "../resources/BaseResource";
import {
	deeplyApplyKeyTransform,
	deeplyApplyValueTransform
} from "@ploy-lib/core";
import camelCase from "lodash/camelCase";
import pRetry from "p-retry";

export abstract class BasePlainResource extends BaseResource {
	protected static endpoint() {
		return this.memo("#endpointPlain", () => {
			return super.endpoint().extend({
				method: "POST"
			});
		});
	}
}

export abstract class BaseCamelCasedResource extends BasePlainResource {
	static async fetch(input: RequestInfo, init: RequestInit) {
		const jsonResponse = await super.fetch(input, init);

		const data = deeplyApplyKeyTransform(jsonResponse, camelCase);
		const dataWithoutNull = deeplyApplyValueTransform(data, v =>
			v == null ? undefined : v
		);

		return dataWithoutNull as any;
	}
}

export abstract class BaseResourceWithRetry extends BaseResource {
	static async fetch(input: RequestInfo, init: RequestInit) {
		const options = {
			retries: 3
		};

		const run = async () => await super.fetch(input, init);

		return await pRetry(run, options);
	}
}
