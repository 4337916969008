import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export class BeneficialOwnerHistoryResource extends BaseCamelCasedResource {
	readonly beneficialOwnerHistoryId: string = "";
	readonly customerId: number | null;
	readonly entNr: string | null;
	readonly firstname: string | null;
	readonly lastname: string | null;
	readonly name: string | null;
	readonly address: string | null;
	readonly addressCity: string | null;
	readonly addressZip: string | null;
	readonly share: string | null;
	readonly isActualBeneficialOwner: boolean = false;
	readonly addUser: string | null;
	readonly updatedUser: string | null;
	readonly addDate: string | null;
	readonly updatedDate: string | undefined;
	readonly citizenships: string | undefined;
	readonly eventCode: string | undefined;
	readonly eventContext: string | undefined;
	readonly hiddenByFilter: boolean | null;
	readonly citizenshipControlled: boolean | null;
	readonly changedFields: Map<string, string>;

	pk() {
		return this.beneficialOwnerHistoryId;
	}

	static listUrl(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		const { customerId } = allParams;

		if (customerId !== undefined) {
			return `${this.urlRoot}/history/${customerId}`;
		}
		throw new Error("BeneficialOwnerHistoryResource requires customerId");
	}

	static urlRoot = apiResourceUrl("beneficial-owner-v2/");

	static urlTemplates = [
		`${BeneficialOwnerHistoryResource.urlRoot}/history/{customerId}`
	];
}
