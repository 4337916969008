import React, { useState } from "react";
import { useResource } from "@rest-hooks/core";
import {
	VendorSalespersonResource,
	ID,
	LoginResource,
	VendorSalesperson
} from "@ploy-lib/rest-resources";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { legacyApiResourceUrl } from "@ploy-lib/core";

interface VendorSalespersonSelectProps {
	salespersonId?: ID;
	vendorId?: ID;
	applicationId?: ID;
}

export const VendorSalespersonSelector = (
	props: VendorSalespersonSelectProps
) => {
	const { salespersonId, vendorId, applicationId } = props;

	const { vendor: currentVendor } = useResource(LoginResource.status(), {});

	const vendorSalespeople = useResource(
		VendorSalespersonResource.list(),
		{
			vendorid: currentVendor?.id
		} || []
	) as VendorSalesperson[];

	const [selected, setSelected] = useState(
		vendorSalespeople.find(x => x.key === `${vendorId}-${salespersonId}`)
	);

	const handleSalespersonChange = (
		e: React.ChangeEvent<{}>,
		value: VendorSalesperson | null
	) => {
		setSelected(value ?? undefined);

		if (!value) return;

		fetch(
			legacyApiResourceUrl(
				`Reassignment.ReassignApplications/ReasignVendorSalesperson`
			),
			{
				method: "POST",
				body: new Blob(
					[
						JSON.stringify({
							salespersonId: value.salepersonId,
							applicationId: applicationId,
							vendorId: value.vendorId
						})
					],
					{
						type: "application/json"
					}
				),
				headers: {
					accept: "application/json"
				}
			}
		);
	};

	return (
		<DployAutocomplete
			style={{ width: 300 }}
			value={selected}
			items={vendorSalespeople}
			onChange={handleSalespersonChange}
			fullWidth
			getItemLabel={item => item.name}
			disableClearable
		/>
	);
};
