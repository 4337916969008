import React from "react";

import WarningIcon from "@material-ui/icons/Warning";
import { Button, MarkdownElement } from "..";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useBrowserDeprecationStatus } from "@ploy-lib/core";
import { Box } from "@material-ui/core";
import { BrowserDeprecation } from "@ploy-lib/types";

const messages = defineMessages({
	deprecated: {
		id: "dealer.browser.deprecated.message",
		defaultMessage: `
#### Nettleseren din er snart for gammel

Fra {date, date, long} vil du av hensyn til sikkerhet ikke lenger kunne logge inn med {deprecated_version, select,
	all {denne nettleseren.}
	other {denne versjonen av {browser_name}.}
}

---

* Nettleser: {browser_name} {browser_version}
* Operativsystem: {os_name} {os_version}

---

{deprecated_version, select,
	all {Bruk en annen nettleser for å kunne fortsette å logge inn.}
	other {Oppdater til en versjon som er nyere enn {browser_name} {deprecated_version}, eller bruk en annen nettleser for å kunne fortsette å logge inn.}
}

Microsoft Edge er en moderne nettleser som er tilgjengelig for Windows 7 og nyere, og følger med som standard i Windows 10. Firefox og Chrome er også gode alternativer.

[Husk å alltid bruke en oppdatert nettleser, les mer om hvorfor her](https://nettvett.no/oppdateringer/)
`
	},
	unsupported: {
		id: "dealer.browser.unsupported.message",
		defaultMessage: `
#### Nettleseren din er for gammel

Av hensyn til sikkerhet kan du ikke logge inn med {deprecated_version, select,
	all {denne nettleseren.}
	other {denne versjonen av {browser_name}.}
}.

---

* Nettleser: {browser_name} {browser_version}
* Operativsystem: {os_name} {os_version}

---

{deprecated_version, select,
	all {Bruk en annen nettleser for å logge inn.}
	other {Oppdater til en versjon som er nyere enn {browser_name} {deprecated_version}, eller bruk en annen nettleser for å logge inn.}
}

Microsoft Edge er en moderne nettleser som er tilgjengelig for Windows 7 og nyere, og følger med som standard i Windows 10. Firefox og Chrome er også gode alternativer.

[Husk å alltid bruke en oppdatert nettleser, les mer om hvorfor her](https://nettvett.no/oppdateringer/)
`
	}
});

export interface DeprecationMessageProps {
	onDismiss: () => void;
	browserDeprecation: BrowserDeprecation;
}

export function DeprecationMessage(props: DeprecationMessageProps) {
	const { onDismiss, browserDeprecation } = props;

	const status = useBrowserDeprecationStatus(browserDeprecation);

	const intl = useIntl();

	if (status.supported && !status.deprecated) return null;

	const { supported, browser, date, os, deprecatedVersion } = status;

	return (
		<div>
			<Box
				textAlign="center"
				color={supported ? "warning.light" : "error.main"}
			>
				<WarningIcon color="inherit" fontSize="large" />
			</Box>

			<MarkdownElement
				text={intl.formatMessage(
					supported ? messages.deprecated : messages.unsupported,
					{
						browser_name: browser.name,
						browser_version: browser.version,
						os_name: os.name,
						os_version: os.version,
						date,
						deprecated_version:
							deprecatedVersion === "*" ? "all" : deprecatedVersion
					}
				)}
			/>

			{supported && (
				<Button fullWidth onClick={onDismiss}>
					<FormattedMessage
						id="dealer.login.deprecation.dismiss.label"
						defaultMessage="Gå videre til innlogging"
					/>
				</Button>
			)}
		</div>
	);
}
