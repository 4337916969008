import { v4 as uuidv4 } from "uuid";
import { ID } from "..";
import { BaseCamelCasedResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface VendorDriver {
	id: ID;
	driverId?: ID;
	customerId?: ID;
	firstname: string;
	lastname: string;
	mobile: string;
	email: string;
	employeeNumber: string;
	costPlace: string;
	regNumber: string;
	privateMileage: string;
	workMileage: string;
}

export class DriverResource
	extends BaseCamelCasedResource
	implements VendorDriver
{
	readonly driverId?: ID = undefined;
	readonly id: ID = this.driverId ?? uuidv4();
	readonly customerId?: ID;
	readonly firstname: string = "";
	readonly lastname: string = "";
	readonly mobile: string = "";
	readonly email: string = "";
	readonly employeeNumber: string = "";
	readonly costPlace: string = "";
	readonly regNumber: string = "";
	readonly privateMileage: string = "";
	readonly workMileage: string = "";

	pk() {
		return this.driverId?.toString() || this.id.toString();
	}

	static urlRoot = apiResourceUrl("drivers");
}
