import { createContext, useCallback, useRef, useState } from "react";
import { useResource } from "rest-hooks";
import { ThirdPartyScriptSettingsResource } from "@ploy-lib/rest-resources";
import { GoogleTracking } from "@ploy-lib/snippet-messaging-types";

export const ThirdPartyScriptsContext =
	createContext<IThirdPartyScriptsContext>({
		settings: {
			loadCookieBot: false,
			cookieBotId: "",
			loadGiosgChat: false,
			loadGoogleTagManager: false,
			googleTagManagerCode: "",
			googleTagManagerIncludeProduct: false,
			googleTagManagerIncludeObjectCategory: false,
			loadGoogleTagManagerExtended: false,
			loadZendeskChat: false,
			zendeskAccountKey: "",
			zendeskAlternativeAccountKey: "",
			loadDNBFIZendesk: false,
			loadZendeskOnLoginPage: true
		},
		google: {
			getHistory: () => [],
			push: (update: any) => {},
			pushAll: (id: string, update: any) => {},
			appendScript: false
		}
	});

export interface IThirdPartyScriptsContext {
	settings: {
		//	CookieBot
		loadCookieBot: boolean;
		cookieBotId?: string;

		// Giosg Chat
		loadGiosgChat: boolean;

		//	GTM
		loadGoogleTagManager: boolean;
		googleTagManagerCode?: string;
		googleTagManagerIncludeProduct?: boolean;
		googleTagManagerIncludeObjectCategory?: boolean;
		loadGoogleTagManagerExtended: boolean;

		//	Zendesk
		loadZendeskChat: boolean;
		zendeskAccountKey?: string;
		zendeskAlternativeAccountKey?: string;
		loadDNBFIZendesk: boolean;
		loadZendeskOnLoginPage: boolean;
	};

	google: {
		getHistory: () => Array<any>;
		push: (update: any) => void;
		pushAll: (id: string, update: any) => void;
		appendScript: boolean;
	};
}

export const useThirdPartyScriptsContext = () => {
	const isInIframe = useRef<boolean>(inIframe());
	const thirdPartySettings = useResource(
		ThirdPartyScriptSettingsResource.detail(),
		{}
	);

	return {
		settings: {
			loadCookieBot: !isInIframe.current && thirdPartySettings.loadCookieBot,
			cookieBotId: thirdPartySettings.cookieBotId,

			// Giosg Chat
			loadGiosgChat: !isInIframe.current && thirdPartySettings.loadGiosgChat,

			//	GTM
			loadGoogleTagManager: thirdPartySettings.loadGoogleTagManager,
			googleTagManagerCode: thirdPartySettings.googleTagManagerCode,
			googleTagManagerIncludeProduct:
				thirdPartySettings.googleTagManagerIncludeProduct,
			googleTagManagerIncludeObjectCategory:
				thirdPartySettings.googleTagManagerIncludeObjectCategory,
			loadGoogleTagManagerExtended:
				thirdPartySettings.loadGoogleTagManagerExtended,

			//	Zendesk
			loadZendeskChat:
				!isInIframe.current && thirdPartySettings.loadZendeskChat,
			zendeskAccountKey: thirdPartySettings.zendeskAccountKey,
			zendeskAlternativeAccountKey:
				thirdPartySettings.zendeskAlternativeAccountKey,
			loadDNBFIZendesk:
				!isInIframe.current && thirdPartySettings.loadDNBFIZendesk
		},
		google: useGoogle(thirdPartySettings.loadGoogleTagManager)
	} as IThirdPartyScriptsContext;
};

const useGoogle = (enabled: boolean) => {
	const isInIframe = useRef<boolean>(inIframe());
	const [googleShadowHistory, setGoogleShadowHistory] = useState<Array<any>>(
		[]
	);
	const [googlePushOnce, setGooglePushOnce] = useState<Array<string>>([]);

	const addGoogleShadowHistory = useCallback(
		(update: any) => setGoogleShadowHistory([...googleShadowHistory, update]),
		[googleShadowHistory]
	);

	const hasGooglePushOnce = useCallback(
		(id: string) => typeof googlePushOnce[id] === "string",
		[googlePushOnce]
	);

	const addGooglePushOnce = useCallback(
		(id: string) => setGooglePushOnce([...googlePushOnce, id]),
		[googlePushOnce]
	);

	const postToParrent = useCallback((update: any) => {
		if (window.parent) {
			window.parent.postMessage(
				JSON.stringify({
					type: "googleTracking",
					update: update
				} as GoogleTracking),
				"*"
			);
		}
	}, []);

	const getHistory = useCallback(() => {
		if (isInIframe.current) {
			return googleShadowHistory;
		} else {
			return window["dataLayer"] ?? [];
		}
	}, [googleShadowHistory]);

	const push = useCallback(
		(update: any) => {
			if (isInIframe.current) {
				addGoogleShadowHistory(update);
				postToParrent(update);
			} else {
				window["dataLayer"] = window["dataLayer"] || [];
				window["dataLayer"].push(update);
			}
		},
		[addGoogleShadowHistory, postToParrent]
	);

	const pushAll = useCallback(
		(id: string, update: any) => {
			if (!hasGooglePushOnce(id)) {
				if (isInIframe.current) {
					addGoogleShadowHistory(update);
					postToParrent(update);
				}

				window["dataLayer"] = window["dataLayer"] || [];
				window["dataLayer"].push(update);
				addGooglePushOnce(id);
			}
		},
		[
			addGooglePushOnce,
			addGoogleShadowHistory,
			hasGooglePushOnce,
			postToParrent
		]
	);

	return {
		getHistory,
		push,
		pushAll,
		appendScript: enabled && !isInIframe.current
	};
};

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e: any) {
		return true;
	}
}
