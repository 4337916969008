import {
	PageStateProviderProps,
	PageProvider,
	PageContextValue,
	HandlePageChangeForGTM
} from "@ploy-ui/template-form";
import { useCallback, useMemo, useEffect, memo, useState } from "react";
import {
	Route,
	Routes,
	useHref,
	useLocation,
	useNavigate,
	useParams
} from "react-router-dom";
import { useAbsolutePath, useParsedQuery } from "@ploy-lib/routing";

const clamp = (num: number, min: number, max: number) =>
	Math.min(Math.max(min, num), max);

const pagePath = ":page/*";

// The base prop is necessary because path routes and index routes behave differently when it comes to relative routing
// each ".." segment navigates up one path route, but ignores index routes.
export const RoutePageProvider = memo((props: PageStateProviderProps) => (
	<Routes>
		<Route
			path={pagePath}
			element={<RoutePageStateProvider base=".." {...props} />}
		/>
		<Route index element={<RoutePageStateProvider {...props} />} />
	</Routes>
));

function RoutePageStateProvider({
	labels,
	initialStep = 0,
	children,
	base = ""
}: PageStateProviderProps & { base?: string }) {
	const params = useParams<"page">();
	const { page = (initialStep + 1).toString() } = params;

	const { search = "", pathname } = useLocation();
	const navigate = useNavigate();

	const lowerCasePage = page && page.toLocaleLowerCase();
	const lowerCaseLabels = labels.map(l => l && l.toLocaleLowerCase());

	const numberOfSteps = lowerCaseLabels.length;

	const getTarget = useCallback(
		(idx: number) => {
			idx = clamp(idx, 0, numberOfSteps - 1);
			return lowerCaseLabels[idx] || (idx + 1).toString();
		},
		[lowerCaseLabels, numberOfSteps]
	);

	const baseHref = useHref(base);
	const getStepHref = useCallback(
		(idx: number) => `${baseHref}/${getTarget(idx)}${search}`,
		[baseHref, getTarget, search]
	);

	const basepath = useAbsolutePath(base);
	const getStepPath = useCallback(
		(idx: number) => `${basepath}/${getTarget(idx)}${search}`,
		[basepath, getTarget, search]
	);

	const stepStringIndex = Number.parseInt(lowerCasePage) - 1;

	const stepIndex = Number.isNaN(stepStringIndex)
		? lowerCaseLabels.indexOf(lowerCasePage)
		: stepStringIndex;

	const step = clamp(stepIndex, 0, numberOfSteps - 1);

	const [startIndex, _] = useState(step);

	const setStepIndex = useCallback(
		(idx: number, replace?: boolean) => {
			const href = getStepPath(idx);
			const currentHref = decodeURI(`${pathname}${search}`);

			if (currentHref !== href && navigate) {
				navigate(href, { replace });
			}
		},
		[getStepPath, pathname, search, navigate]
	);

	useEffect(() => {
		if (numberOfSteps > 1 && (lowerCasePage == null || stepIndex !== step)) {
			setStepIndex(step, true);
		}
	}, [
		lowerCasePage,
		setStepIndex,
		stepStringIndex,
		numberOfSteps,
		stepIndex,
		step
	]);

	const next = useCallback<PageContextValue["next"]>(
		() => setStepIndex(step + 1),
		[setStepIndex, step]
	);
	const prev = useCallback<PageContextValue["prev"]>(
		() => setStepIndex(step - 1),
		[setStepIndex, step]
	);
	const goto = useCallback<PageContextValue["goto"]>(
		(idx: number, replace?: boolean) => setStepIndex(idx, replace),
		[setStepIndex]
	);
	const reset = useCallback<PageContextValue["reset"]>(
		() => setStepIndex(0),
		[setStepIndex]
	);

	const state = useMemo<PageContextValue>(
		() => ({
			labels,
			next,
			prev,
			goto,
			reset,
			step,
			getTarget,
			getStepHref,
			lastStep: numberOfSteps - 1,
			initialStep: startIndex,
			totalSteps: numberOfSteps,
			isLastStep: step === numberOfSteps - 1
		}),

		[
			labels,
			next,
			prev,
			goto,
			reset,
			step,
			getTarget,
			getStepHref,
			numberOfSteps,
			startIndex
		]
	);

	return (
		<PageProvider value={state}>
			<HandlePageChangeForGTM step={stepIndex} />
			{children}
		</PageProvider>
	);
}
