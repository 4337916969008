import React from "react";
import type { EditComponentProps } from "@material-table/core";
import {
	IntlNumberField,
	DployTextField,
	IntlKeyboardDatePicker,
	IntlKeyboardTimePicker,
	IntlKeyboardDateTimePicker,
	IntlTimePicker,
	IntlDateTimePicker,
	IntlDatePicker
} from "@ploy-ui/form-fields";
import { NumericDployColumn, DatetimeDployColumn } from "./types";

export const getNumberEdit =
	<RowData extends object>(
		column: NumericDployColumn<RowData>,
		format?: string
	) =>
	({ onChange, value, columnDef }: EditComponentProps<RowData>) => {
		const placeholder =
			typeof columnDef.title === "string" ? columnDef.title : undefined;

		return (
			<IntlNumberField
				format={format}
				{...column.editFormatOptions}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				percentValueAsFraction={column.type === "percentFraction"}
				// label={columnDef.title || " "}
				variant="standard"
				margin="dense"
			/>
		);
	};

export const getDateEdit =
	<RowData extends object>(
		column: DatetimeDployColumn<RowData>,
		format?: string
	) =>
	({ onChange, value, columnDef }: EditComponentProps<RowData>) => {
		const placeholder =
			typeof columnDef.title === "string" ? columnDef.title : undefined;

		const Picker = column.editFormatOptions
			? IntlKeyboardDatePicker
			: IntlDatePicker;

		return (
			<Picker
				format={format}
				{...(column.editFormatOptions ?? column.formatOptions)}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				// label={columnDef.title || " "}
				inputVariant="standard"
				margin="dense"
			/>
		);
	};

export const getTimeEdit =
	<RowData extends object>(
		column: DatetimeDployColumn<RowData>,
		format?: string
	) =>
	({ onChange, value, columnDef }: EditComponentProps<RowData>) => {
		const placeholder =
			typeof columnDef.title === "string" ? columnDef.title : undefined;

		const Picker = column.editFormatOptions
			? IntlKeyboardTimePicker
			: IntlTimePicker;

		return (
			<Picker
				format={format}
				{...(column.editFormatOptions ?? column.formatOptions)}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				// label={columnDef.title || " "}
				inputVariant="standard"
				margin="dense"
			/>
		);
	};

export const getDateTimeEdit =
	<RowData extends object>(
		column: DatetimeDployColumn<RowData>,
		format?: string
	) =>
	({ onChange, value, columnDef }: EditComponentProps<RowData>) => {
		const placeholder =
			typeof columnDef.title === "string" ? columnDef.title : undefined;

		const Picker = column.editFormatOptions
			? IntlKeyboardDateTimePicker
			: IntlDateTimePicker;

		return (
			<Picker
				format={format}
				{...(column.editFormatOptions ?? column.formatOptions)}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				// label={columnDef.title || " "}
				inputVariant="standard"
				margin="dense"
			/>
		);
	};

export const TextEdit = <RowData extends object>({
	onChange,
	value,
	columnDef
}: EditComponentProps<RowData>) => {
	const placeholder =
		typeof columnDef.title === "string" ? columnDef.title : undefined;

	const modifiedOnChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => onChange(e.currentTarget.value);

	return (
		<DployTextField
			onChange={modifiedOnChange}
			value={value}
			placeholder={placeholder}
			// label={columnDef.title || " "}
			variant="standard"
			margin="dense"
		/>
	);
};
