import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useDrawer } from "../../../contexts";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import { autocolor } from "@ploy-ui/core";

export interface UserInfoToggleDrawerProps {
	disableToggle?: boolean;
	color?: string;
}

function UserInfoToggleDrawer(props: UserInfoToggleDrawerProps) {
	const { disableToggle } = props;
	const { toggle, isOpen } = useDrawer();

	let {
		credentialType,
		userFullName = "",
		vendorName,
		roleName = "Salesman"
	} = useResource(VulcanDataResource.detail(), {});

	const classes = useStyles(props);

	userFullName = credentialType === "Anonymous" ? "Anonym" : userFullName;
	vendorName = credentialType === "External" ? vendorName : undefined;

	const userNameInitials = userFullName
		.split(" ")
		.map(n => n[0])
		.join("");

	return (
		<div className={classes.root}>
			{isOpen ? (
				<div
					className={classes.toggleContainer}
					onClick={disableToggle ? undefined : toggle}
				>
					<Typography className={classes.role} align="center">
						{roleName}
					</Typography>
					<Typography className={classes.name} align="center">
						{userFullName}
					</Typography>
					<Typography className={classes.vendor} align="center">
						{vendorName}
					</Typography>
				</div>
			) : (
				<IconButton
					className={classes.iconButton}
					onClick={disableToggle ? undefined : toggle}
				>
					<Typography className={classes.initials}>
						{userNameInitials}
					</Typography>
				</IconButton>
			)}
		</div>
	);
}
UserInfoToggleDrawer.displayName = "UserInfoToggleDrawer";

const useStyles = makeStyles(
	theme => ({
		root: {
			display: "flex",
			justifyContent: "center",
			minHeight: 55
		},
		toggleContainer: {
			cursor: "pointer"
		},
		role: {
			color: (props: { color?: string }) =>
				autocolor({
					bgcolor: props.color,
					emphasizeText: 0.29,
					autoContrastText: true,
					theme
				}).color,
			textTransform: "uppercase",
			fontSize: 11
		},
		name: {
			color: (props: { color?: string }) =>
				autocolor({
					bgcolor: props.color,
					emphasizeText: 0.29,
					autoContrastText: true,
					theme
				}).color,
			fontSize: 12,
			fontWeight: 700
		},
		vendor: {
			color: (props: { color?: string }) =>
				autocolor({
					bgcolor: props.color,
					emphasizeText: 0.4,
					autoContrastText: true,
					theme
				}).color,
			textTransform: "uppercase",
			fontSize: "0.7rem",
			fontWeight: 700,
			whiteSpace: "pre-wrap"
		},
		iconButton: {
			height: 40,
			width: 40,
			backgroundColor: "rgba(0, 0, 0, 0.31)",
			"&:hover": {
				backgroundColor: "rgba(0, 0, 0, 0.31)"
			},
			color: (props: { color?: string }) =>
				autocolor({
					bgcolor: props.color,
					emphasizeText: 0.29,
					autoContrastText: true,
					theme
				}).color,
			alignSelf: "center"
		},
		initials: {
			fontWeight: 700,
			fontSize: 14
		}
	}),
	{ name: "UserInfoToggleDrawer" }
);

export default UserInfoToggleDrawer;
