import { CalculatorState } from "../calculator";
import { getInNamespaced, isResolvedCtrl } from "../calculator/utils";
import { setIn, getIn } from "@ploy-lib/core";

export function calculationToParams<TNamespaces extends string, TData>(
	calculation: Partial<CalculatorState<TNamespaces, TData>> = {},
	parameterList: string[] = [],
	serviceNamespace: string
): { [key: string]: string } | undefined {
	const { values, variableControlMaps, controlFieldMaps } = calculation;
	//
	if (parameterList.length === 0) return;

	return parameterList.reduce((params, param) => {
		const [targetStr, sourceStr, defaultString] = param.trim().split(";");

		const defaultValue =
			defaultString != null
				? // eslint-disable-next-line no-new-func
				  new Function("return " + defaultString + ";")()
				: null;

		let [source, sourceNs = serviceNamespace] = (sourceStr || targetStr)
			.split(".")
			.reverse();

		let target = targetStr;

		const ctrl = getIn(variableControlMaps, [sourceNs, target]);
		const ref = getIn(controlFieldMaps, [sourceNs, ctrl]);

		if (isResolvedCtrl(ref)) {
			source = ref.resolved;
			sourceNs = ref.namespace;
		}

		const value = getInNamespaced(
			values,
			sourceNs as TNamespaces,
			source,
			defaultValue
		);

		if (value == null) return params;

		return setIn(params, target, value);
	}, {} as { [key: string]: string } | undefined);
}
