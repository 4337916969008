import { Service } from "@ploy-lib/types";
import { Patch } from "../types";

export type UnsubscripeCallback = () => void;

export interface EventManager<TN extends string, TD> {
	calculatorReady: SubscriberAndNotifierNoPayload;
	patch: SubscriberAndNotifier<{ patches: Patch<TN, TD>[] }>;
	serviceSuccess: SubscriberAndNotifier<{ namespace: TN; service: Service }>;
}

export function createEventManager<TN extends string, TD>(): EventManager<
	TN,
	TD
> {
	return {
		calculatorReady: subscriberAndNotifierNoPayload(),
		patch: subscriberAndNotifier<{ patches: Patch<TN, TD>[] }>(),
		serviceSuccess: subscriberAndNotifier<{ namespace: TN; service: Service }>()
	};
}

const emptyNotifier: SubscriberAndNotifierNoPayload = {
	notify: () => {},
	subscribe: () => () => {}
};

export const emptyEventManager: EventManager<any, any> = {
	calculatorReady: emptyNotifier,
	patch: emptyNotifier as unknown as EventManager<any, any>["patch"],
	serviceSuccess: emptyNotifier as unknown as EventManager<
		any,
		any
	>["serviceSuccess"]
};

interface SubscriberAndNotifier<T> {
	notify: (msg: T) => void;
	subscribe: (cb: (msg: T) => void) => UnsubscripeCallback;
}

const subscriberAndNotifier = <T>(): SubscriberAndNotifier<T> => {
	let count: number = 0;
	const myMap = new Map<number, (msg: T) => void>();

	return {
		notify: (msg: T) => {
			myMap.forEach(fn => fn(msg));
		},
		subscribe: (cb: (msg: T) => void): UnsubscripeCallback => {
			const instance = count++;
			myMap.set(instance, cb);

			return () => {
				myMap.delete(instance);
			};
		}
	};
};

interface SubscriberAndNotifierNoPayload {
	notify: () => void;
	subscribe: (cb: () => void) => UnsubscripeCallback;
}

const subscriberAndNotifierNoPayload = (): SubscriberAndNotifierNoPayload => {
	let count: number = 0;
	const myMap = new Map<number, () => void>();

	return {
		notify: () => {
			myMap.forEach(fn => fn());
		},
		subscribe: (cb: () => void): UnsubscripeCallback => {
			const instance = count++;
			myMap.set(instance, cb);

			return () => {
				myMap.delete(instance);
			};
		}
	};
};
