import React, { useState } from "react";
import { useResource } from "@rest-hooks/core";
import {
	GeneralInfoResource,
	ProductInfo,
	ProductInfoLink
} from "@ploy-lib/rest-resources";
import { Grid, Icon, Link, makeStyles, Typography } from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

interface GeneralInfoProps {}

const GeneralInformation = (props: GeneralInfoProps) => {
	const classes = useStyles();
	const productInfo = useResource(GeneralInfoResource.list(), {}) || [];

	const [expanded, setExpanded] = useState<string[]>([]);
	const [selected, setSelected] = useState<string[]>([]);

	const handleToggle = (_: any, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	const handleSelect = (_: any, nodeIds: string[]) => {
		setSelected(nodeIds);
	};

	return (
		<TreeView
			expanded={expanded}
			selected={selected}
			onNodeToggle={handleToggle}
			onNodeSelect={handleSelect}
			classes={{ root: classes.root }}
		>
			{productInfo &&
				productInfo.length > 0 &&
				productInfo.map((info: ProductInfo) => (
					<TreeItem
						key={`${info.productId}`}
						nodeId={`${info.productId}`}
						label={
							<Grid container alignItems="center" direction="row">
								{info.productInfoLinks?.length > 0 ? (
									expanded.some(e => e === info.productId.toString()) ? (
										<ExpandMoreIcon />
									) : (
										<ChevronRightIcon />
									)
								) : (
									<Icon />
								)}
								<Typography>{info.productName}</Typography>
							</Grid>
						}
						classes={{
							iconContainer: classes.iconContainer
						}}
					>
						{info.productInfoLinks.map((productInfoLink: ProductInfoLink) => (
							<TreeItem
								key={`${productInfoLink.linkId}`}
								nodeId={`${productInfoLink.linkId}`}
								label={
									<Grid container direction="row">
										<Typography key={productInfoLink.linkId}>
											<Link underline="hover">{productInfoLink.linkText}</Link>
										</Typography>
									</Grid>
								}
								onClick={() => window.open(productInfoLink.linkUrl, "_blank")}
							/>
						))}
					</TreeItem>
				))}
		</TreeView>
	);
};

const useStyles = makeStyles(theme => ({
	root: {},
	iconContainer: {
		width: 0
	}
}));

export { GeneralInformation };
