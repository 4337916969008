import { FieldEditorOptions, BaseFieldProps } from "./types";
import { IntlShape } from "react-intl";
import { FieldProps, getIn, FormikState } from "formik";
import { ErrorDisplay } from "@ploy-lib/types";

export function getFormatOptions<T extends {} = {}>(
	intl: IntlShape,
	formatType: string,
	premadeFormat?: string
): T | undefined {
	const optionsFromFormat =
		premadeFormat &&
		intl.formats[formatType] &&
		intl.formats[formatType][premadeFormat];

	if (premadeFormat && !optionsFromFormat)
		console.error(
			`[React Intl] No ${formatType} format named: ${premadeFormat}`
		);

	return optionsFromFormat;
}

export function formatToParts(
	formatter: Intl.NumberFormat,
	number?: number
): Intl.NumberFormatPart[] | undefined;
export function formatToParts(
	formatter: Intl.DateTimeFormat,
	date?: Date
): Intl.DateTimeFormatPart[] | undefined;
export function formatToParts(
	formatter: Intl.DateTimeFormat | Intl.NumberFormat,
	value?: Date | number
) {
	return typeof formatter.formatToParts === "function"
		? formatter.formatToParts(value as any)
		: undefined;
}

export const identity = i => i;

export function defaultGetItemLabel(item: any): any {
	return item?.["value"] ?? item;
}

export function defaultGetItemValue(item: any): any {
	return item?.["key"] ?? item;
}

export function isNotNull<T>(x: T): x is NonNullable<T> {
	return x != null;
}

/**
 * Generates a record type with known names
 * @param data some object object hwere all values are @type FieldEditorOptions
 */
export function identityRecordOfFieldEditorOptions<K extends string>(
	data: Record<K, FieldEditorOptions>
): Record<K, FieldEditorOptions> {
	return data;
}

export function getNoTouchError(errorDisplay: ErrorDisplay) {
	switch (errorDisplay) {
		case ErrorDisplay.Touched:
		case ErrorDisplay.TouchedNoMessage:
			return ErrorDisplay.TouchedNoMessage;
		case ErrorDisplay.Always:
		case ErrorDisplay.AlwaysNoMessage:
			return ErrorDisplay.AlwaysNoMessage;
		default:
			return ErrorDisplay.Never;
	}
}

export function getFieldError<V>(
	errors: FormikState<V>["errors"],
	touched: FormikState<V>["touched"],
	name: string,
	errorDisplay: ErrorDisplay | undefined
): { error: boolean; helperText: string | undefined } {
	const errorDisplayToUse = errorDisplay ?? ErrorDisplay.Touched;
	var [displayError, displayErrorMessage] = shouldDisplayError(
		touched,
		name,
		errorDisplayToUse
	);
	if (displayError) {
		const errorMessage = errors[name] || getIn(errors, name);
		return {
			error: !!errorMessage,
			helperText: displayErrorMessage ? errorMessage : ""
		};
	}
	return {
		error: false,
		helperText: undefined
	};
}

export function shouldDisplayError<V>(
	touched: FormikState<V>["touched"],
	name: string,
	errorDisplay: ErrorDisplay
): [boolean, boolean] {
	switch (errorDisplay) {
		case ErrorDisplay.Touched:
			return [getIn(touched, name), true];
		case ErrorDisplay.TouchedNoMessage:
			return [getIn(touched, name), false];
		case ErrorDisplay.AlwaysNoMessage:
			return [true, false];
		case ErrorDisplay.Always:
			return [true, true];
		case ErrorDisplay.Never:
		default:
			return [false, false];
	}
}
