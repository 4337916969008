import { Link } from "react-router-dom";
import { useTenantImage } from "@ploy-ui/tenants";

import { makeStyles } from "@material-ui/core/styles";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import clsx from "clsx";
import { ImageFit } from "@ploy-ui/core";

export interface AppBarBannerProps {
	position?: string;
	className?: string;
}

const AppBarBanner = ({ position = "left", className }: AppBarBannerProps) => {
	const { homePage } = useResource(VulcanDataResource.detail(), {});

	const classes = useStyles();

	const mainlogo = useTenantImage("mainlogo");

	return (
		<Link to={homePage || "/"} className={clsx(className, classes.link)}>
			<ImageFit
				src={mainlogo}
				fit="contain"
				position={position}
				className={classes.img}
			/>
		</Link>
	);
};

const useStyles = makeStyles(
	{
		link: {
			flexGrow: 1,
			display: "flex",
			justifyContent: "center",
			alignItems: "stretch"
		},
		img: {
			flexGrow: 1
		}
	},
	{ name: "AppBar" }
);

export default AppBarBanner;
