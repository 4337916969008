import Text from "./Text";
import { applyPropertyControls, ControlType } from "../../property-controls";
import React from "react";
import { contentBoxPropertyControls } from "../../ContentBox";
import { FormattedMessage } from "react-intl";

applyPropertyControls(Text, {
	text: {
		type: ControlType.String,
		title: (
			<FormattedMessage id="dploy.grid.text.text.label" defaultMessage="Text" />
		),
		multiline: true,
		description: (
			<FormattedMessage
				id="dploy.grid.text.text.description"
				defaultMessage="Supports markdown formatting"
			/>
		),
		coerce: (_, values) => values.content
	},
	boxProps: contentBoxPropertyControls
});

export default Text;
