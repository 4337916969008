import { useContext, useCallback } from "react";
import {
	serviceManagerContext,
	calculationContext,
	dispatchContext
} from "../context";
import { servicePendingName } from "../../utils";

export function useService(namespace?: string, name?: string) {
	const serviceManager = useContext(serviceManagerContext);
	const calculation = useContext(calculationContext);
	const dispatch = useContext(dispatchContext);

	return useCallback(
		async (urlParams?: { [key: string]: string }, payload?: any) => {
			if (namespace == null || name == null)
				throw new Error(`Service not found: ${namespace}.${name}`);

			dispatch({
				type: "patch",
				payload: {
					patches: [
						{
							target: servicePendingName(namespace, name),
							namespace,
							value: 1,
							overwrite: true,
							changeTrigger: servicePendingName(namespace, name),
							isResolve: true
						}
					]
				}
			});

			return serviceManager.call(
				{ name, namespace },
				calculation,
				urlParams,
				payload
			);
		},
		[namespace, name, dispatch, serviceManager, calculation]
	);
}

export function useServiceHref(namespace?: string, name?: string) {
	const serviceManager = useContext(serviceManagerContext);
	const calculation = useContext(calculationContext);

	if (name == null || namespace == null) return undefined;

	return serviceManager.getHref({ name, namespace }, calculation);
}
