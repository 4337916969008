import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { TextField } from "@ploy-ui/form-fields";
import { LoginResource } from "@ploy-lib/rest-resources";
import { useFetcher } from "@rest-hooks/core";
import { FormBase } from "@ploy-ui/login";
import { ErrorDisplay } from "@ploy-lib/types";

const messages = defineMessages({
	newPasswordPlaceholder: {
		id: "dealer.login.field.new_password.placeholder",
		description: "Placeholder for password field",
		defaultMessage: "New password"
	},
	newPasswordLabel: {
		id: "dealer.login.field.new_password.label",
		description: "Label for for password field",
		defaultMessage: "Password"
	},
	submitError: {
		id: "dealer.login.update-password.error",
		description: "An error occurred",
		defaultMessage: "An error has occured"
	},
	headerText: {
		id: "dealer.login.header.new_password",
		description: "Header for new password page",
		defaultMessage: "New password"
	},
	submitButtonLabel: {
		id: "dealer.login.button.continue.label",
		description: "Label for continue button",
		defaultMessage: "Continue"
	}
});

const UpdatePasswordForm = () => {
	const setPassword = useFetcher(LoginResource.setPassword());

	const intl = useIntl();

	const initValues = { newPassword: "" };

	const fieldConfig = [
		{
			name: "newPassword",
			component: TextField,
			type: "password",
			autoComplete: "new-password",
			placeholder: intl.formatMessage(messages.newPasswordPlaceholder),
			label: intl.formatMessage(messages.newPasswordLabel),
			fullWidth: true,
			margin: "normal",
			errorDisplay: ErrorDisplay.Touched
		}
	];

	return (
		<FormBase
			submit={values => setPassword(undefined, values)}
			initValues={initValues}
			fieldConfig={fieldConfig}
			headerText={intl.formatMessage(messages.headerText)}
			submitButtonText={intl.formatMessage(messages.submitButtonLabel)}
		/>
	);
};

export default UpdatePasswordForm;
