import React, { forwardRef } from "react";
import {
	Menu,
	MenuItem,
	ListItemIcon,
	Icon,
	ListItemText,
	styled
} from "@material-ui/core";
import { isAccessDenied } from "@ploy-lib/rest-resources";
import { OverlayCircularProgress } from "@ploy-ui/core";
import { defineMessages, useIntl } from "react-intl";
import { useAppActionClick } from "../hooks/useAppActionClick";

import { useAppLoad } from "../appLoadContext";
import { BaseCamelCasedResource } from "@ploy-lib/rest-resources/es/plain/BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import {
	SimpleResource,
	SchemaDetail,
	AbstractInstanceType,
	EndpointExtraOptions
} from "@rest-hooks/rest";
import { useStatefulResource } from "@rest-hooks/legacy";
import { NetworkError } from "@ploy-lib/types";
import { FormikProps } from "formik/dist/types";

export interface FunctionButton {
	disabled: boolean;
	icon?: string;
	label: string;
	name: string;
	shouldRefresh: boolean;
	shouldSave: boolean;
	successMessage?: string;
	canResend: boolean;
	buttonUrl?: string;
	disableValidation: boolean;
}

export class FunctionButtonResource
	extends BaseCamelCasedResource
	implements FunctionButton
{
	readonly disabled: boolean = false;
	readonly icon: string = "";
	readonly label: string = "";
	readonly name: string = "";
	readonly shouldRefresh: boolean = false;
	readonly shouldSave: boolean = false;
	readonly successMessage: string = "";
	readonly canResend: boolean = false;
	readonly buttonUrl: string = "";
	readonly disableValidation: boolean = false;

	pk() {
		return `${this.name}`;
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		const data = await super.fetch(input, init);

		if (isAccessDenied(data))
			throw new NetworkError({
				status: 403,
				statusText: "AccessDenied"
			} as Response);

		return (
			data.map?.(d => ({ ...d, buttonUrl: d.url })) ?? {
				...data,
				buttonUrl: data.url
			}
		);
	}

	static detail<T extends typeof SimpleResource>(this: T) {
		const endpoint = super.detail();

		return endpoint.extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			fetch: async params => {
				const data = await endpoint.fetch({
					includedFunctonButtons: params.name
				});

				return data?.[0];
			}
		});
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: 0
		};
	}

	static urlRoot = legacyApiResourceUrl("AppChange/GetFunctionButtons");
}

export const StyledIcon = styled(Icon)({
	overflow: "initial"
});

const DarkOverlayCircularProgress = styled(OverlayCircularProgress)({
	backgroundColor: "rgba(0, 0, 0, 0.3)"
});

export const messages = defineMessages({
	lblCancelWarningApplication: {
		id: "dploy.template-form.app-action.cancel.confirm",
		description: "Label for cancel application alert",
		defaultMessage: "Er du sikker på at du vil bortfalle saken?"
	}
});

interface AppActionProps {
	onClose: () => any;
	button: FunctionButton;
	form: FormikProps<any>;
}

const AppAction = forwardRef(
	(props: AppActionProps, ref: React.Ref<HTMLLIElement>) => {
		const { button } = props;
		const intl = useIntl();
		const { pending, disabled, onClickButton } = useAppActionClick(props);

		return (
			<>
				{pending && <DarkOverlayCircularProgress pending />}
				<MenuItem
					button
					ref={ref}
					onClick={event => {
						if (button.name === "Cancel") {
							if (
								window.confirm(
									intl.formatMessage(messages.lblCancelWarningApplication)
								)
							)
								onClickButton(event);
						} else {
							onClickButton(event);
						}
					}}
					disabled={disabled}
				>
					<ListItemIcon>
						<StyledIcon className={button.icon} />
					</ListItemIcon>
					<ListItemText primary={button.label} />
				</MenuItem>
			</>
		);
	}
);

export const AppActions = (props: {
	anchor: Element | ((element: Element) => Element) | null | undefined;
	setAnchor: (
		anchor: Element | ((element: Element) => Element) | null | undefined
	) => void;
	form: FormikProps<any>;
}) => {
	const { anchor, setAnchor, form } = props;
	const appLoad = useAppLoad();

	const { data: buttons, error } = useStatefulResource(
		FunctionButtonResource.list(),
		appLoad.id ? {} : null
	);

	if ((error as NetworkError)?.status === 403) {
		return null;
	}

	return (
		<Menu
			elevation={0}
			id="AppActions-menu"
			anchorEl={anchor}
			keepMounted
			open={Boolean(anchor)}
			onClose={() => setAnchor(null)}
		>
			{(buttons || []).map(button => (
				<AppAction
					form={form}
					onClose={() => setAnchor(null)}
					button={button}
					key={button.name}
				/>
			))}
		</Menu>
	);
};

AppActions.displayName = "DployAppActions";

export default AppActions;
