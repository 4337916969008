import * as React from "react";

import { Typography, Theme, Divider } from "@material-ui/core";
import Grid, { GridJustification, GridWrap } from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

import { FieldContainer } from "../field";
import { makeStyles } from "@material-ui/core/styles";
import { TemplateSection, TemplateField } from "@ploy-lib/types";
import { fieldToKey, sectionToKey } from "../utils";

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			position: "relative",
			backgroundColor: "inherit"
		},
		paper: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		},
		header: {
			fontWeight: 700,
			padding: theme.spacing(0.5)
		},
		headerMarginLiteral: {},
		headerSimpleCard: {
			paddingLeft: 12,
			paddingRight: 12,
			paddingTop: 12,
			alignItems: "center",
			display: "flex",
			fontWeight: 400
		},
		outline: ({ outline, square }: SectionProps) => ({
			border: `1px solid ${
				(outline && theme.palette[outline] && theme.palette[outline].main) ||
				theme.palette.secondary.main
			}`
		})
	}),
	{ name: "DployFormSection" }
);

export interface SectionProps extends TemplateSection {
	className?: string;
	classes?: any;
	literal?: boolean;
	disabled?: boolean;
	justifyContent?: GridJustification;
	wrap?: GridWrap;
	collapsedSection?: boolean;
	onClick?: () => void;
	setSectionCompleted?: (
		id: TemplateSection["formTemplateSectionId"],
		completed: boolean
	) => void;
	allFields?: TemplateField[];
	desktopViewForMobile?: boolean;
}

export interface SectionWrapperProps extends SectionProps {
	InnerSectionComponent?: React.ComponentType<SectionProps>;
}

const Section = (props: SectionWrapperProps) => {
	const {
		className,
		classes = {},
		fields = [],
		sectionTitle,
		literal,
		disabled,
		justifyContent,
		wrap,
		onClick,
		useSeparator,
		collapsedSection,
		// showStatus = false,
		// setSectionCompleted,
		formTemplateSectionId,
		elevation = 0,
		square,
		outline,
		innerWidthSM,
		desktopViewForMobile,
		InnerSectionComponent
	} = props;

	const sectionClasses = useStyles(props);

	const fieldGroups = fields.reduce((acc, f) => {
		if (f.alignWithPrevious && acc.length > 0) {
			acc[acc.length - 1].push(f);
		} else {
			acc.push([f]);
		}
		return acc;
	}, [] as TemplateField[][]);

	return (
		<Grid
			component={Paper}
			elevation={elevation}
			square={square}
			container
			className={clsx(sectionClasses.root, className, {
				[sectionClasses.paper]: elevation > 0 || outline,
				[sectionClasses.outline]: outline
			})}
			justifyContent={justifyContent}
			wrap={wrap}
			spacing={1}
			onClick={onClick}
		>
			{sectionTitle && (
				<Grid item xs={12}>
					<Typography
						className={clsx(sectionClasses.header, {
							[sectionClasses.headerMarginLiteral]:
								literal ||
								(fields.some(x => x) && fields.every(field => field.literal)),
							[sectionClasses.headerSimpleCard]:
								!props.hideSectionIfNoVisibleFields && !fields.some(x => x)
						})}
						variant={
							!props.hideSectionIfNoVisibleFields && !fields.some(x => x)
								? "h5"
								: "body1"
						}
					>
						{sectionTitle}
					</Typography>
				</Grid>
			)}

			{InnerSectionComponent ? (
				<InnerSectionComponent {...props} className={undefined} />
			) : (
				fieldGroups.map((fields, idx) => {
					if (fields.length === 0) return null;

					return (
						<React.Fragment key={sectionToKey(formTemplateSectionId, idx)}>
							<FieldContainer
								key={fieldToKey(fields[0])}
								fields={fields}
								justifyContent={justifyContent}
								wrap={wrap}
								literal={literal}
								lowContrastText={collapsedSection}
								className={classes.fieldContainer}
								linkedHelperText={
									innerWidthSM === "auto" || (innerWidthSM ?? 12) < 12
								}
								disabled={disabled}
								desktopViewForMobile={desktopViewForMobile}
							/>
						</React.Fragment>
					);
				})
			)}
			{useSeparator && (
				<Grid item xs={12}>
					<Divider orientation="horizontal" />
				</Grid>
			)}
		</Grid>
	);
};

Section.displayName = "DployFormSection";

export default React.memo(Section);
