import { isNotNull } from "../calculator";

interface Settings<T> {
	debounce?: number;
	timeout?: number;
	throttle?: number;
	// cancel?: boolean;
	key: T;
}

export interface WithStatus {
	ok: boolean;
	cancelled?: boolean;
	timeout?: boolean;
}

interface PendingCall {
	trigger: () => void;
	cancel: () => void;
	triggered: boolean;
}

async function wait(timeout: number) {
	return new Promise<void>(resolve => window.setTimeout(resolve, timeout));
}

const supportsIdleCallback =
	typeof (window as any).requestIdleCallback === "function" &&
	typeof (window as any).cancelIdleCallback === "function";

function requestIdleCallback(
	handler: TimerHandler,
	options: { timeout: number }
) {
	if (supportsIdleCallback) {
		return (window as any).requestIdleCallback(handler, options);
	} else {
		// Fallback to timeout: 0 for browsers not supporting idle callbacks.
		// (IE, Edge, Safari)
		return window.setTimeout(handler, 0);
	}
}

function cancelIdleCallback(handle: number) {
	if (supportsIdleCallback) {
		return (window as any).cancelIdleCallback(handle);
	} else {
		return window.clearTimeout(handle);
	}
}

function getPromiseFunctions<S>() {
	let resolve: (value?: S | PromiseLike<S>) => void = () => {};
	let reject: (reason?: any) => void = () => {};

	const promise = new Promise<S>((promiseResolve, promiseReject) => {
		resolve = promiseResolve;
		reject = promiseReject;
	});

	return {
		resolve,
		reject,
		promise
	};
}

export function createPromiseHandler<
	S extends WithStatus,
	TFunc extends (...args: any[]) => Promise<S>
>(
	func: TFunc,
	mapCreator: <TV>() => WeakMap<any, TV>
): (
	settings: Settings<
		typeof mapCreator extends <TV>() => WeakMap<infer K, TV> ? K : never
	>
) => TFunc;

export function createPromiseHandler<
	S extends WithStatus,
	TFunc extends (...args: any[]) => Promise<S>
>(
	func: TFunc,
	mapCreator?: <TV>() => Map<any, TV>
): (
	settings: Settings<
		typeof mapCreator extends <TV>() => Map<infer K, TV> ? K : string
	>
) => TFunc;

export function createPromiseHandler<
	S extends WithStatus,
	TFunc extends (...args: any[]) => Promise<S>
>(
	func: TFunc,
	mapCreator: <TV>() => Map<any, TV> | WeakMap<any, TV> = <TV>() =>
		new Map<any, TV>()
): (
	settings: Settings<
		typeof mapCreator extends <TV>() => WeakMap<infer K, TV> | Map<infer K, TV>
			? K
			: string
	>
) => TFunc {
	const current = mapCreator<PendingCall>();
	const throttled = mapCreator<PendingCall>();
	const previusArgs = mapCreator<any[]>();

	return function ({ debounce = 0, timeout = 0, throttle = 0, key }) {
		function promiseHandler(...args: any[]): Promise<S> {
			// Cancel immediately if all arguments are strict equal to previous call
			const previous = previusArgs.get(key);
			if (
				previous &&
				previous.length === args.length &&
				previous.every((x, i) => x === args[i])
			) {
				return Promise.resolve({ ok: false, cancelled: true } as S);
			} else {
				previusArgs.set(key, args);
			}

			const currentCall = current.get(key);
			// console.log(
			// 	"---",
			// 	key,
			// 	":",
			// 	currentCall && currentCall.triggered ? "running" : "",
			// 	currentCall && !currentCall.triggered ? "debounced" : "",
			// 	!currentCall ? "ready" : "",
			// 	"---"
			// );

			// Throttle if currently running
			if (currentCall && currentCall.triggered) {
				// Cancel current throttled call and replace it
				const currentThrottled = throttled.get(key);
				if (currentThrottled) currentThrottled.cancel();

				// Replace existing throttled call
				const nextThrottled = {
					trigger: triggerThrottled,
					cancel: cancelThrottled,
					triggered: false
				};
				throttled.set(key, nextThrottled);

				// logArgs(`Throttled (${throttle} ms):`, args);

				const throttlePromiseFuncs = getPromiseFunctions<S>();

				function triggerThrottled() {
					if (nextThrottled.triggered) return;
					nextThrottled.triggered = true;
					// logArgs("Throttled triggered:", args);
					if (previusArgs.get(key) === args) {
						previusArgs.delete(key);
					}
					promiseHandler(...args).then(
						res => throttlePromiseFuncs.resolve(res),
						res => throttlePromiseFuncs.reject(res)
					);
				}

				function cancelThrottled() {
					if (!nextThrottled.triggered)
						throttlePromiseFuncs.resolve({ ok: false, cancelled: true } as S);
				}

				return throttlePromiseFuncs.promise;
			}

			// Cancel current debounced call and replace it
			if (currentCall && !currentCall.triggered) {
				currentCall.cancel();
			}

			function triggerThrottled() {
				const latestThrottled = throttled.get(key);
				if (latestThrottled) {
					throttled.delete(key);
					latestThrottled.trigger();
				}
			}

			const cancelPromiseFuncs = getPromiseFunctions<S>();
			const servicePromiseFuncs = getPromiseFunctions<S>();
			const cancelPromise = cancelPromiseFuncs.promise;
			const servicePromise = servicePromiseFuncs.promise;

			const nextCall = {
				cancel: cancelNext,
				trigger: triggerNext,
				triggered: false
			};

			// logArgs(`Set current:`, args);
			current.set(key, nextCall);
			const handle =
				debounce > 0
					? window.setTimeout(nextCall.trigger, debounce)
					: requestIdleCallback(nextCall.trigger, { timeout: 250 });

			// logArgs(`Debounced (${debounce} ms):`, args);

			function cancelNext() {
				// logArgs("Cancelled:", args);
				// Cancel debounce and resolve
				cancelIdleCallback(handle);
				window.clearTimeout(handle);
				cancelPromiseFuncs.resolve({ ok: false, cancelled: true } as S);
			}

			function triggerNext() {
				if (nextCall.triggered) return;

				nextCall.triggered = true;
				// logArgs(`Debounced called (${debounce} ms):`, args);

				return func(...args).then(
					res => servicePromiseFuncs.resolve(res),
					rej => servicePromiseFuncs.reject(rej)
				);
			}

			const timeoutPromise =
				timeout > 0
					? wait(debounce + timeout).then(() => {
							if (previusArgs.get(key) === args) {
								previusArgs.delete(key);
							}
							// logArgs(`Timeout (${timeout} ms):`, args);
							return { ok: false, timeout: true } as S;
					  })
					: null;

			// Create throttle promise. The response will be ignored if not already completed
			let throttlePromise = throttle > 0 ? wait(throttle) : Promise.resolve();

			// const start = performance.now();
			// throttlePromise = throttlePromise.then(() => {
			// 	const end = performance.now();
			// 	logArgs(
			// 		`Throttled finished (${throttle} ms):`,
			// 		args,
			// 		`(actual: ${(end - start).toLocaleString()} ms)`
			// 	);
			// });

			return Promise.race(
				[servicePromise, timeoutPromise, cancelPromise].filter(isNotNull)
			).then(
				res => {
					if (previusArgs.get(key) === args && !res.ok) {
						previusArgs.delete(key);
					}

					// if (res.ok) logArgs("Response:", args, res);

					if (current.get(key) !== nextCall && !res.cancelled && !res.timeout) {
						console.warn(
							`Possible out of order network response detected (${key}).`
						);

						return { ok: false, cancelled: true } as S;
					}

					throttlePromise.then(() => {
						if (current.get(key) === nextCall) {
							// logArgs(`Remove current:`, args);
							current.delete(key);
						}
						triggerThrottled();
					});

					return res;
				},
				reason => {
					if (previusArgs.get(key) === args) {
						previusArgs.delete(key);
					}

					// logArgs("Error:", args, reason);

					throttlePromise.then(() => {
						if (current.get(key) === nextCall) {
							// logArgs(`Remove current:`, args);
							current.delete(key);
						}

						triggerThrottled();
					});

					throw reason;
				}
			);
		}

		return promiseHandler as TFunc;
	};
}

// function logArgs(m, [s, b, o]: any, ...rest) {
// 	if (s.name === "Conditions")
// 		console.log(
// 			m,
// 			s.name,
// 			"(ObjectPrice:",
// 			o && o.values && o.values.Calculator && o.values.Calculator.ObjectPrice,
// 			")",
// 			...rest
// 		);
// }
