import React, { useCallback, useState, Suspense, useMemo } from "react";

import { BisnodeSearch } from "./BisnodeSearch";
import {
	DployAutocompleteProps,
	DployAutocomplete,
	MuiAutocompleteFieldProps,
	useFieldToTextField
} from "@ploy-ui/form-fields";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { BaseCamelCasedResource } from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useResource } from "@rest-hooks/core";

interface CustomerSettings {
	customerViewNumberEnabled: boolean;
	disableCustomerSearch: boolean;
	displayBisnodeSearch: boolean;
}

class CustomerSettingsResource
	extends BaseCamelCasedResource
	implements CustomerSettings
{
	readonly customerViewNumberEnabled: boolean;
	readonly disableCustomerSearch: boolean;
	readonly displayBisnodeSearch: boolean;

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static urlRoot = legacyApiResourceUrl("AppLoanLeasing/GetCustomerSettings");
}

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	card: {
		flexBasis: 1000,
		maxHeight: "100vh",
		overflowY: "auto"
	}
}));

const messages = defineMessages({
	bisnodeOption: {
		id: "ploy-ui.core.bisnodeSearch.bisnode-option.label",
		description: "Label of bisnode search option in select",
		defaultMessage: "name search in bisnode ..."
	},
	closeLabel: {
		id: "ploy-ui.core.bisnodeSearch.modal.close.label",
		description: "Label of modal close button",
		defaultMessage: "Close"
	}
});

export function BisnodeSearchField<
	T,
	DisableClearable extends boolean | undefined = undefined
>(props: MuiAutocompleteFieldProps<T, undefined, DisableClearable>) {
	const {
		onSelectItem,
		valueKey,
		labelKey,
		emptyEqualsZero,
		options,
		formatString,
		italicText,
		boldText,
		multiple,
		form: { setFieldValue },
		field,
		errorDisplay,
		modalText,
		...rest
	} = props;

	const customerSettings = useResource(CustomerSettingsResource.detail(), {});

	const showBisnodeSearch = Boolean(
		customerSettings && customerSettings.displayBisnodeSearch
	);

	const [open, setOpen] = useState(false);

	const intl = useIntl();

	const bisnodeOption = intl.formatMessage(messages.bisnodeOption);

	const onChange = useCallback<
		NonNullable<DployAutocompleteProps<T, false, DisableClearable>["onChange"]>
	>(
		(e, selected) => {
			if (typeof selected === "string" && selected === bisnodeOption) {
				setOpen(true);
			} else {
				if (onSelectItem) onSelectItem(selected);
			}
		},
		[bisnodeOption, onSelectItem]
	);

	const onInputChange = useCallback<
		NonNullable<
			DployAutocompleteProps<T, false, DisableClearable>["onInputChange"]
		>
	>(
		(e, value) => {
			if (e && e.type === "change") setFieldValue(field.name, value);
		},
		[field.name, setFieldValue]
	);

	const onBisnodeSelect = useCallback(
		(companyNumber: string) => {
			setOpen(false);
			setFieldValue(field.name, companyNumber);
			if (onSelectItem) onSelectItem(companyNumber as any);
		},
		[field.name, setFieldValue, onSelectItem]
	);

	const { autoComplete, value, ...textFieldProps } = useFieldToTextField(
		props as any
	);

	const classes = useStyles();

	const defaultFilter = useMemo(() => createFilterOptions<T>(), []);

	const filterOptionsWithBisnode = useCallback<typeof defaultFilter>(
		(options, state) => [
			...defaultFilter(options, state),
			bisnodeOption as unknown as T
		],
		[bisnodeOption, defaultFilter]
	);

	if (!showBisnodeSearch)
		return (
			<DployAutocomplete<T, undefined, DisableClearable>
				{...rest}
				{...textFieldProps}
				value={
					value as DisableClearable extends true ? NonNullable<T> : T | null
				}
				onChange={onChange}
				onInputChange={onInputChange}
				inputValue={value ? String(value) : ""}
				searchable
				minSearch={3}
				autoSelect={false}
				autoHighlight={false}
				removeNoOptionsMessage
				defaultValue={undefined}
			/>
		);

	return (
		<>
			<DployAutocomplete<T, undefined, DisableClearable>
				{...rest}
				{...textFieldProps}
				value={
					value as DisableClearable extends true ? NonNullable<T> : T | null
				}
				onChange={onChange}
				onInputChange={onInputChange}
				inputValue={value ? String(value) : ""}
				searchable
				minSearch={3}
				autoSelect={false}
				autoHighlight={false}
				removeNoOptionsMessage
				AutocompleteProps={{
					filterOptions: filterOptionsWithBisnode
				}}
				defaultValue={undefined}
			/>
			<Modal
				aria-labelledby="bisnode-search-modal-title"
				aria-describedby="bisnode-search-modal-description"
				className={classes.modal}
				open={open}
				onClose={() => setOpen(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={open}>
					<Suspense fallback={<CircularProgress />}>
						<Card className={classes.card}>
							<CardHeader
								title={
									<FormattedMessage
										id="ploy-ui.core.bisnodeSearch.modalTitle.text"
										description="Title of bisnode search modal"
										defaultMessage="Name Search"
									/>
								}
								subheader={
									<FormattedMessage
										id="ploy-ui.core.bisnodeSearch.modal.description"
										description="Description of bisnode search modal"
										defaultMessage="Search by company"
									/>
								}
								action={
									<IconButton
										onClick={() => setOpen(false)}
										aria-label={intl.formatMessage(messages.closeLabel)}
									>
										<CloseIcon />
									</IconButton>
								}
							/>
							<CardContent>
								<BisnodeSearch
									initialSearchValue={field.value}
									onSelect={onBisnodeSelect}
								/>
							</CardContent>
						</Card>
					</Suspense>
				</Fade>
			</Modal>
		</>
	);
}
