import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { AbstractInstanceType, EndpointExtraOptions, Resource, SchemaDetail } from "@rest-hooks/rest";

export class PartnerWebAgentResource extends BaseCamelCasedResource {
	readonly agent?: ID;
	readonly vendorId?: number;
	readonly instanceName?: string | null = null;
	readonly message?: string;

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: Infinity,
			errorExpiryLength: Infinity,
			invalidIfStale: true
		};
	}

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static urlRoot = apiResourceUrl("partnerWeb");
}

export class PartnerWebShortRefResource extends BaseCamelCasedResource {
	readonly shortRef?: ID;
	readonly applicationNumber?: string | null = null;
	readonly message?: string;

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: Infinity,
			errorExpiryLength: Infinity,
			invalidIfStale: true
		};
	}

	pk() {
		return this.shortRef?.toString();
	}

	static detail<T extends typeof Resource>(this: T) {
		return super.detail().extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			method: "POST"
		});
	}

	static urlRoot = apiResourceUrl("partnerWeb/ref");
}
