import React, { memo } from "react";
import { InputFieldProps } from "../../types";
import { useResource, useFetcher } from "@rest-hooks/core";
import {
	GuarantorResource,
	Guarantor,
	CustomerInfoSearchResource
} from "@ploy-lib/rest-resources";
import { ExpandableListEditSingle, ConfigElement } from "@ploy-ui/core";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { Grid, ListItemText } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import * as yup from "yup";
import {
	createValidationHelpers,
	ValidationHelpers
} from "@ploy-lib/validation-helpers";
import { useAppLoad } from "../../appLoadContext";
import { TextArea } from "@ploy-ui/form-fields";
import { TextLiteral } from "../literals";

const collapsedElement = (values: Guarantor) => (
	<Grid container alignItems="center">
		<ChevronRight />
		<ListItemText primary={values.customerName} />
	</Grid>
);

const messages = defineMessages({
	customerNumber: {
		id: "dealer.updateGuarantorField.field.customerNumber",
		defaultMessage: "SSN"
	},
	customerCompanyNumber: {
		id: "dealer.updateGuarantorField.field.customerCompanyNumber",
		defaultMessage: "Organization number"
	},
	customerName: {
		id: "dealer.updateGuarantorField.field.customerName",
		defaultMessage: "Name"
	},
	phoneMobile: {
		id: "dealer.updateGuarantorField.field.phoneMobile",
		defaultMessage: "Phone"
	},
	email: {
		id: "dealer.updateGuarantorField.field.email",
		defaultMessage: "Email"
	},
	address: {
		id: "dealer.updateGuarantorField.field.address",
		defaultMessage: "Address"
	},
	addressZip: {
		id: "dealer.updateGuarantorField.field.addressZip",
		defaultMessage: "Zip code"
	},
	addressCity: {
		id: "dealer.updateGuarantorField.field.addressCity",
		defaultMessage: "City"
	},
	guarantorAmount: {
		id: "dealer.updateGuarantorField.field.guarantorAmount",
		defaultMessage: "Amount"
	},
	guarantorEmployer: {
		id: "dealer.updateGuarantorField.field.guarantorEmployer",
		defaultMessage: "Employer"
	},
	guarantorGrossIncome: {
		id: "dealer.updateGuarantorField.field.guarantorGrossIncome",
		defaultMessage: "Gross income"
	},
	comment: {
		id: "dealer.updateGuarantorField.field.comment",
		defaultMessage: "Comment"
	},
	invalidSSN: {
		id: "dealer.updateGuarantorField.error.invalidSSN",
		defaultMessage: "Invalid SSN"
	},
	invalidOrgNr: {
		id: "dealer.updateGuarantorField.error.invalidOrgNr",
		defaultMessage: "Invalid organization number"
	},
	header: {
		id: "dealer.updateGuarantorField.header",
		defaultMessage: "Guarantors"
	}
});

const LiteralField = (literal: Boolean, fieldProps: InputFieldProps) => {
	return !literal
		? undefined
		: props => {
				const { configElement, margin, ...rest } = props;
				const { label, ...field } = fieldProps;
				return (
					<TextLiteral
						label={configElement.title}
						items={[]}
						{...field}
						{...rest}
					/>
				);
		  };
};

const createGuarantorConfig = (
	intl: IntlShape,
	validation: ValidationHelpers,
	fieldProps: InputFieldProps,
	literal: Boolean
) =>
	[
		{
			name: "customerNumber",
			title: intl.formatMessage(messages.customerNumber),
			autofillKey: true,
			disabled: g => g.customerId !== null,
			required: true,
			render: LiteralField(literal, fieldProps),
			validation: yup
				.string()
				.test(
					"validssn",
					intl.formatMessage(messages.invalidSSN),
					value => value === undefined || validation.validSsn(value)
				)
		},
		{
			name: "customerCompanyNumber",
			title: intl.formatMessage(messages.customerCompanyNumber),
			autofillKey: true,
			disabled: g => g.customerId !== null,
			required: true,
			render: LiteralField(literal, fieldProps),
			validation: yup
				.string()
				.test(
					"validorgnr",
					intl.formatMessage(messages.invalidOrgNr),
					value => value === undefined || validation.validOrgNumber(value)
				)
		},
		{
			name: "customerName",
			title: intl.formatMessage(messages.customerName),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "phoneMobile",
			title: intl.formatMessage(messages.phoneMobile),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "email",
			title: intl.formatMessage(messages.email),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "address",
			title: intl.formatMessage(messages.address),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "addressZip",
			title: intl.formatMessage(messages.addressZip),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "addressCity",
			title: intl.formatMessage(messages.addressCity),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "guarantorAmount",
			title: intl.formatMessage(messages.guarantorAmount),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "guarantorEmployer",
			title: intl.formatMessage(messages.guarantorEmployer),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "guarantorGrossIncome",
			title: intl.formatMessage(messages.guarantorGrossIncome),
			render: LiteralField(literal, fieldProps)
		},
		{
			name: "comment",
			title: intl.formatMessage(messages.comment),
			render: !literal
				? props => {
						const { configElement, margin, ...rest } = props;
						return (
							<TextArea
								label={configElement.title}
								margin={margin as any}
								options={{ textAreaRows: 5 }}
								{...rest}
							/>
						);
				  }
				: LiteralField(literal, fieldProps)
		}
	] as ConfigElement<Guarantor>[];

const UpdateGuarantorField = memo((props: InputFieldProps) => {
	const intl = useIntl();
	const validation = createValidationHelpers(intl.locale);

	const guarantorConfig = createGuarantorConfig(intl, validation, props, false);

	const updateGuarantor = useFetcher(GuarantorResource.update());
	const deleteGuarantor = useFetcher(GuarantorResource.delete());
	const createGuarantor = useFetcher(GuarantorResource.create());

	const { id } = useAppLoad();

	const onUpdateGuarantor = (guarantor: Guarantor) => {
		return updateGuarantor({ customerId: guarantor.customerId }, guarantor);
	};

	const onDeleteGuarantor = (guarantor: Guarantor) => {
		return deleteGuarantor({ customerId: guarantor.customerId }, undefined);
	};

	const onCreateGuarantor = (guarantor: Guarantor) => {
		return createGuarantor({}, guarantor, [
			[
				GuarantorResource.list(),
				{ id },
				(newGuarantorId: string, guarantorIds: string[] | undefined) => [
					...(guarantorIds || []),
					newGuarantorId
				]
			]
		]);
	};

	const searchCustomer = useFetcher(CustomerInfoSearchResource.detail());
	const getCustomerInfo = async (guarantor: Guarantor) => {
		const customer = await searchCustomer(
			guarantor.customerNumber
				? { custNo: guarantor.customerNumber }
				: { orgno: guarantor.customerCompanyNumber }
		);

		const newGuarator = mapCustomerResourceToGuarantor(customer);

		const updatedGuarantor = {
			...guarantor,
			...newGuarator
		};
		return updatedGuarantor as Guarantor;
	};

	const mapCustomerResourceToGuarantor = (
		customer: Readonly<CustomerInfoSearchResource>
	) => {
		return {
			customerTypeId: customer?.cust?.customerTypeId,
			customerNumber: customer?.cust?.customerNumber,
			customerCompanyNumber: customer?.cust?.customerCompanyNumber,
			customerName: customer?.cust?.customerName,
			phoneMobile: customer?.cust?.phoneMobile,
			email: customer?.cust?.email,
			address: customer?.addr?.address1,
			addressZip: customer?.addr?.addressZip,
			addressCity: customer?.addr?.addressCity
		} as Partial<Guarantor>;
	};

	const initialGuarantors = useResource(GuarantorResource.list(), { id }) ?? [];

	return (
		<Grid xs={12}>
			<ExpandableListEditSingle<Guarantor>
				header={intl.formatMessage(messages.header)}
				initialElementsList={initialGuarantors}
				elementIdKey={"customerId"}
				elementConfig={guarantorConfig}
				onCreate={guarantor => onCreateGuarantor(guarantor)}
				onUpdate={guarantor => onUpdateGuarantor(guarantor)}
				onDelete={guarantor => onDeleteGuarantor(guarantor)}
				elementCreator={() => GuarantorResource.fromJS({})}
				collapsedElement={collapsedElement}
				margin="dense"
				canDelete
				useThemedButtons
				autofillFunction={guarantor => getCustomerInfo(guarantor)}
				columns={2}
				maxRows={2}
			/>
		</Grid>
	);
});

UpdateGuarantorField.displayName = "UpdateGuarantorField";

const UpdateGuarantorLiteral = memo((props: InputFieldProps) => {
	const { id } = useAppLoad();

	const guarantors = useResource(GuarantorResource.list(), { id });

	const intl = useIntl();
	const validation = createValidationHelpers(intl.locale);

	const guarantorConfig = createGuarantorConfig(intl, validation, props, true);

	return (
		<Grid xs={12}>
			<ExpandableListEditSingle<Guarantor>
				header={intl.formatMessage(messages.header)}
				initialElementsList={guarantors}
				elementIdKey={"customerId"}
				elementConfig={guarantorConfig}
				collapsedElement={collapsedElement}
				margin="dense"
				canAddElement={false}
				canSave={false}
				useThemedButtons
				columns={1}
			/>
		</Grid>
	);
});

export { UpdateGuarantorLiteral, UpdateGuarantorField };
