import { useContext, useCallback } from "react";
import {
	StateContext,
	ReadShape,
	Schema,
	ParamsFromShape,
	BodyFromShape,
	useFetchDispatcher,
	ReturnFromShape,
	State,
	FetchShape
} from "@rest-hooks/core";

function selectMeta<R = any>(state: State<R>, fetchKey: string) {
	return state.meta[fetchKey];
}

function selectExpiresAt<Params extends Readonly<object>, R = any>(
	state: State<R>,
	fetchShape: Pick<ReadShape<any, Params>, "getFetchKey" | "options">,
	params: Params | null,
	entitiesExpireAt = 0
): number {
	const { getFetchKey } = fetchShape;
	const key = params ? getFetchKey(params) : "";
	const meta = selectMeta(state, key);

	if (!meta) {
		return entitiesExpireAt;
	}

	// Temporarily prevent infinite loops until invalidIfStale is revised
	if (
		fetchShape.options?.invalidIfStale &&
		meta.prevExpiresAt &&
		meta.expiresAt - meta.prevExpiresAt < 1000
	) {
		return meta.expiresAt + 2000;
	}

	return meta.expiresAt;
}

export default function usePreFetcher(): <
	Shape extends FetchShape<Schema, Readonly<object>, any>
>(
	fetchShape: Shape,
	params: ParamsFromShape<Shape>,
	body?: BodyFromShape<Shape>,
	entitiesExpireAt?: number
) => ReturnFromShape<typeof fetchShape> | undefined {
	const state = useContext(StateContext);

	const dispatchFetcher = useFetchDispatcher(true);

	return useCallback(
		(fetchShape, params, body, entitiesExpireAt = 0) => {
			const expiresAt = selectExpiresAt(
				state,
				fetchShape,
				params,
				entitiesExpireAt
			);

			if (Date.now() < expiresAt || !params) return;

			return dispatchFetcher(fetchShape, params, body!);
		},
		[dispatchFetcher, state]
	);
}
