import React from "react";

import { useVariableData } from "@ploy-lib/calculation";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import {
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants
} from "./ButtonCommon";

import { ButtonField } from "./Button";

export const SubmitButton = ({
	label,
	disabled,
	...props
}: ButtonFieldProps) => {
	const { value: overrideLabel, missing } = useVariableData(
		"Calculator",
		"SubmitButtonOverrideLabel"
	);

	const { value: errorMessage } = useVariableData("Calculator", "ErrorMessage");

	return (
		<ButtonField
			{...props}
			role="submit"
			disabled={disabled || errorMessage !== ""}
			label={(!missing && overrideLabel?.toString()) || label}
		/>
	);
};

SubmitButton.displayName = "SubmitButton";

export const EditorSubmitButtons = identityRecordOfFieldEditorOptions({
	SubmitButton: {
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors
		}
	}
});
