import { useContext, useEffect } from "react";
import {
	IThirdPartyScriptsContext,
	ThirdPartyScriptsContext
} from "./ThirdPartyScriptsContext";

export default function GoogleTagManagerInitializer({
	GoogleTagManagerCode,
	CustomerCategoryId
}) {
	const thirdPartySettings = useContext(ThirdPartyScriptsContext);

	useEffect(() => {
		initGoogleTagManager(thirdPartySettings.google, GoogleTagManagerCode);
	}, [GoogleTagManagerCode]);

	useEffect(() => {
		gtmManagerIdentity(thirdPartySettings.google, CustomerCategoryId);
	}, [CustomerCategoryId]);

	return null;
}

const gtmManagerIdentity = (
	google: IThirdPartyScriptsContext["google"],
	type?: number
) => {
	if (type) {
		// Do not initialize if already initialized
		if (google.getHistory().some(x => x["gtm.start"])) return;

		//do nothing if type is falsy or 0
		let typeString;

		switch (type) {
			case 1:
				typeString = "bil";
				break;
			case 2:
				typeString = "gjenstand";
				break;
			default:
				break;
		}

		google.push({
			event: "identifisering",
			userType: typeString
		});
	}
};

const initGoogleTagManager = (
	google: IThirdPartyScriptsContext["google"],
	code?: string
) => {
	google.pushAll("init", {
		"gtm.start": new Date().getTime(),
		event: "gtm.js"
	});

	if (google.appendScript) {
		const firstScriptTag = document.getElementsByTagName("script")[0];
		const scriptTag = document.createElement("script");

		scriptTag.async = true;
		scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${code}`;

		firstScriptTag?.parentNode?.insertBefore(
			scriptTag,
			firstScriptTag.nextSibling
		);
	}
};
