import { useContext, useEffect } from "react";
import { ThirdPartyScriptsContext } from "@ploy-ui/core";
import { usePageState } from "../PageContext";
import { useAppLoad } from "../appLoadContext";
import { useParsedQuery } from "@ploy-lib/routing";
import { useVariableData } from "@ploy-lib/calculation";
import { LoginResource, VulcanDataResource } from "@ploy-lib/rest-resources";
import { useCache, useResource } from "@rest-hooks/core";
import { getExtendedGTMData } from "./GoogleTagManageExtended";
import { getGTMData } from "./GoogleTagManager";

export const useGTM = () => {
	const { initialStep, totalSteps, step, labels } = usePageState();
	const lowerCaseLabels = labels.map(l => l && l.toLocaleLowerCase());
	const tabName = lowerCaseLabels[step];
	const appLoadData = useAppLoad();

	const { fromSubmit, AuthorizationCode: sblAuthCode } = useParsedQuery<{
		fromSubmit: boolean;
		AuthorizationCode: string;
	}>();
	const fromSubmitOrSbl = fromSubmit || !!sblAuthCode;

	const { google, settings } = useContext(ThirdPartyScriptsContext);
	const objectCategoryId = useVariableData<string>(
		"Object",
		"CategoryCombined"
	).value;

	const { isAuthenticated } = useCache(LoginResource.status(), {}) ?? {};

	const vulcanData = useResource(
		VulcanDataResource.detail(),
		isAuthenticated ? {} : null
	);

	const loanAmount = useVariableData<number>("Calculator", "LoanAmount");

	type optionsType = { key: string; value: string };
	const objectCategoriesOptions = useVariableData<optionsType[] | string>(
		"Object",
		"options_CategoryCombined"
	);

	if (!settings.loadGoogleTagManager) return (event: string = "") => {};

	//	Matches e.g. '"85|48"' to '{key: "85|48", value: "Campingvogn|Kjøretøy"}' => "Campingvogn":
	const selectedObjectCategory: string | undefined = Array.isArray(
		objectCategoriesOptions?.value
	)
		? objectCategoriesOptions?.value
				?.filter((catCombined: optionsType) => {
					return (
						catCombined["key"].split("|")[0] === objectCategoryId?.split("|")[0]
					);
				})[0]
				?.value.split("|")[0]
		: undefined;

	let virtualPageViewURLPostfix = settings.loadGoogleTagManagerExtended
		? GTMCodeFromProductName(appLoadData.productName ?? "")
		: "";

	const hotjarUri = `/soknad${virtualPageViewURLPostfix}/steg${step}`;

	const GTMData = settings.loadGoogleTagManagerExtended
		? getExtendedGTMData(
				google,
				appLoadData.applicationNumber,
				appLoadData.productName,
				vulcanData?.vendorName,
				tabName,
				initialStep,
				step,
				totalSteps,
				appLoadData.vulcanContext === "PostProcess",
				loanAmount.value ?? 0,
				selectedObjectCategory,
				appLoadData.productId,
				appLoadData.vendorInfo?.salesChannel,
				fromSubmitOrSbl,
				virtualPageViewURLPostfix
		  )
		: getGTMData(
				google,
				appLoadData.applicationNumber,
				appLoadData.productName,
				vulcanData?.vendorName,
				step,
				appLoadData.vulcanContext === "PostProcess",
				loanAmount.value ?? 0
		  );

	const pushEvent = (event: string = "") => {
		const eventCode = event ? event : GTMData?.event;
		if (eventCode) {
			google.push({ GTMData, event: event ? event : GTMData?.event });
			//	Call hj() after pushing to dataLayer
			if (window["hj"]) {
				window["hj"]("vpv", hotjarUri);
			}
		}
	};
	return pushEvent;
};

export const HandlePageChangeForGTM = ({ step }: { step: number }) => {
	const pushEvent = useGTM();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => pushEvent(), [step]);
	return null;
};

const GTMCodeFromProductName = (productName: string) => {
	return (
		"-" +
		productName
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/æ/g, "ae")
			.replace(/ø/g, "oe")
			.replace(/å/g, "aa")
	);
};
