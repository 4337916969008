import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";
import { EndpointExtraOptions, Resource } from "@rest-hooks/rest";

export class MissingCourseResource extends BaseResource {
	readonly externalCourseID: string;
	readonly courseUrl?: string;
	readonly courseName: string;

	pk() {
		return this.externalCourseID;
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: Infinity
		};
	}

	static url<T extends typeof Resource>(this: T) {
		return this.urlRoot;
	}

	static urlRoot = apiResourceUrl("user/courses/incomplete");
}
