import { makeStyles, Theme } from "@material-ui/core/styles";
import { usePages } from "../../pagesContext";
import { addRegisteredStepper } from "./stepperDescriptions";
import { BaseStepperProps } from "./BaseStepperProps";
import { usePageState } from "../../PageContext";
import { Grid, Typography } from "@material-ui/core";
import { FormattedMessage, FormattedNumber } from "react-intl";

const useStepperTextStepperStyles = makeStyles((theme: Theme) => ({
	textContent: {
		fontSize: 32
	}
}));

addRegisteredStepper({
	name: "TextStepper",
	displayName: "TextStepper"
});

export const TextStepper = (props: BaseStepperProps) => {
	const pages = usePages();
	const { step } = usePageState();

	var count = -1;

	const displayIndexArr: number[] = pages.map(p => {
		if (p.displayInStepper) {
			count += 1;
			return count;
		}
		return -1;
	});

	const getDisplayNum = (step: number) => {
		if (step === 0 && displayIndexArr[step] === -1) {
			return -1;
		}
		return displayIndexArr[step] === -1
			? getDisplayNum(step - 1)
			: displayIndexArr[step] + 1;
	};

	const activeStep = getDisplayNum(step);

	const TextStepperClasses = useStepperTextStepperStyles();

	return activeStep > -1 ? (
		<Grid container justifyContent="center">
			<Typography className={TextStepperClasses.textContent}>
				<FormattedMessage
					id="dploy.text-stepper.part"
					defaultMessage={"Part "}
				/>
				<FormattedNumber value={getDisplayNum(step)} />
				<FormattedMessage id="dploy.text-stepper.of" defaultMessage={" of "} />
				<FormattedNumber value={count + 1} />
			</Typography>
		</Grid>
	) : null;
};
