import { FieldConfig, FieldInputProps, useField } from "formik";
import {
	FormControlLabel,
	FormControlLabelProps,
	Checkbox,
	Switch,
	Radio
} from "@material-ui/core";

export type FormikFormControlLabelProps<T> = Omit<
	FormControlLabelProps,
	keyof FieldInputProps<T> | "control"
> &
	Pick<FieldConfig<T>, "name" | "type" | "value"> & {
		control?: FormControlLabelProps["control"];
		type?: "checkbox" | "radio";
	};

export function FormikFormControlLabel<T>(
	props: FormikFormControlLabelProps<T>
) {
	const { name, type, value, ...rest } = props;

	const [field] = useField<T>({ name, type: type ?? "checkbox", value });

	let control = <Switch />;

	switch (type) {
		case "checkbox":
			control = <Checkbox />;
			break;
		case "radio":
			control = <Radio />;
			break;
	}

	return <FormControlLabel control={control} {...rest} {...field} />;
}
