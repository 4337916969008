import {
	generatePath,
	Navigate,
	NavigateProps,
	Params,
	Route,
	useParams
} from "react-router-dom";
import { useResource } from "@rest-hooks/core";
import { ProductResource, AppLoadResource } from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { parsePath } from "history";

export const navMigration = {
	CarSubscription: "external/AuthenticatedView",
	FrontPage: "dashboard",
	ProductCalculator: "form/calculator",
	AppLoanLeasing: "form/finance",
	AllApplications: "applications",
	Reports: "reports",
	NewService: "form/service",
	"ExternalView/KmReading": "external/KmReading",
	"ExternalView/Commission": "external/Commission",
	"ExternalView/Fulfillment": "external/Fulfillment",
	"Phoenix/Fulfillment": "external/Fulfillment",
	SalespersonAssignment: "salesperson-assignment",
	ProductSelector: "form/finance",
	WholesaleOverview: "wholesale-overview"
};

function ProductNavigate(props: NavigateProps) {
	const params = useParams<"id">();
	const products = useResource(ProductResource.list(), {});

	const product = products.find(p => p.id?.toString() === params.id);

	return (
		<ParamsNavigate
			{...props}
			defaults={{ id: product?.externalCode.toLowerCase() ?? "" }}
		/>
	);
}

function ApplicationNavigate(props: NavigateProps) {
	const params = useParams<"id">();
	const appLoad = useResource(AppLoadResource.detail(), {
		url: legacyApiResourceUrl(`AppLoad/Load/${params.id}`)
	});

	return (
		<ParamsNavigate {...props} defaults={{ id: appLoad.applicationNumber }} />
	);
}

export function ParamsNavigate<Key extends string = string>(
	props: NavigateProps & { defaults?: Params<Key> }
) {
	const { to, replace, state, defaults } = props;

	const { pathname, search, hash } =
		typeof to === "string" ? parsePath(to) : to;

	const params = useParams<"id">();

	return (
		<Navigate
			to={{
				pathname:
					pathname && generatePath(pathname, { ...params, ...defaults }),
				hash,
				search
			}}
			replace={replace}
			state={state}
		/>
	);
}

/* Route migration from v1 */
export const v1routes = (
	<>
		<Route path="FrontPage" element={<Navigate replace to="dashboard" />} />
		<Route
			path="AppLoanLeasing/Load"
			element={<Navigate replace to="applications" />}
		/>
		<Route
			path="AppLoanLeasing/Load/:id"
			element={<ApplicationNavigate replace to="form/application/:id" />}
		/>
		<Route
			path="AppLoanLeasing"
			element={<Navigate replace to="form/finance" />}
		/>
		<Route
			path="AppLoanLeasing/CreateWithSelector"
			element={<Navigate replace to="form/finance" />}
		/>
		<Route
			path="AppLoanLeasing/CreateWithSelector/:id"
			element={<ProductNavigate replace to="form/finance/:id" />}
		/>
		<Route path="NewService" element={<Navigate replace to="form/service" />} />
		<Route
			path="NewService/CreateWithSelector"
			element={<Navigate replace to="form/service" />}
		/>
		<Route
			path="NewService/CreateWithSelector/:id"
			element={<ProductNavigate to="form/service/:id" />}
		/>
		<Route
			path="ProductCalculator"
			element={<Navigate replace to="form/calculator" />}
		/>
		<Route
			path="ProductCalculator/:id"
			element={<ProductNavigate replace to="form/calculator/:id" />}
		/>
		<Route
			path="AllApplications"
			element={<Navigate replace to="applications" />}
		/>
		<Route path="ExternalView" element={<Navigate replace to="external" />} />
		<Route
			path="ExternalView/:view"
			element={<ParamsNavigate replace to="external/:view" />}
		/>
		<Route
			path="SalespersonAssignment"
			element={<Navigate replace to="salesperson-assignment" />}
		/>
		<Route
			path="Phoenix/:view"
			element={<ParamsNavigate replace to="external/:view" />}
		/>
		<Route
			path="ProductSelector"
			element={<Navigate replace to="form/finance" />}
		/>
		<Route
			path="WholesaleOverview"
			element={<Navigate replace to="wholesale-overview" />}
		/>
	</>
);
