import React from "react";

export const ImageFit = props => {
	const {
		src,
		fit,
		position,
		supportFitPositionInIE = true,
		component,
		...residualProps
	} = props;

	if (component || (supportFitPositionInIE && (fit || position))) {
		const Component = component || "div";
		return (
			<Component
				{...residualProps}
				style={{
					...residualProps.style,
					backgroundImage: `url("${src}")`,
					backgroundSize: fit,
					backgroundPosition: position || "center",
					backgroundRepeat: "no-repeat"
				}}
			/>
		);
	}

	return (
		<img
			alt=""
			src={src}
			{...residualProps}
			style={{
				...residualProps.style,
				objectFit: fit,
				objectPosition: position
			}}
		/>
	);
};
