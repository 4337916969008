import React, { useState, useCallback } from "react";
import Section, { SectionProps } from "../Section";
import { Box, Switch, FormControlLabel } from "@material-ui/core";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { WrappedComponentProps } from "react-intl";

addRegisteredSectionLayout({
	name: "ToggleLiteralSection",
	displayName: "ToggleLiteralSection",
	settings: {}
});

const ToggleLiteralSection = (props: SectionProps & WrappedComponentProps) => {
	const { className, fields } = props;
	const [literalToggled, setLiteralToggled] = useState(props.literal);
	const toggleLiteral = useCallback(() => setLiteralToggled(x => !x), []);

	if (fields.length === 0) return null;

	return (
		<>
			<Section {...props} literal={literalToggled} />
			<Box className={className} display="flex" justifyContent="center">
				<FormControlLabel
					control={
						<Switch
							color="primary"
							checked={!literalToggled || false}
							value={!literalToggled}
							onChange={toggleLiteral}
						/>
					}
					label={"Gjør endringer"}
				/>
			</Box>
		</>
	);
};

ToggleLiteralSection.displayName = "DployToggleLiteralSection";

export { ToggleLiteralSection };
