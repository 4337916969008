import React, { useLayoutEffect, useState, useEffect, useRef } from "react";

export function useResizeCallback(
	callback: (
		...sizes: {
			clientWidth: number;
			parentWidth: number;
			scrollWidth: number;
		}[]
	) => void,
	...refs: React.RefObject<Element>[]
) {
	const callbackRef = useRef(callback);
	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useLayoutEffect(() => {
		function update() {
			const sizes = refs.map(({ current: node }) => ({
				clientWidth: node?.clientWidth ?? 0,
				scrollWidth: node?.scrollWidth ?? 0,
				parentWidth: node?.parentElement?.clientWidth ?? 0
			}));

			callbackRef.current?.(...sizes);
		}
		window.addEventListener("resize", update);
		update();
		return () => window.removeEventListener("resize", update);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...refs]);
}

export function useParentWidth(ref: React.RefObject<Element>) {
	const [width, setWidth] = useState(0);
	useResizeCallback(({ parentWidth }) => setWidth(parentWidth), ref);
	return width;
}
