import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";
import { ProductInfoLink } from "./ProductInfoLinkResource";

export interface ProductInfo {
	readonly productId: number;
	readonly productName: string;
	readonly productInfoLinks: ProductInfoLink[];
}

export class GeneralInfoResource
	extends BaseCamelCasedResource
	implements ProductInfo
{
	readonly productId: number;
	readonly productName: string;
	readonly productInfoLinks: ProductInfoLink[];

	pk() {
		return `${this.productId}`;
	}

	static urlRoot = legacyApiResourceUrl("Product/GetGeneralInfo");
}
