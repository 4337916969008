import { apiResourceUrl } from "@ploy-lib/core";
import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { ApplicationResource } from "./ApplicationResource";
import { EndpointExtraOptions } from "@rest-hooks/rest";

export enum AMLRisk {
	NoContact = "NoContact",
	NotSet = "NotSet",
	Low = "Low",
	Medium = "Medium",
	High = "High"
}

export enum CustomerAMLRole {
	NotSet = "NotSet",
	Customer = "Customer",
	CoSigner = "CoSigner",
	BeneficialOwner = "BeneficialOwner",
	Dagligleder = "Dagligleder",
	Nestleder = "Nestleder",
	Obervatør = "Observatør",
	Styremedlem = "Styremedlem",
	Styretsleder = "Styretsleder",
	Varamedlem = "Varamedlem",
	Kontaktperson = "Kontaktperson",
	Fullmaktshaver = "Fullmaktshaver",
	Unknown = "Unknown"
}

export interface RiskResult {
	name: string;
	result: AMLRisk;
	riskScore: number;
	value?: string;
}

export interface CustomerAMLResult {
	firstName: string;
	lastName: string;
	role: CustomerAMLRole;
	riskResults: RiskResult[];
}

export class ApplicationAMLRisksResource extends BaseResource {
	readonly id?: ID;
	readonly applicationNumber?: string;
	readonly totalRisk: AMLRisk = AMLRisk.NotSet;
	readonly overrideRisk?: AMLRisk;
	readonly customerAMLResults: CustomerAMLResult[] = [];
	readonly riskResults: RiskResult[] = [];
	readonly canOverride: Boolean;
	readonly showRiskScoreValue: Boolean;

	pk() {
		return this.id?.toString();
	}

	// static indexes = ["applicationNumber"];

	static urlTemplates = [
		`${ApplicationResource.urlRoot}/{applicationNumber}/aml-risk`
	];

	static urlRoot = apiResourceUrl("aml-risks");
}

export class ApplicationAMLRiskHistoryResource extends BaseResource {
	readonly id?: ID;
	readonly userFullName?: string;
	readonly risk: AMLRisk = AMLRisk.NotSet;
	readonly comment: string = "";
	readonly timestamp: string = "";

	pk() {
		return this.id?.toString();
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			pollFrequency: 60 * 1000,
			dataExpiryLength: 30 * 1000
		};
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/history`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{amlRiskId}/history`;
}
