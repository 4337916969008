import {
	Variable,
	Resolve,
	Validator,
	TemplateField,
	Service
} from "@ploy-lib/types";
import {
	GeneratedCalcRule,
	ResolvedField,
	Namespaced,
	FunctionTypes
} from "./types";
import { composeFirstParam, isNotNull, getInNamespaced } from "./utils";
import { createCalculatorFunctions } from "./createCalculatorFunctions";
import {
	Patch,
	ServiceBodyField,
	ServiceBodyValue,
	FieldPatch,
	ServiceTrigger
} from "../types";
import { createPatcher } from "./createPatcher";
import { createExecuteFunctions } from "./createExecuteFunctions";
import { servicePendingName } from "../utils";
import { ValidationHelpers } from "@ploy-lib/validation-helpers";
import sortBy from "lodash/sortBy";

export interface CalculatorState<TNamespaces extends string, TData> {
	values: Partial<Namespaced<number | string | TData, TNamespaces>>;
	isMissing: Partial<Namespaced<boolean, TNamespaces>>;
	isWriteLocked: Partial<Namespaced<boolean, TNamespaces>>;
	isFieldVisible: Partial<Namespaced<boolean, TNamespaces>>;
	isEnabled: Partial<Namespaced<boolean, TNamespaces>>;
	errors: Partial<Namespaced<boolean | string, TNamespaces>>;
	controlFieldMaps: Partial<
		Namespaced<ResolvedField<TNamespaces>, TNamespaces>
	>;
	fieldControlMaps: Partial<Namespaced<string, TNamespaces>>;
	variableControlMaps: Partial<Namespaced<string, TNamespaces>>;
	resolvedVariableMaps: Partial<Namespaced<string[], TNamespaces>>;
	formValues: Partial<Namespaced<TData, TNamespaces>>;
	initialFormValues: Partial<Namespaced<TData, TNamespaces>>;
	defaultFieldVisibility: Partial<Namespaced<boolean, TNamespaces>>;
	serviceBodyValuesMap: Partial<Record<TNamespaces, ServiceBodyValue[]>>;
}

export interface Change<TNamespaces> {
	namespace?: TNamespaces;
	changedFieldId: string;
}

export interface Calculator<TNamespaces extends string, TData> {
	init(
		input: Partial<CalculatorState<TNamespaces, TData>>,
		form: Partial<Namespaced<any, TNamespaces>>
	): CalculatorState<TNamespaces, TData>;

	patch<T extends Partial<CalculatorState<TNamespaces, TData>>>(
		state: T,
		patches: readonly (
			| Patch<TNamespaces, TData>
			| FieldPatch<TNamespaces, TData>
		)[]
	): [T, Change<TNamespaces>[], TNamespaces[]];

	update(
		state: CalculatorState<TNamespaces, TData>,
		formValues: Partial<Namespaced<TData, TNamespaces>>,
		writeLocked: Partial<Namespaced<boolean | null, TNamespaces>>
	): readonly [
		CalculatorState<TNamespaces, TData>,
		readonly Change<TNamespaces>[],
		readonly TNamespaces[]
	];

	calculate(
		state: CalculatorState<TNamespaces, TData>,
		changed: readonly Change<TNamespaces>[],
		init: readonly TNamespaces[]
	): readonly [
		CalculatorState<TNamespaces, TData>,
		readonly ServiceTrigger<TNamespaces>[],
		readonly Change<TNamespaces>[]
	];

	validate(
		state: CalculatorState<TNamespaces, TData>
	): CalculatorState<TNamespaces, TData>;
}

export function createCalculator<TNamespaces extends string, TData>(
	cgfMap: Record<string, TemplateField>,
	generatedCalcrules: Partial<Record<TNamespaces, GeneratedCalcRule<TData>>>,
	validationHelpers: ValidationHelpers,
	variableDefs: Record<TNamespaces, Variable[]>,
	validatorDefs: Record<TNamespaces, Validator[]>,
	functions: FunctionTypes[],
	services: Record<TNamespaces, Service[]>,
	resolveDefs: Partial<Record<string, Resolve<TNamespaces>>> = {},
	macros?: Namespaced<number, TNamespaces>,
	serviceBodyFields?: Partial<Record<TNamespaces, ServiceBodyField[]>>
): Calculator<TNamespaces, TData> {
	const funcs = createExecuteFunctions(functions);

	const triggerMaps = new Map(
		Object.entries<Service[]>(services).map(([namespace, serviceList]) => {
			const triggerMap = new Map<string, ServiceTrigger<TNamespaces>[]>();

			const triggerList = serviceList.map(s => ({
				namespace: namespace as TNamespaces,
				service: s.name,
				changeFilter: s.changeFilter,
				expression: s.expression
			}));

			for (const trigger of triggerList) {
				const changeFilters =
					trigger.changeFilter && trigger.changeFilter !== "#manual"
						? trigger.changeFilter.split("|")
						: [];

				for (const changedFieldId of changeFilters) {
					const triggers = triggerMap.get(changedFieldId) || [];
					triggerMap.set(changedFieldId, [...triggers, trigger]);
				}
			}

			return [namespace, triggerMap] as [TNamespaces, typeof triggerMap];
		})
	);

	// DR FieldFunction and Validator format (namespace/changedFieldId)  -->  executeFunctions() format (path)
	function changeToPath(change: Change<TNamespaces>) {
		if (change.changedFieldId.includes("_Service_")) {
			// example: "Calculator_Service_Conditions_Success"
			const [namespace] = change.changedFieldId.split("_");
			return `services.${namespace}.${change.changedFieldId}`;
		}

		return `values.${change.namespace}.${change.changedFieldId}`;
	}

	// executeFunctions() format (path)  -->  DR FieldFunction and Validator format (namespace/changedFieldId)
	function pathToChange(path: string): Change<TNamespaces> {
		const [type, namespace, changedFieldId] = path.split(".", 3);

		return {
			namespace:
				type === "services" || namespace === "undefined"
					? undefined
					: (namespace as TNamespaces),
			changedFieldId
		};
	}

	const allNamespaces = Object.keys(variableDefs) as TNamespaces[];

	const defaultGlobalChange: Change<TNamespaces>[] = allNamespaces.map(
		namespace => ({
			namespace,
			changedFieldId: "ForeignResolves"
		})
	);

	const functionsList = allNamespaces.map(namespace =>
		createCalculatorFunctions<TNamespaces, TData>(
			namespace as TNamespaces,
			generatedCalcrules,
			validationHelpers,
			variableDefs,
			validatorDefs,
			resolveDefs,
			macros,
			serviceBodyFields
		)
	);

	const namespacedVariables = functionsList.reduce((acc, f) => {
		acc[f.namespace] = f.variableMap;
		return acc;
	}, {} as Partial<Namespaced<Variable, TNamespaces>>);

	// Map: namespace -> ['SomeVar', 'FakeInit', ...]
	const namespaceInitOnLoadVariables = new Map(
		Object.entries<Variable[]>(variableDefs).map(([n, variables]) => {
			let initOnLoad = sortBy(
				variables.filter(v => v.initializeOnLoad),
				v => v.initializeOrder ?? 0
			).map(v => v.name);

			if (initOnLoad.length === 0) initOnLoad = ["N/A"];

			return [n, initOnLoad] as [typeof n, typeof initOnLoad];
		})
	);

	// Map: namespace -> { initialize, update, calculate, validate }
	const namespaceFunctions = new Map(
		functionsList.map(
			f => [f.namespace, f] as [typeof f["namespace"], typeof f]
		)
	);

	const initAll = composeFirstParam(...functionsList.map(fs => fs.initialize));
	const updateAll = composeFirstParam(...functionsList.map(fs => fs.update));
	const validateAll = composeFirstParam(
		...functionsList.map(fs => fs.validate)
	);

	const resolvesInv = Object.entries(resolveDefs).reduce(
		(acc, [name, resolve]) => {
			const namespace = resolve && resolve.namespace;
			const field = resolve && resolve.field;
			if (namespace && field) {
				if (!acc[namespace]) acc[namespace] = new Map<string, string>();
				acc[namespace].set(field, name);
			}
			return acc;
		},
		{} as Record<TNamespaces, Map<string, string>>
	);

	const patcher = createPatcher<TNamespaces, TData>(
		namespacedVariables,
		cgfMap
	);

	const patch: Calculator<TNamespaces, TData>["patch"] = (state, patches) => {
		const init: TNamespaces[] = [];

		const fieldChanged: Change<TNamespaces>[] = [];
		const resolveChanged: Change<TNamespaces>[] = [];
		let globalChange: Change<TNamespaces>[] = [];
		const addChangeTrigger = (
			namespace: TNamespaces,
			changedFieldId: string,
			isResolve: boolean
		) => {
			if (isResolve) {
				resolveChanged.push({ namespace, changedFieldId });
			} else {
				globalChange.push(...defaultGlobalChange);
				fieldChanged.push({ namespace, changedFieldId });
			}
		};

		const patched = patcher(state, addChangeTrigger, patches);

		const changed: Change<TNamespaces>[] = [
			...fieldChanged,
			...resolveChanged,
			...globalChange
		];

		return [patched, changed, init] as [
			typeof patched,
			typeof changed,
			typeof init
		];
	};

	const update: Calculator<TNamespaces, TData>["update"] = (
		state,
		formValues,
		writeLocked
	) => {
		const init: TNamespaces[] = [];
		const addInitTrigger = (namespace: TNamespaces) => init.push(namespace);
		const fieldChanged: Change<TNamespaces>[] = [];
		const resolveChanged: Change<TNamespaces>[] = [];
		let globalChange: Change<TNamespaces>[] = [];
		const addChangeTrigger = (
			namespace: TNamespaces,
			changedFieldId: string,
			isResolve: boolean
		) => {
			if (isResolve) {
				resolveChanged.push({ namespace, changedFieldId });
			} else {
				// Handle CustomGuiField.GlobalEvent here
				// globalChange.push(something)
				globalChange.push(...defaultGlobalChange);
				fieldChanged.push({ namespace, changedFieldId });
			}
		};

		// If formValues object is strict equal to the one used in the previus calculation, skip calculation.
		if (state.formValues && formValues === state.formValues)
			return [state, fieldChanged, init];

		// Use immer to wrap mutating update() call, and record the changes.
		// All writes to a property will be registered, even if the value is unchanged.
		// update() does equality checks before writing, so we only register actual changes.
		// Another option is to use lower level functions in immer to generate patches,
		// and compare the changes with the original before applying.

		const updateOuput = updateAll(
			state,
			patcher,
			addChangeTrigger,
			addInitTrigger,
			formValues,
			writeLocked
		);

		const changed: Change<TNamespaces>[] = [
			...fieldChanged,
			...resolveChanged,
			...globalChange
		];

		// Keep a reference to the latest formValues object
		let updated = {
			...updateOuput,
			formValues
		};

		// First update: execute all simple functions once after updating.
		if (!state.formValues) {
			let [next, addedChanges] = funcs.executeCalculateFunctions(updated);

			[next, addedChanges] = funcs.executeVariableStateFunctions(
				next,
				addedChanges
			);

			[next, addedChanges] = funcs.executeFieldErrorFunctions(
				next,
				addedChanges
			);

			updated = next;
			changed.concat(
				addedChanges.filter(c => c.startsWith("values.")).map(pathToChange)
			);
		}

		return [updated, changed, init];
	};

	const calculate: Calculator<TNamespaces, TData>["calculate"] = (
		state,
		changed,
		init
	) => {
		// if values.{namespace} was added, it should be initialized
		// changes: [{ path: ['values', '{namespace}'] }]
		const variablesToTriggerInitialCalculation = init.map(namespace => ({
			namespace,
			variables: namespaceInitOnLoadVariables.get(namespace)
		}));

		// Find foreign resolves connected to changed variable
		// Could propably pre-process most of this in initialize(), instead of doing it after every update
		const foreignResolveTriggers = changed
			.flatMap(({ namespace, changedFieldId }) => {
				if (!namespace) return null;

				const control = getInNamespaced(
					state.variableControlMaps,
					namespace,
					changedFieldId
				);

				const field =
					control &&
					getInNamespaced(state.controlFieldMaps, namespace, control);

				const fieldName = field && field.fieldName;

				const namespaceResolvesInv = resolvesInv[namespace];
				const resolve =
					fieldName &&
					namespaceResolvesInv &&
					namespaceResolvesInv.get(fieldName);

				if (!resolve) return null;

				return Object.keys(state.resolvedVariableMaps).map(
					(resolvedNamespace: TNamespaces) => {
						const resolveMap = state.resolvedVariableMaps[resolvedNamespace];
						const variables = resolveMap && resolveMap[resolve];

						return variables && variables.length > 0
							? {
									namespace: resolvedNamespace,
									variables,
									changedFieldId
							  }
							: null;
					}
				);
			})
			.filter(isNotNull);

		const foreignResolvesToTriggerCalculation = foreignResolveTriggers.reduce(
			(acc, { namespace, variables }) => {
				acc[namespace] = acc[namespace] || [];
				acc[namespace].push(...variables);
				return acc;
			},
			{} as Record<TNamespaces, string[]>
		);

		const initBasePaths: (keyof typeof state)[] = [
			"values",
			"isMissing",
			"isWriteLocked"
		];
		const initPaths = init.flatMap(ns =>
			initBasePaths.map(path => `${path}.${ns}`)
		);
		let changedPaths = changed.map(changeToPath).concat(initPaths);

		[state, changedPaths] = funcs.executeCalculateFunctions(
			state,
			changedPaths
		);
		[state, changedPaths] = funcs.executeVariableStateFunctions(
			state,
			changedPaths
		);

		let allChanges = changedPaths
			.filter(
				path =>
					(path.startsWith("values.") || path.startsWith("services.")) &&
					!initPaths.some(initPath => path.startsWith(initPath))
			)
			.map(pathToChange)
			.filter(c => c.changedFieldId);

		let globalEventChanges = allChanges
			.map(f => {
				if (!f.namespace) return null;
				let guiField = cgfMap[`${f.namespace}.${f.changedFieldId}`];
				if (guiField && guiField.globalEvent)
					return {
						changedFieldId: guiField.globalEvent
					} as Change<TNamespaces>;
				return null;
			})
			.filter(f => f !== null) as Change<TNamespaces>[];
		allChanges = allChanges.concat(globalEventChanges);

		const triggerChanged = new Set();
		function addTriggers(ns: TNamespaces, triggers?: Set<string>) {
			if (!triggers) return;
			for (const name of triggers) {
				triggerChanged.add(
					changeToPath({
						changedFieldId: name,
						namespace: ns
					})
				);
			}
		}

		for (const init of variablesToTriggerInitialCalculation) {
			const functions = namespaceFunctions.get(init.namespace);

			// Initalize new namespace
			if (functions) {
				if (!init.variables || init.variables.length === 0) {
					let triggers: Set<string> | undefined;
					[state, triggers] = functions.calculate(state);
					addTriggers(init.namespace, triggers);
				} else {
					for (const variableName of init.variables) {
						let triggers: Set<string> | undefined;
						state = functions.updateResolves(state, patcher);
						[state, triggers] = functions.calculate(state, variableName);
						addTriggers(init.namespace, triggers);
					}
				}
			}
		}

		// Calculate changes
		for (const change of allChanges) {
			const namespacesToTrigger = change.namespace
				? [namespaceFunctions.get(change.namespace)]
				: functionsList;

			for (const functions of namespacesToTrigger.filter(isNotNull)) {
				let triggers: Set<string> | undefined;
				state = functions.updateResolves(state, patcher);
				[state, triggers] = functions.calculate(state, change.changedFieldId);
				addTriggers(functions.namespace, triggers);
			}
		}

		// Calculate foreign resolves connected to changed fields
		for (const [namespace, variables] of Object.entries<string[]>(
			foreignResolvesToTriggerCalculation
		)) {
			const functions = namespaceFunctions.get(namespace as TNamespaces);

			if (functions) {
				state = functions.updateResolves(state, patcher);
				for (const variableName of variables) {
					let triggers: Set<string> | undefined;
					[state, triggers] = functions.calculate(state, variableName);
					addTriggers(functions.namespace, triggers);
				}
			}
		}

		const initChanges = variablesToTriggerInitialCalculation.flatMap(
			({ variables = [], namespace }) =>
				variables.map<Change<TNamespaces>>(changedFieldId => ({
					changedFieldId,
					namespace
				}))
		);

		const serviceTriggerSet = new Set(
			[...initChanges, ...allChanges, ...foreignResolveTriggers].flatMap(
				({ namespace, changedFieldId }) => {
					const namespacesToTrigger = namespace
						? [triggerMaps.get(namespace)]
						: [...triggerMaps.values()];

					const servicesToTrigger = namespacesToTrigger
						.flatMap(
							triggers => (triggers && triggers.get(changedFieldId)) || []
						)
						.filter(isNotNull)
						.filter(t => {
							if (t.expression) {
								// eslint-disable-next-line no-new-func
								const func = new Function(
									"Validation",
									"value",
									`return ${t.expression}`
								);
								return func(
									{
										default: validationHelpers,
										validationHelpers
									},
									getInNamespaced(state.values, namespace, changedFieldId)
								);
							}
							return true;
						});
					return servicesToTrigger;
				}
			)
		);

		if (
			process.env.NODE_ENV === "development" &&
			(window as any).__debugService === "on" &&
			serviceTriggerSet.size > 0
		) {
			for (const s of serviceTriggerSet.values()) {
				const triggeredBy = s
					.changeFilter!.split("|")
					.filter(c =>
						[...initChanges, ...allChanges, ...foreignResolveTriggers].some(
							x =>
								x.changedFieldId === c &&
								(!x.namespace || x.namespace === s.namespace)
						)
					);
				console.log(
					`${triggeredBy.join(", ")} triggered ${s.namespace}.${s.service}`
				);
			}
		}

		let serviceTriggers = [...serviceTriggerSet];

		const patches = serviceTriggers.map(({ service, namespace }) => ({
			target: servicePendingName(namespace, service),
			namespace,
			value: 1 as unknown as TData,
			overwrite: true,
			changeTrigger: servicePendingName(namespace, service),
			isResolve: true
		}));

		if (patches.length > 0) {
			// Recursively patch and calculate changes to service "pending" variables
			// This is necessary for correct behaviour when "pending" variables are used in field functions
			const [
				serviceCalculated,
				additionalServiceTriggers,
				additionalCalculateTriggers
			] = calculate(...patch(state, patches));
			state = serviceCalculated;
			serviceTriggers = [
				...new Set([...serviceTriggers, ...additionalServiceTriggers])
			];

			for (const change of additionalCalculateTriggers) {
				triggerChanged.add(changeToPath(change));
			}
		}

		const calculateTriggers = [...triggerChanged].map(pathToChange);

		return [state, serviceTriggers, calculateTriggers];
	};

	const validate: Calculator<TNamespaces, TData>["validate"] = state => {
		[state] = funcs.executeFieldErrorFunctions(state);

		let results = funcs.executeFieldStateFunctions(state);

		[state, results] = validateAll([state, results]);

		return funcs.processStateResults(state, results);
	};

	const init: Calculator<TNamespaces, TData>["init"] = (state, form) =>
		initAll(state, form);

	return {
		init,
		patch,
		update,
		calculate,
		validate
	};
}
