import clsx from "clsx";

import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ChevronRight, EmojiTransportation } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { To } from "react-router-dom";
import { autocolor, useIsActive, useLinkProps } from "@ploy-ui/core";
import { useDrawer } from "../../../contexts";

export const useMenuItemStyles = makeStyles(
	theme => ({
		root: {
			"&:not($selected)": {
				color: (props: { color?: string }) =>
					autocolor({
						bgcolor: props.color,
						emphasizeText: 0.29,
						autoContrastText: true,
						theme
					}).color
			}
		},
		collapsed: {},
		icon: {
			color: "inherit",
			display: "flex",
			justifyContent: "center"
		},
		faIcon: {
			overflow: "visible",
			fontSize: "20px"
		},
		primary: {
			color: "inherit",
			fontSize: "inherit",
			fontWeight: "inherit",
			[theme.breakpoints.between("xs", "md")]: {
				textAlign: "center",
				whiteSpace: "pre-wrap"
			}
		},
		primaryOpen: {
			textAlign: "left"
		},
		secondary: {
			color: "inherit",
			fontSize: "inherit",
			fontWeight: "inherit"
		},
		text: {},
		selected: () => ({})
	}),
	{ name: "MenuItemLink" }
);

export interface MenuItemNavLinkProps {
	className?: string;
	primary?: React.ReactNode;
	secondary?: React.ReactNode;
	iconClass?: string;
	icon?: React.ReactNode;
	secondaryAction?: React.ReactNode;
	href?: string;
	to?: To;
	target?: string;
	end?: boolean;
	caseSensitive?: boolean;
	color?: string;
	children?: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	classes?: { [key in keyof ReturnType<typeof useMenuItemStyles>]?: string };
}

function getExplicitIconOrNull(icon: string | undefined) {
	if (typeof icon === "undefined") return null;
	if (icon === "EmojiTransportation") return <EmojiTransportation />;
	return null;
}

export const MenuItemNavLink = (props: MenuItemNavLinkProps) => {
	const classes = useMenuItemStyles(props);

	const { isOpen } = useDrawer();

	let isActive = useIsActive(props);

	const {
		primary,
		secondary,
		className,
		iconClass,
		icon,
		children,
		secondaryAction,
		...rest
	} = useLinkProps(props);

	let muiIcon = getExplicitIconOrNull(iconClass);

	return (
		<MenuItem
			component="a"
			selected={isActive}
			className={clsx(classes.root, className, {
				[classes.collapsed]: !isOpen
			})}
			classes={{ selected: classes.selected }}
			{...rest}
		>
			{muiIcon ? (
				<ListItemIcon className={clsx(classes.icon)}>{muiIcon}</ListItemIcon>
			) : iconClass || icon ? (
				<ListItemIcon className={clsx(classes.icon)}>
					{icon ?? (
						<Icon className={clsx(`fa fa-${iconClass}`, classes.faIcon)} />
					)}
				</ListItemIcon>
			) : undefined}
			<ListItemText
				inset={!icon && !iconClass}
				classes={{
					primary: clsx({ [classes.primaryOpen]: isOpen }, classes.primary),
					secondary: clsx(classes.secondary)
				}}
				className={classes.text}
				primary={primary}
				secondary={secondary}
			/>
			{children}
			{isActive && secondaryAction && isOpen ? (
				<ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
			) : (
				isActive && isOpen && <ChevronRight />
			)}
		</MenuItem>
	);
};
