import {
	createContext,
	Suspense,
	SuspenseProps,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";

interface GlobalSuspenseContext {
	active: boolean;
	override: () => () => void;
}

interface SuspenseContext {
	activate: () => () => void;
}

const suspenseContext = createContext<SuspenseContext | undefined>(undefined);
const globalSuspenseContext = createContext<GlobalSuspenseContext | undefined>(
	undefined
);

interface GlobalSuspenseProviderProps {
	fallback: React.ReactNode;
	children: React.ReactNode;
	replace?: boolean;
}

export function GlobalSuspenseProvider(props: GlobalSuspenseProviderProps) {
	const { children, fallback, replace = false } = props;

	const [activeCounter, setActiveCounter] = useState(0);
	const [overriddenCounter, setOverridenCounter] = useState(0);

	const activate = useCallback(() => {
		setActiveCounter(s => s + 1);

		return () => {
			setActiveCounter(s => s - 1);
		};
	}, []);

	const override = useCallback(() => {
		setOverridenCounter(s => s + 1);

		return () => {
			setOverridenCounter(s => s - 1);
		};
	}, []);

	const globalSuspense = useContext(globalSuspenseContext);

	const active = globalSuspense?.active ?? activeCounter > 0;
	const suspense = useContext(suspenseContext);

	useEffect(() => globalSuspense?.override(), [globalSuspense]);

	const suspenseValue = useMemo(() => ({ activate }), [activate]);
	const globalSuspenseValue = useMemo(
		() => ({ override, active }),
		[active, override]
	);

	return (
		<suspenseContext.Provider value={suspense ?? suspenseValue}>
			<globalSuspenseContext.Provider value={globalSuspenseValue}>
				{active && overriddenCounter === 0 ? fallback : null}
				<GlobalSuspense>{active && replace ? null : children}</GlobalSuspense>
			</globalSuspenseContext.Provider>
		</suspenseContext.Provider>
	);
}

export function GlobalSuspenseActivator() {
	const suspense = useContext(suspenseContext);

	useEffect(() => suspense?.activate(), [suspense]);

	return null;
}

export type GlobalSuspenseProps = Omit<SuspenseProps, "fallback"> & {
	fallback?: SuspenseProps["fallback"];
};

export function GlobalSuspense(props: GlobalSuspenseProps) {
	return <Suspense fallback={<GlobalSuspenseActivator />} {...props} />;
}
