import { useMemo } from "react";
import { useResource } from "@rest-hooks/core";
import { ProfileResource, LoginResource } from "@ploy-lib/rest-resources";
import { TaskFilter } from "./TaskListItem";

export function useTasks(tasks: TaskFilter[] = [], placeholder?: boolean) {
	const profile = useResource(
		ProfileResource.detail(),
		!placeholder && tasks.some(t => t.filter.salepersonId === "current")
			? {}
			: null
	);
	const login = useResource(
		LoginResource.status(),
		!placeholder && tasks.some(t => t.filter.vendorId === "current") ? {} : null
	);

	return useMemo<TaskFilter[]>(() => {
		return tasks.map<TaskFilter>(task => ({
			...task,
			filter: {
				...task.filter,
				salepersonId:
					task.filter.salepersonId === "current"
						? profile?.salespersonId
						: task.filter.salepersonId,
				vendorId:
					task.filter.vendorId === "current"
						? login?.vendor?.id
						: task.filter.vendorId
			}
		}));
	}, [login, profile, tasks]);
}
