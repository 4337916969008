import Todos from "./Todos";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { ApplicationStatus, DocumentStatus, ID } from "@ploy-lib/types";
import { MarkdownElement } from "@ploy-ui/core";
import { DocumentType, ApplicationInfoFilter } from "@ploy-lib/rest-resources";
import { TaskFilter } from "./TaskListItem";
import { contentBoxPropertyControls } from "../../ContentBox";
import { defineMessage, FormattedMessage } from "react-intl";

interface RawTaskFilter extends ApplicationInfoFilter {
	id: string;
	salepersonId: ID;
	filterName: string;
	allSignedButSecurity: boolean;
	appfilter: "All" | "Mine";
	primaryMessage: string;
	secondaryMessage: string;
	icon: string;
}

applyPropertyControls(Todos, {
	showEmptyResult: {
		type: ControlType.Boolean,
		title: (
			<FormattedMessage
				id="dploy.grid.todos.showEmptyResult.label"
				defaultMessage="Show tasks with empty result set"
			/>
		)
	},
	tasks: {
		type: ControlType.Array,
		title: (
			<FormattedMessage
				id="dploy.grid.todos.tasks.label"
				defaultMessage="Task list"
			/>
		),
		description: (
			<FormattedMessage
				tagName={MarkdownElement}
				ignoreTag
				id="dploy.grid.todos.tasks.description"
				defaultMessage="Main and sub header support [ICU formatting](http://userguide.icu-project.org/formatparse/messages) of variables.

Available variables:
- `amount`: Amount of applications in search result
			"
			/>
		),
		coerce: (value, values) => {
			let rawTaskFilters: RawTaskFilter[] = [];

			try {
				if (values.taskFilterList)
					rawTaskFilters = JSON.parse(values.taskFilterList);
			} catch (e: any) {
				console.warn(e);
			}

			return rawTaskFilters.map(
				({
					id,
					filterName,
					primaryMessage,
					secondaryMessage,
					icon,
					appfilter,
					allSignedButSecurity,
					...filter
				}): TaskFilter => {
					const appStatus = Array.isArray(filter.appStatus)
						? (filter.appStatus as number[])
						: (filter.appStatus as number);

					const docStatus = Array.isArray(filter.docStatus)
						? (filter.docStatus as number[])
						: (filter.docStatus as number);

					return {
						id,
						name: filterName,
						filter: {
							...filter,
							salepersonId:
								filter.salepersonId ??
								(appfilter === "Mine" ? "current" : undefined),
							allTasksSignedExceptType:
								filter.allTasksSignedExceptType ??
								(allSignedButSecurity ? [DocumentType.Security] : undefined),
							appStatus,
							docStatus
						},
						primaryMessage,
						secondaryMessage,
						iconClass: icon
					};
				}
			);
		},
		propertyControl: {
			type: ControlType.Object,
			title: (
				<FormattedMessage
					id="dploy.grid.todos.task.title"
					defaultMessage="Task"
				/>
			),
			description: (
				<FormattedMessage
					tagName={MarkdownElement}
					ignoreTag
					id="dploy.grid.todos.task.description"
					defaultMessage="Main and sub header support [ICU formatting](http://userguide.icu-project.org/formatparse/messages) of variables.

Available variables:
- `amount`: Amount of applications in search result
				"
				/>
			),
			propertyControls: {
				id: {
					type: ControlType.Hidden,
					title: "Id"
				},
				name: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.todos.task.name.label"
							defaultMessage="Name"
						/>
					),
					defaultValue: "Nytt gjøremål"
				},
				iconClass: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.todos.task.iconClass.label"
							defaultMessage="Icon"
						/>
					),
					description: (
						<FormattedMessage
							id="dploy.grid.todos.task.iconClass.description"
							defaultMessage="Enter the name of an icon from Font Awesome"
						/>
					)
				},
				primaryMessage: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.todos.task.primaryMessage.label"
							defaultMessage="Main header"
						/>
					),
					multiline: true
				},
				secondaryMessage: {
					type: ControlType.String,
					title: (
						<FormattedMessage
							id="dploy.grid.todos.task.secondaryMessage.label"
							defaultMessage="Sub header"
						/>
					),
					multiline: true,
					defaultValue: "{amount, plural, one {# sak} other {# saker} } funnet"
				},
				filter: {
					type: ControlType.Object,
					title: (
						<FormattedMessage
							id="dploy.grid.todos.task.filter.title"
							defaultMessage="Search filter"
						/>
					),
					propertyControls: {
						vendorId: {
							type: ControlType.Enum,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.vendorId.label"
									defaultMessage="Vendor"
								/>
							),
							options: ["current"],
							optionTitles: [
								{
									id: "dploy.grid.todos.task.filter.vendorId.current.label",
									defaultMessage: "Logged in vendor"
								}
							],
							defaultValue: "current"
						},
						salepersonId: {
							type: ControlType.Enum,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.salepersonId.label"
									defaultMessage="Salesperson"
								/>
							),
							options: ["", "current"],
							optionTitles: [
								{
									id: "dploy.grid.todos.task.filter.salepersonId.empty.label",
									defaultMessage: "All salespersons"
								},
								{
									id: "dploy.grid.todos.task.filter.salepersonId.current.label",
									defaultMessage: "Logged in salesperson"
								}
							],
							defaultValue: ""
						},
						fullText: {
							type: ControlType.String,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.fullText.label"
									defaultMessage="Search text"
								/>
							),
							placeholder: "Hva/hvem leter du etter"
						},
						carRegNo: {
							type: ControlType.String,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.carRegNo.label"
									defaultMessage="Reg. number"
								/>
							),
							placeholder: "AB12345"
						},
						hasCarRegNo: {
							type: ControlType.Boolean,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.hasCarRegNo.label"
									defaultMessage="Missing reg. number"
								/>
							)
						},
						customerName: {
							type: ControlType.String,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.customerName.label"
									defaultMessage="Customer name"
								/>
							)
						},
						customerNameContains: {
							type: ControlType.Enum,
							title: "",
							options: [false, true],
							optionTitles: [
								defineMessage({
									id: "dploy.grid.todos.task.filter.customerNameContains.false.label",
									defaultMessage: "Starts with"
								}),
								defineMessage({
									id: "dploy.grid.todos.task.filter.customerNameContains.true.label",
									defaultMessage: "Includes"
								})
							]
						},
						productList: {
							type: ControlType.Array,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.productList.label"
									defaultMessage="Product"
								/>
							),
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: []
							}
						},
						fromDate: {
							type: ControlType.Date,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.fromDate.label"
									defaultMessage="From reg. date"
								/>
							)
						},
						toDate: {
							type: ControlType.Date,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.toDate.label"
									defaultMessage="To reg. date"
								/>
							)
						},
						appStatus: {
							type: ControlType.Array,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.appStatus.label"
									defaultMessage="Status"
								/>
							),
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(ApplicationStatus)
							}
						},
						docStatus: {
							type: ControlType.Array,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.docStatus.label"
									defaultMessage="Document status"
								/>
							),
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(DocumentStatus)
							}
						},
						allTasksSignedExceptType: {
							type: ControlType.Array,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.allTasksSignedExceptType.label"
									defaultMessage="Everything signed except"
								/>
							),
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(DocumentType)
							}
						},
						allTasksCompletedExceptType: {
							type: ControlType.Array,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.allTasksCompletedExceptType.label"
									defaultMessage="Everything completed except"
								/>
							),
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(DocumentType)
							}
						},
						searchInVendorHierarchy: {
							type: ControlType.Boolean,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.searchInVendorHierarchy.label"
									defaultMessage="Search in vendor hierarchy"
								/>
							)
						},
						useInternalDocStatus: {
							type: ControlType.Boolean,
							title: (
								<FormattedMessage
									id="dploy.grid.todos.task.filter.useInternalDocStatus.label"
									defaultMessage="Use internal document status"
								/>
							)
						}
					}
				}
			}
		}
	},
	boxProps: contentBoxPropertyControls
});

export default Todos;
