import React, { useCallback } from "react";
import {
	TextFieldProps as FormikTextFieldProps,
	fieldToTextField
} from "formik-material-ui";
import {
	IntlNumberFieldProps,
	IntlNumberField
} from "./helpers/IntlNumberField";
import { BaseFieldProps, FieldEditorOptions } from "./types";
import { useFormikValueOnChange } from "./hooks";
import { Overwrite, Omit } from "@material-ui/types";
import { identityRecordOfFieldEditorOptions, getFieldError } from "./utils";

export type NumberFieldProps = Overwrite<
	Omit<IntlNumberFieldProps, "onChange" | "value"> &
		FormikTextFieldProps &
		BaseFieldProps,
	{
		formatString?: string;
	}
>;

const useFieldToIntlNumberField = ({
	items,
	getItemValue,
	getItemLabel,
	valueKey,
	labelKey,
	getItemSuggestions,
	onSelectItem,
	manual,
	horizontal: row,
	multiple,
	pending,
	options,
	formatString,
	italicText,
	boldText,
	errorDisplay,
	modalText,
	onBlur: propsBlur,
	onChange: nativeOnChange,
	...props
}: NumberFieldProps): IntlNumberFieldProps => {
	const onChange = useFormikValueOnChange(props);
	const {
		field,
		form: { errors, touched }
	} = props;

	const numberProps = fieldToTextField(
		props as FormikTextFieldProps
	) as IntlNumberFieldProps;

	const { onBlur: formikBlur } = numberProps;

	const onBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (formikBlur) formikBlur(e);
			if (propsBlur) propsBlur(e);
		},
		[formikBlur, propsBlur]
	);

	return {
		...numberProps,
		...getFieldError(errors, touched, field.name, errorDisplay),
		onChange,
		onBlur,
		nativeOnChange,
		formatString,
		value:
			typeof numberProps.value === "number"
				? numberProps.value
				: Number(numberProps.value)
	};
};

export function CurrencyField(props: NumberFieldProps) {
	const numberProps = useFieldToIntlNumberField(props);
	return <IntlNumberField format="currency_input" {...numberProps} />;
}

export function PercentField(props: NumberFieldProps) {
	const numberProps = useFieldToIntlNumberField(props);
	return <IntlNumberField format="percent_input" {...numberProps} />;
}

export function NumberField(props: NumberFieldProps) {
	const numberProps = useFieldToIntlNumberField(props);
	return <IntlNumberField {...numberProps} />;
}

const commonOptions: FieldEditorOptions = {
	editableOptions: {
		placeholder: true,
		suffix: true,
		allowNegative: true,
		maximumFractionDigits: true
	}
};

export const EditorNumberFields = identityRecordOfFieldEditorOptions({
	CurrencyField: commonOptions,
	PercentField: commonOptions,
	NumberField: commonOptions
});
