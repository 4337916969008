import * as Yup from "yup";
import { LimitDetailTableSchema } from "./LimitDetailSchema";

export enum FieldType {
	Select,
	Multiselect,
	Text,
	Number,
	Currency,
	Percent,
	Date,
	Checkbox
}

export const standardValidation: {
	[key in FieldType]: any;
} = {
	[FieldType.Checkbox]: Yup.string().nullable(),
	[FieldType.Select]: Yup.string().nullable(),
	[FieldType.Multiselect]: Yup.array().nullable(),
	[FieldType.Text]: Yup.string().nullable(),
	[FieldType.Number]: Yup.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.nullable(),
	[FieldType.Currency]: Yup.number().nullable(),
	[FieldType.Percent]: Yup.number().nullable(),
	[FieldType.Date]: Yup.date().nullable()
};

export const requiredValidation: {
	[key in FieldType]: any;
} = {
	[FieldType.Checkbox]: Yup.boolean().required(),
	[FieldType.Select]: Yup.string().required(),
	[FieldType.Multiselect]: Yup.array().required().min(1),
	[FieldType.Text]: Yup.string().required(),
	[FieldType.Number]: Yup.number().required(),
	[FieldType.Currency]: Yup.number().required(),
	[FieldType.Percent]: Yup.number().required(),
	[FieldType.Date]: Yup.date().required()
};

export interface TableColumnMetadata<Row extends {} = {}> {
	readonly fieldType: FieldType;
	// Defaults to 2 for percent and 0 for number/currency
	readonly minimumFractionDigits?: number;
	// Defaults to 2 for percent and 0 for number/currency
	readonly maximumFractionDigits?: number;
	readonly allowNegative?: boolean;
	readonly isRequired?: Boolean;
	readonly displayValue?: (
		value: any,
		rowData: any,
		cells: any,
		isPlaceholder: boolean,
		firstInGroup: boolean
	) => any;
	readonly alwaysEditable?: Boolean;
	readonly isEditableFunctions?: Record<string, any>;
	readonly validatorSchemas?: Record<string, any>;
	readonly aggregate?: (tableRows: Row[]) => number;
	readonly aggregateFooter?: (tableRows: Row[]) => number;
	readonly defaultValue?: unknown;
}
