import * as React from "react";
import { Commission as PuiCommission } from "@ploy-ui/commission";
import CommissionAPI from "@ploy-lib/commission-api";
import { ExternalViewComponent } from "../types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(8),
		padding: theme.spacing(4)
	}
}));

export const Commission: ExternalViewComponent = ({
	dtResult,
	token = "",
	endpoint,
	internalUser
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CommissionAPI token={token} endpoint={endpoint}>
				{api => <PuiCommission {...api} internalUser={internalUser} />}
			</CommissionAPI>
		</div>
	);
};

export default Commission;
