import React from "react";
import ContentBox from "../../ContentBox";
import { GridComponent } from "../types";
import { GeneralInformation as GeneralInformationComponent } from "@ploy-ui/general-info";

export interface GeneralInformationProps {}

const GeneralInformation: GridComponent<GeneralInformationProps> = props => {
	const { boxProps } = props;

	return (
		<ContentBox {...boxProps}>
			<GeneralInformationComponent />
		</ContentBox>
	);
};

GeneralInformation.displayName = "DployDashboardGeneralInformation";

export default GeneralInformation;
