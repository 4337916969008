import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface VendorInfo {
	readonly customerId: ID;
	readonly disabled?: boolean;
	readonly customerName?: string;
}

export class VendorResource extends BaseResource implements VendorInfo {
	readonly customerId: ID;
	readonly disabled?: boolean;
	readonly customerName?: string;

	pk() {
		return this.customerId.toString();
	}

	static urlRoot = apiResourceUrl("vendors");
}
