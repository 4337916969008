import { apiResourceUrl } from "@ploy-lib/core";
import { ID } from "../types";
import { BaseCamelCasedResource } from "./BaseResource";

export interface OptionalConsents {
	readonly id: ID;
	label: string;
	description: string;
	accepted: boolean;
}

export class OptionalConsentsResource
	extends BaseCamelCasedResource
	implements OptionalConsents
{
	readonly id: ID = 0;
	readonly label: string = "";
	readonly description: string = "";
	readonly accepted: boolean = false;

	pk() {
		return this.id.toString();
	}

	static urlRoot = apiResourceUrl("consents/optional");
}
