import { CalculatorState, Change, Namespaced } from "./calculator";
import { Service } from "@ploy-lib/types";

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export type State<TN extends string, TD> = Readonly<{
	isInitialized: true;
	calculation: Readonly<CalculatorState<TN, TD>>;
	changes: Readonly<Change<TN>[]>;
	init: Readonly<TN[]>;
	serviceTriggers: Readonly<ServiceTrigger<TN>[]>;
	servicesToTrigger: Readonly<ServiceTrigger<TN>[]>;
}>;

export interface InitialState<TN extends string, TD> {
	isInitialized: false;
	calculation: Partial<Readonly<CalculatorState<TN, TD>>>;
	changes: Readonly<Change<TN>[]>;
	init: Readonly<TN[]>;
	serviceTriggers: Readonly<ServiceTrigger<TN>[]>;
	servicesToTrigger: Readonly<ServiceTrigger<TN>[]>;
}

export interface Patch<TN extends string, TD> {
	target: string;
	changeTrigger?: string;
	clickTrigger?: boolean | string;
	namespace: TN;
	value?: TD;
	overwrite?: boolean;
	isFieldChange?: boolean;
	silent?: boolean;
	missing?: boolean;
	writeLocked?: boolean;
	isInitial?: boolean;
	isResolve?: boolean;
	noDefer?: boolean;
}

export interface FieldPatch<TN extends string, TD> {
	namespace: TN;
	fieldName: string;
	initialValue: TD;
}

export function isFieldPatch<TN extends string, TD>(
	patch: Patch<TN, TD> | FieldPatch<TN, TD>
): patch is FieldPatch<TN, TD> {
	return (patch as FieldPatch<TN, TD>).fieldName !== undefined;
}

export interface NamespaceService<TNamespaces> extends Service {
	namespace: TNamespaces;
}

export interface ServiceTrigger<TNamespaces> {
	expression?: any;
	changeFilter?: string;
	namespace: TNamespaces;
	service: string;
}

export interface ReInitAction<TN extends string> {
	type: "reinit";
	meta?: object;
	payload: {
		namespaces?: TN[];
		clear?: TN[];
	};
}

export interface UpdateAction<TN extends string, TD> {
	type: "update" | "init";
	payload: {
		formValues: Partial<Namespaced<TD, TN>>;
	};
	meta?: object;
}

type CalculationTypes = "calculate" | "validate" | "calculate_and_validate";
export interface CalulationAction<TN extends string, TD> {
	type: CalculationTypes;
	payload?: {
		formValues?: Partial<Namespaced<TD, TN>>;
	};
	meta?: object;
}

export interface UpdateServiceTriggersAction {
	type: "update_service_triggers";
}

export interface ServiceSuccessAction<TN extends string> {
	type: "service_success";
	meta: {
		service: Service;
		namespace: TN;
	};
}

export interface PatchAction<TN extends string, TD> {
	type: "patch";
	payload: {
		patches: Patch<TN, TD>[];
	};
	meta?: object;
}

export interface FieldPatchAction<TN extends string, TD> {
	type: "field_patch";
	payload: {
		patches: FieldPatch<TN, TD>[];
	};
	meta?: object;
}

// put other actions here in union
export type ActionTypes<TN extends string, TD> =
	| CalulationAction<TN, TD>
	| UpdateAction<TN, TD>
	| ServiceSuccessAction<TN>
	| UpdateServiceTriggersAction
	| PatchAction<TN, TD>
	| FieldPatchAction<TN, TD>
	| ReInitAction<TN>;

export type Middleware = <R extends React.Reducer<any, any>>({
	dispatch
}: {
	dispatch: React.Dispatch<React.ReducerAction<R>>;
}) => (
	next: React.Dispatch<React.ReducerAction<R>>
) => (action: React.ReducerAction<R>) => void;

export enum ServiceBodyType {
	Number = "number",
	Text = "text",
	Object = "object"
}

export interface ServiceBodyBase {
	namespace: string;
	storageName?: string;
	type: ServiceBodyType;
	multiple?: boolean;
	typeName?: string;
}

export interface ServiceBodyField extends ServiceBodyBase {
	field: string;
}

export interface ServiceBodyVariable extends ServiceBodyBase {
	variable: string;
}

export interface ServiceBodyStaticValue extends ServiceBodyBase {
	name: string;
	value?: string;
}

export interface ServiceBodyVariableField
	extends ServiceBodyField,
		ServiceBodyVariable {}

export type ServiceBodyValue =
	| ServiceBodyField
	| ServiceBodyVariable
	| ServiceBodyVariableField
	| ServiceBodyStaticValue;
