import { createContext, useContext } from "react";
import { AppLoad } from "@ploy-lib/types";
import { AppLoadResource } from "@ploy-lib/rest-resources";

interface ApploadContext extends AppLoad {
	refetchApp: () => void;
	refetchCalcRules: (() => void) | undefined;
	formContext?: string;
}

export const appLoadContext = createContext<ApploadContext>({
	...AppLoadResource.fromJS({}),
	refetchApp: () => {},
	refetchCalcRules: undefined
});

appLoadContext.displayName = "AppLoad";

export function useAppLoad() {
	return useContext(appLoadContext);
}

export const AppLoadProvider = appLoadContext.Provider;
export const AppLoadConsumer = appLoadContext.Consumer;
