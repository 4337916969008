import { useCallback } from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@ploy-ui/form-fields";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import { PendingButton } from "@ploy-ui/core";
import { PasswordResetResource } from "@ploy-lib/rest-resources";
import { useFetcher } from "@rest-hooks/core";
import { SubmitStatusMessage, HeaderIcon } from "@ploy-ui/login";

const messages = defineMessages({
	usernamePlaceholder: {
		id: "dealer.login.field.new-password.username-paceholder",
		description: "Placeholder for email field",
		defaultMessage: "E-postadresse"
	},
	submitError: {
		id: "dealer.login.new-password.error",
		description: "An error occurred",
		defaultMessage: "An error has occured"
	}
});

const useStyles = makeStyles(theme => ({
	link: {
		color: theme.palette.primary.main,
		textDecoration: "none"
	},
	button: {
		marginBottom: theme.spacing(1.5)
	},
	icon: {
		color: theme.palette.primary.main
	}
}));

const NewPasswordForm = () => {
	const classes = useStyles();

	const requestPasswordReset = useFetcher(
		PasswordResetResource.resetPassword()
	);

	const intl = useIntl();

	const onSubmit = useCallback(
		async (values, formik) => {
			try {
				formik.setStatus({ channelType: values.channelType });
				formik.setSubmitting(true);
				const { error } = await requestPasswordReset(undefined, values);

				if (error) {
					formik.setErrors({ username: error });
				}
			} catch (error) {
				formik.setStatus({
					submit: {
						status: "error",
						message: intl.formatMessage(messages.submitError)
					},
					channelType: values.channelType
				});
			} finally {
				formik.setSubmitting(false);
			}
		},
		[requestPasswordReset, intl]
	);

	return (
		<div>
			<HeaderIcon
				text={
					<FormattedMessage
						id="dealer.login.header.forgotten_password"
						description="Header for forgotten password page"
						defaultMessage="Forgotten password"
					/>
				}
			/>

			<Formik initialValues={{ username: "" }} onSubmit={onSubmit}>
				{formik => {
					if (
						!formik.isSubmitting &&
						formik.status &&
						formik.status.channelType
					) {
						return (
							<>
								<Typography align="center">
									<i
										className={clsx(
											"fa fa-fw fa-check-circle fa-lg",
											classes.icon
										)}
									/>{" "}
									{formik.status.channelType === "sms" ? (
										<FormattedMessage
											id="dealer.login.password_sent_message.sms"
											description="Message for new password sent to mobile"
											defaultMessage="Password sent to your mobile"
										/>
									) : (
										<FormattedMessage
											id="dealer.login.password_sent_message.email"
											description="Message for new password sent to email"
											defaultMessage="Password sent to your email"
										/>
									)}
								</Typography>
								<Typography align="center">
									<Link to="/login" className={classes.link}>
										<FormattedMessage
											id="dealer.login.link.back_to_login.label"
											description="Label for back to login link"
											defaultMessage="Back to log in"
										/>
									</Link>
								</Typography>
							</>
						);
					}

					return (
						<Form>
							<Field
								name="username"
								component={TextField}
								type="email"
								placeholder={intl.formatMessage(messages.usernamePlaceholder)}
								label={
									<FormattedMessage
										id="dealer.login.field.username.label"
										description="Label for email field"
										defaultMessage="E-mail address"
									/>
								}
								fullWidth
								margin="normal"
								autoFocus
							/>

							<PendingButton
								pending={
									formik.isSubmitting &&
									formik.status &&
									formik.status.channelType === "email"
								}
								disabled={!formik.isValid}
								onClick={() =>
									onSubmit({ ...formik.values, channelType: "email" }, formik)
								}
								fullWidth
								color="secondary"
								size="large"
								variant="contained"
								className={classes.button}
							>
								<FormattedMessage
									id="dealer.login.button.send_to_email.label"
									description="Label for send with email button"
									defaultMessage="Send new password by email"
								/>
							</PendingButton>
							<PendingButton
								pending={
									formik.isSubmitting &&
									formik.status &&
									formik.status.channelType === "sms"
								}
								disabled={!formik.isValid}
								onClick={() =>
									onSubmit({ ...formik.values, channelType: "sms" }, formik)
								}
								fullWidth
								color="secondary"
								size="large"
								variant="contained"
								className={classes.button}
							>
								<FormattedMessage
									id="dealer.login.button.send_to_sms.label"
									description="Label for send with sms button"
									defaultMessage="Send new password by SMS"
								/>
							</PendingButton>

							<SubmitStatusMessage />

							<Typography align="center">
								<Link to=".." className={classes.link}>
									<FormattedMessage
										id="dealer.login.link.back_to_login.label"
										description="Label for back to login link"
										defaultMessage="Back to log in"
									/>
								</Link>
							</Typography>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default NewPasswordForm;
