import React, { memo, useState } from "react";
import { PendingButton } from "@ploy-ui/core";
import Icon from "@material-ui/core/Icon";
import { modals } from "../../components/modals";

import {
	isButtonColor,
	isButtonVariant,
	ButtonFieldProps
} from "./ButtonCommon";
import { DployFormControl } from "@ploy-ui/form-fields";

export const OpenModalButton = memo(
	({
		label,
		color,
		variant,
		icon,
		fullWidth,
		disabled,
		field: { value },
		margin
	}: ButtonFieldProps) => {
		const buttonColor = isButtonColor(color) ? color : undefined;
		const buttonVariant = isButtonVariant(variant) ? variant : undefined;
		const [isOpen, setOpen] = useState(false);

		const labelIcon =
			icon && typeof icon === "string" ? <Icon className={icon} /> : icon;

		const onClickHandler = () => setOpen(true);
		const onCloseHandler = () => setOpen(false);

		const ModalComponent = modals[value];

		return (
			<DployFormControl margin={margin as any} fullWidth={fullWidth}>
				<PendingButton
					color={buttonColor}
					variant={buttonVariant}
					fullWidth={fullWidth}
					disabled={disabled}
					pending={false}
					size="large"
					onClick={onClickHandler}
					endIcon={labelIcon}
				>
					{label}
				</PendingButton>
				<ModalComponent open={isOpen} handleClose={onCloseHandler} />
			</DployFormControl>
		);
	}
);

OpenModalButton.displayName = "OpenModalButton";
