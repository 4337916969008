import React, { Suspense } from "react";
import { WholesaleLimitResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { WholesaleLimitDetail } from "./WholesaleLimitDetail";

import { ExpandableTable, ExpandableTableProps } from "@ploy-ui/core";
import { Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { defineMessages, IntlShape, useIntl } from "react-intl";

const columnDefinition = (intl: IntlShape) =>
	[
		{
			id: "customerId",
			label: intl.formatMessage(messages.customerIdHeader),
			hidden: { xsDown: true },
			hiddenInExpandable: { smUp: true }
		},
		{
			id: "customerName",
			label: intl.formatMessage(messages.customerNameHeader)
		},
		{
			id: "limitRemaining",
			label: intl.formatMessage(messages.limitRemainingHeader),
			hidden: { smDown: true }
		},
		{
			id: "limit",
			label: intl.formatMessage(messages.limitHeader)
		},
		{
			id: "sumView",
			label: intl.formatMessage(messages.sumViewHeader)
		},
		{
			id: "sumApproved",
			label: intl.formatMessage(messages.sumApprovedHeader)
		},
		{
			id: "other",
			label: intl.formatMessage(messages.otherHeader),
			hiddenInExpandable: { mdUp: true }
		}
	] as ExpandableTableProps<any, any>["columnData"];

const useStyles = makeStyles(theme => ({
	expandedColumn: {
		padding: 0
	}
}));

const messages = defineMessages({
	customerIdHeader: {
		id: "dealer.wholesalelimit.table.header.customerid",
		description: "header for wholesalelimit table, column customerId",
		defaultMessage: "KundeId"
	},
	customerNameHeader: {
		id: "dealer.wholesalelimit.table.header.customername",
		description: "header for wholesalelimit table, column customerName",
		defaultMessage: "Kunde"
	},
	limitRemainingHeader: {
		id: "dealer.wholesalelimit.table.header.limitremaining",
		description: "header for wholesalelimit table, column limitRemaining",
		defaultMessage: "Gjenværende grense"
	},
	limitHeader: {
		id: "dealer.wholesalelimit.table.header.limit",
		description: "header for wholesalelimit table, column limit",
		defaultMessage: "Grense"
	},
	sumViewHeader: {
		id: "dealer.wholesalelimit.table.header.sumview",
		description: "header for wholesalelimit table, column sumView",
		defaultMessage: "Benyttet"
	},
	sumApprovedHeader: {
		id: "dealer.wholesalelimit.table.header.sumapproved",
		description: "header for wholesalelimit table, column sumApproved",
		defaultMessage: "Godkjent avrop"
	},
	otherHeader: {
		id: "dealer.wholesalelimit.table.header.other",
		description: "header for wholesalelimit table, column other",
		defaultMessage: "Andre forpliktelser"
	}
});
interface WholesaleLimitProps {
	vendorId?: number;
}

const WholesaleLimit = (props: WholesaleLimitProps) => {
	const { vendorId } = props;
	const classes = useStyles();
	const intl = useIntl();
	const wholesaleLimitData = useResource(WholesaleLimitResource.list(), {
		vendorId: vendorId
	});

	return (
		<Grid container item spacing={3} xs={12}>
			<Grid item xs={12}>
				<ExpandableTable
					idColumn="vendorId"
					tableData={wholesaleLimitData}
					columnData={columnDefinition(intl)}
					renderExpandedSection={datarow =>
						datarow.vendorId && (
							<Suspense fallback={<LinearProgress />}>
								<WholesaleLimitDetail vendorId={datarow.vendorId} />
							</Suspense>
						)
					}
					classes={classes}
				/>
			</Grid>
		</Grid>
	);
};

export { WholesaleLimit };
