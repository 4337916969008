import React from "react";
import { DataTable } from "@ploy-ui/core";
import {
	UploadedDocumentResource,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "@material-ui/core";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useAppLoad } from "../../appLoadContext";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";

const emptyArray = [];

export const DocumentListSection = injectIntl(() => {
	const { applicationNumber, id: appSessionId } = useAppLoad();

	const documentList =
		useResourceWithInvalidate(
			UploadedDocumentResource.list(),
			appSessionId ? { applicationNumber } : null
		) ?? emptyArray;

	return (
		<DataTable<UploadedDocumentResource>
			data={documentList}
			columns={[
				{
					title: (
						<FormattedMessage
							id="template-form.sections.document-list.name-column"
							description="document name"
							defaultMessage="Name"
						/>
					),
					grouping: false,
					cellStyle: {
						wordBreak: "break-word"
					},
					render: rowData => (
						<Link
							href={legacyApiResourceUrl(
								"/DocumentGrid/GetDocument" +
									"?documentId=" +
									rowData.documentId
							)}
							className="file-upload__document-name"
							underline="hover"
						>
							{rowData.filename}
						</Link>
					)
				}
			]}
			options={{
				actionsColumnIndex: Infinity,
				search: false,
				toolbar: false,
				paging: documentList.length > 5,
				showEmptyDataSourceMessage: false,
				emptyRowsWhenPaging: false
			}}
		/>
	);
});

addRegisteredSectionLayout({
	name: "DocumentListSection",
	displayName: "DocumentListSection",
	settings: {}
});
